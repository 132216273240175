import React, { Fragment } from 'react';
import PrivateRoute from 'pages/routes/PrivateRouter';
import { ROLE_DASHBOARD } from 'pages/roles';
import { ROUTE_MOCKED_DASHBOARD, ROUTE_MOCKED_DASHBOARD_REQUEST } from 'pages/constants';
import DashboardRequestModal from '../components/dashboardRequest/DashboardRequestModal';
import MockedDashboard from 'pages/dashboards/mockedDashboard';

const MockedDashboardLayout = () => {
  return (
    <Fragment>
      <PrivateRoute authorized={[ROLE_DASHBOARD]} path={ROUTE_MOCKED_DASHBOARD} component={MockedDashboard} />
      <PrivateRoute authorized={[ROLE_DASHBOARD]} path={ROUTE_MOCKED_DASHBOARD_REQUEST} exact component={DashboardRequestModal} />
    </Fragment>
  )
};

export default MockedDashboardLayout;
