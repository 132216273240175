import moment from 'moment';
import _ from 'lodash';
import { reset, actionTypes, getFormValues, FormAction } from 'redux-form';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { changeAnyFormFieldActionFilter, changeFieldFilterByPattern } from 'utils/reduxFormSelector';
import { cardError, swapTrailPricesFilter } from 'redux/epics/price/structures/swap/';
import { getCardByFormName, getCardById, getErrorsCardByFormName } from 'redux/epics/price/structures/swap/utils';
import { swapCardUpdate } from 'redux/actions/swap';
import { CARD_STATUSES } from 'constants.js';
import { isCardErrors } from 'pages/price/output/asianSwap/helpers';
import { getSelectedCurrency, swapFormCardName, PRICINIG_BULLET_STRIP_FORM_OUTPUT } from 'pages/price/output/bulletStrip/BulletStripPricingOutputCardForm';
import { priceFormFailed } from 'redux/actions/price';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { updateBulletStripCardMutation } from 'redux/queries/bulletStrip';
import { PRICING_BULLET_STRIP_CONFIRMATION_MODAL_CANCEL, PRICING_BULLET_STRIP_CONFIRMATION_MODAL_APPROVE } from 'redux/actions/bulletStrip';
import { BulletStripLegType, BulletStripCardFormDataType } from './types';

const { REQUESTED } = CARD_STATUSES;
// hook for avoid performance issues - force update field only if it necessary (only for swap cards)
const { CHANGE } = actionTypes;
export const change = (
  form: string,
  field: string,
  value: any,
  touch: boolean = false,
  persistentSubmitErrors: boolean = false
): FormAction => ({
  type: CHANGE,
  meta: { form, field, touch, persistentSubmitErrors },
  payload: value
});

export const bulletStripFormFilter = action => action && action.meta && !action.meta.form.indexOf(PRICINIG_BULLET_STRIP_FORM_OUTPUT)

const onFieldChanged = (action$, fieldName = '', debounce = 100) => action$.pipe(
  filter(changeAnyFormFieldActionFilter(fieldName)),
  filter(bulletStripFormFilter),
  debounceTime(debounce),
);

const getFormData = (form, state): BulletStripCardFormDataType => {
  const formValueSelector = getFormValues(form);
  return formValueSelector(state) as BulletStripCardFormDataType;
}

const changeLegsSizeEpic = (action$, state$) =>
  merge<FormAction>(
    onFieldChanged(action$, 'firstMonth'),
    onFieldChanged(action$, 'lastMonth'),
  ).pipe(
    filter(({ payload, meta }) => {
      return !!payload && !meta?.force
    }),
    switchMap(({ meta }) => {
      const { form } = meta;
      const { firstMonth, lastMonth: formLastMonth, legs, fillAllLegs, monthListOptions } = getFormData(form, state$.value);

      const actions: FormAction[] = [];
      if (!formLastMonth) {
        actions.push(change(form, 'lastMonth', firstMonth));
      }
      const lastMonth = formLastMonth || firstMonth;
      const start = moment(firstMonth);
      const end = moment(lastMonth);

      if (end.diff(start, 'M') < 0) return from([]);

      const prevNotionals = _.mapValues(_.keyBy(legs, 'contractExpiry'), 'notional');
      const prevExpiryDate = _.mapValues(_.keyBy(legs, 'contractExpiry'), 'expiryDate');
      const dates: BulletStripLegType[] = [];
      let total = 0;

      for (const { value, expiryDate: defaultExpiryDate } of monthListOptions) {
        if (moment(value).diff(end) <= 0 && moment(value).diff(start) >= 0) {
          const notional = prevNotionals[value] || prevNotionals[value] === 0 ? prevNotionals[value] : (fillAllLegs || 1);
          const expiryDate = prevExpiryDate[value] ? prevExpiryDate[value] : defaultExpiryDate;
          dates.push({
            contractExpiry: value,
            notional,
            expiryDate,
          });
          total += parseFloat(`${notional}`);
        }
      }

      return from([
        ...actions,
        change(form, 'legs', dates),
        change(form, 'notional', total, true)
      ]);
    }),
  );

const fillAllLegsNotionalsEpic = (action$, state$) =>
  merge<FormAction>(
    onFieldChanged(action$, 'fillAllLegs', 250),
  ).pipe(
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta, payload }) => {
      if (!payload || !parseFloat(payload) || parseFloat(payload) <= 0) return from([]);
      const { form } = meta;
      const { legs } = getFormData(form, state$.value);
      const actions: FormAction[] = [];

      const data = legs.map((leg) => ({ ...leg, notional: payload }));
      actions.push(change(form, 'legs', data));
      actions.push(change(form, 'notional', parseFloat(payload) * legs.length));

      return from(actions);
    }),
  );

const updateNotionalEpic = (action$, state$) =>
  merge<FormAction>(
    action$.pipe(
      filter(changeFieldFilterByPattern(/^legs\[\d{1,9}].notional/)),
      filter(bulletStripFormFilter),
      debounceTime(300),
    )
  ).pipe(
    filter(({ payload, meta }) => (!!payload || payload === 0) && !meta?.force),
    switchMap(({ meta }) => {
      const { form } = meta;
      const { legs } = getFormData(form, state$.value);
      const notional = legs.reduce((a, { notional }) => a + parseFloat(`${notional}`), 0);
      return from([
        change(form, 'fillAllLegs', null),
        change(form, 'notional', notional, true),
      ]);
    }),
  )

const getEvents = (action$) => [
  action$.pipe(
    filter(changeFieldFilterByPattern(/^legs\[\d{1,9}].expiryDate/)),
    filter(bulletStripFormFilter),
    debounceTime(300),
  ),
  onFieldChanged(action$, 'notional'),
  onFieldChanged(action$, 'termCurrency'),
  onFieldChanged(action$, 'unit'),
];

const startUpdateCardEpic = (action$, state$) =>
  merge<FormAction>(
    ...getEvents(action$),
  ).pipe(
    filter(() => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta, payload }) => {
      const formErrors = getErrorsCardByFormName(state$, meta);
      if (isCardErrors(formErrors)) return from([]);

      const card = getCardByFormName(state$, meta);
      const confirmationPopup = card.status === REQUESTED;
      return from([
        swapCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
          confirmationPopup,
          lastChangedField: { field: meta.field, payload }
        })
      ])
    })
  );

const sendUpdateCard = (card) => {
  const { fillAllLegs, fxCode } = card;
  return from(updateBulletStripCardMutation(card))
    .pipe(
      switchMap((newCardData) => {
        return from([
          swapCardUpdate({
            ...newCardData,
            fillAllLegs,
            fxCode,
            price: null,
            isError: false,
            loading: false,
            isLoading: false,
            confirmationPopup: false,
          }),
        ]);
      }),
      catchError((error) => {
        const actions = [
          priceFormFailed({}),
          swapCardUpdate({
            id: card.id,
            fillAllLegs,
            loading: false,
            isLoading: false,
            confirmationPopup: false,
            isError: !!error
          })
        ];
        const err = getSingleGraphQlError(error);
        if (err?.message) {
          return from([
            ...actions,
            notificationErrorSimple(err.message),
          ])
        }

        return from([
          ...actions,
          cardError(card, 'update'),
        ]);
      })
    )
}

const prepareUpdatedFields = (state$) => switchMap(({ field, formName, cardState }) => {
  const { id, commodityCode } = cardState;
  const cardForm = getFormData(formName, state$.value);
  const { termCurrency, baseCurrency, legs } = cardForm;

  if (field === 'termCurrency' && termCurrency !== baseCurrency) {
    const { currencyOptions: currencyState = {} } = state$.value?.price
    const currencyOptions = currencyState[baseCurrency] ? currencyState[baseCurrency] : [{ label: baseCurrency, value: baseCurrency, deliverable: false, fxCode: null }];
    const { fxCode } = getSelectedCurrency(currencyOptions, termCurrency);
    cardForm.fxCode = fxCode;
  }

  if (termCurrency === baseCurrency) {
    cardForm.fxCode = null;
  }

  return sendUpdateCard({
    id,
    commodityCode,
    ...cardForm,
    legs: legs.map(({ expiryDate, ...leg }) => ({ ...leg, expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : null })),
  })
})

const cardUpdateEpic = (action$, state$) =>
  merge<FormAction>(
    ...getEvents(action$),
  ).pipe(
    filter((): boolean => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }): boolean => !!payload && !meta?.force),
    map(({ meta, payload }) => {
      const { field, form: formName } = meta;
      const formErrors = getErrorsCardByFormName(state$, meta);
      const cardState = getCardByFormName(state$, meta);
      return { field, formName, payload, cardState, formErrors }
    }),
    filter(({ formErrors }: any): boolean => !isCardErrors(formErrors)),
    filter(({ cardState }: any): boolean => !cardState.confirmationPopup),
    prepareUpdatedFields(state$),
  );

const closeModalEpic = (action$, state$) => action$.pipe(
  ofType(PRICING_BULLET_STRIP_CONFIRMATION_MODAL_CANCEL),
  switchMap(({ payload: id }) => {
    const card = getCardById(state$.value.price?.trailPrice?.cards, id);
    const formName = swapFormCardName(id);
    const actions = [
      reset(formName),
      swapCardUpdate({
        ...card,
        loading: false,
        isLoading: false,
        confirmationPopup: false,
      }),
    ];
    return from(actions);
  })
)

const approveModalEpic = (action$, state$) => action$.pipe(
  ofType(PRICING_BULLET_STRIP_CONFIRMATION_MODAL_APPROVE),
  map(({ payload: { id, field = '', payload } }: FormAction) => {
    const cardState = getCardById(state$.value.price.trailPrice.cards, id);
    const formName = swapFormCardName(id);
    return { formName, field, payload, cardState }
  }),
  prepareUpdatedFields(state$),
)

export default combineEpics(
  changeLegsSizeEpic,
  fillAllLegsNotionalsEpic,
  updateNotionalEpic,
  startUpdateCardEpic,
  cardUpdateEpic,
  closeModalEpic,
  approveModalEpic
);
