import React, { PureComponent } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

const DURATION = 500;

class InputAnimation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: false, value: null }
  }

  static getDerivedStateFromProps(props, state) {
    const { value: nextValue } = props;
    const { value } = state;
    return (value !== nextValue) ? { value: nextValue } : null;
  }

  componentDidUpdate(prevProps, state) {
    const { value: prevValue } = state;
    const { show, value } = this.state;
    const { active } = this.props;
    if (active && value !== prevValue && show === false) {
      (show === false) ?
        this.showAnimation()
        :
        this.clearAnimationTimer();
    }
  }

  showAnimation = () => {
    this.setState({ show: true });
    this.timeout = setTimeout(() => {
      this.setState({ show: false });
    }, DURATION * 2);
  }

  clearAnimationTimer = () => {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentWillUnmount() {
    this.setState({ show: false });
    this.clearAnimationTimer();
  }

  render() {
    const { show } = this.state;
    return (
      show ?
        <Transition in={show} appear={true} unmountOnExit={true} timeout={DURATION}>
          {status => (
            <div className={`form-control-wrapper-marker transition-marker-jump transition-marker-jump-${status}`}></div>
          )}
        </Transition>
        :
        null
    )
  }
}

InputAnimation.defaultProps = {
  active: true,
};

InputAnimation.propTypes = {
  value: PropTypes.any,
  active: PropTypes.bool.isRequired,
};

export default InputAnimation;
