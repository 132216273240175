import React from 'react';
import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import AccumulatorPricingHistoryRow from './accumulator/AccumulatorPricingHistoryRow';
import PricingHistoryRow from './PricingHistoryRow';

const ChoosePricinigHistoryType = ({ structure, ...props }) => {
  switch (structure) {
    case STRUCTURE_ACCUMULATOR:
      return <AccumulatorPricingHistoryRow structure={structure} {...props} />
    default:
      return <PricingHistoryRow structure={structure} {...props} />
  }
};

export default ChoosePricinigHistoryType;
