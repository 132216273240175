import { CUSTOM_PRICING_FORM } from 'components/customPricingRequests/CustomPricingRequestForm';
import { combineEpics } from 'redux-observable';
import { getFormValues } from 'redux-form';
import { customRequestsNewItem } from 'redux/actions/custom-requests';
import { REDUX_FORM_SUBMITTED } from 'redux/epics/constants';
import { filter, map } from 'rxjs/operators';

const customRequestsFormSubmittedFilter = action => action.type === REDUX_FORM_SUBMITTED && action.meta.form === CUSTOM_PRICING_FORM;
const valueselector = getFormValues(CUSTOM_PRICING_FORM);

const submittedFormEpic = (action$, state$) =>
  action$
    .pipe(
      filter(customRequestsFormSubmittedFilter),
      map(() => {
        const state = state$.value;
        const values = valueselector(state);
        return customRequestsNewItem(values);
      })
    );

export default combineEpics(
  submittedFormEpic,
)
