import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ServerErrorMessage = ({ code, message }) => {
  switch (code) {
    case 'E38':
      return <FormattedMessage id="error.e38"
        defaultMessage="Notification is not active" />
    case 'E42':
      return <FormattedMessage id="error.e42"
        defaultMessage="You already have made a dashboard request" />
    case 'E46':
      return <FormattedMessage id="error.e46"
        defaultMessage="This notification has already been setup" />
    case 'E48':
      return <FormattedMessage id="error.e48"
        defaultMessage="notification is expired" />
    case 'E49':
      return <FormattedMessage id="error.e49"
        defaultMessage="dashboard not exist for this user" />
    case 'E50':
      return <FormattedMessage id="error.e50"
        defaultMessage="can't generate Power BI token, please check your Power BI account settings" />
    case 'E51':
      return <FormattedMessage id="error.e51"
        defaultMessage="user can't send dashboard refresh request again" />
    case 'E52':
      return <FormattedMessage id="error.e52"
        defaultMessage="can't generate Power BI token, please check your Power BI dashboard settings" />
    case 'E54':
      return <FormattedMessage id="error.e54"
        defaultMessage="Pricing tag is not correct" />
    default:
      const errorMessage = message?.description ?? message
      return errorMessage ?
      errorMessage :
        <FormattedMessage id="error.undefined"
          defaultMessage="Undefined Server error" />
  }
};

ServerErrorMessage.propTypes = {
  code: PropTypes.string,
  message: PropTypes.string
}

export default ServerErrorMessage;
