import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { FormText } from 'reactstrap';

const FieldInfoMessage = props => {
  const { input: { value }, touched, valid, message } = props;
  return ( value && touched && message && valid ?
    <FormText color="success" className="form-control-alert">{message}</FormText> : null
  );
};

FieldInfoMessage.propTypes = {
  ...fieldPropTypes.meta
};

export default (FieldInfoMessage);
