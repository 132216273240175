import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import debug from 'debug';
import { combineEpics } from 'redux-observable';
import { PRICING_FORM, updateStructure } from 'redux/actions/price';
import { combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { changeActionFilter } from 'utils/reduxFormSelector';
import { PRICING_LOGGER_PREFIX } from './constants';

export const findSelectedStructure = (formValues = {}, structures = []) => {
  const { structure, structureType, barrierType, leverage, leverageStyle, remainderFeature, accumulationStyle } = formValues;
  return structures.find(item =>
    structureType === item.type
    && structure === item.structure
    && barrierType === item.barrierType
    && leverageStyle === item.leverageStyle
    && leverage === item.leverage
    && remainderFeature === item.remainderFeature
    && accumulationStyle === item.accumulationStyle
  );
}

const selectStructureEpicLogger = debug(PRICING_LOGGER_PREFIX + ':selectStructureEpic')
export const selectStructureEpic = (action$, state$) =>
  combineLatest(
    of({ payload: STRUCTURE_ACCUMULATOR }),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'structureType'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'barrierType'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'leverageValue'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'leverageStyle'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'remainderFeature'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'accumulationStyle'))),
  ).pipe(
    map(actions => actions.map(({ payload }) => payload)),
    filter(([structure, structureType, barrierType, leverage, leverageStyle, remainderFeature, accumulationStyle]) =>
      !!structure && !!structureType && !!barrierType && !!leverage && !!leverageStyle && !!remainderFeature && !!accumulationStyle
    ),
    map(([structure, structureType, barrierType, leverage, leverageStyle, remainderFeature, accumulationStyle]) =>
      ({ structure, structureType, barrierType, leverage, leverageStyle, remainderFeature, accumulationStyle })),
    map((formValues) => {
      const state = state$.value;
      const { structures } = state.price;
      return findSelectedStructure(formValues, structures);
    }),
    tap(selectStructureEpicLogger.bind(selectStructureEpicLogger, 'test')),
    map(structure => updateStructure(structure || null)),
    tap(selectStructureEpicLogger)
  );

export default combineEpics(
  selectStructureEpic,
);
