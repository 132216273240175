import InlineInput from 'components/form/InlineInput';
import { toggleOpen } from 'components/layout/header/ProfileDropdown';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { setAppOptions } from './TableColumnsTogglerWrapper';

class TableColumnsToggler extends PureComponent {

  constructor(args) {
    super(args);
    this.state = {
      isOpen: false,
      columns: this.props.columns,
      hidden: this.props.hidden || []
    }
    this.toggle = toggleOpen.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(state.columns, props.columns)) {
      return { columns: props.columns }
    }

    return null;
  }

  hideColums = value => {
    const { hidden } = this.state;
    hidden.push(value);
    this.setState({ hidden });
  }

  checkAll = e => {
    const { reset, onCheckAll, storageName, formName } = this.props;
    setAppOptions(storageName || 'pricingHistory', null);
    onCheckAll();
    reset(formName || PRICING_HISTORY_FORM_COLUMNS);
    e.preventDefault();
  }

  onChangeFilter = ({ name, value }) => {
    if (!name) return;
    const { displayColumns, storageName } = this.props;
    let updatedColumns = { ...displayColumns };
    const updatedValue = (typeof value == "boolean") ? value : value === 'false';
    updatedColumns[name] = updatedValue;
    setAppOptions(storageName || 'pricingHistory', updatedColumns);
  }

  render() {
    const { columns, isOpen } = this.state;
    const { isActiveAllColumns } = this.props;
    return (columns ?
      <ButtonGroup className="control-columns btn-group-outline">
        <Button type="button" color={isActiveAllColumns ? 'primary' : ''} onClick={this.checkAll}>
          <FormattedMessage id="common/display"
            defaultMessage="Display" />&nbsp;
            <FormattedMessage id="common/all"
            defaultMessage="All" />
        </Button>
        <Dropdown isOpen={isOpen} toggle={this.toggle} className="btn-group d-inline-block pricing-history-display-dropdown">
          <DropdownToggle nav caret className={`btn${isOpen ? ' btn-primary' : ''}`}>
            <FormattedMessage id="common/display"
              defaultMessage="Display" />
          </DropdownToggle>
          <DropdownMenu right className="pt-3">
            <Form className="px-3" noValidate>
              <div className="checkboxes-list">
                {columns.map((column) => {
                  return <Field
                    key={column.key}
                    name={column.fieldName || column.key}
                    type="checkbox"
                    labelCheckbox={column.label}
                    onChangeFilter={this.onChangeFilter}
                    component={InlineInput} />
                })}
              </div>
            </Form>
          </DropdownMenu>
        </Dropdown>
      </ButtonGroup>
      : null
    );
  }
}

TableColumnsToggler.propTypes = {
  columns: PropTypes.array.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({ reset }, dispatch);

const mapStateToProps = (state, ownProps) => {
  const form = ownProps.formName ? ownProps.formName : PRICING_HISTORY_FORM_COLUMNS;
  return {
    initialValues: ownProps.initialValues,
    form,
  }
}

export const PRICING_HISTORY_FORM_COLUMNS = '@PRICING_HISTORY/FORM_COLUMNS';

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true
  })
)(TableColumnsToggler);
