import { STRUCTURE_VANILLA } from 'constants.js';
import { ofType } from 'redux-observable';
import { PRICING_SUBMIT_FORM } from 'redux/actions/price';
import { swapCardCreate } from 'redux/actions/swap';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { createVanillaCardMutation } from 'redux/queries/vanilla';
import { from } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { extractContractData } from '../../swap';
import { catchCardCreation, applyContract } from 'redux/epics/price/structures/swap/cards/create';

const saveCard = (structure, commodityContract, cardData) => {
  return from(createVanillaCardMutation(structure, cardData))
  .pipe(
    filter(payload => !!payload),
    map(({ price, priceForCalcSpread, id, contractCode }) => {
      return swapCardCreate({
        ...cardData,
        id,
        price,
        priceForCalcSpread,
        contractCode
      })
    }),
    catchCardCreation(commodityContract)
  )
}

export const submitPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_VANILLA),
    filter(({ payload }) => !!payload && Array.isArray(state$.value.price.pricings)),
    map(({ payload }) => extractContractData(state$.value.price.pricings, payload)),
    filter(contract => contract),
    debounceTime(200),
    switchMap(({ commodity: commodityContract, commodityCode, direction, quotedCurrency }) => {

      const structure = priceFormValueSelector(state$.value).structure.toLowerCase();
      const cardData = {
        structure: STRUCTURE_VANILLA.toLowerCase(),
        direction,
        commodityContract,
        commodityCode,
        quantity: 1,
        structureType: 'call',
        quotedCurrency,
      };

      return applyContract(structure, cardData, saveCard);
    })
  );

export default submitPriceFormEpic;
