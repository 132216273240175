import { THEME_SET, THEME_SET_IMAGES, THEME_SET_INITIAL, THEME_SET_LOADER, THEME_SET_META, THEME_SET_NAME, THEME_SET_STYLES, THEME_SET_TEXTS } from 'redux/actions/theme';
import { THEME_INIT_STATE } from './initial';
import THEME_AGILE from './templates/agile';
import { prepareMeta } from './utils';

export default function themeReducer(state = THEME_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case THEME_SET:
      if (!payload) return {
        ...state,
        ...THEME_AGILE,
      };
      const { images, styles, texts, name } = payload || {};
      // UI call it only if theme exist from API response
      const meta = prepareMeta(payload);
      return {
        ...state,
        texts,
        meta,
        name: name || 'custom',
        images,
        styles
      };
    case THEME_SET_TEXTS:
      return { ...state, texts: payload };
    case THEME_SET_IMAGES:
      return { ...state, images: payload };
    case THEME_SET_STYLES:
      return { ...state, styles: payload };
    case THEME_SET_META:
      return { ...state, meta: payload };
    case THEME_SET_NAME:
      return { ...state, name: payload };
    case THEME_SET_LOADER:
      return { ...state, isLoading: Boolean(payload) };
    case THEME_SET_INITIAL:
      return THEME_INIT_STATE;
    default:
      return state;
  }
};
