import validateJs from 'utils/validate.js';
import { commodityValidation as commodity } from '../../validations';

export default function validate(data, props) {
  const { validationParams } = props;

  return validateJs(data, {
    ...validationParams,
    structure: {
      presence: true,
    },
    commodity,
    direction: {
      presence: true,
    },
  }) || {};
}
