import validateJs from 'utils/validate.js';
import moment from 'moment';
import { DATE_FORMAT_INPUT } from 'constants.js';
import validateMessages from 'utils/validate.messages';

validateJs.extend(validateJs.validators.datetime, {
  parse: value => {
    const date = moment(value, DATE_FORMAT_INPUT, true);
    return date;
  },
  format: value => {
    return moment(value).format(DATE_FORMAT_INPUT);
  }
});

export default function (data) {
  return validateJs(data, {
    cutomRequestStructure: {
      presence: {
        message: `^Structure ${validateMessages.blank}`
      }
    },
    cutomRequestStatus: {
      presence: {
        message: `^Status ${validateMessages.blank}`
      }
    },
    cutomRequestType: {
      presence: {
        message: `^Type ${validateMessages.blank}`
      }
    },
    cutomRequestUnderlying: {
      presence: {
        message: `^Underlying ${validateMessages.blank}`
      },
    },
    cutomRequestCurrency: {
      presence: {
        message: `^Currency ${validateMessages.blank}`
      },
      length: {
        minimum: 2,
        tooShort: `^Currency ${validateMessages.tooShort}`,
        maximum: 100,
        tooLong: `^Currency ${validateMessages.tooLong}`,
      }
    },
    cutomRequestExpiredAt: {
      presence: {
        message: `^Expired at ${validateMessages.blank}`
      },
      datetime: true,
    },
    cutomRequestNotional: {
      presence: {
        message: `^Notional ${validateMessages.blank}`
      },
      length: {
        maximum: 100,
        tooLong: `^Notional ${validateMessages.tooLong}`
      }
    },
    cutomRequestDirection: {
      presence: {
        message: `^Direction ${validateMessages.blank}`
      }
    },
    cutomRequestStructureDetails: {
      presence: {
        message: `^Structure details ${validateMessages.blank}`
      },
    },
  }) || {};
};
