import { DataType } from "./types";
import { isDefined } from "validate.js";

export const convertDataType = (
  dataType: DataType,
  value:
    | string
    | number
    | undefined
    | { label: string; value: string | number },
) => {
  switch (dataType) {
    case DataType.list:
      if (isDefined(value)) {
        return [value];
      }
      return undefined;
    case DataType.int:
      return typeof value === "string" ? parseInt(value) : value || 0;
    case DataType.float:
      return typeof value === "string" ? parseFloat(value) : value || 0;
    case DataType.str:
      if (!isDefined(value)) {
        return "";
      }
      return typeof value === "object" ? String(value.value) : String(value);
    case DataType.bool:
      return !!value;
    default:
      return value;
  }
};

const PATTERN = /\d+-\d+$/; // number/s hyphen number/s (e.g 1-1)

export const remapScheduleData = (
  fomValues: Record<string, number | string>,
  schedule: Record<string, Record<string, number | string>>[],
) => {
  return Object.keys(fomValues).reduce((accum, key) => {
    const matches = key?.match(PATTERN);
    if (matches) {
      const [index] = matches[0];
      const [keyWithoutIndex] = key.split(`-${matches[0]}`);
      const formValue = fomValues[key];
      accum[index] = {
        ...accum[index],
        [keyWithoutIndex]: isNaN(Number(formValue))
          ? formValue
          : Number(formValue),
      };
    }
    return accum;
  }, Array(schedule.length));
};
