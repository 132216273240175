import validateJs from 'utils/validate.js';

export default function (data) {
  return validateJs(data, {
    details: {
      presence: true,
      length: { minimum: 2, maximum: 255 }
    },
  }) || {};
};
