import { ofType, combineEpics } from "redux-observable";
import { priceFormFailed } from "redux/actions/price";
import { notificationErrorSimple } from "redux/alerts/actions";
import { priceFormValueSelector } from "redux/epics/price/price-form";
import { getSingleGraphQlError } from "redux/epics/utils";
import { requestPriceMutation } from "redux/queries/minifuture";
import { from } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { getCardById } from "redux/epics/price/structures/swap/utils";
import {
  minifutureCardUpdate,
  MINIFUTURE_REQUEST_PRICE,
} from "redux/actions/minifuture";
import { cardError } from "redux/epics/price/structures/swap";

export const startCardRequestPriceEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_REQUEST_PRICE),
    filter(() => priceFormValueSelector(state$.value)),
    filter(({ payload }) => payload),
    map(({ payload }) => payload),
    switchMap(({ id }) => {
      const card = getCardById(state$.value.minifuture.trailPrice.cards, id);
      const actions = [
        minifutureCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
          isRequested: false,
        }),
      ];
      return from(actions);
    })
  );

export const submitCardForRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_REQUEST_PRICE),
    filter(() => priceFormValueSelector(state$.value)),
    filter(({ payload }) => payload),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap(({ id }) => {
      const card = getCardById(state$.value.minifuture.trailPrice.cards, id);
      return from(requestPriceMutation({ id })).pipe(
        filter((payload) => !!payload),
        map(({ status }) => {
          return minifutureCardUpdate({
            ...card,
            status,
            loading: false,
            isLoading: false,
            isRequested: true,
          });
        }),
        catchError((error) => {
          const actions = [
            priceFormFailed(),
            minifutureCardUpdate({
              ...card,
              loading: false,
              isLoading: false,
            }),
          ];
          const err = getSingleGraphQlError(error);
          if (err?.message) {
            return from([...actions, notificationErrorSimple(err.message)]);
          }

          return from([...actions, cardError(card, "retrieve")]);
        })
      );
    })
  );

export default combineEpics(
  startCardRequestPriceEpic,
  submitCardForRequestEpic
);
