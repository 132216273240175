import InlineInput from 'components/form/InlineInput';
import { DATE_FORMAT_PRICING_ACCUMULATOR_MINI } from 'constants.js';
import { isBusinessDay, toDay } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const PricinigMiniFormStartEndDates = props => {
  const {
    isLoading = false,
    isFlexible,
    structureExpiredAtMax,
    filterEndDate,
    scheduleDates,
    bankHolidays,
  } = props;
  const structureExpiredAtMaxPrev = usePrevious(structureExpiredAtMax);

  const [formValues, setFormValues] = useState({
    startDate: toDay(),
    endDate: isFlexible ? structureExpiredAtMax : null,
  });

  const onChangeFilter = ({ e: value, name }) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  useEffect(() => {
    if (structureExpiredAtMax !== structureExpiredAtMaxPrev) {
      setFormValues({
        ...formValues,
        endDate: structureExpiredAtMax
      });
    }
  }, [structureExpiredAtMax, structureExpiredAtMaxPrev, formValues]);

  const endDateMin = formValues?.startDate;
  const maxStartDate = formValues?.endDate;

  return (
    <div className="mb-3 mt-2">
      <div className="d-flex mb-2">
        <div className="pre-label">
          <FormattedMessage id="form.price.fields.start.label"
            defaultMessage="Start" />
        </div>
        <div className="ml-auto form-control-mini form-control-mini-expiry text-right">
          <Field
            name="startDate"
            component={InlineInput}
            type="date"
            filterDate={isBusinessDay}
            minDate={isFlexible ? toDay() : null}
            maxDate={isFlexible ? maxStartDate : null}
            readOnlyInput={true}
            placeHolder={'Select'}
            disabled={!isFlexible || isLoading}
            onChangeFilter={onChangeFilter}
            className="text-right"
            dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
            excludeDates={bankHolidays}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="pre-label expiry-label">
          <FormattedMessage id="form.price.fields.expiration.label"
            defaultMessage="Expiry" /> <span className="pl-2 font-weight-bold">
            <FormattedMessage id="scheduleDates"
              defaultMessage={scheduleDates} /></span>
        </div>
        <div className="ml-auto form-control-mini form-control-mini-expiry text-right">
          <Field
            name="endDate"
            component={InlineInput}
            type="date"
            filterDate={filterEndDate}
            minDate={endDateMin || toDay()}
            maxDate={structureExpiredAtMax}
            readOnlyInput={true}
            placeHolder={'Select'}
            disabled={!isFlexible || isLoading}
            onChangeFilter={onChangeFilter}
            className="text-right"
            dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
            excludeDates={bankHolidays}
          />
        </div>
      </div>
    </div>
  );
};

PricinigMiniFormStartEndDates.defaultProps = {
  isFlexible: false,
};

PricinigMiniFormStartEndDates.propTypes = {
  structureExpiredAtMax: PropTypes.string.isRequired,
  isFlexible: PropTypes.bool.isRequired,
  filterEndDate: PropTypes.func.isRequired,
};

export default PricinigMiniFormStartEndDates
