export const fieldValidationParamsSelector = form => form && form.input ? form.input.reduce((prev, field) => {
  prev[field.name] = getValidationRule(field);
  return prev;
}, {}) : {}

export function getValidationRule(field) {
  const { type } = field;
  switch (type) {
    case 'number':
      const numberValidationRules = {
        presence: true,
        numericality: {
          lessThanOrEqualTo: field.max,
          greaterThanOrEqualTo: field.min,
        }
      };
      return numberValidationRules;
    default:
      return {
        presence: true,
      };
  }
}
