import moment from 'moment';
import _ from 'lodash';
import { reset, actionTypes, getFormValues } from 'redux-form';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { changeAnyFormFieldActionFilter, changeFieldFilterByPattern } from 'utils/reduxFormSelector';
import { cardError, swapTrailPricesFilter } from 'redux/epics/price/structures/swap/';
import { getCardByFormName, getCardById, getErrorsCardByFormName } from 'redux/epics/price/structures/swap/utils';
import { swapCardUpdate } from 'redux/actions/swap';
import { CARD_STATUSES } from 'constants.js';
import { isCardErrors } from 'pages/price/output/asianSwap/helpers';
import { DATE_FORMAT } from 'pages/price/output/asianSwap/constants';
import { getSelectedCurrency, swapFormCardName, PRICINIG_ASIAN_SWAP_SWAP_FORM_OUTPUT } from 'pages/price/output/asianSwap/AsianSwapPricingOutputCardForm/AsianSwapPricingOutputCardForm';
import { priceFormFailed } from 'redux/actions/price';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { updateAsianSwapCardMutation } from 'redux/queries/asianSwap';
import { PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_CANCEL, PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_APPROVE } from 'redux/actions/asianSwap';

const { REQUESTED } = CARD_STATUSES;
// hook for avoid performance issues - force update field only if it necessary (only for swap cards)
const { CHANGE } = actionTypes;
const change = (
  form,
  field,
  value,
  touch = false,
  persistentSubmitErrors = false
) => ({
  type: CHANGE,
  meta: { form, field, touch, persistentSubmitErrors },
  payload: value
});

export const asianSwapFormFilter = action => action && action.meta && !action.meta.form.indexOf(PRICINIG_ASIAN_SWAP_SWAP_FORM_OUTPUT)

const onFieldChanged = (action$, fieldName = '', debounce = 100) => action$.pipe(
  filter(changeAnyFormFieldActionFilter(fieldName)),
  filter(asianSwapFormFilter),
  debounceTime(debounce),
);

const getFormData = (form, state) => {
  const formValueSelector = getFormValues(form);
  return formValueSelector(state);
}

const changeSizesPerSettlementEpic = (action$, state$) =>
  merge(
    onFieldChanged(action$, 'month'),
    onFieldChanged(action$, 'lastMonth'),
  ).pipe(
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta }) => {
      const { form } = meta;
      const { month, lastMonth: formLastMonth, legs, fillAllLegs } = getFormData(form, state$.value);

      const actions = [];
      if(!formLastMonth) {
        actions.push(change(form, 'lastMonth', month));
      }
      const lastMonth = formLastMonth || month;
      const start = moment(month);
      const end = moment(lastMonth);

      if (end.diff(start, 'M') < 0) return from([]);

      const prevNotionals = _.mapValues(_.keyBy(legs, 'contractExpiry'), 'notional');

      const dates = [];
      const currDate = start.clone();
      let total = 0;
      while (currDate.diff(end) <= 0) {
        const contractExpiry = currDate.clone().format(DATE_FORMAT);
        const notional = prevNotionals[contractExpiry] || prevNotionals[contractExpiry] === 0 ? prevNotionals[contractExpiry] : (fillAllLegs || 1);
        dates.push({
          contractExpiry,
          notional,
        });
        total += parseFloat(notional);
        currDate.add(1, 'M')
      }

      return from([
        ...actions,
        change(form, 'legs', dates),
        change(form, 'notional', total, true)
      ]);
    }),
  );

const fillAllSizesPerSettlementEpic = (action$, state$) =>
  merge(
    onFieldChanged(action$, 'fillAllLegs', 250),
  ).pipe(
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta, payload }) => {
      if (!payload || !parseFloat(payload) || parseFloat(payload) <= 0) return from([]);
      const { form } = meta;
      const { legs } = getFormData(form, state$.value);
      const actions = [];

      const data = legs.map((leg) => ({ ...leg, notional: payload }));
      actions.push(change(form, 'legs', data));
      actions.push(change(form, 'notional', parseFloat(payload) * legs.length));

      return from(actions);
    }),
  );

const updateNotionalEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeFieldFilterByPattern(/^legs\[\d{1,9}].notional/)),
      filter(asianSwapFormFilter),
      debounceTime(300),
    )
  ).pipe(
    filter(({ payload, meta }) => (!!payload || payload === 0) && !meta?.force),
    switchMap(({ meta }) => {
      const { form } = meta;
      const { legs } = getFormData(form, state$.value);
      const notional = legs.reduce((a, { notional }) => a + parseFloat(notional), 0);
      return from([
        change(form, 'fillAllLegs', null),
        change(form, 'notional', notional, true),
      ]);
    }),
  )

const getEvents = (action$) => [
  onFieldChanged(action$, 'notional'),
  onFieldChanged(action$, 'termCurrency'),
  onFieldChanged(action$, 'fixing'),
  onFieldChanged(action$, 'settlement'),
  onFieldChanged(action$, 'unit'),
  onFieldChanged(action$, 'underlyingContracts'),
];

const startUpdateCardEpic = (action$, state$) =>
  merge(
    ...getEvents(action$),
  ).pipe(
    filter(() => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta, payload }) => {
      const formErrors = getErrorsCardByFormName(state$, meta);
      if (isCardErrors(formErrors)) return from([]);

      const card = getCardByFormName(state$, meta);
      const confirmationPopupAsian = card.status === REQUESTED;
      return from([
        swapCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
          confirmationPopupAsian,
          lastChangedField: { field: meta.field, payload }
        })
      ])
    })
  );

const sendUpdateCard = (card) => {
  const cardSimple = {
    ...card,
    loading: false,
    isLoading: false,
    confirmationPopupAsian: false,
  }
  return from(updateAsianSwapCardMutation(card))
    .pipe(
      switchMap(({ status, disableCompo, bloombergTicker }) => {
        return from([
          swapCardUpdate({
            ...cardSimple,
            price: null,
            status,
            disableCompo,
            isError: false,
            bloombergTicker,
          }),
        ]);
      }),
      catchError((error) => {
        const actions = [
          priceFormFailed(),
          swapCardUpdate({
            ...cardSimple,
            isError: !!error
          })
        ];
        const err = getSingleGraphQlError(error);
        if (err?.message) {
          return from([
            ...actions,
            notificationErrorSimple(err.message),
          ])
        }

        return from([
          ...actions,
          cardError(card, 'update'),
        ]);
      })
    )
}

const prepareUpdatedFields = (state$) => switchMap(({ field, formName, cardState }) => {
  const { id, commodityCode } = cardState;
  const cardForm = getFormData(formName, state$.value);
  const { termCurrency, baseCurrency } = cardForm;

  if (field === 'termCurrency' && termCurrency !== baseCurrency) {
    const { currencyOptions: currencyState = {} } = state$.value?.price
    const currencyOptions = currencyState[baseCurrency] ? currencyState[baseCurrency] : [{ label: baseCurrency, value: baseCurrency, deliverable: false, fxCode: null }];
    const { fxCode } = getSelectedCurrency(currencyOptions, termCurrency);
    cardForm.fxCode = fxCode;
  }

  if (termCurrency === baseCurrency) {
    cardForm.fxCode = null;
  }

  return sendUpdateCard({
    id,
    commodityCode,
    ...cardForm,
  })
})

const cardUpdateEpic = (action$, state$) =>
  merge(
    ...getEvents(action$),
  ).pipe(
    filter(() => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }) => !!payload && !meta?.force),
    map(({ meta, payload }) => {
      const { field, form: formName } = meta;
      const formErrors = getErrorsCardByFormName(state$, meta);
      const cardState = getCardByFormName(state$, meta);
      return { field, formName, payload, cardState, formErrors }
    }),
    filter(({ formErrors }) => !isCardErrors(formErrors)),
    filter(({ cardState }) => !cardState.confirmationPopupAsian),
    prepareUpdatedFields(state$),
  );

const closeModalEpic = (action$, state$) => action$.pipe(
  ofType(PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_CANCEL),
  switchMap(({ payload: id }) => {
    const card = getCardById(state$.value.price?.trailPrice?.cards, id);
    const formName = swapFormCardName(id);
    const actions = [
      reset(formName),
      swapCardUpdate({
        ...card,
        loading: false,
        isLoading: false,
        confirmationPopupAsian: false,
      }),
    ];
    return from(actions);
  })
)

const approveModalEpic = (action$, state$) => action$.pipe(
  ofType(PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_APPROVE),
  map(({ payload: { id, field = '', payload } }) => {
    const cardState = getCardById(state$.value.price.trailPrice.cards, id);
    const formName = swapFormCardName(id);
    return { formName, field, payload, cardState }
  }),
  prepareUpdatedFields(state$),
)

export default combineEpics(
  changeSizesPerSettlementEpic,
  fillAllSizesPerSettlementEpic,
  updateNotionalEpic,
  startUpdateCardEpic,
  cardUpdateEpic,
  closeModalEpic,
  approveModalEpic
);
