import React, { PureComponent } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import NotifyMeDropdownList from './NotifyMeDropdownList';
import SwitchBtn from 'components/controls/SwitchBtn';
// import { ROUTE_NOTIFICATIONS } from 'pages/constants';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { notificationsToggleAction, notificationsOnlyReadToggleAction, notificationsReadAllAction } from 'redux/actions/notifications';
import { bindActionCreators } from 'redux';
import 'pages/notifications/components/Notifications.scss';
import ReadAllConfirmation from 'components/modal/ReadAllConfirmation';
import './NotifyMe.scss';

const ORDER_SUBJECT_FILLED = 'Filled order';

class NotifyMeDropdown extends PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      dropdownOpen: false,
      offsetRight: null,
      isConfirmation: false
    }
    this.audio = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { counter, list: notifications } = prevProps;
    const { counter: nextCounter, list: nextNotifications } = this.props;
    if (nextCounter > counter
      && notifications
      && notifications.length !== nextNotifications.length
      && nextNotifications && nextNotifications.length
      && nextNotifications[0].subject === ORDER_SUBJECT_FILLED
    ) {
      this.play();
    }
  }

  toggle = e => {
    const { dropdownOpen, isConfirmation } = this.state;
    if(isConfirmation) return null;
    const { notificationsToggle } = this.props;
    if (e && e.currentTarget && typeof e.currentTarget.getBoundingClientRect === 'function') {
      const DOMRect = e.currentTarget.getBoundingClientRect();
      const MODAL_RIGHT_OFFSET = 7;
      const scrollBar = window.innerWidth - document.documentElement.clientWidth;
      const offsetRight = parseFloat(window.innerWidth - DOMRect.right - scrollBar + (DOMRect.width / 2) - MODAL_RIGHT_OFFSET, 2);
      this.setState({ dropdownOpen: !dropdownOpen, offsetRight: offsetRight });
    }
    else {
      this.setState({
        dropdownOpen: !dropdownOpen
      });
    }
    notificationsToggle(!dropdownOpen);
  }

  play = () => {
    if (this.audio && this.audio.current && this.audio.current.paused) {
      this.audio.current.play()
    }
  }

  handleChange = ({ target }) => {
    const { notificationsOnlyReadToggle } = this.props;
    notificationsOnlyReadToggle(target.checked);
  }

  handleReadAll = () => {
    const { notificationsReadAll } = this.props;
    notificationsReadAll();
    this.setState(state => ({
      ...state,
      isConfirmation: false
    }));
  }

  handleIsOpenConfirmation = (status = false) => {
    this.setState(state => ({
      ...state,
      isConfirmation: status
    }));
  }

  render() {
    const { counter, loading, list, showOnlyRead } = this.props;
    const { dropdownOpen, offsetRight, isConfirmation } = this.state;
    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="notification-dropdown-icon tour-btn-notifications">
        <DropdownToggle nav aria-label="Notificatios menu">
          <NotificationsIcon />
        </DropdownToggle>

        <audio ref={this.audio}>
          <source src={require('assets/sounds/coin.mp3').default} type="audio/mp3" />
        </audio>

        {counter > 0 ? <div className="notification-counter">
          <span>
            {counter}
          </span>
        </div> : null}

        <DropdownMenu className="notification-dropdown">
          <span className="dropdown-arrow" style={{ right: `${offsetRight}px` }}></span>
          <div className="notification-dropdown-header">
            <Row>
              <Col>
                <h3>
                  <FormattedMessage id="common/notifications"
                    defaultMessage="Notifications" />
                </h3>
                <ReadAllConfirmation
                  isOpen={isConfirmation}
                  onOpen={this.handleIsOpenConfirmation}
                  handleReadAll={this.handleReadAll}
                  counter={counter}
                />
                <SwitchBtn
                  title="All/Unread"
                  checked={showOnlyRead}
                  onChange={this.handleChange}
                  className="show-only-read-btn"
                />
              </Col>
              {/* <Col className="text-right">
                <Link activeclassname="active" onClick={this.toggle} className="nav-item" to={ROUTE_NOTIFICATIONS}>
                  <FormattedMessage id="common/manage"
                    defaultMessage="Manage" />
                </Link>
              </Col> */}
            </Row>
          </div>
          {dropdownOpen ? <NotifyMeDropdownList list={list} loading={loading} notifyMe={this.props.notifyMe} showOnlyRead={showOnlyRead} /> : null}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const mapStateToProps = createSelector(
  ({ notifications }) => notifications,
  (notifications) => notifications
);

const mapDispatchToProps = dispatch => bindActionCreators({
  notificationsToggle: notificationsToggleAction,
  notificationsOnlyReadToggle: notificationsOnlyReadToggleAction,
  notificationsReadAll: notificationsReadAllAction
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(NotifyMeDropdown);

