import { Fragment } from 'react';
import Modal from 'components/modal/Modal';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const bodyStyle = {
  textAlign: 'center',
  padding: '0 0 40px 0',
};

const Footer = ({ onClose, onRead }) => (
  <div className="text-right">
    <Button color="default" type="button" className="mr-3" onClick={onClose}>
      <FormattedMessage id="common/cancel" defaultMessage="No" />
    </Button>
    <Button type="button" color="primary" onClick={onRead}>
      <FormattedMessage id="common/send-order" defaultMessage="Yes" />
    </Button>
  </div>
);

const ReadAllConfirmation = ({ isOpen, onOpen, handleReadAll, counter }) => {
  const onClick = () => counter > 0 ? onOpen(true) : null;
  const className = ['text-primary', 'read-all-btn'];
  if(counter === 0) className.push('read-all-disable')
  return (
    <Fragment>
      <span
        onClick={onClick}
        className={className.join(' ')}
      >Read All</span>
      <Modal
        isOpen={isOpen}
        title="Read All"
        onToggle={() => onOpen(false)}
        component={<div style={bodyStyle}>Are you sure you want to clear all your notifications?</div>}
        footer={<Footer onClose={() => onOpen(false)} onRead={handleReadAll}/>}
      />
    </Fragment>
  )
}

export default ReadAllConfirmation;
