import { notGreaterThanOrEqualTo, notLessThanOrEqualTo, notValid, presence } from 'components/form/validate';
import { isWeekday } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import validateJs from 'utils/validate.js';
import { PRICING_MINI_CARD_TYPE_FLEX, PRICINIG_MINI_REFERENCE_NUMBER_DEFAUTLT_RANGES } from './constants';

export const WEEK_DAYS = Array.from(new Array(7)).map((_, i) => i);

export const numericalityValidation = (min, max) => {
  return {
    notValid,
    greaterThanOrEqualTo: min,
    notGreaterThanOrEqualTo,
    lessThanOrEqualTo: max,
    notLessThanOrEqualTo,
  }
};

export const filterDayOfWeek = (expiryAllowedWeekDays = WEEK_DAYS, excludeBusinessDays = true) => (date) => {
  const day = date ? date.getDay() : null;
  if (excludeBusinessDays) return isWeekday(date) && expiryAllowedWeekDays.includes(day);
  return expiryAllowedWeekDays.includes(day);
};

export const createDynamicFieldsValdiation = (fields = []) => {
  let validations = {};
  if (Array.isArray(fields)) {
    fields.forEach(field => {
      if (field.type === 'number') {
        const rules = {
          numericality: numericalityValidation(field.min, field.max),
        };

        validations[field.name] = {
          ...rules,
          presence: !field.disabled
        };
      }
    });
  }
  return validations;
};

function validate(data, props) {
  const { fields, type } = props;
  const isFlexible = type === PRICING_MINI_CARD_TYPE_FLEX;

  const dynamicValidations = createDynamicFieldsValdiation(fields);
  let validations = {
    structureExpiryDate: {
      presence: true,
    },
    contractExpiry: {
      presence: true,
    },
    referenceType: {
      presence: true,
    },
    reference: {
      presence,
      numericality: {
        notValid,
        greaterThanOrEqualTo: PRICINIG_MINI_REFERENCE_NUMBER_DEFAUTLT_RANGES.min,
        notGreaterThanOrEqualTo,
        lessThanOrEqualTo: PRICINIG_MINI_REFERENCE_NUMBER_DEFAUTLT_RANGES.max,
        notLessThanOrEqualTo,
      }
    },
  };

  if (isFlexible) {
    validations = {
      ...validations,
      startDate: {
        presence: true,
      },
      endDate: {
        presence: true,
      },
    }
  } else {
    validations = {
      ...validations,
      ...dynamicValidations,
    }
  }

  const validationResult = validateJs(data, validations) || {};

  return validationResult;
}

export default validate;
