import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Col, FormText, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { TYPE_COMPONENT } from '../../fields';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';


const AutocallableFormFields = (props) => {
  const {
    structures,
    currencyOptions,
    maturityOptions,
    frequencyOptions,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    autocallOptions,
    firstObservationInOptions,
    couponTypeOptions,
    memoryCouponOptions,
    barrierTypeOptions,
    solveForOptions,
    couponDirectionOptions,
    formConfig,
    formFields,
    messages,
    change,
  } = props;

  const formFieldsOptions = {
    structures,
    currencyOptions,
    maturityOptions,
    frequencyOptions,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    autocallOptions,
    firstObservationInOptions,
    couponTypeOptions,
    memoryCouponOptions,
    barrierTypeOptions,
    solveForOptions,
    couponDirectionOptions,
  };

  const readOnlyArray =
    formConfig && formConfig.readOnly ? formConfig.readOnly : [];

  return formFields ? (
    <Fragment>
      <Row className="mb-3">
        {formFields.map((field, idx) => {
          const {
            name,
            fieldType,
            type,
            colOptions = { xs: 12 },
            positionOptions,
            optionsName,
            label,
            component: FieldComponent,
            ...rest
          } = field;
          const options =
            optionsName && formFieldsOptions[optionsName]
              ? formFieldsOptions[optionsName]
              : [];
          if (fieldType === TYPE_COMPONENT) {
            return (
              <Col key={name} {...colOptions}>
                <FieldComponent {...field} change={change} />
              </Col>
            );
          }
          const readOnly = readOnlyArray.length
            ? readOnlyArray.includes(name)
            : false;

          return formConfig[name] !== false ? (
            <Fragment key={idx}>
              {positionOptions && !formConfig[positionOptions.relatedField] ? (
                <Col key={name} {...positionOptions}></Col>
              ) : null}
              <Col key={name} {...colOptions}>
                <div className="d-flex align-items-center mb-4 app-form-row">
                  {label ? (
                    <div className="pre-label app-label">{label}</div>
                  ) : null}
                  <div className="form-group-row">
                    <Field
                      name={name}
                      options={options}
                      type={type}
                      component={FieldComponent}
                      {...rest}
                      readOnly={readOnly}
                      change={change}
                    />
                    {messages[name] ? (
                      <FormText
                        color="warning"
                        className="form-control-alert font-sm"
                      >
                        {messages[name]}
                      </FormText>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Fragment>
          ) : null;
        })}
      </Row>
    </Fragment>
  ) : null;
};

AutocallableFormFields.defaultProps = {
  formConfig: {},
  messages: {},
};

AutocallableFormFields.propTypes = {
  formConfig: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
};
const mapDispatchToProps = (dispatch)  => bindActionCreators({ change }, dispatch);

const connector = connect(null, mapDispatchToProps);

export default connector(AutocallableFormFields);
