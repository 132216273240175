import { PRICING_FORM_VANILLA_INITIAL_VALUES } from './constants';
import VanillaForm from './VanillaForm';
import VanillaFormValidate from './VanillaFormValidate';
import { vanillaLimitationSelector } from './vanillaLimitationSelector';

const mapStateToProps = state => {
  const props = vanillaLimitationSelector(state);
  return {
    ...props,
   component: VanillaForm,
   initialValues: PRICING_FORM_VANILLA_INITIAL_VALUES,
   validate: VanillaFormValidate
 }
}

export default mapStateToProps;
