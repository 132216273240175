export const CATEGORIES = [
  'learning',
  'research'
];

export const docs = [
  {
    title: 'Research Africa',
    slug: 'marex_spectron_agresearch_wafrica_210819',
    createdAt: "2019-05-28T13:59:55.720Z",
    parentCat: CATEGORIES[1]
  },
  {
    title: 'Ukraine Weather Research sugar',
    slug: 'marexspectron_brazil_weather_research_coffee_220819',
    createdAt: "2019-08-28T13:59:55.720Z",
    parentCat: CATEGORIES[1]
  },
  {
    title: 'Brazil Weather Research coffee',
    slug: 'marexspectron_brazil_weather_research_coffee_220819',
    createdAt: "2019-08-28T13:59:55.720Z",
    parentCat: CATEGORIES[1]
  },
  {
    title: 'UK Weather Research lumber',
    slug: 'marexspectron_brazil_weather_research_coffee_220819',
    createdAt: "2019-08-28T13:59:55.720Z",
    parentCat: CATEGORIES[0]
  },
  {
    title: 'Brazil Rainfall and Soil Moisture Sugar',
    slug: 'marexspectron_brazil_rainfall_and_soil_moisture_sugar_210819',
    createdAt: "2019-07-28T13:59:55.720Z",
    parentCat: CATEGORIES[0]
  },
  {
    title: 'UK Weather Learning lumber',
    slug: 'marexspectron_brazil_weather_research_coffee_220819',
    createdAt: "2019-08-28T13:59:55.720Z",
    parentCat: CATEGORIES[0]
  },
];

export const DOCS = {
  [CATEGORIES[0]]: docs.filter(doc => doc.title.indexOf('Researc') < 0),
  [CATEGORIES[1]]: docs.filter(doc => doc.title.indexOf('Researc') >= 0),
};

export const CHILDREN_CATEGORIES = {
  [CATEGORIES[0]]: [
    {
      title: 'Commodities',
      slug: 'commodities',
      childs: [
        {
          title: 'Agriculture',
          slug: 'agriculture',
          childs: [
            {
              title: 'Grains',
              slug: 'grains',
              childs: [
                {
                  title: 'Corn BMF',
                  slug: 'corn_bmf',
                },
                {
                  title: 'Corn CBOT',
                  slug: 'corn_cbot',
                },
                {
                  title: 'Raperseed Matif',
                  slug: 'raperseed_matif',
                },
                {
                  title: 'Wheat Chicago',
                  slug: 'wheat_chicago',
                },
              ]
            },
            {
              title: 'Oilseed',
              slug: 'oilseed',
            },
            {
              title: 'Soft',
              slug: 'soft',
            },
            {
              title: 'Base Metals',
              slug: 'base_metals',
            },
            {
              title: 'Cash',
              slug: 'cash',
            },
            {
              title: 'Default',
              slug: 'default',
            },
            {
              title: 'Energy',
              slug: 'energy',
              childs: [
                {
                  title: 'Crude Oil',
                  slug: 'сrude_oil',
                  childs: [
                    {
                      title: 'Brent',
                      slug: 'brent'
                    },
                    {
                      title: 'Dated Brent',
                      slug: 'dated_brent'
                    },
                    {
                      title: 'WTI',
                      slug: 'wti'
                    },
                  ]
                },
                {
                  title: 'Distilliates',
                  slug: 'distilliates',
                  childs: [
                    {
                      title: 'Gasoline RBOB',
                      slug: 'gasoline rbob'
                    },
                    {
                      title: 'Heating Oil',
                      slug: 'heating_oil'
                    },
                    {
                      title: 'WTI',
                    },
                  ]
                },
                {
                  title: 'EUA',
                  slug: 'eua',
                },
                {
                  title: 'Gas',
                  slug: 'gas',
                }
              ]
            }
          ]
        },
      ]
    },
  ],
  [CATEGORIES[1]]: [
    { title: 'structures', slug: 'structures' },
    { title: 'reports', slug: 'reports' },
    { title: 'forecasts', slug: 'forecasts' },
  ]
};

const cats = [];

Object.keys(CHILDREN_CATEGORIES).forEach((key) => {
  [...CHILDREN_CATEGORIES[key]].forEach(({ slug }) => {
    cats.push(slug);
  });
})

export const ALL_CATEGORIES = [
  ...CATEGORIES,
  ...cats
]
