import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Loader from 'components/common/loader/Loader';
import { PRICING_FORM, submitForm } from 'redux/actions/price';
import { fieldValidationParamsSelector } from './dynamicValidation';
import priceFormChooser from './PriceFormChooser';

export const fvs = formValueSelector(PRICING_FORM);

const mapDispatchToProps = dispatch => bindActionCreators({
  onSubmit: submitForm,
}, dispatch);

export const priceSelector = createSelector(
  [state => state.price],
  ({ isLoading, underlying, currentFormConfig }) => ({
    underlying,
    isLoading,
    validationParams: fieldValidationParamsSelector(currentFormConfig),
  })
);

const mapStateToProps = (state, ownProps) => {
  const structure = ownProps.structure;
  return {
    structure,
    ...priceSelector(state),
    ...priceFormChooser(structure, state, ownProps)
  };
};

class PriceFormType extends PureComponent {

  constructor(args) {
    super(args);
    this.state = {
      structure: null
    }
  }

  render() {
    const { component: PriceForm, isLoading, structure, swapType, ...rest } = this.props;
    return (structure ?
      <Fragment>
        <PriceForm structure={structure} swapType={swapType} {...rest} />
        {isLoading ?
          <Fragment>
            <Loader className="loader-center" />
            <div className="loader-overlay"></div>
          </Fragment>
          : null
        }
      </Fragment>
      : null);
  }
}

PriceFormType.propTypes = {
  structure: PropTypes.string.isRequired,
  structures: PropTypes.array.isRequired,
  component: PropTypes.any,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: PRICING_FORM,
    enableReinitialize: true
  })
)(PriceFormType);
