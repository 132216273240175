import { from } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { pricingResultsLoader, setStaticCursorData } from 'redux/actions/price';
import { PRICING_SWAP_DATA_LOADED, swapCardsUpdate } from 'redux/actions/swap';
import { loadAsianSwapCards } from 'redux/queries/asianSwap';
import { swapTrailPricesFilter } from './../../swap';
import { SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';
import { PRICING_ASIAN_SWAP_CARDS_FETCH_MORE } from 'redux/actions/asianSwap';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { STRUCTURE_SWAP } from 'constants.js';


export const loadAsianSwapCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_DATA_LOADED),
      filter(({ payload }) => payload && !isEmpty(payload.pricings) && !isEmpty(payload.swapType) && payload.swapType === SWAP_ASIAN),
      switchMap(() => {
        const limit = state$.value.auth.account.cardsLimit
        return from(loadAsianSwapCards(null , limit)).pipe(
          switchMap(data => {
            const { structure, swapType } = priceFormValueSelector(state$.value) || {};
            if(structure === STRUCTURE_SWAP && swapType === SWAP_ASIAN) {
              const actions = [
                swapCardsUpdate(data),
                setStaticCursorData(data),
                pricingResultsLoader(false),
              ];
              return from(actions);
            }
            return from([]);
          })
        )
      })
    );

export const fetchMoreAsianSwapCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_ASIAN_SWAP_CARDS_FETCH_MORE),
      filter(({ payload }) => !!payload && !!state$.value.price && !!state$.value.price.trailPrice && !!state$.value.price.trailPrice.pageInfo),
      filter(() => swapTrailPricesFilter(state$)),
      map(({ payload }) => payload),
      switchMap(({cursor, limit}) => {
        return from(loadAsianSwapCards(cursor, limit)).pipe(
          filter(data => data && Array.isArray(data.cards)),
          switchMap(data => {
            const oldCards = state$.value.price.trailPrice && state$.value.price.trailPrice.cards ? state$.value.price.trailPrice.cards : [];
            const actions = [
              swapCardsUpdate({
                ...data,
                cards: uniqBy([
                  ...oldCards,
                  ...data.cards,
                ], 'id')
              }),
              pricingResultsLoader(false),
            ];
            return from(actions).pipe(
              delay(180) //Animation time
            );
          })
        )
      })
    );

export default combineEpics(
  loadAsianSwapCardsEpic,
  fetchMoreAsianSwapCardsEpic,
);
