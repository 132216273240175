import React, { memo } from 'react';
import InlineInput from 'components/form/InlineInput';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import isNumber from 'lodash/isNumber';
import { ROUTE_PRICING_ORDERS_VANILLA } from 'pages/constants';
import { VANILLA_STRUCTURE_TYPES } from 'pages/price/PriceForm/forms/vanilla/constants';
import { optionsProvider } from 'pages/price/PriceForm/utils';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { Field, Form, getFormValues, reduxForm, change } from 'redux-form';
import { orderInit } from 'redux/actions/orders';
import './vanilla.scss';
import validate from './VanillaPricingOutputFormValidate';

const currencyChooser = ({ quotedCurrency }) => {
  return optionsProvider([quotedCurrency]);
}

function VanillaPricingOutputForm(props) {
  const { direction, id, expiryOptions, handleSubmit, invalid, isLoading, history, orderInit, change } = props;

  const trade = () => {
    orderInit({ id });
    history.push(ROUTE_PRICING_ORDERS_VANILLA);
  }

  const isDisabled = !expiryOptions || !expiryOptions.length;
  const currencies = currencyChooser(props);

  const contractExpiryHandler = (contractExpiry) => {
    const { bloombergTicker = null, strikeLevel = null } = expiryOptions.find(({ value }) => value === contractExpiry) || {}

    change("bloombergTicker", bloombergTicker);
    if(strikeLevel) change("strikeLevel", strikeLevel);
  }

  return (
    <Form id={`form-card-vanilla-${id}`} className="form-vanilla" onSubmit={handleSubmit} noValidate>
      <Row className="row-size-xs">
        <Col xs={6}>
          <Field name={`structureType-${id}`}
            label={<FormattedMessage id="form.price.fields.type.label"
              defaultMessage="Type" />}
            justValue={true}
            component={RadioGroup}
            options={VANILLA_STRUCTURE_TYPES}
          />
        </Col>
        <Col xs={6} className="feedback-right">
          <Field name="strikeLevel"
            label={<FormattedMessage id="form.price.fields.strikeLevel.label"
              defaultMessage="Strike Level" />}
            type="number"
            step="1"
            component={InlineInput}
          />
        </Col>
        <Col xs={6}>
          <Field name="payoutCurrency"
            label={<FormattedMessage id="form.price.fields.payout-currency.label"
              defaultMessage="Currency" />}
            justValue={true}
            component={InlineSelectInput}
            options={currencies}
          />
        </Col>
        <Col xs={6}>
          {expiryOptions && expiryOptions.length ?
            <Field name="contractExpiry"
              label="Contract Expiry"
              component={InlineSelectInput}
              clearable={false}
              justValue={true}
              disabled={!expiryOptions.length}
              options={expiryOptions}
              onChange={contractExpiryHandler}
            />
            : <span className="form-control-label">There are no vanilla available at the moment</span>
          }
        </Col>
        <Col xs={6}>
          <Field name="quantity"
            type="number"
            label={"Total Size"}
            placeHolder="Total Size"
            step="1"
            disabled={isDisabled}
            component={InlineInput}
          />
        </Col>
        <Col xs={6}>
          <Button type="button"
            className={"w-100 px-1 btn-input-h m-t-label text-uppercase" + (direction === 'buyer' ? ' btn-buy' : ' btn-sell')}
            onClick={trade}
            disabled={isLoading || isDisabled || invalid}>
            {
              !invalid && !isDisabled && isLoading ?
                'Loading...' :
                (direction === 'buyer' ? 'Request Offer' : 'Request Bid')
            }
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

VanillaPricingOutputForm.propTypes = {
  expiryOptions: PropTypes.array.isRequired,
  direction: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.any
};

export const vanillaFormCardName = id => `${PRICINIG_VANILLA_FORM_OUTPUT}_${id}`;
export const vanillaFormValuesSelector = (id, state) => getFormValues(vanillaFormCardName(id))(state);

export const PRICINIG_VANILLA_FORM_OUTPUT = '@PRICINIG/VANILLA/FORM_OUTPUT';

const mapStateToProps = (state, ownProps) => {
  const { bloombergTicker, quotedCurrency } = ownProps;
  const contractExpiry = ownProps && ownProps.contractExpiry ?
    ownProps.contractExpiry :
    (ownProps.expiryOptions && ownProps.expiryOptions.length ? ownProps.expiryOptions[0].value : null);

  const quantity = !isNaN(parseFloat(ownProps.quantity)) ? ownProps.quantity : 1;
  const structureType = ownProps.structureType ? ownProps.structureType : 'Call';
  const strikeLevel = isNumber(ownProps.strikeLevel) ? ownProps.strikeLevel : '';
  return {
    form: vanillaFormCardName(ownProps.id),
    initialValues: {
      currency: quotedCurrency,
      quantity,
      contractExpiry,
      strikeLevel,
      bloombergTicker,
      [`structureType-${ownProps.id}`]: structureType
    }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ orderInit, change }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ enableReinitialize: false, validate }),
  withRouter,
  memo,
)(VanillaPricingOutputForm);
