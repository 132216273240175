import { createSelector } from 'reselect';
import { structuredProductsFormFieldsSelector } from '../autocallable/autocallableFormProps';
import { mapStateToPropsUnderlyings } from '../../underlyings/Underlyings';
import CapitalProtectionFormFields from './CapitalProtectionFormFields';
import CapitalProtectionFormValidate from './CapitalProtectionFormValidate';
import { PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES } from './constats.js';

export const structuresSelector = createSelector(
  [state => state.structuredProducts.structures],
  structures => structures
);

const capitalProtectionFormProps = (state, formOptions) => {
  const structures = structuresSelector(state);
  const issuerOptions = formOptions && formOptions['issuer'] ? formOptions['issuer'] : [];
  const legalShapeOptions = formOptions && formOptions['legalShape'] ? formOptions['legalShape'] : [];
  const floatingCurveOptions = formOptions && formOptions['floatingCurve'] ? formOptions['floatingCurve'] : [];
  const currencyOptions = formOptions && formOptions['currency'] ? formOptions['currency'] : [];
  const maturityOptions = formOptions && formOptions['maturity'] ? formOptions['maturity'] : [];
  const formFields = structuredProductsFormFieldsSelector(state);
  const underlyingsFields = mapStateToPropsUnderlyings(state)?.fields || [];
  const messages = {};
  const basketTypeOptions = formOptions && formOptions['basketType'] ? formOptions['basketType'] : [];
  const optionTypeOptions = formOptions && formOptions['optionType'] ? formOptions['optionType'] : [];
  const capTypeOptions = formOptions && formOptions['capType'] ? formOptions['capType'] : [];
  const barrierTypeOptions = formOptions && formOptions['barrierType'] ? formOptions['barrierType'] : [];

  return {
    structures,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    currencyOptions,
    maturityOptions,
    component: CapitalProtectionFormFields,
    initialValues: PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES,
    validate: CapitalProtectionFormValidate,
    formFields,
    underlyingsFields,
    messages,
    basketTypeOptions,
    optionTypeOptions,
    capTypeOptions,
    barrierTypeOptions,
  }
}

export default capitalProtectionFormProps;
