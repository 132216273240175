import { COMMON_ORDERS_SET_INITIAL_STATE, COMMON_ORDERS_SET_CURRENT, COMMON_ORDERS_REFETCH, COMMON_ORDERS_NEW, COMMON_ORDERS_RESET_CURRENT } from 'redux/actions/ordersCommon';

const COMMON_ORDERS_INIT_STATE = {
  current: null,
  update: null,
  newOrder: null
}

export default function commonOrderReducer(state = COMMON_ORDERS_INIT_STATE, action) {
  try {
    switch (action.type) {
      case COMMON_ORDERS_SET_INITIAL_STATE:
        return { ...COMMON_ORDERS_INIT_STATE }
      case COMMON_ORDERS_SET_CURRENT:
        return { ...state, current: action.payload }
      case COMMON_ORDERS_RESET_CURRENT:
        return { ...state, current: null }
      case COMMON_ORDERS_REFETCH:
        return { ...state, update: action.payload }
      case COMMON_ORDERS_NEW:
        return { ...state, newOrder: action.payload }
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
