import type { Action } from 'redux';
import {
  MINIFUTURE_CARDS_UPDATE,
  MINIFUTURE_CARD_CREATE, MINIFUTURE_CARD_DELETE,
  MINIFUTURE_CARD_UPDATE, MINIFUTURE_DATA_LOADED,
  MINIFUTURE_CARD_REFETCH
} from 'redux/actions/minifuture';
import { swapAddCard, swapRemoveCard, swapUpdateCard } from './price';

export type IPriceReducerState = {
  pricings: Object[],
  trailPrice: Object | null,
  refetched: number,
}

const PRICING_INIT_STATE: IPriceReducerState = {
  pricings: [],
  trailPrice: null,
  refetched: 0,
};

export default function minifutureReducer(state: IPriceReducerState = PRICING_INIT_STATE, action: Action): IPriceReducerState {
  try {
    switch (action.type) {
      case MINIFUTURE_DATA_LOADED:
        return { ...state, ...action.payload };
      // Multiple
      case MINIFUTURE_CARD_CREATE:
        return { ...state, trailPrice: swapAddCard(state.trailPrice, action.payload) }
      case MINIFUTURE_CARDS_UPDATE:
        return { ...state, trailPrice: action.payload }
      case MINIFUTURE_CARD_UPDATE:
          return { ...state, trailPrice: swapUpdateCard(state.trailPrice, action.payload) }
      case MINIFUTURE_CARD_DELETE:
        return { ...state, trailPrice: swapRemoveCard(state.trailPrice, action.payload) }
      case MINIFUTURE_CARD_REFETCH:
          return { ...state, refetched: state.refetched + 1 }
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
