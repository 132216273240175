import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const StyledSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -8,
    marginLeft: -8,
    transition: 'background 0.2s ease-in-out',
    '&:focus,&:hover': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  valueLabel: {
    left: '-40%',
    top: -24,
    borderRadius: '2px',
    transition: 'background 0.2s ease-in-out',
    '& *': {
      // background: '#1D252E',
      // color: '#ffffff',
      // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
      // color: '#fff',
      // transform: 'rotate(0)',
      // borderRadius: '2px',
      transition: 'background 0.2s ease-in-out',
      'width': '28px',
      'height': '28px',
      'padding': '8px 0',
      'white-space': 'nowrap',
      'font-size': '10px',
      'text-align': 'center',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#337bc0',
    height: 4,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

export default StyledSlider;
