import { STRUCTURE_TWIN_WIN } from 'constants.js';
import { BARRIER_TYPE_AMERICAN } from 'pages/price/FormItem';
import { PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/autocallable/constats';

export const TWIN_WIN_STRIKE_LEVEL_MIN = 50;
export const TWIN_WIN_STRIKE_LEVEL_MAX = 100;

export const PRICING_FORM_TWIN_WIN_INITIAL_VALUES = {
  ...PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES,
  barrierType: BARRIER_TYPE_AMERICAN,
  structure: STRUCTURE_TWIN_WIN,
  noCouponPaidAtMaturity: true,
};
