import apolloClient from 'apollo.js';
import gql from 'graphql-tag';
import store from 'store.js';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import { mapNodes } from 'utils/';
import { pricingResultsLoader } from 'redux/actions/price';
import { STRUCTURE_VANILLA, DEFAULT_LIMIT_FOR_LOAD_CARDS  } from 'constants.js';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

export const VANILLA_LOAD_FORM_DATA = gql`query loadAllDataVanilla {
  pricingVanillaContractsConnection {
    structureStatus
    commodities {
      commodity
      commodityCode
      quotedCurrency
    }
  }
}`;

export const loadVanillaData = async () => {
  store.dispatch(pricingResultsLoader(true));
  try {
    return await apolloClient.query({
      query: VANILLA_LOAD_FORM_DATA,
      fetchPolicy: 'cache-first',
    })
      .then((res) => {
        const data = res.data && res.data.pricingVanillaContractsConnection;
        return Array.isArray(data.commodities) && data.commodities;
      });
  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_VANILLA));
    store.dispatch(pricingResultsLoader(false));
    console.log(e);
  }
}


const PRICING_VANILLA_CALCULATE_PRICE_MUTATION = gql`mutation pricingVanillaCalculatePriceMutation(
  $structure: String!
  $direction: PricingVanillaDirectionEnumType!
  $commodityCode: String!
  $commodityContract: String!
  $contractExpiry: String!
  $strike: Float!
  $type: PricingVanillaTypeEnum!
  $bloombergTicker: String
  $quotedCurrency: String!
) {
  pricingVanillaCalculatePrice (
    structure: $structure
    direction: $direction
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    contractExpiry: $contractExpiry
    strike: $strike
    type: $type
    bloombergTicker: $bloombergTicker
    quotedCurrency: $quotedCurrency
  ) {
    id
    price
    contractCode
    priceForCalcSpread
  }
}`;

export const createVanillaCardMutation = (structure,
  {
    direction,
    commodityCode,
    commodityContract,
    contractExpiry,
    strikeLevel: strike,
    structureType: type,
    bloombergTicker,
    quotedCurrency
  }) => {
  return apolloClient.mutate({
    mutation: PRICING_VANILLA_CALCULATE_PRICE_MUTATION,
    variables: {
      structure,
      direction,
      commodityCode,
      commodityContract,
      contractExpiry,
      strike,
      type,
      bloombergTicker,
      quotedCurrency
    },
  })
    .then(response => response.data.pricingVanillaCalculatePrice);
}

const PRICING_VANILLA_UPDATE_PRICE_MUTATION = gql`mutation pricingVanillaCalculatePriceUpdateMutation(
  $id: String!
  $structure: String!
  $direction: PricingVanillaDirectionEnumType!
  $commodityCode: String!
  $commodityContract: String!
  $contractExpiry: String!
  $strike: Float!
  $type: PricingVanillaTypeEnum!
  $bloombergTicker: String
) {
  pricingVanillaCalculatePriceUpdate (
    id: $id,
    structure: $structure
    direction: $direction
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    contractExpiry: $contractExpiry
    strike: $strike
    type: $type
    bloombergTicker: $bloombergTicker
  ) {
    id
    price
    contractCode
    priceForCalcSpread
  }
}`;

export const updateVanillaCardMutation = (
  structure,
  {
    id,
    direction,
    contractCode,
    commodityCode,
    commodityContract,
    contractExpiry,
    quantity = 1,
    quotedCurrency,
    structureType: type,
    strikeLevel: strike,
    bloombergTicker,
  }) => {
  const variables = {
    structure,
    id,
    direction,
    contractCode,
    commodityCode,
    commodityContract,
    contractExpiry,
    quantity,
    quotedCurrency,
    type,
    strike,
    bloombergTicker,
  };
  return apolloClient.mutate({
    mutation: PRICING_VANILLA_UPDATE_PRICE_MUTATION,
    variables,
  })
    .then(response => response.data.pricingVanillaCalculatePriceUpdate);
}

const PRICING_VANILLA_DELETE_PRICE_MUTATION = gql`mutation pricingVanillaCalculatePriceDeleteMutation(
  $id: String!
) {
  pricingVanillaCalculatePriceDelete (
    id: $id
  )
}`;

export const deleteVanillaCardMutation = id => {
  return apolloClient.mutate({
    mutation: PRICING_VANILLA_DELETE_PRICE_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

const PRICINIG_VANILLA_HISTORY = gql`query pricingVanillaHistoryQuery (
  $cursor: String
  $limit: Int
) {
  pricingVanillaHistoryConnection (
    after: $cursor
    first: $limit
  ) {
    edges {
      node{
        id
        quantity
        direction
        contractCode
        quotedCurrency
        contractExpiry
        price
        structure
        commodityContract
        commodityCode
        limit
        createdAt
        updatedAt
        strikeLevel: strike
        structureType: type
        bloombergTicker
        swapContractCode
        priceForCalcSpread
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    },
  }
}`;

export const loadVanillaCardsData = async (cursor = null, limit) => {
  try {
    let resp = await apolloClient.query({
      query: PRICINIG_VANILLA_HISTORY,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        cursor,
        limit: limit || DEFAULT_LIMIT_FOR_LOAD_CARDS
      }
    })
      .then((res) => {
        const cards = mapNodes(res.data.pricingVanillaHistoryConnection);
        const pageInfo = res.data.pricingVanillaHistoryConnection.pageInfo;
        return { cards, pageInfo };
      });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_VANILLA));
    console.log(e);
  }
}
