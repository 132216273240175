import React from 'react';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import Bundle from 'pages/Bundle';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const MockedDashboard = props => (
  <ErrorBoundary>
    <PageMeta title="Dashboards" />
    <Bundle load={() => import('./MockedDashboard')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default MockedDashboard;
