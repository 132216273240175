import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { createAlert } from 'react-redux-alerts';
import {
  PRICING_FAIL_LOAD_PRODUCTS,
  PRICING_FAIL_CALCULATE_FIELD,
  TRAIL_SELECT_FAIL,
  NOTIFICATION_IS_EXPIRED,
  PRICING_FAIL_FORM_LOAD_ALL_DATA,
  SIMPLE_ERROR,
  EXPIRATION_PASSWORD,
} from 'redux/alerts/actions';
import { Alert } from 'reactstrap';
import Warning from '@material-ui/icons/Warning';

const AlertIcon = () => <div className="alert-icon">
  <Warning />
</div>;

const Alerts = _ => {
  return (
    <Fragment>
      <PriceFailLoadProductsAlert />
      <PriceFailCalulateFieldAlert />
      <PriceHistorySelectTrailBad />
      <NotificationExpiredAlert />
      <PriceFromFailLoadAllData />
      <SimpleAlert />
      <ExpirationPasswordAlert />
    </Fragment>
  );
};

const PriceFailLoadProductsAlert = createAlert({
  alertName: PRICING_FAIL_LOAD_PRODUCTS
})(({ close }) => (
  <Alert color="danger" toggle={close}>
    <AlertIcon />
    <FormattedMessage id="alert/price-fail-load-products-alert"
      defaultMessage="Product does'n exist" />
  </Alert>
));

const PriceFailCalulateFieldAlert = createAlert({
  alertName: PRICING_FAIL_CALCULATE_FIELD
})(({ close, message }) => (
  <Alert color="danger" toggle={close}>
    <AlertIcon />
    <FormattedMessage id="alert/price-fail-calculate-field"
      defaultMessage="The {fieldName} cannot be calculated, please adjust your input."
      values={message}
    />
  </Alert>
));

const PriceHistorySelectTrailBad = createAlert({
  alertName: TRAIL_SELECT_FAIL,
})(({ close, message }) => (
  <Alert color="info" toggle={close}>
    <AlertIcon />
    <FormattedMessage id="alert/pricing-is-expired"
      defaultMessage="Pricing is expired."
      values={message}
    />
  </Alert>
));

const NotificationExpiredAlert = createAlert({
  alertName: NOTIFICATION_IS_EXPIRED,
})(({ close }) => (
  <Alert color="info" toggle={close}>
    <AlertIcon />
    <FormattedMessage id="alert/notification-is-expired"
      defaultMessage="Notification is expired."
    />
  </Alert>
));

const PriceFromFailLoadAllData = createAlert({
  alertName: PRICING_FAIL_FORM_LOAD_ALL_DATA,
})(({ close, message }) => (
  <Alert color="danger" toggle={close}>
    <AlertIcon />
    <FormattedMessage id="alert/price-load-all-fail"
      defaultMessage="Can't load all {structure} products, please contact us."
      values={message}
    />
  </Alert>
));

const SimpleAlert = createAlert({
  alertName: SIMPLE_ERROR,
})(({ close, message }) => {
  return (
    <Alert color="danger" toggle={close}>
      <AlertIcon />
      {message}
    </Alert>
  )
});

const ExpirationPasswordAlert = createAlert({
  alertName: EXPIRATION_PASSWORD,
})(({ close, message }) => {
  return (
    <Alert style={{ "min-width": "560px" }} color="warning" toggle={close}>
      <AlertIcon />
      <FormattedMessage id="alert/expiration-warning"
        defaultMessage={message}
        values={message}
      />
    </Alert>
  )
});

export default Alerts;
