import {
  COMODITY_CONTRACTS_OPTIONS_QUERY,
  PRICING_TYPE_OPTIONS_QUERY,
  PRICING_BARRIER_TYPE_OPTIONS_QUERY,
  PRICING_REMAINDER_FEATURE_OPTIONS_QUERY,
  PRICING_LEVERAGE_STYLE_OPTIONS_QUERY,
  PRICING_LEVERAGE_OPTIONS_QUERY,
  PRICING_ACCUMULATION_STYLE_OPTIONS_QUERY,
} from './query';
import { SORT_FIELDS } from './sorter';
import orderBy from 'lodash/orderBy';

const FILTERS_LIST_PRICING_HISTORY_ARRAY = [
  {
    'name': 'updatedAt', //Date
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'placeHolder': 'Last Changes',
    'isVisible': false,
  },
  // {
  //   'name': 'structure',
  //   'filterType': 'String',
  //   'type': 'select',
  //   'placeHolder': 'Structure',
  //   'query': STRUCTURES_OPTIONS_QUERY,
  //   'queryResp': 'pricingHistoryStructure',
  //   'isVisible': false,
  // },
  {
    'name': 'type',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Type',
    'query': PRICING_TYPE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryType',
    'isVisible': false,
  },
  {
    'name': 'commodityContract',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Commodity Contract',
    'query': COMODITY_CONTRACTS_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryCommodityContract',
    'isVisible': false,
  },
  {
    'name': 'accumulationStyle',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'AccumulationStyle',
    'query': PRICING_ACCUMULATION_STYLE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryAccumulationStyle',
    'isVisible': false,
  },
  // {
  //   'name': 'currency',
  //   'filterType': 'String',
  //   'type': 'select',
  //   'placeHolder': 'Currency',
  //   'query': PRICING_CURRECNY_OPTIONS_QUERY,
  //   'queryResp': 'pricingHistoryQuotedCurrency'
  // },
  {
    'name': 'barrierType',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Barrier Type',
    'query': PRICING_BARRIER_TYPE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryBarrierType',
    'isVisible': false
  },
  {
    'name': 'remainderFeature',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Remainder Feature',
    'query': PRICING_REMAINDER_FEATURE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryRemainderFeature',
    'isVisible': false
  },
  {
    'name': 'contractExpiry',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'placeHolder': 'Contract Expiry',
    'isVisible': false
  },
  {
    'name': 'leverageStyle',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Leverage Style',
    'query': PRICING_LEVERAGE_STYLE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryLeverageStyle',
    'isVisible': false
  },
  {
    'name': 'leverage',
    'filterType': 'String',
    'type': 'select',
    'placeHolder': 'Leverage',
    'query': PRICING_LEVERAGE_OPTIONS_QUERY,
    'queryResp': 'pricingHistoryLeverage',
    'isVisible': false
  },
];

export const FILTERS_LIST_PRICING_HISTORY = orderBy(FILTERS_LIST_PRICING_HISTORY_ARRAY, 'name');

export const DISPLAY_COLUMNS_PRICING_HISTORY = SORT_FIELDS
  .filter(({ displayAllways }) => !displayAllways);
