import { DEFAULT_THEME } from 'redux/reducers/theme/templates/agile';
import { createSelector } from 'reselect';

export const themeSelector = createSelector(
  state => state.theme,
  theme => theme || {},
);

export const themeImagesSelector = createSelector(
  themeSelector,
  ({ images }) => images || {},
);

export const themeStylesSelector = createSelector(
  themeSelector,
  ({ styles } = {}) => ({ styles: styles || null }),
);

export const themeTextsSelector = createSelector(
  themeSelector,
  ({ texts }) => texts || null
);

export const themeMetaDataSelector = createSelector(
  themeSelector,
  ({ meta }) => meta || null
);

export const themeNameSelector = createSelector(
  themeSelector,
  ({ name }) => name || null
);

export const isThemeSelector = createSelector(
  themeSelector,
  ({ name }) => ({ isTheme: name !== DEFAULT_THEME }),
);
