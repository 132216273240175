import React, { PropsWithChildren } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { UncontrolledTooltip } from "reactstrap";

interface DynamicFieldRemoveProps {
  index: number;
  name: string;
  onRemove: (index: number) => void;
  show: boolean;
}

const DynamicFieldRemove: React.FC<
  PropsWithChildren<DynamicFieldRemoveProps>
> = ({ index, name, children, onRemove, show }) => {
  const remove = () => {
    onRemove(index);
  };

  return name ? (
    <div className="fileds-toggle-group">
      {children}
      {show ? (
        <>
          <button
            id={`btn-remove-${name}-${index}`}
            type="button"
            className="btn remove"
            onClick={remove}
          >
            <CloseIcon />
          </button>
          {/*@ts-ignore*/}
          <UncontrolledTooltip
            placement="top"
            target={`btn-remove-${name}-${index}`}
          >
            Remove
          </UncontrolledTooltip>
        </>
      ) : null}
    </div>
  ) : null;
};

export default DynamicFieldRemove;
