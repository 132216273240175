import { INPUT_NUMBER_MAX_VALUE, INPUT_NUMBER_MAX_VALUE_LARGE, STRUCTURE_TWIN_WIN } from 'constants.js';
import { formRadioValue } from 'redux/epics/structured-products/form/utils';
import validateJs from 'utils/validate.js';
import validateMessages from 'utils/validate.messages';
import { AUTOCALL_TRIGGER_LEVEL_MAX, AUTOCALL_TRIGGER_LEVEL_MIN, AUTOCALL_TRIGGER_LEVEL_MIN_DEFAULT, BARRIER_LEVEL_MIN, COUPON_LEVEL_MAX, COUPON_LEVEL_MIN, COUPON_TRIGGER_LEVEL_MAX, COUPON_TRIGGER_LEVEL_MIN, REOFFER_MAX, REOFFER_MIN, STRIKE_LEVEL_MAX, STRIKE_LEVEL_MIN } from './constats';
import { TWIN_WIN_STRIKE_LEVEL_MIN, TWIN_WIN_STRIKE_LEVEL_MAX } from './../twinWin/constants';

export const dynamicValidationAutocallTriggerLevel = (formData = {}, min = AUTOCALL_TRIGGER_LEVEL_MIN_DEFAULT, max = AUTOCALL_TRIGGER_LEVEL_MAX) => {
  const { autocall, barrierLevel, strikeLevel } = formData;
  const barrierLevelValue = parseFloat(barrierLevel);
  const strikeLevelValue = parseFloat(strikeLevel);
  const minAutocallTriggerLevelFormValue = barrierLevelValue || strikeLevelValue;
  const autocallBoolean = formRadioValue(autocall);
  const minAutocallTriggerLevel = autocallBoolean ? minAutocallTriggerLevelFormValue : min;
  return { min: minAutocallTriggerLevel || 0, max };
}

export const dynamicValidationBarrierLevel = (formData = {}, min = BARRIER_LEVEL_MIN) => {
  const { barrierLevel, strikeLevel } = formData;
  const barrierLevelValue = parseFloat(barrierLevel);
  const strikeLevelValue = parseFloat(strikeLevel);
  const barrierLevelMin = barrierLevelValue === 1 ? 1 : min;
  const barrierLevelMax = strikeLevelValue >= BARRIER_LEVEL_MIN && strikeLevelValue <= STRIKE_LEVEL_MAX ? strikeLevelValue : STRIKE_LEVEL_MAX;
  return { min: barrierLevelMin, max: barrierLevelMax };
}

export const getIssuerMaxCouponLevel = (issuerOptions = [], issuer) => {
  const { max } = issuerOptions.find(({ value }) => (value === issuer)) || { max: COUPON_LEVEL_MAX };
  return max;
}

export const getFieldMaxCouponLevel = (formFields = []) => {
  const { max } = (Array.isArray(formFields) && formFields.find(({ name }) => name === 'couponLevel')) || { max: COUPON_LEVEL_MAX };
  return max;
}

const validationCouponLevel = props => {
  const { formFields = [] } = props;
  const max = getFieldMaxCouponLevel(formFields);
  return {
    presence: {
      message: `^Coupon Level (per annum) ${validateMessages.blank}`
    },
    numericality: {
      greaterThanOrEqualTo: COUPON_LEVEL_MIN,
      lessThanOrEqualTo: max,
    },
    maxDecimalDigits: { max: 3 },
  };
}

const dynamicValidationsCreate = (formData, props = {}) => {
  let valdiations = {};
  const { underlyingsFields = [] } = props;
  const { autocall, barrierType, structure, barrierLevel } = formData;

  if (barrierType && barrierType.toLowerCase() !== 'none') {
    const { min: minBarrierLevel, max: maxBarrierLevel } = dynamicValidationBarrierLevel(formData) || {};
    valdiations['barrierLevel'] = {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: minBarrierLevel,
        lessThanOrEqualTo: maxBarrierLevel,
      },
      maxDecimalDigits: true,
    };
  }

  const { min: minAutocallTriggerLevel, max: maxAutocallTriggerLevel } = dynamicValidationAutocallTriggerLevel(formData) || {};
  const autocallBoolean = formRadioValue(autocall);

  if (autocallBoolean) {
    valdiations['autocallTriggerLevel'] = {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: minAutocallTriggerLevel,
        lessThanOrEqualTo: maxAutocallTriggerLevel,
      },
      maxDecimalDigits: { max: 3 },
    };
  }

  if (underlyingsFields && underlyingsFields.length) {
    valdiations[underlyingsFields[0].name] = {
      presence: {
        message: `^Underlying ${validateMessages.blank}`,
      }
    }
  }

  valdiations['couponLevel'] = validationCouponLevel(props);

  if(structure === STRUCTURE_TWIN_WIN) {
    const barrierLevelValue = parseFloat(barrierLevel);
    valdiations['strikeLevel'] = {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: barrierLevelValue > TWIN_WIN_STRIKE_LEVEL_MIN ? barrierLevelValue : TWIN_WIN_STRIKE_LEVEL_MIN,
        notGreaterThanOrEqualTo: `must be greater than or equal to ${barrierLevelValue > TWIN_WIN_STRIKE_LEVEL_MIN ? 'Barrier Level' : TWIN_WIN_STRIKE_LEVEL_MIN}`,
        lessThanOrEqualTo: TWIN_WIN_STRIKE_LEVEL_MAX,
      },
      maxDecimalDigits: true,
    }
  }

  return valdiations;
}

export default function validate(formData, props = {}) {
  const dynamicValidations = dynamicValidationsCreate(formData, props);

  return validateJs(formData, {
    structure: {
      presence: true,
    },
    issuer: {
      presence: true,
    },
    legalShape: {
      presence: true,
    },
    floatingCurve: {
      presence: true,
    },
    currency: {
      presence: true,
    },
    maturity: {
      presence: true,
    },
    autocall: {
      presence: true,
    },
    frequency: {
      presence: true,
    },
    firstObservationIn: {
      presence: true,
    },
    autocallTriggerLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: AUTOCALL_TRIGGER_LEVEL_MIN,
        lessThanOrEqualTo: AUTOCALL_TRIGGER_LEVEL_MAX,
      },
      maxDecimalDigits: { max: 3 },
    },
    couponType: {
      presence: true,
    },
    memoryCoupon: {
      presence: true,
    },
    couponTriggerLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: COUPON_TRIGGER_LEVEL_MIN,
        lessThanOrEqualTo: COUPON_TRIGGER_LEVEL_MAX,
      },
      maxDecimalDigits: { max: 3 },
    },
    solveFor: {
      presence: true,
    },
    reoffer: {
      presence: {
        message: `^reoffer ${validateMessages.blank}`
      },
      numericality: {
        greaterThanOrEqualTo: REOFFER_MIN,
        lessThanOrEqualTo: REOFFER_MAX,
      },
      maxDecimalDigits: true,
    },
    strikeLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: STRIKE_LEVEL_MIN,
        lessThanOrEqualTo: STRIKE_LEVEL_MAX,
      },
      maxDecimalDigits: true,
    },
    step: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
      },
      maxDecimalDigits: true,
    },
    spread: {
      presence: {
        message: `^Spread ${validateMessages.blank}`
      },
      numericality: {
        onlyInteger: true,
      }
    },
    downsideLeverage: {
      presence: true,
    },
    barrierType: {
      presence: true,
    },
    notional: {
      presence: {
        message: `^Notional ${validateMessages.blank}`
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE_LARGE,
      }
    },
    ...dynamicValidations,
  }) || {};
}
