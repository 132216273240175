import React, { PureComponent, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import { createSelector } from 'reselect';
import { selectSubForm } from 'redux/actions/price';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';

class PriceSubFormLabelSelect extends PureComponent {
  onClick = e => {
    e.preventDefault();
    const { name, onClick } = this.props;
    onClick(name);
  }
  render() {
    const { label, active } = this.props;
    return (
      <NavLink onClick={this.onClick} active={active}>
        <span>{label}</span>
      </NavLink>
    );
  }
}

const markerPostiton = (selected, options, key) => {
  const findIndex = options.findIndex((option) => selected === option[key]);
  const leftPostion = 100 / options.length * findIndex;
  return {
    left: leftPostion + '%'
  }
}

class PriceSubFormSelect extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.current !== nextProps.current || !isEqual(this.props.forms, nextProps.forms);
  }
  render() {
    const { forms = [], current, select } = this.props;
    return forms?.length
      ? (
        <div className="form-group">
          <label>
            <FormattedMessage id="form.price.fields.solve-form"
              defaultMessage="Solve for" />
          </label>
          <div>
            <div className={`nav nav-pills mb-2 pills-rounded clearfix nav-pills-col-${forms.length}`}>
              {forms.map(({ label, name }) => (
                <div key={name} className={`nav-item${current === name ? ' active' : ''}`}>
                  <PriceSubFormLabelSelect onClick={select} name={name} label={label} />
                </div>
              ))}
              {current ? <div className="nav-pills-marker" key="pills-marker" style={markerPostiton(current, forms, 'name')}></div> : null}
            </div>
          </div>
        </div>
      )
      : null;
  }
}

const structureSelector = createSelector(
  ({ price: { structureConfig } }) => structureConfig,
  structureConfig => structureConfig && structureConfig.forms
    ? structureConfig.forms.map(({ name, label }) => ({ name, label }))
    : []
)

const currentFormLabelSelect = createSelector(
  state => state.price.currentFormConfig,
  form => form ? form.name : null
)

const mapStateToProps = state => ({
  forms: structureSelector(state),
  current: currentFormLabelSelect(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  select: selectSubForm
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceSubFormSelect);
