import InlineInput from 'components/form/InlineInput';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import InlineInputBlock from 'components/form/inputs/readOnly/InlineInputBlock';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import PriceDynamicForm from '../../../PriceDynamicForm';
import PriceSubFormSelect from '../../PriceSubFormSelect';
import { defaultDateFormater, isWeekday, tommorowDay } from './validators';
import { getBankHolidays, BANK_HOLIDAYS_TYPE } from 'components/form/BankHolidaysCalendar/query';

async function bankHolidaysReq(underlyingId, { setBankHolidays }) {
  const res = await getBankHolidays([underlyingId], BANK_HOLIDAYS_TYPE.ACCUMULATORS);
  setBankHolidays(res);
}

const AccumulatorFormFields = props => {
  const {
    structureExpiredAt,
    barrierType,
    leverageValue,
    leverageStyle,
    currency,
    structureType,
    commodity,
    contractExpiries,
    structures,
    formConfig,
    underlying = {},
    remainderFeatures = [],
    formValues = {},
    accumulationStyles = [],
    accumulatorUnderlyings = {},
  } = props;
  const bloombergTicker = underlying ? underlying.bloombergTicker : null;
  const structureExpiredAtMax = structureExpiredAt && structureExpiredAt[0] ? structureExpiredAt[0].value : null;
  const { remainderFeature, commodity: selectedCommodity } = formValues;
  const underlyingId = accumulatorUnderlyings[selectedCommodity];
  const [ bankHolidays, setBankHolidays ] =  useState([]);

  useEffect(() => {
    if (selectedCommodity && underlyingId) {
      bankHolidaysReq(underlyingId, { setBankHolidays });
    }
  }, [selectedCommodity])

  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={4} className="tour-structure">
          <Field name="structure"
            label={<FormattedMessage id="form.price.fields.structure.label"
              defaultMessage="Structure" />}
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            options={structures}
          />
        </Col>
        <Col xs={12} sm={8}>
          <div className="float-sm-right tour-type">
            <Field name="structureType"
              label={<FormattedMessage id="form.price.fields.structureType.label"
                defaultMessage="Type" />}
              component={RadioGroup}
              justValue={true}
              options={structureType}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={8} className="tour-comodity-contract-and-expiry flex-1">
          <Row>
            <Col xs={12} sm={6}>
              <Field name="commodity"
                label="Commodity Contract"
                component={InlineSelectInput}
                justValue={true}
                options={commodity} />
            </Col>
            <Col xs={12} sm={6}>
              <Field name="contractExpiry"
                label="Contract Expiry"
                component={InlineSelectInput}
                justValue={true}
                options={contractExpiries} />
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={4} className="tour-bloomberticker">
          <InlineInputBlock label={<FormattedMessage id="form.price.fields.bloomberg-ticker.label"
            defaultMessage="Bloomberg Ticker" />} value={bloombergTicker} />
        </Col>
      </Row>

      <Row className="tour-ref-price-group">
        <Col xs={12} sm={4}>
          <Field name="payoutCurrency"
            label={<FormattedMessage id="form.price.fields.payout-currency.label"
              defaultMessage="Currency" />}
            justValue={true}
            component={InlineSelectInput}
            preselectIfOneOption={true}
            options={currency}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Field name="expiration"
            label={<FormattedMessage id="form.price.fields.expiration.label"
              defaultMessage="Structure Expiry Date" />}
            component={InlineInput}
            type="date"
            filterDate={isWeekday}
            minDate={tommorowDay()}
            dateFormater={defaultDateFormater}
            maxDate={structureExpiredAtMax}
            readOnlyInput={true}
            excludeDates={bankHolidays}
          />
        </Col>
        <Col xs={12} sm={4} className="tour-notional">
          <Field name="notional"
            label={<FormattedMessage id="form.price.fields.notional.label"
              defaultMessage="Notional (Contracts)" />}
            placeHolder="Enter Notional"
            type="number"
            step={1}
            component={InlineInput} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="tour-barrier-type">
          <Field name="barrierType"
            label={<FormattedMessage id="form.price.fields.barrier-type.label"
              defaultMessage="Barrier Type" />}
            justValue={true}
            component={RadioGroup}
            options={barrierType}
          />
        </Col>
      </Row>

      <Row className="tour-leverage price-form-tour-leverage">
        <Col>
          <Field name="leverageValue"
            label={<FormattedMessage id="form.price.fields.leverage.label"
              defaultMessage="Leverage" />}
            justValue={true}
            component={InlineSelectInput}
            options={leverageValue}
          />
        </Col>
        <Col>
          <Field name="leverageStyle"
            label={<FormattedMessage id="form.price.fields.leverage-style.label"
              defaultMessage="Leverage Style" />}
            justValue={true}
            component={InlineSelectInput}
            options={leverageStyle}
          />
        </Col>
        <Col>
          <Field name="remainderFeature"
            label={<FormattedMessage id="form.price.fields.remainder-quantity.label"
              defaultMessage="Remainder Feature" />}
            justValue={true}
            component={InlineSelectInput}
            options={remainderFeatures}
          />
        </Col>

        <Col>
          <Field name="accumulationStyle"
            label={<FormattedMessage id="form.price.fields.accumulation-style.label"
              defaultMessage="Accumulation Style" />}
            justValue={true}
            component={InlineSelectInput}
            options={accumulationStyles}
          />
        </Col>

      </Row>

      <Row className="tour-solve-for price-solve-for-wrapper">
        <Col>
          <PriceSubFormSelect />
        </Col>
      </Row>

      <div className="price-dynamic-form-wrapper">
        <PriceDynamicForm formConfig={formConfig} remainderFeature={remainderFeature} />
      </div>
    </Fragment>
  )
}

export const accumulatorFormFieldsProps = {
  barrierType: PropTypes.array,
  leverageValue: PropTypes.array,
  leverageStyle: PropTypes.array,
  currency: PropTypes.array,
  structureType: PropTypes.array,
  commodity: PropTypes.array,
  contractExpiries: PropTypes.array,
  structures: PropTypes.array,
  accumulationStyles: PropTypes.array,
  formConfig: PropTypes.object,
  underlying: PropTypes.object,
}

AccumulatorFormFields.propTypes = {
  ...accumulatorFormFieldsProps
}

export default AccumulatorFormFields;
