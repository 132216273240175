import { createSelector } from 'reselect';
import { optionsProvider } from '../../utils';

export const swapLimitationSelector = createSelector(
  [
    state => state.price.limitation,
    state => state.formData.isAsianSwap || false
  ],
  (limitation, isAsianSwap = false) => {
    return limitation && limitation.commodityContracts ? {
      commodityContracts: optionsProvider(limitation.commodityContracts || []),
      isAsianSwap
    } : {
      isAsianSwap
    }
  }
);
