import { DATE_FORMAT_LABEL, DATE_FORMAT_LABEL_SHORT } from 'constants.js';
import { createSelector } from 'reselect';
import { optionsProvider, prepareDates } from '../../utils';

export const accumulatorLimitationSelector = createSelector(
  [
    state => state.price.limitation,
    state => state.price.pricings,
  ],
  (limitation, pricings = []) => {
    const accumulatorUnderlyings = pricings && pricings.length
      ? pricings.reduce((acc, { commodity, accumulatorUnderlyings = {}}) => accumulatorUnderlyings?.id ? {
        ...acc,
        [commodity]: accumulatorUnderlyings?.id
      } : acc, {})
      : {};

    return limitation ?
      {
        barrierType: optionsProvider(limitation.barrierType || []),
        leverageValue: optionsProvider(limitation.leverageValue || []),
        leverageStyle: optionsProvider(limitation.leverageStyle || []),
        remainderFeature: optionsProvider(limitation.remainderFeature || []),
        structureType: optionsProvider(limitation.structureType || []),
        currency: optionsProvider(limitation.currency || []),
        commodity: optionsProvider(limitation.commodity || []),
        contractExpiries: limitation.contractExpiries ? prepareDates({ dates: limitation.contractExpiries, format: DATE_FORMAT_LABEL_SHORT }) : [],
        structureExpiredAt: limitation.structureExpiredAt ? prepareDates({ dates: limitation.structureExpiredAt, format: DATE_FORMAT_LABEL }) : [],
        accumulatorUnderlyings,
      } : null
  }
);
