import moment from 'moment';
import { from } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { STRUCTURE_SWAP } from 'constants.js';
import { PRICING_SUBMIT_FORM } from 'redux/actions/price';
import { swapCardCreate } from 'redux/actions/swap';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { createAsianSwapCardMutation } from 'redux/queries/asianSwap';
import { extractContractData } from '../../swap';
import { catchCardCreation } from 'redux/epics/price/structures/swap/cards/create';
import { SWAP_ASIAN, DATE_FORMAT } from 'pages/price/output/asianSwap/constants';

const saveCard = (commodityContract, cardData) => {
  return from(createAsianSwapCardMutation(cardData))
  .pipe(
    filter(payload => !!payload),
    map((responceOrder) => {
      return swapCardCreate({
        ...cardData,
        ...responceOrder,
        price: null,
        priceForCalcSpread: null
      })
    }),
    catchCardCreation(commodityContract)
  )
}


export const submitPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => {
      const { structure, swapType } = priceFormValueSelector(state$.value) || {}
      return structure === STRUCTURE_SWAP && swapType === SWAP_ASIAN
    }),
    filter(({ payload }) => !!payload && Array.isArray(state$.value.price.pricings)),
    map(({ payload }) => extractContractData(state$.value.price.pricings, payload)),
    filter(contract => contract),
    debounceTime(200),
    switchMap(({ commodity: commodityContract, commodityCode, direction, quotedCurrency, bloombergTicker }) => {
      const UNDERLYING_OPTIONS = state$.value.price.asianUnderlyingContracts || [];
      const SETTLEMENT_OPTIONS = state$.value.price.asianSettlementOptions || [];
      const expiry = moment().startOf('M').format(DATE_FORMAT);
      const notional = 1;
      const card = {
        direction,
        commodityCode,
        commodityContract,
        underlyingContracts: UNDERLYING_OPTIONS.length ? UNDERLYING_OPTIONS[0].value : '',
        settlement: SETTLEMENT_OPTIONS.length ? SETTLEMENT_OPTIONS[0].value : '',
        firstMonth: expiry,
        lastMonth: expiry,
        baseCurrency: quotedCurrency,
        legs: [{
          notional,
          contractExpiry: expiry
        }],
        notional,
        bloombergTicker,
        balmo: parseInt(moment().format('DD')) === 1
      }
      return saveCard(commodityContract, card)
    })
  );

export default submitPriceFormEpic;
