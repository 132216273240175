import { DATE_FORMAT_FACTSHEET, RECORD_EMPTY } from 'constants.js';
import { BARRIER_TYPE_NONE } from 'pages/price/FormItem';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';
import isNumber from 'lodash/isNumber';
import { QUANTITY_FORMATTER_PARAMS } from './constants';

const REFERENCE_FIGURES_CONTENT_CONFIG = [
  {
    label: 'Reference Contract',
    view: ({ commodityContract, contractExpiry }) => (
      <Fragment>
        {commodityContract}&nbsp;{formatDate2UI(contractExpiry)}
      </Fragment>),
  },
  {
    label: 'Bloomberg Ticker',
    view: ({ bloombergTicker }) => (<span>{bloombergTicker}</span>),
  },
  {
    label: 'Currency',
    view: ({ quotedCurrency, accumulatorUnderlyings }) =>
      (<span>{accumulatorUnderlyings && accumulatorUnderlyings.currency ? accumulatorUnderlyings.currency : quotedCurrency}</span>),
  },
  {
    label: 'Start Date',
    view: () => formatDate2UI(Date.now(), DATE_FORMAT_FACTSHEET),
  },
  {
    label: 'Expiry Date',
    view: ({ optionExpiryDate, businessDays }) => (<Fragment>{formatDate2UI(optionExpiryDate, DATE_FORMAT_FACTSHEET)}&nbsp;({businessDays} business days)</Fragment>),
  },
  {
    label: 'Reference Contract Price',
    view: ({ referencePrice, quoteUnits }) => (<span>
      <FormattedNumber
        value={referencePrice}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      /> {quoteUnits}
    </span>),
  },
  {
    label: 'Accumulation Level ({action}ing level)',
    view: ({ strikeLevel, quoteUnits }) => (<span>
      <FormattedNumber
        value={strikeLevel}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      /> {quoteUnits}
    </span>),
  },
  {
    label: 'Barrier Level',
    view: ({ barrierLevel, quoteUnits }) => (<span>
      {barrierLevel ? <Fragment>
        <FormattedNumber
          value={barrierLevel}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        /> {quoteUnits}
      </Fragment>
        : RECORD_EMPTY}
    </span>),
    filter: ({ barrierType }) => barrierType !== BARRIER_TYPE_NONE
  },
  {
    label: 'Remainder Level',
    view: ({ remainderLevel, quoteUnits }) => <span><FormattedNumber value={remainderLevel} maximumFractionDigits={4} /> {quoteUnits}</span>,
    filter: ({ remainderFeature, remainderLevel }) => isNumber(remainderLevel) && remainderFeature !== 'none'
  },
  {
    label: 'Notional',
    view: ({ notional }) => (<span><FormattedNumber value={notional} /> Reference Contract</span>),
  },
  {
    label: 'Daily Quantity',
    view: ({ notional, businessDays }) => (<span><FormattedNumber {...QUANTITY_FORMATTER_PARAMS} value={notional / businessDays} /> Reference Contract</span>),
  },
  {
    label: 'Daily Leverage',
    view: ({ description }) => (<span>{description.leverage}</span>),
  },
];

export default REFERENCE_FIGURES_CONTENT_CONFIG;
