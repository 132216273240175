import toString from 'lodash/toString';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { themeStylesSelector } from './utils';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const ThemeDetect = props => {
  let style;
  const { styles, themeName = 'agile' } = props;
  const prevStyles = usePrevious(styles);

  const _addStyles = (themeName, styles) => {
    if (themeName && styles) {
      style = document.createElement('style');
      style.id = themeName; //Use theme name
      document.head.appendChild(style);
      style.innerHTML = toString(styles);
    }
  }

  const addStyles = useCallback(_addStyles);

  useEffect(() => {
    addStyles();
    return () => {
      if (style?.parentNode) style.parentNode.removeChild(style);
    }
  }, []);

  useEffect(() => {
    if (!prevStyles || prevStyles !== styles) addStyles(themeName, styles);
    return () => {
      if (style?.parentNode) style.parentNode.removeChild(style);
    }
  }, [addStyles, themeName, styles, prevStyles])

  return null;
};

ThemeDetect.propTypes = {
  styles: PropTypes.string,
};

export default connect(themeStylesSelector)(ThemeDetect);
