import type { Action } from 'redux';
import {
  PRICING_FX_CARDS_UPDATE,
  PRICING_FX_CARD_CREATE, PRICING_FX_CARD_DELETE,
  PRICING_FX_CARD_UPDATE, PRICING_FX_DATA_LOADED
} from 'redux/actions/fx';
import { swapAddCard, swapRemoveCard, swapUpdateCard } from './price';

export type IPriceReducerState = {
  pricings: Object[],
  trailPrice: Object | null,
  fxBankHolidays: Object[]
}

const PRICING_INIT_STATE: IPriceReducerState = {
  pricings: [],
  trailPrice: null,
  fxBankHolidays: []
};

export default function fxReducer(state: IPriceReducerState = PRICING_INIT_STATE, action: Action): IPriceReducerState {
  try {
    switch (action.type) {
      case PRICING_FX_DATA_LOADED:
        return { ...state, ...action.payload };
      // Multiple
      case PRICING_FX_CARD_CREATE:
        return { ...state, trailPrice: swapAddCard(state.trailPrice, action.payload) }
      case PRICING_FX_CARDS_UPDATE:
        return { ...state, trailPrice: action.payload }
      case PRICING_FX_CARD_UPDATE:
          return { ...state, trailPrice: swapUpdateCard(state.trailPrice, action.payload) }
      case PRICING_FX_CARD_DELETE:
        return { ...state, trailPrice: swapRemoveCard(state.trailPrice, action.payload) }
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
