import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import Bundle from 'pages/Bundle';
import React from 'react';

export const Category = props => (
  <ErrorBoundary>
    <Bundle title="Category Page" load={() => import('./Category')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default Category;
