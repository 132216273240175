import { combineEpics } from 'redux-observable';
import pricingMiniPageLifeEpic from './life';
import load from './load';
import formCardsDestroyEpic from './destroy';

export default combineEpics(
  load,
  formCardsDestroyEpic,
  pricingMiniPageLifeEpic,
);
