import validateJs from 'utils/validate.js';
import validateMessages from 'utils/validate.messages';

export default function (data = {}) {
  const validations = {
    physicalId: {
      length: {
        maximum: 50,
        tooLong: `^Physical Id ${validateMessages.tooLong}`,
      }
    },
  };

  return validateJs(data, validations) || {};
};
