import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { logout } from 'redux/actions/auth';
import store from 'store.js';
import Bowser from "bowser";
import introspectionQueryResultData from './fragmentTypes.json';
import { getAuthToken, setAuthToken } from 'auth/index.js';
import logger from 'utils/Logger';
import { REDIRECT_CODE } from 'constants.js';

const graphqlLink = window.API_PROTOCOL + '://' + window.API_HOST + '/graphql';
const httpLink = createHttpLink({ uri: graphqlLink });

const wsProtocol = window.API_WS_PROTOCOL || 'wss';
const uri = `${wsProtocol}://${window.SUBSCRIPTION_HOST}/subscription`;
const tabIdKey = 'tabID';
const wsLink = new WebSocketLink({
  uri,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      authorization: `Bearer ${getAuthToken()}`,
      tabId: sessionStorage.getItem(tabIdKey),
    })
  }
});

const defineTabID = () => {
  const { userAgent } = window.navigator;
  const _browser = Bowser.getParser(userAgent);
  const { parsedResult: { browser, platform} } = _browser.parse();
  const iPageTabID = sessionStorage.getItem(tabIdKey);
  if (!iPageTabID) {
    const iLocalTabID = localStorage.getItem(tabIdKey);
    const iPageTabID = iLocalTabID ? Number(iLocalTabID) + 1 : 1;
    localStorage.setItem(tabIdKey, iPageTabID);
    sessionStorage.setItem(tabIdKey, `${browser.name}_${platform.type}_${iPageTabID}`);
  }
}

export const closeWsLink = () => {
  wsLink.subscriptionClient.close()
};

export const connectWsLink = () => {
  defineTabID()
  wsLink.subscriptionClient.connect()
};

const middlewareLink = setContext(() => {
  const token = getAuthToken();

  // Use token for old queries
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  return {
    headers
  }
});

let link = middlewareLink.concat(httpLink);


const REQUEST_RESPONSE_HEADER_TOKEN = 'authorization';

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const { response: { headers } } = context;
    if (headers) {
      const token = headers.get(REQUEST_RESPONSE_HEADER_TOKEN);
      if (token) {
        setAuthToken(token, true);
      }
    };

    return response;
  });
});

link = afterwareLink.concat(link);

const errorLink = onError(({ networkError, graphQLErrors, operation: { operationName } }) => {
  logger.error('Http errors.', {
    operationName,
    graphQLErrors,
    networkError,
    networkErrorCode: networkError ? networkError.statusCode : null,
  });
  if (graphQLErrors) {
    const authError = graphQLErrors.find(({ code }) => code === 401 || code === REDIRECT_CODE);
    if (authError) {
      const state = store.getState();
      const isAuthorized = state?.auth?.isAuthorized;
      if (isAuthorized) {
        store.dispatch(logout());
      }
      client.cache.reset();
      closeWsLink();
    }

    graphQLErrors.map(({ message }) =>
      console.log('[GraphQL error]: Message', message),
    );
  }

  if (networkError && networkError.statusCode === 401) {
    client.cache.reset()
    store.dispatch(logout());
    closeWsLink();
  } else if (networkError && networkError.statusCode > 401) {
    console.log('Server returned an error');
  }
})

link = errorLink.concat(link);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  link,
);

const client = new ApolloClient({
  link,
  cache,
});

export const apolloErrors = (e) => {
  return e && Array.isArray(e.graphQLErrors) && e.graphQLErrors.length ? e.graphQLErrors : [{ code: 'E1' }]
}

export default client;
