import React from 'react';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { TYPE_LIMIT } from 'components/trade/constants.js';

const LegLabel = ({
  formattedUnit,
  legsPrices,
  contractExpiry,
  expiryDate,
  cardPrice,
  orderType
}) => {
  /* this check is here to prevent the leg from displaying in the order summary modal if notional is 0 */
  const { notional, ticker, price } = legsPrices.find(({ month }) => month === contractExpiry);
  const formatedTicker = ticker && ticker[1] !== ' ' ? ticker.replace(' ', '') : ticker;
  return notional ? (
    <li>
      <FormattedNumber
        value={notional}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />
      &nbsp;{formattedUnit}&nbsp;{formatedTicker}&nbsp;@&nbsp;
      <FormattedNumber
        value={orderType === TYPE_LIMIT ? cardPrice : price}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />
      &nbsp; - &nbsp;Expiry &nbsp;{moment(expiryDate).format('DD MMM YYYY')}
    </li>
  ) : null
};

export const TradeLabel = ({
  direction,
  legsPrices,
  formattedUnit,
  legs,
  orderType,
  price: cardPrice
}) => (
  <>
    <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
    <ul className="trade-modal-legs">
      {legs.map(({ contractExpiry, expiryDate }, index) => (<LegLabel
        key={index}
        formattedUnit={formattedUnit}
        legsPrices={legsPrices}
        contractExpiry={contractExpiry}
        expiryDate={expiryDate}
        cardPrice={cardPrice}
        orderType={orderType}
        />))}
    </ul>
  </>
);
