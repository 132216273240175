import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isInvalid, reduxForm } from 'redux-form';
import { graphql, withApollo } from 'react-apollo';
import { createSelector } from 'reselect';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import ServerError from 'components/common/errors/ServerError';
import Loader from 'components/common/loader/Loader';
import { isEqual } from 'apollo-utilities';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { queryOptionsProvider } from 'components/graphql/query.utils';
import { createFilter } from 'pages/price/PricingHistory/PricingHistoryTable';
import { totalColumns } from 'pages/price/PricingHistory/TableColumnsTogglerWrapper';
import { setUnwindIdAction } from "redux/actions/auth";
import { mapNodes } from 'utils';
import { DASHBOARD_TRADES_FORM_COLUMNS, DASHBOARD_TRADES_FORM_FILTERS, DASHBOARD_TRADES_FORM_ITEM } from './constants';
import OpenTradesTableItemEdit from './edit/OpenTradesTableItemEdit';
import OpenTradesTableEmpty from './OpenTradesTableEmpty';
import OpenTradesTableFilter from './OpenTradesTableFilter';
import OpenTradesTableHeader from './OpenTradesTableHeader';
import OpenTradesTableList from './OpenTradesTableList';
import { DASHBOARD_TRADES_QUERY } from './query';
import { FILTERED_TRADES_SORTING } from './sorter';
import OpenTradesUnwind from './unwind/OpenTradesUnwind';
import { CANCEL_UNWIND_MUTATION } from './unwind/query'
import { STRUCTURE_ASIAN_SWAP } from 'constants.js'

const DEFAULT_COLUMN_WIDTH_PX = 140;

const defaultFilter = [
  {
    "field": "barrierKnocked",
    "operator": "eq",
    "value": false
  }
];

const filteringCondition = (item, conditionsOfChoiceTrades) => {
  const [underlyingName, extId, currency, ticker] = Array.from(conditionsOfChoiceTrades);

  return item.underlyingName === underlyingName
    && item?.externalId?.includes(extId)
    && item.currency === currency
    && item.ticker === ticker
    && item.canUnwind
}

export const cancelUnwindMutation = async (client, setUnwindIdAction, unwindHistoryId) => {
  const result = await client.mutate({
    mutation: CANCEL_UNWIND_MUTATION,
    variables: {
      unwindHistoryId,
    }
  });
  setUnwindIdAction(null)
  return result;
}

class OpenTradesTableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromFilter: true,
      orderBy: {
        order: 'desc',
        by: FILTERED_TRADES_SORTING[1].key
      },
      dataLoaded: false,
      isFirstRequest: false,
      selectedId: null,
      lastUpdated: null,
      liveUpdates: [],
      lastNewOrder: null,
      filter: defaultFilter,
      ...this.emptyStateForSelectingTrades(),
      unwindProcess: false,
      itemsCount: 0,
    };
    this.onChangeOrder = this._onChangeOrder.bind(this);
    this.onChangeFilter = debounce(this._onChangeFilter.bind(this), 500);
    this.scroller = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.newOrder?.externalIds) {
      const { externalIds, canUnwind } = props.newOrder

      const liveUpdates = [{
        externalIds,
        canUnwind,
      }]

      return state.liveUpdates !== liveUpdates ? {
        liveUpdates,
      } : null
    }

    if (props.newOrder || !isEqual(props.newOrder, state.lastNewOrder)) {
      const { isMyDashboard, userId, dashboardId } = props;

      if (isMyDashboard && props.newOrder?.traderId !== userId) {
        return { lastNewOrder: props.newOrder };
      }

      if(!isMyDashboard && dashboardId !== props.newOrder.dashboardId) {
        return { lastNewOrder: props.newOrder };
      }

      const idx = state.liveUpdates.findIndex((x) => x?.externalId === props.newOrder.externalId );

      const liveUpdates =
        idx === -1
          ? [props.newOrder, ...state.liveUpdates]
          : state.liveUpdates?.map((x) =>
              x.externalId == props.newOrder.externalId ? props.newOrder : x
            );

      return {
        lastNewOrder: props.newOrder,
        liveUpdates: liveUpdates,
      };
    }

    const items = mapNodes(props.otcStatementsConnection)
    if (items?.length > state.itemsCount) {
      const { conditionsOfChoiceTrades, itemsCount } = state
      const filteredArray = items.slice(itemsCount).filter(item => filteringCondition(item, conditionsOfChoiceTrades));
      return {
        selectAllChecked: conditionsOfChoiceTrades.size ? (!Boolean(filteredArray.length)) : false,
        itemsCount: items.length
      }
    }

    return null
  }

  emptyStateForSelectingTrades = () => ({
    selectedIds : new Set(),
    selectAllChecked: false,
    conditionsOfChoiceTrades: new Set(),
    liveUpdates: [],
  })

  _onChangeOrder = field => {
    this.setState({ fromFilter: true });
    const { refetch, variables } = this.props;
    let { orderBy } = this.state;
    const order = (orderBy.by === field && orderBy.order === 'desc') ? 'asc' : 'desc';
    orderBy = {
      order: order,
      by: field
    };
    this.setState({
      orderBy,
      ...this.emptyStateForSelectingTrades()
    });
    variables.sorter = orderBy;
    this.scroller.current.scrollTo(0, 0);
    refetch(variables);
  }

  _onChangeFilter = (field) => {
    const { invalidFilterForm } = this.props;
    if (invalidFilterForm) return; //Don't submit invalid form
    const { refetch, variables } = this.props;
    const filter = field ? createFilter([...this.state.filter], field) : this.state.filter;
    const sendFilter = filter?.length ? filter : defaultFilter;
    this.setState({
      fromFilter: true,
      filter: sendFilter,
      ...this.emptyStateForSelectingTrades()
    });
    this.scrollTop();
    refetch({ ...variables, filter: sendFilter });
  }

  scrollTop = () => {
    if (this.scroller && this.scroller.current) {
      this.scroller.current.scrollTo(0, 0);
    }
  }

  onSelectTradeItem = (accumulatorMiniHistoryId, otcStatementId, commonId) => {
    const selectedId = [accumulatorMiniHistoryId, otcStatementId, commonId]
    this.setState({ selectedId });
  }

  onCloseEditModal = () => {
    this.setState({ selectedId: null });
  }

  onSuccessUpdate = lastUpdated => {
    this.setState({ lastUpdated });
  }

  onSelectedTradeItem = (conditionsOfChoiceTrades, externalId) => {
    const { selectedIds, lastUpdated, liveUpdates } = this.state;
    const { otcStatementsConnection } = this.props;

    this.setState({ conditionsOfChoiceTrades });
    if (selectedIds.has(externalId)) {
      selectedIds.delete(externalId);
      this.setState({selectAllChecked: false})
    } else {
      selectedIds.add(externalId);
    }

    const count = this.mergeWithLiveUpdated(
      this.mergeLastUpdated(mapNodes(otcStatementsConnection), lastUpdated),
      liveUpdates
    ).filter(item => filteringCondition(item, conditionsOfChoiceTrades));
    if(selectedIds.size === count.length) this.setState({selectAllChecked: true})

    if(!selectedIds.size) {
      this.setState({ conditionsOfChoiceTrades: new Set() });
    }
  }

  onSelectedAll = () => {
    const { selectedIds, conditionsOfChoiceTrades, lastUpdated, selectAllChecked } = this.state;
    const { otcStatementsConnection, liveUpdates } = this.props;
    if(!selectedIds.size) return

    if(!selectAllChecked) {
      this.mergeWithLiveUpdated(
        this.mergeLastUpdated(mapNodes(otcStatementsConnection), lastUpdated),
        liveUpdates
      ).map(item => {
        if (filteringCondition(item, conditionsOfChoiceTrades) && !selectedIds.has(item.externalId)) {
          selectedIds.add(item.externalId)
        }
      });
      this.setState({ selectedIds, selectAllChecked: true });
    } else {
      this.setState({
        ...this.emptyStateForSelectingTrades()
      })
    }
  }

  onUnwind = () => {
    const { selectedIds } = this.state;
    this.setState({ unwindProcess: Boolean(selectedIds.size) })
  }

  onCloseUnwindTradeModal = async (unwindHistoryId, submitSucceeded) => {
    if(submitSucceeded || !unwindHistoryId) {
      this.setState({
        unwindProcess: false,
        ...this.emptyStateForSelectingTrades()
      })
      return
    }
    const { client, setUnwindIdAction } = this.props;
    try {
      const response = await cancelUnwindMutation(client, setUnwindIdAction, unwindHistoryId)

      if (response?.data?.cancelUnwindMutation) {
        this.setState({
          unwindProcess: false,
          ...this.emptyStateForSelectingTrades()
        })
      }
    } catch (e) {
      console.log(e.graphQLErrors);
      this.setState({
        unwindProcess: false,
        ...this.emptyStateForSelectingTrades()
      })
    }
  }

  mergeLastUpdated = (items, lastUpdated) => {
    if (!lastUpdated) return items;
    if(lastUpdated.tradeName === STRUCTURE_ASIAN_SWAP) {
      return items.map(({ commonId, ...rest }) => {
        if(commonId === lastUpdated.commonId) {
          return {
            commonId,
            ...rest,
            trader: lastUpdated.trader,
            location: lastUpdated.location,
            customer: lastUpdated.customer,
            physicalId: lastUpdated.physicalId,
          };
        }
        return { commonId, ...rest };
      });
    }
    const findIndex = items.findIndex(({ id }) => id === lastUpdated.id);
    if (findIndex >= 0) {
      items[findIndex] = lastUpdated;
    }
    return items;
  }

  mergeWithLiveUpdated = (items, liveUpdates = []) => {
    if(liveUpdates[0]?.externalIds) {
      const [{ externalIds, canUnwind }] = liveUpdates;
      externalIds.map(extId => {
        const findIndex = items.findIndex(({ externalId }) => extId === externalId);
        if (findIndex >= 0) {
          items[findIndex].canUnwind = canUnwind;
        }
      })
    }

    // filter is re-applied on update so no need to merge items
    if(liveUpdates?.length && this.state.filter.length < 2) {
      const newItems = [];
      liveUpdates.forEach((update) => {
        const idx = items.findIndex((x) => x.externalId === update.externalId);
        if (idx === -1) {
          newItems.push(update);
          return;
        }

        const existing = items[idx];
        items[idx] = {
          ...existing,
          ...update
        };
      });
      return [...newItems, ...items];
    }

    return items
  }

  componentDidUpdate(_, prevState, snapshot) {
    // when an update occurs we need to check if our current filter is still applicable
    if (prevState.lastNewOrder !== this.state.lastNewOrder) {
      this.onChangeFilter();
    }
  }

  render() {
    const { otcStatementsConnection, loading, loadMore, isLoading, displayColumns, error, dashboardId, isMyDashboard, isSlackId, setUnwindIdAction } = this.props;
    const { orderBy, fromFilter, isFirstRequest, selectedId, filter, lastUpdated, conditionsOfChoiceTrades, selectedIds, selectAllChecked, unwindProcess, liveUpdates } = this.state;

    const items = this.mergeWithLiveUpdated(this.mergeLastUpdated(mapNodes(otcStatementsConnection), lastUpdated), liveUpdates);
    const totalDisplayColumns = totalColumns(displayColumns, FILTERED_TRADES_SORTING);
    const isActiveAllColumns = totalDisplayColumns === FILTERED_TRADES_SORTING.length;
    const displayColumnsClass = isActiveAllColumns ? ' columns-diplay-all' : '';
    const displayColumnsTableWidth = totalDisplayColumns > 14 ? `${totalDisplayColumns * DEFAULT_COLUMN_WIDTH_PX}px` : null;
    const downloadVariables = {
      dashboardId,
      isMyDashboard,
      sorter: orderBy,
      filter
    };
    if (error) {
      return <ServerError />;
    } else if (!loading && !fromFilter && Array.isArray(items) && !items.length) {
      return <OpenTradesTableEmpty />
    } else if (!isFirstRequest && Array.isArray(items)) {
      return (
        <div ref={this.scroller} className={`app-table-wrapper${displayColumnsClass}`}>
          <OpenTradesTableFilter
            isActiveAllColumns={isActiveAllColumns}
            onChangeFilter={this.onChangeFilter}
            displayColumns={displayColumns}
            dashboardId={dashboardId}
            downloadVariables={downloadVariables}
            isMyDashboard={isMyDashboard}
            isLoading={isLoading}
            conditionsOfChoiceTrades={conditionsOfChoiceTrades}
            selectedIds={selectedIds}
            onSelectedAll={this.onSelectedAll}
            selectAllChecked={selectAllChecked}
            onUnwind={this.onUnwind}
            isSlackId={isSlackId}
          />
          <div className="app-table-content">
            <OpenTradesTableHeader
              onChange={this.onChangeOrder}
              disabled={items.length < 2}
              displayColumns={displayColumns}
              selected={orderBy}
              style={{ minWidth: displayColumnsTableWidth }}
              isSlackId={isSlackId}
            />
            {loading ? <Loader /> :
              items?.length ?
                <div className="app-table" style={{ minWidth: displayColumnsTableWidth }}>
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMore}
                    threshold={50}
                    useWindow={false}
                    hasMore={!isLoading && otcStatementsConnection?.pageInfo.hasNextPage}
                    loader={<Loader key={'TradesTableLoader'} />}>
                    <OpenTradesTableList
                      list={items}
                      displayColumns={displayColumns}
                      onSelect={this.onSelectTradeItem}
                      onChangeTrade={this.onSelectedTradeItem}
                      conditionsOfChoiceTrades={conditionsOfChoiceTrades}
                      selectedIds={selectedIds}
                      isSlackId={isSlackId}
                    />
                  </InfiniteScroll>
                  {selectedId ?
                    <OpenTradesTableItemEdit
                      dashboardId={dashboardId}
                      isMyDashboard={isMyDashboard}
                      trailId={selectedId}
                      onClose={this.onCloseEditModal}
                      onSuccessUpdate={this.onSuccessUpdate} />
                    : null}
                  {
                    unwindProcess ?
                      <OpenTradesUnwind
                        unwindProcess
                        selectedIds={selectedIds}
                        onClose={this.onCloseUnwindTradeModal}
                        onSuccessUpdate={this.onSuccessUpdate}
                        setUnwindIdAction={setUnwindIdAction}
                      />
                    : null
                  }
                </div>
                :
                <div className="app-table text-center">
                  <FormattedMessage id="trades/empty-filter"
                    defaultMessage="Trades is empty" />
                </div>
            }
          </div>
        </div>
      )
    }
    return null;
  }
}

OpenTradesTableWrapper.propTypes = {
  dashboardId: PropTypes.number.isRequired,
  isMyDashboard: PropTypes.bool
};
OpenTradesTableWrapper.defaultProps = {
  isMyDashboard: false
}

export const selectorFormColumns = getFormValues(DASHBOARD_TRADES_FORM_COLUMNS);
export const invalidFilterFormSelector = isInvalid(DASHBOARD_TRADES_FORM_FILTERS);

const mapDispatchToProps = dispatch => bindActionCreators({
  setUnwindIdAction
}, dispatch);

const mapStateToProps = createSelector(
  [
    state => selectorFormColumns(state),
    state => invalidFilterFormSelector(state),
    state => state.openTrades,
    state => state.auth,
  ],
  (formData, invalidFilterForm, openTrades, { account }) => ({
    displayColumns: formData || {},
    invalidFilterForm,
    newOrder: openTrades.newOrder,
    isSlackId: account.isSlackId,
    userId: account.id,
  })
);

const graphqlOptions = props => {
  const { dashboardId, isMyDashboard } = props;
  return {
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      dashboardId,
      isMyDashboard,
      filter: defaultFilter,
      total: 10,
    }
  }
};

export const DASHBOARD_TRADES_QUERY_OPTIONS = queryOptionsProvider(DASHBOARD_TRADES_QUERY, 'otcStatementsConnection', graphqlOptions);

export default compose(
  reduxForm({ form: DASHBOARD_TRADES_FORM_ITEM }),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(DASHBOARD_TRADES_QUERY, DASHBOARD_TRADES_QUERY_OPTIONS),
  withApollo,
)(OpenTradesTableWrapper);
