import { STRUCTURE_FX } from 'constants.js';
import { ofType, combineEpics } from 'redux-observable';
import { cardFxError } from 'redux/epics/price/structures/fx/';
import { priceFormFailed } from 'redux/actions/price';
import { fxCardUpdate, PRICING_FX_REQUEST_PRICE } from 'redux/actions/fx';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { requestPriceMutation } from 'redux/queries/fx';
import { from } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { getCardById } from 'redux/epics/price/structures/swap/utils';

export const startCardRequestPriceEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FX_REQUEST_PRICE),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_FX),
    filter(({ payload }) => payload),
    map(({ payload }) => payload),
    switchMap(({id}) => {
      const card = getCardById(state$.value.fx.trailPrice.cards, id);
      const actions = [
        fxCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
          isRequested: false,
        })];
      return from(actions);
    })
  );

export const submitCardForRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FX_REQUEST_PRICE),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_FX),
    filter(({ payload }) => payload),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap(({id}) => {
      const card = getCardById(state$.value.fx.trailPrice.cards, id);
      return from(requestPriceMutation({id}))
        .pipe(
          filter(payload => !!payload),
          map(({ status }) => {
            return fxCardUpdate({
              ...card,
              status,
              loading: false,
              isLoading: false,
              isRequested: true,
            })
          }),
          catchError((error) => {
            const actions = [
              priceFormFailed(),
              fxCardUpdate({
                ...card,
                loading: false,
                isLoading: false,
              })
            ];
            const err = getSingleGraphQlError(error);
            if (err?.message) {
              return from([
                ...actions,
                notificationErrorSimple(err.message),
              ])
            }

            return from([
              ...actions,
              cardFxError(card, 'retrieve'),
            ]);
          })
        )
    })
  );

export default combineEpics(
  startCardRequestPriceEpic,
  submitCardForRequestEpic,
);
