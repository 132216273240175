import { from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { swapCardDelete, swapCardUpdate } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { cardError, swapTrailPricesFilter } from 'redux/epics/price/structures/swap/';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { deleteAsianSwapCardMutation } from 'redux/queries/asianSwap';
import { PRICING_ASIAN_SWAP_CARD_START_DELETE } from 'redux/actions/asianSwap';
import { getCardById } from './../../swap/utils';

export const cardDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_ASIAN_SWAP_CARD_START_DELETE),
    filter(() => swapTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap(id => {
      const card = getCardById(state$.value.price.trailPrice.cards, id);
      return from(deleteAsianSwapCardMutation(id))
        .pipe(
          filter(payload => !!payload),
          switchMap(() => of(swapCardDelete(id))),
          catchError((error) => {
            const actions = [
              cardError(card),
              swapCardUpdate({
                ...card,
                loading: false,
                isLoading: false
              })
            ];
            const err = getSingleGraphQlError(error);
            if (err?.message && err.code === 403) {
              return from([
                ...actions,
                notificationErrorSimple(err.message),
              ])
            }

            return from(actions);
          })
        )
    })
  );

export const cardLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_ASIAN_SWAP_CARD_START_DELETE),
    filter(() => swapTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    switchMap(id => {
      const card = getCardById(state$.value.price.trailPrice.cards, id)
      const actions = [
        swapCardUpdate({
          ...card,
          loading: true
        })];
      return from(actions);
    })
  );

export default combineEpics(
  cardDeleteEpic,
  cardLoaderEpic,
);
