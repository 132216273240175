import isEmpty from 'lodash/isEmpty';
import { CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS, CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS_ARRAY } from 'pages/structured-products/components/forms/types/capitalProtection/fields';
import { handleVisibleFormFieldsAutocallble } from './autocallable';

const CAP_TYPE_CAPPED = 'capped';
const CAP_TYPE_BARRIER = 'barrier';
const OPTION_TYPE_PUT = 'put';

const getTotalUnderlyings = (formValues = {}) => Object
  .keys(formValues)
  .filter((underlying) => formValues[underlying] && /^underlying_/.test(underlying))
  .length || formValues?.underlyings?.length;

const setAdditionalField = (visibleFieldsConditions = {}, isVisible = true, fields = CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS_ARRAY) => {
  const visibleFieldsObj = { ...visibleFieldsConditions };
  fields.forEach(key => {
    const isVisibleField = isVisible && visibleFieldsConditions[key];
    visibleFieldsObj[key] = isVisibleField;
  });
  return visibleFieldsObj
}

export const handleVisibleFormFieldsCapitalProtection = (formValues = {}, fields = CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS) => {
  const defaultVisibleFields = handleVisibleFormFieldsAutocallble(formValues, fields); //Keep all validations from autocallable
  if ((!formValues) || isEmpty(formValues)) return defaultVisibleFields;
  let visibleFieldsConditions = {};
  const isAdditinalSection = Boolean(formValues.isAdditinalSection);
  visibleFieldsConditions['basketType'] = getTotalUnderlyings(formValues) > 1;

  const capType = formValues.capType ? formValues.capType.toLowerCase() : null;
  const capType2 = formValues.capType2 ? formValues.capType2.toLowerCase() : null;
  const isCapTypeBarrier = capType === CAP_TYPE_BARRIER;
  const isCapType2Barrier = capType2 === CAP_TYPE_BARRIER;
  visibleFieldsConditions['barrierType'] = isCapTypeBarrier;
  visibleFieldsConditions['barrierLevel'] = isCapTypeBarrier;

  const optionType = formValues.optionType ? formValues.optionType.toLowerCase() : null;
  visibleFieldsConditions['rebateLevel'] = optionType !== OPTION_TYPE_PUT && isCapTypeBarrier;

  visibleFieldsConditions['capLevel'] = capType === CAP_TYPE_CAPPED;
  visibleFieldsConditions['capLevel2'] = capType2 === CAP_TYPE_CAPPED;

  visibleFieldsConditions['barrierType2'] = isCapType2Barrier;
  visibleFieldsConditions['barrierLevel2'] = isCapType2Barrier;

  return {
    ...defaultVisibleFields,
    ...visibleFieldsConditions,
    ...setAdditionalField(visibleFieldsConditions, isAdditinalSection),
  };
}
