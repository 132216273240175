import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const TradesHistoryPage = props => (
  <ErrorBoundary>
    <PageMeta title="Trade History" />
    <Bundle title="Trade History" load={() => import('./components/TradesHistory')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default TradesHistoryPage;
