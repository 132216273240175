import update from 'immutability-helper';
import isNaN from 'lodash/isNaN';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import type { Action } from 'redux';
import type { ISproductAnalysisType, IStructureDetailsType } from 'redux/actions/price';
import { PRICING_ACCUMULATOR_DATA_LOADED, PRICING_CREATE_FIELDS_PARAMS, PRICING_DELETE_FIELDS_PARAMS, PRICING_FORM_LIMITATION, PRICING_FORM_LOAD, PRICING_LOADER, PRICING_RESET, PRICING_RESULTS_LOAD, PRICING_SELECT_SUB_FORM, PRICING_SET_CHART_DATA, PRICING_SET_STATIC_CURSOR_DATA, PRICING_SET_FIELDS_PARAMS, PRICING_SET_FORM_STRUCTURES, PRICING_SET_INITIAL_STATE, PRICING_STRUCTURE_UPDATE_DETAILS, PRICING_UPDATE_FIELDS_PARAMS, PRICING_UPDATE_STRUCTURE, PRICING_UPDATE_TRAIL_RPICE, PRICING_UPDATE_UNDERLINE, PRICING_VANILLA_FORM_LOADED } from 'redux/actions/price';
import { PRICING_SWAP_CARDS_UPDATE, PRICING_SWAP_CARD_CREATE, PRICING_SWAP_CARD_DELETE, PRICING_SWAP_CARD_UPDATE, PRICING_SWAP_DATA_LOADED, PRICING_SWAP_REFETCH_EXPIRY } from 'redux/actions/swap';

export const formatSwapPrice = price => {
  const priceValid = parseFloat(price);
  return !isNaN(priceValid) ? priceValid : null;
}

export type IPriceReducerState = {
  structure: Object | null,
  underlying: Object | null,
  structureDetails: IStructureDetailsType | null,
  productAnalysis: ISproductAnalysisType | null,
  structureConfig: StructureConfig,
  currentFormConfig: FormConfig,
  units: Array[] | null,
  fields: DynamicField[],
  limitation: Object | null,
  structures: Objects[],
  underlyings: Objects[],
  products: Objects[] | null,
  pricings: Objects[],
  currencyOptions: Object,
  trailPrice: Object | null,
  formStructures: Array[] | null,
  isLoading: Boolean,
  isLoadingResults: Boolean,
  chart: Object | null,
  staticCursor : String | null,
  refetchedExpiries: number,
  asianUnderlyingContracts: Array[],
  asianSettlementOptions: Array[],
}

// @TODO: remmove nested vanilla property
const PRICING_INIT_STATE: IPriceReducerState = {
  structure: null,
  underlying: null,
  structureDetails: null,
  productAnalysis: null,
  structureConfig: null,
  currentFormConfig: null,
  fields: [],
  limitation: null,
  structures: [],
  underlyings: [],
  pricings: [],
  currencyOptions: {},
  units: [],
  trailPrice: null,
  isLoading: false,
  isLoadingResults: false,
  formStructures: [],
  chart: null,
  products: [],
  staticCursor: null,
  refetchedExpiries: 0,
  asianUnderlyingContracts: [],
  asianSettlementOptions: [],
};

export const swapAddCard = (trailPrice, payload) => {
  const { cards = [] } = trailPrice;
  const card = {
    ...payload,
    confirmationPopup: false,
    price: formatSwapPrice(payload.price)
  }
  return {
    ...trailPrice,
    cards: [
      card,
      ...uniqBy(cards, 'id'),
    ]
  }
}

export const swapRemoveCard = (trailPrice, excludeId) => {
  const { cards = [] } = trailPrice;
  return {
    ...trailPrice,
    cards: cards.filter(({ id }) => id !== excludeId)
  }
}

export const swapUpdateCard = (trailPrice, card) => {
  const { cards = [] } = trailPrice || {};
  const findIndex = cards.findIndex(({ id }) => id === card.id);
  const newCard = {
    ...cards[findIndex],
    ...card,
    price: formatSwapPrice(card.price)
  };

  if (findIndex >= 0) {
    cards[findIndex] = newCard;
  };
  return {
    ...trailPrice,
    cards
  }
}

export const setCursor = (trailPrice) => {
  const { cards = [] } = trailPrice;
  return cards[cards.length - 2]?.cursor
}

export default function priceReducer(state: IPriceReducerState = PRICING_INIT_STATE, action: Action): IPriceReducerState {
  try {
    switch (action.type) {
      case PRICING_SET_INITIAL_STATE:
        return { ...PRICING_INIT_STATE }
      case PRICING_FORM_LOAD:
        return {
          ...PRICING_INIT_STATE,
          formStructures: state.formStructures //fix issue when switch beetween structures
        };

      case PRICING_ACCUMULATOR_DATA_LOADED:
        return {
          ...state,
          ...action.payload,
        };

      case PRICING_LOADER:
        return { ...state, isLoading: action.payload }

      case PRICING_RESULTS_LOAD:
        return { ...state, isLoadingResults: action.payload }

      // TODO: will be updated in future

      case PRICING_VANILLA_FORM_LOADED:
        return { ...state, ...action.payload };

      case PRICING_FORM_LIMITATION:
        return { ...state, limitation: action.payload.limitation };

      case PRICING_STRUCTURE_UPDATE_DETAILS:
        return { ...state, structureDetails: action.payload };

      case PRICING_RESET:
        return { ...PRICING_INIT_STATE };

      case PRICING_SET_FIELDS_PARAMS:
        if (action.structure) {
          let [currentFormConfig] = action.structure && action.structure.forms ? action.structure.forms : [];
          return {
            ...state,
            structureConfig: action.structure,
            currentFormConfig,
          };
        } else {
          return {
            ...state,
            structureConfig: null,
            currentFormConfig: null,
          };
        }

      case PRICING_SELECT_SUB_FORM:
        return {
          ...state,
          currentFormConfig: state.structureConfig && state.structureConfig.forms ?
            state.structureConfig.forms.find(({ name }) => name === action.payload) : null,
        };

      case PRICING_CREATE_FIELDS_PARAMS:
        return { ...state, fields: [...state.fields, action.payload] };

      case PRICING_UPDATE_FIELDS_PARAMS:
        const updateFormInput = form => update(form, {
          input: {
            $apply: (inputs) => inputs.map(
              input => {
                const props = omit(action.payload, '__typename');
                return input.name === action.name
                  ? { ...input, ...props }
                  : input;
              }
            )
          }
        });
        return update(state,
          {
            structureConfig: {
              forms: {
                $apply: (forms) => forms.map(updateFormInput),
              },
            },
            currentFormConfig: {
              $apply: updateFormInput,
            }
          }
        );

      case PRICING_UPDATE_TRAIL_RPICE:
        return {
          ...state,
          trailPrice: action.payload,
        };

      case PRICING_DELETE_FIELDS_PARAMS:
        return { ...state, fields: state.fields.filter(({ name }) => name !== action.payload) };

      case PRICING_UPDATE_UNDERLINE:
        return { ...state, underlying: action.underlying };

      case PRICING_UPDATE_STRUCTURE:
        return { ...state, structure: action.structure };

      case PRICING_SET_FORM_STRUCTURES:
        return { ...state, formStructures: action.payload };

      // Swap
      // Single
      case PRICING_SWAP_CARD_CREATE:
        return { ...state, trailPrice: swapAddCard(state.trailPrice, action.payload) }

      case PRICING_SWAP_CARD_DELETE:
        return { ...state, trailPrice: swapRemoveCard(state.trailPrice, action.payload) }

      case PRICING_SWAP_CARD_UPDATE:
        return { ...state, trailPrice: swapUpdateCard(state.trailPrice, action.payload) }

      case PRICING_SWAP_DATA_LOADED:
        return { ...state, ...action.payload };
      // Multiple
      case PRICING_SWAP_CARDS_UPDATE:
        return { ...state, trailPrice: action.payload }

      case PRICING_SET_CHART_DATA:
        return { ...state, chart: action.payload }

      case PRICING_SET_STATIC_CURSOR_DATA:
        return { ...state, staticCursor: setCursor(action.payload) }

      case PRICING_SWAP_REFETCH_EXPIRY:
        return { ...state, refetchedExpiries: state.refetchedExpiries + 1 }

      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
