import { STRUCTURE_AUTOCALLABLE } from 'constants.js';
import { BARRIER_TYPE_EUROPEAN } from 'pages/price/FormItem';
import { OPTION_NO_DELAY } from 'redux/epics/structured-products/form/options/firstObservationIn';
import { ISSUER_DEFAULT, DEFAULT_COUPON_DIRECTION } from '../../constants';

export const messageBarrierLevel1 = 'Please note that Barrier Level at 1 should be used only to price Capital Protected Structures';

export const BARRIER_LEVEL_MIN = 49;
export const STRIKE_LEVEL_MAX = 150;
export const STRIKE_LEVEL_MIN = 49;
export const COUPON_LEVEL_MIN = 0;
export const COUPON_LEVEL_MAX = 100;
export const REOFFER_MIN = 0;
export const REOFFER_MAX = 200;
export const COUPON_TRIGGER_LEVEL_MIN = 50;
export const COUPON_TRIGGER_LEVEL_MAX = 150;
export const AUTOCALL_TRIGGER_LEVEL_MIN_DEFAULT = 50;
export const AUTOCALL_TRIGGER_LEVEL_MIN = 80;
export const AUTOCALL_TRIGGER_LEVEL_MAX = 150;
export const COUPON_TRIGGER_LEVEL_DEFAULT = 50;
export const CONDITIONAL_COUPON_TYPE = 'Conditional';
export const ONE_STAR_THRESHOLD_MAX = 150;
export const ONE_STAR_THRESHOLD_MIN = 85;


export const PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES = {
  structure: STRUCTURE_AUTOCALLABLE,
  issuer: ISSUER_DEFAULT,
  currency: 'USD',
  maturity: 12,
  autocall: 'yes',
  frequency: 3,
  firstObservationIn: OPTION_NO_DELAY,
  autocallTriggerLevel: 100,
  couponType: 'Guaranteed',
  memoryCoupon: 'yes',
  solveFor: 'Reoffer',
  strikeLevel: 100,
  downsideLeverage: 100,
  barrierType: BARRIER_TYPE_EUROPEAN,
  barrierLevel: 75,
  step: 2,
  legalShape: 'Note',
  spread: 0,
  oneStarThreshold: 100,
  couponTriggerLevel: COUPON_TRIGGER_LEVEL_DEFAULT,
  couponDirection: DEFAULT_COUPON_DIRECTION
};
