import store from 'store.js';
import { notificationClearCacheFEVersion } from 'redux/errors/actions';

const APP_REFRESH_TIMEOUT = 5 * 60 * 1000;
export default async function startCheckingAppVersion() {
  console.log('starting app version check');
  if (!window['fetch']) {
    console.error('browser does not support fetch');
    return;
  }

  // periodically compare version on server copy of index.html against in memory copy
  // this is not ideal, but at the moment we don't have an end-point to provide this info
  // in future we may want a dedicated end-point that can also provide release notes to client
  doVersionCheckLoop();
};

async function doVersionCheckLoop() {
  try {
    // index.html should be setup not to use caching on server but add query just in case
    const response = await fetch('/index.html?v=' + new Date().getTime());
    const text = await response.text();
    const vers = extractValue(text, 'window.VERSION');
    console.debug('version check', { server: vers, local: window.VERSION });
    if (vers !== window.VERSION) {
      // todo: we could detect if major minor version change and change behaviour
      // e.g. if major version change automatically force refresh instead of popup
      console.warn('version check: server has a different version to client', { server: vers, local: window.VERSION })
      store.dispatch(notificationClearCacheFEVersion());
    }
  } catch (e) {
    console.error('failed to fetching version info', e);
  }

  setTimeout(() => doVersionCheckLoop(), APP_REFRESH_TIMEOUT);
};

function extractValue(text, name) {
  const startIdx = text.indexOf(name);
  const endIdx = text.indexOf(',', startIdx); //minify HTML version use ',' instead of ';' for window variables
  const line = text.substring(startIdx, endIdx);
  const lineValue = line.match(/"([^"]+)"/);
  const value = lineValue && lineValue[1];
  return value;
};
