import moment from 'moment';
import validateJs from 'utils/validate.js';


const { datetime } = validateJs.validators;
validateJs.validators.settlementDate = datetime.bind({
  parse: (value) => +moment.utc(value),
  format: (value) => moment.utc(value).toISOString()
});

const SIZE_PER_SETTLEMENT_SCHEMA = {
  notional: {
    presence: true,
    numericality: {
      greaterThan: 0,
    },
  },
  legs: {
    presence: true,
    array: {
      notional: {
        presence: true,
        numericality: {
          greaterThanOrEqualTo: 0,
          message: (value, attribute, { greaterThanOrEqualTo }, { contractExpiry }) => `: ${moment(contractExpiry).format('MMM-YY')} must be greater than or equal to ${greaterThanOrEqualTo}`
        },
      },
      contractExpiry: {
        presence: true,
      }
    }
  }
}

const SCHEMA = {
  unit: {
    presence: true,
  },
  underlyingContracts: {
    presence: true,
  },
  ...SIZE_PER_SETTLEMENT_SCHEMA
};

export const validateLegs = (data) => {
  return validateJs(data, SIZE_PER_SETTLEMENT_SCHEMA) || {};
}

export default function validate(data) {
  const { month } = data;
  SCHEMA.firstMonth = {
    presence: true,
  };
  SCHEMA.lastMonth = {
    presence: true,
    settlementDate: {
      earliest: +moment.utc(month),
      message: `cannot be before First Month`
    }
  };

  return validateJs(data, SCHEMA) || {};
}
