import { getAuthToken } from 'auth/';
import gql from 'graphql-tag';
import React, { PureComponent } from 'react';
import { Subscription } from 'react-apollo';
import NotifyMeDropdown from './NotifyMeDropdown';

const NOTIFYME_SUBSCRIPTION = gql`subscription notifyMeSubscription ($authorization: String!){
  notifyMeCheck (authorization: $authorization) {
    id
    value
  }
}`;

class NotifyMeSubscription extends PureComponent {
  render() {
    return (
      <Subscription
        subscription={NOTIFYME_SUBSCRIPTION}
        variables={{ authorization: getAuthToken() }}>
        {({ data }) => {
          return <NotifyMeDropdown className="nav-item-icon" notifyMe={data && !data.loading && data.notifyMeCheck ? data.notifyMeCheck : null} />
        }}
      </Subscription>
    );
  }
}

export default NotifyMeSubscription;
