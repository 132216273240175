import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL } from './fileldsAdditional';
import { STRUCTURED_PRODUCTS_FORM_FIELDS, STRUCTURED_PRODUCTS_FORM_NOTIONAL, FIELD_TYPE_DEFAULT } from '../../fields';
import { InlineSelectInput, InlineInput, RadioGroup } from '@agile/react-components';
import { PRICING_FORM } from 'redux/actions/price';
import { Field } from 'redux-form';

const CAPITAL_PROTECTION_FORM_FIELDS = [
  ...STRUCTURED_PRODUCTS_FORM_FIELDS,
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "basketType",
    label: <FormattedMessage id="form.price.fields.basket-type.label"
      defaultMessage="Basket Type" />,
    placeHolder: 'Enter Basket Type',
    justValue: true,
    component: RadioGroup,
    FieldComponent: Field,
    optionsName: 'basketTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "maturity",
    label: <FormattedMessage id="form.price.fields.payout-maturity.label"
      defaultMessage="Maturity" />,
    placeHolder: 'Enter Maturity',
    justValue: true,
    action: PRICING_FORM,
    component: InlineSelectInput,
    optionsName: 'maturityOptions',
    colOptions: { xs: 12, sm: 6 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "capitalProtectionLevel",
    label: <FormattedMessage id="form.price.fields.autocall-trigger-level.label"
      defaultMessage="Capital Protection Level" />,
    placeHolder: 'Enter Capital Protection Level',
    type: "number",
    step: 1,
    min: 0,
    max: 120,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "optionType",
    label: <FormattedMessage id="form.price.fields.option-type.label"
      defaultMessage="Option Type" />,
    placeHolder: 'Enter Option Type',
    justValue: true,
    component: RadioGroup,
    FieldComponent: Field,
    optionsName: 'optionTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "strikeLevel",
    label: <FormattedMessage id="form.price.fields.strike-level.label"
      defaultMessage="Strike Level" />,
    placeHolder: 'Enter Strike Level',
    type: "number",
    step: 1,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 6 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "participationLevel",
    label: <FormattedMessage id="form.price.fields.participation-level.label"
      defaultMessage="Participation Level" />,
    placeHolder: 'Enter Participation Level',
    type: "number",
    step: 1,
    min: 0,
    max: 500,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 6 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "capType",
    label: <FormattedMessage id="form.price.fields.cap-type.label"
      defaultMessage="Cap Type" />,
    placeHolder: 'Enter Cap Type',
    justValue: true,
    component: RadioGroup,
    FieldComponent: Field,
    optionsName: 'capTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "capLevel",
    label: <FormattedMessage id="form.price.fields.cap-level.label"
      defaultMessage="Cap Level" />,
    placeHolder: 'Enter Cap Level',
    type: "number",
    step: 1,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 },
    isVisible: false,
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "barrierType",
    label: <FormattedMessage id="form.price.fields.barrier-type.label"
      defaultMessage="Barrier Type" />,
    justValue: true,
    component: RadioGroup,
    FieldComponent: Field,
    optionsName: 'barrierTypeOptions',
    colOptions: { xs: 12 },
    isVisible: false,
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "barrierLevel",
    label: <FormattedMessage id="form.price.fields.barrier-level.label"
      defaultMessage="Barrier Level" />,
    placeHolder: 'Enter Barrier Level',
    type: "number",
    step: 1,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 },
    isVisible: false,
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "rebateLevel",
    label: <FormattedMessage id="form.price.fields.rebate-level.label"
      defaultMessage="Rebate Level" />,
    placeHolder: 'Enter Rebate Level',
    type: "number",
    step: 1,
    min: 85,
    max: 150,
    action: PRICING_FORM,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 },
    isVisible: false,
  },
  ...CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL,
  ...STRUCTURED_PRODUCTS_FORM_NOTIONAL,
];

export const CAPITAL_PROTECTION_FORM_FIELDS_KEYS_ARRAY = [];
export const CAPITAL_PROTECTION_FORM_FIELDS_KEYS = {};
CAPITAL_PROTECTION_FORM_FIELDS.forEach(({ name, isVisible = true }) => {
  CAPITAL_PROTECTION_FORM_FIELDS_KEYS[name] = isVisible;
  CAPITAL_PROTECTION_FORM_FIELDS_KEYS_ARRAY.push(name);
});

export const CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS = {};
export const CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS_ARRAY = [];
CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL.forEach(({ name, isVisible = true, skip = false }) => {
  CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS[name] = isVisible;
  if (!skip) CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL_KEYS_ARRAY.push(name);
});

export default CAPITAL_PROTECTION_FORM_FIELDS;
