import isNaN from 'lodash/isNaN';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const itemStyle = item => ({ color: item.color });

const ProductChartTooltip = ({ payload }) => {
  return (
    <div className="product-chart-tooltip">
      <table>
        <tbody>
          {payload
            .map(item => {
              return (!isNaN(item.value) ? <tr className="py-1" key={item.dataKey}>
                <td className="px-2">
                  <span style={itemStyle(item)}>{item.name}:</span>
                </td>
                <td className="px-2">
                  <FormattedNumber value={item.value} minimumFractionDigits={2} maximumFractionDigits={2} />
                </td>
              </tr> : null)
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ProductChartTooltip;
