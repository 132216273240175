import gql from 'graphql-tag';

export const CREATE_CUSTOM_PRICING_MUTATION = gql`mutation createCustomPricingRequestMutation(
  $structure: String!
  $status: CustomPricingRequestStatusType!
  $type: CustomPricingRequestTypeType!
  $underlying: String!
  $currency: String!
  $expiredAt: DateTime!
  $notional: String!
  $direction: CustomPricingRequestDirectionType!
  $structureDetails: String!
) {
  createCustomPricingRequest (
    structure: $structure
    status: $status
    type: $type
    underlying: $underlying
    currency: $currency
    expiredAt: $expiredAt
    notional: $notional
    direction: $direction
    structureDetails: $structureDetails
  ) {
    payload {
      id
    }
  }
}`;
