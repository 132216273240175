import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import gql from 'graphql-tag';

// @TODO: add fragments
export const PRICING_HISTORY_QUERY = gql`query fetchPricingHistory (
  $cursor: String
  $sorter: [UserAccountPricingHistorySorterType]
  $filter: [UserAccountPricingHistoryFilterType]
) {
  userCurrent {
    id
    calculateHistory: pricingHistory (
      first: 30
      after: $cursor
      sorter: $sorter
      filter: $filter
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          ... on PricingAccumulatorHistoryType {
            id
              marketData {
                id
                structure
              }
              accumulatorUnderlyings {
                id
                currency
              }
              optionExpiryDate
              marketDataDisabled
              expired
              futValPt
              notional
              quoteUnits
              quotedCurrency
              barrierLevel
              commodityContract
              bloombergTicker
              contractExpiry
              type
              structure
              updatedAt
              createdAt
              priceToDisplay
              price
              type
              notionalPhysical
              spotNotional
              timeToExpiry
              accumulationLevel: strikeLevel
              strikeLevel
              referencePrice
              futTradingUnits
              barrierType
              commodityContract
              remainderFeature
              remainderLevel
              leverage
              leverageStyle
              accumulationStyle
            }
        }
      }
    }
  }
}`;

export const COMODITY_CONTRACTS_OPTIONS_QUERY = gql`query {
  pricingHistoryCommodityContract
}`;

export const STRUCTURES_OPTIONS_QUERY = gql`query {
  pricingHistoryStructure
}`;

export const PRICING_TYPE_OPTIONS_QUERY = gql`query {
  pricingHistoryType
}`;

export const PRICING_BARRIER_TYPE_OPTIONS_QUERY = gql`query {
  pricingHistoryBarrierType
}`;

export const PRICING_REMAINDER_FEATURE_OPTIONS_QUERY = gql`query {
  pricingHistoryRemainderFeature
}`;

export const PRICING_CURRECNY_OPTIONS_QUERY = gql`query {
  pricingHistoryQuotedCurrency
}`;

export const PRICING_LEVERAGE_STYLE_OPTIONS_QUERY = gql`query {
  pricingHistoryLeverageStyle
}`;

export const PRICING_LEVERAGE_OPTIONS_QUERY = gql`query {
  pricingHistoryLeverage
}`;

export const PRICING_ACCUMULATION_STYLE_OPTIONS_QUERY = gql`query {
  pricingHistoryAccumulationStyle
}`;

export const PRICING_HISTORY_QUERY_OPTIONS = {
  options: {
    fetchPolicy: GRAPHQL_NETWORK_ONLY
  },
  props({ data: { loading, userCurrent: { calculateHistory } = {}, fetchMore, refetch, variables, ...rest } }) {
    return {
      refetch,
      loading,
      calculateHistory,
      loadMore: () => {
        return fetchMore({
          query: PRICING_HISTORY_QUERY,
          variables: {
            ...variables,
            cursor: calculateHistory.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.userCurrent.calculateHistory.edges;
            const pageInfo = fetchMoreResult.userCurrent.calculateHistory.pageInfo;
            const updatedData = [...previousResult.userCurrent.calculateHistory.edges, ...newEdges];
            return newEdges.length ? {
              userCurrent: {
                ...previousResult.userCurrent,
                calculateHistory: {
                  __typename: previousResult.userCurrent.calculateHistory.__typename,
                  edges: updatedData,
                  pageInfo
                },
              },
            } : previousResult;
          },
        });
      },
      variables,
      ...rest
    };
  }
};
