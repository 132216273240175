import { actions } from 'react-redux-alerts';

export const USER_TOKEN_EXPIRED = 'USER_TOKEN_EXPIRED';
export const API_FAIL = 'API_FAIL';
export const FE_CACHED = 'FE_CACHED';
export const USER_ONLINE_STATUS = 'USER_ONLINE_STATUS';

export function userSessionExpired(trail) {
  return actions.createAlert(USER_TOKEN_EXPIRED, { trail });
}

export function notificationApiError() {
  return actions.createAlert(API_FAIL);
}

export function notificationClearCacheFEVersion() {
  return actions.createAlert(FE_CACHED);
}

export function notificationUserOnlineStatus(online) {
  return actions.createAlert(USER_ONLINE_STATUS, { online });
}
