export const compareValues = (a, b) => a === b;

export const LIMITATION_RELATION_ACCUMULATOR_MINI = [
  {
    key: 'commodity',
    filled: true,
    limitationKey: 'commodity',
    sheetExtractor: record => record.commodity,
    compare: compareValues,
  },
  {
    key: 'contractExpiry',
    filled: true,
    limitationKey: 'contractExpiries',
    sheetExtractor: record => record.contractExpiry,
    compare: compareValues,
  },
  {
    key: 'expiration',
    filled: true,
    limitationKey: 'structureExpiredAt',
    sheetExtractor: record => record.expiryDate,
    compare: compareValues,
  },
];
