import PropTypes from 'prop-types';
import React from 'react';

function TableHeaderSorterItem(props) {
  const { value, label, onChange, selectedSortField, isDisabled, className } = props;

  const changeOrder = () => {
    onChange(value);
  }

  const active = selectedSortField === value ? ' active' : '';
  const additionaClass = `${className ? ` ${className}` : ''}`;
  const orderClass = !isDisabled ? 'on' : 'off';
  const elClassName = `app-table-col size-${value} col-align col-order order-${orderClass} text-left${active}${additionaClass}`;
  return (
    <div
      className={elClassName}
      onClick={!isDisabled ? changeOrder : null}>
      {label}
    </div>
  )
}

TableHeaderSorterItem.defaultProps = {
  isDisabled: false
}

TableHeaderSorterItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedSortField: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default TableHeaderSorterItem;
