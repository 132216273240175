import TableEmpty from 'components/common/table/EmptyTable';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const TradesTableEmpty = () => {
  return (
    <TableEmpty>
      <div className="page-msg">
        <FormattedMessage id="custom-requests/empty-message"
          defaultMessage="You haven't any Trades yet, so it's empty." />
      </div>
    </TableEmpty>
  )
}

export default TradesTableEmpty;
