import autocallableFormProps from '../autocallable/autocallableFormProps';
import { PRICING_FORM_FIXED_COUPON_NOTE_INITIAL_VALUES } from './constants';

const fixedCouponNoteFormProps = (state, formOptions) => {
  const props = autocallableFormProps(state, formOptions);
  const { initialValues } = props;
  return {
    ...props,
    initialValues: {
      ...initialValues,
      ...PRICING_FORM_FIXED_COUPON_NOTE_INITIAL_VALUES,
    },
  }
}

export default fixedCouponNoteFormProps;
