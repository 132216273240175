import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { combineEpics, ofType } from 'redux-observable';
import { pricingResultsLoader, setStaticCursorData } from 'redux/actions/price';
import { PRICING_FX_CARDS_FETCH_MORE, PRICING_FX_DATA_LOADED, fxCardsUpdate, PRICING_FX_FORM_OUTPUT } from 'redux/actions/fx';
import { loadFxCardsData } from 'redux/queries/fx';
import { from } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { fxTrailPricesFilter } from '..';
import { touch } from 'redux-form';

const generateTouchActions = (cards = []) => cards.map( i => touch(`${PRICING_FX_FORM_OUTPUT}_${i.id}`, 'notional'));

export const loadFxCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FX_DATA_LOADED),
      filter(({ payload }) => payload && !isEmpty(payload.pricings)),
      switchMap(() => {
        const limit = state$.value.auth.account.cardsLimit
        return from(loadFxCardsData(null , limit)).pipe(
          switchMap(data => {
            const actions = [
              fxCardsUpdate(data),
              setStaticCursorData(data),
              pricingResultsLoader(false),
              ...generateTouchActions(data?.cards),
            ];
            return from(actions);
          })
        )
      })
    );

export const fetchMoreFxCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FX_CARDS_FETCH_MORE),
      filter(({ payload }) => !!payload && !!state$.value.fx && !!state$.value.fx.trailPrice && !!state$.value.fx.trailPrice.pageInfo),
      filter(() => fxTrailPricesFilter(state$)),
      map(({ payload }) => payload),
      switchMap(({cursor, limit}) => {
        return from(loadFxCardsData(cursor, limit)).pipe(
          filter(data => data && Array.isArray(data.cards)),
          switchMap(data => {
            const oldCards = state$.value.fx.trailPrice && state$.value.fx.trailPrice.cards ? state$.value.fx.trailPrice.cards : [];
            const actions = [
              fxCardsUpdate({
                ...data,
                cards: uniqBy([
                  ...oldCards,
                  ...data.cards,
                ], 'id')
              }),
              ...generateTouchActions(data?.cards),
              pricingResultsLoader(false),
            ];
            return from(actions).pipe(
              delay(180) //Animation time
            );
          })
        )
      })
    );

export default combineEpics(
  loadFxCardsEpic,
  fetchMoreFxCardsEpic,
);
