import React, { Fragment } from 'react';
import CountDown from 'components/common/countdown/CountDown';
import TradeModal from 'pages/price/mini/components/trade/TradeModal';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { REFERENCE_TYPES_OPTIONS } from '../constants';
import DynamicFields, { ContractExpiry, Expiry, ReferencePrice } from '../fields/';
import { filterDayOfWeek, WEEK_DAYS } from '../validate';
import PricingMiniControls from 'pages/price/mini/components/pricinigMiniForm/PricingMiniControls';

const PricinigMiniFormDefault = props => {
  const {
    id,
    invalid,
    isLoading = false,
    contractExpiries = [],
    referenceTypes = [],
    trailId,
    form,
    futTradingUnits,
    tradeId,
    countDown,
    getReferencePrice,
    onChangeReferenceType,
    fields,
    isDisabledReference,
    structureExpiredAtMax,
    isDisabled,
    labelBtnLoading,
    labelBtnPrice,
    isDisabledTradeBtn,
    expiryAllowedWeekDays,
    formData,
    isFixings,
    bankHolidays,
    greeksDisplay,
    deltaPercentage,
    isCalculating,
    onStopReferencePrice,
  } = props;

  const { scheduleDates = ' ' } = formData || { scheduleDates: ' ' };
  const filterExpiryDate = filterDayOfWeek(expiryAllowedWeekDays);

  return (
    <Fragment>
      <div className="form-card-mini-content">
        <PricingMiniControls {...props} />
        <Row className="row-size-xs">
          <Col xs={12}>
            <div className="fileds-toggle-group">
              <ContractExpiry
                contractExpiries={contractExpiries}
                isLoading={isLoading} />
              <ReferencePrice
                id={id}
                isLoading={isLoading || isCalculating}
                referenceTypes={referenceTypes}
                form={form}
                getReferencePrice={getReferencePrice}
                onChangeReferenceType={onChangeReferenceType}
                isDisabledReference={isDisabledReference}
                isCalculating={isCalculating}
                onStopReferencePrice={onStopReferencePrice}
              />
            </div>
          </Col>
          <DynamicFields id={id} isLoading={isLoading} form={form} fields={fields} />
        </Row>
      </div>
      <div className="mt-auto w-100 form-card-mini-content form-card-mini-footer">
        <Expiry
          filterDate={filterExpiryDate}
          structureExpiredAtMax={structureExpiredAtMax}
          isLoading={isLoading}
          scheduleDates={scheduleDates}
          bankHolidays={bankHolidays}
          greeksDisplay={greeksDisplay}
        />
        {greeksDisplay ?
          <Row className="mb-3">
            <Col xs={6} className="pr-1">
              <div className="pre-label expiry-label">
                Delta <span className="pl-2 font-weight-bold">{deltaPercentage ? `${deltaPercentage}%` : '-'}</span>
              </div>
            </Col>
            <Col xs={6} className="pr-1 pl-1"></Col>
          </Row>
          : null}
        <Row className="mb-1">
          <Col xs={6} className="pr-1">
            <Button
              type="submit"
              color="primary"
              className={"px-1 w-100 btn-input-h text-uppercase"}
              disabled={isLoading || isDisabled || invalid}>
              {!invalid && !isDisabled && isLoading ? labelBtnLoading : labelBtnPrice}
            </Button>
          </Col>
          <Col xs={6} className="pl-1">
            <TradeModal
              countDown={countDown}
              trailId={trailId}
              cardId={id}
              futTradingUnits={futTradingUnits}
              disabled={isLoading || isDisabledTradeBtn || !isFixings} />
          </Col>
        </Row>
        {!isDisabledTradeBtn && trailId && !tradeId ?
          <CountDown showCounter={false} count={countDown} showProgress={true} />
          : null}
      </div>
    </Fragment>
  );
};

PricinigMiniFormDefault.defaultProps = {
  contractExpiries: [],
  referenceTypes: REFERENCE_TYPES_OPTIONS,
  isLoading: false,
  selectedUnderlying: null,
  fields: [],
  isTrade: false,
  isFactSheet: true,
  expiryAllowedWeekDays: WEEK_DAYS,
};

PricinigMiniFormDefault.propTypes = {
  contractExpiries: PropTypes.array.isRequired,
  referenceTypes: PropTypes.array.isRequired,
  structureExpiredAt: PropTypes.array.isRequired,
  miniAccumulatorCardGetReferencePrice: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  selectedUnderlying: PropTypes.object,
  trailId: PropTypes.string,
  tradeId: PropTypes.string,
  isTrade: PropTypes.bool.isRequired,
  isFactSheet: PropTypes.bool.isRequired,
  expiryAllowedWeekDays: PropTypes.array.isRequired,
};

export default PricinigMiniFormDefault
