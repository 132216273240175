import { STRUCTURE_VANILLA, INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { PRICINIG_VANILLA_FORM_OUTPUT } from 'pages/price/output/vanilla/VanillaPricnigOutputCardForm';
import { combineEpics, ofType } from 'redux-observable';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { loadedVanillaData, pricingDataLoader, PRICING_VANILLA_FORM_LOADED, PRICING_VANILLA_FORM_LOAD, setLimitationForm, updateTrailPrice } from 'redux/actions/price';
import { PRICING_SWAP_CARD_START_DELETE, swapCardUpdate } from 'redux/actions/swap';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { LIMITATION_RELATION_VANILLA } from 'redux/prices/config-vanilla';
import { loadAllVariable } from 'redux/queries/price';
import { loadVanillaData } from 'redux/queries/vanilla';
import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { changeAnyFormFieldActionFilter, changeFormRadioFieldActionFilter } from 'utils/reduxFormSelector';
import { priceFormDestroyFilter } from '../../filters';
import cardsEpics from './cards/';
import { getCardByFormName, getCardById } from './utils';

export const vanillaFormFilter = action => action && action.meta && !action.meta.form.indexOf(PRICINIG_VANILLA_FORM_OUTPUT)

export const vanillaTrailPricesFilter = state$ => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_VANILLA && Array.isArray(state$.value.price.trailPrice.cards);

export const loadVanillaForm = action$ =>
  action$
    .pipe(
      ofType(PRICING_VANILLA_FORM_LOAD),
      switchMap(() => {
        return from(loadVanillaData())
          .pipe(
            takeUntil(action$.pipe(filter(
              action => action.type === AUTH_LOGOUT ||
                priceFormDestroyFilter(action)
            )))
          );
      }),
      switchMap(data => {
        const actions = [
          loadedVanillaData({ pricings: data }),
        ]
        return from(actions);
      })
    );

export const vanillaLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_VANILLA_FORM_LOADED),
      map(({ payload }) => payload && payload.pricings),
      filter(data => !!data),
      map(data => {
        const formData = priceFormValueSelector(state$.value);
        return loadAllVariable(formData, data, LIMITATION_RELATION_VANILLA);
      }),
      map((limitation) => {
        return setLimitationForm({
          limitation,
          limitationRelation: LIMITATION_RELATION_VANILLA
        })
      }),
    );

export const loadVanillaFinish = action$ =>
  action$.pipe(
    ofType(PRICING_VANILLA_FORM_LOADED),
    switchMap(() => from([
      pricingDataLoader(false),
      updateTrailPrice(null)
    ]))
  );


export const cardChangeFieldsLoaderEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('contractExpiry')),
      filter(vanillaFormFilter),
      debounceTime(50),
      filter(({ payload }) => !!payload),
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('quantity')),
      filter(vanillaFormFilter),
      debounceTime(50),
      filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE),
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('strikeLevel')),
      filter(vanillaFormFilter),
      debounceTime(50),
      filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE),
    ),
    action$.pipe(
      filter(changeFormRadioFieldActionFilter('structureType')),
      filter(vanillaFormFilter),
      debounceTime(50),
      filter(({ payload }) => !!payload),
    ),
  ).pipe(
    filter(() => vanillaTrailPricesFilter(state$)),
    map(({ meta }) => {
      const card = getCardByFormName(state$, meta);
      return swapCardUpdate({
        ...card,
        isLoading: true //Use to disable submit button
      });
    })
  );

export const cardLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SWAP_CARD_START_DELETE),
    filter(() => vanillaTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    switchMap(id => {
      const card = getCardById(state$.value.price.trailPrice.cards, id)
      const actions = [
        swapCardUpdate({
          ...card,
          loading: true
        })];
      return from(actions);
    })
  );

export default combineEpics(
  loadVanillaForm,
  loadVanillaFinish,
  vanillaLifeEpic,
  cardLoaderEpic,
  cardChangeFieldsLoaderEpic,
  cardsEpics,
);
