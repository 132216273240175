import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const NoMatchPage = props => (
  <ErrorBoundary>
    <PageMeta title="Page Not Found" />
    <Bundle title="Page Not Found" load={() => import('./NoMatch')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default NoMatchPage;
