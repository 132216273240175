import gql from 'graphql-tag';

export const PRICING_FX_TRADE_MUTATION2 = gql`mutation fxTradeMutation(
  $id: String!
  $price: Float
  $quoteId: String
  $orderType: String
  $timeInForce: String
  $expiryDate: DateTime
  $slackIceClientsId: ID
  $priceForCalcSpread: Float
) {
  fxTrade (
    cardId: $id
    price: $price
    quoteId: $quoteId
    orderType: $orderType
    timeInForce: $timeInForce
    expiryDate: $expiryDate
    slackIceClientsId: $slackIceClientsId
    priceForCalcSpread: $priceForCalcSpread
  ) {
    id
    status,
  }
}`;
