import { priceFormPropTypes } from "pages/price/PriceForm/forms/priceFormPropTypes";
import React, { cloneElement } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import {
  setStructuredProductsLoader,
  structuredProductsResultsLoader,
} from "redux/actions/structured-products";

function TypesForm(props) {
  const {
    submitting,
    invalid,
    pristine,
    handleSubmit,
    reset,
    children,
    structure,
    scheduleInvalid,
    ...rest
  } = props;
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    dispatch(structuredProductsResultsLoader(true));
    dispatch(setStructuredProductsLoader(true));
    handleSubmit(e);
  };

  return (
    <Form
      onSubmit={onSubmit}
      onReset={reset}
      noValidate
      className={`price-form-container price-form-${structure}`}
    >
      <div className="strucutred-products-form-fields">
        {cloneElement(children, rest)}
      </div>
      <Row>
        <Col xs={6} sm={4} xl={{ size: 3, offset: 6 }}>
          <Button
            color="default"
            type="reset"
            className="w-100"
            disabled={pristine || submitting}
          >
            <FormattedMessage id="form.price.reset" defaultMessage="Reset" />
          </Button>
        </Col>
        <Col xs={6} sm={{ size: 4, offset: 4 }} xl={{ size: 3, offset: 0 }}>
          <Button
            className="tour-price-btn w-100"
            color="primary"
            type="submit"
            disabled={invalid || submitting || scheduleInvalid}
          >
            <FormattedMessage id="form.price.submit" defaultMessage="Price" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

TypesForm.propTypes = {
  ...priceFormPropTypes,
};

export default TypesForm;
