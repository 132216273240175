import InlineInput from 'components/form/InlineInput';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';

const StopPriceField = ({ formatMessage }) => (
  <Col xs={12} sm={4}>
    <Field
      name="stopPrice"
      type="number"
      placeHolder={formatMessage({
        id: "trade/stop_price.label",
        defaultMessage: "Stop Price",
      })}
      label={<FormattedMessage id="trade/stop_price.label"
        defaultMessage="Stop Price" />}
      step="1"
      component={InlineInput}
    />
  </Col>
);

export default StopPriceField;

