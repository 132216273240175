import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import TourTooltip from './TourTooltip';
import PropTypes from 'prop-types';

const TourTooltipFormFields = props => {
  const { required, ...rest } = props;
  const emptyValues = required ? required.filter((requireField => !props[requireField])) : null;
  const isDisabledBtn = Boolean(emptyValues && emptyValues.length);
  return <TourTooltip disabledNext={isDisabledBtn} {...rest} />
}

TourTooltipFormFields.propTypes = {
  required: PropTypes.array,
  formName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { required, formName } = props;
  const formSelector = formName ? formValueSelector(formName): null;
  let fromValues = {};
  if (required && required.length) {
    required.forEach(field => {
      fromValues[field] = formSelector(state, field);
    });
  }

  return {
    ...fromValues
  }
}

export default connect(
  mapStateToProps
)(TourTooltipFormFields);
