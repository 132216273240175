import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import { InlineInput } from 'components/form/InlineInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import { SWAP_ASIAN, SWAP_BULLET, SWAP_TYPE_OPTIONS } from 'pages/price/output/asianSwap/constants';

import { SWAP_DIRECTIONS } from './constants';
import { ROUTE_PRICING } from 'pages/constants';

const style = {
  calendarSpread: {
    position: 'absolute',
    width: '500px',
    bottom: '-30px',
  },
  swapType: {
    marginTop: '27px',
    minHeight: 0
  }
};

const SwapFormFields = props => {
  const {
    commodityContracts,
    structures,
    enableCalendarSpread,
    isBulletStrip,
    isAsianSwap,
    redirectTo,
    swapType
  } = props;

  const changeSwapType = (data) => {
    redirectTo(data === SWAP_BULLET ? `${ROUTE_PRICING}/swap/asian` : `${ROUTE_PRICING}/swap`);
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={4}>
          <Field
            name="structure"
            label={<FormattedMessage id="form.price.fields.structure.label"
              defaultMessage="Structure" />}
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            options={structures}
          />
        </Col>
        <Col xs={12} sm={8}>
          <Field
            name="commodity"
            label="Commodity Contract"
            component={InlineSelectInput}
            justValue={true}
            preselectIfOneOption={true}
            options={commodityContracts}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Field
            name="direction"
            label={<FormattedMessage id="form.price.fields.barrier-type.label"
              defaultMessage="Direction" />}
            justValue={true}
            component={RadioGroup}
            options={SWAP_DIRECTIONS}
          />
        </Col>
        {isAsianSwap ? <Col xs={12} sm={6}>
          <Field
            name="swapType"
            groupStyle={style.swapType}
            justValue={true}
            component={RadioGroup}
            options={SWAP_TYPE_OPTIONS}
            onChange={changeSwapType}
          />
        </Col> : null}
      </Row>
        {(enableCalendarSpread || isBulletStrip) && swapType !== SWAP_ASIAN ? <Row style={style.calendarSpread}>
          {enableCalendarSpread ? <Col xs={12} sm={4}>
            <Field
              type="checkbox"
              component={InlineInput}
              name="calendarSpread"
              label
              labelCheckbox={<FormattedMessage id="form.price.fields.calendar-spread.label"
                defaultMessage="Calendar Spread" />}
            />
          </Col>: null}
          {isBulletStrip ? <Col xs={12} sm={4}>
            <Field
              type="checkbox"
              component={InlineInput}
              name="bulletStrip"
              label
              labelCheckbox={<FormattedMessage id="form.price.fields.bullet-strip.label"
                defaultMessage="Bullet Strip" />}
            />
          </Col>: null}
        </Row> : null}
    </Fragment>
  )
}

export default SwapFormFields;
