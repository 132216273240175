import Loader from 'components/common/loader/Loader';
import { STRUCTURE_AUTOCALLABLE } from 'constants.js';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { destroyStructuredProductsForm, loadStructuredProductsForm, submitStructuredProductsForm } from 'redux/actions/structured-products';
import { createSelector } from 'reselect';
import { PRICING_STRUCTURED_FORM } from './constants';
import priceFormChooser from './PriceFormChooser';
import TypesForm from './types/TypesForm';

export const formValueSelectorStructuredForm = formValueSelector(PRICING_STRUCTURED_FORM);
export const formValuesSelectorStructuredForm = getFormValues(PRICING_STRUCTURED_FORM);

const mapDispatchToProps = dispatch => bindActionCreators({
  onSubmit: submitStructuredProductsForm,
  loadForm: loadStructuredProductsForm,
  destroyForm: destroyStructuredProductsForm,
}, dispatch);

export const formConfigSelector = createSelector(
  [state => state.structuredProducts],
  ({ formConfig, formOptions, isLoading, isScheduleLoading, selectedStrucutre, scheduleInvalid }) => ({
    formConfig,
    formOptions,
    isLoading,
    selectedStrucutre,
    isScheduleLoading,
    scheduleInvalid,
  })
);

export const formOptionsSelector = createSelector(
  [state => state.structuredProducts.formOptions],
  (formOptions) => formOptions
);

const mapStateToProps = state => {
  const formData = formValuesSelectorStructuredForm(state);
  const formOptions = formOptionsSelector(state);
  const structure = formData?.structure ? formData.structure : STRUCTURE_AUTOCALLABLE;
  return {
    structure,
    ...formConfigSelector(state),
    ...priceFormChooser(structure, state, formOptions)
  };
};

class PriceFormType extends PureComponent {

  constructor(args) {
    super(args);
    this.state = {
      structure: null
    }
  }

  componentWillUnmount() {
    const { destroyForm } = this.props;
    destroyForm();
  }

  componentDidMount() {
    const { loadForm } = this.props;
    loadForm();
  }

  render() {
    const { component: PriceForm, isLoading, selectedStrucutre, ...rest } = this.props;
    if (!selectedStrucutre) return <Loader />;
    return (selectedStrucutre ?
      <Fragment>
        <TypesForm structure={selectedStrucutre} {...rest}>
          <PriceForm />
        </TypesForm>
        {isLoading ?
          <Fragment>
            <Loader className="loader-center" />
            <div className="loader-overlay"></div>
          </Fragment>
          : null
        }
      </Fragment>
      : null);
  }
};

PriceFormType.propTypes = {
  structure: PropTypes.string.isRequired,
  structures: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadForm: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
  component: PropTypes.any,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: PRICING_STRUCTURED_FORM,
    enableReinitialize: true,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })
)(PriceFormType);
