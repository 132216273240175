import React from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

const defaultStyle = duration => {
  duration = duration || 300;
  return {
    overflow: 'hidden',
    height: '0px',
    transition: `height ${duration}ms ease-in-out`,
  }
};

const transitionStyles = (status, height) => {
  height = height || '100px;'
  const styles = {
    entering: { height: '0px' },
    entered: { height: `${height}px` },
  };
  return styles[status];
};

const AnimateHeight = props => {
  const { in: show, duration, height, children } = props;
  const showTransition = Boolean(show);
  return (
    <Transition in={showTransition} timeout={duration}>
      {status => (
        <div style={{
          ...defaultStyle(duration),
          ...transitionStyles(status, height)
        }}>
          {children}
        </div>
      )}
    </Transition>
  )
};

AnimateHeight.defaultProps = {
  duration: 300,
}

AnimateHeight.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
}

export default AnimateHeight;
