import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { themeImagesSelector } from 'components/theme/utils';

const NonLoggedHeader = props => {
  const { images = {} } = props;
  const loginLogo = images?.loginLogo;
  return (
    <div className="d-flex">
      <Link to="/" className="logo mx-auto align-top">
        <img src={loginLogo} alt="Logo Marex Solutions" className="img-fluid" />
      </Link>
    </div>
  );
};

const mapStateToProps = state => ({
  images: themeImagesSelector(state),
});

NonLoggedHeader.defaultProps = {
  images: {},
};

NonLoggedHeader.propTypes = {
  images: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NonLoggedHeader);
