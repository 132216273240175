import gql from 'graphql-tag';

export const QUERY_APP_VERSION = gql`query VersionFetch {
  version
}`;

export const QUERY_FOOTER_PAGES = gql`query staticPageConnectionQuery (
  $position: StaticPagePositionType!
  ) {
    staticPageConnection (
      position: $position
    ) {
      seo
      title
    }
}`;
