import React from 'react';
import { FormattedMessage } from 'react-intl';
import EmptyPricingHistoryIcon from 'components/icons/EmptyPricingHistory';

const PricingHistoryListEmpty = () => {
  return (
    <div className="mb-3">
      <div className="icon">
        <EmptyPricingHistoryIcon />
      </div>
      <h3 className="title">
        <FormattedMessage id="price.page.empty"
          defaultMessage="Your pricings history will be stored here." />
      </h3>
    </div>
  )
}

export default PricingHistoryListEmpty;
