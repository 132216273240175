import InlineInput from 'components/form/InlineInput';
import AutoComplete from 'components/form/inputs/autocomplete/AutoComplete';
import InlineDateRangePicker from 'components/form/inputs/InlineDateRangePicker/InlineDateRangePicker';
import InlinePriceInput from 'components/form/inputs/InlinePriceInput';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import InlineRadioDotsGroup from 'components/form/inputs/radio/InlineRadioDotsGroup';
import Slider from 'components/form/inputs/slider/Slider';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { inputFormatterNumber } from 'utils/formformaters';

type InputFormComponentFactoryField = {
  type: string,
  name: string,
  label?: string,
};

type InputFormComponentFactoryProps = {
  field: InputFormComponentFactoryField,
  intl?: Object
};

const InputFormComponentFactory = (props: InputFormComponentFactoryProps) => {
  const { field, intl, ...rest } = props;
  const type = field?.type;
  switch (type) {
    case 'select':
      return (
        <Field
          {...rest}
          name={field.name}
          label={field.label}
          component={InlineSelectInput} />
      );
    case 'price':
      return (
        <Field
          {...rest}
          {...field}
          component={InlinePriceInput} />
      );
    case 'formatted-number':
      return (
        <Field
          {...rest}
          {...field}
          format={inputFormatterNumber(intl)}
          component={InlineInput} />
      );
    case 'radiogroup':
      return (
        <Field
          {...rest}
          {...field}
          component={InlineRadioDotsGroup} />
      );
    case 'date-range':
      return (
        <Field
          {...rest}
          {...field}
          component={InlineDateRangePicker} />
      );
    case 'slider':
      return (
        <Field
          {...rest}
          {...field}
          component={Slider} />
      );
    case 'autocomplete':
      return (
        <Field
          {...rest}
          name={field.name}
          label={field.label}
          component={AutoComplete} />
      );
    default:
      return (
        <Field
          {...rest}
          {...field}
          component={InlineInput} />
      );
  }
};

export default injectIntl(InputFormComponentFactory);
