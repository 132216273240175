import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import InputFormComponentFactory from './InputFormComponentFactory';

type PriceDynamicFormProps = {
  formConfig: any,
  remainderFeature: string,
}

class PriceDynamicForm extends PureComponent<PriceDynamicFormProps> {
  render() {
    const { formConfig, remainderFeature } = this.props;
    let inputs = formConfig && Array.isArray(formConfig.input) ? formConfig.input : [];
    if (remainderFeature === 'none') {
      inputs = inputs.filter(({ name }) => name !== 'remainderLevel')
    }

    return (
      inputs && inputs.length ?
        <Row className="tour-dynamic-form">
          {inputs.map((field) => (
            <Col xs={12} sm={4} key={field.name}>
              <InputFormComponentFactory field={field} />
            </Col>)
          )}
        </Row>
        :
        null
    )
  }
}

const mapStateToProps = createSelector(
  ({ price: { currentFormConfig } }) => currentFormConfig,
  (currentFormConfig) => ({
    formConfig: currentFormConfig,
  })
)

export default connect(mapStateToProps)(PriceDynamicForm);
