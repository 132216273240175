import { combineEpics } from 'redux-observable';
import minifiture from './minifuture';
import cards from './cards';
import RequestProductCreation from './RequestProductCreation';

export default combineEpics(
  minifiture,
  cards,
  RequestProductCreation
);
