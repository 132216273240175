import { STRUCTURE_AUTOCALLABLE, STRUCTURE_CAPITAL_PROTECTION, STRUCTURE_TWIN_WIN, STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import UnknownStructure from 'pages/price/PriceForm/UnknownStructure';
import autocallableFormProps from './types/autocallable/autocallableFormProps';
import capitalProtectionFormProps from './types/capitalProtection/capitalProtectionFormProps';
import fixedCouponNoteFormProps from './types/fixedCouponNote/fixedCouponNoteFormProps';
import twinWinFormProps from './types/twinWin/twinWinFormProps';

export default function (structure, state, formOptions) {
  let props = {};
  const structureName = structure ? structure.toLowerCase() : 'Unknown';
  switch (structureName) {
    case (STRUCTURE_AUTOCALLABLE):
      props = autocallableFormProps(state, formOptions);
      break;
    case (STRUCTURE_TWIN_WIN):
      props = twinWinFormProps(state, formOptions);
      break;
    case (STRUCTURE_CAPITAL_PROTECTION):
      props = capitalProtectionFormProps(state, formOptions);
      break;
    case (STRUCTURE_FIXED_COUPON_NOTE):
      props = fixedCouponNoteFormProps(state, formOptions);
      break;
    default:
      return {
        structure: structureName,
        component: UnknownStructure
      }
  }
  return props;
}
