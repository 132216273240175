import EmptyTableIcon from 'components/icons/EmptyTableIcon';
import React from 'react';

const TableEmpty = props => {
  const { children } = props;
  return (
    <div className="text-center">
      <div className="page-empty-icon">
        <EmptyTableIcon />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default TableEmpty;
