import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import priceForm from './price-form';
import clearPriceForm from './clear-price-form';
import priceTour from './price-tour';
import priceTagForm from './price-tag-form';
import priceFormAccumulator from './structures/accumulator/price-form';
import structures from './structures';
import { priceFormDestroyFilter } from './filters';
import priceHistoryEpic from 'redux/prices/history/pricingHistoryEpics';
import { PRICING_INIT } from 'redux/actions/price';
import swapEpics from './structures/swap/';
import bulletStripEpics from './structures/bulletStrip';
import asianSwapEpics from './structures/asianSwap';
import vanillaEpics from './structures/vanilla/';
import fxEpics from './structures/fx/';
import pricinigsMiniEpics from './mini/';

const pricingFormLifeEpics = combineEpics(
  priceForm,
  priceTour,
  priceTagForm,
  priceFormAccumulator,
);

export const pricingLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_INIT),
      switchMap(() => {
        return pricingFormLifeEpics(action$, state$)
          .pipe(
            takeUntil(action$.pipe(filter(priceFormDestroyFilter)))
          )
      })
    );

export default combineEpics(
  structures,
  pricingLifeEpic,
  priceHistoryEpic,
  clearPriceForm,
  swapEpics,
  vanillaEpics,
  fxEpics,
  pricinigsMiniEpics,
  asianSwapEpics,
  bulletStripEpics,
);
