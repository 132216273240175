import { mergeTitle } from 'components/common/pageMeta/utils';
import gql from 'graphql-tag';
import isFunction from 'lodash/isFunction';
import { mapNodes } from 'utils';

export const dashboardReportFilterOptionsQuery = (response = 'tradeType') => gql`query otcStatementsConnectionQuery (
  $filter: [OTCStatementValueFilterType]
  $distinct: Boolean
  $dashboardId: Int!
  $isMyDashboard: Boolean
) {
  otcStatementsConnection(
    distinct: $distinct,
    dashboardId: $dashboardId,
    filter: $filter
    isMyDashboard: $isMyDashboard
  ) {
    edges{
      node {
        id
        ${response}
        __typename
      }
      __typename
    }
  }
}`;

const dashboardReportFilterOptionsQueryVariables = field => ({ dashboardid, distinct = true, dashboardId = 0, ...rest }) => ({
  ...rest,
  distinct,
  dashboardId,
  filter: {
    "field": field,
    "operator": "isNot",
    "value": null
  },
});

const dashboardReportFilterOptionsQueryRespone = field =>
  ({ otcStatementsConnection }) => mapNodes(otcStatementsConnection).map((item) => item[field]);

const optionsProviderDefault = (list) => list.map(({ title }) => ({ value: title, label: title }))

const fields = [
  {
    key: 'tradeType',
    response: 'tradeType'
  },
  {
    key: 'currency',
    response: 'currency'
  },
  {
    key: 'customer',
    response: `customer {
      title: name
      code
      id
    }`,
    optionsProvider: optionsProviderDefault,
  },
  {
    key: 'location',
    response: `location {
      id
      title: name
      code
    }`,
    optionsProvider: optionsProviderDefault,
  },
  {
    key: 'trader',
    response: `trader {
      id
      lastName
      firstName
    }`,
    optionsProvider: (list) => list.map(({ id: value, firstName, lastName }) => {
      const label = mergeTitle(firstName, lastName);
      return ({ value, label });
    })
  },
];

export const DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES = {};
fields.forEach(({ key, response, optionsProvider }) => {
  const query = dashboardReportFilterOptionsQuery(response);
  const variables = dashboardReportFilterOptionsQueryVariables(key);
  DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES[key] = {
    query,
    variables,
    queryResp: dashboardReportFilterOptionsQueryRespone(key),
    optionsProvider,
  }
});

export const extractFilterVariables = (filters = [], data = {}) => {
  return filters.map(filter => {
    const { variables: variablesFunc, placeHolder: placeHolderProp, ...rest } = filter;
    const variables = isFunction(variablesFunc) ? variablesFunc(data) : null;

    // Fix for input placeholders
    const placeHolder = ['number', 'text'].includes(filter.type) && placeHolderProp?.props?.defaultMessage ?
      placeHolderProp.props.defaultMessage : placeHolderProp;

    return {
      ...rest,
      variables,
      placeHolder,
    }
  })
}
