import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import uniq from 'lodash/uniq';
import { structuresConfigInputFielsUniq } from 'pages/price/FormItem';
import { change, untouch, isSubmitting } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { priceReset, PRICING_FORM, PRICING_FORM_FAILED, PRICING_FORM_SUBMITTED, PRICING_SELECT_SUB_FORM, PRICING_UNSELECT_SHEET, PRICING_UPDATE_STRUCTURE, PRICING_UPDATE_TRAIL_RPICE, PRICING_UPDATE_UNDERLINE, resetUnderline, setInitialStatePricinig, updateStructureDetails, updateTrailPrice, setCahrtData } from 'redux/actions/price';
import { empty, from, merge, zip } from 'rxjs';
import { combineLatest, distinctUntilChanged, filter, map, mapTo, switchMap, take } from 'rxjs/operators';
import { changeActionFilter, changeActionFilterArray } from 'utils/reduxFormSelector';
import { REDUX_FORM_FIELD_CHANGE } from '../constants';
import { priceFormDestroyFilter, priceFormResetFilter } from './filters';
import { priceFormValueSelector } from './price-form';

const actionsResetPriceOutput = [
  updateTrailPrice(null),
  updateStructureDetails(null),
  // setCahrtData(null)
];

const clearPriceDynamicFormEpic = (action$, state$) =>
  merge(
    action$.pipe(
      ofType(PRICING_UPDATE_STRUCTURE),
      map(({ id }) => id),
      distinctUntilChanged()
    ),
    action$.pipe(ofType(
      PRICING_UPDATE_UNDERLINE),
      map(({ id }) => id),
      distinctUntilChanged()
    ),
    action$.pipe(
      ofType(PRICING_SELECT_SUB_FORM),
      filter(({ payload }) => !!state$.value.price.currentFormConfig && state$.value.price.currentFormConfig.name !== payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_FORM, 'barrierType')),
      distinctUntilChanged()
    ),
  ).pipe(
    filter(() => !!state$.value.price.currentFormConfig),
    switchMap(() => {
      const state = state$.value;
      const form = state.price.currentFormConfig;
      if (form && form.input && form.output) {
        const actions = [setCahrtData(null)];
        structuresConfigInputFielsUniq
          // .filter(name => name !== form.output.name)
          .forEach((name) => {
            actions.push(
              change(PRICING_FORM, name, null),
              untouch(PRICING_FORM, name)
            )
          });
        return from(uniq(actions));
      } else {
        return empty();
      }
    }),
  );

export const clearDateStructureEpic = action$ =>
  merge(
    action$.pipe(ofType(PRICING_UNSELECT_SHEET)),
    action$.pipe(
      filter(action => action.type === REDUX_FORM_FIELD_CHANGE && action.meta.form === PRICING_FORM &&
        action.meta.field === 'notional' && !action.payload)),
  ).pipe(mapTo(updateStructureDetails(null)));

const FIELDS = [
  'barrierLevel',
  'pricePerLot',
  'accumulationLevel',
  'strikeLevel',
];

// @TODO: optimize epic call in each field change
const clearOrderTrailPriceEpic = (action$, state$) =>
  merge(
    action$.pipe(filter(priceFormResetFilter)),
    action$.pipe(ofType(PRICING_SELECT_SUB_FORM)),
    action$.pipe(filter(
      changeActionFilterArray(PRICING_FORM, ['accumulationLevel', 'barrierLevel']),
      map(({ payload }) => payload),
      distinctUntilChanged()
    )),
    action$.pipe(ofType(PRICING_FORM_FAILED)),
    action$.pipe(filter(
      changeActionFilter(PRICING_FORM, 'structure'),
      map(({ payload }) => payload),
      distinctUntilChanged()
    ))
  ).pipe(
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    filter(() => !!state$.value.price.trailPrice && !isSubmitting(PRICING_FORM)(state$.value)),
    switchMap(() => [...actionsResetPriceOutput]),
  );

const clearOrderTrailPriceAfterSubmitEpic = action$ =>
  zip(
    action$.pipe(ofType(PRICING_FORM_SUBMITTED)),
    action$.pipe(
      ofType(PRICING_UPDATE_TRAIL_RPICE),
      filter(({ payload }) => !!payload)
    ),
  ).pipe(
    switchMap(() =>
      action$.pipe(
        filter(
          action => action.type === REDUX_FORM_FIELD_CHANGE &&
            action.meta.form === PRICING_FORM &&
            !FIELDS.includes(action.meta.field)
        ),
        take(1),
        switchMap(() => [...actionsResetPriceOutput]),
      )
    )
  );

const RESET_PRICING_RESULT_ACTIONS = [
  priceReset(),
  resetUnderline(null),
  setInitialStatePricinig()
];

export const destroyPriceFormEpic = action$ =>
  action$
    .pipe(
      filter(priceFormDestroyFilter),
      switchMap(() => {
        return from(RESET_PRICING_RESULT_ACTIONS);
      })
    );

export const resetProductOutputEpic = action$ =>
  action$.pipe(
    combineLatest(
      action$.pipe(ofType(PRICING_FORM_SUBMITTED)),
      action$.pipe(filter(changeActionFilter(PRICING_FORM, 'structure')))
    ),
    switchMap(() => [
      ...actionsResetPriceOutput,
      ...RESET_PRICING_RESULT_ACTIONS,
    ])
  );

export default combineEpics(
  clearPriceDynamicFormEpic,
  clearDateStructureEpic,
  clearOrderTrailPriceEpic,
  clearOrderTrailPriceAfterSubmitEpic,
  destroyPriceFormEpic,
  // resetProductOutputEpic
);
