import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import AccumulatorChart from './accumulator/Chart';
import { PRODUCT_CHART_FRAGMENT } from './ProductChartFragment';
import { FETCH_PRICING_MARKET_DATA } from './ProductChartBlock/query';

function ProductChart(props) {
  const {
    trail,
    marketDataId,
    query,
    component: ChartComponent,
    height,
    xGap,
    marketData,
    ...rest } = props;
  return !isEmpty(marketData) ?
    <ChartComponent marketData={marketData} trail={trail} height={height} xGap={xGap} {...rest} />
    : null;
}

ProductChart.defaultProps = {
  query: FETCH_PRICING_MARKET_DATA,
  component: AccumulatorChart
}

ProductChart.propTypes = {
  trail: PropTypes.object,
  component: PropTypes.any.isRequired,
  marketData: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired
};

ProductChart.fragments = {
  trail: PRODUCT_CHART_FRAGMENT
}

export default memo(ProductChart);
