import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const BookingPage = props => (
  <ErrorBoundary>
    <PageMeta title="Booking" />
    <Bundle title="Booking Page" load={() => import('./BookingLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default BookingPage;
