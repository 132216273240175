import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import Bundle from 'pages/Bundle';
import React from 'react';

export const Single = props => (
  <ErrorBoundary>
    <Bundle title="Single Page" load={() => import('./Single')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default Single;
