import { combineEpics } from 'redux-observable';
import createEpics from './create';
import deleteEpics from './delete';
import updateEpics from './update';
import requestEpics from './request';

export default combineEpics(
  createEpics,
  deleteEpics,
  updateEpics,
  requestEpics
);
