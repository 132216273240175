import { ofType } from 'redux-observable';
import { PRICING_SWAP_CARD_START_DELETE, swapCardDelete, swapCardUpdate } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { vanillaTrailPricesFilter } from 'redux/epics/price/structures/vanilla/';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { deleteVanillaCardMutation } from 'redux/queries/vanilla';
import { from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { cardError } from '../../swap';
import { getCardById } from '../utils';

export const cardDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SWAP_CARD_START_DELETE),
    filter(() => vanillaTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap(id => {
      const card = getCardById(state$.value.price.trailPrice.cards, id);
      return from(deleteVanillaCardMutation(id))
        .pipe(
          filter(payload => !!payload),
          switchMap(() => of(swapCardDelete(id))),
          catchError((error) => {
            const actions = [
              cardError(card),
              swapCardUpdate({
                ...card,
                loading: false,
                isLoading: false
              })
            ];
            const err = getSingleGraphQlError(error);
            if (err?.message && err.code === 403) {
              return from([
                ...actions,
                notificationErrorSimple(err.message),
              ])
            }

            return from(actions);
          })
        )
    })
  );

export default cardDeleteEpic;
