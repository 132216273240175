import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { FormFeedback } from 'reactstrap';

//@TODO: Hot fix, remove replace
const cleanMessage = (message) => {
  const msg = message.replace('Vanilla ', '');
  return msg.charAt(0).toUpperCase() + msg.substr(1);
}

const FieldErrorMessage = props => {
  const { errorOnIsTouched = true, touched, error, submitting, invalid, className = '' } = props;
  const isTouched = errorOnIsTouched ? touched : true;
  return isTouched && invalid && !submitting && error && error.length ? (
    <FormFeedback className={className}>
      {cleanMessage(error[error.length - 1])}
    </FormFeedback>
  ) : null;
};

FieldErrorMessage.propTypes = {
  ...fieldPropTypes.meta
};

export default FieldErrorMessage;
