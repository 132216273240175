export const COMMON = '@COMMON';
export const COMMON_SCROLL_START = `${COMMON}/SCROLL_START`;
export const COMMON_SCROLL_FINISH = `${COMMON}/SCROLL_FINISH`;
export const COMMON_SCROLL_SET = `${COMMON}/SCROLL_SET`;

export const scrollToStartAction = payload => ({ type: COMMON_SCROLL_START, payload });

export const scrollToFinshAction = () => ({ type: COMMON_SCROLL_FINISH });

export const scrollSetPositionAction = payload => ({ type: COMMON_SCROLL_SET, payload });
