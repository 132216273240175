import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { createEmptyUnderlying } from 'pages/structured-products/components/forms/underlyings/utils';
import { change, reset } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import {
  scheduleOptionsSet,
  restoreStructuredFormUnderlyingsUpdateListAction,
  restoreStructuredHistoryStartAction,
  structuredProductsFormConfigSet,
  structuredProductsResponseScheduleSet,
  structuredProductsScheduleFromHistory,
  structuredProductsScheduleLock,
  structuredProductsScheduleSet,
  STRUCTURED_PRODUCTS_FORM_CONFIG_SET,
  STRUCTURED_PRODUCTS_FORM_DATA_LOADED,
  STRUCTURED_PRODUCTS_FORM_RESTORE,
  structuredProductsFormOptionsSet,
  structuredProductsRestoreCurrencyList
} from 'redux/actions/structured-products';
import { getOption, getOptionKey } from 'redux/epics/utils';
import { from, merge, zip } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { caseStrucutredProductsFormConfig, isStructureContaintSchedule } from '../form/utils';
import { handleVisibleFormFields } from '../form/visibleFields';

const getRadioGroupValue = flag => flag ? 'yes' : 'no';

export const payloadToFormData = (formData = {}, formOptionsDefault = {}) => {
  const { type: structure, memoryCoupon, frequency, firstObservationIn: firstObservationInValue } = formData;
  const firstObservationIn = parseInt(firstObservationInValue, 10) || firstObservationInValue;
  return {
    ...formData,
    memoryCoupon: getRadioGroupValue(memoryCoupon),
    frequency: formOptionsDefault['frequency'] ? getOptionKey(getOption(frequency, formOptionsDefault['frequency'], 'label'), 'value') : frequency,
    firstObservationIn,
    structure,
  }
}

const setStructuredProductsFormValues = (formData = {}, fieldChanges = []) => {
  let actions = [];
  Object.keys(formData)
    .forEach(fieldKey => {
      if (fieldChanges.includes(fieldKey)) {
        actions.push(
          change(PRICING_STRUCTURED_FORM, fieldKey, formData[fieldKey])
        )
      }
    });

  actions.push(
    structuredProductsFormOptionsSet({frequencyValue: formData['frequency']}),
    structuredProductsFormOptionsSet({firstObservationInValue: formData['firstObservationIn']})
  )

  return actions;
}

const setStructuredProductsFormUnderlyings = (formData = {}) => {
  const { underlyings = [] } = formData;
  const emptyUnderlyings = underlyings?.length ?
    underlyings
      .map((underlying) => {
        const newUnderlying = {
          ...createEmptyUnderlying(),
          value: underlying.id,
          options: [{ value: underlying.id, label: underlying.title }],
        };
        return newUnderlying;
      }) : [createEmptyUnderlying()];

  const actions = emptyUnderlyings.map(({name, value}) => change(PRICING_STRUCTURED_FORM, name, value));
  return [restoreStructuredFormUnderlyingsUpdateListAction(emptyUnderlyings), ...actions];
}

const setStructuredProductsScheduleFormValues = (formData = {}) => {
  const { schedule, strikeDate, paymentLag, observationLag } = formData;
  return [
    scheduleOptionsSet({
      strikeDate,
      observationLag,
      paymentLag
    }),
    structuredProductsResponseScheduleSet(schedule),
    structuredProductsScheduleFromHistory(true),
    structuredProductsScheduleSet(schedule),
    structuredProductsScheduleLock(true),
  ];
}

export const restoreStructuredHistoryChangeStructureEpic = (action$, state$) =>
  action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_RESTORE))
    .pipe(
      map(({ payload }) => mapStructuredHistoryResponse(payload, state$)),
      switchMap((formData = {}) => {
        const { structure } = formData;
        const actions = [
          change(PRICING_STRUCTURED_FORM, 'structure', structure),
          reset(PRICING_STRUCTURED_FORM),
          restoreStructuredHistoryStartAction(),
        ];

        return from(actions);
      })
    );

const removeHiddenFields = (formValues = {}) => {
  let fields = {};
  const visibleFields = handleVisibleFormFields(formValues);
  Object.keys(formValues).forEach(key => {
    if (visibleFields[key] !== false) fields[key] = formValues[key];
  });
  return fields;
}

const mapStructuredHistoryResponse = (payload, state$) => {
  const formOptionsDefault = state$.value?.structuredProducts?.formOptionsDefault;
  const formDataValues = payloadToFormData(payload, formOptionsDefault || {});
  return removeHiddenFields(formDataValues);
}

const isStructureChangedAndMakeRequest = (formDataHistory, state = {}) => {
  const structure = state.structuredProducts?.selectedStrucutre;
  return formDataHistory.structure !== structure;
}

// structureRestoreActionsChangedStructure and structureRestoreActions
// if structure was changed we make requests and wait until it load
// otherwise just restore history
const structureRestoreActionsChangedStructure = (action$, state$) => zip(
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_RESTORE),
    filter(({ payload }) => payload),
    map(({ payload }) => mapStructuredHistoryResponse(payload, state$)),
    filter((formDataHistory = {}) => isStructureChangedAndMakeRequest(formDataHistory, state$.value)),
  ),
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_DATA_LOADED),
    filter(({ payload }) => payload && !payload.isFirstRequest),
  ), // wait until structure request return data but skip first request,
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_CONFIG_SET),
  ),
);

const structureRestoreActions = (action$, state$) => action$.pipe(
  ofType(STRUCTURED_PRODUCTS_FORM_RESTORE),
  filter(({ payload }) => payload),
  map(({ payload }) => [mapStructuredHistoryResponse(payload, state$)]),
  filter(([formDataHistory = {}]) => !isStructureChangedAndMakeRequest(formDataHistory, state$.value)),
);

export const restoreStructuredHistoryFormEpic = (action$, state$) =>
  merge(
    structureRestoreActionsChangedStructure(action$, state$),
    structureRestoreActions(action$, state$),
  )
    .pipe(
      delay(1000), // Wait until apply form config
      map(([data]) => data),
      switchMap((formData = {}) => {
        const { structure } = formData;
        const fieldValues = handleVisibleFormFields(formData);
        const { fieldKeysArray } = caseStrucutredProductsFormConfig(structure);
        let actions = [
          restoreStructuredHistoryStartAction(),
          structuredProductsFormConfigSet(fieldValues),
          ...setStructuredProductsFormUnderlyings(formData),
          ...setStructuredProductsFormValues(formData, fieldKeysArray),
        ];
        if (isStructureContaintSchedule(structure)) {
          actions.push(...setStructuredProductsScheduleFormValues(formData));
        }
        actions.push(structuredProductsRestoreCurrencyList())

        return from(actions);
      })
    );

export default combineEpics(
  restoreStructuredHistoryChangeStructureEpic,
  restoreStructuredHistoryFormEpic,
);
