import { REFERENCE_TYPE_LIVE } from 'pages/price/mini/components/pricinigMiniForm/constants';
import { pricingMiniFormValuesSelector } from 'pages/price/mini/components/pricinigMiniForm/utils';
import { change } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { miniAccumulatorCardGetReferencePrice, miniAccumulatorCardUpdate, PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE_FINISH } from 'redux/actions/accumulator-mini';
import { priceFormFailed, PRICING_SUBMIT_FORM, PRICING_SUBMIT_FORM_REQUEST, submitFormRequest } from 'redux/actions/price';
import { calculateAccumulatorMini } from 'redux/queries/accumulator-mini';
import { from, zip } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { emptyPricingFormFilter, getPricingMiniFormName, roundToClosest } from './utils';

export const submitPricingMiniFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => emptyPricingFormFilter(state$)),
    filter(() => state$.value.accumulatorMini.cards && state$.value.accumulatorMini.cards.length),
    debounceTime(350),
    map(({ payload }) => payload),
    map(({ id: cardId, ...formData }) => {
      const cards = state$.value.accumulatorMini.cards;
      const findIndex = cards.findIndex(({ id }) => id === cardId);
      const card = cards[findIndex];
      const { referenceType } = formData;
      if (referenceType === REFERENCE_TYPE_LIVE) return miniAccumulatorCardGetReferencePrice({ id: cardId, notifyWhenFinish: true });
      return submitFormRequest({ card, formData, cardId });
    })
  );

const populatePricingMiniFormResultsActions = ({ levels, trailId, isTrade, ...rest }, card, outputs, fieldsMap, cardId) => {
  let outputsActions = [];
  const cardFields = [...card.fields];
  if (Array.isArray(outputs) && outputs.length && Array.isArray(levels)) {
    const { roundingRule } = rest;
    levels.forEach((level) => {
      if (outputs.includes(level.field)) {
        const value = roundToClosest(parseFloat(level.value), roundingRule);
        const formName = getPricingMiniFormName(cardId);
        outputsActions.push(change(formName, level.field, parseFloat(value)));

        // Add active color for result slider
        const fieldIndex = fieldsMap.get(level.field)?.index;
        if (cardFields[fieldIndex]) {
          cardFields[fieldIndex] = {
            ...cardFields[fieldIndex],
            isResult: true,
          }
        }
      }
    });
  }
  const actions = [
    ...outputsActions,
    miniAccumulatorCardUpdate({
      ...card,
      isCardResult: true,
      fields: cardFields,
      ...rest,
      trailId,
      isTrade,
      isLoading: false,
      errorText: null
    }),
  ];

  return actions;
};

const pricingMiniFormSubmitFail = (card) => {
  const errorText = `We are unable to solve your product. Please try to adjust your Input.`;
  const actions = [
    priceFormFailed(card),
    miniAccumulatorCardUpdate({ ...card, errorText, isLoading: false }),
  ];
  return actions;
};

const preparePricingMiniData = (card = {}, formData = {}) => {
  let inputs = [];
  let outputs = [];
  const fieldsMap = new Map();
  if (card && Array.isArray(card.fields)) {
    card.fields
      .forEach(({ name, disabled, label }, index) => {
        const field = {
          label,
          field: name,
          value: !disabled ? parseFloat(formData[name]) : null,
        }
        fieldsMap.set(name, {
          ...field,
          index
        });
        if (name.toLowerCase() !== 'reference') {
          inputs.push(field);
          if (disabled) outputs.push(name);
        }
      });
  }

  return { inputs, outputs, fieldsMap };
};

export const submitPricingMiniFormLiveRequestEpic = (action$, state$) =>
  zip(
    action$.pipe(
      ofType(PRICING_SUBMIT_FORM),
      filter(({ payload }) => payload?.referenceType === REFERENCE_TYPE_LIVE)
    ),
    action$.pipe(ofType(PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE_FINISH)),
  ).pipe(
    filter(() => emptyPricingFormFilter(state$)),
    filter(() => state$.value.accumulatorMini.cards && state$.value.accumulatorMini.cards.length),
    map(([{ payload }]) => payload),
    map(({ id: cardId, ...formData }) => {
      const cards = state$.value.accumulatorMini.cards;
      const findIndex = cards.findIndex(({ id }) => id === cardId);
      const card = cards[findIndex];
      return submitFormRequest({ card, formData, cardId });
    })
  );

export const submitPricingMiniFormRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM_REQUEST),
    filter(() => emptyPricingFormFilter(state$)),
    filter(() => state$.value.accumulatorMini.cards && state$.value.accumulatorMini.cards.length),
    map(({ payload }) => payload),
    concatMap(({ card, cardId }) => {
      const formName = getPricingMiniFormName(cardId);
      const formData = pricingMiniFormValuesSelector(formName)(state$.value);
      const { inputs, outputs, fieldsMap } = preparePricingMiniData(card, formData);
      return from(calculateAccumulatorMini(card, inputs, formData))
        .pipe(
          filter(payload => !!payload),
          switchMap((response) => {
            const actions = populatePricingMiniFormResultsActions(response, card, outputs, fieldsMap, cardId);
            return from(actions);
          }),
          catchError(() => {
            const actions = pricingMiniFormSubmitFail(card);
            return from(actions);
          })
        )
    })
  );

export default combineEpics(
  submitPricingMiniFormEpic,
  submitPricingMiniFormRequestEpic,
  submitPricingMiniFormLiveRequestEpic,
);
