import Loader from 'components/common/loader/Loader';
import TableHeaderSorter from 'components/tables/TableHeaderSorter';
import { withBreakPointsContext } from 'components/theme/breakPoints/BreakPoints';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { mapNodes } from 'utils/';
import PricingHistoryFilters from './PricingHistoryFilters';
import PricingHistoryList from './PricingHistoryList';
import { SORT_FIELDS } from './sorter';
import { PRICING_HISTORY_FORM_COLUMNS } from './TableColumnsToggler';
import { totalColumns } from './TableColumnsTogglerWrapper';
import { BREAKPOINT_XS } from 'components/theme/breakPoints/constants';
import { hasScrollBar } from 'components/tables/utils';

class PricingHistoryTableContent extends PureComponent {

  constructor(args) {
    super(args);
    this.state =  {
      isScrollBar: false
    };
    this.scroller = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const isScrollBar = this.hasScrollBar();
    if (prevState.isScrollBar !== isScrollBar) {
      this.setState({
        isScrollBar,
      });
    }
  }

  hasScrollBar = () => {
    const current = this?.scroller?.current;
    return hasScrollBar(current);
  }

  render() {
    const { isScrollBar } = this.state;
    const {
      calculateHistory,
      loading,
      currentTrail,
      loadMore,
      isLoading,
      responsive: {
        screenSize
      },
      displayColumns,
      orderBy,
      onChangeFilter,
      onClose,
      onChangeOrder,
      isLoadingRefetch,
      scrollerWrapper
    } = this.props;
    const totalDisplayColumns = totalColumns(displayColumns, SORT_FIELDS);
    const isActiveAllColumns = totalDisplayColumns === SORT_FIELDS.length;
    const displayColumnsClass = isActiveAllColumns ? ` columns-diplay-all` : '';
    const items = mapNodes(calculateHistory);
    const sorters = SORT_FIELDS
      .filter(({ key, displayAllways }) => !!displayAllways || (displayColumns && displayColumns[key] && displayColumns[key]));
    const classMore = items && isScrollBar ? ' pricing-history-has-more ' : '';
    const getScrollParent = screenSize === BREAKPOINT_XS ? () => scrollerWrapper.current : null;
    return (
      <div className={`pricing-history-list${displayColumnsClass}${classMore}`}>
        <PricingHistoryFilters
          onChangeFilter={onChangeFilter}
          onClose={onClose}
          displayColumns={displayColumns}
          isActiveAllColumns={isActiveAllColumns}
          isLoading={isLoadingRefetch} />
        {items?.length ?
          <div className="pricing-history-content">
            <TableHeaderSorter
              sorters={sorters}
              order={orderBy.order}
              selected={orderBy.by}
              onChange={onChangeOrder} />
            {loading ? <Loader /> :
              <div ref={this.scroller} className="pricing-history-scroller">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadMore}
                  threshold={50}
                  useWindow={false}
                  hasMore={!isLoading && calculateHistory.pageInfo.hasNextPage}
                  getScrollParent={getScrollParent}
                  loader={<Loader key={'PricingHistoryTableContentLoader'} />}>
                  <PricingHistoryList displayColumns={displayColumns} screenSize={screenSize} items={items} currentTrail={currentTrail} onClose={onClose} />
                </InfiniteScroll>
              </div>
            }
          </div>
          : <div className="text-center">
            <FormattedMessage id="price.page/empty-pricing-history"
              defaultMessage="Pricing History is empty." />
          </div>}
      </div>
    )
  }
};

export const selectorFormColums = getFormValues(PRICING_HISTORY_FORM_COLUMNS);

const mapStateToProps = createSelector(
  [state => selectorFormColums(state)],
  formData => ({
    displayColumns: formData || {}
  })
);

PricingHistoryTableContent.propTypes = {
  scrollerWrapper: PropTypes.object.isRequired,
};

export default compose(
  withBreakPointsContext,
  connect(mapStateToProps),
)(PricingHistoryTableContent);
