import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';

const FxFormFields = props => {
  const {
    commodityContracts = [],
    structures,
  } = props;
  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={4}>
          <Field name="structure"
            label={<FormattedMessage id="form.price.fields.structure.label"
              defaultMessage="Structure" />}
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            options={structures}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Field name="commodity"
            label="FX Pair"
            component={InlineSelectInput}
            justValue={true}
            preselectIfOneOption={true}
            options={commodityContracts}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default FxFormFields;
