export interface ScheduleConfig {
  dtype: DataType,
  mandatory: boolean,
  editable: boolean,
  'display-name': string
}

export enum DataType {
  list = 'list',
  int = 'int',
  float = 'float',
  str = 'str',
  bool = 'bool',
}

export enum ScheduleType {
  COUPON = 'coupon-schedule',
  EARLY_TERM = 'early-termination-schedule',
  FLOATING = 'floating-coupon-schedule',
}
