import auth from './auth';
import theme from './theme/';
import price from './price';
import customRequests from './custom-requests';
import notifications from './notifications';
import tours from './tours';
import formData from './formData';
import common from './common';
import orders from './orders';
import ordersCommon from './ordersCommon';
import accumulatorMini from './accumulator-mini';
import structuredProducts from './structured-products';
import minifuture from './minifuture';
import fx from './fx';
import booking from './booking';
import openTrades from './open-trades';

export default ({
  auth,
  theme,
  price,
  customRequests,
  notifications,
  tours,
  formData,
  common,
  orders,
  ordersCommon,
  accumulatorMini,
  structuredProducts,
  minifuture,
  fx,
  booking,
  openTrades,
});
