import { from, of, zip } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { change } from 'redux-form';
import {
  PRICING_HISTORY_SELECT_TRAIL,
  PRICING_HISTORY_SELECT_TRAIL_LOADED,
  PRICING_HISTORY_SELECT_TRAIL_BAD,
  selectTrailAction,
  PRICING_HISTORY_SELECT_URL,
  selectedTrailLoadedAction,
  setTrailData,
  preSetTrailData,
} from './pricingHistoryActions';
import { PRICING_FORM, pricingDataLoader } from 'redux/actions/price';
import { trailSelectFail, notificationErrorSimple } from '../../alerts/actions';
import { loadTrailForFill, chooseStructureConfig } from '../../queries/price';
import { scrollTop } from 'redux/epics/common';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { map, switchMap, flatMap, last, filter, take, mapTo, catchError } from 'rxjs/operators';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import { DATE_FORMAT_LABEL_MONTH_SHORT_YEAR_SHORT } from 'constants.js';
import isEmpty from 'lodash/isEmpty';

export const parseTrailContractExpiryDate = (contractExpiry) => {
  const date = formatDate2UI(contractExpiry, DATE_FORMAT_LABEL_MONTH_SHORT_YEAR_SHORT);
  const formatedDate = (typeof date === 'string') ? date.replace(/\s/g, '') : null;
  return formatedDate;
};

// @TODO: use same properties on BE and FE
export const updateTrailDataAfterRequest = trail => ({
  ...trail,
  commodity: trail.commodityContract,
  leverageValue: trail.description.leverage,
  leverageStyle: trail.description.leverageStyle,
  // remainderFeature: trail.description.remainderFeature,
  structureType: trail.description.type,
  expiryDate: trail.optionExpiryDate,
})

export const loadPricingFillDataLoaderEpic = action$ =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_SELECT_TRAIL),
      mapTo(pricingDataLoader(true))
    );

export const loadPricingFillDataEpic = action$ =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_SELECT_TRAIL),
      map(({ payload }) => payload),
      filter(({ id }) => !!id),
      flatMap(({ id, structure }) => {
        return zip(
          loadTrailForFill(id, structure),
          scrollTop().pipe(last()),
        ).pipe(
          map(([trail]) => {

            if (!trail || isEmpty(trail)) {
              return notificationErrorSimple('Market data is empty fo the Pricinig History record.');
            }

            const formatedTrail = updateTrailDataAfterRequest(trail);
            return selectedTrailLoadedAction(formatedTrail);
          }),
          catchError((e) => {
            return from([
              notificationErrorSimple(`Can't load ${structure} Pricing History record.`),
              pricingDataLoader(false)
            ]);
          })
        );
      })
    );

export const selectPricingHistoryActionResolver = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_HISTORY_SELECT_TRAIL_LOADED),
      switchMap(({ trail }) => {
        const formData = priceFormValueSelector(state$.value);
        const currentStructure = formData && formData.structure ? formData.structure : null;

        if (trail.structureName === currentStructure) {
          return of(setTrailData(trail));
        }

        const actions = [
          change(PRICING_FORM, 'structure', trail.structureName),
          preSetTrailData(trail),
        ];

        return from(actions);
      }));

export const badTrailSelectEpic = action$ =>
  action$.pipe(
    ofType(PRICING_HISTORY_SELECT_TRAIL_BAD),
    map(trailSelectFail)
  );

export const selectTrailDynamicEpic = action$ =>
  action$.pipe(
    ofType(PRICING_HISTORY_SELECT_URL),
    map(({ payload }) => payload),
    filter(({ structure }) => structure)
  ).pipe(
    switchMap((trail) => {
      const structureConfig = chooseStructureConfig(trail.structure);
      return action$.pipe(
        ofType(structureConfig.dataLoaded),
        take(1),
        map(() => selectTrailAction(trail)),
      )
    })
  );

export default combineEpics(
  badTrailSelectEpic,
  loadPricingFillDataEpic,
  selectPricingHistoryActionResolver,
  selectTrailDynamicEpic,
  loadPricingFillDataLoaderEpic
);
