import round from 'lodash/round';
import { TYPE_MARKET } from './constants';

export const getCard = (id, state) => {
  if(!id) return {};
  const trailPrice = state.price?.trailPrice?.cards || [];
  return trailPrice.find(({ id: cardId }) => cardId === id);
}

export const getLivePrice = (id, isCombinedCard, { orderType } = {}, state) => {
  if (isCombinedCard) {
    const { price = null, quoteId, rate, cmPrice } = getCard(id, state);
    return { compoPrice: round(price, 4), quoteId, rate, cmPrice }
  };

  if(orderType !== TYPE_MARKET || !id) return { price: null };
  const { price = null } = getCard(id, state);
  return { price: round(price, 4) }
}

export const getLivePriceMinifuture = (id, { orderType } = {}, state) => {
  if(orderType !== TYPE_MARKET || !id) return { price: null };
  const trailPrice = state.minifuture?.trailPrice?.cards || [];
  const { price = null } = trailPrice.find(({ id: cardId }) => cardId === id);
  return { price: round(price, 4) }
}
