import { STRUCTURE_SWAP } from 'constants.js';
import { PRICINIG_DIRECTIONS } from '../../constants.js';

export const SWAP_DIRECTIONS = [
  ...PRICINIG_DIRECTIONS
];

export const PRICING_FORM_SWAP_INITIAL_VALUES = {
  structure: STRUCTURE_SWAP,
  direction: SWAP_DIRECTIONS[0].value,
  commodity: null,
  calendarSpread: false,
  bulletStrip: false,
};
