import isEmpty from 'lodash/isEmpty';
import { ofType, combineEpics } from 'redux-observable';
import { pricingResultsLoader, PRICING_VANILLA_FORM_LOADED, setStaticCursorData } from 'redux/actions/price';
import { swapCardsUpdate, PRICING_SWAP_CARDS_FETCH_MORE } from 'redux/actions/swap';
import { from } from 'rxjs';
import { filter, switchMap, map, delay } from 'rxjs/operators';
import { loadVanillaCardsData } from 'redux/queries/vanilla';
import { vanillaTrailPricesFilter } from '..';
import uniqBy from 'lodash/uniqBy';

export const loadVanillaCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_VANILLA_FORM_LOADED),
      filter(({ payload }) => {
        return payload && !isEmpty(payload.pricings);
      }),
      switchMap(() => {
        const limit = state$.value.auth.account.cardsLimit
        return from(loadVanillaCardsData(null , limit)).pipe(
          switchMap(data => {
            const actions = [
              swapCardsUpdate(data),
              setStaticCursorData(data),
              pricingResultsLoader(false),
            ];
            return from(actions);
          })
        )
      })
    );

export const fetchMoreVanillaCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_CARDS_FETCH_MORE),
      filter(({ payload }) => !!payload && !!state$.value.price && !!state$.value.price.trailPrice && !!state$.value.price.trailPrice.pageInfo),
      filter(() => vanillaTrailPricesFilter(state$)),
      map(({ payload }) => payload),
      switchMap(({cursor, limit}) => {
        return from(loadVanillaCardsData(cursor, limit)).pipe(
          switchMap(data => {
            const oldCards = state$.value.price.trailPrice && state$.value.price.trailPrice.cards ? state$.value.price.trailPrice.cards : [];
            const actions = [
              swapCardsUpdate({
                ...data,
                cards: uniqBy([
                  ...oldCards,
                  ...data.cards,
                ], 'id')
              }),
              pricingResultsLoader(false),
            ];
            return from(actions).pipe(
              delay(180) //Animation time
            )
          })
        )
      })
    );

export default combineEpics(
  loadVanillaCardsEpic,
  fetchMoreVanillaCardsEpic,
);
