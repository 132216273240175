import { useEffect, useState } from "react";
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TYPE_MARKET } from '../constants.js';
import InlineInput from 'components/form/InlineInput';

const PriceField = ({ orderType, formatMessage, actualPrice, intl, maximumFractionDigits }) => {

  const [price, setPrice] = useState(actualPrice);

  useEffect(() => {
    if(actualPrice) setPrice(actualPrice);
  }, [actualPrice]);


  if (orderType === TYPE_MARKET) {
    return (
      <InlineInput
        label={<FormattedMessage id="trade/price.label"
          defaultMessage="Price" />}
        placeHolder={formatMessage({
          id: "trade/price.label",
          defaultMessage: "Price",
        })}
        disabled={true}
        type="number"
        step="1"
        meta={{}}
        input={{
          value: price,
          name: 'price',
        }}
      />
    );
  }

  return (<Field
    name="price"
    type="number"
    placeHolder={formatMessage({
      id: "trade/price.label",
      defaultMessage: "Price",
    })}
    label={<FormattedMessage id="trade/price.label"
      defaultMessage="Price" />}
    step="1"
    format={(value) => value ? parseFloat(intl.formatNumber(parseFloat(value), { maximumFractionDigits }).replace(/,/g, '')) : value}
    component={InlineInput}
  />);
};

PriceField.defaultProps = {
  maximumFractionDigits: 4
};
PriceField.propTypes = {
  maximumFractionDigits: PropTypes.number.isRequired
};

export default injectIntl(PriceField);
