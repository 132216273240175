import gql from 'graphql-tag';

export const GET_AVAILABLE_ACCOUNTS = gql`query getAvailableAccountsQuery {
  userCounterparties {
    id
    client
    isDefault
  }
}`;

export const UPDATE_ORDER_ACCOUNT_MUTATION = gql`mutation updateNeonOrderAccountMutation(
  $orderId: String!
  $slackIceClientsId: ID!
) {
  neonOrderAccountUpdate (
    orderId: $orderId
    slackIceClientsId: $slackIceClientsId
  ) {
    id
    client
  }
}`;

export const CLIENT_OPTIONS_QUERY_FRAGMENT = gql`fragment userClientFragment on UserClientType {
  id
  title: name
  code
  recordType
  isDefaultAccount
}`;

// for reference : userClient is in MS-BE\src\graphql\Resources\User\query\userClient.js
export const CLIENT_OPTIONS_QUERY = gql`query getClientsQuery (
  $cursor: String
  $search: String
  $recordTypes: [String]
  $isin: String
) {
  userClient(
    after: $cursor
    search: $search
    recordTypes: $recordTypes
    isin: $isin
  ) {
    edges {
      node {
        id
        ...userClientFragment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}
${CLIENT_OPTIONS_QUERY_FRAGMENT}`;

