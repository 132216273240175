export const THEME = '@THEME';
export const THEME_SET = `${THEME}/SET`;
export const THEME_SET_INITIAL = `${THEME_SET}/INITIAL`;
export const THEME_SET_TEXTS = `${THEME_SET}/TEXTS`;
export const THEME_SET_IMAGES = `${THEME_SET}/IMAGES`;
export const THEME_SET_STYLES = `${THEME_SET}/STYLES`;
export const THEME_SET_META = `${THEME_SET}/META`;
export const THEME_SET_LOADER = `${THEME_SET}/LOADER`;
export const THEME_SET_NAME = `${THEME_SET}/NAME`;

export const themeSet = payload => ({ type: THEME_SET, payload });

export const themeSetInitial = _ => ({ type: THEME_SET_INITIAL });

export const themeSetTexts = payload => ({ type: THEME_SET_TEXTS, payload });

export const themeSetImages = payload => ({ type: THEME_SET_TEXTS, payload });

export const themeSetStyles = payload => ({ type: THEME_SET_STYLES, payload });

export const themeSetMeta = payload => ({ type: THEME_SET_META, payload });

export const themeSetLoader = payload => ({ type: THEME_SET_LOADER, payload });

export const themeSetName = payload => ({ type: THEME_SET_NAME, payload });
