import Logger from './Logger';
export * from './constants';
const logger =  new Logger();

if (typeof window !== 'undefined') {
  window.onerror = (errorMsg, url, line, column) => {
    logger.error('Uncaught Exception', {
      errorMsg: errorMsg ? (errorMsg.message || errorMsg) : '',
      url,
      line,
      column
    });
  };
  window.onunhandledrejection = (event) => {
    logger.error('Unhandled Rejection', {
      errorMsg: event.reason ? event.reason.message : event.message || null,
    });
  };
}

export default logger;
