import gql from 'graphql-tag';

export const CREATE_VANILLA_ORDER_MUTATION = gql`mutation createNeonOrderMutation(
  $commodityCode: String!
  $contractExpiry: String!
  $quantity: Float!
  $direction: PricingSwapDirectionEnumType!
  $price: Float
  $quotedCurrency: String!
  $contractCode: String!
  $commodityContract: String!
  $structureType: String!
  $strikeLevel: Float!
  $structure: String!
  $timeInForce: String!
  $orderType: String!
  $stopPrice: Float
  $maxShow: Float
  $expiryDate: DateTime
  $slackIceClientsId: ID
  $priceForCalcSpread: Float
  $swapContractCode: String
) {
  createOrder(
    commodityCode: $commodityCode
    contractExpiry: $contractExpiry
    quantity: $quantity
    direction: $direction
    price: $price
    quotedCurrency: $quotedCurrency
    contractCode: $contractCode
    commodityContract: $commodityContract
    structureType: $structureType
    strikeLevel: $strikeLevel
    structure: $structure
    timeInForce: $timeInForce
    orderType: $orderType
    stopPrice: $stopPrice
    maxShow: $maxShow
    expiryDate: $expiryDate
    slackIceClientsId: $slackIceClientsId
    priceForCalcSpread: $priceForCalcSpread
    swapContractCode: $swapContractCode
    ) {
      id
    }
}`;
