import { STRUCTURE_AUTOCALLABLE, STRUCTURE_CAPITAL_PROTECTION, STRUCTURE_TWIN_WIN, STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import { CONDITIONAL_COUPON_TYPE, PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/autocallable/constats';
import { stopSubmit } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { scrollToStartAction } from 'redux/actions/common';
import { setStructuredProductsLoader, structuredProductsResultsLoader, structuredProductsResultsSet, STRUCTURED_PRODUCTS_FORM_RESTORE, STRUCTURED_PRODUCTS_FORM_SUBMIT } from 'redux/actions/structured-products';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { STRUCTURED_PRODUCTS_SUBMIT_DEBOUNCE } from 'redux/epics/constants';
import { getOption, getOptionKey, getRegisterFields, getSingleGraphQlError } from 'redux/epics/utils';
import { from, merge, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { getObservations, mergeScheduleFormDataWithResponse, scheduleFormDataSelector, setScheduleDataActions } from './schedule';
import { caseStrucutredProductsFormConfig, formRadioValue } from './utils';
import { scheduleOptions } from 'pages/structured-products/components/output/schedule/utils'

export const getStructuredFormFrequencyLabelByValue = (formValues, state, key) => {
  const frequencyInt = parseInt(formValues[key], 10);
  const formOptionsDefault = state?.structuredProducts?.formOptionsDefault;
  if (!formOptionsDefault) return formValues[key];
  const frequencyValue = isNaN(frequencyInt) ? formValues[key] : getOptionKey(getOption(frequencyInt, formOptionsDefault[key]), 'label');
  return frequencyValue;
};

export const getStructuredFormStructureLabelByValue = (formValues, state, key) => {
  const formOptionsDefault = state?.structuredProducts?.structures;
  const { structure } = formValues
  if (!formOptionsDefault) return formValues[key];
  const structureValue = getOptionKey(getOption(structure, formOptionsDefault), 'label');
  return structureValue;
};

export const parseFormValuesAutocallable = (formValues = {}, state) => {
  const stepDown = Boolean(formValues.stepDown);
  const oneStar = Boolean(formValues.oneStar);

  return {
    ...formValues,
    memoryCoupon: formRadioValue(formValues.memoryCoupon),
    firstObservationIn: formValues.firstObservationIn ? formValues.firstObservationIn.toString() : PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES['firstObservationIn'],
    stepDown,
    snowball: Boolean(formValues.snowball),
    couponTriggerLevel: formValues.couponTriggerLevel && formValues.couponType === CONDITIONAL_COUPON_TYPE ? parseFloat(formValues.couponTriggerLevel) : null,
    downsideLeverage: formValues.downsideLeverage ? parseFloat(formValues.downsideLeverage) : null,
    autocallTriggerLevel: formValues.autocallTriggerLevel ? parseFloat(formValues.autocallTriggerLevel) : null,
    step: formValues.step && stepDown ? parseFloat(formValues.step) : null,
    couponLevel: formValues.couponLevel ? parseFloat(formValues.couponLevel) : null,
    reoffer: formValues.reoffer ? parseFloat(formValues.reoffer) : null,
    barrierLevel: formValues.barrierLevel ? parseFloat(formValues.barrierLevel) : null,
    strikeLevel: formValues.strikeLevel ? parseFloat(formValues.strikeLevel) : null,
    frequency: getStructuredFormFrequencyLabelByValue(formValues, state, 'frequency'),
    oneStar,
    oneStarThreshold: formValues.oneStarThreshold && oneStar ? parseFloat(formValues.oneStarThreshold) : null,
  }
};

export const parseFormValuesAutocallableUnderlyings = (formValues = {}, state = {}) => {
  const registeredFields = getRegisterFields(PRICING_STRUCTURED_FORM, state);
  const underlyings = [];
  if (registeredFields) {
    Object.keys(formValues).forEach((key) => {
      if (key.indexOf('underlying') >= 0 && registeredFields[key]) {
        const underlying = formValues[key];
        if (underlying) underlyings.push(underlying);
      }
    });
  }
  return underlyings;
};

export const parseFormValuesCapitalProtection = (formValues = {}, state) => {
  const options = [];
  const firstOption = {
    optionType: formValues.optionType,
    strikeLevel: formValues.strikeLevel ? parseFloat(formValues.strikeLevel) : null,
    participationLevel: formValues.participationLevel ? parseFloat(formValues.participationLevel) : null,
    capType: formValues.capType,
    barrierType: formValues.barrierType,
    capLevel: formValues.capLevel ? parseFloat(formValues.capLevel) : null,
    barrierLevel: formValues.barrierLevel ? parseFloat(formValues.barrierLevel) : null,
    rebateLevel: formValues.rebateLevel ? parseFloat(formValues.rebateLevel) : 0
  }
  options.push(firstOption);

  const secondOption = {
    optionType: formValues.optionType2,
    strikeLevel: formValues.strikeLevel2 ? parseFloat(formValues.strikeLevel2) : null,
    participationLevel: formValues.participationLevel2 ? parseFloat(formValues.participationLevel2) : null,
    capType: formValues.capType2,
    barrierType: formValues.barrierType2,
    capLevel: formValues.capLevel2 ? parseFloat(formValues.capLevel2) : null,
    barrierLevel: formValues.barrierLevel2 ? parseFloat(formValues.barrierLevel2) : null,
  }

  if (formValues.isAdditinalSection) {
    options.push(secondOption);
  }

  return {
    notional: formValues.notional,
    issuer: formValues.issuer,
    legalShape: formValues.legalShape,
    floatingCurve: formValues.floatingCurve,
    spread: formValues.spread,
    currency: formValues.currency,
    basketType: formValues.basketType || 'Worst Of',
    maturity: formValues.maturity,
    capitalProtectionLevel: parseFloat(formValues.capitalProtectionLevel),
    options,
  }
};

export const getSchedulePayload = state => {
  const scheduleFormData = scheduleFormDataSelector(state.value);
  const { strikeDate, observationLag, paymentLag } = scheduleFormData || scheduleOptions(state.value);
  const originalSchedule = state.value?.structuredProducts?.scheduleResponse || [];

  return {
    ...mergeScheduleFormDataWithResponse(originalSchedule, scheduleFormData),
    scheduleOptions: {
      strikeDate: strikeDate,
      observationLag: parseInt(observationLag),
      paymentLag: parseInt(paymentLag),
    }
  }
};

export const autocallGenerateRequestPayload = (formValues = {}, state = {}) => {
  const underlyings = parseFormValuesAutocallableUnderlyings(formValues, state.value);
  const observations = getObservations(formValues);

  const { schedule, scheduleOptions } = getSchedulePayload(state);

  return {
    ...parseFormValuesAutocallable(formValues, state.value),
    ...scheduleOptions,
    schedule,
    observations,
    underlyings,
  };
};

export const capitalProtectionGenerateRequestPayload = (formValues = {}, state) => {
  const underlyings = parseFormValuesAutocallableUnderlyings(formValues, state.value);
  return {
    ...parseFormValuesCapitalProtection(formValues, state.value),
    underlyings,
  }
};

export const caseStructuredProductsRequestPayload = (formValues = {}, state) => {
  switch (formValues.structure) {
    case STRUCTURE_AUTOCALLABLE:
    case STRUCTURE_TWIN_WIN:
    case STRUCTURE_FIXED_COUPON_NOTE:
      return autocallGenerateRequestPayload(formValues, state)
    case STRUCTURE_CAPITAL_PROTECTION:
      return capitalProtectionGenerateRequestPayload(formValues, state)
    default:
      return formValues;
  }
};

export const submitAutocallableFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_SUBMIT),
    filter(({ payload }) => payload),
    debounceTime(STRUCTURED_PRODUCTS_SUBMIT_DEBOUNCE),
    filter(() => !state$.value?.structuredProducts?.isScheduleLoading),
    map(() => formValuesSelectorStructuredForm(state$.value)),
    filter((formValues) => formValues.structure === STRUCTURE_CAPITAL_PROTECTION || state$.value.structuredProducts?.schedule),
    switchMap((formValues) => {
      const variables = caseStructuredProductsRequestPayload(formValues, state$);
      const { submit } = caseStrucutredProductsFormConfig(formValues.structure);
      if (typeof submit !== 'function') {
        throw new Error('Unknown structure');
      };
      return from(submit(variables))
        .pipe(
          filter(payload => !!payload),
          switchMap((response) => {
            const responseData = {
              ...response,
              structure: formValues.structure,
            };
            let actions = [
              structuredProductsResultsSet(responseData),
              structuredProductsResultsLoader(false),
              setStructuredProductsLoader(false),
            ];
            if (formValues.solveFor === 'Coupon') {
              const couponLevel = response.couponLevel;
              const schedule = state$.value.structuredProducts.schedule.map(item => {
                return { ...item, couponLevel };
              });
              actions = [...actions, ...setScheduleDataActions(schedule)];
            }
            return from(actions)
          }),
          catchError((error) => {
            const err = getSingleGraphQlError(error);
            const msg = err?.message ? err.message : null;
            const errMsgDescription = msg?.description ? msg.description : null;
            const errMsgTitle = msg?.title ? msg.title : null;
            return from([
              stopSubmit(PRICING_STRUCTURED_FORM),
              structuredProductsResultsSet(null),
              structuredProductsResultsLoader(false),
              setStructuredProductsLoader(false),
              notificationErrorSimple(errMsgDescription || errMsgTitle || `Structure cannot be calculated, please adjust your input.`),
            ]);
          })
        )
    })
  );

export const submitScrollAutocallableFormEpic = (action$, state$) =>
  merge(
    action$.pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_SUBMIT),
      debounceTime(STRUCTURED_PRODUCTS_SUBMIT_DEBOUNCE),
      filter(() => !state$.value?.structuredProducts?.isScheduleLoading),
    ),
    action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_RESTORE)),
  ).pipe(
    debounceTime(50),
    filter(({ payload }) => payload),
    switchMap(() => of(scrollToStartAction()))
  );

export default combineEpics(
  submitAutocallableFormEpic,
  submitScrollAutocallableFormEpic,
);
