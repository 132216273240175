import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import { isThemeSelector } from 'components/theme/utils';
import Bundle from 'pages/Bundle';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export const HomePage = props => {
  const { isTheme, title } = props;
  return (
    <ErrorBoundary>
      <PageMeta title={isTheme ? title : 'Marex Solutions'} />
      <Bundle title={title} load={() => import('./Home')}>
        {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
      </Bundle>
    </ErrorBoundary>
  );
};

HomePage.defaultProps = {
  isTheme: false,
  title: 'Home Page',
};

HomePage.propTypes = {
  isTheme: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(isThemeSelector)(HomePage);
