import gql from 'graphql-tag';

export const PRICING_HISTORY_ACCUMULATOR_QUERY_FRAGMENT = gql`fragment priceAccumulatorFragment on PricingAccumulatorHistoryType {
  id
  structure
  tag
  quoteUnits
  businessDays
  notional
  accumulationLevel: strikeLevel
  strikeLevel
  barrierLevel
  referencePrice
  barrierType
  calculateType
  pricePerLot
  price
  marketDataDisabled
  expired
  optionExpiryDate
  quotedCurrency
  contractExpiry
  commodityContract
  bloombergTicker
  remainderFeature
  remainderLevel
  accumulatorUnderlyings {
    id
    logo
    currency
  }
  marketData {
    id
    structure
  }
  description {
    id
    type
    leverage
    leverageStyle
    productDescription
    product
  }
}`;
