import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import InputFormComponentFactory from 'pages/price/InputFormComponentFactory';
import { optionsProvider } from 'pages/price/PriceForm/utils';
import React, { Component } from 'react';

class TableFilterFormControl extends Component {
  state = {
    options: []
  };

  componentDidMount() {
    // Graphql returns wrong data from cache after update without fetch data
    this.prepateOptions();
  }

  prepateOptions = () => {
    const { queryResp, optionsProvider: optionsProviderProp, data } = this.props;
    const optionsHandler = isFunction(optionsProviderProp) ? optionsProviderProp : optionsProvider;
    const response = isFunction(queryResp) ? queryResp(data) : data[queryResp];
    const options = response ? optionsHandler(response.filter(el => !isNull(el) && !isUndefined(el))) : [];
    this.setState({ options });
  }

  render() {
    const { options } = this.state;
    const { name, placeHolder, changeFilter, filter } = this.props;
    return (
      <InputFormComponentFactory
        placeHolder={placeHolder}
        key={`input-${name}`}
        field={filter}
        options={options}
        onChangeFilter={changeFilter}
        selectedSingleOption={false} />
    )
  }
};

export default TableFilterFormControl;
