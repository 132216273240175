import { DATE_FORMAT_INPUT, DATE_FORMAT_UTC } from 'constants.js';
import moment from 'moment';
import momentBusinessDays from 'moment-business-days';

export const weekdaysValidator = value => {
  const date = defaultDateFormater(value)
  return isWeekday(date) ? undefined : ['Weekend'];
};

export const isWeekday = (date) => {
  let day = null;
  if (date && (typeof date.day === 'function' || typeof date.getDay === 'function')) {
    day = date.day ? date.day() : date.getDay();
  }
  return ![0, 6].includes(day);
};

export const isHoliday = (date) => momentBusinessDays(date).isHoliday();
export const isBusinessDay = (date) => momentBusinessDays(date).isBusinessDay();

export const tommorowDay = () => moment().utc().add(1, 'days').hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);
export const thisMonth = () => moment().utc().startOf('month').hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);
export const nextMonth = (data) => moment(data).utc().add(1, 'months').startOf('month').hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);

export const toDay = () => moment().utc().hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);

export const tommorowDayWithoutWeekends = () => {
  let days = 1;
  if (moment().day() === 5) days = 3;
  if (moment().day() === 6) days = 2;
  return moment().utc().add(days, 'days').hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);
}

export const defaultDateFormater = (value, format = DATE_FORMAT_INPUT) => {
  if (!value) return null;
  const d = (moment(value, format, true).isValid()) ? value : new Date(value);
  const date = moment(d, format, true).hour(0).minute(0).second(0).millisecond(0).utc(0).toDate();
  return date;
};

export const minDateValidator = minDate => value => {
  const days = moment.utc(value).diff(minDate, 'days');
  return days >= 0 ? undefined : ['Min day'];
};

export const maxDateValidator = maxDate => value => {
  const days = moment.utc(maxDate).diff(value, 'days');
  return days >= 0 ? undefined : ['Max day'];
};

export const dayWithoutWeekends = () => {
  let days = 0;
  if (moment().day() === 0) days = 1;
  if (moment().day() === 6) days = 2;
  return moment().add(days, 'days').hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);
}
