import { STRUCTURE_VANILLA } from 'constants.js';
import { PRICINIG_DIRECTIONS, PRICINIG_STRUCTURE_TYPES } from '../../constants.js';

export const VANILLA_DIRECTIONS = [
  ...PRICINIG_DIRECTIONS
];

export const PRICING_FORM_VANILLA_INITIAL_VALUES = {
  structure: STRUCTURE_VANILLA,
  direction: VANILLA_DIRECTIONS[0].value,
  commodity: null
};

export const VANILLA_STRUCTURE_TYPES = [
  ...PRICINIG_STRUCTURE_TYPES
];
