import gql from 'graphql-tag';

export const OPEN_TRADE_UNWIND_QUERY = gql`mutation unwindOpenTradesMutation (
  $externalIds: [String]
) {
  unwindOpenTradesMutation (
    externalIds: $externalIds
  ) {
    unwindHistoryId
    items {
      externalId
      legId
      direction
      tradingPrice
      currency
      contractTicker
      referencePrice
      remainingFixings
      clientBarrierLevel
      deltaLot
      gamma
      gammaCurrency
      vega
      vegaCurrency
    }
    summary {
      delta
      gamma
      vega
      referencePrice
      cashSettlement
      gammaCurrency
      vegaCurrency
      currency
    }
  }
}`;

export const CONFIRM_UNWIND_MUTATION = gql`mutation confirmUnwindMutation (
  $unwindHistoryId: Int!
  $comment: String
) {
  confirmUnwindMutation (
    unwindHistoryId: $unwindHistoryId
    comment: $comment
  )
}`;

export const CANCEL_UNWIND_MUTATION = gql`mutation cancelUnwindMutation (
  $unwindHistoryId: Int!
) {
  cancelUnwindMutation (
    unwindHistoryId: $unwindHistoryId
  )
}`;
