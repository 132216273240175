import validateJs from 'utils/validate.js';

const validation = {
  expiryDays: {
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0
    }
  },
};

export default function (data) {
  return validateJs(data, validation) || {};
};
