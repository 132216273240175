import gql from 'graphql-tag';


export const PRODUCT_CHART_FRAGMENT = gql`fragment ProductChartFragment on PricingAccumulatorHistoryType {
  id
  marketData {
    id
    matrix {
      edges {
        node {
          price
          date
        }
      }
    }
  }
}`;
