import React from 'react';
import { InlineInput } from 'components/form/InlineInput';
import { Field } from 'redux-form';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const OpenTradesUnwindSection = props => {
  const { conditionsOfChoiceTrades, selectedIds, selectAllChecked, onUnwind, onSelectedAll } = props;
  const labelCheckbox = `Select All (${selectedIds.size})`

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="select-all">
        <Field
          name="selectAll"
          type="checkbox"
          labelCheckbox={labelCheckbox}
          disabled={!conditionsOfChoiceTrades.size}
          onChangeFilter={onSelectedAll}
          component={InlineInput}
          checked={selectAllChecked}
        />
        <div className="select-filtering">
          {[...conditionsOfChoiceTrades].map((i, k) => <span>{i ? `${conditionsOfChoiceTrades.size-1 === k ? `${i}`: `${i}, `}` : i}</span>)}
        </div>
      </div>
      <Button
        disabled={!selectedIds.size}
        color="primary"
        onClick={onUnwind}
      >
        <FormattedMessage id="dashboards/unwind"
          defaultMessage="Unwind" />
      </Button>
    </div>
  );
};

export default OpenTradesUnwindSection;
