import ArrowBack from '@material-ui/icons/ArrowBack';
import Loader from 'components/common/loader/Loader';
import TableFilterDropdownList from 'components/tables/TableHeaderFilter/TableFilterDropdownList';
import TableFilterForm from 'components/tables/TableHeaderFilter/TableFilterForm';
import { getFiltersVisiblity } from 'components/tables/utils';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { orderByReactIntlNode } from 'utils';
import { DISPLAY_COLUMNS_PRICING_HISTORY, FILTERS_LIST_PRICING_HISTORY } from './constants';
import { PRICING_HISTORY_FORM_COLUMNS } from './TableColumnsToggler';
import TableColumnsTogglerWrapper from './TableColumnsTogglerWrapper';

export const PRICING_HISTORY_FORM_FILTERS = '@PRICING_HISTORY/FORM_FILTERS';

export const PRICING_HISTORY_FORM_FILTERS_INITIAL_VALUES = {
  filterUpdatedAt: null,
  filterUnderlyingName: null,
  filterUnderlyingExpiredAt: null
};

class PricingHistoryFilters extends PureComponent {

  state = {
    filters: FILTERS_LIST_PRICING_HISTORY
      .map((filter) => ({
        ...filter,
        isVisible: false
      })),
  }

  toggleFilter = (name) => {
    const { filters } = this.state;
    const index = filters.findIndex(item => item.name === name);
    filters[index].isVisible = !filters[index].isVisible;
    this.setState({
      filters: [...filters]
    });
  }

  render() {
    const { onClose, isActiveAllColumns, displayColumns, onChangeFilter, isLoading } = this.props;
    const { filters } = this.state;
    const { visibleFilters, inVisibleFilters } = getFiltersVisiblity(filters);
    return (
      <Fragment>
        <div className="d-sm-flex mb-3">
          <div className="pricing-history-title mb-2 mb-sm-0">
            <ArrowBack className="icon" onClick={onClose} />
            <FormattedMessage id="price.page.headers.pricing-history"
              defaultMessage="Pricing History" />
          </div>
          <div className="ml-auto">
            <div className="d-inline-block">
              <TableColumnsTogglerWrapper
                columns={orderByReactIntlNode(DISPLAY_COLUMNS_PRICING_HISTORY)}
                formName={PRICING_HISTORY_FORM_COLUMNS}
                storageName={'pricingHistory'}
                displayColumns={displayColumns}
                isActiveAllColumns={isActiveAllColumns} />
            </div>
            <div className="d-inline-block ml-3">
              <TableFilterDropdownList list={inVisibleFilters} onSelect={this.toggleFilter} />
            </div>
          </div>
        </div>
        <div className="loader-wrapper filter-form-loader">
          <TableFilterForm
            formName={PRICING_HISTORY_FORM_FILTERS}
            initialValues={PRICING_HISTORY_FORM_FILTERS_INITIAL_VALUES}
            filters={visibleFilters}
            onChangeFilter={onChangeFilter}
            toggleFilter={this.toggleFilter}
            {...this.props} />
          {isLoading && visibleFilters?.length ?
            <Fragment>
              <div className="loader-overlay"></div>
              <Loader />
            </Fragment> : null}
        </div>
      </Fragment>
    );
  }
}

PricingHistoryFilters.propTypes = {
  onChangeFilter: PropTypes.func,
};

export default PricingHistoryFilters;
