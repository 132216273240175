import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import { FETCH_PRICING_MARKET_DATA } from 'pages/price/ProductChart/ProductChartBlock/query';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import ProductChart from '../../price/ProductChart/ProductChart';

const CHART_COLORS = ['#757679', '#17375e', '#1f9ec5', '#5f6163'];

const chartLines = (props) => {
  const lines = [
    {
      label: 'Reference Price',
      name: 'referencePrice',
      value: parseFloat(props['referencePrice']),
      strokeDasharray: '2 2'
    }
  ];

  if(props.type === STRUCTURE_ACCUMULATOR.toLowerCase()) {
    lines.push({
      label: 'Accumulation Level',
      name: 'accumulationLevel',
      value: parseFloat(props['accumulationLevel']),
    }, {
      label: 'Barrier Level',
      name: 'barrierLevel',
      value: parseFloat(props['barrierLevel']),
    }, {
      label: 'Remainder Level',
      name: 'remainderLevel',
      value: parseFloat(props['remainderLevel']),
    })
  } else {
    props?.factSheetData?.fields?.forEach(item => {
      lines.push({
        label: item.label,
        name: item.field,
        value: item.value,
      });
    })
  }

  return lines;
}

const PrintProductChart = props => {
  const {
    marketDataId,
    quoteUnits,
  } = props;
  const lines = chartLines(props);

  return (
    marketDataId ?
      <Container fluid>
        <Fragment>
          <h3><span className="text-uppercase">
            <FormattedMessage id="print-pdf/historical-price-of"
              defaultMessage="Historical price of the reference contract" />&nbsp;</span>(<FormattedMessage id="common/in"
                defaultMessage="in" />&nbsp;{quoteUnits})
          </h3>
          <Query query={FETCH_PRICING_MARKET_DATA} variables={{ id: marketDataId }}>
            {({ data: { pricingAccumulatorMarketData, loading } = {} }) => {
              if (loading || !pricingAccumulatorMarketData) return null;
              return (
                <ProductChart
                  height={492}
                  cartesianGridColor={'rgba(0,0,0,0.2)'}
                  colors={CHART_COLORS}
                  xGap={30}
                  marketData={pricingAccumulatorMarketData}
                  marketDataId={marketDataId}
                  lines={lines}
                />)
            }}
          </Query>
        </Fragment>
      </Container>
      :
      null
  );
};

PrintProductChart.propTypes = {
  marketDataId: PropTypes.string.isRequired,
  quoteUnits: PropTypes.string.isRequired,
};

export default PrintProductChart;
