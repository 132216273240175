import moment from "moment";
import { DATE_FORMAT } from './constants';

export const DEFAULT_MAX_MONTH = moment().startOf('M').add(2, 'y').format(DATE_FORMAT);
export const DEFAULT_START_DATE_MONTH = moment().startOf('M').format(DATE_FORMAT);

export const datesBetweenFirstMonth = (startDate, endDate) => {
  const dates = [];
  const currDate = moment(startDate);
  const lastDate = moment(endDate);

  while (currDate.diff(lastDate) <= 0) {
    const isShowBalmo = parseInt(moment().format('DD')) !== 1 && moment(startDate).format(DATE_FORMAT) === currDate.clone().format(DATE_FORMAT);
    if (isShowBalmo) {
      dates.push({
        value: `${currDate.clone().format(DATE_FORMAT)}-false`,
        label: `${currDate.clone().format('MMM-YY')} (full)`,
        balmo: false,
        month: currDate.clone().format(DATE_FORMAT)
      });
    }
    dates.push({
      value: `${currDate.clone().format(DATE_FORMAT)}-true`,
      label: isShowBalmo ? `${currDate.clone().format('MMM-YY')} (balmo)` : currDate.clone().format('MMM-YY'),
      balmo: true,
      month: currDate.clone().format(DATE_FORMAT)
    });
    currDate.add(1, 'M')
  }
  return dates;
}

export const datesBetweenLastMonth = (startDate, endDate) => {
  const dates = [];
  const currDate = moment(startDate);
  const lastDate = moment(endDate);

  while (currDate.diff(lastDate) <= 0) {
    dates.push({
      value: currDate.clone().format(DATE_FORMAT),
      label: currDate.clone().format('MMM-YY')
    });
    currDate.add(1, 'M')
  }
  return dates;
}

export const isLegsErrors = (legsErrors = [{}]) => {
  const legs = Object.assign(...legsErrors);
  const keys = Object.keys(legs);

  keys.splice(keys.indexOf('expiryDate'), 1);
  return keys.length;
}

export const isCardErrors = (formErrors) => {
  if (formErrors.legs) {
    if (!isLegsErrors(formErrors.legs)) delete formErrors.legs;
  }
  return Object.keys(formErrors).length;
}
