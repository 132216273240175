import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import { normalizeId } from 'pages/orders/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';

class ProductChartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this._toggle.bind(this);
  }

  _toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { component, title, className, icon, iconTitle, isIconTooltip, cardId } = this.props;
    return (
      <div>
        <Button id={`pricing-mini-chart-${normalizeId(cardId)}`} color="link" size="sm" className="btn-icon" onClick={this.toggle}>{icon}</Button>
        {iconTitle && isIconTooltip ?
          <UncontrolledTooltip placement="top" target={`pricing-mini-chart-${normalizeId(cardId)}`}>
            {iconTitle}
          </UncontrolledTooltip>
          : null}
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} className={`modal-dark ${className}`}>
          <div className="modal-close" onClick={this.toggle}></div>
          <ModalHeader>
            {title}
          </ModalHeader>
          <ModalBody>
            {component}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ProductChartModal.defaultProps = {
  icon: <ZoomOutMap />,
  isIconTooltip: false,
};

ProductChartModal.propTypes = {
  title: PropTypes.string,
  iconTitle: PropTypes.string,
  isIconTooltip: PropTypes.bool.isRequired,
  cardId: PropTypes.string.isRequired,
  icon: PropTypes.node,
}

export default ProductChartModal;
