import { fvs } from 'pages/price/PriceForm/PriceForm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import FieldErrorMessage from '../FieldErrorMessage';
import FieldInfoMessage from '../FieldInfoMessage';
import { renderInputWithMask } from './inlineInput/InlineInputWithMask';
import { priceMessageShort } from './priceInput/PriceInfoMessages';

const InlinePriceInput = props => {
  const {
    label,
    type,
    input,
    meta,
    currency,
    notional,
    showMessage,
    ...rest
  } = props;
  const message = priceMessageShort(input.value, notional, currency);
  return (
    <FormGroup color={meta.touched && meta.invalid ? 'danger' : null}>
      {label ? <Label for={input.name}>{label}</Label> : null}
      {renderInputWithMask({
        type,
        ...input,
        ...rest,
        meta,
      })}
      <FieldErrorMessage {...meta} />
      {showMessage ? (
        <FieldInfoMessage message={message} input={input} {...meta} />
      ) : null}
    </FormGroup>
  );
};

InlinePriceInput.defaultProps = {
  type: 'text',
  showMessage: true
};

InlinePriceInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.node,
  showMessage: PropTypes.bool
};

export default connect(state => ({
  currency: fvs(state, 'payoutCurrency'),
  notional: fvs(state, 'notional')
}))(InlinePriceInput);
