export const PRICING_SWAP = '@PRICING/SWAP';
export const PRICING_SWAP_FORM_LOAD = `${PRICING_SWAP}/FORM_LOAD`;
export const PRICING_SWAP_DATA_LOADED = `${PRICING_SWAP}/FORM_DATA_LOADED`;
export const PRICING_SWAP_CARD_CREATE = `${PRICING_SWAP}/CARD/CREATE`;
export const PRICING_SWAP_CARD_DELETE = `${PRICING_SWAP}/CARD/DELETE`;
export const PRICING_SWAP_CARD_START_DELETE = `${PRICING_SWAP}/CARD/START_DELETE`;
export const PRICING_SWAP_CARD_UPDATE = `${PRICING_SWAP}/CARD/UPDATE`;
export const PRICING_SWAP_CARDS_UPDATE = `${PRICING_SWAP}/CARDS/UPDATE`;
export const PRICING_SWAP_CARDS_FETCH_MORE = `${PRICING_SWAP}/CARDS/FETCH_MORE`;
export const SWAP_CARDS_REQUEST_PRICE = `${PRICING_SWAP}/CARD/REQUEST/PRICE`;

export const PRICING_SWAP_CONFIRMATION_MODAL_APPROVE = `${PRICING_SWAP}/REQUEST/CONFIRMATION_MODAL_APPROVE`;
export const PRICING_SWAP_CONFIRMATION_MODAL_CANCEL = `${PRICING_SWAP}/REQUEST/CONFIRMATION_MODAL_CANCEL`;

export const PRICING_SWAP_REFETCH_EXPIRY = `${PRICING_SWAP}/REFETCH/CONTRACT_EXPIRY`;

export const loadSwapForm = (payload) => ({ type: PRICING_SWAP_FORM_LOAD, payload });

export const loadedSwapData = payload => ({
  type: PRICING_SWAP_DATA_LOADED,
  payload,
});

export const swapCardCreate = payload => ({
  type: PRICING_SWAP_CARD_CREATE,
  payload,
});

export const swapCardDelete = payload => ({
  type: PRICING_SWAP_CARD_DELETE,
  payload,
});

export const swapCardStartDelete = payload => ({
  type: PRICING_SWAP_CARD_START_DELETE,
  payload,
});

export const swapCardUpdate = payload => ({
  type: PRICING_SWAP_CARD_UPDATE,
  payload,
});

export const swapCardsUpdate = payload => ({
  type: PRICING_SWAP_CARDS_UPDATE,
  payload,
});

export const swapCardsFetchMore = payload => ({
  type: PRICING_SWAP_CARDS_FETCH_MORE,
  payload
});

export const swapCardsRequestPrice = payload => ({
  type: SWAP_CARDS_REQUEST_PRICE,
  payload
});

export const swapApproveModalAction = payload => ({
  type: PRICING_SWAP_CONFIRMATION_MODAL_APPROVE,
  payload
});

export const swapCancelModalAction = payload => ({
  type: PRICING_SWAP_CONFIRMATION_MODAL_CANCEL,
  payload
});

export const starRefetchinExpiry = () => ({
  type: PRICING_SWAP_REFETCH_EXPIRY,
});
