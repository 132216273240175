import { combineEpics } from 'redux-observable';
import options from './options';
import init from './init';
// import dynamicValidations from './dynamicValidations';

export default combineEpics(
  init,
  options,
  // dynamicValidations,
);
