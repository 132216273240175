import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { STRUCTURE_SWAP } from 'constants.js';
import { FormAction, change } from 'redux-form';
import { PRICING_SUBMIT_FORM, PRICING_FORM } from 'redux/actions/price';
import { swapCardCreate } from 'redux/actions/swap';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { createBulletStripCardMutation } from 'redux/queries/bulletStrip';
import { extractContractData } from '../../swap';
import { catchCardCreation } from 'redux/epics/price/structures/swap/cards/create';
import { SWAP_BULLET } from 'pages/price/output/asianSwap/constants';
import { BulletStripCardDataType, PriceFormPayload } from './types';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';


const saveCard = (commodityContract, cardData) => {
  return from(createBulletStripCardMutation(cardData))
  .pipe(
    filter(payload => !!payload),
    map((responceOrder) => {
      return swapCardCreate({
        ...cardData,
        ...responceOrder,
        quantity: 1,
        price: null,
        priceForCalcSpread: null,
        contractCode: null,
      })
    }),
    catchCardCreation(commodityContract)
  )
}

export const submitPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => {
      const { structure, swapType, bulletStrip } = priceFormValueSelector(state$.value) as BulletStripCardDataType || {};
      return structure === STRUCTURE_SWAP && swapType === SWAP_BULLET && bulletStrip;
    }),
    filter(({ payload }) => !!payload && Array.isArray(state$.value.price.pricings)),
    map(({ payload }: PriceFormPayload) => extractContractData(state$.value.price.pricings, payload)),
    filter((contract: any) => contract),
    debounceTime(200),
    switchMap(({ commodity: commodityContract, commodityCode, direction, quotedCurrency }: BulletStripCardDataType) => {
      const card = {
        direction,
        commodityCode,
        commodityContract,
        baseCurrency: quotedCurrency,
      }
      return saveCard(commodityContract, card)
    })
  );

const pricingFormFilter = action => action && action.meta && !action.meta.form.indexOf(PRICING_FORM)

const onFieldChanged = (action$, fieldName = '', debounce = 100) => action$.pipe(
  filter(changeAnyFormFieldActionFilter(fieldName)),
  filter(pricingFormFilter),
  debounceTime(debounce),
);

export const normalizeCardViewEpic = (action$) =>
  merge<FormAction>(
    onFieldChanged(action$, 'calendarSpread'),
    onFieldChanged(action$, 'bulletStrip'),
  ).pipe(
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta }) => {
      const { field } = meta;
      return from([
        change(PRICING_FORM, field === 'bulletStrip' ? 'calendarSpread' : 'bulletStrip', false),
      ]);
    }),
  );

export default combineEpics(
  submitPriceFormEpic,
  normalizeCardViewEpic
);
