import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { miniAccumulatorCardDisableField } from 'redux/actions/accumulator-mini';
import SolveIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';

const FieldLock = props => {
  const { children, fieldName, id, isLocked, onToogleAction, index } = props;

  const toggle = () => {
    onToogleAction({ id, field: fieldName, isLocked: !isLocked });
  }

  return (
    <div className="field-lock d-flex" onClick={isLocked ? toggle : null}>
      <div id={`field-lock-tooltip-${id}-${index}`} onClick={toggle} className="lock-icon">
        {isLocked ? <EditIcon /> : <SolveIcon />}
      </div>
      <UncontrolledTooltip placement="top" target={`field-lock-tooltip-${id}-${index}`}>
        {isLocked ? 'Edit' : 'Solve'}
      </UncontrolledTooltip>
      {children}
    </div>
  )
}

FieldLock.defaultProps = {
  isLocked: false,
};

FieldLock.propTypes = {
  isLocked: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ onToogleAction: miniAccumulatorCardDisableField }, dispatch);

export default connect(null, mapDispatchToProps)(FieldLock);
