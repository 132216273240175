import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const TourTooltip = props => {
  const { text, next, skip, showNext, disabledNext, showFooter } = props;
  const isDisabled = Boolean(disabledNext);
  return (
    text ?
      <Fragment>
        <div className="joyride-tooltip-content">
          {text}
        </div>
        {showFooter ?
          <div className="joyride-tooltip-footer clearfix text-center">
            {showNext ? <Button disabled={isDisabled} className="float-right mb-2" color="primary" onClick={next} tabIndex={-1} >
              <FormattedMessage id="common/next"
                defaultMessage="Next" />
            </Button> : null}
            <Button color="link" onClick={skip} tabIndex={-1}>
              <FormattedMessage id="common/skip"
                defaultMessage="Skip" />
            </Button>
          </div>
          :
          null
        }
      </Fragment>
      :
      null
  )
}

TourTooltip.defaultProps = {
  showNext: true,
  disabledNext: false,
  showFooter: true,
}

TourTooltip.propTypes = {
  text: PropTypes.node.isRequired,
  next: PropTypes.func,
  skip: PropTypes.func,
  showNext: PropTypes.bool,
  showFooter: PropTypes.bool,
  disabledNext: PropTypes.bool,
};

export default TourTooltip;
