import serialize from 'serialize-javascript';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

export const distinctObjectChanges = (a, b) => serialize(a) === serialize(b);

export const getSingleGraphQlError = error => error && error.graphQLErrors && error.graphQLErrors[0] ? error.graphQLErrors[0] : null

export const getRegisterFields = (formName, state) => {
  const { form } = state;
  const registeredFields = form[formName]?.registeredFields;
  return registeredFields;
}

export const getOption = (value, options = [], key = 'value') => options.find(({ [key]: findValue }) => value === findValue);
export const getOptionKey = (option = {}, key = 'label') => isNumber(option[key]) || isString(option[key]) ? option[key] : null;

export const roundUp = (value, decimalPlaces = 3, elseValue = 0) => {
  if (!(typeof value === 'number' && !Number.isNaN(value))) return elseValue;
  const powers = [1, 10, 100, 1000, 10000, 100000, 1000000];
  if (decimalPlaces > powers.length-1 || decimalPlaces < 0) throw new Error("Invalid decimal places specified")
  const power = powers[decimalPlaces];
  return (Math.ceil(value * power))/power;
}
