import Footer from 'components/common/footer/Footer';
import ThemeDetect from 'components/theme/ThemeDetect';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import NonLoggedHeader from './NonLoggedHeader';

const RenderLayout = props => {
  const { children } = props;
  return (
    <Fragment>
      <ThemeDetect />
      <div className="bg-overlay"></div>
      <div className="d-flex flex-column justify-content-between nonloged-container">
        <NonLoggedHeader />
        <Container fluid>
          {children}
        </Container>
        <Footer />
      </div>
    </Fragment>
  );
};

RenderLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RenderLayout;
