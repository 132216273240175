import { fetchNotifications, fetchUnreadNotifications, subscribeNotifications, readAllNotificationsMutation } from 'components/notifyMe/query';
import { combineEpics, ofType } from 'redux-observable';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_SET_ACCOUNT, USER_ONLINE_STATUS } from 'redux/actions/auth';
import { notificationsGetAction, notificationAdd, notificationsUpdateAction, NOTIFICATIONS_GET, NOTIFICATION_SHOW_ONLY_READ_TOGGLE, NOTIFICATION_READ_ALL, notificationUpdateCounter, NOTIFICATION_REPRIСING } from 'redux/actions/notifications';
import { PRICING_ACCUMULATOR_DATA_LOADED, PRICING_FORM_LIMITATION } from 'redux/actions/price';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { distinctObjectChanges } from 'redux/epics/utils';
import { selectTrailAction } from 'redux/prices/history/pricingHistoryActions';
import { from, merge, zip, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mapTo, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  NOTIFICATION_ACTION_TYPE_CONTRACT,
  NOTIFICATION_ACTION_TYPE_DEFAULT,
  NOTIFICATION_ACTION_TYPE_COMMON_ORDER,
  NOTIFICATION_ACTION_TYPE_OPEN_TRADES,
  NOTIFICATION_ACTION_TYPE_ORDER,
  NOTIFICATION_TYPE_SYSTEM,
  NOTIFICATION_ACTION_TYPE_CARD,
  NOTIFICATION_ACTION_TYPE_MINIFUTURE,
  NOTIFICATION_ACTION_TYPE_CALENDAR_SPREAD,
  NOTIFICATION_ACTION_TYPE_MF_FX,
  NOTIFICATION_ACTION_REFERENCE_PRICE
} from "./constants";
import {
  notificationsHandler,
  notificationsOrderHandler,
  notificationsCommonOrderHandler,
  notificationsOpenTradesHandler,
  updateStructures,
  notificationsFxCardHandler,
  minifutureCardsUpdate,
  calendarSpreadPermissionUpdate,
  mfFxPermissionUpdate,
  notificationsReferencePriceHandler,
} from './notificationsHandler';

const repriceNotitificationEpic = action$ =>
  action$.pipe(ofType(NOTIFICATION_REPRIСING))
    .pipe(
      map(({ payload: { pricingHistory } }) => pricingHistory),
      switchMap(
        (pricingHistory) => {
          return zip(
            action$.pipe(ofType(PRICING_ACCUMULATOR_DATA_LOADED)), //@TODO: add to store structure config
            action$.pipe(ofType(PRICING_FORM_LIMITATION), take(2))
          )
            .pipe(
              mapTo(selectTrailAction(pricingHistory)),
              take(1)
            )
        })
    );

const getNotificationsEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(NOTIFICATIONS_GET)),
    action$.pipe(ofType(NOTIFICATION_SHOW_ONLY_READ_TOGGLE))
  ).pipe(
    switchMap(() => {
      return from(fetchNotifications(state$.value.notifications.showOnlyRead)).pipe(
        filter(({ notificationsList }) => notificationsList),
        map(({ notificationsList }) => notificationsList),
        map((list) => notificationsUpdateAction(list)),
        catchError(() => notificationErrorSimple(`Can't fetch notifications`))
      );
    })
  );

const notitifacationsSubscriptionEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(AUTH_SET_ACCOUNT),
      switchMap(() => {
        return action$
          .pipe(
            ofType(AUTH_LOGIN),
            switchMap(() => {
              return from(subscribeNotifications()).pipe(
                filter(({ data }) => data && data.notifications),
                map(({ data: { notifications } }) => notifications),
                distinctUntilChanged(distinctObjectChanges),
                switchMap(data => {
                  return from(detectNotitificationAction(data, state$)).pipe(
                    distinctUntilChanged(distinctObjectChanges),
                  )
                }),
                takeUntil(action$.pipe(ofType(AUTH_LOGOUT)))
              )
            }),
          );
      }),
    );

const notitifacationsCounterEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(AUTH_LOGIN)),
    action$.pipe(
      ofType(USER_ONLINE_STATUS),
      filter(({ payload }) => payload && state$.value?.auth?.isAuthorized),
      debounceTime(500)
    ),
  )
    .pipe(
      switchMap(() => {
        return from(fetchUnreadNotifications()).pipe(
          filter(({ unreadNotifications }) => unreadNotifications),
          map(({ unreadNotifications }) => notificationUpdateCounter(unreadNotifications)),
          takeUntil(action$.pipe(ofType(AUTH_LOGOUT))),
          catchError(() => of(notificationErrorSimple(`Can't fetch notifications counter`)))
        )
      }),
    );

const systemNotifications = (notification, state$) => {
  const { payload } = notification;
  const type = payload && payload.type ? payload.type : '';
  switch (type) {
    case NOTIFICATION_ACTION_TYPE_DEFAULT:
      return notificationsHandler(notification, state$);
    case NOTIFICATION_ACTION_TYPE_ORDER:
      return notificationsOrderHandler(notification, state$);
    case NOTIFICATION_ACTION_TYPE_COMMON_ORDER:
      return notificationsCommonOrderHandler(notification, state$);
    case NOTIFICATION_ACTION_TYPE_OPEN_TRADES:
      return notificationsOpenTradesHandler(notification, state$);
    case NOTIFICATION_ACTION_TYPE_CONTRACT:
      return updateStructures(state$);
    case NOTIFICATION_ACTION_TYPE_CARD:
      return notificationsFxCardHandler(notification, state$);
    case NOTIFICATION_ACTION_TYPE_MINIFUTURE:
      return minifutureCardsUpdate(notification, state$);
    case NOTIFICATION_ACTION_TYPE_CALENDAR_SPREAD:
      return calendarSpreadPermissionUpdate(notification, state$);
    case NOTIFICATION_ACTION_TYPE_MF_FX:
      return mfFxPermissionUpdate(notification, state$);
    case NOTIFICATION_ACTION_REFERENCE_PRICE:
      return notificationsReferencePriceHandler(notification, state$);
    default:
      return [];
  }
}

const defaultMessage = (notification, state$) => {
  let counter = state$.value.notifications.counter >= 0 ? ++state$.value.notifications.counter : 0;
  return [
    notificationAdd(notification),
    notificationUpdateCounter(counter)
  ];
}

const detectNotitificationAction = (notification, state$) => {
  const { type } = notification;
  switch (type) {
    case NOTIFICATION_TYPE_SYSTEM:
      return systemNotifications(notification, state$);
    default:
      return defaultMessage(notification, state$);
  }
}

const readAllNotificationsEpic = (action$) =>
  action$.pipe(ofType(NOTIFICATION_READ_ALL))
  .pipe(switchMap(() => {
    return from(readAllNotificationsMutation())
      .pipe(
        switchMap(() => from([
          notificationsGetAction(),
          notificationUpdateCounter(0)
        ]))
      );
  }));

export default combineEpics(
  repriceNotitificationEpic,
  notitifacationsSubscriptionEpic,
  notitifacationsCounterEpic,
  getNotificationsEpic,
  readAllNotificationsEpic,
);
