import gql from 'graphql-tag';

export const PRODUCT_ANALYSIS_FRAGMENT = gql`fragment ProductAnalysisFragment on PricingAccumulatorHistoryType {
  id
  strikeLevelInPercentageOfReferencePrice
  barrierLevelInPercentageOfReferencePrice
  spotNotional
  businessDays
  notionalPhysical
  futTradingUnits
  quotedCurrency
}`;
