import lodashMerge from 'lodash/merge';
import { change } from 'redux-form';
import { ofType } from 'redux-observable';
import { miniAccumulatorCardUpdate, PRICING_MINI_ACCUMULATOR_CARD_DISABLE } from 'redux/actions/accumulator-mini';
import { from, merge } from 'rxjs';
import { debounceTime, filter, first, switchMap } from 'rxjs/operators';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';
import { getCardId } from '../structures/vanilla/utils';
import { defaultCardProps, findPricinigMiniCard } from './limitations';
import { emptyPricingFormFilter, getPricingMiniFormName, pricinigMiniFormFilter } from './utils';

export const lockFieldAction = (fields = [], fieldName, isLocked) => {
  let nextDisableIndex = null;
  if (!isLocked) {
    const disabledIndexes = fields.reduce((filtered, field, index) => {
      if (field.name === fieldName) nextDisableIndex = index + 1;
      if (nextDisableIndex === index && !field.isLock) nextDisableIndex = index + 1;
      if (field.isLock) filtered.push(index);
      return filtered;
    }, []);
    if (nextDisableIndex > disabledIndexes[disabledIndexes.length - 1]) nextDisableIndex = disabledIndexes[0];
  };

  return fields
    .map((field, index) => {
      let disabled = field.disabled;
      if (field.isLock) {
        disabled = fieldName === field.name ? isLocked : false;
        if (nextDisableIndex === index) disabled = true;
      }
      return {
        ...field,
        disabled,
        isResult: false,
      }
    });
};

export const lockPricinigMiniEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARD_DISABLE),
    debounceTime(50),
    filter(() => emptyPricingFormFilter(state$)),
    filter(({ payload }) => !!payload && state$.value.accumulatorMini?.cards?.length),
    switchMap(({ payload = {} }) => {

      const cardId = payload.id;
      const formName = getPricingMiniFormName(cardId);
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};

      const lockFileds = card.fields;
      const fields = lockFieldAction(lockFileds, payload.field, payload.isLocked);
      const resetFields = fields.filter(({ isLock, disabled, name }) => isLock && disabled && payload.field !== name);

      const newCard = {
        ...card,
        fields,
        ...defaultCardProps,
      };
      const actions = [
        change(formName, payload.field, null),
        miniAccumulatorCardUpdate(newCard),
        ...resetFields.map(({ name }) => change(formName, name))
      ];
      return from(actions);
    })
  );

export const updateFieldPriceMiniEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('barrLevel')),
      filter(({ payload }) => !!payload),
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('accLevel')),
      filter(({ payload }) => !!payload),
    ),
  )
    .pipe(
      debounceTime(50),
      filter(pricinigMiniFormFilter),
      filter(() => state$.value.accumulatorMini.cards && state$.value.accumulatorMini.cards.length),
      switchMap(({ meta }) => {
        const cardId = getCardId(meta);
        const findIndex = state$.value.accumulatorMini.cards.findIndex(({ id }) => id === cardId);

        const lockFileds = state$.value.accumulatorMini.cards[findIndex].fields;
        const fields = lockFieldAction(lockFileds, meta.field, true);
        const newCard = {
          ...state$.value.accumulatorMini.cards[findIndex],
          fields: lodashMerge(state$.value.accumulatorMini.cards[findIndex].fields, fields),
        };

        const actions = [
          miniAccumulatorCardUpdate(newCard),
        ];
        return from(actions);
      }),
      first(),
    );

export default lockPricinigMiniEpic;
