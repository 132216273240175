import React, { Fragment, useEffect, useState, useCallback, useRef } from "react";
import ReactDOM from 'react-dom';
import moment from "moment";
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import { isNil } from "lodash";
import InlineBox from 'components/form/inputs/InlineBox';
import InlineInput from "components/form/InlineInput";
import FieldErrorMessage from 'components/form/FieldErrorMessage';
import { isWeekday, tommorowDay } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import { DATE_FORMAT_PRICING_ACCUMULATOR_MINI } from 'constants.js';
import { validateLegs } from './validate';


const RenderDate = ({ input: { value } }) => (<span>{moment(value).format('MMM-YY')}</span>);

const DropdownPortal = (ref) => (props) => {
  const { children } = props;
  if (!ref?.current || !children) return null;

  return ReactDOM.createPortal(
    children,
    ref.current
  );
}

const RenderDropdownItem = ({ fields, Portal, monthListOptions = [] }) => (
  <Fragment>
    {
      fields.map((leg, index) => {
        const { contractExpiry } = fields.get(index) || {};
        const { expiryDate } = monthListOptions.find(({ value }) => value === contractExpiry) || { expiryDate: null };
        return (
          <DropdownItem key={index} text>
            <Row>
              <Col>
                <Field
                  name={`${leg}.contractExpiry`}
                  component={RenderDate}
                />
              </Col>
              <Col>
                <Field
                  name={`${leg}.expiryDate`}
                  placeholder="Expiry Date"
                  type="date"
                  readOnlyInput={true}
                  popperContainer={Portal}
                  filterDate={isWeekday}
                  minDate={tommorowDay()}
                  maxDate={expiryDate}
                  dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
                  component={InlineInput}
                />
              </Col>
            </Row>
          </DropdownItem>
        )
      })
    }
  </Fragment>
);

export const FixingDatesField = (props) => {
  const { label, formData } = props;
  const { monthListOptions } = formData;

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const toggle = () => setIsOpen(!isOpen);

  const onClose = useCallback(() => {
    const formErrors = validateLegs(formData);
    if (isNil(formErrors.expiryDate)) {
      const { expiryDate } = Object.assign(...(formErrors?.legs || [{}]));
      setErrors(expiryDate);
    } else {
      setErrors(null);
    }
  }, [formData]);

  useEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  return (
    <InlineBox className="FixingDatesField" label={label} meta={{}}>
      <Dropdown isOpen={isOpen} toggle={toggle} direction={'down'} inNavbar={true}>
        <div className="lable">
          <Field
            name="fixingDates"
            disabled={true}
            component={InlineInput}
          />
        </div>
        <FieldErrorMessage errorOnIsTouched={false} invalid={true} submitting={false} error={errors} />
        <DropdownToggle caret>
          <span className="Select-arrow-zone">
            <span className="Select-arrow"></span>
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <Fragment>
            <DropdownItem header>
              <Row>
                <Col>Contract</Col>
                <Col>Expiry Date</Col>
              </Row>
            </DropdownItem>
            <DropdownItem divider />
          </Fragment>
          <FieldArray name="legs" component={RenderDropdownItem} Portal={DropdownPortal(dropdownRef)} monthListOptions={monthListOptions} />
        </DropdownMenu>
        <div ref={dropdownRef}></div>
      </Dropdown>
    </InlineBox>
  );
};

FixingDatesField.defaultProps = {
  label: 'Fixing Dates',
}

FixingDatesField.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FixingDatesField;
