import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { maskPriceInput, normalizePriceInput } from 'utils/formformaters';
import FieldErrorMessage from '../../FieldErrorMessage';
import MaskedInput from '../MaskedInput';

export const renderInputWithMask = props => {
  const { type, intl, ...rest } = props;
  return (
    <MaskedInput
      type={type}
      maskNormalizer={normalizePriceInput}
      mask={maskPriceInput}
      {...rest}
    />
  );
};

const InlineInputWithMask = props => {
  const {
    label,
    type,
    input,
    meta,
    currency,
    notional,
    showMessage,
    forceError,
    errorOnIsTouched = true,
    ...rest
  } = props;
  const isDanger = forceError ? true : meta.touched && meta.invalid;
  return (
    <FormGroup color={isDanger ? 'danger' : null}>
      {label ? <Label for={input.name}>{label}</Label> : null}
      {renderInputWithMask({
        type,
        ...input,
        ...rest,
        meta,
      })}
      <FieldErrorMessage errorOnIsTouched={errorOnIsTouched} {...meta} />
    </FormGroup>
  );
};

InlineInputWithMask.defaultProps = {
  type: 'text',
  forceError: false,
};

InlineInputWithMask.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.node,
  forceError: PropTypes.bool
};

export default InlineInputWithMask;
