import isNumber from 'lodash/isNumber';
import { filterDynamicPrices } from 'pages/price/mini/components/chart/utils';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { mapNodes } from 'utils';
import ProductChartTooltip from '../ProductChartTooltip';

const Chart = props => {
  const {
    height,
    cartesianGridColor,
    colors,
    xGap,
    marketData,
    lines = [],
  } = props;
  const matrix = marketData && marketData.matrix ? mapNodes(marketData.matrix) : [];
  const chartData = filterDynamicPrices(matrix, lines);

  return (
    chartData && chartData.length ?
      <ResponsiveContainer width="100%" height={height}>
        <LineChart data={chartData} margin={{ left: -10 }}>
          <Line strokeWidth="2" dot={false} type="linear" name="Settlement Price" dataKey="price" stroke={colors[0]} />
          {lines
            .filter((line) => line.name && line.label && isNumber(line.value))
            .map((line, lineIndex) => {
              return (
                <Line
                  strokeDasharray={line.strokeDasharray}
                  key={line.name}
                  strokeWidth="2"
                  dot={false}
                  type="linear"
                  name={line.label}
                  dataKey={line.name}
                  stroke={colors[lineIndex + 1] || '#ba68c8'}
                  connectNulls={true} />
              )
            })}
          <CartesianGrid stroke={cartesianGridColor} strokeDasharray="10 1" />
          <XAxis minTickGap={xGap} type="category" dataKey="dateLabel" />
          <YAxis type="number" domain={['auto', 'auto']} />
          <Tooltip content={<ProductChartTooltip />} />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
      :
      null
  )
}

Chart.defaultProps = {
  height: 320,
  cartesianGridColor: 'rgba(255, 255, 255,  0.1)',
  colors: ['#cdcdcd', '#26c481', '#0bb2dd', '#dddddd', '#6a1b9a'],
  xGap: 5,
  barrierLevel: null,
  accumulationLevel: null,
  remainderLevel: null,
};

Chart.propTypes = {
  marketData: PropTypes.array.isRequired,
  xGap: PropTypes.number,
  barrierLevel: PropTypes.number,
  accumulationLevel: PropTypes.number,
  remainderLevel: PropTypes.number,
};

export default memo(Chart);
