import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Loader from 'components/common/loader/Loader';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import debounce from 'lodash/debounce';
import React, { Fragment, PureComponent } from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';

const style = {
  verticalAlign: 'middle',
  padding: '0 15px'
};

class OpenTradeDownloadBtn extends PureComponent {

  constructor(args) {
    super(args);
    this.state = {
      loading: false,
    };
  }

  downloadFileRequest = debounce(async () => {
    const { client, variables, query } = this.props;
    this.setState({ loading: true });
    try {
      const { data } = await client.query({
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
        query,
        variables
      });
      const { buffer, name } = data?.otcStatementsCsvReport || data?.otcOrdersCsvReport;
      this.fileForceDownload(buffer, name);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });

  }, 200)

  fileForceDownload = (link, filename) => {
    const linkEl = document.createElement('a');
    linkEl.href = link;
    linkEl.target = '_blank'
    linkEl.download = filename ? filename : 'dashboard.csv'
    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
  }

  render() {
    const { loading } = this.state;
    return (
      <div className={`download-button-wrapper`} style={style}>
        <Fragment>
          <div onClick={this.downloadFileRequest} className="btn-download">
            {loading ?
              <Loader className="loader-small mt-0 mb-0 m-auto" />
              :
              <Fragment>
                <LibraryBooksIcon />
                <small className="ml-1">CSV</small>
              </Fragment>
            }
          </div>
        </Fragment>
      </div>
    )
  }
}

export default compose(
  withApollo,
)(OpenTradeDownloadBtn);
