import Loader from 'components/common/loader/Loader';
import { QUERY_NETWORK_ONLY_OPTIONS } from 'components/graphql/constants';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { notificationApiError } from 'redux/errors/actions';
import { QUERY_APP_VERSION } from './query';

function BackEndVersion(props) {
  const { data: { loading, version: beVersion, error }, notificationApiError, feVersion } = props;

  const showError = () => {
    if (error) {
      notificationApiError();
    }
  }

  useEffect(showError, []);

  return (
    <Fragment>
      {loading ? <Loader /> : (
        beVersion ? `Version ${feVersion}/${beVersion}` : null
      )}
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ notificationApiError }, dispatch);

BackEndVersion.propTypes = {
  data: PropTypes.object.isRequired,
  notificationApiError: PropTypes.func.isRequired,
  feVersion: PropTypes.string.isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  graphql(QUERY_APP_VERSION, QUERY_NETWORK_ONLY_OPTIONS)
)(BackEndVersion);
