import { themeTextsSelector } from 'components/theme/utils';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import BackEndVersion from './BackEndVersion';
import FooterStaticPages from './FooterStaticPages';

const year = (new Date()).getFullYear();

const Footer = ({ version, texts = {} }) => {
  const copyright = texts?.copyright;
  return (
    <div className="footer-nav mt-auto mt-sm-0">
      <div className="text-center py-2">
        <FooterStaticPages />
      </div>
      <div className="row pb-2">
        <div className="col-xs-12 col-sm-4" />
        <div className="col-xs-12 col-sm-4 text-center footer-text">
          {copyright}&nbsp;&copy; {year}
        </div>
        <div className="col-xs-12 col-sm-4 text-right">
          <div className="px-3 version">
            <BackEndVersion feVersion={version} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  version: state => state.version,
  texts: themeTextsSelector,
});

export { Footer as Component };
export default connect(mapStateToProps)(Footer);
