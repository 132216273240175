import { INPUT_NUMBER_MAX_VALUE, STRUCTURE_VANILLA } from 'constants.js';
import { extractSwapContract } from 'pages/price/output/selectors';
import { vanillaFormValuesSelector } from 'pages/price/output/vanilla/VanillaPricnigOutputCardForm';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import { blur } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { priceFormFailed } from 'redux/actions/price';
import { swapCardUpdate } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { vanillaFormFilter, vanillaTrailPricesFilter } from 'redux/epics/price/structures/vanilla/';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { updateVanillaCardMutation } from 'redux/queries/vanilla';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, filter, switchMap } from 'rxjs/operators';
import { changeAnyFormFieldActionFilter, changeFormRadioFieldActionFilter } from 'utils/reduxFormSelector';
import { getCardByFormName } from '../utils';

export const cardUpdateFieldsEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('quantity')),
      filter(vanillaFormFilter),
      filter(() => vanillaTrailPricesFilter(state$)),
      debounceTime(200),
      filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE),
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('strikeLevel')),
      filter(vanillaFormFilter),
      filter(() => vanillaTrailPricesFilter(state$)),
      debounceTime(500),
      filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE),
    )
  )
    .pipe(
      switchMap(({ meta }) => {
        const fieldName = meta.field;
        const card = getCardByFormName(state$, meta);
        const formData = vanillaFormValuesSelector(card.id, state$.value) || {};
        const fieldValue = formData[fieldName];
        const cardUpdated = {
          ...card,
          ...formData,
          [fieldName]: parseFloat(fieldValue)
        }
        return updateCardData(STRUCTURE_VANILLA, cardUpdated, card, `change ${fieldName}`);
      })
    );

export const cardUpdateExpiryEpic = (action$, state$) =>
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('contractExpiry')),
    filter(vanillaFormFilter),
    filter(() => vanillaTrailPricesFilter(state$)),
    debounceTime(200),
    filter(({ payload }) => !!payload),
    switchMap(({ meta, payload: expiryAction }) => {
      const structure = priceFormValueSelector(state$.value).structure;
      const card = getCardByFormName(state$, meta);
      const { id, commodityContract } = card;
      const { bloombergTicker, strikeLevel } = vanillaFormValuesSelector(id, state$.value) || {}

      const findContract = extractSwapContract({
        pricings: state$.value.price.pricings,
        commodity: commodityContract
      });

      const cardUpdated = {
        ...card,
        contractExpiry: expiryAction,
        quotedCurrency: findContract.quotedCurrency,
        bloombergTicker,
        strikeLevel,
      };
      const failActions = [
        blur(meta.form, meta.field, card['contractExpiry'])
      ];
      const successAction = [
      ]
      return updateCardData(structure, cardUpdated, card, 'changecontract expiry', failActions, successAction);
    })
  );

export const cardUpdateStructureTypeEpic = (action$, state$) =>
  action$.pipe(
    filter(changeFormRadioFieldActionFilter('structureType')),
    filter(vanillaFormFilter),
    filter(() => vanillaTrailPricesFilter(state$)),
    debounceTime(200),
    switchMap(({ payload, meta }) => {
      const card = getCardByFormName(state$, meta);
      const cardUpdated = {
        ...card,
        structureType: payload,
      }
      const failActions = [
        blur(meta.form, meta.field, card['structureType'])
      ];
      return updateCardData(STRUCTURE_VANILLA, cardUpdated, card, 'change type', failActions);
    })
  );

const updateCardData = (structure, card, cardOld, errorMsgAction = 'update price', actions = [], successActions = []) => {
  const cardSimple = {
    ...cardOld,
    loading: false,
    isLoading: false
  }

  return from(updateVanillaCardMutation(structure, card)).pipe(
    switchMap(({ price, structureType: type, contractCode, priceForCalcSpread }) => {
      const cardNew = {
        ...cardOld,
        ...card,
        priceForCalcSpread,
        type,
        price,
        contractCode,
        loading: false,
        isLoading: false,
      };
      return [swapCardUpdate(cardNew), ...successActions];
    }),
    catchError((error) => {
      const errorActions = [
        priceFormFailed(),
        swapCardUpdate(cardSimple)
      ];
      const err = getSingleGraphQlError(error);
      if (err?.message && err.code === 403) {
        return from([
          ...actions,
          ...errorActions,
          notificationErrorSimple(err.message),
        ])
      }

      return from([
        ...actions,
        ...errorActions,
        cardError(card, errorMsgAction),
      ]);
    })
  )
}

export const cardError = (card, action = 'delete') => {
  if (!card) {
    return notificationErrorSimple(`Can't delete card`);
  }
  const label = card.commodityContract ? ` ${card.commodityContract}` : '';
  const date = formatDate2UI(card.contractExpiry);
  const text = `Can't ${action} for${label} - ${date}`;
  return notificationErrorSimple(text);
}

export default combineEpics(
  cardUpdateExpiryEpic,
  cardUpdateStructureTypeEpic,
  cardUpdateFieldsEpic,
);
