import isNull from 'lodash/isNull';

//@TODO: simplify logic
export const onChangeField = (e, onChange, { name, onChangeFilter, meta, type, operator }) => {
  let sendValue;
  if (type === 'checkbox') {
    sendValue = e.target.checked;
  } else if (!!e && e.target && e.target.value) {
    sendValue = e.target.value;
  } else if (!!e && (e.value || isNull(e.value))) {
    sendValue = e.value;
  } else if (!!e && e.min && e.max) {
    sendValue = e;
  } else {
    sendValue = null;
  }

  if (typeof onChangeFilter === 'function') {
    onChangeFilter({ name, value: sendValue, e, meta, operator });
  }
  return onChange(e);
}
