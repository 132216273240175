import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import reducers from 'redux/reducers';
import { rootEpic } from 'redux/epics';
import { alertReducer } from 'react-redux-alerts';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { APP_VERSION } from 'constants.js';
import formReducer from 'redux/reducers/reduxForm';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history } from 'components/router/BrowserRouter';

const appReducer = combineReducers({
  ...reducers,
  version: data => data || window.VERSION || APP_VERSION,
  alerts: alertReducer,
  form: formReducer,
  router: connectRouter(history),
});

const epicMiddleware = createEpicMiddleware();

const middleware = compose(
  applyMiddleware(
    epicMiddleware,
    routerMiddleware(history),
  ),
  (process.env.NODE_ENV !== 'production' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
);

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = {
      alerts: state.alerts
    }
  }

  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  middleware
);

epicMiddleware.run(rootEpic);

export default store;
