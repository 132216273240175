import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import { structuresConfigInputFielsUniq, BARRIER_TYPE_AMERICAN } from 'pages/price/FormItem';

let dynamicFormFields = {};

structuresConfigInputFielsUniq.forEach(name => dynamicFormFields[name] = null);

export const PRICING_FORM_INITIAL_VALUES = {
  structure: STRUCTURE_ACCUMULATOR,
  structureType: 'Producer',
  notional: 10,
  barrierType: BARRIER_TYPE_AMERICAN,
  payoutCurrency: null,
  expiration: null,
  leverageValue: 2,
  leverageStyle: 'Daily',
  accumulationStyle: 'Daily',
  remainderFeature: 'none',
  ...dynamicFormFields
};
