import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

export const StaticPage = props => (
  <ErrorBoundary>
    <Bundle load={() => import('./StaticPageModalRoute')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default StaticPage;
