import React from 'react';
import PropTypes from 'prop-types';
import { PRICING_MINI_CARD_TYPES, PRICING_MINI_CARD_TYPE_DEFAULT, PRICING_MINI_CARD_TYPE_FLEX } from 'pages/price/mini/components/pricinigMiniForm/constants.js';
import PricinigMiniFormDefault from './PricinigMiniFormDefault';
import PricinigMiniFormFlexible from './PricinigMiniFormFlexible';

const PricinigMiniFormChooser = props => {
  const { type } = props;
  switch (type) {
    case PRICING_MINI_CARD_TYPE_FLEX:
      return <PricinigMiniFormFlexible {...props} />;
    default:
      return <PricinigMiniFormDefault {...props} />
  }
};

PricinigMiniFormChooser.defaultProps = {
  type: PRICING_MINI_CARD_TYPE_DEFAULT
};

PricinigMiniFormChooser.propTypes = {
  type: PropTypes.oneOf(PRICING_MINI_CARD_TYPES).isRequired,
};

export default PricinigMiniFormChooser;
