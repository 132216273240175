//Open link
export const ROUTE_LOGIN = "/login";
export const ROUTE_SIGN_UP = "/sign-up";
export const ROUTE_EMAIL_CONFIRM = "/confirm/:token";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_RESET_PASSWORD = "/reset-password/:sessionId";
export const ROUTE_STATIC_PAGE = "/page/:slug";
export const ROUTE_NOT_FOUND = "/not-found";
export const ROUTE_HOME = "/";

//Private links
export const ROUTE_MOCKED_DASHBOARD = "/dashboard";
export const ROUTE_MOCKED_DASHBOARD_REQUEST = `${ROUTE_MOCKED_DASHBOARD}/request`;
export const ROUTE_DASHBOARDS = "/dashboards";
export const ROUTE_DASHBOARDS_REQUEST = `${ROUTE_DASHBOARDS}/request`;
export const ROUTE_DASHBOARD_SINGLE = `${ROUTE_DASHBOARDS}/:id`;
export const ROUTE_DASHBOARD_SINGLE_PAGE = `${ROUTE_DASHBOARDS}/:id/page/:page`;
export const ROUTE_PRICING = "/pricing";
export const ROUTE_PRICING_MINI = `${ROUTE_PRICING}/mini`;
export const ROUTE_PRICING_MINI_COMMODITY = `${ROUTE_PRICING_MINI}/:underlyingId`;
export const ROUTE_PRICING_MINI_COMMODITY_GROUPS = `${ROUTE_PRICING_MINI_COMMODITY}/groups`;
export const ROUTE_PRICING_STRUCTURE = `${ROUTE_PRICING}/:structure`;
export const ROUTE_PRICING_SWAP_ASIAN = `${ROUTE_PRICING}/:structure/asian`;
export const ROUTE_PRICING_HISTORY = `${ROUTE_PRICING}/history`;
export const ROUTE_PRICING_ORDERS_SWAP = `${ROUTE_PRICING}/swap/trade`;
export const ROUTE_PRICING_ORDERS_ASIAN = `${ROUTE_PRICING}/swap/asian/trade`;
export const ROUTE_PRICING_ORDERS_BULLET_STRIP = `${ROUTE_PRICING}/swap/bullet-strip/trade`;
export const ROUTE_PRICING_ORDERS_FX = `${ROUTE_PRICING}/forwards-ndfs/trade`;
export const ROUTE_PRICING_ORDERS_VANILLA = `${ROUTE_PRICING}/vanilla/trade`;
export const ROUTE_PRICING_ORDERS = `${ROUTE_PRICING}/orders`;
export const ROUTE_PRICING_HISTORY_ID = `${ROUTE_PRICING_HISTORY}/:id`;

export const ROUTE_MY_DASHBOARD = "/my-dashboard";

export const ROUTE_BOOKING = "/booking";
export const ROUTE_BOOKING_DETAIL = `${ROUTE_BOOKING}/:packageId`;

export const ROUTE_PROFILE = "/profile";
export const ROUTE_MINIFUTURE = "/minifuture";
export const ROUTE_MINIFUTURE_HISTORY = `${ROUTE_MINIFUTURE}/history`;
export const ROUTE_MINIFUTURE_ORDERS = `${ROUTE_MINIFUTURE}/orders`;
export const ROUTE_MINIFUTURE_TRADE = `${ROUTE_MINIFUTURE}/trade`;
// export const ROUTE_NOTIFICATIONS = '/notifications';
// export const ROUTE_NOTIFICATIONS_EXPIRED = '/notifications-expired';
// export const ROUTE_NOTIFICATION_READ = ROUTE_NOTIFICATIONS + '/:id/read';
export const ROUTE_CUSTOM_REQUESTS = "/custom-request";
export const ROUTE_CUSTOM_REQUESTS_NEW = `${ROUTE_CUSTOM_REQUESTS}/new`;
export const ROUTE_CUSTOM_REQUEST_OPEN = `${ROUTE_CUSTOM_REQUESTS}/:id/open`;
export const ROUTE_TRADES_HISTORY = "/trades-history";

export const ROUTE_PORTAL = "/portal";
export const ROUTE_PORTAL_CATEGORY = `${ROUTE_PORTAL}/category/:slug?`;
export const ROUTE_PORTAL_SINGLE_POST = `${ROUTE_PORTAL}/:post`;

export const ROUTE_STRUCTURED_PRODUCTS = "/structured-products";
export const ROUTE_STRUCTURED_PRODUCTS_SINGLE = `${ROUTE_STRUCTURED_PRODUCTS}/:structure`;
export const ROUTE_STRUCTURED_PRODUCTS_HISTORY = `${ROUTE_STRUCTURED_PRODUCTS}/history`;

export const ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS = "/sp-preview";
export const ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS_HISTORY = `${ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS}/history`;
