import React, { Fragment, memo } from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import { VANILLA_DIRECTIONS } from './constants';

const VanillaFormFields = props => {
  const {
    structures,
    commodityContracts
  } = props;
  return (
    <Fragment>
      <Row>
        <Col xs={12} sm={4}>
          <Field name="structure"
            label={<FormattedMessage id="form.price.fields.structure.label"
              defaultMessage="Structure" />}
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            options={structures}
          />
        </Col>

        <Col xs={12} sm={6}>
          <Field name="commodity"
            label="Commodity Contract"
            component={InlineSelectInput}
            justValue={true}
            preselectIfOneOption={true}
            options={commodityContracts}
          />
        </Col>

        <Col xs={12}>
          <Field name="direction"
            label={<FormattedMessage id="form.price.fields.barrier-type.label"
              defaultMessage="Direction" />}
            justValue={true}
            component={RadioGroup}
            options={VANILLA_DIRECTIONS}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default memo(VanillaFormFields);
