import validateJs from 'utils/validate.js';
import validateMessages from 'utils/validate.messages';
import { INPUT_NUMBER_MAX_VALUE_LARGE } from 'constants.js'

export default function validate(data, props) {
  const { validationParams } = props;

  return validateJs(data, {
    ...validationParams,
    commodity: {
      presence: {
        message: `^Commodity Contract ${validateMessages.blank}`
      },
    },
    contractExpiry: {
      presence: {
        message: `^Contract Expiry ${validateMessages.blank}`
      },
    },
    structureType: {
      presence: true,
    },
    expiration: {
      presence: {
        message: `^Structure Expiry Date ${validateMessages.blank}`
      },
    },
    payoutCurrency: {
      presence: true,
    },
    structure: {
      presence: true,
    },
    barrierType: {
      presence: true,
    },
    leverageValue: {
      presence: true,
    },
    leverageStyle: {
      presence: true,
    },
    underlying: {
      presence: true,
    },
    notional: {
      presence: true,
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE_LARGE,
      }
    }
  }) || {};
}
