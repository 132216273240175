import React, { Fragment, useMemo } from 'react';
import PriciniMiniChartBlock from 'pages/price/mini/components/chart/PriciniMiniChartBlock';
import FactSheet from 'pages/price/mini/components/factsheet/FactSheet';
import { ProductDescriptionBtn } from 'pages/price/mini/components/pricinigMiniForm/ProductDescriptionControl';
import CopyCardControl from 'pages/price/mini/components/pricinigMiniForm/CopyCardControl';
import { isEmpty } from 'lodash';

const PricingMiniControls = ({
  bloombergTicker,
  form,
  fields,
  id,
  trailId,
  isFactSheet,
  title,
  productDescription,
  subTitle,
  description
}) => {

  const premiumField = useMemo(() => fields?.filter((field) => field.name === "premiumAmount"), [fields]);

  return (
    <Fragment>
      <div className="card-mini-controls-wp">
        <div className="title-block">
          <div className="h5 sub-title">
            {subTitle}
          </div>
        </div>
        <div className="form-card-mini-content-controls">
          <ProductDescriptionBtn cardId={id} title={subTitle} productDescription={productDescription} />
          <PriciniMiniChartBlock bloombergTicker={bloombergTicker} form={form} fields={fields} cardId={id} />
          {trailId ?
            <FactSheet
              cardId={id}
              trailId={trailId}
              bloombergTicker={bloombergTicker}
              commodityContract={title} 
              premiumField={!isEmpty(premiumField) && premiumField[0]}
            />
            : null}
          <CopyCardControl cardId={id}/>
        </div>
      </div>
      {description ? <div className="font-sm mb-3">{description}</div> : null}
    </Fragment>
)};

export default PricingMiniControls;
