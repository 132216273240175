import { InlineInput } from '@agile/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FIELD_TYPE_DEFAULT, STRUCTURED_PRODUCTS_FORM_FIELDS, STRUCTURED_PRODUCTS_FORM_NOTIONAL } from '../../fields';
import {
  autocallField,
  autocallTriggerLevelField,
  barrierLevelField,
  barrierTypeField,
  couponLevelField,
  couponTriggerLevelField,
  couponTypeField,
  downsideLeverageField,
  firstObservationInField,
  frequencyField,
  maturityField,
  memoryCouponField,
  reofferField,
  snowballField,
  solveForField,
  stepDownField,
  stepField,
  strikeLevelField,
  couponDirectionField,
} from '../autocallable/fields';
import { PRICING_FORM } from 'redux/actions/price';

const noCouponLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "noCouponPaidAtMaturity",
  labelCheckbox: <FormattedMessage id="form.price.fields.no-coupon.label"
    defaultMessage="No Coupon Paid at Maturity" />,
  type: "checkbox",
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: { xs: 6, sm: 4 }
};

const TWIN_WIN_FORM_FIELDS = [
  ...STRUCTURED_PRODUCTS_FORM_FIELDS,
  maturityField,
  autocallField,
  frequencyField,
  firstObservationInField,
  autocallTriggerLevelField,
  stepDownField,
  stepField,
  couponTypeField,
  memoryCouponField,
  couponTriggerLevelField,
  snowballField,
  solveForField,
  couponDirectionField,
  couponLevelField,
  reofferField,
  noCouponLevelField,
  strikeLevelField,
  downsideLeverageField,
  barrierTypeField,
  barrierLevelField,
  ...STRUCTURED_PRODUCTS_FORM_NOTIONAL,
];

export const TWIN_WIN_FORM_FIELDS_KEYS_ARRAY = [];
export const TWIN_WIN_FORM_FIELDS_KEYS = {};
TWIN_WIN_FORM_FIELDS.forEach(({ name, isVisible = true }) => {
  TWIN_WIN_FORM_FIELDS_KEYS[name] = isVisible;
  TWIN_WIN_FORM_FIELDS_KEYS_ARRAY.push(name);
});

export default TWIN_WIN_FORM_FIELDS;
