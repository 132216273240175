export const ISSUER_DEFAULT = 'Marex Financial';
export const PRICING_STRUCTURED_FORM = '@PRICING/STRUCTURED/FORM';
export const PRICING_STRUCTURED_FORM_TAG = `${PRICING_STRUCTURED_FORM}/TAG`;
export const PRICING_STRUCTURED_FORM_SHEDULE = `${PRICING_STRUCTURED_FORM}/SHEDULE`;
export const PRICING_STRUCTURED_FORM_SCHEDULE_EARLY_TERM = `${PRICING_STRUCTURED_FORM}/SCHEDULE_EARLY_TERM`;
export const PRICING_STRUCTURED_FORM_FLOATING_LEG_SHEDULE = `${PRICING_STRUCTURED_FORM}/FLOATING_LEG_SHEDULE`;
export const PRICING_STRUCTURED_FORM_TRADE = `${PRICING_STRUCTURED_FORM}/TRADE`;
export const PRICING_STRUCTURED_FORM_TERMSHEET = `${PRICING_STRUCTURED_FORM}/TERMSHEET`;

export const CLIENT_PAYS_LABEL = 'Client Pays';
export const CLIENT_RECEIVES_LABEL = 'Client Receives';
export const COUPON_DIRECTION_OPTIONS = [
  { value: CLIENT_RECEIVES_LABEL, label: CLIENT_RECEIVES_LABEL },
  { value: CLIENT_PAYS_LABEL, label: CLIENT_PAYS_LABEL },
];
export const DEFAULT_COUPON_DIRECTION = CLIENT_RECEIVES_LABEL;
