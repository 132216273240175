import gql from 'graphql-tag';

export const ACCUMULATOR_MINI_TRADE_MUTATION = gql`mutation accumulatorTradeMiniMutation(
  $id: String!
  $notional: Float!
  $comment: String
  $clientId: String!
  $locationId: String
  $customerId: String
  $msaFixings: Int!
  $alreadyTraded: Boolean
) {
  accumulatorTradeMini (
    id: $id
    notional: $notional
    comment: $comment
    clientId: $clientId
    locationId: $locationId
    customerId: $customerId
    msaFixings: $msaFixings
    alreadyTraded: $alreadyTraded
  )
}`;
