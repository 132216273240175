import gql from 'graphql-tag';

export const CUSTOMER_OPTIONS_QUERY = gql`query getUserCustomersQuery (
  $search: String
  $cursor: String
  $filter: [UserCustomersFilterType]
  $first: Int
) {
  userCustomers (
    search: $search
    after: $cursor
    filter: $filter
    first: $first
  ) {
    edges {
      node {
        id
        code
        title: name
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}`;
