export const PRICING_HISTORY_SELECT_TRAIL = '@PRICING_HISTORY/SELECT_TRAIL';
export const PRICING_HISTORY_SELECT_URL = '@PRICING_HISTORY/SELECT_TRAIL_URL';
export const PRICING_HISTORY_SELECT_TRAIL_BAD = '@PRICING_HISTORY/SELECT_TRAIL_BAD';
export const PRICING_HISTORY_SELECT_TRAIL_LOADED = '@PRICING_HISTORY/SELECT_TRAIL_LOADED';
export const PRICING_HISTORY_SELECT_TRAIL_FROM_LOADED_DATA = '@PRICING_HISTORY/SELECT_TRAIL_FROM_LOADED_DATA';
export const PRICING_HISTORY_PRE_SET_TRAIL = '@PRICING_HISTORY/PRE_SET_TRAIL';
export const PRICING_HISTORY_SET_TRAIL = '@PRICING_HISTORY/SET_TRAIL';
export const PRICING_HISTORY_SELECT_TRAIL_FORM_RESTORE_TRIGGER = `${PRICING_HISTORY_SELECT_TRAIL}/FORM/RESTORE/TRIGGER`;

export function selectTrailAction(trail) {
  return { type: PRICING_HISTORY_SELECT_TRAIL, payload: trail }
}

export function badSelectTrailAction(trail) {
  return { type: PRICING_HISTORY_SELECT_TRAIL_BAD, payload: trail }
}

export function selectTrailUrlAction(trail) {
  return { type: PRICING_HISTORY_SELECT_URL, payload: trail }
}

export function selectedTrailLoadedAction(trail) {
  return { type: PRICING_HISTORY_SELECT_TRAIL_LOADED, trail }
}

export function selectedTrailLoadedDataAction(trail) {
  return { type: PRICING_HISTORY_SELECT_TRAIL_FROM_LOADED_DATA, trail }
}

export function setTrailData(trail) {
  return { type: PRICING_HISTORY_SET_TRAIL, payload: trail }
}

export function preSetTrailData(trail) {
  return { type: PRICING_HISTORY_PRE_SET_TRAIL, payload: trail };
}

export const triggerRestoreByTagAction = payload => ({
  type: PRICING_HISTORY_SELECT_TRAIL_FORM_RESTORE_TRIGGER,
  payload
});
