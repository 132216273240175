import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { reset, change } from "redux-form";
import { TOURS_RUN, TOURS_WELCOME } from "../../actions/tours";
import { PRICING_FORM } from "../../actions/price";
import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import { switchMap, mapTo } from 'rxjs/operators';
import { scrollToStartAction } from 'redux/actions/common';

export const welcomePriceTourEpic = action$ =>
  action$
    .pipe(
      ofType(TOURS_WELCOME),
      mapTo(change(PRICING_FORM, 'structure', STRUCTURE_ACCUMULATOR))
    );

export const startPriceTourEpic = action$ =>
  action$.pipe(
    ofType(TOURS_RUN),
    switchMap(() => {
      return from([
        scrollToStartAction(),
        reset(PRICING_FORM)
      ])
    })
  );

export default combineEpics(
  startPriceTourEpic,
  welcomePriceTourEpic
);
