import { useSelector } from "react-redux";
import { spFormValueSelector } from "../../../../../components/configurableSp/selectors/selectors";
import { useMemo } from "react";
import { Field } from "redux-form";
import { InlineSelectInput } from "@agile/react-components";
import { availableFirstObservationIn } from "../../../../../redux/epics/structured-products/form/options/firstObservationIn";

const FirstObservationIn = ({
  formName,
  changeField,
  optionsMap,
  change,
  ...rest
}) => {
  const formValues: any = useSelector((state) => {
    return spFormValueSelector(formName)(state);
  });

  const options = useMemo(
    () => availableFirstObservationIn(formValues),
    [formValues?.maturity, formValues?.frequency],
  );

  return (
    <Field
      name="firstObservationIn"
      component={InlineSelectInput}
      change={changeField}
      options={options}
      clearable={false}
      {...rest}
    />
  );
};

export default FirstObservationIn;
