import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { STRUCTURE_SWAP, STRUCTURE_VANILLA, STRUCTURE_FX, DATE_FORMAT_LABEL, DATE_FORMAT_LABEL_SHORT } from 'constants.js';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import { TYPE_MARKET, TYPE_STOP, TYPE_ICEBERG, MKT, TYPE_STOP_LIMIT, TYPE_OCO } from 'components/trade/constants.js';
import { SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';

const LegLabel = ({
  quantity,
  formattedUnit,
  commodityContract,
  contractExpiry,
  contractExpirySecond,
  orderType,
  stopPrice,
  price,
  maxShow
}) => (
  <li>
    <FormattedNumber
      value={quantity}
      minimumFractionDigits={0}
      maximumFractionDigits={4} />
    &nbsp;{formattedUnit}
    &nbsp;{commodityContract}
    &nbsp;{contractExpiry ? formatDate2UI(contractExpiry) : 'Spot'}{contractExpirySecond ? `-${formatDate2UI(contractExpirySecond)}` : null}
    &nbsp;@&nbsp;
    {orderType === TYPE_STOP_LIMIT ? (<span>STP {<FormattedNumber
      value={stopPrice}
      minimumFractionDigits={0}
      maximumFractionDigits={4} />} / LMT {<FormattedNumber
        value={price}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />}</span>) : null
    }
    {orderType === TYPE_STOP ? 'STP ' : null}
    {orderType === TYPE_MARKET ? MKT : orderType === TYPE_STOP_LIMIT ? null : <FormattedNumber
      value={orderType === TYPE_STOP ? stopPrice : price}
      minimumFractionDigits={0}
      maximumFractionDigits={4} />}
    {orderType === TYPE_STOP ? ` / ${MKT}` : null}
    &nbsp;{orderType === TYPE_ICEBERG ? `Iceberg (${maxShow || ''})` : null}
  </li>
);

const AsianSwapLegLabel = ({
  formattedUnit,
  commodityContract,
  underlyingContracts,
  contractExpiry,
  notional,
}) => {
  /* this check is here to prevent the leg from displaying in the order summary modal if notional is 0 */
  return notional ? (
    <li>
      <FormattedNumber
        value={notional}
        minimumFractionDigits={0}
        maximumFractionDigits={4} />
      &nbsp;{formattedUnit}&nbsp;{commodityContract}&nbsp;
      {underlyingContracts} average&nbsp;
      {moment(contractExpiry).format('MMM-YY')}
      &nbsp;@&nbsp;
      {MKT}
    </li>
  ) : null
};

function TradeLabel(props) {
  const {
    direction,
    contractExpiry,
    contractExpirySecond,
    commodityContract,
    structureType,
    strikeLevel,
    price,
    quantity,
    structure,
    stopPrice,
    maxShow,
    orderType,
    unit,
    formData,
    quotedCurrency,
    legs,
    isSpot
  } = props;

  const formattedUnit = unit?.toUpperCase() || 'LOT';
  switch (structure) {
    case SWAP_ASIAN:
      return (
        <Fragment>
          <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
          <ul>
            {legs.map((data, index) => (<AsianSwapLegLabel key={index} formattedUnit={formattedUnit} {...props} {...data} />))}
          </ul>
        </Fragment>
      );
    case STRUCTURE_VANILLA.toLowerCase():
      return (
        <Fragment>
          <span className="text-bolder text-uppercase">Client {direction === 'buyer' ? 'Buys' : 'Sells'}</span>&nbsp;
          <FormattedNumber
            value={quantity}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />
          &nbsp;LOT {commodityContract}&nbsp;{formatDate2UI(contractExpiry)}&nbsp;<FormattedNumber
            value={strikeLevel}
            minimumFractionDigits={0}
            maximumFractionDigits={2} />&nbsp;<span className="text-capitalize">{structureType}</span>&nbsp;@&nbsp;{orderType === TYPE_STOP ? 'Market Stop ' : null}
          {orderType === TYPE_STOP_LIMIT ? (<span>STP {<FormattedNumber
            value={stopPrice}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />} / LMT {<FormattedNumber
              value={price}
              minimumFractionDigits={0}
              maximumFractionDigits={4} />}</span>) : null
          }
          {orderType === TYPE_MARKET ? MKT : orderType === TYPE_STOP_LIMIT ? null : <FormattedNumber
            value={stopPrice || price}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />}&nbsp;{orderType === TYPE_ICEBERG ? `Iceberg (${maxShow})` : null}
        </Fragment>
      )
    case STRUCTURE_FX.toLowerCase():
      return (
        <Fragment>
          <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
          <FormattedNumber
            value={quantity}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />&nbsp;
          ({quotedCurrency})&nbsp;
          {commodityContract}&nbsp;
          {formatDate2UI(contractExpiry, DATE_FORMAT_LABEL)}&nbsp;
        </Fragment>
      )
    default:
      if (orderType === TYPE_OCO && formData?.legs) {
        return (
          <Fragment>
            <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
            <ul>
              {formData?.legs.map((data, index) => (<LegLabel key={index} formattedUnit={formattedUnit} {...props} {...data} />))}
            </ul>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <span className="text-bolder text-uppercase">Client {(direction === 'buyer' || direction === 'buy') ? 'Buys' : 'Sells'}</span>&nbsp;
          <FormattedNumber
            value={quantity}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />
          &nbsp;{formattedUnit}&nbsp;{commodityContract}&nbsp;{contractExpiry ? formatDate2UI(contractExpiry, isSpot ? DATE_FORMAT_LABEL : DATE_FORMAT_LABEL_SHORT) : 'Spot'}{contractExpirySecond ? `-${formatDate2UI(contractExpirySecond)}` : null}&nbsp;@&nbsp;{orderType === TYPE_STOP ? 'Market Stop ' : null}
          {orderType === TYPE_STOP_LIMIT ? (<span>STP {<FormattedNumber
            value={stopPrice}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />} / LMT {<FormattedNumber
              value={price}
              minimumFractionDigits={0}
              maximumFractionDigits={4} />}</span>) : null
          }
          {orderType === TYPE_MARKET ? MKT : orderType === TYPE_STOP_LIMIT ? null : <FormattedNumber
            value={stopPrice || price}
            minimumFractionDigits={0}
            maximumFractionDigits={4} />}&nbsp;{orderType === TYPE_ICEBERG ? `Iceberg (${maxShow || ''})` : null}
        </Fragment>
      )
  }
}

TradeLabel.defaultProps = {
  structure: STRUCTURE_SWAP.toLowerCase(),
};

TradeLabel.propTypes = {
  structure: PropTypes.string.isRequired,
};

export default TradeLabel;
