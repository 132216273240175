import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const DashboardPages = props => (
  <ErrorBoundary>
    <PageMeta title="Dashboards" />
    <Bundle title="Dashboards Page" load={() => import('layouts/DashboardsLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default DashboardPages;
