import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const InputClearWrapper = props => {
  const { children, name } = props;
  const { onChange } = children.props;
  return (
    name ?
      <Fragment>
        {children}
        <span className="btn-clear" onClick={e => onChange(null)}>×</span>
      </Fragment>
      :
      null
  );
};

InputClearWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default InputClearWrapper;
