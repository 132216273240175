export const DATE_FORMAT_INPUT = "DD/MM/YYYY";
export const DATE_FORMAT_FACTSHEET = "Do MMMM YYYY";
export const DATE_FORMAT_PRICING_ACCUMULATOR_MINI = "dd MMM yy";
export const DATE_FORMAT_PRICING_ACCUMULATOR_CHART = "MMM-YY";
export const DEFAULT_APP_TITLE = "AGILE";
export const DEBOUNCE_TIME = 350;
export const DEBOUNCE_TIME_TABS = 200;
export const IDLE_VIDEO_TIME = 5000;
export const FORM_SUBMIT_SUCCESS_TIMEOUT = 4000;
export const PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT = 2000;
export const FORM_EDIT_SUCCESS_TIMEOUT = 2000;
export const DAILY_ORDER_ERROR_TIMEOUT = 15000;
export const TOOLTIP_TIMEOUT = 3000;
export const IDLE_TIMEOUT = 3000;
export const TRADE_BUTTON_TIMEOUT_SECONDS = 60;
export const UNWIND_CANCELATION_SECONDS = 300; // 5 min
export const TRADE_BUTTON_TIMEOUT = 1000 * TRADE_BUTTON_TIMEOUT_SECONDS;
export const ANIMATION_TIME = 300;
export const MODAL_ANIMATION_TIME = 200;
export const DATE_FORMAT_LABEL_SHORT = "MMMM YYYY";
export const DATE_FORMAT_LABEL_MONTH_SHORT = "MMM YY";
export const DATE_FORMAT_LABEL_MONTH_SHORT_YEAR_FULL = "MMM YYY";
export const DATE_FORMAT_LABEL_MONTH_SHORT_YEAR_SHORT = "MMM YY";
export const DATE_FORMAT_LABEL = "DD MMMM YYYY";
export const DATE_FORMAT_LABEL_DAY_MONTH_SHORT = "DD MMM YYYY";
export const PER_PAGE_DEFAULT = 20;
export const DATE_FORMAT_CASH_MOVEMENT = "MMMM yyyy";
export const DATE_FORMAT_INPUT_DATEPICKER = "dd/MM/yyyy";
export const DATE_FORMAT_UTC = "YYYY-MM-DD[T]HH:mm:ss";

// Structures
// @TODO: sync with BE and use lowercase for all names
export const STRUCTURE_VANILLA = "Vanilla";
export const STRUCTURE_ACCUMULATOR = "Accumulator";
export const STRUCTURE_ACCUMULATOR_MINI = "Accumulator-mini";
export const STRUCTURE_SWAP = "Swap";
export const STRUCTURE_SWAP_BULLET_STRIP = "strip bullet swap";
export const STRUCTURE_SWAP_BULLET_STRIP_VIEW = "bullet_strip";
export const STRUCTURE_ASIAN_SWAP = "Asian Swap";
export const STRUCTURE_CALENDAR_SPREAD = "calendar spread";
export const STRUCTURE_AUTOCALLABLE = "autocall";
export const STRUCTURE_CAPITAL_PROTECTION = "capital_protection";
export const STRUCTURE_TWIN_WIN = "twin_win_autocall";
export const STRUCTURE_FIXED_COUPON_NOTE = "fixed_coupon_note";
export const STRUCTURE_MINIFUTURE = "Minifuture";
export const STRUCTURE_FX = "Forwards / NDFs";

export const RECORD_EMPTY = "-";

// Validations
export const INPUT_NUMBER_MAX_VALUE = Math.pow(10, 6);
export const INPUT_NUMBER_MAX_VALUE_LARGE = Math.pow(10, 15);
export const APP_VERSION = "2.8.0";

// Fx
export const CARD_STATUSES = {
  NEW: "new",
  REQUESTED: "requested",
  APPROVED: "request approved",
  REJECTED: "rejected",
  DEACTIVATED: "deactivated",
};
export const TRADE_DATE_FORMAT = "YYYYMMDD";
export const REDIRECT_CODE = "ACCOUNT.PASS.OLDER_THAN_90_DAYS";

export const AUTOCALL_VALUE = {
  ISSUER_CALLABLE: "issuer callable",
  YES: "yes",
  NO: "no",
};

export const NOT_CALLABLE = "Not Callable";
export const CALLABLE = "Callable";

export const DEFAULT_UNIT = "Lot";
export const DEFAULT_UNIT_TYPE = "default";

export const BUFFER_TIMEOUT = 400; //ms

export const DEFAULT_LIMIT_FOR_LOAD_CARDS = 8;

export const ERROR_FETCH_CONTRACT_EXPIRY =
  "No contracts are available for this underlying, please contact the trading team.";

export const ERROR_FETCH_CONTRACT_EXPIRY_2 =
  "There is no Contract Expiry 2 for the selected Contract Expiry, please contact the trading team.";

export const ERROR_FETCH_PRODUCT_INFO =
  "No product info are available for this underlying, please contact the trading team.";

export const DEFAULT_FPT_PROGRAMME_ID = 6;

export const REFETCH_PRODUCT_TIMEOUT = 900000; //ms - 15min
