import { ofType } from 'redux-observable';
import { miniAccumulatorCardsUpdate, PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY } from 'redux/actions/accumulator-mini';
import { filter, mapTo } from 'rxjs/operators';
import { emptyPricingFormFilter } from './utils';

export const formCardsDestroyEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY),
    filter(() => emptyPricingFormFilter(state$)),
    mapTo(miniAccumulatorCardsUpdate([])),
  );

export default formCardsDestroyEpic;
