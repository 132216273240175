import FxFormValidate from './FxFormValidate';
import { PRICING_FORM_FX_INITIAL_VALUES } from './constants';
import FxForm from './FxForm';
import { fxLimitationSelector } from './fxLimitationSelector';

const fxFormProps = state => {
  const props = fxLimitationSelector(state);
  return {
    ...props,
    component: FxForm,
    initialValues: PRICING_FORM_FX_INITIAL_VALUES,
    validate: FxFormValidate
  }
}

export default fxFormProps;
