import { INPUT_NUMBER_MAX_VALUE_LARGE } from 'constants.js';
import validateJs from 'utils/validate.js';
import validateMessages from 'utils/validate.messages';

export default function (data = {}) {
  const validations = {
    clientId: {
      presence: {
        message: `^Account ${validateMessages.blank}`
      },
    },
    notional: {
      presence: {
        message: `^Notional ${validateMessages.blank}`
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE_LARGE,
      }
    },
  };

  return validateJs(data, validations) || {};
};
