import React, { PureComponent, Fragment } from 'react';
import Idle from 'react-idled';
import PropTypes from 'prop-types';
import { IDLE_VIDEO_TIME } from 'constants.js';

class IdleContent extends PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      in: true,
      show: true
    }
  }

  static defaultProps = {
    timeout: IDLE_VIDEO_TIME
  }

  onChangeIdle = (idle) => {
    this.setState({ in: !idle });
    this.props.onChange(idle);
  }

  render() {
    const { children, timeout } = this.props;
    const { show } = this.state;
    return (
      show ?
        <Fragment>
          <Idle
            timeout={timeout}
            onChange={({ idle }) => { this.onChangeIdle(idle) }}>
          </Idle>
          <div className={'fade-blocks ' + (this.state.in ? 'in' : '')}>
            {children}
          </div>
        </Fragment>
        :
        null
    )
  }
}

IdleContent.propTypes = {
  timeout: PropTypes.number,
  onChange: PropTypes.func,
};

export default IdleContent;
