import ChangeOrderBlock from 'components/tables/blocks/ChangeOrderBlock';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { TRADES_SORTING } from './sorter';

const SELECT_COLUMN = TRADES_SORTING[0].key
class OpenTradesTableHeader extends PureComponent {

  orderByField = value => {
    if(value === SELECT_COLUMN) return;

    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }

  render() {
    const { selected: { order, by }, disabled, defaultSorters = TRADES_SORTING, displayColumns, style, isSlackId } = this.props;
    const sorters = defaultSorters
      .filter(({ key: fieldName, displayAllways }) => {
        if(fieldName === SELECT_COLUMN) {
          return isSlackId
        }
        return !!displayAllways || (displayColumns && displayColumns[fieldName]);
      });

    return (
      <div
        style={style}
        className={"d-none d-sm-block app-table-header" + (order ? ` order-${order}` : '') + (disabled ? ' disabled' : '')}>
        {sorters
          .map(item =>
            <ChangeOrderBlock
              key={item.key}
              value={item.key}
              label={<span>{item.label}</span>}
              orderBy={by}
              onChange={this.orderByField}
              disabled={item.disabled} />
          )}
      </div>
    )
  }
};

OpenTradesTableHeader.defaultProps = {
  disabled: false,
  selected: {
    order: 'asc',
    by: 'underlyingName'
  },
};

OpenTradesTableHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default OpenTradesTableHeader;
