import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from '../loading/LoadingFullPage';

export const OldBrowser = props => (
  <Bundle title={'Old Browser Page'} load={() => import('./OldBrowser')} {...props}>
    {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
  </Bundle>
);

export default OldBrowser;
