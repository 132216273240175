import Loader from 'components/common/loader/Loader';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import Modal from 'components/modal/Modal';
import { mapStateToPropsTradePrcingMini } from 'pages/price/mini/components/trade/TradeForm';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DASHBOARD_TRADE_QUERY } from '../query';
import OpenTradesTableItemEditForm from './OpenTradesTableItemEditForm';

const OpenTradesTableItemEdit = props => {
  const { trailId, onClose, isClient, data: { otcStatement, loading } = {}, dashboardId, onSuccessUpdate, isMyDashboard } = props;

  const mapInitialValues = (data = {}) => {
    const {
      physicalId,
      customer,
      location,
      trader,
    } = data || {};
    const customerId = customer?.id;
    const locationId = location?.id;
    const traderId = trader?.id;
    return {
      physicalId,
      customerId,
      locationId,
      traderId,
    }
  }

  const initialValues = otcStatement ? mapInitialValues(otcStatement) : {};

  return (
    isClient ?
      <Modal
        isOpen={Boolean(trailId)}
        onClose={onClose}
        onToggle={onClose}
        component={
          loading ?
            <Loader /> :
            <OpenTradesTableItemEditForm
              trailId={trailId}
              isClient={isClient}
              clientId={otcStatement?.clientId}
              dashboardId={dashboardId}
              isMyDashboard={isMyDashboard}
              onClose={onClose}
              onSuccessUpdate={onSuccessUpdate}
              initialValues={initialValues} />
        } />
      : null
  );
};

const DASHBOARD_TRADE_QUERY_OPTIONS = {
  options: props => {
    const { trailId: [accumulatorMiniHistoryId, otcStatementId, commonId], dashboardId, isMyDashboard } = props;
    return {
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        accumulatorMiniHistoryId,
        otcStatementId,
        dashboardId,
        isMyDashboard,
        commonId
      }
    }
  }
};

OpenTradesTableItemEdit.propTypes = {
  trailId: PropTypes.string.isRequired,
  dashboardId: PropTypes.number.isRequired,
  onSuccessUpdate: PropTypes.func,
  isClient: PropTypes.bool.isRequired,
};

export default compose(
  graphql(DASHBOARD_TRADE_QUERY, DASHBOARD_TRADE_QUERY_OPTIONS),
  connect(mapStateToPropsTradePrcingMini)
)(OpenTradesTableItemEdit);
