
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Underlyings from './underlyings/Underlyings';
import { PRICING_FORM } from 'redux/actions/price';

import { InlineInput, InlineInputWithMask, InlineSelectInput } from '@agile/react-components';

export const FIELD_TYPE_COMPONENT = 'field-component';
export const TYPE_COMPONENT = 'component';
export const FIELD_TYPE_DEFAULT = 'field';

export const STRUCTURED_PRODUCTS_FORM_FIELDS = [
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "structure",
    label: <FormattedMessage id="form.price.fields.structure.label"
      defaultMessage="Structure" />,
    action: PRICING_FORM,
    component: InlineSelectInput,
    justValue: true,
    clearable: false,
    optionsName: 'structures',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "issuer",
    label: <FormattedMessage id="form.price.fields.issuer.label"
      defaultMessage="Issuer" />,
    action: PRICING_FORM,
    component: InlineSelectInput,
    justValue: true,
    clearable: false,
    optionsName: 'issuerOptions',
    colOptions: { xs: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "currency",
    label: <FormattedMessage id="form.price.fields.currency.label"
      defaultMessage="Currency" />,
    justValue: true,
    placeHolder: 'Enter Currency',
    action: PRICING_FORM,
    component: InlineSelectInput,
    optionsName: 'currencyOptions',
    colOptions: { xs: 12, sm: 6 },
    showError: true
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "legalShape",
    label: <FormattedMessage id="form.price.fields.legalShape.label"
      defaultMessage="Format" />,
    action: PRICING_FORM,
    component: InlineSelectInput,
    justValue: true,
    clearable: false,
    optionsName: 'legalShapeOptions',
    colOptions: { xs: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "floatingCurve",
    label: <FormattedMessage id="form.price.fields.floatingCurve.label"
      defaultMessage="Floating Curve" />,
    action: PRICING_FORM,
    component: InlineSelectInput,
    justValue: true,
    clearable: false,
    setDefaultFirstOption: true,
    optionsName: 'floatingCurveOptions',
    colOptions: { xs: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "spread",
    label: <FormattedMessage id="form.price.fields.spread.label"
      defaultMessage="Spread" />,
    placeHolder: 'Enter Spread',
    type: "number",
    step: 1,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 },
    action: PRICING_FORM,
  },
  {
    fieldType: TYPE_COMPONENT,
    name: 'underlyings',
    component: Underlyings,
    colOptions: { xs: 12 },
    total: 1,
  },
];

export const STRUCTURED_PRODUCTS_FORM_NOTIONAL = [
  {
    fieldType: FIELD_TYPE_DEFAULT,
    name: "notional",
    label: <FormattedMessage id="form.price.fields.notional.label"
      defaultMessage="Notional" />,
    placeHolder: 'Enter Notional',
    type: "formatted-number",
    component: InlineInputWithMask,
    colOptions: { xs: 12, sm: 8 }
  }
];
