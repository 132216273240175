import React from 'react';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { NOTIFICATION_TYPE_NOTIFY_ME, NOTIFICATION_TYPE_ORDER } from './constansts';

const NotifyIcon = ({ type }) => {
  switch (type) {
    case NOTIFICATION_TYPE_ORDER:
      return <ListAltIcon />
    case NOTIFICATION_TYPE_NOTIFY_ME:
      return <ShowChartIcon />;
    default:
      return <MailOutlineIcon />;
  }
};

export default NotifyIcon;
