import { combineEpics, ofType } from "redux-observable";
import {
  minifutureCardDelete,
  minifutureCardUpdate,
  MINIFUTURE_CARD_START_DELETE,
} from "redux/actions/minifuture";
import { notificationErrorSimple } from "redux/alerts/actions";
import {
  cardError,
} from "redux/epics/price/structures/swap/";
import {
  getCardById,
} from "redux/epics/price/structures/swap/utils";
import { getSingleGraphQlError } from "redux/epics/utils";
import {
  deleteCardMutation,
} from "redux/queries/minifuture";
import { from, of } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
} from "rxjs/operators";

export const cardDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CARD_START_DELETE),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap((id) => {
      const card = getCardById(state$.value.minifuture.trailPrice.cards, id);
      return from(deleteCardMutation(id)).pipe(
        filter((payload) => !!payload),
        switchMap(() => of(minifutureCardDelete(id))),
        catchError((error) => {
          const actions = [
            cardError(card),
            minifutureCardUpdate({
              ...card,
              loading: false,
              isLoading: false,
            }),
          ];
          const err = getSingleGraphQlError(error);
          if (err?.message && err.code === 403) {
            return from([...actions, notificationErrorSimple(err.message)]);
          }

          return from(actions);
        })
      );
    })
  );

export const cardLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CARD_START_DELETE),
    map(({ payload }) => payload),
    switchMap((id) => {
      const card = getCardById(state$.value.minifuture.trailPrice.cards, id);
      const actions = [
        minifutureCardUpdate({
          ...card,
          loading: true,
        }),
      ];
      return from(actions);
    })
  );

export default combineEpics(cardLoaderEpic, cardDeleteEpic);
