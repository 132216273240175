import { PRICING_MINI_CARD_TYPE_DEFAULT, PRICING_MINI_CARD_TYPE_FLEX } from 'pages/price/mini/components/pricinigMiniForm/constants.js';
import { PRICINIG_MINI_FORM } from 'pages/price/mini/components/pricinigMiniForm/PricinigMiniForm';
import { pricingMiniFormValuesSelector } from 'pages/price/mini/components/pricinigMiniForm/utils';
import { toDay } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import { change, untouch } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { miniAccumulatorCardGetReferencePrice, miniAccumulatorCardUpdate } from 'redux/actions/accumulator-mini';
import { LIMITATION_RELATION_ACCUMULATOR_MINI } from 'redux/prices/config-accumulator-mini';
import { loadAllVariable } from 'redux/queries/price';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { changeDynamicFormNameActionFilter, changeFormActionDynamicFormFilter } from 'utils/reduxFormSelector';
import { getCardId } from '../structures/vanilla/utils';
import formCardsInitEpic from './init';
import { emptyPricingFormFilter, pricinigMiniFormFilter } from './utils';
import moment from 'moment';

export const defaultCardProps = {
  trailId: null,
  tradeId: null,
  isTrade: false,
  errorText: null,
};

export const findPricinigMiniCard = (cards = [], cardId) => {
  if (!cards?.length) return;
  return cards.find(({ id }) => id === cardId);
}

export const formLimitationChangeEpic = (action$, state$) =>
  action$.pipe(
    filter(changeFormActionDynamicFormFilter(PRICINIG_MINI_FORM)),
    filter(() => emptyPricingFormFilter(state$)),
    filter(pricinigMiniFormFilter),
    filter(({ meta: { field } }) =>
      LIMITATION_RELATION_ACCUMULATOR_MINI
        .filter(({ onlyFill }) => !onlyFill)
        .map(({ key }) => key).includes(field)),
    map(({ meta }) => {
      const formData = pricingMiniFormValuesSelector(meta.form)(state$.value);
      const cardId = getCardId(meta);
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      const {
        underlying: {
          data: underlyings = []
        } = {}
      } = card;
      const filterData = {
        ...formData,
        commodity: card.commodity
      };
      const limitation = loadAllVariable(filterData, underlyings);
      return miniAccumulatorCardUpdate({
        ...card,
        limitation,
        ...defaultCardProps,
      });
    }),
  );

export const formPricingMiniErrorMessageEpic = (action$, state$) =>
  action$.pipe(
    filter(changeFormActionDynamicFormFilter(PRICINIG_MINI_FORM)),
    filter(() => emptyPricingFormFilter(state$)),
    filter(pricinigMiniFormFilter),
    map(({ meta }) => {
      const cardId = getCardId(meta);
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      return miniAccumulatorCardUpdate({
        ...card,
        isLoading: false,
        errorText: null,
      });
    }),
  );

const selectUnderlyingEpic = (action$, state$) =>
  action$.pipe(
    filter(changeDynamicFormNameActionFilter(PRICINIG_MINI_FORM, 'contractExpiry')),
    filter(pricinigMiniFormFilter),
    filter(() => emptyPricingFormFilter(state$)),
    switchMap(({ payload: selectedContractExpiry, meta }) => {
      const cardId = getCardId(meta);
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      const {
        limitation,
        underlying: {
          data: underlyings = []
        } = {},
        flexible,
        type
      } = card;
      const selectedUnderlying = selectedContractExpiry ? underlyings.find(
        ({ contractExpiry }) => contractExpiry === selectedContractExpiry
      ) : null;
      const newCard = {
        ...card,
        selectedUnderlying,
        trailId: null,
        tradeId: null,
        isTrade: false,
        errorText: null,
        isUpdateFormFieldsValuesInLive: true,
      };

      let additionalActions = [];
      const isStructureExpiredAt = limitation?.structureExpiredAt?.length;
      const structureExpiryDateMax = isStructureExpiredAt ? limitation['structureExpiredAt'][0] : null;
      if (isStructureExpiredAt && type === PRICING_MINI_CARD_TYPE_DEFAULT) {
        additionalActions = [
          ...additionalActions,
          change(meta.form, 'structureExpiryDate', structureExpiryDateMax),
        ];
      }

      if (type === PRICING_MINI_CARD_TYPE_FLEX) {
        const startDate = !flexible && selectedUnderlying?.startDate ? selectedUnderlying.startDate : toDay();
        const endDate = !selectedUnderlying?.endDate || moment(selectedUnderlying.endDate).isAfter(structureExpiryDateMax) ?
          structureExpiryDateMax : selectedUnderlying.endDate;

        additionalActions = [
          ...additionalActions,
          change(meta.form, 'startDate', startDate),
          change(meta.form, 'endDate', endDate),
        ];

      }
      const actions = [
        miniAccumulatorCardUpdate(newCard),
        ...(selectedContractExpiry ?
          [
            miniAccumulatorCardGetReferencePrice({ id: cardId, updateFormFieldsValues: true, changeReferenceType: true })
          ] :
          [
            change(meta.form, 'reference', null),
            untouch(meta.form, 'reference')
          ]),
        ...additionalActions,
      ];
      return from(actions);
    }),
  );

export default combineEpics(
  formCardsInitEpic,
  formLimitationChangeEpic,
  formPricingMiniErrorMessageEpic,
  selectUnderlyingEpic,
);
