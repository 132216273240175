import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { getFormValues, formValueSelector  } from 'redux-form';
import validateJs from 'utils/validate.js';
import { numericalityValidation } from './validate';

export const pricingMiniFormValuesSelector = name => state => getFormValues(name)(state);
export const pricingMiniFormValueSelector = name => (state, fieldName) => formValueSelector(name)(state, fieldName);

export const validateInputNumber = (name, value, min = 0, max = INPUT_NUMBER_MAX_VALUE) => {
  const validations = {
    [name]: {
      numericality: numericalityValidation(min, max)
    }
  };
  const data = {
    [name]: value
  };
  const errors = validateJs(data, validations);
  return errors;
}
