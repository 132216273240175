import TagForm from 'components/form/tag/TagForm';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { triggerRestoreByTagAction } from 'redux/prices/history/pricingHistoryActions';

export const PRICING_TAG_FORM = '@PRICINIG/FORM_TAG';

class PricingTagForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  submit = async (tag) => {
    const { triggerRestoreByTag } = this.props;
    triggerRestoreByTag(tag);
  };

  render() {
    const { intl: { formatMessage } } = this.props;
    return (
      <TagForm
        fielLabel={formatMessage({
          id: 'form.pricing-request.pricing-tag',
          defaultMessage: 'Pricing Tag'
        })}
        submitFormHandler={this.submit}
        title={
          formatMessage({
            id: 'price.page.headers.load-pricing',
            defaultMessage: 'Load Pricing'
          })}
        form={PRICING_TAG_FORM} />
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  triggerRestoreByTag: triggerRestoreByTagAction,
}, dispatch);

PricingTagForm.propTypes = {
  badSelectTrail: PropTypes.func.isRequired,
  selectedTrailLoadedData: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
}

export { PricingTagForm as Component };
export default compose(
  connect(null, mapDispatchToProps),
  withApollo,
  injectIntl,
)(PricingTagForm);
