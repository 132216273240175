import { withBreakPointsContext } from 'components/theme/breakPoints/BreakPoints';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import OpenTradesTableItem from './OpenTradesTableItem';

class TradesTableList extends Component {
  render() {
    const { list = [], responsive: { screenSize }, displayColumns, onSelect, onClose, onChangeTrade, conditionsOfChoiceTrades, selectedIds, isSlackId } = this.props;

    return (
      list?.length ?
        list.map((node, index) => {
          return (
            <OpenTradesTableItem
              {...node}
              key={index}
              index={index}
              displayColumns={displayColumns}
              onSelect={onSelect}
              onClose={onClose}
              onChangeTrade={onChangeTrade}
              conditionsOfChoiceTrades={conditionsOfChoiceTrades}
              selectedIds={selectedIds}
              isSlackId={isSlackId}
              screenSize={screenSize} />
          )
        })
        :
        <div className="text-center">
          <FormattedMessage id="pricing-requests/common.empty-list" defaultMessage="Empty list" />
        </div>
    )
  }
};

TradesTableList.propTypes = {
  list: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withBreakPointsContext(TradesTableList);
