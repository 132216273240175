import React from 'react';
import Bundle from 'pages/Bundle';
import Loader from 'components/common/loader/Loader';

export const VideoBundle = props => (
  <Bundle load={() => import('./VideoBackground')}>
    {Component => (Component ? <Component {...props} /> : <Loader />)}
  </Bundle>
);

export default VideoBundle;
