import { themeMetaDataSelector } from 'components/theme/utils';
import { DEFAULT_APP_TITLE } from 'constants.js';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

const PageMeta = props => {
  const { title, description, favicon, titleSuffix } = props;
  const displayTitle = title ? `${title} | ${titleSuffix}` : titleSuffix;
  return (
    <Helmet
      title={displayTitle} >
      {description && <meta name="description" content={description} />}
      {favicon && <link rel="icon" type="image/png" href={favicon} sizes="16x16" />}
    </Helmet>
  )
};

PageMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  favicon: PropTypes.string,
  titleSuffix: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { defaultTitle, favicon, description } = themeMetaDataSelector(state) || {};
  return {
    titleSuffix: defaultTitle || DEFAULT_APP_TITLE,
    favicon,
    description: ownProps.description || description,
  };
};

export default connect(mapStateToProps)(PageMeta);
