import { DATE_FORMAT_PRICING_ACCUMULATOR_CHART } from 'constants.js';
import isNumber from 'lodash/isNumber';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

export const filterDynamicPrices = (prices, lines = []) => {
  const chartData = prices.map(item => {
    let matrixItem = {
      ...item,
      dateLabel: item.date ? moment(item.date).format(DATE_FORMAT_PRICING_ACCUMULATOR_CHART) : null
    }

    lines.forEach(line => {
      if (isNumber(line.value)) {
        matrixItem[line.name] = line.value;
      }
    })

    return matrixItem;
  });

  return orderBy(chartData, 'date', 'asc');
};
