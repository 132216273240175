import { ORDERS_SET_INITIAL_STATE, ORDERS_SET_CURRENT, ORDERS_REFETCH, ORDERS_NEW, ORDERS_RESET_CURRENT, ORDERS_UPDATE_OPTIONS, ORDERS_LOADING_OPTIONS } from 'redux/actions/orders';

const DEFAUL_OPTIONS = {
  loading: true,
  default: null,
  options: []
};

export const OPTIONS = {
  DISTRIBUTOR: 'distributor',
  ISIN: 'isin',
}

export const ORDERS_INIT_STATE = {
  current: null,
  update: null,
  newOrder: null,
  mfOptions: {
    [OPTIONS.DISTRIBUTOR]: {
      ...DEFAUL_OPTIONS
    },
    [OPTIONS.ISIN]: {
      ...DEFAUL_OPTIONS
    }
  }
}

export default function priceReducer(state = ORDERS_INIT_STATE, action) {
  try {
    switch (action.type) {
      case ORDERS_SET_INITIAL_STATE:
        return { ...ORDERS_INIT_STATE }
      case ORDERS_SET_CURRENT:
        return { ...state, current: action.payload }
      case ORDERS_UPDATE_OPTIONS:
        const { field, options} = action.payload;
        return {
          ...state,
          mfOptions: {
            ...state.mfOptions,
            [field]: {
              loading: false,
              options: [ ...options ],
            }
          }
        }
      case ORDERS_LOADING_OPTIONS:
        const { field: fieldName } = action.payload;
        return {
          ...state,
          mfOptions: {
            ...state.mfOptions,
            [fieldName]: {
              loading: true,
              options: [],
            }
          }
        }
      case ORDERS_RESET_CURRENT:
        return { ...state, current: null, mfOptions: null }
      case ORDERS_REFETCH:
        return { ...state, update: action.payload }
      case ORDERS_NEW:
        return { ...state, newOrder: action.payload }
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
