import { themeImagesSelector } from 'components/theme/utils';
import { INPUT_NUMBER_MAX_VALUE, TRADE_BUTTON_TIMEOUT_SECONDS } from 'constants.js';
import { normalizeId } from 'pages/orders/utils';
import { PRICING_MINI_CARD_TYPE_DEFAULT } from 'pages/price/mini/components/pricinigMiniForm/constants';
import { sortDates } from 'pages/price/PriceForm/utils';
import { change } from 'redux-form';
import { ofType, combineEpics } from 'redux-observable';
import { miniAccumulatorCardsUpdate, PRICING_MINI_ACCUMULATOR_CARDS_LOADED, setMiniAccumulatorCardsLoader, PRICING_MINI_ACCUMULATOR_CARD_COPY } from 'redux/actions/accumulator-mini';
import { loadTradeConfig } from 'redux/epics/config/trade/queries';
import { loadAllVariable } from 'redux/queries/price';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, delay } from 'rxjs/operators';
import { emptyPricingFormFilter, extractRangesFormula, getPricingMiniFormName } from './utils';
import { findPricinigMiniCard } from 'redux/epics/price/mini/limitations';

const UNLOCK_FIELDS = ['reference'];

export const mapFields = (fields = []) => {
  const solvableIndex = fields.findIndex(({ isSolvable }) => isSolvable) || 0;

  const ranges = {
    min: 0,
    max: INPUT_NUMBER_MAX_VALUE,
  };

  return Array.isArray(fields) ?
    fields
      .map((field, index) => {
        const { id, defaultValue, field: name, label, isSolvable = false, range } = field;
        const formulas = extractRangesFormula(range);
        const disabled = !UNLOCK_FIELDS.includes(name) && isSolvable;

        return {
          id: id || index + 1,
          label,
          placeHolder: `Enter ${label}`,
          name,
          type: 'number',
          isLock: disabled,
          disabled: index === solvableIndex,
          defaultValue,
          ...ranges,
          formulas
        }
      }) : [];
}

const mapCards = (cards = [], countDown = TRADE_BUTTON_TIMEOUT_SECONDS, images = {}) => {
  const defaultImage = images?.pricingMiniCardBg?.default || '/images/no-image.png';
  return cards
    .filter(({ underlying }) => underlying)
    .map((card) => {
      const { cardId, underlying, fields: cardFields, type, dateconfig = {} } = card;
      const id = normalizeId(cardId);
      const limitation = loadAllVariable({}, underlying.data) || {};
      const fields = Array.isArray(cardFields) ? mapFields(cardFields) : null;
      if (card.limitation) return card;
      return {
        ...card,
        id,
        cardId,
        subTitle: card.title,
        title: underlying?.commodityContract,
        type: type || PRICING_MINI_CARD_TYPE_DEFAULT,
        ...dateconfig,
        limitation: {
          ...limitation,
          contractExpiries: Array.isArray(limitation.contractExpiries) ?
            limitation.contractExpiries
              .sort(sortDates) : []
        },
        img: underlying?.logo || defaultImage,
        fields,
        countDown,
        isFixings: false,
      }
    })
};

const setDefaultValues = (cards = [], skipIds = []) => {
  const actions = cards
    .filter(({ cardId }) => !skipIds.includes(cardId))
    .map(({ id, limitation = {} }) => {
      const { contractExpiries = [] } = limitation;

      const contractExpiry = contractExpiries?.length ? contractExpiries[0] : null;
      const formName = getPricingMiniFormName(id);
      return change(formName, 'contractExpiry', contractExpiry)
    });
  return actions;
}

const TRADE_CONFIG_AGILE_MINI = 'Pricing Mini';

export const formCardsInitEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARDS_LOADED),
    filter(() => emptyPricingFormFilter(state$)),
    filter(({ payload }) => payload && payload.cards),
    switchMap(({ payload: pricingMiniCardsData }) => {
      return from(loadTradeConfig())
        .pipe(
          filter(({ getTradeConfigs }) => Array.isArray(getTradeConfigs)),
          map(({ getTradeConfigs = [] }) => {
            const agileMiniConfig = getTradeConfigs.find(({ name }) => name === TRADE_CONFIG_AGILE_MINI);
            const { countdown: countDown } = agileMiniConfig || {};
            return {
              countDown,
              data: pricingMiniCardsData,
            }
          }),
          catchError(() => {
            console.log(`Can't get tarde config for ${TRADE_CONFIG_AGILE_MINI}`);
            return of({ data: pricingMiniCardsData });
          })
        )
    }),
    switchMap(({ data, countDown }) => {
      const images = themeImagesSelector(state$.value);
      const preparedCards = mapCards(data.cards, countDown, images || {});
      const actions = [
        miniAccumulatorCardsUpdate({ ...data, cards: preparedCards }),
        setMiniAccumulatorCardsLoader(false),
        ...setDefaultValues(preparedCards, data?.oldCardIds || []),
      ]
      return from(actions);
    }),
  );

export const copyCardInitEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARD_COPY),
    delay(150),
    switchMap(({ payload: { to } }) => {
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, to) || {};
      return from([
        ...setDefaultValues([{ ...card }], []),
      ]);
    }),
  );

export default combineEpics(
  formCardsInitEpic,
  copyCardInitEpic
)
