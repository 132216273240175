import { STRUCTURE_FX } from 'constants.js';
import { ofType } from 'redux-observable';
import { priceFormFailed, PRICING_SUBMIT_FORM } from 'redux/actions/price';
import { fxCardCreate } from 'redux/actions/fx';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { createFxCardMutation } from 'redux/queries/fx';
import { from } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { cardFxError } from 'redux/epics/price/structures/fx';
import { extractContractData } from '../index';

export const submitPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_FX),
    filter(({ payload }) => !!payload && Array.isArray(state$.value.fx.pricings)),
    map(({ payload }) => extractContractData(state$.value.fx.pricings, payload)),
    filter(contract => contract),
    debounceTime(200),
    switchMap(({ commodity, tradeDate, baseCurrency, id }) => {
      const structure = priceFormValueSelector(state$.value).structure.toLowerCase();
      const cardData = {
        structure: STRUCTURE_FX.toLowerCase(),
        direction: 'buy',
        tradeDate,
        notionalCurrency: baseCurrency,
        notional: 1,
        id,
      };
      return from(createFxCardMutation(structure, cardData))
        .pipe(
          filter(payload => !!payload),
          map(({ price, id, fxCode, notionalCurrency, notional, deliverable, baseCurrency, termCurrency, fxContract, limit }) => {
            return fxCardCreate({
              ...cardData,
              id,
              price,
              fxCode,
              notionalCurrency,
              notional,
              deliverable,
              baseCurrency,
              termCurrency,
              fxContract,
              limit,
            })
          }),
          catchError((error) => {
            const actions = [
              priceFormFailed(),
            ];
            const err = getSingleGraphQlError(error);
            if (err?.message || err.code === 403) {
              return from([
                ...actions,
                notificationErrorSimple(err.message),
              ])
            }

            return from([
              ...actions,
              cardFxError(commodity, 'retrieve'),
            ]);
          })
        )
    })
  );

export default submitPriceFormEpic;
