import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'reactstrap';
import FieldLock from './FieldLock';
import PricinigMiniFormField from '../PricinigMiniFormField';

const PricinigMiniFormDynamicFields = props => {
  const { fields = [], isLoading, form, id } = props;
  const isFieldsSovleFor = fields?.length && fields.filter(({ isLock }) => isLock).length > 1;
  return (
    fields?.length ?
      fields
        .map((field, fieldIndex) => {
          const { isLock, disabled, name } = field;
          return (
            <Col key={name} xs={12}>
              {isLock && fields.length > 1 && isFieldsSovleFor ?
                <FieldLock
                  id={id}
                  fieldName={name}
                  name={`${id}-${name}`}
                  isLocked={isLoading || disabled}
                  index={fieldIndex} >
                  <PricinigMiniFormField isLoading={isLoading} form={form} {...field} />
                </FieldLock>
                :
                <PricinigMiniFormField isLoading={isLoading} form={form} {...field} />
              }
            </Col>
          );
        })
      : null
  )
};

PricinigMiniFormDynamicFields.defaultProps = {
  fields: [],
};

PricinigMiniFormDynamicFields.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default PricinigMiniFormDynamicFields;
