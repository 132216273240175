import { TOURS_RUN, TOURS_STOP, TOURS_GO_TO_STEP, TOURS_WELCOME } from 'redux/actions/tours';

const TOURS_INIT_STATE = {
  show: false,
  goto: null,
  welcome: false
};

export default function toursReducer(state = TOURS_INIT_STATE, action) {
  switch (action.type) {
    case TOURS_RUN:
      return { ...state, show: true };
    case TOURS_STOP:
      return { ...state, show: false };
    case TOURS_GO_TO_STEP:
      return { ...state, goto: action.payload };
    case TOURS_WELCOME:
      return { ...state, welcome: action.payload };
    default:
      return state;
  }
}
