import { combineEpics } from 'redux-observable';
import createEpics from './create.js';
import listEpics from './list.js';
import deleteEpics from './delete.js';
import updateEpics from './update.js';

export default combineEpics(
  createEpics,
  listEpics,
  deleteEpics,
  updateEpics,
);
