import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { optionsProviderObj } from 'pages/price/PriceForm/utils';

export const PRICINIG_MINI_REFERENCE_NUMBER_DEFAUTLT = 400;

export const PRICINIG_MINI_REFERENCE_NUMBER_DEFAUTLT_RANGES = {
  min: 0,
  max: INPUT_NUMBER_MAX_VALUE,
};

export const REFERENCE_TYPE_LIMIT = 'limit';
export const REFERENCE_TYPE_LIVE = 'live';

export const REFERENCE_TYPES = [
  REFERENCE_TYPE_LIMIT,
  REFERENCE_TYPE_LIVE,
];

export const REFERENCE_TYPES_OPTIONS = optionsProviderObj(REFERENCE_TYPES);

export const initialValues = {
  referenceType: REFERENCE_TYPE_LIVE
};

export const PRICING_MINI_CARD_TYPE_FLEX = 'flex';
export const PRICING_MINI_CARD_TYPE_DEFAULT = 'default';
export const PRICING_MINI_CARD_TYPES = [
  PRICING_MINI_CARD_TYPE_FLEX,
  PRICING_MINI_CARD_TYPE_DEFAULT,
];
