import React, { PureComponent } from "react";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Form, Row } from "reactstrap";
import InlineInputWithMask from "components/form/inputs/inlineInput/InlineInputWithMask";
import MainErrorMessage from "components/form/MainErrorMessage";
import validate from "./validate";
import InlineInput from "components/form/InlineInput";
import { isEmpty } from "lodash";

export const PRICINIG_MINI_FACT_SHEET_FORM = '@PRICINIG/FORM_FACT_SHEET_MINI';

class FactSheetModalForm extends PureComponent {
  submit = ({notional, premiumAmount}) => {
    const { toggleFactSheet, setNotionalAction, setPremiumAction } = this.props;
    setPremiumAction(premiumAmount)
    toggleFactSheet();
    setNotionalAction(notional)

    this.closeModal();
  };

  resetFormAfterSubmit = () => {
    const { onClose, setNotionalAction, setPremiumAction, premiumAmount, reset } = this.props;
    reset(PRICINIG_MINI_FACT_SHEET_FORM);
    setPremiumAction(premiumAmount)
    setNotionalAction(null)
    if (typeof onClose === "function") {
      onClose();
    }
  };

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  };

  closeModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  onCancel = () => {
    const { setNotionalAction, setPremiumAction, premiumAmount } = this.props;
    setPremiumAction(premiumAmount)
    setNotionalAction(null)
    this.closeModal()
  };

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  render() {
    const {
      pristine,
      handleSubmit,
      invalid,
      submitting,
      error,
      intl: { formatMessage },
      premiumField,
    } = this.props;
    return (
      <div className="pricing-mini-trade-form">
        <div className="modal-header">
          <div className="modal-title">
            <FormattedMessage
              id="pricing-mini-trade.title"
              defaultMessage="Factsheet details"
            />
          </div>
        </div>
        <MainErrorMessage error={error} />
        <Form
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          onSubmit={handleSubmit(this.submit)}
        >
          <Row>
            <Col xs={6}>
              <Field
                name="notional"
                type="formatted-number"
                label="Notional"
                placeHolder={formatMessage({
                  id: "form.pricinig-mini.notional",
                  defaultMessage: "Enter Notional",
                })}
                showMessage={false}
                component={InlineInputWithMask}
              />
            </Col>
          </Row>
          {!isEmpty(premiumField) ? <Row>
            <Col xs={6}>
              <Field
                name="premiumAmount"
                type="number"
                label={premiumField?.label}
                placeHolder={formatMessage({
                  id: "form.pricinig-mini.premiumAmount",
                  defaultMessage: `Enter ${premiumField?.label}`,
                })}
                showMessage={false}
                component={InlineInput}
              />
            </Col>
          </Row> : null}
          <div className="text-right">
            <Button
              color="default"
              type="button"
              className="mr-3"
              disabled={submitting}
              onClick={this.onCancel}
            >
              <FormattedMessage id="common/cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              type="button"
              onClick={handleSubmit(this.submit)}
              color="primary"
              disabled={pristine || invalid || submitting}
            >
              <FormattedMessage
                id="common/send-order"
                defaultMessage="Generate"
              />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
};

const reduxFormOptions = {
  form: PRICINIG_MINI_FACT_SHEET_FORM,
  validate,
  enableReinitialize: true,
};

export default compose(
  injectIntl,
  reduxForm(reduxFormOptions),
)(FactSheetModalForm);
