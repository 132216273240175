import Loader from 'components/common/loader/Loader';
import { GRAPHQL_CACHE_FIRST } from 'components/graphql/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';

const FilterControlQuery = props => {
  const { children, query, variables } = props;
  return (
    query ?
      <div className="filter-control-loader-wrapper loader-wrapper">
        <Query query={query} variables={variables} fetchPolicy={GRAPHQL_CACHE_FIRST}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return <div>{error}</div>
            if (data) return children(data)
          }}
        </Query>
      </div>
      :
      null
  );
};

FilterControlQuery.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
};

export default FilterControlQuery;
