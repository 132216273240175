import { OPEN_TRADES_SET_INITIAL_STATE, OPEN_TRADES_SET_CURRENT, OPEN_TRADES_REFETCH, OPEN_TRADES_NEW, OPEN_TRADES_RESET_CURRENT } from 'redux/actions/open-trades';

const OPEN_TRADES_INIT_STATE = {
  current: null,
  update: null,
  newOrder: null
}

export default function openTradesReducer(state = OPEN_TRADES_INIT_STATE, action) {
  try {
    switch (action.type) {
      case OPEN_TRADES_SET_INITIAL_STATE:
        return { ...OPEN_TRADES_INIT_STATE }
      case OPEN_TRADES_SET_CURRENT:
        return { ...state, current: action.payload }
      case OPEN_TRADES_RESET_CURRENT:
        return { ...state, current: null }
      case OPEN_TRADES_REFETCH:
        return { ...state, update: action.payload }
      case OPEN_TRADES_NEW:
        return { ...state, newOrder: action.payload }
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
