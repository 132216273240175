import { change, untouch } from 'redux-form';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import { STRUCTURE_FX, STRUCTURE_MINIFUTURE, STRUCTURE_SWAP, STRUCTURE_ASIAN_SWAP } from 'constants.js';
import { notificationRead, notificationUpdate, notificationUpdateCounter, notificationsGetAction } from 'redux/actions/notifications';
import { ordersNew } from 'redux/actions/orders';
import { commonOrdersNew } from 'redux/actions/ordersCommon';
import { loadedSwapData, starRefetchinExpiry, swapCardUpdate } from 'redux/actions/swap';

import { getCardById } from 'redux/epics/price/structures/swap/utils';
import { fxCardUpdate } from 'redux/actions/fx';
import { openTradesNew } from 'redux/actions/open-trades';
import { NOTIFICATION_ACTION_CREATE, NOTIFICATION_ACTION_UPDATE } from './constants';
import { minifutureCardUpdate, starRefetchinMfCards, loadForm } from 'redux/actions/minifuture';
import { getPricingMiniFormName } from 'redux/epics/price/mini/utils';
import { updateReferencePriceActions } from 'redux/epics/price/mini/reference-price';
import { miniAccumulatorCardUpdate, miniAccumulatorCardGetReferencePriceFinish } from 'redux/actions/accumulator-mini';
import { SWAP_BULLET } from 'pages/price/output/asianSwap/constants';

export const updateObjectKeys = (obj) => {
  let newObj = {};
  if (isObject(obj)) {
    Object.keys(obj).forEach(key => {
      const newKey = camelCase(key);
      newObj[newKey] = obj[key];
    });
  }
  return newObj;
}

export const notificationsHandler = (notification, state$) => {
  const { payload } = notification;
  const action = payload && payload.action ? payload.action : '';
  switch (action) {
    case 'readAllNotifications':
      return [
        notificationsGetAction(),
        notificationUpdateCounter(0)
      ];
    case 'readNotification':
      const readNotificationCounter = state$.value.notifications.counter > 0 ? --state$.value.notifications.counter : 0;
      return [
        notificationRead(payload),
        notificationUpdateCounter(readNotificationCounter)
      ];
    case 'updateNotification':
      return [
        notificationUpdate(omit(payload, 'action')),
      ];
    default:
      return [];
  }
}

export const notificationsCommonOrderHandler = (notification, state$) => {
  const { payload } = notification;
  const action = payload?.action ?? '';
  const currentPayload = state$.value?.ordersCommon?.newOrder || {};
  const newPayload = payload ? updateObjectKeys(payload) : null;
  switch (action) {
    case NOTIFICATION_ACTION_CREATE:
    case NOTIFICATION_ACTION_UPDATE:
      return [
        commonOrdersNew({
          ...currentPayload,
          ...newPayload,
          id: payload.f_id,
          updatedAt: newPayload.updatedAt ? newPayload.updatedAt : newPayload.createdAt,
        })
      ];
    default:
      return [];
  }
}

export const notificationsOpenTradesHandler = (notification, state$) => {
  const { payload } = notification;
  const action = payload?.action ?? '';
  const currentPayload = state$.value?.ordersCommon?.newOrder || {};
  const newPayload = payload ? updateObjectKeys(payload) : null;
  switch (action) {
    case NOTIFICATION_ACTION_UPDATE:
    case NOTIFICATION_ACTION_CREATE:
      return [
        openTradesNew({
          ...(newPayload.externalId === currentPayload.externalId ? currentPayload : {}),
          ...newPayload,
          updatedAt: newPayload.updatedAt ? newPayload.updatedAt : newPayload.createdAt,
        })
      ];
    default:
      return [];
  }
}

export const notificationsOrderHandler = (notification, state$) => {
  const { payload } = notification;
  const action = payload && payload.action ? payload.action : '';
  const newPayload = payload ? updateObjectKeys(payload) : null;
  switch (action) {
    case NOTIFICATION_ACTION_CREATE:
    case NOTIFICATION_ACTION_UPDATE:
      return [
        ordersNew({
          ...newPayload,
          id: payload.f_id,
          updatedAt: newPayload.updatedAt ? newPayload.updatedAt : newPayload.createdAt,
        })
      ];
    default:
      return [];
  }
}

const UPDATE_HANDLER = new Map([
  [STRUCTURE_FX.toLocaleLowerCase(), fxCardUpdate],
  [STRUCTURE_SWAP.toLowerCase(), swapCardUpdate],
  [STRUCTURE_ASIAN_SWAP.toLowerCase(), swapCardUpdate],
  [STRUCTURE_MINIFUTURE.toLocaleLowerCase(), minifutureCardUpdate]
]);

export const notificationsFxCardHandler = (notification, state$) => {
  const { payload } = notification;
  const action = payload?.action ? payload.action : '';
  const { cardId, status, price, quoteId, ratio } = payload;
  const card = getCardById(state$.value.price.trailPrice?.cards, cardId) || getCardById(state$.value.fx.trailPrice?.cards, cardId) || getCardById(state$.value.minifuture.trailPrice?.cards, cardId);
  const update = UPDATE_HANDLER.get(card?.structure.toLowerCase());

  if (!update) {
    return [];
  }

  switch (action) {
    case NOTIFICATION_ACTION_UPDATE:
      return [
        update({
          ...card,
          status,
          price,
          quoteId,
          ratio,
        })
      ];
    default:
      return [];
  }
}

export const updateStructures = () => {
  return [starRefetchinExpiry()];
};

export const minifutureCardsUpdate = (notification, state$) => {
  return [starRefetchinMfCards()];
}

export const calendarSpreadPermissionUpdate = ({ payload }, state$) => {
  return [loadedSwapData({ ...state$.value.price, swapType: payload?.swapType || SWAP_BULLET, enableCalendarSpread: !!(payload?.enabled) })];
}

export const mfFxPermissionUpdate = ({ payload }, state$) => {
  return [starRefetchinMfCards(), loadForm()];
}

export const notificationsReferencePriceHandler = (notification, state$) => {
  const { payload } = notification;
  const cardsList = Array.isArray(state$.value?.accumulatorMini?.cards) ? state$.value.accumulatorMini.cards : [];
  const cards = cardsList.filter(({ selectedUnderlying, structure, id }) => structure === payload.structure && selectedUnderlying && selectedUnderlying.id === payload.underlyingId && (payload.cardId && payload.cardId === id));

  const actions = [];
  for(const { id, isUpdateFormFieldsValuesInLive, isReferencePriceForSolve } of cards) {
    const formName = getPricingMiniFormName(id)
    actions.push(
      change(formName, 'reference', payload.referencePrice),
      untouch(formName, 'reference'),
      ...updateReferencePriceActions(state$, formName, payload.referencePrice, isUpdateFormFieldsValuesInLive, false),
      miniAccumulatorCardUpdate({
        id,
        isUpdateFormFieldsValuesInLive: false,
      }),
    )

    if(isReferencePriceForSolve) actions.push(miniAccumulatorCardGetReferencePriceFinish())
  }
  return actions;
}
