import DescriptionFormatted from 'pages/price/output/accumulator/structureDescription/DescriptionFormatted';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const PrintProductDescription = props => {
  const {
    productDescription
  } = props;
  return (
    productDescription ?
      <div className="structure-description-section bg-gray">
        <h3 className="text-uppercase">
          <FormattedMessage id="print-pdf/product-description"
            defaultMessage="Product Description" />
        </h3>
        <div className="structure-description">
          <DescriptionFormatted description={productDescription} />
        </div>
      </div> : null
  );
};

PrintProductDescription.propTypes = {
  productDescription: PropTypes.string.isRequired,
};

export default PrintProductDescription;
