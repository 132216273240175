import React from 'react';
import PropTypes from 'prop-types';
import TableHeaderSorterItem from './TableHeaderSorterItem';

function TableHeaderSorter(props) {
  const { onChange } = props;

  const orderByField = field => {
    onChange(field);
  }

  const { selected: selectedSortField, order, sorters } = props;
  const disabled = sorters && sorters.length < 2;
  return (
    sorters && sorters.length ?
      <div className={'d-xs-block d-sm-block d-none' + order ? `order-${order}` : ''}>
        <div className={'d-none d-sm-block app-table-row app-table-header pricing-history-header mb-1' + (disabled ? ' disabled' : '')}>
          {sorters
            .map(item => <TableHeaderSorterItem
              key={item.key}
              value={item.key}
              onChange={orderByField}
              isDisabled={item.isDisabled}
              label={item.label}
              className={item.className}
              selectedSortField={selectedSortField} />)
          }
        </div>
      </div>
      : null
  );
}

TableHeaderSorter.defaultProps = {
  disabled: false,
  sorters: []
}

TableHeaderSorter.propTypes = {
  sorters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default TableHeaderSorter;
