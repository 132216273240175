import gql from 'graphql-tag';
import OpenTradesTableItem from './OpenTradesTableItem';
import OtcOrdersTableItem from './OtcOrdersTableItem';

export const DASHBOARD_OTC_ORDERS_QUERY = gql`query dashboardOtcOrdersQuery (
  $total: Int
  $dashboardId: Int
  $isMyDashboard: Boolean
  $cursor: String
  $filter: [OTCOrderValueFilterType]
  $sorter: [OTCOrderValueSorterType]
) {
  otcOrdersConnection (
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    first: $total
    after: $cursor
    filter: $filter
    sorter: $sorter
  ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          id
          ...OtcOrdersTableConnectionFragment
        }
      }
  }
}
${OtcOrdersTableItem.fragments.item}`;

export const DASHBOARD_OTC_ORDERS_CANCEL_MUTATION = gql`mutation cancelOTCOrderMutation  (
  $id: Int!
  ) {
  cancelOTCOrderMutation (
    id: $id
  )
}`;




export const DASHBOARD_TRADES_QUERY = gql`query dashboardTradesQuery (
  $total: Int
  $dashboardId: Int!
  $isMyDashboard: Boolean
  $cursor: String
  $filter: [OTCStatementValueFilterType]
  $sorter: [OTCStatementValueSorterType]
) {
  otcStatementsConnection (
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    first: $total
    after: $cursor
    filter: $filter
    sorter: $sorter
  ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          id
          ...TradesTableConnectionFragment
        }
      }
  }
}
${OpenTradesTableItem.fragments.item}`;

export const DASHBOARD_TRADE_QUERY = gql`query otcStatementQuery (
  $accumulatorMiniHistoryId: String
  $otcStatementId: String
  $dashboardId: Int!
  $isMyDashboard: Boolean
  $commonId: String
) {
  otcStatement (
    accumulatorMiniHistoryId: $accumulatorMiniHistoryId
    otcStatementId: $otcStatementId
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    commonId: $commonId
  ) {
    id
    ...TradesTableConnectionFragment
  }
}
${OpenTradesTableItem.fragments.item}`;

export const DASHBOARD_TRADE_UPDATE_MUTATION = gql`mutation updateOTCStatementMutation  (
  $accumulatorMiniHistoryId: String
  $otcStatementId: String
  $dashboardId: Int!
  $isMyDashboard: Boolean
  $customerId: String
  $locationId: String
  $physicalId: String
  $traderId: String
  $commonId: String
) {
  updateOTCStatementMutation (
    accumulatorMiniHistoryId: $accumulatorMiniHistoryId
    otcStatementId: $otcStatementId
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    customerId: $customerId
    locationId: $locationId
    physicalId: $physicalId
    traderId: $traderId
    commonId: $commonId
  ) {
    id
    ...TradesTableConnectionFragment
  }
}
${OpenTradesTableItem.fragments.item}`;

export const DASHBOARD_REPORT_TRADE_QUERY = gql`query otcStatementsCsvReportQuery (
  $dashboardId: Int!
  $isMyDashboard: Boolean
  $filter: [OTCStatementValueFilterType]
  $sorter: [OTCStatementValueSorterType]
) {
  otcStatementsCsvReport (
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    filter: $filter
    sorter: $sorter
  ) {
    buffer
    name
  }
}`;

export const DASHBOARD_REPORT_ORDERS_QUERY = gql`query otcOrdersCsvReportQuery (
  $dashboardId: Int!
  $isMyDashboard: Boolean
  $filter: [OTCOrderCsvValueFilterType]
  $sorter: [OTCOrderCsvValueSorterType]
) {
  otcOrdersCsvReport (
    dashboardId: $dashboardId
    isMyDashboard: $isMyDashboard
    filter: $filter
    sorter: $sorter
  ) {
    buffer
    name
  }
}`;

export const DASHBOARD_REPORT_FILTER_OPTIONS_QUERY = gql`query otcStatementsConnectionQuery (
  $filter: [OTCStatementValueFilterType]
  $distinct: Boolean
  $dashboardId: Int!
) {
  otcStatementsConnection(
    distinct: $distinct,
    dashboardId: $dashboardId,
    filter: $filter
  ) {
    edges{
      node {
        tradeType
        customer {
          name
          code
          id
        }
      }
    }
  }
}`;
