import { GRAPHQL_NETWORK_ONLY } from "./constants";

export function queryOptionsProvider(query, propertyName, options) {
  if (!propertyName) return;
  options = options || { fetchPolicy: GRAPHQL_NETWORK_ONLY };
  return {
    options,
    props({ data: { loading, fetchMore, refetch, variables, ...rest } }) {
      return {
        refetch,
        loading,
        [propertyName]: rest[propertyName],
        loadMore: () => {
          return fetchMore({
            query,
            variables: {
              ...variables,
              cursor: rest[propertyName].pageInfo.endCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }, ...restUpdateQuery) => {
              const newEdges = fetchMoreResult[propertyName].edges;
              const pageInfo = fetchMoreResult[propertyName].pageInfo;
              const updatedData = [...previousResult[propertyName].edges, ...newEdges];
              return newEdges.length ? {
                [propertyName]: {
                  ...previousResult[propertyName],
                  __typename: previousResult[propertyName].__typename,
                  edges: updatedData,
                  pageInfo,
                  ...restUpdateQuery
                },
              } : previousResult;
            },
          });
        },
        variables,
        ...rest
      };
    }
  }
}
