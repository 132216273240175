import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import FieldErrorMessage from '../FieldErrorMessage';

export const InlineBox = props => {
  const {label, name, meta, children, ...rest} = props;
  return (
    <FormGroup color={meta.touched && meta.invalid ? 'danger' : null} {...rest}>
      { label ? <Label for={name}>{label}</Label> : null }
      {children}
      <FieldErrorMessage {...meta}/>
    </FormGroup>
  );
};

InlineBox.defaultProps = {
  type: 'text'
}

InlineBox.propTypes = {
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default InlineBox;
