export const THEME_INIT_STATE = {
  texts: {
    copyright: null,
  },
  images: {
    logo: null,
    loginLogo: null,
  },
  styles: null,
  name: null,
  meta: {
    defaultTitle: null,
    loginTitle: null,
    loginDescription: null,
    description: null,
    favicon: null,
  },
  isLoading: false,
};
