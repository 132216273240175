// Roles
export const ROLE_PRICING = 'pricing';
export const ROLE_DASHBOARD = 'dashboard';
export const ROLE_TRADES_HISTORY = 'trades';
export const ROLE_PORTAL = 'portal';
export const ROLE_PRICING_MINI = 'pricing-mini';
export const ROLE_STRUCTURED_PRODUCTS = 'autocall'; //'structured-products';
export const ROLE_MINIFUTURE = 'minifuture';
export const ROLE_MY_DASHBOARD = 'my-dashboard';
export const ROLE_BOOKING = 'booking';
export const ROLE_CONFIGURABLE_SP = 'configurable-sp';
