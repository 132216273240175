import gql from 'graphql-tag';

export const CREATE_ASIAN_SWAP_ORDER_MUTATION = gql`mutation createAsianSwapOrderMutation(
  $cardId: String!
  $structure: String!
  $commodityCode: String!
  $timeInForce: String
  $orderType: String
  $price: Float
  $slackIceClientsId: ID
  $avgPrice: Float,
  $legsPrices: [AsianLegPriceType],
) {
  createAsianSwapOrder(
    cardId: $cardId
    structure: $structure
    commodityCode: $commodityCode
    timeInForce: $timeInForce
    orderType: $orderType
    price: $price
    slackIceClientsId: $slackIceClientsId
    avgPrice: $avgPrice
    legsPrices: $legsPrices
  ) {
    id
  }
}`;
