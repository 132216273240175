import apolloClient from 'apollo.js';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import store from 'store.js';
import { mapNodes } from 'utils/';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

export const QUERY_LOAD_ACCUMULATOR_UNDERLYINGS_DATA = gql`query queryLoadAccumulatorUnderlyingsData{
  pricingAccumulatorUnderlyingDataConnection {
    edges {
      node {
        id
        commodity: commodityContract
        expiryDate
        underlyingAsset
        bloombergTicker
        contractExpiry: contractCode
        accumulatorUnderlyings {
          id
          currency
        }
      }
    }
  }
}`;

export const loadAccumulatorUnderlyingsData = async () => {
  try {
    let resp = await apolloClient.query({
      query: QUERY_LOAD_ACCUMULATOR_UNDERLYINGS_DATA,
      fetchPolicy: GRAPHQL_NETWORK_ONLY
    })
      .then(({ data }) => {
        const underlyings = mapNodes(data.pricingAccumulatorUnderlyingDataConnection)
          .filter(({ accumulatorUnderlyings }) => accumulatorUnderlyings);
        if (isEmpty(underlyings)) {
          store.dispatch(notificationPriceFailLoadAllData());
        }
        return underlyings;
      });
    return resp;
  } catch (e) {
    // @TODO: add handle error component
    console.log(e);
  }
};

export const QUERY_LOAD_DESCRIPTIONS_DATA = gql`query loadAllData {
  pricingAccumulatorDescriptionConnection {
    edges {
      node {
        id
        structureCode
        updatedAt
        leverage
        leverageStyle
        barrierType
        structure
        productDescription
        productCategory
        product
        type
        remainderFeature
        accumulationStyle
      }
    }
  }
}`;

export const loadAccumulatorDescriptionsData = async () => {
  try {
    let resp = await apolloClient.query({
      query: QUERY_LOAD_DESCRIPTIONS_DATA,
      fetchPolicy: GRAPHQL_NETWORK_ONLY
    })
      .then(({ data }) => {
        const descriptions = mapNodes(data.pricingAccumulatorDescriptionConnection);
        if (isEmpty(descriptions)) {
          store.dispatch(notificationPriceFailLoadAllData());
        }
        return descriptions;
      });
    return resp;
  } catch (e) {
    // @TODO: add handle error component
    console.log(e);
  }
};
