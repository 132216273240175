import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import { themeMetaDataSelector } from 'components/theme/utils';
import Bundle from 'pages/Bundle';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export const LoginPage = props => {
  const { meta } = props;
  const { defaultTitle: titleSuffix, loginTitle: title, loginDescription: description } = meta || {};
  return (
    <ErrorBoundary>
      <PageMeta
        title={title}
        description={description}
        titleSuffix={titleSuffix}
      />
      <Bundle title="Log In Page" load={() => import('./Login')}>
        {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
      </Bundle>
    </ErrorBoundary>
  )
};

LoginPage.propTypes = {
  meta: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ meta: themeMetaDataSelector(state) });

export default connect(mapStateToProps)(LoginPage);
