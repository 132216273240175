
export const COMMON_ORDERS = '@COMMON_ORDERS';
export const COMMON_ORDERS_SET_INITIAL_STATE = `${COMMON_ORDERS}/INIT`;
export const COMMON_ORDERS_SET_CURRENT = `${COMMON_ORDERS}/CURRENT/SET`;
export const COMMON_ORDERS_RESET_CURRENT = `${COMMON_ORDERS}/CURRENT/RESET`;
export const COMMON_ORDERS_INIT_CURRENT = `${COMMON_ORDERS}/INIT`;
export const COMMON_ORDERS_EDIT = `${COMMON_ORDERS}/EDIT`;
export const COMMON_ORDERS_REFETCH = `${COMMON_ORDERS}/REFETCH`;
export const COMMON_ORDERS_DELETE = `${COMMON_ORDERS}/DELETE`;
export const COMMON_ORDERS_RESET = `${COMMON_ORDERS}/RESET`;
export const COMMON_ORDERS_NEW = `${COMMON_ORDERS}/NEW`;


export const commonOrderSet = payload => ({ type: COMMON_ORDERS_SET_CURRENT, payload });
export const commonOrderReset = payload => ({ type: COMMON_ORDERS_RESET_CURRENT, payload });
export const commonOrderInit = payload => ({ type: COMMON_ORDERS_INIT_CURRENT, payload });
export const commonOrderEdit = payload => ({ type: COMMON_ORDERS_EDIT, payload });
export const commonOrderRefetch = payload => ({ type: COMMON_ORDERS_REFETCH, payload });
export const commonOrderDelete = payload => ({ type: COMMON_ORDERS_DELETE, payload });
export const commonOrdersSetInital = () => ({ type: COMMON_ORDERS_SET_INITIAL_STATE });
export const commonOrdersNew = payload => ({ type: COMMON_ORDERS_NEW, payload });
