import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import Bundle from 'pages/Bundle';
import React from 'react';

export const ConfirmPage = props => (
  <ErrorBoundary>
    <PageMeta title="Confirm Email" />
    <Bundle title="Confirm Email Page" load={() => import('./ConfirmPage')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default ConfirmPage;
