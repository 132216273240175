import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const SwitchBtn = withStyles({
  switchBase: {
    '&$checked': {
      color: '#0bb2dd',
    },
    '&$checked + $track': {
      backgroundColor: '#0bb2dd',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#fff',
    opacity: 0.3
  },
})(Switch);

export default SwitchBtn;
