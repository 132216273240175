import validateJs from "utils/validate.js";
import jsonLogic from "json-logic-js";
import { isArray } from "lodash";

const getVars = (numericalityRule: object) => {
  const text = JSON.stringify(numericalityRule);
  const matches = text.match(/(?<="var":(["']))([^"']*)/g);
  const uniqueVars = new Set(matches);
  const uniqueVarsArray = Array.from(uniqueVars);
  return uniqueVarsArray;
};

const parseNumericality = (numericality: object, formData) => {
  const formattedNumericality = {};
  for (const [numericalityKey, numericalityRule] of Object.entries(
    numericality,
  )) {
    if (typeof numericalityRule === "number") {
      formattedNumericality[numericalityKey] = numericalityRule;
    } else {
      const arrayOfVars = getVars(numericalityRule);
      const parsedData = arrayOfVars.reduce((acc, i) => {
        acc[i] = isNaN(formData[i])
          ? formData[i]?.value || formData[i]
          : parseFloat(formData[i]);
        return acc;
      }, {});
      const addedLogic = jsonLogic.apply(numericalityRule, parsedData);
      formattedNumericality[numericalityKey] = isArray(addedLogic)
        ? addedLogic[0]
        : addedLogic;
    }
  }
  return formattedNumericality;
};

const parseRules = (rules: object, formData = {}) => {
  const formattedRules = {}
  for (const [key, rule] of Object.entries(rules)) {
    if (rule.hasOwnProperty("numericality")) {
      const { numericality } = rule;
      formattedRules[key] = {
        ...rule,
        numericality: parseNumericality(numericality, formData),
      };
    } else {
      formattedRules[key] = {
        ...rule,
      };
    }
  }
  return formattedRules
}

export default function validate(rules: object) {
  return function (formData, props, name) {
    const parsedRules = parseRules(rules, props.values || props);

    if (name) {
      // validator is being called a second time without form values, we can skip this call
      return
    }

    return validateJs(formData, parsedRules) || {};
  }
}
