import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const StructuredProducts = props => (
  <ErrorBoundary>
    <PageMeta title="Structured Products" />
    <Bundle title="Structured Products Page" load={() => import('layouts/StrucutredProductsLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

const mapStateToProps = createSelector(
  [state => state.auth],
  ({ account }) => ({
    isConfigurableSp: account?.isConfigurableSp || false,
  })
);

export default compose(
  connect(mapStateToProps),
)(StructuredProducts);
