import validate from "validate.js";
import messages from "utils/validate.messages";
import isEmpty from "lodash/isEmpty";
import { TIME_IN_FORCE_GTD } from "components/trade/constants.js";

validate.validators.presence.options = { message: messages.blank };
validate.validators.restricted = () => messages.restricted;
validate.validators.email.options = { message: `^${messages.invalidFormat}` };

export const NAME_VALIDATION = {
  format: {
    pattern: /[A-Za-z \-.']+/,
    message: `^${messages.invalidFormat}. You can use only (  ), (-), (.) and (').`,
  },
  length: {
    minimum: 2,
    tooShort: "^Minimum value allowed %{count} symbols",
    maximum: 100,
    tooLong: "^Maximum value allowed %{count} symbols",
  },
};

export function minApprove(value, options = {}) {
  const { rules = [], minimum = 1, message = "is too weak" } = options;
  let acceptErrors = rules.length - minimum;

  const isValid =
    rules.reduce((acceptErrors, rule) => {
      return acceptErrors === -1 || !validate.single(value, rule)
        ? acceptErrors
        : acceptErrors - 1;
    }, acceptErrors) !== -1;

  return !isValid ? message : undefined;
}

validate.validators.minApprove = minApprove;

export const PASSWORD_RULES = [
  { format: /.*[a-z]+.*/ },
  { format: /.*[A-Z]+.*/ },
  { format: /.*[0-9]+.*/ },
  { format: /.*[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]+.*/ },
];

export function password(value = "", options = {}) {
  const { message = "^is week.", strong = false } = options;
  return value
    ? validate.single(
        value.trim(),
        {
          length: {
            minimum: 8,
            message,
          },
          minApprove: {
            rules: PASSWORD_RULES,
            minimum: strong ? 4 : 3,
            message,
          },
        },
        { format: "flat" },
      )
    : undefined;
}

validate.validators.password = password;

export const maxDecimalDigitsValidator = (value, options = {}) => {
  const { max = 2 } = options;
  const valueArray = value ? value.toString().split(".") : null;
  const isInvalid = valueArray && valueArray[1] && valueArray[1].length > max;
  return isInvalid ? `${messages.maxDigitsAfterDecimal}: ${max}` : undefined;
};

validate.validators.maxDecimalDigits = maxDecimalDigitsValidator;

validate.validators.expiryGoodTillDate = (
  value,
  options = {},
  key,
  attributes,
) => {
  const { timeInForce } = attributes;
  if (timeInForce === TIME_IN_FORCE_GTD && !value)
    return "required for Good Till Date.";
  return undefined;
};

export const customNumber = (value, options = {}) => {
  const num = parseFloat(value);
  const isValid = isNaN(num) || options.test(num);
  return isValid ? undefined : options.msg;
};

validate.validators.customNumber = customNumber;

validate.validators.array = (arrayItems = [], itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    errors[index] = error ? { ...error } : {};
    return errors;
  }, []);
  return isEmpty(arrayItemErrors) ? null : [...arrayItemErrors];
};

validate.validators.arrayFixed = (arrayItems = [], itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    if (error) errors[index] = error;
    return errors;
  }, []);
  return isEmpty(arrayItemErrors) ? undefined : [...arrayItemErrors];
};

validate.validators.itemComparator = (
  value,
  { comparator },
  attribute,
  attributes,
) => {
  if (!comparator) return undefined;
  const error = validate(attributes, { [attribute]: comparator(attributes) });
  return error ? error[attribute] : undefined;
};

export default validate;
