import { combineEpics, ofType } from 'redux-observable';
import { PRICING_MINI_ACCUMULATOR_CARDS_UPDATE, loadedMiniAccumulatorCardsData, PRICING_MINI_ACCUMULATOR_CARDS_FETCH_MORE, PRICING_MINI_ACCUMULATOR_CARDS_LOAD, setMiniAccumulatorCardsLoader } from 'redux/actions/accumulator-mini';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { distinctObjectChanges } from 'redux/epics/utils';
import { loadAccumulatorMiniPricngsCardsData } from 'redux/queries/pricing-mini';
import { bankHolidaysSet } from "redux/actions/accumulator-mini";
import isArray from 'lodash/isArray';
import { getBankHolidays, BANK_HOLIDAYS_TYPE } from 'components/form/BankHolidaysCalendar/query';

import { from, EMPTY } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { emptyPricingFormFilter } from './utils';

export const loadBankHolidays = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARDS_UPDATE),
    switchMap(() => {
      const [{ underlyingId } = {}] = [] = state$.value?.accumulatorMini?.cards;
      const underlyingIds = underlyingId ? [underlyingId] : [];

      if (!underlyingIds.length) return EMPTY;

      return from(getBankHolidays(underlyingIds, BANK_HOLIDAYS_TYPE.ACCUMULATORS))
        .pipe(
          filter((getBankHolidays) => isArray(getBankHolidays)),
          switchMap((getBankHolidays) => from([bankHolidaysSet(getBankHolidays)])),
          catchError(() => from([notificationErrorSimple('Bank holidays isn\'t available')]))
        )
    })
  )

export const loadFormEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_MINI_ACCUMULATOR_CARDS_LOAD),
      filter(({ payload }) => payload?.underlyingId),
      debounceTime(200),
      switchMap(({ payload: { underlyingId, groupIds, cardIds } }) => {
        return from(loadAccumulatorMiniPricngsCardsData({ underlyingId, groupIds, cardIds }))
          .pipe(
            catchError(() => {
              const errorActions = [
                notificationErrorSimple(`Can't load Pricing Cards`),
              ];
              return from(errorActions);
            }),
            takeUntil(action$.pipe(ofType(AUTH_LOGOUT)))
          );
      }),
      switchMap(data => {
        const oldCards = state$.value.accumulatorMini && state$.value.accumulatorMini.cards ? state$.value.accumulatorMini.cards : [];
        const oldCardIds = oldCards.map(({ cardId }) => cardId);
        return from([
          loadedMiniAccumulatorCardsData({ ...data, oldCardIds }),
        ])
      })
    );

export const fetchMorePicingMiniCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_MINI_ACCUMULATOR_CARDS_FETCH_MORE),
      filter(() => emptyPricingFormFilter(state$)),
      map(({ payload }) => payload),
      distinctUntilChanged(distinctObjectChanges),
      debounceTime(200),
      switchMap(({ underlyingId, page, groupIds, cardIds }) => {
        return from(loadAccumulatorMiniPricngsCardsData({ underlyingId, groupIds, cardIds }, page)).pipe(
          filter(data => data && Array.isArray(data.cards)),
          switchMap(data => {
            const { pageInfo } = data || {};
            const oldCards = state$.value.accumulatorMini && state$.value.accumulatorMini.cards ? state$.value.accumulatorMini.cards : [];
            const actions = [
              loadedMiniAccumulatorCardsData({
                pageInfo,
                cards: [
                  ...oldCards,
                  ...data.cards,
                ],
              }),
              setMiniAccumulatorCardsLoader(false),
            ];
            return from(actions);
          })
        )
      })
    );

export default combineEpics(
  loadFormEpic,
  fetchMorePicingMiniCardsEpic,
  loadBankHolidays,
);
