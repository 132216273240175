import { AUTOCALLABLE_FORM_FIELDS_KEYS } from 'pages/structured-products/components/forms/types/autocallable/fields';
import { TWIN_WIN_FORM_FIELDS_KEYS } from 'pages/structured-products/components/forms/types/twinWin/fields';
import { handleVisibleFormFieldsAutocallble } from './types/autocallable';
import { STRUCTURE_CAPITAL_PROTECTION, STRUCTURE_AUTOCALLABLE, STRUCTURE_TWIN_WIN, STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import { handleVisibleFormFieldsCapitalProtection } from './types/capitalProtection';
import { CAPITAL_PROTECTION_FORM_FIELDS_KEYS } from 'pages/structured-products/components/forms/types/capitalProtection/fields';
import { handleVisibleFormFieldsTwinWin } from './types/twinWin';

export const handleVisibleFormFields = (formValues = {}) => {
  const { structure } = formValues;
  switch (structure) {
    case STRUCTURE_AUTOCALLABLE:
    case STRUCTURE_FIXED_COUPON_NOTE:
      return handleVisibleFormFieldsAutocallble(formValues, AUTOCALLABLE_FORM_FIELDS_KEYS);
    case STRUCTURE_TWIN_WIN:
      return handleVisibleFormFieldsTwinWin(formValues, TWIN_WIN_FORM_FIELDS_KEYS);
    case STRUCTURE_CAPITAL_PROTECTION:
      return handleVisibleFormFieldsCapitalProtection(formValues, CAPITAL_PROTECTION_FORM_FIELDS_KEYS);
    default:
      return {};
  }
}
