import React, { PureComponent, Fragment } from 'react';
import NotifyMeDropdownListItem from './NotifyMeDropdownListItem';
import { withRouter } from 'react-router-dom';
import { ROUTE_PRICING_HISTORY } from 'pages/constants';
import { withApollo } from 'react-apollo';
import { notificationRead, notificationsGetAction } from 'redux/actions/notifications';
import { connect } from 'react-redux';
import Loader from 'components/common/loader/Loader';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { compose } from 'redux';
import { notificationExpired } from 'redux/alerts/actions';
import { DEBOUNCE_TIME } from 'constants.js';
import { bindActionCreators } from 'redux';
// import InfiniteScroll from 'react-infinite-scroller';
import { createSelector } from 'reselect';
import { READ_NOTIFICATION_DROPDOWN } from './query';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { NOTIFICATION_TYPE_NOTIFY_ME } from './constansts';

const notificationRoute = (node) => {
  const type = node && node.payload ? node.payload.type : '';
  switch (type) {
    case NOTIFICATION_TYPE_NOTIFY_ME:
      const pricingHistoryId = node.payload && node.payload.pricingHistoryId ? node.payload.pricingHistoryId : '';
      return ROUTE_PRICING_HISTORY + '/' + pricingHistoryId
    default:
      return null;
  }
}

class NotifyMeDropdownList extends PureComponent {
  constructor(props) {
    super(props);
    this.readNotififcation = debounce(this._readNotififcation.bind(this), DEBOUNCE_TIME);
    this.updateRoute = this._updateRoute.bind(this);
    this.props.notificationsGet();
  }

  _updateRoute = (node) => {
    const { history } = this.props;
    const route = notificationRoute(node);
    if (route) {
      history.push(route);
    }
  }

  _readNotififcation = async (node) => {
    const { id, isRead } = node;
    const { client, read, notificationError } = this.props;
    if (!isRead) {
      try {
        let response = await client.mutate({
          mutation: READ_NOTIFICATION_DROPDOWN,
          variables: {
            id
          }
        }).then((resp) => {
          read(node);
          this.updateRoute(node);
          return resp;
        });

        return response.data;
      } catch (e) {
        notificationError();
        console.log(e);
      }
    } else {
      this.updateRoute(node);
    }
  }

  render() {
    const { list, loading, isLoadingPrice, showOnlyRead } = this.props;
    const items = list;
    // if (items?.length > 500) items.length = 500;
    if (loading) {
      return <Loader />
    } else if (!loading && items?.length) {
      return (
        <div className="loader-wrapper">
          {isLoadingPrice ?
            <Fragment >
              <Loader />
              <div className="loader-overlay overlay-65"></div>
            </Fragment>
            : null}
          <div className="notification-dropdown-content">
            <TransitionGroup>
              {
                items.map((node, index) =>
                  <CSSTransition key={node.id} classNames="transition-appear-element" timeout={350} in>
                    <NotifyMeDropdownListItem
                      key={node.id}
                      index={index}
                      showOnlyRead={showOnlyRead}
                      onChageStatus={this.readNotififcation}
                      onDelete={this.deleteNotififcation}
                      {...node} />
                  </CSSTransition>
                )
              }
            </TransitionGroup>
          </div>
        </div>
      )
    } else {
      return <div className="text-center">
        <FormattedMessage id="notifications/dropdown.empty-list"
          defaultMessage="You do not have any notifications"
        />
      </div>
    }
  }
}

const mapStateToProps = createSelector(
  [
    state => state.price,
  ],
  ({ isLoading }) => ({ isLoadingPrice: isLoading })
);

const mapDispatchToProps = dispatch => bindActionCreators({
  read: notificationRead,
  notificationError: notificationExpired,
  notificationsGet: notificationsGetAction
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withApollo,
)(NotifyMeDropdownList);
