import { optionsProvider } from 'pages/price/PriceForm/utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES } from '../filter/filterBuilder';
import { DASHBOARD_REPORT_FILTER_OPTIONS_QUERY } from '../query';

export const FILTERS_LIST_TRADES = [
  {
    'name': 'tradeType',
    'filterType': 'String',
    'type': 'select',
    'query': DASHBOARD_REPORT_FILTER_OPTIONS_QUERY,
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'tradeType',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Type" id="open-trades/tradeType" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Type" id="open-trades/tradeType" />,
    ...DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES['tradeType'],
  },
  {
    'name': 'externalId',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'externalId',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="External ID" id="open-trades/externalId" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="External ID" id="open-trades/externalId" />,
  },
  {
    'name': 'underlyingName',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'underlyingName',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Name" id="open-trades/underlyingName" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Name" id="open-trades/underlyingName" />,
  },
  {
    'name': 'tradeDate',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'queryField': 'tradeDate',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Date" id="open-trades/tradeDate" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Date" id="open-trades/tradeDate" />,
  },
  {
    'name': 'buySell',
    'filterType': 'String',
    'type': 'select',
    'queryField': 'buySell',
    'options': optionsProvider(['Buy', 'Sell']), //@TODO: implement query on BE
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Buy/Sel" id="open-trades/buySell" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Buy/Sel" id="open-trades/buySell" />,
  },
  {
    'name': 'tradeName',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'match',
    'queryField': 'tradeName',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Name" id="open-trades/tradeName" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Name" id="open-trades/tradeName" />,
  },
  {
    'name': 'currency',
    'filterType': 'String',
    'type': 'select',
    'query': DASHBOARD_REPORT_FILTER_OPTIONS_QUERY,
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'currency',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Currency" id="open-trades/currency" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Currency" id="open-trades/currency" />,
    ...DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES['currency'],
  },
  {
    'name': 'deliveryMonth',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'queryField': 'deliveryMonth',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delivery Month" id="open-trades/deliveryMonth" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delivery Month" id="open-trades/deliveryMonth" />,
  },
  {
    'name': 'startDate',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'queryField': 'startDate',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Start Date" id="open-trades/startDate" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Start Date" id="open-trades/startDate" />,
  },
  {
    'name': 'lastMonthAsian',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'queryField': 'lastMonthAsian',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Last Month Asian" id="open-trades/lastMonthAsian" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Last Month Asian" id="open-trades/lastMonthAsian" />,
  },
  {
    'name': 'expiryDate',
    'filterType': 'FilterDateType',
    'type': 'date-range',
    'queryField': 'expiryDate',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Expiry Date" id="open-trades/expiryDate" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Expiry Date" id="open-trades/expiryDate" />,
  },
  {
    'name': 'strikeWithUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'strikeWithUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Strike With Units" id="open-trades/strikeWithUnits" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Strike With Units" id="open-trades/strikeWithUnits" />,
  },
  {
    'name': 'accumulationLevel',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'accumulationLevel',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulation Level" id="open-trades/accumulationLevel" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulation Level" id="open-trades/accumulationLevel" />,
  },
  {
    'name': 'barrierLevel',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'barrierLevel',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Barrier Level" id="open-trades/barrierLevel" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Barrier Level" id="open-trades/barrierLevel" />,
  },
  {
    'name': 'remainderLevel',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'remainderLevel',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remainder Level" id="open-trades/remainder-level" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remainder Level" id="open-trades/remainder-level" />,
  },
  {
    'name': 'min',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'min',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Min" id="open-trades/min" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Min" id="open-trades/min" />,
  },
  {
    'name': 'max',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'max',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max" id="open-trades/max" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max" id="open-trades/max" />,
  },
  {
    'name': 'volumeInClientUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'volumeInClientUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Volume In Client Units" id="open-trades/volumeInClientUnits" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Volume In Client Units" id="open-trades/volumeInClientUnits" />,
  },
  {
    'name': 'notionalLong',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'notionalLong',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long" id="open-trades/notionalLong" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long" id="open-trades/notionalLong" />,
  },
  {
    'name': 'notionalShort',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'notionalShort',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short" id="open-trades/notionalShort" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short" id="open-trades/notionalShort" />,
  },
  {
    'name': 'notionalLongUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'notionalLongUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long Units" id="open-trades/notional-long-units" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long Units" id="open-trades/notional-long-units" />,
  },
  {
    'name': 'notionalShortUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'notionalShortUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short Units" id="open-trades/notional-short-units" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short Units" id="open-trades/notional-short-units" />,
  },
  {
    'name': 'premiumTotal',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'premiumTotal',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Total" id="open-trades/premium-total" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Total" id="open-trades/premium-total" />,
  },
  {
    'name': 'premiumPerUnit',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'premiumPerUnit',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Per Unit" id="open-trades/premium-per-unit" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Per Unit" id="open-trades/premium-per-unit" />,
  },
  {
    'name': 'totalFixings',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'totalFixings',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Total Fixings" id="open-trades/total-fixings" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Total Fixings" id="open-trades/total-fixings" />,
  },
  {
    'name': 'remainingFixings',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'remainingFixings',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remaining Fixings" id="open-trades/remaining-fixings" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remaining Fixings" id="open-trades/remaining-fixings" />,
  },
  {
    'name': 'underlyingPrice',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'underlyingPrice',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Price" id="open-trades/underlyingPrice" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Price" id="open-trades/underlyingPrice" />,
  },
  {
    'name': 'accumulatedLots',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'accumulatedLots',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Lots" id="open-trades/accumulated-lots" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Lots" id="open-trades/accumulated-lots" />,
  },
  {
    'name': 'accumulatedUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'accumulatedUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Units" id="open-trades/accumulated-units" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Units" id="open-trades/accumulated-units" />,
  },
  {
    'name': 'maxQuantityAccumulated',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'maxQuantityAccumulated',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Quantity Accumulated" id="open-trades/max-quantity-accumulated" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Quantity Accumulated" id="open-trades/max-quantity-accumulated" />,
  },
  {
    'name': 'maxRemainingQuantity',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'maxRemainingQuantity',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Remaining Quantity" id="open-trades/max-remaining-quantity" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Remaining Quantity" id="open-trades/max-remaining-quantity" />,
  },
  {
    'name': 'deltaInLots',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'deltaInLots',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta In Lots" id="open-trades/deltaInLots" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta In Lots" id="open-trades/deltaInLots" />,
  },
  {
    'name': 'deltaUnits',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'deltaUnits',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta Units" id="open-trades/delta-units" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta Units" id="open-trades/delta-units" />,
  },
  {
    'name': 'net',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'net',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Net" id="open-trades/net" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Net" id="open-trades/net" />,
  },
  {
    'name': 'bid',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'bid',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Bid" id="open-trades/bid" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Bid" id="open-trades/bid" />,
  },
  {
    'name': 'mid',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'mid',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Mid" id="open-trades/mid" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Mid" id="open-trades/mid" />,
  },
  {
    'name': 'ask',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'ask',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ask" id="open-trades/ask" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ask" id="open-trades/ask" />,
  },
  {
    'name': 'ticker',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'ticker',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ticker" id="open-trades/ticker" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ticker" id="open-trades/ticker" />,
  },
  {
    'name': 'trader',
    'filterType': 'String',
    'type': 'select',
    'query': DASHBOARD_REPORT_FILTER_OPTIONS_QUERY,
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'trader',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="User" id="open-trades/user" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="User" id="open-trades/user" />,
    ...DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES['trader'],
  },
  {
    'name': 'customer',
    'filterType': 'String',
    'type': 'select',
    'query': DASHBOARD_REPORT_FILTER_OPTIONS_QUERY,
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'customer',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Customer" id="open-trades/customer" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Customer" id="open-trades/customer" />,
    ...DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES['customer'],
  },
  {
    'name': 'location',
    'filterType': 'String',
    'type': 'select',
    'query': DASHBOARD_REPORT_FILTER_OPTIONS_QUERY,
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'location',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Location" id="open-trades/location" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Location" id="open-trades/location" />,
    ...DASHBOARD_REPORT_FILTER_OPTIONS_QUERIES['location'],
  },
  {
    'name': 'physicalId',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'iLike',
    'queryField': 'physicalId',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Physical ID" id="open-trades/physicalId" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Physical ID" id="open-trades/physicalId" />,
  },
  {
    'name': 'tradeStyle',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'match',
    'queryField': 'tradeStyle',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Style" id="open-trades/trade-style" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Style" id="open-trades/trade-style" />,
  },
  {
    'name': 'legId',
    'filterType': 'String',
    'type': 'text',
    'clearBtn': true,
    'operator': 'match',
    'queryField': 'legId',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Leg ID" id="open-trades/leg-id" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Leg ID" id="open-trades/leg-id" />,
  },
  {
    'name': 'mtmInTradeCcy',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'queryField': 'mtmInTradeCcy',
    'placeHolder': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="MtM in trade currency" id="open-trades/mtmInTradeCcy" />,
    'label': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="MtM in trade currency" id="open-trades/mtmInTradeCcy" />,
  },
];

export const NAV_FILTERS_LIST_TRADES = [
  {
    'name': 'barrierKnocked',
    'filterType': 'String',
    'type': 'checkbox',
    'operator': 'eq',
    'queryField': 'barrierKnocked',
    'labelCheckbox': <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Barriers Knocked Today" id="open-trades/barrierKnocked" />,
    'poisiton': 'top',
    displayAlways: true,
  },
  {
    'name': 'expiryDays',
    'filterType': 'Number',
    'operator': 'eq',
    'type': 'number',
    'clearBtn': true,
    'size': 'sm',
    'queryField': 'expiryDays',
    'placeHolder': 'All',
    'poisiton': 'top',
    'min': 0,
    displayAlways: true,
  },
];

export const OPEN_TRADES_FORM_EDIT = '@OPEN_TRADES/FORM/EDIT';
