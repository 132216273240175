import { timer, from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { tap, delay, takeWhile, defaultIfEmpty, map, switchMap, last, mapTo } from 'rxjs/operators';
import { COMMON_SCROLL_START, scrollToFinshAction } from 'redux/actions/common';

const scrollToPosition = (position) => {
  const step = 50;
  position = position >= 80 ? position - 80 : 0;
  return window.scrollTo(0, window.scrollY > position ? window.scrollY - step : position);
}

export const scrollTop = (position = 0) =>
  timer(100, 10)
    .pipe(
      tap(() => scrollToPosition(position)),
      takeWhile(() => window.scrollY > position && window.scrollY !== 0),
      defaultIfEmpty(),
      delay(50)
    );

export const scrollTopEpic = action$ =>
  action$.pipe(
    ofType(COMMON_SCROLL_START),
    map(({ payload }) => payload),
    switchMap((position) => {
      const y = position && position.y ? position.y : 0;
      return from(scrollTop(y).pipe(last()))
        .pipe(
          mapTo(scrollToFinshAction())
        )
    }));

export default combineEpics(
  scrollTopEpic
)
