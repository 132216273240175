import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Col, Row, FormText } from 'reactstrap';
import { Field } from 'redux-form';
import { TYPE_COMPONENT } from '../../fields';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';


const CapitalProtectionFormFields = props => {
  const {
    structures,
    currencyOptions,
    maturityOptions,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    formConfig,
    formFields,
    messages,
    basketTypeOptions,
    optionTypeOptions,
    capTypeOptions,
    barrierTypeOptions,
    change,
  } = props;
  const formFieldsOptions = {
    structures,
    currencyOptions,
    maturityOptions,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    basketTypeOptions,
    optionTypeOptions,
    capTypeOptions,
    barrierTypeOptions,
  };
  const readOnlyArray = formConfig && formConfig.readOnly ? formConfig.readOnly : [];
  return (
    formFields ?
      <Fragment>
        <Row className="mb-3">
          {formFields.map((field, index) => {
            const { name, fieldType, type, colOptions = { xs: 12 }, optionsName, label, component: FieldComponent, ...rest } = field;
            const options = optionsName && formFieldsOptions[optionsName] ? formFieldsOptions[optionsName] : [];
            if (fieldType === TYPE_COMPONENT) {
              return (
                <Col key={index} {...colOptions}>
                  <FieldComponent change={change}/>
                </Col>
              );
            }
            const readOnly = readOnlyArray.length ? readOnlyArray.includes(name) : false;
            return (
              formConfig[name] !== false ?
                <Col key={name} {...colOptions}>
                  <div className="d-flex align-items-center mb-4 app-form-row">
                    {label ?
                      <div className="pre-label app-label">
                        {label}
                      </div>
                      : null}
                    <div className="form-group-row">
                      <Field
                        name={name}
                        options={options}
                        type={type}
                        component={FieldComponent}
                        {...rest}
                        readOnly={readOnly}
                        change={change} 
                      />
                      {messages && messages[name] ?
                        <FormText color="warning" className="form-control-alert font-sm">{messages[name]}</FormText>
                        : null
                      }
                    </div>
                  </div>
                </Col>
                : null
            )
          })}
        </Row>
      </Fragment>
      : null
  )
}

CapitalProtectionFormFields.defaultProps = {
  formConfig: {},
  messages: {},
};

CapitalProtectionFormFields.propTypes = {
  formConfig: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch)  => bindActionCreators({ change }, dispatch);

const connector = connect(null, mapDispatchToProps);

export default connector(CapitalProtectionFormFields);
