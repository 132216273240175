import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import ChoosePricinigHistoryType from './types/ChoosePricinigHistoryType';
import isEqual from 'lodash/isEqual';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export const isPricingInActive = ({ expired, marketDataDisabled }) => expired || marketDataDisabled;

class PricingHistoryItem extends Component {

  selectTrail = event => {
    event.stopPropagation();
    const { item, onClose, selectTrail } = this.props;
    if (!isPricingInActive(item)) {
      onClose();
      selectTrail(item);
    }
  }

  shouldComponentUpdate(nextProps) {
    // For performance. I don't expect changing history each time.
    return this.props.isSelected !== nextProps.isSelected || this.props.screenSize !== nextProps.screenSize || !isEqual(this.props.displayColumns, nextProps.displayColumns);
  }

  render() {
    const { item, index, isSelected, screenSize, displayColumns } = this.props;
    const isDisabled = isPricingInActive(item);
    const cardClassName = 'card-pricing-history app-table-row'
      + (isSelected ? ' pricinig-history-active' : '')
      + (isDisabled ? ' disabled' : '');
    return (
      item ? <CSSTransition classNames="trail-appear" timeout={500} in>
        <div id={`item-pricnig-history-${index}`} className={cardClassName} onClick={this.selectTrail}>
          <ChoosePricinigHistoryType {...item} screenSize={screenSize} displayColumns={displayColumns} />
          {isDisabled ? <UncontrolledTooltip placement="top" target={`item-pricnig-history-${index}`}>
            <FormattedMessage id="common/no-re-pricing"
              defaultMessage="Re-pricing is not available" />
          </UncontrolledTooltip>
            : null}
        </div>
      </CSSTransition> : null
    );
  }
}

PricingHistoryItem.fragments = {
  onClose: PropTypes.func,
}

export default PricingHistoryItem;
