import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import uniqueId from 'lodash/uniqueId';
import { normalizeId } from 'pages/orders/utils';
import AddIcon from '@material-ui/icons/AddBox';
import { miniAccumulatorCardCopy } from 'redux/actions/accumulator-mini';

const CopyCardControl = ({ cardId, miniAccumulatorCardCopy }) => {
  const copyHandler = () => miniAccumulatorCardCopy(cardId, `${uniqueId('copy')}-${cardId}`);

  return (
    <div>
      <Button id={`pricing-mini-copy-${normalizeId(cardId)}`} color="link" size="sm" className="btn-icon" onClick={copyHandler}>
        <AddIcon />
      </Button>
      <UncontrolledTooltip placement="top" target={`pricing-mini-copy-${normalizeId(cardId)}`}>
        <FormattedMessage id="price.copy-card"
          defaultMessage="Copy Card" />
      </UncontrolledTooltip>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  miniAccumulatorCardCopy,
}, dispatch);

export default compose(
  connect(null, mapDispatchToProps),
)(CopyCardControl);
