import { DATE_FORMAT_INPUT } from 'constants.js';
import moment from 'moment';

export const normalizeName = (value : string) => {
  return value.replace(/ +(?= )/g, '');
}

export const normalizeDate = (value: string | undefined, format = DATE_FORMAT_INPUT) =>
  (value ? moment(value, format).utc(!!value) : null);

/**
 * Normalise values to number
 * @param value
 * @param defaultValue if NaN is returned then this value is returned e.g. if you wanted to return 0 for undefined/NaN values
 */
export const normaliseValueToNumber = (value: string | number | undefined, defaultValue?: number | null)=>  {
  const number = typeof value === 'number' ? value : parseFloat(( value || '').replace(/,/g, ''));
  if (isNaN(number)) {
    return defaultValue
  }
  return number;
}

export const normalizeZeroToNull = (value: string | number ) => Number(value) > 0 ? value : null
