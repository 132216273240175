import { getSortLabelOptions } from 'components/tables/utils';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const TRADES_SORTING = [
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Select" id="open-trades/select" />,
    key: 'select',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Type" id="open-trades/tradeType" />,
    key: 'tradeType',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="External ID" id="open-trades/externalId" />,
    key: 'externalId',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Name" id="open-trades/underlyingName" />,
    key: 'underlyingName',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Date" id="open-trades/tradeDate" />,
    key: 'tradeDate',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Buy/Sell" id="open-trades/buySell" />,
    key: 'buySell',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Name" id="open-trades/tradeName" />,
    key: 'tradeName',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Currency" id="open-trades/currency" />,
    key: 'currency',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delivery Month" id="open-trades/deliveryMonth" />,
    key: 'deliveryMonth',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Start Date" id="open-trades/startDate" />,
    key: 'startDate',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Last Month Asian" id="open-trades/lastMonthAsian" />,
    key: 'lastMonthAsian',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Expiry Date" id="open-trades/expiryDate" />,
    key: 'expiryDate',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Strike With Units" id="open-trades/strikeWithUnits" />,
    key: 'strikeWithUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulation Level" id="open-trades/accumulationLevel" />,
    key: 'accumulationLevel',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Barrier Level" id="open-trades/barrierLevel" />,
    key: 'barrierLevel',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remainder Level" id="open-trades/remainder-level" />,
    key: 'remainderLevel',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Min" id="open-trades/min" />,
    key: 'min',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max" id="open-trades/max" />,
    key: 'max',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Volume In Client Units" id="open-trades/volumeInClientUnits" />,
    key: 'volumeInClientUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long" id="open-trades/notionalLong" />,
    key: 'notionalLong',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short" id="open-trades/notionalShort" />,
    key: 'notionalShort',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Long Units" id="open-trades/notional-long-units" />,
    key: 'notionalLongUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Notional Short Units" id="open-trades/notional-short-units" />,
    key: 'notionalShortUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Total" id="open-trades/premium-total" />,
    key: 'premiumTotal',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Premium Per Unit" id="open-trades/premium-per-unit" />,
    key: 'premiumPerUnit',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Total Fixings" id="open-trades/total-fixings" />,
    key: 'totalFixings',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Remaining Fixings" id="open-trades/remaining-fixings" />,
    key: 'remainingFixings',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Underlying Price" id="open-trades/underlyingPrice" />,
    key: 'underlyingPrice',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Lots" id="open-trades/accumulated-lots" />,
    key: 'accumulatedLots',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Accumulated Units" id="open-trades/accumulated-units" />,
    key: 'accumulatedUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Quantity Accumulated" id="open-trades/max-quantity-accumulated" />,
    key: 'maxQuantityAccumulated',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Max Remaining Quantity" id="open-trades/max-remaining-quantity" />,
    key: 'maxRemainingQuantity',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta In Lots" id="open-trades/deltaInLots" />,
    key: 'deltaInLots',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Delta Units" id="open-trades/delta-units" />,
    key: 'deltaUnits',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Net" id="open-trades/net" />,
    key: 'net',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Bid" id="open-trades/bid" />,
    key: 'bid',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Mid" id="open-trades/mid" />,
    key: 'mid',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ask" id="open-trades/ask" />,
    key: 'ask',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Ticker" id="open-trades/ticker" />,
    key: 'ticker',
    displayAllways: true,
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="User" id="open-trades/user" />,
    key: 'trader',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Customer" id="open-trades/customer" />,
    key: 'customer',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Location" id="open-trades/location" />,
    key: 'location',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Physical ID" id="open-trades/physical-id" />,
    key: 'physicalId',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Trade Style" id="open-trades/trade-style" />,
    key: 'tradeStyle',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="Leg ID" id="open-trades/leg-id" />,
    key: 'legId',
  },
  {
    label: <FormattedMessage children={msg => <>{msg}</>} defaultMessage="MtM in trade currency" id="open-trades/mtmInTradeCcy" />,
    key: 'mtmInTradeCcy',
  },
];

export const FILTERED_TRADES_SORTING = TRADES_SORTING
  .filter(({key}) => key !== 'select');

export const DISPLAY_COLUMNS_TRADES_SORTING = FILTERED_TRADES_SORTING
  .filter(({ displayAllways }) => !displayAllways);

export const getSortLabel = getSortLabelOptions(FILTERED_TRADES_SORTING);
