import gql from 'graphql-tag';

export const FETCH_PRICING_MARKET_DATA = gql`query pricingAccumulatorMarketDataQuery ($id: ID!) {
  pricingAccumulatorMarketData(id: $id) {
    id
    matrix {
      edges {
        node {
          id
          price
          date
        }
      }
    }
  }
}`;
