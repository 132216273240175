import { toggleOpen } from 'components/layout/header/ProfileDropdown';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import TableFilterDropdownListItem from './TableFilterDropdownListItem';
import isEqual from 'lodash/isEqual';

// TODO: add clear all btn
class TableFilterDropdownList extends Component {
  state = {
    isOpen: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { list } = this.props;
    const { list: nextList } = nextProps;
    const { isOpen } = this.state;
    const { isOpen: nextStateIsOpen } = nextState;
    return !isEqual(list, nextList) || nextStateIsOpen !== isOpen;
  }

  toggle = toggleOpen.bind(this);

  render() {
    const { onSelect, list } = this.props;
    const visibleItems = list.filter(({ isVisible }) => !isVisible);
    const { isOpen } = this.state;
    return (
      <div className="filter-wrapper">
        {list && visibleItems && visibleItems.length && onSelect ?
          <Dropdown key="filterDropDown" isOpen={isOpen} toggle={this.toggle} className="filter-dropdown">
            <DropdownToggle nav color="link">
              <FormattedMessage id="forms/filter.add-new"
                defaultMessage="Add Filter" />
            </DropdownToggle>
            <DropdownMenu right>
              {visibleItems
                .map(item => <TableFilterDropdownListItem key={item.name} onSelect={onSelect} {...item} />)}
            </DropdownMenu>
          </Dropdown>
          :
          null
        }
      </div>
    );
  }
}

TableFilterDropdownList.propTypes = {
  list: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TableFilterDropdownList;
