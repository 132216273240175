import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
const validateStringMinusDotRegExp = new RegExp('^[-]$|^[-+]?[0-9]*[.]$[0-9]');
const validateNumberStrRegExp = new RegExp('^[-+]?[0-9]*[.,]?[0-9]+$');
const lastDot = new RegExp('^((?!-).)*[.]$');
const lastDotZero = new RegExp('^((?!-).)*[.]0$');

const validateStringMinusDot = (str) => validateStringMinusDotRegExp.test(str);

const validateNumberStr = (str) => validateNumberStrRegExp.test(str);

const validateLastDot = (str) => lastDot.test(str)
const validateLastDotAndZero = (str) => lastDotZero.test(str)

export const inputFormatterNumber = (intl) =>
  (value) => {
    if (!!value && typeof value === 'number') {
      return intl.formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      value = '';
    }
    return '' + value;
  };

export const normalizePriceInput = (value, action) => {
  if (validateLastDot(value) || validateLastDotAndZero(value)) {
    if(action === 'onBlur') {
      const number = value ? value.toString().replace(/[^\d.-]/g, "") : null;
      return Number(parseFloat(number).toFixed(2));
    }
    return value;
  }
  const number = value ? value.toString().replace(/[^\d.-]/g, "") : null;
  return Number(parseFloat(number).toFixed(2));
}

export const maskPriceInput = (value, intl, options) => {
  let {
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options;
  value = value.toString().replace(/[^\d.-]/g, '');

  let number = value.split('.');

  if (validateLastDot(value)) {
    const valEndNumber = (number.length > 2) ? parseInt(number[1]) : null;
    const valEnd = !isNaN(valEndNumber) && isNumber(valEndNumber) ? valEndNumber : '';
    return intl.formatNumber(number[0], {
      minimumFractionDigits,
      maximumFractionDigits,
    }) + '.' + valEnd;
  }

  if (value && value.length > 1 && value[0] === '-' && isNaN(Number(value))) {
    return value[0];
  }

  if (value === '-0') {
    return value;
  }

  if (number.length > 2) {
    minimumFractionDigits = 2;
  }

  if (number[1] === '0') {
    minimumFractionDigits = 1;
  }

  if (validateNumberStr(value) && !validateStringMinusDot(value)) {
    return intl.formatNumber(value, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits,
    });
  }

  return value;
}

export const typeFormatter = (type) => {
  if (!type) return type;

  return type[0].toUpperCase() + type.slice(1);
}
