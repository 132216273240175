import { AUTOCALLABLE_FORM_FIELDS_KEYS } from 'pages/structured-products/components/forms/types/autocallable/fields';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';

import { AUTOCALL_VALUE } from 'constants.js';

export const handleVisibleFormFieldsAutocallble = (formValues = {}, fields = AUTOCALLABLE_FORM_FIELDS_KEYS) => {
  const changedFields = clone(fields);

  if ((!formValues) || isEmpty(formValues)) return changedFields;
  let readOnly = ['downsideLeverage'];

  const couponType = formValues.couponType ? formValues.couponType.toLowerCase() : null;
  if (couponType === 'guaranteed') {
    changedFields['memoryCoupon'] = false;
    changedFields['couponTriggerLevel'] = false;
    changedFields['snowball'] = false;
  }

  const solveFor = formValues.solveFor ? formValues.solveFor.toLowerCase() : null;
  if (solveFor === 'coupon') {
    changedFields['couponLevel'] = false;
  } else if (solveFor === 'reoffer') {
    changedFields['reoffer'] = false;
  }

  const barrierType = formValues.barrierType ? formValues.barrierType.toLowerCase() : null;
  if (barrierType === 'none' || barrierType === 'vanilla') {
    changedFields['barrierLevel'] = false;
  }

  if(barrierType === 'none' || barrierType === 'european') {
    changedFields['oneStar'] = true;
    const oneStar = Boolean(formValues.oneStar);
    changedFields['oneStarThreshold'] = oneStar;
  } else {
    changedFields['oneStar'] = false;
    changedFields['oneStarThreshold'] = false;
  }

  const autocall = formValues.autocall ? formValues.autocall.toLowerCase() : null;

  if (autocall === AUTOCALL_VALUE.NO) {
    changedFields['firstObservationIn'] = false;
    changedFields['autocallTriggerLevel'] = false;
    changedFields['stepDown'] = false;
    changedFields['step'] = false;
    changedFields['snowball'] = false;
  }

  const legalShape = formValues.legalShape ? formValues.legalShape.toLowerCase() : null;
  if (legalShape === 'swap') {
    changedFields['issuer'] = false;
  } else if (legalShape === 'option' || legalShape === 'warrant') {
    changedFields['floatingCurve'] = false;
    changedFields['spread'] = false;
    changedFields['issuer'] = false;
  } else {
    changedFields['floatingCurve'] = false;
    changedFields['spread'] = false;
  }

  if (autocall === AUTOCALL_VALUE.YES) {
    const stepDown = Boolean(formValues.stepDown);
    changedFields['step'] = stepDown;
  }

  if (autocall === AUTOCALL_VALUE.ISSUER_CALLABLE) {
    changedFields['snowball'] = false;
    changedFields['autocallTriggerLevel'] = false;
    changedFields['stepDown'] = false;
    changedFields['step'] = false;
  }


  const snowball = Boolean(formValues.snowball)
  if (snowball && couponType !== 'guaranteed') {
    readOnly.push('couponTriggerLevel');
  }

  changedFields['couponDirection'] = false;
  if(solveFor === 'coupon' && ['warrant', 'option', 'swap'].includes(legalShape)) {
    changedFields['couponDirection'] = true;
  }

  // @TODO: update form config object to change dynamic properties
  // min, max ranges
  changedFields['readOnly'] = readOnly;

  return changedFields;
}
