import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import { PRICINIG_ORDER_TYPES, TYPE_ICEBERG, TYPE_STOP_LIMIT, TYPE_MARKET, TYPE_OCO, TYPE_LIMIT } from 'components/trade/constants.js';
import { STRUCTURE_SWAP, STRUCTURE_MINIFUTURE } from 'constants.js';

const OrderTypeField = ({ structure, isCombinedCard, overriddenOrderTypes, xs = 12, sm = 4 }) => {

  let orderTypes;
  if (overriddenOrderTypes) {
    orderTypes = overriddenOrderTypes;
  } else {
    const filterIsMinifuture = structure?.toLowerCase() !== STRUCTURE_MINIFUTURE.toLowerCase();
    const filterIsSwap = structure?.toLowerCase() === STRUCTURE_SWAP.toLowerCase();

    orderTypes = PRICINIG_ORDER_TYPES
      .filter(x => filterIsMinifuture || (x.value !== TYPE_ICEBERG && x.value !== TYPE_STOP_LIMIT))
      .filter(x => filterIsSwap || (x.value !== TYPE_OCO))
      .map((item) => {
        if (isCombinedCard && item.value !== TYPE_MARKET && item.value !== TYPE_LIMIT) {
          return {
            ...item,
            disabled: true
          }
        }
        return item;
      });
  }

  return (
    <Col xs={xs} sm={sm}>
      <Field
        name="orderType"
        className="orderType"
        label={<FormattedMessage id="trade/orderType.label" defaultMessage="Order Type" />}
        justValue={true}
        component={InlineSelectInput}
        options={orderTypes}
        clearable={false}
      />
    </Col>
  );
}


export default OrderTypeField;
