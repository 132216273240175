import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ROUTE_MOCKED_DASHBOARD, ROUTE_DASHBOARDS } from 'pages/constants';

class NavDashboards extends PureComponent {
  render() {
    const { list } = this.props;
    return (
      <NavItem className="tour-page-dashboard">
        <NavLink tag={Link} activeclassname="active" className="text-uppercase text-bolder" to={(list && list.length) ? ROUTE_DASHBOARDS : ROUTE_MOCKED_DASHBOARD}>
          <FormattedMessage id="header.dashboard.label"
            defaultMessage="Dashboard"
          />
        </NavLink>
      </NavItem>
    )
  }
}

NavDashboards.propTypes = {
  list: PropTypes.array.isRequired,
};

export default NavDashboards;
