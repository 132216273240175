
import { CUSTOM_PRICING_NEW_ITEM } from 'redux/actions/custom-requests';

export type ICustomRequestsReducerState = {
  submitted: Object | null,
}

const CUSTOM_REQUESTS_INIT_STATE: ICustomRequestsReducerState = {
  submitted: null,
};

export default function customRequestsReducer(state: ICustomRequestsReducerState = CUSTOM_REQUESTS_INIT_STATE, action: Action): ICustomRequestsReducerState {
  switch (action.type) {
    case CUSTOM_PRICING_NEW_ITEM:
      return { ...state, submitted: action.payload };

    default:
      return state;
  }
}
