import { combineEpics } from 'redux-observable';
import changes from './changes';
import reset from './reset';
import options from './options/';
import submit from './submit';
import schedule from './schedule';

export default combineEpics(
  changes,
  reset,
  options,
  submit,
  schedule,
);
