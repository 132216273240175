import { compareValues } from './configs';

export const LIMITATION_RELATION_SWAP = [
  {
    key: 'comomodity',
    filled: true,
    limitationKey: 'commodityContracts',
    sheetExtractor: record => record.commodity,
    compare: compareValues,
  },
];
