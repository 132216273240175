import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { normalizePriceInput } from "../../../../utils/formformaters";
import isNumber from 'lodash/isNumber';

const rednerMessage = (price, notional, currency) => {
  notional = notional || 1;
  return price >= 0 ? (
    <div className="text-output text-warning">
      <FormattedMessage
        id="alert/price-calculated-success-pays"
        defaultMessage="Client pays"
      /> {currency} <FormattedNumber
        value={price}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      /> <FormattedMessage id="alert/price-per-lot" defaultMessage="per lot" />
    </div>
  ) : (
    <div className="text-output text-success">
      <FormattedMessage
        id="alert/price-calculated-success-receives"
        defaultMessage="Client receives"
      /> {currency} <FormattedNumber
        value={-price}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      /> <FormattedMessage id="alert/price-per-lot" defaultMessage="per lot" />
    </div>
  );
};

export function priceMessageShort(price, notional, currency) {
  price = parseFloat(normalizePriceInput(price));
  return !!price && isNumber(price)
    ? rednerMessage(price, notional, currency)
    : null;
}
