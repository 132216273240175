import WarningIcon from '@material-ui/icons/Warning';
import { reloadPage } from 'components/errorBoundary/ErrorBoundary';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createAlert } from 'react-redux-alerts';
import { Alert, Button } from 'reactstrap';
import { API_FAIL, FE_CACHED, USER_ONLINE_STATUS, USER_TOKEN_EXPIRED } from 'redux/errors/actions';
import './Errors.scss';

const Errors = () => {
  return (
    <div className="app-errors">
      <UserTokenExpiredAlert />
      <ApiConnectionAlert />
      <ClearCachelert />
      <UserOnlineStatus />
    </div>
  );
};

const UserTokenExpiredAlert = createAlert({
  alertName: USER_TOKEN_EXPIRED
})(({ close }) => (
  <Alert color="danger" toggle={close}>
    <div className="alert-icon">
      <WarningIcon />
    </div>
    <FormattedMessage id="alert/session-expired"
      defaultMessage="Session token is expired or invalid" />
  </Alert>
));

const ApiConnectionAlert = createAlert({
  alertName: API_FAIL,
})(({ close }) => (
  <Alert color="danger" toggle={close}>
    <div className="alert-icon">
      <WarningIcon />
    </div>
    <FormattedMessage id="alert/server-error"
      defaultMessage="Server returned an error. Please contact us."
    />
  </Alert>
));

const ClearCachelert = createAlert({
  alertName: FE_CACHED,
})(({ close }) => (
  <Alert color="warning" toggle={close}>
    <div className="alert-icon">
      <WarningIcon />
    </div>
    <div className="mb-3">
      <FormattedMessage id="alert/features"
        defaultMessage="New features have been released, please update." />
    </div>
    <div className="text-center">
      <Button color="primary" onClick={reloadPage} size="sm">
        <FormattedMessage id="form.custom-pricing.submit"
          defaultMessage="Update" />
      </Button>
    </div>
  </Alert>
));

const UserOnlineStatus = createAlert({
  alertName: USER_ONLINE_STATUS,
})(({ message }) => {
  const { online } = message;
  return (
    <Alert color="danger" >
      <div className="alert-icon">
        <WarningIcon />
      </div>
      {!online ?
        <FormattedMessage id="alert/internet-offline"
          defaultMessage="Your device lost Internet connection." />
        : null}
    </Alert>
  )
});

export default Errors;
