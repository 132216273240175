import apolloClient from 'apollo.js';
import gql from 'graphql-tag';


const BULLET_STRIP_CREATE_CARD_MUTATION = gql`mutation createBulletStripCardMutation(
  $direction: String!
  $commodityCode: String!
  $commodityContract: String!
  $baseCurrency: String!
  $fxCode: String
) {
  createBulletStripCard (
    direction: $direction
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    quotedCurrency: $baseCurrency
    fxCode: $fxCode
  ) {
    id
    termCurrency
    quotedCurrency
    disableCompo
    priceForCalcSpread
    isSpot
    isMetal
    structure
    status
    unit
    unitType
    bloombergTicker
    view
    firstMonth
    lastMonth
    quantity
    legs {
      notional
      contractExpiry
      expiryDate
    }
  }
}`;

export const createBulletStripCardMutation = ({
    direction,
    commodityCode,
    commodityContract,
    baseCurrency,
    fxCode,
    balmo = true
  }) => {
  return apolloClient.mutate({
    mutation: BULLET_STRIP_CREATE_CARD_MUTATION,
    variables: {
      direction,
      commodityCode,
      commodityContract,
      baseCurrency,
      fxCode,
      balmo
    },
  })
    .then(response => response.data.createBulletStripCard);
}

const BULLET_STRIP_UPDATE_CARD_MUTATION = gql`mutation updateBulletStripCardMutation(
  $id: String!
  $commodityCode: String!
  $baseCurrency: String!
  $termCurrency: String
  $fxCode: String
  $unit: String!
  $unitType: String!
  $firstMonth: DateString!
  $lastMonth: DateString!
  $notional: Float!
  $legs: [BulletStripCardLegArgs]
) {
  updateBulletStripCard (
    id: $id
    commodityCode: $commodityCode
    quotedCurrency: $baseCurrency
    termCurrency: $termCurrency
    fxCode: $fxCode
    unit: $unit
    unitType: $unitType
    firstMonth: $firstMonth
    lastMonth: $lastMonth
    notional: $notional
    legs: $legs
  ) {
    id
    status
    disableCompo
    bloombergTicker
    quantity
    firstMonth
    lastMonth
    unit,
    unitType
    quotedCurrency
    termCurrency
    legs {
      notional
      contractExpiry
      expiryDate
    }
  }
}`;

export const updateBulletStripCardMutation = ({
    id,
    commodityCode,
    baseCurrency,
    termCurrency,
    fxCode,
    unit,
    unitType,
    firstMonth,
    lastMonth,
    notional,
    legs,
  }) => {
  const variables = {
    id,
    commodityCode,
    baseCurrency,
    termCurrency,
    fxCode,
    unit,
    unitType,
    firstMonth,
    lastMonth,
    notional,
    legs,
  };
  return apolloClient.mutate({
    mutation: BULLET_STRIP_UPDATE_CARD_MUTATION,
    variables,
  })
    .then(response => response.data.updateBulletStripCard);
}

const BULLET_STRIP_DELETE_CARD_MUTATION = gql`mutation deleteBulletStripCardMutation(
  $id: String!
) {
  deleteBulletStripCard (
    id: $id
  )
}`;

export const deleteBulletStripCardMutation = id => {
  return apolloClient.mutate({
    mutation: BULLET_STRIP_DELETE_CARD_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

const BULLET_STRIP_REQUEST_PRICE_MUTATION = gql`mutation bulletStripPriceRequestMutation(
  $id: String!
) {
  bulletStripPriceRequest (
    id: $id
  ) {
    id
    status,
  }
}`;

export const requestPriceMutation = ({
  id,
}) => {
  return apolloClient.mutate({
    mutation: BULLET_STRIP_REQUEST_PRICE_MUTATION,
    variables: {
      id,
    },
  }).then(response => response.data.bulletStripPriceRequest);
}

export const BULLET_STRIP_MONTH_LIST_QUERY =  gql`query bulletStripMonthOptionsQuery (
  $commodityCode: String!
) {
  bulletStripMonthOptions(
    commodityCode: $commodityCode
  ) {
    label
    value
    expiryDate
  }
}`;
