import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Close from '@material-ui/icons/Close';
import isFunction from 'lodash/isFunction';
import ButtonRemove from 'components/controls/ButtonRemove';
import InputFormComponentFactory from 'pages/price/InputFormComponentFactory';
import FilterControlQuery from '../../form/filters/FilterControlQuery';
import './tableFilter.scss';
import TableFilterFormControl from './TableFilterFormControl';

class TableFilterForm extends Component {

  constructor(args) {
    super(args);
    this.state = {
      filters: args.filters
        .map((filter) => ({
          ...filter,
          isVisible: false
        }))
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      filters: props.filters
    }
  }

  changeFilter = (field = {}) => {
    const { onChangeFilter } = this.props;
    if (!isFunction(onChangeFilter)) return;
    const { name: key, value, operator } = field;
    const sendData = field ? { key, value, operator } : null;
    onChangeFilter(sendData);
  }

  toggleFilter = (name) => {
    const { filters } = this.state;
    const { changeFieldValue, formName, toggleFilter } = this.props;
    const index = filters.findIndex(item => item.name === name);
    if (filters[index].isVisible) {
      this.changeFilter({
        name: filters[index].name,
        value: null
      });
      changeFieldValue(formName, name, '');
    }
    toggleFilter(name);
  }

  submit = () => false;

  render() {
    const { reset, handleSubmit, children } = this.props;
    const { filters } = this.state;
    const visibleFilters = filters.filter(({ isVisible }) => isVisible);
    return (
      <Form onReset={reset} noValidate className="filter-form" onSubmit={handleSubmit(this.submit)}>
        {isFunction(children) && children({ changeFilter: this.changeFilter })}
        {visibleFilters
          .filter((filter) => filter)
          .map((filter) => {
            const { query, name, queryResp, variables, optionsProvider, placeHolder } = filter;
            if (query) {
              return (
                <div key={name} className="filter-control-block">
                  <FilterControlQuery query={query} variables={variables}>
                    {data =>
                      <TableFilterFormControl
                        filter={filter}
                        data={data}
                        name={name}
                        optionsProvider={optionsProvider}
                        queryResp={queryResp}
                        placeHolder={placeHolder}
                        changeFilter={this.changeFilter} />}
                  </FilterControlQuery>
                  <ButtonRemove onRemove={this.toggleFilter} result={name} className={'remove'}>
                    <Close />
                  </ButtonRemove>
                </div>
              )
            }
            return (
              <div key={name} className="filter-control-block">
                <InputFormComponentFactory
                  placeHolder={filter.placeHolder}
                  key={`input-${name}`}
                  field={filter}
                  options={filter.options}
                  onChangeFilter={this.changeFilter}
                  selectedSingleOption={false}
                  preselectIfOneOption={true}
                  />
                <ButtonRemove onRemove={this.toggleFilter} result={name} className={'remove'}>
                  <Close />
                </ButtonRemove>
              </div>
            )
          })}
      </Form>
    );
  }
};

TableFilterForm.propTypes = {
  onChangeFilter: PropTypes.func,
  changeFieldValue: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({ changeFieldValue: change }, dispatch);

const mapStateToProps = (_, ownProps) => {
  const {
    formName: form = 'FORM_FILTER',
    initialValues,
    validate,
  } = ownProps || {};
  return {
    form,
    initialValues: initialValues || null,
    validate,
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true
  })
)(TableFilterForm);
