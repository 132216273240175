import LoggedLayout from 'components/layout/logged/LoggedLayout';
import ScrollToTop from 'components/ScrollToTop';
import { isThemeSelector } from 'components/theme/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { pathToClass } from 'utils/';
import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicRoutes from './routes/PublicRoutes';

const RootRoutes = props => {
  const { isAuthorized, history, isTheme } = props;
  const pathname = history?.location?.pathname;
  return (
    <div className={`page ${pathToClass(pathname)}`}>
      <ScrollToTop>
        {isAuthorized
          ?
          <LoggedLayout>
            <ProtectedRoutes />
          </LoggedLayout>
          : <PublicRoutes isTheme={isTheme} />
        }
      </ScrollToTop>
    </div>
  );
}

const isAuthorizedSelector = createSelector(
  state => state.auth,
  ({ isAuthorized }) => ({ isAuthorized })
);

const mapStateToProps = state => ({
  ...isAuthorizedSelector(state),
  ...isThemeSelector(state),
})

RootRoutes.defaultProps = {
  isAuthorized: false,
  isTheme: false,
};

RootRoutes.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isTheme: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(RootRoutes);
