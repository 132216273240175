import { COMMON_SCROLL_SET } from 'redux/actions/common';

const COMMON_STATE_INIT = {
  scrollTo: {
    x: 0,
    y: 0
  }
}

export default function commonReducer(state = COMMON_STATE_INIT, action) {
  switch (action.type) {
    case COMMON_SCROLL_SET:
      return { ...state, scrollTo: action.payload };
    default:
      return state;
  }
}
