import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import InlineInput from 'components/form/InlineInput';
import { STRUCTURE_MINIFUTURE } from 'constants.js';
import { SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';

const TotalSizeField = ({ formatMessage, isCombinedCard, structure, isMinifutureFx }) => {
  const id = structure?.toLowerCase() === STRUCTURE_MINIFUTURE.toLowerCase() ? 'trade/units.label' : 'trade/total-size.label';
  const defaultMessage = structure?.toLowerCase() === STRUCTURE_MINIFUTURE.toLowerCase() ? 'Nr. Of Units' : 'Total Size';
  const isAsianSwap = structure?.toLowerCase() === SWAP_ASIAN;
  return (
    <Col xs={12} sm={4}>
      <Field
        name="quantity"
        type="number"
        placeHolder={formatMessage({
          id,
          defaultMessage,
        })}
        label={<FormattedMessage id={id}
          defaultMessage={defaultMessage} />}
        step="1"
        disabled={isCombinedCard || isMinifutureFx || isAsianSwap}
        component={InlineInput}
      />
    </Col>
  );
}

export default TotalSizeField;
