import SwapFormValidate from './SwapFormValidate';
import { PRICING_FORM_SWAP_INITIAL_VALUES } from './constants';
import SwapForm from './SwapForm';
import { swapLimitationSelector } from './swapLimitationSelector';
import { SWAP_BULLET } from 'pages/price/output/asianSwap/constants';

const swapFormProps = (state, { swapType }) => {
  const props = swapLimitationSelector(state);
  return {
    ...props,
    component: SwapForm,
    enableCalendarSpread: state.price.enableCalendarSpread,
    isBulletStrip: state.auth?.account?.isBulletStrip,
    initialValues: { ...PRICING_FORM_SWAP_INITIAL_VALUES, swapType: swapType || SWAP_BULLET },
    validate: SwapFormValidate
  }
}

export default swapFormProps;
