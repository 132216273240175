export const compareValues = (a, b) => a === b;

export const LIMITATION_RELATION = [
  {
    key: 'structureType',
    filled: true,
    limitationKey: 'structureType',
    sheetExtractor: null,
    compare: compareValues,
  },
  {
    key: 'commodity',
    filled: true,
    limitationKey: 'commodity',
    sheetExtractor: record => record.commodity,
    compare: compareValues,
  },
  {
    key: 'contractExpiry',
    filled: true,
    limitationKey: 'contractExpiries',
    sheetExtractor: record => record.contractExpiry,
    compare: compareValues,
  },
  {
    key: 'expiration',
    filled: true,
    limitationKey: 'structureExpiredAt',
    sheetExtractor: record => record.expiryDate,
    compare: compareValues,
  },
  {
    key: 'payoutCurrency',
    filled: true,
    limitationKey: 'currency',
    sheetExtractor: record => (record.accumulatorUnderlyings && record.accumulatorUnderlyings.currency) ?
      record.accumulatorUnderlyings.currency : record.quotedCurrency,
    compare: compareValues,
  },
];
