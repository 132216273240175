import apolloClient from 'apollo.js';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import gql from 'graphql-tag';
import { SCHEDULE_FORM_FIELDS_SEND, SCHEDULE_DATE_FIELDS } from 'redux/epics/structured-products/form/schedule/';
import { mapNodes } from 'utils/';

export const QUERY_AUTOCALLABLE_SCHEDULE = gql`query autocallSchedule(
  $maturity: Int!
  $frequency: String!
  $firstObservationIn: String
  $observations: [PriceAutocallScheduleObservations]
  $schedule: [PriceAutocallScheduleData]
  $paymentDelay: Int!
  $couponTriggerLevel: Float
  $step: Float
  $autocall: Boolean!
  $autocallTriggerLevel: Float
  $couponType: String!
  $snowball: Boolean!
  $couponLevel: Float
  $barrierLevel: Float
  $barrierType: String
  $strikeLevel: Float
  $issuer: String
  $strikeDate: DateTime!
  $observationLag: Int!
  $paymentLag: Int!
  $underlyingIds: [String]
  $structure: String
) {
  autocallScheduleConnection (
    maturity: $maturity
    frequency: $frequency
    firstObservationIn: $firstObservationIn
    observations: $observations
    schedule: $schedule
    paymentDelay: $paymentDelay
    couponTriggerLevel: $couponTriggerLevel
    step: $step
    autocall: $autocall
    autocallTriggerLevel: $autocallTriggerLevel
    couponType: $couponType
    snowball: $snowball
    couponLevel: $couponLevel
    barrierLevel: $barrierLevel
    barrierType: $barrierType
    strikeLevel: $strikeLevel
    issuer: $issuer
    strikeDate: $strikeDate
    observationLag: $observationLag
    paymentLag: $paymentLag
    underlyingIds: $underlyingIds
    structure: $structure
    ) {
      edges {
      node {
        id
        observationDate
        redemptionDate
        autocallTriggerLevel
        couponTriggerLevel
        couponLevel
      }
    }
  }
}`;

export const getAutocallableSchedule = data => {
  const {
    maturity,
    frequency,
    firstObservationIn,
    observations,
    paymentDelay,
    couponTriggerLevel,
    autocall,
    step,
    autocallTriggerLevel,
    couponType,
    snowball,
    schedule,
    couponLevel,
    barrierLevel,
    barrierType,
    strikeLevel,
    issuer,
    strikeDate,
    observationLag,
    paymentLag,
    underlyingIds,
    structure,
  } = data;
  return apolloClient.query({
    query: QUERY_AUTOCALLABLE_SCHEDULE,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      maturity,
      frequency,
      firstObservationIn,
      observations,
      paymentDelay,
      couponTriggerLevel,
      autocall,
      step,
      autocallTriggerLevel,
      couponType,
      snowball,
      schedule,
      couponLevel,
      barrierLevel,
      barrierType,
      strikeLevel,
      issuer,
      strikeDate,
      observationLag,
      paymentLag,
      underlyingIds,
      structure
    }
  }).then(({ data }) => {
    return mapNodes(data.autocallScheduleConnection)
      .map((row) => {
        const scheduleRow = { ...row };
        SCHEDULE_FORM_FIELDS_SEND
          .forEach((key) => {
            if(!SCHEDULE_DATE_FIELDS.includes(key)) {
              const valueNumber = parseFloat(row[key]);
              const value = !isNaN(valueNumber) ? valueNumber : null;
              scheduleRow[key] = value;
            }
          });
        return scheduleRow;
      });
  })
}
