import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { apolloErrors } from 'apollo.js';
import { compose, bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm, reset, SubmissionError, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import round from 'lodash/round';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT, RECORD_EMPTY, STRUCTURE_SWAP } from 'constants.js';
import { formatDate2UI, filterTimeInForceOptions } from 'pages/price/PriceForm/utils';
import {
  TIME_IN_FORCE_OPTIONS, TIME_IN_FORCE_DEFAULT, ORDER_TYPES_DEFAULT, TYPE_MARKET, TYPE_LIMIT,
  PRICINIG_ORDER_TYPES, TIME_IN_FORCE_GTD, MIN_PRICE_VALIDATION,
  TYPE_OCO, TYPE_STOP_LIMIT, TIME_IN_FORCE_GTC, TIME_IN_FORCE_DAY
} from '../constants.js';
import TradeLabel from '../TradeLabel';
import { SWAP_ORDERS_FORM_INITIAL_VALUES } from './constants';
import { CREATE_SWAP_ORDER_MUTATION, CREATE_COMPO_SWAP_ORDER_MUTATION, CREATE_SWAP_ORDER_WITH_LEGS_MUTATION } from './query';
import { validateSwap as validate } from './swapTradeFormValidate';
import OrderAccount from 'components/orders/accounts/AccountsTrade';
import { injectIntl } from 'react-intl';
import OrderTypesRow from 'components/trade/OrderTypesRow';
import OrderTypeField from 'components/trade/components/OrderTypeField';
import ExpiryDateField from 'components/trade/components/ExpiryDateField';
import RenderLegs from 'components/trade/swap/RenderLegs';
import isNumber from 'lodash/isNumber';
import { getLivePrice } from 'components/trade/helpers';

const placeOrderArguments = (data, props) => {
  const {
    commodityCode,
    contractExpiry,
    contractExpirySecond,
    direction,
    quotedCurrency,
    contractCode,
    commodityContract,
    isCombinedCard,
    id: cardId,
    quoteId,
    rate,
    cmPrice,
    compoPrice,
    priceForCalcSpread,
    fxPriceForCalcSpread,
    cmPriceForCalcSpread,
    unitType,
    unit,
    price: livePrice,
    metalHedgeCost,
    metalMark,
    isSpot: isCardSpot
  } = props;
  const {
    price,
    quantity,
    timeInForce = TIME_IN_FORCE_DEFAULT.value,
    orderType = ORDER_TYPES_DEFAULT.value,
    stopPrice,
    maxShow,
    expiryDate,
    account,
    legs,
  } = data;

  if(orderType === TYPE_OCO) {
    return {
      mutation: CREATE_SWAP_ORDER_WITH_LEGS_MUTATION,
      variables: {
        commodityCode,
        structure: STRUCTURE_SWAP.toLowerCase(),
        cardId,
        contractExpiry,
        contractExpirySecond,
        orderType,
        timeInForce,
        expiryDate,
        quantity,
        priceForCalcSpread,
        legs,
        maxShow,
        slackIceClientsId: account,
        unitType,
        unit
      },
    }
  }

  if(isCombinedCard) {
    return {
      mutation: CREATE_COMPO_SWAP_ORDER_MUTATION,
      variables: {
        commodityCode,
        cardId,
        quantity,
        rate,
        cmPrice,
        price: orderType === TYPE_LIMIT ? price : compoPrice,
        structure: STRUCTURE_SWAP.toLowerCase(),
        quoteId,
        timeInForce,
        orderType,
        slackIceClientsId: account,
        fxPriceForCalcSpread,
        cmPriceForCalcSpread,
        metalHedgeCost,
        metalMark
      }
    }
  }
  return {
    mutation: CREATE_SWAP_ORDER_MUTATION,
    variables: {
      commodityCode,
      contractExpiry,
      contractExpirySecond,
      direction,
      price: livePrice || price,
      quotedCurrency,
      quantity,
      contractCode,
      commodityContract,
      timeInForce,
      orderType,
      stopPrice,
      maxShow,
      expiryDate,
      structure: STRUCTURE_SWAP.toLowerCase(),
      slackIceClientsId: account,
      priceForCalcSpread,
      unitType,
      unit,
      metalHedgeCost,
      metalMark,
      isCardSpot
    },
  };
}


class SwapTrade extends PureComponent {

  constructor(props) {
    super(props);
    const { price, form } = props;
    this.state = {
      submitted: false,
      price,
      formName: form,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { price } = props;
    const { price: statePrice } = state;
    return price !== statePrice ? { price } : null;
  }

  submit = async data => {
    const {
      client,
    } = this.props;
    try {
      const { variables, mutation } = placeOrderArguments(data, this.props)

      let response = await client.mutate({
        mutation,
        variables
      }).then((resp) => {
        if (
          (resp.data.createOrder && resp.data.createOrder.id)
          || (resp.data.createCompoOrder && resp.data.createCompoOrder.id)
          || (resp.data.createOrderWithLegs && resp.data.createOrderWithLegs.id)
        ) {
          this.runResetFormTimeout();
          this.setState({
            submitted: true
          });
        }
        return resp.data;
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  }

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(SWAP_ORDERS_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      handleSubmit,
      submitting,
      direction,
      contractExpiry,
      contractExpirySecond,
      commodityContract,
      error,
      firstName,
      lastName,
      formData,
      intl: { formatMessage },
      isCombinedCard,
      compoPrice,
      structure,
      filterDate,
      isSpot,
      isMetal
    } = this.props;

    let { invalid } = this.props;
    const { submitted, formName } = this.state;
    const { quantity, price, timeInForce, orderType, stopPrice, maxShow, expiryDate, maxExpiryDate } = formData;

    const showFormLabel = quantity && ((price !== '' && (isNumber(Number(price)) || contractExpirySecond)) || stopPrice || orderType === TYPE_MARKET || (orderType === TYPE_OCO)) && (parseFloat(quantity) > 0 || contractExpirySecond);
    const { label: orderTypeLable } = PRICINIG_ORDER_TYPES.find(item => item.value === orderType) || ORDER_TYPES_DEFAULT;

    if (isCombinedCard && (!compoPrice || compoPrice < MIN_PRICE_VALIDATION)) {
      invalid = true;
    }

    const overriddenOrderTypes = (isSpot && isMetal) ? PRICINIG_ORDER_TYPES.map(item => ({ ...item, disabled: (item.value === TYPE_MARKET || item.value === TYPE_LIMIT) ? false : true })) : null

    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage(isCombinedCard ? 'Order has been created successfully.' : 'Order is currently being reviewed by the Trading team. Please wait.')} />
        :
        <Fragment>
          <h1>
            {direction === 'buyer' ? 'Buy' : 'Sell'}&nbsp;-&nbsp;{commodityContract} - {contractExpiry ? formatDate2UI(contractExpiry) : 'Spot'}{contractExpirySecond ? `-${formatDate2UI(contractExpirySecond)}` : null}
          </h1>
          <MainErrorMessage error={error} />
          <Form id={`form-card-swap`} onSubmit={handleSubmit(this.submit)} noValidate>
            <Row className="row-size-xs">
              <OrderTypeField structure={structure} isCombinedCard={isCombinedCard} overriddenOrderTypes={overriddenOrderTypes} sm={4} />
              <Col xs={12} sm={4}>
                <Field
                  name="timeInForce"
                  className="timeInForce"
                  label={<FormattedMessage id="trade/timeInForce.label"
                    defaultMessage="Time In Force" />}
                  justValue={true}
                  component={InlineSelectInput}
                  options={filterTimeInForceOptions(TIME_IN_FORCE_OPTIONS, orderType, isCombinedCard || (isSpot && isMetal))}
                  clearable={false}
                />
              </Col>
              {timeInForce === TIME_IN_FORCE_GTD ? <ExpiryDateField formatMessage={formatMessage} maxExpiryDate={maxExpiryDate} filterDate={filterDate} /> : null}
            </Row>
            <OrderTypesRow {...this.props} />
            {orderType === TYPE_OCO ? <FieldArray name="legs" component={RenderLegs} {...this.props} /> : null}
            <Row>
              <Col xs={12}>
                <OrderAccount
                  direction={direction}
                  formName={formName}
                />
              </Col>
            </Row>

            <div className="mb-3">
              {showFormLabel ?
                <TradeLabel  {...this.props} price={price} stopPrice={stopPrice} orderType={orderType} quantity={quantity} maxShow={maxShow} />
                : RECORD_EMPTY}
            </div>

            <div className="mb-3">
              <FormattedMessage id="common/account"
                defaultMessage="User" />: {firstName} {lastName}
            </div>

            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.onCloseModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="submit"
                className={"text-uppercase" + (direction === 'buyer' ? ' btn-buy' : ' btn-sell')}
                onClick={this.trade}
                disabled={invalid || submitting ||
                  (timeInForce === TIME_IN_FORCE_GTD && !expiryDate)
                }>
                <FormattedMessage id="orders.send-limit"
                  defaultMessage={`Send ${orderTypeLable} order`} />
              </Button>
            </div>
          </Form>
        </Fragment>
    );
  }
}

SwapTrade.defaultProps = {
  quantity: 1,
  formData: {}
};

SwapTrade.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.number
};

export const SWAP_ORDERS_FORM = '@PRICINIG/SWAP/FORM_TRADE';

export const selectorFormValues = getFormValues(SWAP_ORDERS_FORM);

const mapStateToProps = (state, ownProps) => {
  const { id, price: selectedPrice, quantity, termCurrency, quotedCurrency, isSpot, isMetal } = ownProps;
  const isCombinedCard = termCurrency !== quotedCurrency;
  const orderType = SWAP_ORDERS_FORM_INITIAL_VALUES.orderType;
  const timeInForce = isCombinedCard || (isSpot && isMetal) ? TIME_IN_FORCE_GTC : TIME_IN_FORCE_DAY;
  const formData = selectorFormValues(state);
  const price = round(selectedPrice, 4) || null;
  const legsInit = {
    legs: [
      { price, orderType: TYPE_LIMIT, stopPrice: null },
      { price, orderType: TYPE_STOP_LIMIT, stopPrice: null },
    ],
  };

  const priceData = getLivePrice(id, isCombinedCard, formData, state);
  return {
    formData,
    isCombinedCard,
    ...priceData,
    initialValues: {
      isCombinedCard,
      quantity,
      price,
      ...SWAP_ORDERS_FORM_INITIAL_VALUES,
      ...legsInit,
      orderType,
      timeInForce
    }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  change
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: SWAP_ORDERS_FORM,
    enableReinitialize: true,
    validate,
  }),
  withApollo,
  injectIntl,
)(SwapTrade);
