export const TOURS = '@TOURS';
export const TOURS_RUN = `${TOURS}/START`;
export const TOURS_STOP = `${TOURS}/STOP`;
export const TOURS_GO_TO_STEP = `${TOURS}/GO_TO_STEP`;
export const TOURS_WELCOME = `${TOURS}/TOURS_WELCOME`;

export const toursStart = () => ({ type: TOURS_RUN });

export const toursStop = () => ({ type: TOURS_STOP });

export const goToStep = payload => ({
  type: TOURS_GO_TO_STEP,
  payload
});

export const toursWelcome = payload => ({
  type: TOURS_WELCOME,
  payload
});
