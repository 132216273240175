
import React from 'react';

const EmptyTableIcon = () => {
  return (
    <svg width="67" height="54" viewBox="0 0 67 54">
      <g fill="none" fillRule="evenodd" opacity=".6" transform="translate(2 1)">
        <path fill="#FFF" d="M32.175 8.425V6.283c0-2.68 2.278-4.871 5.062-4.871h14.207L64.35 13.607v2.142L51.444 3.554H37.237c-2.784 0-5.062 2.192-5.062 4.87z" />
        <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.7" d="M49.603 1.071v7.987s.213 4.198 4 4.198h9.145L49.603 1.07z" />
        <path fill="#FFF" d="M56.12 21.504H38.395c-.49 0-.89-.506-.89-1.123 0-.618.4-1.123.89-1.123H56.12c.49 0 .891.505.891 1.123 0 .617-.401 1.123-.89 1.123M56.12 26.91H38.395c-.49 0-.89-.562-.89-1.248 0-.688.4-1.25.89-1.25H56.12c.49 0 .891.562.891 1.25 0 .686-.401 1.249-.89 1.249" />
        <path stroke="#FFF" strokeLinecap="round" strokeWidth="2.7" d="M.085 45.421s1.727 4.18 5.765 4.18h25.071c4.179 0 6.686-4.18 6.686-4.18" opacity=".4" />
        <path stroke="#FFF" strokeWidth="2.7" d="M31.742 51.416H5.926C2.666 51.416 0 48.85 0 45.714V6.539C0 3.402 2.667.836 5.926.836h16.632l15.11 14.277v30.6c0 3.137-2.667 5.703-5.926 5.703z" />
        <path stroke="#FFF" strokeLinecap="round" strokeWidth="2.7" d="M31.34 9.196V5.4c0-2.65 2.252-4.816 5.005-4.816h14.05l12.762 12.06V38.49c0 2.65-2.252 4.817-5.005 4.817h-7.173" />
        <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.7" d="M22.558 1.106v9.182s.244 4.825 4.596 4.825h10.514L22.558 1.106z" />
        <path fill="#FFF" d="M29.088 24.6H8.714c-.564 0-1.025-.585-1.025-1.297 0-.713.461-1.296 1.025-1.296h20.374c.564 0 1.025.583 1.025 1.296 0 .712-.461 1.296-1.025 1.296M29.088 30.779H8.714c-.564 0-1.025-.633-1.025-1.406 0-.773.461-1.405 1.025-1.405h20.374c.564 0 1.025.632 1.025 1.405s-.461 1.406-1.025 1.406" />
        <ellipse cx="46.8" cy="43.471" fill="#FFF" rx="1.671" ry="1.672" />
        <path stroke="#FFF" strokeLinecap="round" strokeWidth="2.7" d="M42.204 43.471H38.42" />
      </g>
    </svg>
  );
};

export default EmptyTableIcon;
