import React from 'react';
import { FormGroup as ReactstarpFormGroup, Label } from 'reactstrap';
import FieldErrorMessage from './FieldErrorMessage';
import Warning from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

const FormGroup = props => {
  const {
    label,
    meta,
    input,
    children,
    type,
    showError,
    disabled,
  } = props;
  return (
    <ReactstarpFormGroup className={`form-group-${type}`} color={(meta.touched || meta.dirty) && meta.invalid && !disabled ? 'danger' : null}>
      {label ? <Label for={input.name}>{label}</Label> : null}
      {children}
      {showError && type !== 'checkbox' && meta.error && !disabled ? <FieldErrorMessage {...meta} /> : null}
      {type !== 'checkbox' && meta.touched && meta.invalid ?
        <span className="form-feedback-icon"><Warning /></span> :
        null}
    </ReactstarpFormGroup>
  );
};

FormGroup.defaultProps = {
  showError: true,
  disabled: false,
};

FormGroup.propTypes = {
  showError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FormGroup;
