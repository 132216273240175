import React, { PureComponent } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Alert } from "reactstrap";
import ServerErrorMessage from "./ServerErrorMessage";
// import ButtonRemove from 'components/controls/ButtonRemove';

const mainErrorMessageTypePropTypes = {
  errors: PropTypes.array,
  error: PropTypes.string,
};

type MainErrorMessageType = InferProps<typeof mainErrorMessageTypePropTypes>;

class MainErrorMessage extends PureComponent {
  state = {
    errors: [],
  };

  static getDerivedStateFromProps(props: MainErrorMessageType) {
    return {
      errors: props.error,
    };
  }

  removeMessage = (index) => {
    const { errors } = this.state;
    if (errors[index]) {
      errors.splice(index, 1);
      this.setState({
        errors: [...errors],
      });
    }
  };

  render() {
    const { errors } = this.state;
    return Array.isArray(errors) ? (
      <div className="errors-wrapper">
        {errors.map((error, index) => (
          <Alert key={index} color="danger">
            <ServerErrorMessage {...error} />
            {/* <ButtonRemove onRemove={this.removeMessage} result={index} className={'btn-clear d-inline-block'}>
              <span className="btn-clear">×</span>
            </ButtonRemove> */}
          </Alert>
        ))}
      </div>
    ) : null;
  }
}

export default MainErrorMessage;
