import { createSelector } from 'reselect';

export const extractSwapContract = createSelector(
  [
    state => state.pricings,
    state => state.commodity
  ],
  (pricings, contractName) => {
    const find = Array.isArray(pricings) && pricings.find(({ commodity }) => {
      return commodity === contractName;
    });
    return find;
  }
);
