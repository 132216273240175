import { PRICING_STRUCTURED_FORM, PRICING_STRUCTURED_FORM_SHEDULE } from 'pages/structured-products/components/forms/constants';
import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import { combineEpics } from 'redux-observable';
import { restoreStructuredFormUnderlyingsCreateListAction, structuredProductsFormConfigSet, structuredProductsResultsSet, structuredProductsScheduleSet, scheduleOptionsSet } from 'redux/actions/structured-products';
import { from, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { changeFormActionFilter } from 'utils/reduxFormSelector';
import { caseStrucutredProductsFormConfig, structuredProductsFormResetFilter } from './utils';
import { handleVisibleFormFields } from './visibleFields';

export const structuredFormResetEpic = (action$, state$) =>
  action$
    .pipe(
      filter(structuredProductsFormResetFilter),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      filter((formData) => formData && formData.structure),
      map((formData) => {
        const { structure } = formData;
        const { initialValues } = caseStrucutredProductsFormConfig(structure);
        return handleVisibleFormFields({ ...initialValues, structure });
      }),
      switchMap((fieldValues) => from([
        structuredProductsFormConfigSet(fieldValues),
        structuredProductsScheduleSet(null),
        structuredProductsResultsSet(null),
        scheduleOptionsSet(null),
        restoreStructuredFormUnderlyingsCreateListAction(),
      ])),
    );

export const resetStructuredFormResultsEpic = action$ =>
  merge(
    action$.pipe(filter(changeFormActionFilter(PRICING_STRUCTURED_FORM_SHEDULE))),
    action$.pipe(filter(changeFormActionFilter(PRICING_STRUCTURED_FORM))),
  )
    .pipe(
      map(({ payload }) => payload),
      distinctUntilChanged(),
      switchMap(() => from([
        structuredProductsResultsSet(null),
      ])),
    );

export default combineEpics(
  structuredFormResetEpic,
  resetStructuredFormResultsEpic,
);
