import React from 'react';
import { FormattedMessage } from 'react-intl';
import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { STRUCTURED_PRODUCTS_FORM_FIELDS, FIELD_TYPE_DEFAULT, STRUCTURED_PRODUCTS_FORM_NOTIONAL } from '../../fields';
import { ONE_STAR_THRESHOLD_MAX, ONE_STAR_THRESHOLD_MIN } from './constats';
import { InlineSelectInput, InlineInput, RadioGroup } from '@agile/react-components';
import { PRICING_FORM } from 'redux/actions/price';
import { Field } from 'redux-form';


export const maturityField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "maturity",
  label: <FormattedMessage id="form.price.fields.payout-maturity.label"
    defaultMessage="Maturity" />,
  placeHolder: 'Enter Maturity',
  justValue: true,
  action: PRICING_FORM,
  component: InlineSelectInput,
  optionsName: 'maturityOptions',
  colOptions: { xs: 12, sm: 6 }
};

export const autocallField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "autocall",
  label: <FormattedMessage id="form.price.fields.payout-autocall.label"
    defaultMessage="Autocall" />,
  placeHolder: 'Enter Autocall',
  justValue: true,
  component: RadioGroup,
  FieldComponent: Field,
  optionsName: 'autocallOptions',
  colOptions: { xs: 12 }
};

export const frequencyField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "frequency",
  label: <FormattedMessage id="form.price.fields.payout-frequency.label"
    defaultMessage="Frequency" />,
  placeHolder: 'Enter Frequency',
  justValue: true,
  action: PRICING_FORM,
  component: InlineSelectInput,
  optionsName: 'frequencyOptions',
  colOptions: { xs: 12, sm: 6 },
  showError: true,
};

export const firstObservationInField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "firstObservationIn",
  label: <FormattedMessage id="form.price.fields.first-observation-in.label"
    defaultMessage="First Observation in" />,
  placeHolder: 'Enter First Observation in',
  justValue: true,
  action: PRICING_FORM,
  component: InlineSelectInput,
  optionsName: 'firstObservationInOptions',
  colOptions: { xs: 12, sm: 6 }
};

export const autocallTriggerLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "autocallTriggerLevel",
  label: <FormattedMessage id="form.price.fields.autocall-trigger-level.label"
    defaultMessage="Autocall Trigger Level" />,
  placeHolder: 'Enter Autocall Trigger Level',
  type: "number",
  step: 1,
  min: 80,
  max: 150,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: { xs: 12, sm: 8 }
};

export const stepDownField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "stepDown",
  labelCheckbox: <FormattedMessage id="form.price.fields.step-down.label" defaultMessage="Step Down" />,
  type: "checkbox",
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 4
  }
};

export const stepField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "step",
  label: <FormattedMessage id="form.price.fields.step.label" defaultMessage="Step" />,
  placeHolder: 'Enter Step',
  type: "number",
  step: 1,
  min: 0,
  max: INPUT_NUMBER_MAX_VALUE,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 8
  }
};

export const couponTypeField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "couponType",
  label: <FormattedMessage id="form.price.fields.coupon-type.label" defaultMessage="Coupon Type" />,
  placeHolder: 'Enter Coupon Type',
  justValue: true,
  action: PRICING_FORM,
  component: InlineSelectInput,
  optionsName: 'couponTypeOptions',
  colOptions: {
    xs: 12,
    sm: 8
  }
};

export const memoryCouponField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "memoryCoupon",
  label: <FormattedMessage id="form.price.fields.payout-memory-coupon.label" defaultMessage="Memory Coupon" />,
  placeHolder: 'Enter Memory Coupon',
  justValue: true,
  component: RadioGroup,
  FieldComponent: Field,
  optionsName: 'memoryCouponOptions',
  colOptions: { xs: 12 }
};

export const couponTriggerLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "couponTriggerLevel",
  label: <FormattedMessage id="form.price.fields.coupon-trigger-level.label" defaultMessage="Coupon Trigger Level" />,
  placeHolder: 'Enter Coupon Trigger Level',
  type: "number",
  step: 1,
  min: 50,
  max: 150,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 8
  }
};

export const snowballField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "snowball",
  labelCheckbox: <FormattedMessage id="form.price.fields.snowball.label" defaultMessage="Snowball" />,
  type: "checkbox",
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 4
  }
};

export const solveForField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "solveFor",
  label: <FormattedMessage id="form.price.fields.solve-for.label" defaultMessage="Solve for" />,
  component: RadioGroup,
  FieldComponent: Field,
  justValue: true,
  optionsName: 'solveForOptions',
  colOptions: { xs: 12 }
};

export const couponDirectionField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "couponDirection",
  label: <FormattedMessage id="form.price.fields.coupon-direction.label" defaultMessage="Direction" />,
  component: RadioGroup,
  FieldComponent: Field,
  justValue: true,
  optionsName: 'couponDirectionOptions',
  colOptions: { xs: 12 }
};

export const couponLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "couponLevel",
  label: <FormattedMessage id="form.price.fields.coupon-level.label" defaultMessage="Coupon Level (per annum)" />,
  placeHolder: 'Enter Coupon Level (per annum)',
  type: "number",
  step: 1,
  min: 0,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 8
  }
};

export const reofferField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "reoffer",
  label: <FormattedMessage id="form.price.fields.reoffer.label" defaultMessage="Reoffer" />,
  placeHolder: 'Enter Reoffer',
  type: "number",
  step: 1,
  min: 0,
  max: 200,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 8
  }
};

export const strikeLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "strikeLevel",
  label: <FormattedMessage id="form.price.fields.strikelevel.label" defaultMessage="Strike Level" />,
  placeHolder: 'Enter Strike Level',
  type: "number",
  step: 1,
  min: 49,
  max: 150,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 6
  }
};

export const downsideLeverageField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "downsideLeverage",
  label: <FormattedMessage id="form.price.fields.downside-leverage.label" defaultMessage="Downside Leverage" />,
  placeHolder: 'Enter Downside Leverage',
  type: "number",
  step: 1,
  action: PRICING_FORM,
  component: InlineInput,
  readOnly: true,
  colOptions: {
    xs: 12,
    sm: 6
  }
};

export const barrierTypeField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "barrierType",
  label: <FormattedMessage id="form.price.fields.barrier-type.label" defaultMessage="Barrier Type" />,
  justValue: true,
  component: RadioGroup,
  FieldComponent: Field,
  optionsName: 'barrierTypeOptions',
  colOptions: { xs: 12 }
};

export const barrierLevelField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "barrierLevel",
  label: <FormattedMessage id="form.price.fields.barrier-level.label"
    defaultMessage="Barrier Level" />,
  placeHolder: 'Enter Barrier Level',
  type: "number",
  step: 1,
  // min: 49,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: { xs: 12, sm: 8 }
};

export const oneStarField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "oneStar",
  labelCheckbox: <FormattedMessage id="form.price.fields.one-star.label" defaultMessage="One Star" />,
  type: "checkbox",
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 4
  },
  positionOptions: {
    relatedField: barrierLevelField.name,
    xs: 12,
    sm: 8
  }
};

export const oneStarThresholdField = {
  fieldType: FIELD_TYPE_DEFAULT,
  name: "oneStarThreshold",
  label: <FormattedMessage id="form.price.fields.one-star-threshold.label" defaultMessage="One Star Threshold" />,
  placeHolder: 'Enter Threshold',
  type: "number",
  step: 1,
  min: ONE_STAR_THRESHOLD_MIN,
  max: ONE_STAR_THRESHOLD_MAX,
  action: PRICING_FORM,
  component: InlineInput,
  colOptions: {
    xs: 12,
    sm: 8
  }
};

const AUTOCALLABLE_FORM_FIELDS = [
  ...STRUCTURED_PRODUCTS_FORM_FIELDS,
  maturityField,
  autocallField,
  frequencyField,
  firstObservationInField,
  autocallTriggerLevelField,
  stepDownField,
  stepField,
  couponTypeField,
  memoryCouponField,
  couponTriggerLevelField,
  snowballField,
  solveForField,
  couponDirectionField,
  couponLevelField,
  reofferField,
  strikeLevelField,
  downsideLeverageField,
  barrierTypeField,
  barrierLevelField,
  oneStarField,
  oneStarThresholdField,
  ...STRUCTURED_PRODUCTS_FORM_NOTIONAL,
];

export const AUTOCALLABLE_FORM_FIELDS_KEYS_ARRAY = [];
export const AUTOCALLABLE_FORM_FIELDS_KEYS = {};
AUTOCALLABLE_FORM_FIELDS.forEach(({ name }) => {
  AUTOCALLABLE_FORM_FIELDS_KEYS[name] = true;
  AUTOCALLABLE_FORM_FIELDS_KEYS_ARRAY.push(name);
});

export default AUTOCALLABLE_FORM_FIELDS;
