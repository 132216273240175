import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { PRICING_STRUCTURED_FORM, PRICING_STRUCTURED_FORM_SHEDULE } from 'pages/structured-products/components/forms/constants';
import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import validate, { dynamicValidationAutocallTriggerLevel, dynamicValidationBarrierLevel, getIssuerMaxCouponLevel } from 'pages/structured-products/components/forms/types/autocallable/AutocallableFormValidate';
import { STRIKE_LEVEL_MAX, STRIKE_LEVEL_MIN } from 'pages/structured-products/components/forms/types/autocallable/constats';
import { focus, touch, getFormValues, getFormInitialValues } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';

import { setScheduleValidity, bankHolidaysGet, scheduleOptionsUpdateFormFields, structuredProductsResponseScheduleSet, structuredProductsScheduleFromHistory, structuredProductsScheduleGetData, scheduleOptionGetData, structuredProductsScheduleLoader, scheduleOptionsLoader, structuredProductsScheduleLock, structuredProductsScheduleResetUserInputs, structuredProductsScheduleSet, STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH, STRUCTURED_PRODUCTS_SCHEDULE_GET_DATA, STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_GET_DATA } from 'redux/actions/structured-products';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { OPTION_INFINE, OPTION_INFINE_VALUE } from "redux/epics/structured-products/form/options/handleOptions.js";
import { distinctObjectChanges, getSingleGraphQlError, roundUp } from 'redux/epics/utils';
import { getAutocallableSchedule } from 'redux/queries/structured-products/';
import { from, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { registerFieldActionDynamicNameFilter, changeActionFilter, changeActionFilterRegExp, changeFormErrors } from 'utils/reduxFormSelector';
import { pricingStructuredFormValuesFilter } from '../changes';
import { getSchedulePayload, parseFormValuesAutocallable } from '../submit';
import { structureRestoringFromHistoryFilter } from '../utils';
import scheduleConditions, { prepareShceduleResponse, saveUserInputs } from './conditions';
import structuredFormShceduleUserInputs from './userInputs';
import { SCHEDULE_OPTIONS_FIELDS_ARRAY, SCHEDULE_OPTIONS_FIELDS } from 'pages/structured-products/components/output/schedule/utils';
import { loadStructuredProductsForm } from 'redux/actions/structured-products';
import { AUTOCALL_VALUE } from 'constants.js';
import { formResetFilter } from 'utils/reduxFormSelector';

const SCHEDULE_DEBOUNCE_TIME = 400;
export const scheduleFormDataSelector = getFormValues(PRICING_STRUCTURED_FORM_SHEDULE);
export const scheduleGetFormInitialValues = getFormInitialValues(PRICING_STRUCTURED_FORM_SHEDULE);
export const MONTHS_IN_YEAR = 12;
export const COUPON_LEVEL_DEFAULT = 50;

export const getObservations = (formValues = {}) => {
  const maturity = parseInt(formValues['maturity'], 10);
  const frequencyForm = formValues?.frequency;
  const autocall = formValues?.autocall;
  const frequency = autocall === 'no' && (frequencyForm === OPTION_INFINE_VALUE || frequencyForm === OPTION_INFINE) ?
    maturity : parseInt(frequencyForm, 10);

  const couponLevel = parseFloat(formValues['couponLevel']);
  const couponLevelSend = roundUp(couponLevel * frequency / MONTHS_IN_YEAR, 6, null);

  const count = parseInt(maturity / frequency, 10);
  const observations = count ? Array(count).fill({
    couponLevel: couponLevelSend
  }) : [];

  return observations;
};

export const SCHEDULE_FORM_FIELDS_SEND = [
  'autocallTriggerLevel',
  'couponTriggerLevel',
  'couponLevel',
  'redemptionDate',
  'observationDate'
];

export const SCHEDULE_DATE_FIELDS = ['redemptionDate', 'observationDate']

export const mergeScheduleFormDataWithResponse = (scheduleData = [], scheduleFormData = {}, saveRows = false) => {
  const originalSchedule = [...scheduleData];
  let schedule = [];
  let anyFieldChanged = false;
  if (originalSchedule && originalSchedule.length) {
    originalSchedule.forEach((originalRow, index) => {

      let scheduleRow = saveRows ? { ...originalRow } : {};
      SCHEDULE_FORM_FIELDS_SEND.forEach((key) => {
        if (scheduleFormData[`${key}-${index}`] !== originalRow[key]) anyFieldChanged = true;
        const fieldValue = (originalRow[key] !== scheduleFormData[`${key}-${index}`]) ? scheduleFormData[`${key}-${index}`] : originalRow[key];

        if (SCHEDULE_DATE_FIELDS.includes(key)) {
          scheduleRow[key] = fieldValue;
        } else {
          const valueNumber = parseFloat(fieldValue);
          scheduleRow[key] = !isNaN(valueNumber) ? valueNumber : null;
        }
      });

      schedule.push(scheduleRow);
    });
  }

  return {
    schedule,
    anyFieldChanged
  };
};

const validateInRange = (value, min = 50, max = 150, isFloat = true) => {
  const intValue = isFloat ? parseFloat(value) : parseInt(value, 10);
  return intValue >= min && intValue <= max;
};

export const structuredFormShceduleEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilterRegExp(PRICING_STRUCTURED_FORM, 'underlying')),
      filter(({ payload }) => payload ? payload : true),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'maturity')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'frequency')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'firstObservationIn')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'couponLevel')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'reoffer')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'solveFor')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocallTriggerLevel')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'couponTriggerLevel')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'memoryCoupon')),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocall')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'stepDown')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'barrierLevel')),
      debounceTime(500)
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'strikeLevel')),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'step')),
      filter(({ payload }) => !isNull(payload)),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'snowball')),
      filter(({ payload }) => isBoolean(payload)),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'couponType')),
    ),
  )
    .pipe(
      distinctUntilChanged(distinctObjectChanges),
      filter(({ payload }) => !isUndefined(payload)),
      filter(() => state$.value.structuredProducts && state$.value.structuredProducts.formOptionsDefault && pricingStructuredFormValuesFilter(state$.value)),
      map(field => {
        return [formValuesSelectorStructuredForm(state$.value), field];
      }),
      filter(([formValues, { meta: { field } } = {}]) => {
        const { barrierLevel, stepDown, } = formValues || {};
        const { min: minBarrierLevel, max: maxBarrierLevel } = dynamicValidationBarrierLevel(formValues) || {};
        return field !== 'barrierLevel'
          || (Boolean(stepDown) && validateInRange(barrierLevel, minBarrierLevel, maxBarrierLevel))
          || isNull(barrierLevel)
      }),
      filter(([{
        barrierLevel,
        strikeLevel,
        stepDown,
        barrierType,
      }, { meta: { field } } = {}]) => field !== 'strikeLevel'
        || (barrierType === 'None' && isNull(barrierLevel) && Boolean(stepDown) && validateInRange(strikeLevel, STRIKE_LEVEL_MIN, STRIKE_LEVEL_MAX))
      ),
      filter(([{
        couponLevel,
        issuer
      }]) => {
        const issuerOptions = state$.value.structuredProducts?.formOptions?.issuer;
        // use values from first issuer if we have issuerOptions but issuer is undefined (e.g. swap)
        return isNull(couponLevel) || !Array.isArray(issuerOptions) || validateInRange(couponLevel, 0, getIssuerMaxCouponLevel(issuerOptions, issuer || issuerOptions[0]?.value));
      }),
      filter(([{
        reoffer,
      }]) => isNull(reoffer) || isUndefined(reoffer) || validateInRange(reoffer, 0, 200)),
      filter(([formValues]) => {
        const { autocallTriggerLevel } = formValues || {};

        const { min: minAutocallTriggerLevel, max: maxAutocallTriggerLevel } = dynamicValidationAutocallTriggerLevel(formValues) || {};
        return isNull(autocallTriggerLevel)
          || validateInRange(autocallTriggerLevel, minAutocallTriggerLevel, maxAutocallTriggerLevel)
      }),
      filter(([{
        couponTriggerLevel = null,
      }]) => isNull(couponTriggerLevel) || validateInRange(couponTriggerLevel, 50, 150)),
      filter(([{ maturity, frequency }]) => maturity && isNumber(frequency)),
      filter(() => state$.value.structuredProducts && !state$.value.structuredProducts.isScheduleLocked && structureRestoringFromHistoryFilter(state$)),
      filter(() => loadStructuredProductsForm()),
      debounceTime(SCHEDULE_DEBOUNCE_TIME),
      map(([, field]) => structuredProductsScheduleGetData(field)),
    );

export const scheduleOptionsEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE)),
      debounceTime(500)
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.OBSERVATION_LAG)),
      debounceTime(500)
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.PAYMENT_LAG)),
      debounceTime(500)
    ),
  )
    .pipe(
      debounceTime(SCHEDULE_DEBOUNCE_TIME),
      switchMap((payload) => of(scheduleOptionGetData(payload))),
    );

export const shceduleOptionLoaderEpic = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_GET_DATA),
    switchMap(() => of(scheduleOptionsLoader(true))),
  );

export const structuredFormShceduleLoaderEpic = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_SCHEDULE_GET_DATA),
    switchMap(() => of(
      structuredProductsScheduleLoader(true),
      bankHolidaysGet(null)
    )),
  );

export const structuredFormShceduleUnlockEpic = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH),
    debounceTime(550),
    switchMap(() => of(structuredProductsScheduleLock(false))),
  );

export const setScheduleDataActions = (data = [], keepUserInputs = false) => {
  const actions = [
    structuredProductsScheduleFromHistory(false),
    structuredProductsScheduleSet(data),
    structuredProductsResponseScheduleSet(data),
    ...!keepUserInputs ? [structuredProductsScheduleResetUserInputs()] : [],
  ];
  return actions;
};


export const structuredFormShceduleGetDataEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(STRUCTURED_PRODUCTS_SCHEDULE_GET_DATA)),
    action$.pipe(ofType(STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_GET_DATA)),
  )
    .pipe(
      filter(() => {
        const formOptionsDefault = state$.value.structuredProducts.formOptionsDefault;
        return formOptionsDefault['frequency'];
      }),
      map(({ payload: field }) => [formValuesSelectorStructuredForm(state$.value), field, SCHEDULE_OPTIONS_FIELDS_ARRAY.includes(field?.meta?.field)]),
      filter(([formValues]) => !isEmpty(formValues)),
      switchMap(([formValues, field, isOptions]) => {
        const stopLoading = () => isOptions ? scheduleOptionsLoader(false) : structuredProductsScheduleLoader(false);
        const formFields = state$.value?.structuredProducts?.formFields || [];
        const { frequency, couponLevel, reoffer } = validate(formValues, { formFields });

        if (frequency || (couponLevel && reoffer)) {
          return from([
            ...setScheduleDataActions(null),
            stopLoading(),
          ]);
        }

        const observations = getObservations(formValues);
        const paymentDelayOptions = state$.value.structuredProducts.formOptionsDefault.paymentDelay;
        const paymentDelay = paymentDelayOptions ? paymentDelayOptions[0].value : 2;
        const variables = parseFormValuesAutocallable(formValues, state$.value);
        const { schedule: scheduleChanged, scheduleOptions: scheduleOptionsForm } = getSchedulePayload(state$);
        const scheduleUserInputs = state$.value?.structuredProducts?.scheduleUserInputs;
        const fieldName = field?.meta?.field;
        const schedule = scheduleConditions(prepareShceduleResponse(scheduleChanged, formValues), fieldName, formValues, scheduleUserInputs);
        const keepUserInputs = saveUserInputs(fieldName, formValues);
        const underlyingIds = state$.value?.structuredProducts?.underlyings.map(({ value }) => value).filter((value) => value);

        return from(
          getAutocallableSchedule({
            ...variables,
            autocall: (variables.autocall === AUTOCALL_VALUE.YES || variables.autocall === AUTOCALL_VALUE.ISSUER_CALLABLE),
            ...scheduleOptionsForm,
            observations,
            schedule,
            paymentDelay,
            underlyingIds,
          })
        ).pipe(
          switchMap(data => {
            const response = prepareShceduleResponse(data, formValues)

            if (isOptions) {
              const values = {
                ...response.reduce((acc, item, index) => {
                  const { observationDate, redemptionDate } = item;
                  acc[`observationDate-${index}`] = observationDate;
                  acc[`redemptionDate-${index}`] = redemptionDate;
                  return acc
                }, {})
              };

              return from([
                scheduleOptionsUpdateFormFields(values),
                structuredProductsResponseScheduleSet(response),
                stopLoading(),
              ]);
            }
            return from([
              ...setScheduleDataActions(response, keepUserInputs),
              stopLoading(),
            ]);
          }),
          catchError((error) => {
            const err = getSingleGraphQlError(error);
            const msg = err?.message ? err.message : null;
            const errMsgDescription = msg?.description ? msg.description : null;
            const errMsgTitle = msg?.title ? msg.title : null;
            return from([
              notificationErrorSimple('Schedule: ' + (errMsgDescription || errMsgTitle || `Can't fetch autocallable schedule`)),
              stopLoading(),
            ]);
          }),
        )
      })
    );

const scheduleErrors = (action$) =>
  action$.pipe(
    filter(changeFormErrors(PRICING_STRUCTURED_FORM_SHEDULE)),
    switchMap(({ payload: { syncErrors = {} } }) => from([
      setScheduleValidity(!!Object.keys(syncErrors).length),
    ]))
  )

const touchedStrikeDate = (action$) =>
  action$.pipe(
    filter(registerFieldActionDynamicNameFilter(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE)),
    debounceTime(200),
    switchMap(() => from([
      touch(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE),
      focus(PRICING_STRUCTURED_FORM_SHEDULE, SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE),
    ]))
  )

const onCancelScheduleEditForm = (action$, state$) =>
  action$.pipe(
    filter(formResetFilter(PRICING_STRUCTURED_FORM_SHEDULE)),
    switchMap(() => {
      const schedule = state$.value.structuredProducts.schedule || [];
      return from([
        structuredProductsResponseScheduleSet(schedule),
      ]);
    }),
  );

export default combineEpics(
  structuredFormShceduleEpic,
  structuredFormShceduleLoaderEpic,
  structuredFormShceduleGetDataEpic,
  structuredFormShceduleUnlockEpic,
  structuredFormShceduleUserInputs,
  scheduleOptionsEpic,
  shceduleOptionLoaderEpic,
  scheduleErrors,
  touchedStrikeDate,
  onCancelScheduleEditForm,
);
