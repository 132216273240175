import { Field } from 'redux-form';
import OpenTradesTableItemBlock from 'components/tables/blocks/OpenTradesTableItemBlock';
import { InlineInput } from 'components/form/InlineInput';
import { RECORD_EMPTY } from 'constants.js';
import gql from 'graphql-tag';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { FormattedDate } from 'react-intl';
import { getSortLabel } from './sorter';

export const TRADES_ITEM_FRAGMENT = gql`fragment TradesTableConnectionFragment on OTCStatementType {
  id
  commonId
  statementId
  externalId
  underlyingName
  tradeDate
  buySell
  tradeName
  tradeType
  currency
  deliveryMonth
  expiryDate
  strikeWithUnits
  volumeInClientUnits
  notionalLong
  notionalShort
  underlyingPrice
  accumulationLevel
  barrierLevel
  deltaInLots
  net
  bid
  mid
  ask
  ticker
  lastMonthAsian
  clientId
  location {
    id
    name
    code
  }
  customer {
    id
    name
    code
  }
  trader {
    id
    firstName
    lastName
  }
  physicalId
  accumulatedLots
  tradeStyle
  legId
  remainingFixings
  maxQuantityAccumulated
  maxRemainingQuantity
  remainderLevel
  totalFixings
  premiumTotal
  premiumPerUnit
  accumulatedUnits
  notionalLongUnits
  notionalShortUnits
  deltaUnits
  maxQuantityAccumulatedUnits
  maxRemainingQuantityUnits
  min
  max
  startDate
  canUnwind
  accumulatorMiniHistoryId
  otcStatementId
  mtmInTradeCcy
}`;

class OpenTradesTableItem extends Component {

  onSelectItem = (e) => {
    if(e.target.tagName !== 'DIV') return
    const { onSelect, accumulatorMiniHistoryId, otcStatementId, commonId } = this.props;
    if (typeof onSelect === 'function') onSelect(accumulatorMiniHistoryId, otcStatementId, commonId);
  }

  parseExternalId(externalId) {
    const [extId] = externalId?.split('-') || [false];
    return extId;
  }

  handleChange = () => {
    const { onChangeTrade, underlyingName, currency, externalId, ticker } = this.props;
    const conditionsOfChoiceTrades = new Set()
    conditionsOfChoiceTrades.add(underlyingName)
    conditionsOfChoiceTrades.add(this.parseExternalId(externalId))
    conditionsOfChoiceTrades.add(currency)
    conditionsOfChoiceTrades.add(ticker)

    if (typeof onChangeTrade === 'function') onChangeTrade(conditionsOfChoiceTrades, externalId);
  }

  render() {
    const {
      screenSize, displayColumns = {}, index, conditionsOfChoiceTrades, selectedIds,
      id,
      trader,
      location,
      customer,
      physicalId,
      externalId,
      underlyingName,
      tradeDate,
      buySell,
      tradeName,
      tradeType,
      currency,
      deliveryMonth,
      lastMonthAsian,
      expiryDate,
      strikeWithUnits,
      volumeInClientUnits,
      notionalLong,
      notionalShort,
      underlyingPrice,
      accumulationLevel,
      barrierLevel,
      deltaInLots,
      net,
      bid,
      mid,
      ask,
      ticker,
      accumulatedLots,
      tradeStyle,
      legId,
      remainingFixings,
      maxQuantityAccumulated,
      maxRemainingQuantity,
      remainderLevel,
      totalFixings,
      premiumTotal,
      premiumPerUnit,
      accumulatedUnits,
      notionalLongUnits,
      notionalShortUnits,
      deltaUnits,
      min,
      max,
      startDate,
      canUnwind,
      isSlackId,
      mtmInTradeCcy
    } = this.props;

    const disabledCheckboxes = !canUnwind || !externalId || !currency || !ticker ||
      (conditionsOfChoiceTrades.size
        && (!conditionsOfChoiceTrades.has(underlyingName)
          || !conditionsOfChoiceTrades.has(this.parseExternalId(externalId))
          || !conditionsOfChoiceTrades.has(currency)
          || !conditionsOfChoiceTrades.has(ticker)
        ));

    return (
      id ?
        <div data-index={`record-${index + 1}`} className="app-table-row" onClick={this.onSelectItem}>
          {isSlackId ?
            <OpenTradesTableItemBlock label='select' name="select" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                <Field
                  name={`record-${id}`}
                  type="checkbox"
                  disabled={disabledCheckboxes}
                  onChangeFilter={this.handleChange}
                  component={InlineInput}
                  checked={selectedIds.has(externalId)}
                />
              </div>
            </OpenTradesTableItemBlock> : null}
          {displayColumns?.tradeType ?
            <OpenTradesTableItemBlock label={getSortLabel('tradeType')} name="tradeType" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {tradeType ? tradeType : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          <OpenTradesTableItemBlock label={getSortLabel('externalId')} name="externalId" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {externalId ? externalId : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          <OpenTradesTableItemBlock label={getSortLabel('underlyingName')} name="underlyingName" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {underlyingName ? underlyingName : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          {displayColumns?.tradeDate ?
            <OpenTradesTableItemBlock label={getSortLabel('tradeDate')} name="tradeDate" screenSize={screenSize}>
              {tradeDate ?
                <Fragment>
                  <FormattedDate value={tradeDate} timeZone='utc'/> {/* There is no saved time data <FormattedTime value={tradeDate} />*/}
                </Fragment>
                : RECORD_EMPTY}
            </OpenTradesTableItemBlock>
          : null}
          <OpenTradesTableItemBlock label={getSortLabel('buySell')} name="buySell" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {buySell ? buySell : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          <OpenTradesTableItemBlock label={getSortLabel('tradeName')} name="tradeName" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {tradeName ? tradeName : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          <OpenTradesTableItemBlock label={getSortLabel('currency')} name="currency" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {currency ? currency : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          {displayColumns?.deliveryMonth ?
            <OpenTradesTableItemBlock label={getSortLabel('deliveryMonth')} name="deliveryMonth" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                <FormattedDate value={deliveryMonth} timeZone='utc'/>
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.startDate ?
            <OpenTradesTableItemBlock label={getSortLabel('startDate')} name="startDate" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {startDate ? <FormattedDate value={startDate} timeZone='utc'/> : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.lastMonthAsian ?
            <OpenTradesTableItemBlock label={getSortLabel('lastMonthAsian')} name="lastMonthAsian" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {lastMonthAsian ? <FormattedDate value={lastMonthAsian} timeZone='utc'/> : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.expiryDate ?
            <OpenTradesTableItemBlock label={getSortLabel('expiryDate')} name="expiryDate" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {expiryDate ? <FormattedDate value={expiryDate} timeZone='utc'/> : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.strikeWithUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('strikeWithUnits')} name="strikeWithUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {strikeWithUnits ? strikeWithUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.accumulationLevel ?
            <OpenTradesTableItemBlock label={getSortLabel('accumulationLevel')} name="accumulationLevel" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {accumulationLevel ? accumulationLevel : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.barrierLevel ?
            <OpenTradesTableItemBlock label={getSortLabel('barrierLevel')} name="barrierLevel" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {barrierLevel ? barrierLevel : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.remainderLevel ?
            <OpenTradesTableItemBlock label={getSortLabel('remainderLevel')} name="remainderLevel" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {remainderLevel ? remainderLevel : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.min ?
            <OpenTradesTableItemBlock label={getSortLabel('min')} name="min" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(min) ? min : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.max ?
            <OpenTradesTableItemBlock label={getSortLabel('max')} name="max" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(max) ? max : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.volumeInClientUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('volumeInClientUnits')} name="volumeInClientUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right text-capitalize">
                {volumeInClientUnits ? volumeInClientUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.notionalLong ?
            <OpenTradesTableItemBlock label={getSortLabel('notionalLong')} name="notionalLong" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(notionalLong) ? notionalLong : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.notionalShort ?
            <OpenTradesTableItemBlock label={getSortLabel('notionalShort')} name="notionalShort" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(notionalShort) ? notionalShort : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.notionalLongUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('notionalLongUnits')} name="notionalLongUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(notionalLongUnits) ? notionalLongUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.notionalShortUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('notionalShortUnits')} name="notionalShortUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(notionalShortUnits) ? notionalShortUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.premiumTotal ?
            <OpenTradesTableItemBlock label={getSortLabel('premiumTotal')} name="premiumTotal" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(premiumTotal) ? premiumTotal : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.premiumPerUnit ?
            <OpenTradesTableItemBlock label={getSortLabel('premiumPerUnit')} name="premiumPerUnit" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(premiumPerUnit) ? parseFloat(premiumPerUnit.toFixed(4)) : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.totalFixings ?
            <OpenTradesTableItemBlock label={getSortLabel('totalFixings')} name="totalFixings" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(totalFixings) ? totalFixings : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.remainingFixings ?
            <OpenTradesTableItemBlock label={getSortLabel('remainingFixings')} name="remainingFixings" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {remainingFixings ? remainingFixings : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.underlyingPrice ?
            <OpenTradesTableItemBlock label={getSortLabel('underlyingPrice')} name="underlyingPrice" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(underlyingPrice) ? underlyingPrice : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.accumulatedLots ?
            <OpenTradesTableItemBlock label={getSortLabel('accumulatedLots')} name="accumulatedLots" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {accumulatedLots ? accumulatedLots : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.accumulatedUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('accumulatedUnits')} name="accumulatedUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(accumulatedUnits) ? accumulatedUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.maxQuantityAccumulated ?
            <OpenTradesTableItemBlock label={getSortLabel('maxQuantityAccumulated')} name="maxQuantityAccumulated" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {maxQuantityAccumulated ? maxQuantityAccumulated : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.maxRemainingQuantity ?
            <OpenTradesTableItemBlock label={getSortLabel('maxRemainingQuantity')} name="maxRemainingQuantity" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {maxRemainingQuantity ? maxRemainingQuantity : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.deltaInLots ?
            <OpenTradesTableItemBlock label={getSortLabel('deltaInLots')} name="deltaInLots" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(deltaInLots) ? deltaInLots : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.deltaUnits ?
            <OpenTradesTableItemBlock label={getSortLabel('deltaUnits')} name="deltaUnits" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(deltaUnits) ? deltaUnits : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.net ?
            <OpenTradesTableItemBlock label={getSortLabel('net')} name="net" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(net) ? net : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.bid ?
            <OpenTradesTableItemBlock label={getSortLabel('bid')} name="bid" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(bid) ? bid : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.mid ?
            <OpenTradesTableItemBlock label={getSortLabel('mid')} name="mid" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(mid) ? mid : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.ask ?
            <OpenTradesTableItemBlock label={getSortLabel('ask')} name="ask" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(ask) ? ask : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          <OpenTradesTableItemBlock label={getSortLabel('ticker')} name="ticker" screenSize={screenSize}>
            <div className="float-right float-sm-none block-right">
              {ticker ? ticker : RECORD_EMPTY}
            </div>
          </OpenTradesTableItemBlock>
          {displayColumns?.trader ?
            <OpenTradesTableItemBlock label={getSortLabel('trader')} name="trader" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {trader ? `${trader.firstName} ${trader.lastName}` : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.customer ?
            <OpenTradesTableItemBlock label={getSortLabel('customer')} name="customer" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {customer ? customer.name : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.location ?
            <OpenTradesTableItemBlock label={getSortLabel('location')} name="location" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {location ? location.name : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.physicalId ?
            <OpenTradesTableItemBlock label={getSortLabel('physicalId')} name="physicalId" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {physicalId ? physicalId : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.tradeStyle ?
            <OpenTradesTableItemBlock label={getSortLabel('tradeStyle')} name="tradeStyle" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {tradeStyle ? tradeStyle : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.legId ?
            <OpenTradesTableItemBlock label={getSortLabel('legId')} name="legId" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {legId ? legId : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
          {displayColumns?.mtmInTradeCcy ?
            <OpenTradesTableItemBlock label={getSortLabel('mtmInTradeCcy')} name="mtmInTradeCcy" screenSize={screenSize}>
              <div className="float-right float-sm-none block-right">
                {isNumber(mtmInTradeCcy) ? mtmInTradeCcy : RECORD_EMPTY}
              </div>
            </OpenTradesTableItemBlock>
          : null}
        </div>
        :
        null
    )
  }
}

const OpenTradesTableItemProps = {
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  underlyingName: PropTypes.string.isRequired,
  tradeDate: PropTypes.string,
  buySell: PropTypes.string.isRequired,
  tradeName: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  deliveryMonth: PropTypes.string,
  lastMonthAsian: PropTypes.string,
  expiryDate: PropTypes.string,
  strikeWithUnits: PropTypes.number,
  volumeInClientUnits: PropTypes.number,
  notionalLong: PropTypes.number,
  notionalShort: PropTypes.number,
  underlyingPrice: PropTypes.number,
  accumulationLevel: PropTypes.number,
  barrierLevel: PropTypes.number,
  deltaInLots: PropTypes.number,
  net: PropTypes.number,
  bid: PropTypes.number,
  mid: PropTypes.number,
  ask: PropTypes.number,
  ticker: PropTypes.string,
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  trader: PropTypes.shape({
    id: PropTypes.string,
    fitstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  physicalId: PropTypes.string,
  tradeStyle: PropTypes.string,
  legId: PropTypes.number,
  remainingFixings: PropTypes.number,
  maxQuantityAccumulated: PropTypes.number,
  maxRemainingQuantity: PropTypes.number,
  remainderLevel: PropTypes.number,
  totalFixings: PropTypes.number,
  premiumTotal: PropTypes.number,
  premiumPerUnit: PropTypes.number,
  accumulatedUnits: PropTypes.number,
  notionalLongUnits: PropTypes.number,
  notionalShortUnits: PropTypes.number,
  deltaUnits: PropTypes.number,
  maxQuantityAccumulatedUnits: PropTypes.number,
  maxRemainingQuantityUnits: PropTypes.number,
  mtmInTradeCcy: PropTypes.number,
};

OpenTradesTableItem.propTypes = {
  displayColumns: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  ...OpenTradesTableItemProps,
};

OpenTradesTableItem.fragments = {
  item: TRADES_ITEM_FRAGMENT
};

export default OpenTradesTableItem;
