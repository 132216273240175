import InlineInput from 'components/form/InlineInput';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';

const MaxShowField = ({ formatMessage }) => (
  <Col xs={12} sm={4}>
    <Field
      name="maxShow"
      type="number"
      placeHolder={formatMessage({
        id: "trade/maxShow.label",
        defaultMessage: "Max Show",
      })}
      label={<FormattedMessage id="trade/maxShow.label"
        defaultMessage="Max Show" />}
      step="1"
      component={InlineInput}
    />
  </Col>
);

export default MaxShowField;

