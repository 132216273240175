import React from 'react';
import { FormattedMessage } from 'react-intl';

const ServerError = ({ children }) => (
  <div className="text-center text-danger">
    {children ? children :
      <FormattedMessage id="common/server-error"
        defaultMessage="Server Error" />}
  </div>
);

export default ServerError;
