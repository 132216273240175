import Loader from 'components/common/loader/Loader';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import React from 'react';
import { graphql } from 'react-apollo';
import StaticPageModal from '../../staticPage/StaticPageModal';
import { QUERY_FOOTER_PAGES } from './query';

const FETCH_FOOTER_PAGES_OPTIONS = {
  options: {
    variables: {
      position: 'footer'
    },
    fetchPolicy: GRAPHQL_NETWORK_ONLY
  }
};

const FooterStaticPages = ({ data: { loading, staticPageConnection, error } }) => {
  if (loading) {
    return <Loader />
  } else if (error) {
    return `Error!: ${error}`;
  } else {
    const pages = staticPageConnection || [];
    return (
      pages.map((page) => <StaticPageModal key={page.seo} slug={page.seo} title={page.title} />)
    );
  }
};

export default graphql(QUERY_FOOTER_PAGES, FETCH_FOOTER_PAGES_OPTIONS)(FooterStaticPages);

