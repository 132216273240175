import { DATE_FORMAT_FACTSHEET } from 'constants.js';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';
import { QUANTITY_FORMATTER_PARAMS } from './constants';

const pluralize = (count, noun, suffix = 's') => (<span><FormattedNumber value={count} maximumFractionDigits={3} /> {noun}{Math.abs(count) > 1 ? suffix : ''}</span>);

const REFERENCE_FIGURES_CONTENT_CONFIG = (data) => {
  const {
    commodityContract,
    tradeDate,
    accumulatorUnderlyingsData,
    bloombergTicker,
    tradedCurrency,
    expiryDate,
    premium,
    factSheetData: {
      fields,
      dailyLot,
      businessDays,
      notionalLot,
      maximumNotionalAccumulated,
      maximumNotionalAccumulatedLot,
      dailyQuantity,
      agileNotional,
      deltaInLot,
      deltaInUnit,
    },
    referencePrice,
    quoteUnits,
    futTradingUnits,
    deltaPercentage,
    greeksDisplay,
    premiumField,
    futValPt,
    lotSize,
    currency,
  } = data;

  const mappedFields = fields.map(i =>({
    label: i.label,
    view: () => <span><FormattedNumber value={i.value} maximumFractionDigits={4} /> {quoteUnits}</span>,
  }))

  const calculatePremiumTotalValue = () => {
    const denominator = lotSize / futValPt;
    const numerator = agileNotional * parseInt(premium);
    return <span><FormattedNumber value={numerator / denominator} /> {currency}</span>
  };

  const content = [
    {
      label: 'Reference Contract',
      view: () => (
        <Fragment>
          {commodityContract}&nbsp;{formatDate2UI(accumulatorUnderlyingsData.contractCode ? accumulatorUnderlyingsData.contractCode : tradeDate)}
        </Fragment>),
    },
    {
      label: 'Bloomberg Ticker',
      view: () => (<span>{bloombergTicker}</span>),
    },
    {
      label: 'Currency',
      view: () =>
        (<span>{tradedCurrency}</span>),
    },
    {
      label: 'Start Date',
      view: () => formatDate2UI(tradeDate, DATE_FORMAT_FACTSHEET),
    },
    {
      label: 'Expiry Date',
      view: () => {
        const bdays = businessDays ? ` (${businessDays} business days)` : '';
        return (<Fragment>{formatDate2UI(expiryDate, DATE_FORMAT_FACTSHEET)}{bdays}</Fragment>)
      },
    },
    {
      label: 'Reference Contract Price',
      view: () => (<span>
        <FormattedNumber
          value={referencePrice}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        /> {quoteUnits}
      </span>),
    },
    ...mappedFields,
    {
      label: 'Notional',
      view: () => (<span><FormattedNumber value={agileNotional} /> {futTradingUnits} ({pluralize(notionalLot, 'lot')})</span>),
      filter: () => (notionalLot),
    },
    {
      label: 'Maximum Notional Accumulated',
      view: () => (<span><FormattedNumber value={maximumNotionalAccumulated} /> {futTradingUnits} ({pluralize(maximumNotionalAccumulatedLot, 'lot')})</span>),
      filter: () => (maximumNotionalAccumulated && maximumNotionalAccumulatedLot),
    },
    {
      label: premiumField?.label,
      view: () => (<span><FormattedNumber value={premium} /> {quoteUnits} ({calculatePremiumTotalValue()})</span>),
      filter: () => (premiumField)
    },
    {
      label: 'Daily Quantity',
      view: () => (<span><FormattedNumber {...QUANTITY_FORMATTER_PARAMS} value={dailyQuantity} /> {futTradingUnits} ({pluralize(dailyLot, 'lot')})</span>),
      filter: () => (dailyLot),
    },
    {
      label: 'Delta',
      view: () => (<span><FormattedNumber value={deltaPercentage} />% (<FormattedNumber value={deltaInUnit} maximumFractionDigits={3} /> {futTradingUnits} or {pluralize(deltaInLot, 'lot')})</span>),
      filter: () => (greeksDisplay && deltaPercentage && deltaInLot && deltaInUnit),
    },
  ];

  return content
}



export default REFERENCE_FIGURES_CONTENT_CONFIG;
