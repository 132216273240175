import apolloClient from 'apollo.js';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import gql from 'graphql-tag';
import lowerCase from 'lodash/lowerCase';
import { isStructureContaintSchedule } from 'redux/epics/structured-products/form/utils';

const optionsToInt = (list = []) => list.map(item => ({ label: item.label, value: parseInt(item.value, 10) }));
const optionsToLabelToValue = (list = []) => list.map(({ label }) => ({ label, value: label }));
const optionsToLabelToValueLowerCase = (list = []) => list.map(({ label }) => ({ label, value: lowerCase(label) }));
const optionsToIntNames = ['paymentDelay', 'maturity', 'frequency'];
const optionsLowerCaseNames = ['autocall', 'memoryCoupon'];
const hiddenFields = ['issuerCallableTrigger', 'issuerCallableDistance']

export const mapStructuredProductsFormData = (data = [], type) => {
  let formOptions = {};
  data.forEach(item => {
    const { name, options = [], default: defaultValue } = item;

    if (!name) return;
    if (optionsToIntNames.includes(name)) {
      return formOptions[name] = optionsToInt(options);
    }

    if (name === 'barrierType' && isStructureContaintSchedule(type)) {
      const listOptions = optionsToLabelToValue(options);
      return formOptions[name] = listOptions;
    }

    if (optionsLowerCaseNames.includes(name)) {
      return formOptions[name] = optionsToLabelToValueLowerCase(options);
    }

    if(hiddenFields.includes(name)) {
      return formOptions[name] = parseFloat(defaultValue);
    }

    formOptions[name] = options;
  });

  return formOptions;
};

export const QUERY_STRUCTURE_PRODUCTS = gql`
  query getStructuredProductsQuery {
    getStructuredProducts {
      value: type
      label
    }
  }`;

export const getStructuredProducts = () => {
  return apolloClient.query({
    query: QUERY_STRUCTURE_PRODUCTS
  }).then(({ data = {} }) => {
    const { getStructuredProducts } = data;

    return getStructuredProducts;
  });
};

export const QUERY_STRUCTURED_PRODUCTS_FORM_DATA = gql`
  query getStructuredProductsFormQuery (
    $type: StructuredProductsFormStructureEnumType!
  ) {
    getStructuredProductsForm (type: $type) {
      name
      default
      options {
        label
        value
        max
        floatingCurve {
          ui
          xml
        }
      }
    }
  }`;

export const getStructuredProductsFormData = (type) => {
  return apolloClient.query({
    query: QUERY_STRUCTURED_PRODUCTS_FORM_DATA,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      type
    }
  }).then(({ data = {} }) => {
    const { getStructuredProductsForm } = data;
    return mapStructuredProductsFormData(getStructuredProductsForm, type);
  })
};
