import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import Portal from 'components/common/portal/Portal';

const PrintModalHeader = ({ onClose, onPrint }) => {
  return (
    <Portal className="print-portal-header">
      <div className="print-modal-navbar">
        <Container>
          <Row>
            <Col className="d-flex align-items-center">
              <div className="d-flex align-items-center link" onClick={onClose}>
                <ArrowBackIcon className="mr-1 btn-icon" /> <span>
                  <FormattedMessage id="common/back"
                    defaultMessage="Back" />
                </span>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              <Button color="default" onClick={onPrint}>
                <FormattedMessage id="common/print-download"
                  defaultMessage="Print/Download" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Portal>
  )
};

PrintModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
};

export default PrintModalHeader;
