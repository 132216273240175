import { reset, actionTypes } from 'redux-form';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { STRUCTURE_SWAP, CARD_STATUSES, DEFAULT_UNIT, ERROR_FETCH_CONTRACT_EXPIRY_2 } from 'constants.js';
import { getCardByFormName, getCardById, getErrorsCardByFormName } from 'redux/epics/price/structures/swap/utils';
import { priceFormFailed } from 'redux/actions/price';
import { swapCardUpdate, PRICING_SWAP_CONFIRMATION_MODAL_APPROVE, PRICING_SWAP_CONFIRMATION_MODAL_CANCEL } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { cardError, swapFormFilter, swapTrailPricesFilter } from 'redux/epics/price/structures/swap/';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { updateSwapCardMutation } from 'redux/queries/swap';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';
import { swapFormValuesSelector, swapFormCardName, getSelectedCurrency } from 'pages/price/output/swap/swapPricingOutputCardForm/SwapPricingOutputCardForm';
import { fetchContractExpiryList } from 'pages/price/output/query';

const { REQUESTED } = CARD_STATUSES;
// hook for avoid performance issues - force update field only if it necessary (only for swap cards)
const { CHANGE } = actionTypes;
const change = (
  form,
  field,
  value,
  touch = false,
  persistentSubmitErrors = false
) => ({
  type: CHANGE,
  meta: { form, field, touch, persistentSubmitErrors, force: true },
  payload: value
});

const getEvents = (action$) => [
  onSwapField(action$, 'quantity', 200),
  onSwapField(action$, 'contractExpiry'),
  onSwapField(action$, 'contractExpirySecond'),
  onSwapField(action$, 'payoutCurrency'),
  onSwapField(action$, 'tradeDate'),
  onSwapField(action$, 'unit')
];

const onSwapField = (action$, fieldName = '', debounce = 100) => action$.pipe(
  filter(changeAnyFormFieldActionFilter(fieldName)),
  filter(swapFormFilter),
  debounceTime(debounce),
);

const isQtyError = (formErrors) => Object.keys(formErrors).length && Object.keys(formErrors).includes('quantity');
const isCurrencyError = (formErrors) => Object.keys(formErrors).length && Object.keys(formErrors).includes('payoutCurrency');

const sendUpdateCard = (formName, card) => {
  const cardSimple = {
    ...card,
    loading: false,
    isLoading: false,
    confirmationPopup: false,
  }

  if (!cardSimple.contractCode) {
    return from([
      priceFormFailed(),
      swapCardUpdate({
        ...cardSimple,
        contractCode: null,
        bloombergTickerSecond: null,
        price: null,
        loading: false,
        isLoading: false,
        isError: true,
      }),
      notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY_2),
    ])
  }

  return from(updateSwapCardMutation(STRUCTURE_SWAP, card))
    .pipe(
      switchMap(({ price, tradeDate, status, fxBankHolidays, disableCompo, priceForCalcSpread }) => {
        const cardNew = {
          ...cardSimple,
          priceForCalcSpread,
          price,
          tradeDate,
          status,
          fxBankHolidays,
          disableCompo,
          isError: false
        };
        return from([
          change(formName, 'tradeDate', tradeDate),
          change(formName, 'unit', cardNew.unit),
          change(formName, 'unitType', cardNew.unitType),
          change(formName, 'contractCode', cardNew.contractCode),
          swapCardUpdate(cardNew),
          change(formName, 'contractExpirySecond', cardNew.contractExpirySecond),
        ]);
      }),
      catchError((error) => {
        const actions = [
          priceFormFailed(),
          swapCardUpdate({
            ...cardSimple,
            isError: !!error
          })
        ];
        const err = getSingleGraphQlError(error);
        if (err?.message) {
          return from([
            ...actions,
            notificationErrorSimple(err.message),
          ])
        }

        return from([
          ...actions,
          cardError(card, 'update'),
        ]);
      })
    )
}

const prepareUpdatedFields = (state$) => switchMap(({ field, formName, payload, card }) => {
  const { id, quotedCurrency, fxCode, commodityCode, contractExpirySecond: calendarSpread, contractCode: cardStateContractCode } = card;
  const { quantity, payoutCurrency, tradeDate = null, unit, unitType, bloombergTicker, bloombergTickerSecond, contractCode } = swapFormValuesSelector(id, state$.value) || {};
  const fields = {
    quantity: parseFloat(quantity),
    termCurrency: payoutCurrency,
    tradeDate,
    forceTradeDate: false,
    fxCode,
    unitType,
    contractCode: calendarSpread ? cardStateContractCode : contractCode,
    bloombergTicker
  }

  if(field === 'unit') {
    fields.unit = unit.value || DEFAULT_UNIT
  }

  if(field === 'payoutCurrency' && quotedCurrency !== payoutCurrency) {
    const { currencyOptions: currencyState = {} } = state$.value?.price
    const currencyOptions = currencyState[quotedCurrency] ? currencyState[quotedCurrency] : [{ label: quotedCurrency, value: quotedCurrency, deliverable: false, fxCode: null }];
    const { fxCode } = getSelectedCurrency(currencyOptions, payoutCurrency);
    fields.fxCode = fxCode;
  }

  if(field === 'contractExpiry') {
    fields.forceTradeDate = true;
    fields.contractExpiry = payload;
  }

  if(field === 'contractExpirySecond') {
    fields.contractExpirySecond = payload;
    fields.contractCode = contractCode;
    fields.bloombergTickerSecond = bloombergTickerSecond;
  }

  if(quotedCurrency === payoutCurrency) {
    fields.tradeDate = null;
    fields.fxCode = null;
  }

  if(calendarSpread && field === 'contractExpiry') {
    return from(
      fetchContractExpiryList({
        type: STRUCTURE_SWAP.toLowerCase(),
        commodityCode: commodityCode,
        contractExpiry: fields.contractExpiry,
      })
    ).pipe(
      filter(payload => !!payload),
      switchMap((expiryDataSecond) => {
        const { bloombergTicker: bloombergTickerSecond, contract: contractCodeSecond, expiry: contractExpirySecond } = expiryDataSecond[0];
        return sendUpdateCard(formName, {
          ...card,
          ...fields,
          contractCode: contractCodeSecond,
          contractExpirySecond,
          bloombergTickerSecond
        })
      }),
      catchError(() => from([
        priceFormFailed(),
        swapCardUpdate({
          ...card,
          ...fields,
          contractCode: null,
          bloombergTickerSecond: null,
          price: null,
          loading: false,
          isLoading: false,
          isError: true,
        }),
        notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY_2),
      ]))
    )
  } else {
    return sendUpdateCard(formName, {
      ...card,
      ...fields,
    })
  }
})

const cardUpdateEpic = (action$, state$) =>
  merge(
    ...getEvents(action$),
  ).pipe(
    filter(() => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }) => !!payload && !meta?.force),
    map(({ meta, payload }) => {
      const { field, form: formName } = meta;
      const card = getCardByFormName(state$, meta);
      const formErrors = getErrorsCardByFormName(state$, meta);
      return { field, formName, payload, card, formErrors }
    }),
    filter(({ formErrors }) => !isQtyError(formErrors)),
    filter(({ formErrors }) => !isCurrencyError(formErrors)),
    filter(({ card }) => !card.confirmationPopup),
    prepareUpdatedFields(state$),
  );

const startUpdateEpic = (action$, state$) =>
  merge(
    ...getEvents(action$),
  ).pipe(
    filter(() => swapTrailPricesFilter(state$)),
    filter(({ payload, meta }) => !!payload && !meta?.force),
    switchMap(({ meta, payload }) => {
      const formErrors = getErrorsCardByFormName(state$, meta);
      if(isQtyError(formErrors)) return from([]);
      if(isCurrencyError(formErrors)) return from([]);
      const card = getCardByFormName(state$, meta);
      const confirmationPopup = card.status === REQUESTED;
      return from([
        swapCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
          confirmationPopup,
          lastChangedField: { field: meta.field, payload}
        })
      ])
    })
  );

const closeModalEpic = (action$, state$) =>  action$.pipe(
    ofType(PRICING_SWAP_CONFIRMATION_MODAL_CANCEL),
    switchMap(({ payload: id }) => {
      const card = getCardById(state$.value.price?.trailPrice?.cards, id);
      const formName = swapFormCardName(id);
      const actions = [
        reset(formName),
        swapCardUpdate({
          ...card,
          loading: false,
          isLoading: false,
          confirmationPopup: false,
        }),
      ];
      return from(actions);
    })
  )

const approveModalEpic = (action$, state$) => action$.pipe(
    ofType(PRICING_SWAP_CONFIRMATION_MODAL_APPROVE),
    map(({ payload: { id, field = '', payload } }) => {
      const card = getCardById(state$.value.price.trailPrice.cards, id);
      const formName = swapFormCardName(id);
      return { formName, field, payload, card }
    }),
    prepareUpdatedFields(state$),
  )

export default combineEpics(
  startUpdateEpic,
  cardUpdateEpic,
  closeModalEpic,
  approveModalEpic
);
