import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import isEqual from 'lodash/isEqual';
import { Component } from 'react';
import { graphql } from 'react-apollo';
import { FETCH_PRICING_MARKET_DATA } from './query';

class ProductChartBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { marketDataId, submitting, trail, data } = this.props;
    const { marketDataId: nextMarketDataId, submitting: nextSubmitting, trail: nextTrail, data: nextData } = nextProps;
    return marketDataId !== nextMarketDataId || submitting !== nextSubmitting || !isEqual(trail, nextTrail) || !isEqual(data, nextData)
      || !isEqual(this.props, nextProps) //When change Barrier Level, or Accumulation Level;
  }

  componentDidUpdate(prevPorps) {
    const { data: { refetch }, trail } = prevPorps;
    const { trail: nextTrail } = this.props;
    if (!isEqual(trail, nextTrail)) {
      refetch();
    }
  }

  render() {
    const {
      children,
      data: { loading, pricingAccumulatorMarketData: marketData },
    } = this.props;
    return (
      children({ loading, marketData })
    )
  }
}

const options = props => {
  return {
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      id: props.marketDataId
    }
  }
};

export default graphql(FETCH_PRICING_MARKET_DATA, { options })(ProductChartBlock);
