import PageMeta from 'components/common/PageMeta';
import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Container } from 'reactstrap';
import PrintLayout from '../Layout';
import PrintProductChart from './PrintProductChart';
import PrintProductChartHeader from './PrintProductChartHeader';
import PrintProductDescription from './PrintProductDescription';

const PrintPriceContent = props => {
  const {
    onClose,
    quoteUnits,
    accumulatorUnderlyings = {},
    marketData,
    type,
    loading,
    description: {
      productDescription,
      product
    } = {},
    view
  } = props;
  const { logo } = accumulatorUnderlyings;
  const marketDataId = marketData && marketData.id ? marketData.id : null;
  const productImg = logo ? logo : '/images/no-image.png';
  return (
    <PrintLayout title={product} onClose={onClose} loading={loading}>
      <PageMeta title="Pricing Factsheet" />
      <div className="print-price-page">
        <Container fluid>
          <PrintProductChartHeader type={type} productImg={productImg} {...props} />
        </Container>
        <PrintProductDescription productDescription={view?.description || productDescription} />
        {marketDataId ? <PrintProductChart marketDataId={marketDataId} quoteUnits={quoteUnits} {...props} /> : null}
      </div>
    </PrintLayout>
  );
};

PrintPriceContent.defaultProps = {
  type: STRUCTURE_ACCUMULATOR.toLowerCase(),
  loading: false,
};

PrintPriceContent.propTypes = {
  onClose: PropTypes.func,
  quoteUnits: PropTypes.string.isRequired,
  accumulatorUnderlyings: PropTypes.object,
  marketData: PropTypes.object,
  description: PropTypes.object,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default memo(PrintPriceContent);
