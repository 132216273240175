import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PricingMiniFormsCard from '../pricingMiniFormsCard/PricingMiniFormsCard';

class PricingMiniFormsList extends Component {

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.items, nextProps.items);
  }

  render() {
    const { items } = this.props;
    return (
      <Row className="d-flex row-size-sm">
        {items
          .map((item, index) => {
            const { id } = item;
            return (
              <Col key={id} xs={12} lg={6} xl={4} className="order-xs-1 order-lg-1 mb-3 xlx-3">
                <PricingMiniFormsCard index={index} {...item} />
              </Col>
            )
          })}
      </Row>
    );
  }
}

PricingMiniFormsList.defaultProps = {
  items: [],
};

PricingMiniFormsList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PricingMiniFormsList;
