import React from 'react';

const EmptyPricingHistory = () => {
  return (
    <svg width="46" height="60">
      <g fill="#FFF" fillRule="evenodd" opacity=".4">
        <path d="M3 2a1.001 1.001 0 0 0 0 2h40a1.001 1.001 0 0 0 0-2H3zm40 4H3C1.346 6 0 4.654 0 3s1.346-3 3-3h40c1.654 0 3 1.346 3 3s-1.346 3-3 3zM3 56a1.001 1.001 0 0 0 0 2h40a1.001 1.001 0 0 0 0-2H3zm40 4H3c-1.654 0-3-1.346-3-3s1.346-3 3-3h40c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
        <path d="M21.203 29h3.595A18.449 18.449 0 0 0 36 12V6H10v6a18.45 18.45 0 0 0 11.203 17zm3.998 2H20.8l-.184-.077A20.445 20.445 0 0 1 8 12V4h30v8c0 8.303-4.951 15.73-12.615 18.923L25.2 31z" />
        <path d="M10 54h26v-6a18.45 18.45 0 0 0-11.203-17h-3.594A18.45 18.45 0 0 0 10 48v6zm28 2H8v-8c0-8.303 4.952-15.73 12.615-18.923L20.8 29h4.4l.185.077A20.445 20.445 0 0 1 38 48v8z" />
        <path d="M12 54a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-22-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-18-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4-17a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6-11a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-2-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-10 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-2-2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-4 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      </g>
    </svg>
  );
};

export default EmptyPricingHistory;
