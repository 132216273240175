import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { submitForm, loadVanillaForm } from 'redux/actions/price';
import VanillaFormFields from './VanillaFormFields';

function VanillaForm(props) {
  const { loadVanillaForm } = props;

  const loadFormCallback = useCallback(loadVanillaForm);

  useEffect(() => {
    loadFormCallback();
  }, [loadFormCallback]);

  const {
    submitting,
    invalid,
    pristine,
    handleSubmit,
    reset,
    ...rest
  } = props;

  return (
    <Form onSubmit={handleSubmit} onReset={reset} noValidate className="price-form price-form-container price-form-vanilla">
      <VanillaFormFields {...rest} />
      <Row>
        <Col xs={12} sm={{ size: 5, offset: 7 }} xl={{ size: 4, offset: 8 }}>
          <Button className="tour-price-btn w-100" color="primary" type="submit" disabled={pristine || invalid || submitting}>
            <FormattedMessage id="form.price.submit"
              defaultMessage="Create Card" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
  loadVanillaForm,
  onSubmit: submitForm,
}, dispatch);

export default connect(null, mapDispatchToProps)(VanillaForm);
