import { ofType, combineEpics } from 'redux-observable';
import { PRICING_FX_CARD_START_DELETE, fxCardDelete, fxCardUpdate } from 'redux/actions/fx';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { cardFxError } from 'redux/epics/price/structures/fx';
import { fxTrailPricesFilter } from 'redux/epics/price/structures/fx';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { deleteFxCardMutation } from 'redux/queries/fx';
import { from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { getCardById } from 'redux/epics/price/structures/swap/utils';

const startCardDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FX_CARD_START_DELETE),
    filter(() => fxTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    switchMap((id) => {
      const card = getCardById(state$.value.fx.trailPrice.cards, id);

      const actions = [
        fxCardUpdate({
          ...card,
          loading: true,
          isLoading: true,
        })];
      return from(actions);
    })
  );

export const cardDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FX_CARD_START_DELETE),
    filter(() => fxTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    debounceTime(200),
    switchMap(id => {
      const card = getCardById(state$.value.fx.trailPrice.cards, id);
      return from(deleteFxCardMutation(id))
        .pipe(
          filter(payload => !!payload),
          switchMap(() => of(fxCardDelete(id))),
          catchError((error) => {
            const actions = [
              cardFxError(card),
              fxCardUpdate({
                ...card,
                loading: false,
                isLoading: false
              })
            ];
            const err = getSingleGraphQlError(error);
            if (err?.message && err.code === 403) {
              return from([
                ...actions,
                notificationErrorSimple(err.message),
              ])
            }

            return from(actions);
          })
        )
    })
  );

export default combineEpics(
  startCardDeleteEpic,
  cardDeleteEpic,
);
