import { combineEpics } from 'redux-observable';
import life from './life';
import destroy from './destroy';
import load from './load';

export default combineEpics(
  life,
  load,
  destroy,
);
