import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/markdown/Markdown';

export const parseDescription = (description) => {
  return typeof description === 'string' ? description.split('\n').filter(Boolean) : [];
}

function DescriptionFormatted(props) {
  const { description } = props;
  const desr = parseDescription(description);
  return (
    desr && desr.length?
      <div data-test="description" className="stucture-description-content">
        {desr.map((value, index) => <Markdown value={value} key={index} />)}
      </div>
      : null
  )
}

DescriptionFormatted.propTypes = {
  description: PropTypes.string.isRequired
}

export default DescriptionFormatted;
