import InlineInput from 'components/form/InlineInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FIELD_TYPE_DEFAULT } from '../../fields';

export const CAPITAL_PROTECTION_FORM_FIELDS_ADDITIONAL = [
  {
    name: 'isAdditinalSection',
    fieldType: FIELD_TYPE_DEFAULT,
    component: InlineInput,
    type: 'checkbox',
    label: <FormattedMessage id="form.price.fields.add-section.label"
      defaultMessage="Add Option" />,
    skip: true
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "optionType2",
    label: <FormattedMessage id="form.price.fields.option-type.label"
      defaultMessage="Option Type 2" />,
    placeHolder: 'Enter Option Type 2',
    justValue: true,
    component: RadioGroup,
    optionsName: 'optionTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "strikeLevel2",
    label: <FormattedMessage id="form.price.fields.strike-level.label"
      defaultMessage="Strike Level 2" />,
    placeHolder: 'Enter Strike Level 2',
    type: "number",
    step: 1,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "participationLevel2",
    label: <FormattedMessage id="form.price.fields.participation-level.label"
      defaultMessage="Participation Level 2" />,
    placeHolder: 'Enter Participation Level 2',
    type: "number",
    step: 1,
    min: 0,
    max: 500,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "capType2",
    label: <FormattedMessage id="form.price.fields.cap-type.label"
      defaultMessage="Cap Type 2" />,
    placeHolder: 'Enter Cap Type 2',
    justValue: true,
    component: RadioGroup,
    optionsName: 'capTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "capLevel2",
    label: <FormattedMessage id="form.price.fields.cap-level.label"
      defaultMessage="Cap Level 2" />,
    placeHolder: 'Enter Cap Level 2',
    type: "number",
    step: 1,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 },
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "barrierType2",
    label: <FormattedMessage id="form.price.fields.barrier-type.label"
      defaultMessage="Barrier Type 2" />,
    justValue: true,
    component: RadioGroup,
    optionsName: 'barrierTypeOptions',
    colOptions: { xs: 12 }
  },
  {
    fieldType: FIELD_TYPE_DEFAULT,
    isVisible: false,
    name: "barrierLevel2",
    label: <FormattedMessage id="form.price.fields.barrier-level.label"
      defaultMessage="Barrier Level 2" />,
    placeHolder: 'Enter Barrier Level 2',
    type: "number",
    step: 1,
    component: InlineInput,
    colOptions: { xs: 12, sm: 8 }
  },
];
