import { BREAKPOINT_XS } from 'components/theme/breakPoints/constants';
import PropTypes from 'prop-types';
import React from 'react';

const OpenTradesTableItemBlock = props => {
  const { name, label, children, screenSize } = props;
  return (
    <div className={`app-table-col size-table-${name}`}>
      {screenSize === BREAKPOINT_XS ?
        <div className="app-table-xs-title d-inline-block d-sm-none">
          {label}
        </div>
        : null}
      {children}
    </div>
  );
};

OpenTradesTableItemBlock.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default OpenTradesTableItemBlock;
