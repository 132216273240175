import Modal from 'components/modal/Modal';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { PRICING_MINI_CARD_TYPE_DEFAULT } from '../pricinigMiniForm/constants';
import TradeForm from './TradeForm';

const TradeModal = props => {
  const { disabled, trailId, type } = props;
  const [isOpen, toggleModal] = useState(false);

  const onCloseModal = () => {
    toggleModal(false);
  }

  const toogle = () => {
    toggleModal(!isOpen);
  }

  return (
    <Fragment>
      <Button
        type="button"
        className="mb-sm-0 w-100 mr-0 btn-input-h btn-buy text-uppercase position-relative"
        id="pricing-mini-trade"
        disabled={(type === PRICING_MINI_CARD_TYPE_DEFAULT && !trailId) || disabled}
        onClick={toogle}>
        <FormattedMessage id="price.trade"
          defaultMessage="Trade" />
      </Button>
      <Modal
        btnType="primary"
        btnClass="btn-icon"
        isOpen={isOpen}
        onToggle={onCloseModal}
        onClose={onCloseModal}
        component={<TradeForm {...props} />} />
    </Fragment>
  );
}

TradeModal.defaultProps = {
  disabled: true,
}

TradeModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  countDown: PropTypes.number,
};

export default TradeModal;
