import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import min from 'lodash/min';
import { SOLVE_FOR_COUPON_VALUE } from 'redux/reducers/structured-products';

const excludePropsFromSchedule = (schedule = [], fieldNames = [], compare) => {
  return fieldNames?.length ? schedule.map(item => {
    let rowCopy = { ...item };
    fieldNames.forEach((key) => {
      rowCopy[key] = isFunction(compare) ? compare(rowCopy[key]) : null;
    });
    return rowCopy;
  }
  ) : schedule;
};

const replacePropsFromSchedule = (schedule = [], fieldNames = [], compare, scheduleUserInputs = {}) => {
  return fieldNames?.length ? schedule.map((item, rowIndex) => {
    let rowCopy = { ...item };
    fieldNames.forEach((key) => {
      const userInputValue = scheduleUserInputs && parseFloat(scheduleUserInputs[rowIndex]?.[key]);
      rowCopy[key] = userInputValue ? compare(userInputValue) : null;
    });
    return rowCopy;
  }
  ) : schedule;
};

const resetScheduleUserInputsProps = (minValue, scheduleUserInputs = {}) => {
  if(scheduleUserInputs) {
    for (const [index, {autocallTriggerLevel}] of Object.entries(scheduleUserInputs)) {
      if (minValue >= Number(autocallTriggerLevel)) {
        delete scheduleUserInputs[index]
      }
    }
  }

  return scheduleUserInputs
};

const compareMinStepDownRow = minValue => value => value >= minValue ? value : null;

export const saveUserInputs = (fieldName, formValues = {}) => {
  const { stepDown } = formValues;
  if (stepDown && ['strikeLevel', 'barrierLevel'].includes(fieldName)) {
    return true;
  }

  return false;
}

const excludeScheduleChanges = (schedule = [], fieldName, formValues = {}, scheduleUserInput = {}) => {
  const { snowball, stepDown, solveFor } = formValues;

  if (stepDown && ['strikeLevel', 'barrierLevel'].includes(fieldName)) {
    const minValue = min([formValues.strikeLevel, formValues.barrierLevel]);
    return replacePropsFromSchedule(schedule, ['autocallTriggerLevel'], compareMinStepDownRow(minValue), resetScheduleUserInputsProps(minValue, scheduleUserInput));
  }

  if (solveFor === SOLVE_FOR_COUPON_VALUE) schedule = excludePropsFromSchedule(schedule, ['couponLevel']);

  if (fieldName === 'snowball' && snowball) {
    return stepDown ? excludePropsFromSchedule(schedule, ['couponTriggerLevel', 'autocallTriggerLevel']) : null;
  }

  if (fieldName === 'step') {
    return excludePropsFromSchedule(schedule, stepDown ? ['autocallTriggerLevel'] : ['couponTriggerLevel', 'autocallTriggerLevel']);
  }

  if (fieldName === 'stepDown' && !stepDown) {
    return excludePropsFromSchedule(schedule, ['autocallTriggerLevel']);
  }

  if (['maturity', 'frequency', 'snowball'].includes(fieldName)) {
    return null;
  }

  if (['autocallTriggerLevel', 'couponTriggerLevel', 'couponLevel'].includes(fieldName)) {
    return excludePropsFromSchedule(schedule, [fieldName]);
  }

  if (fieldName === 'firstObservationIn') return excludePropsFromSchedule(schedule, ['autocallTriggerLevel']);

  return schedule;
};

export const prepareShceduleResponse = (schedule = [], formValues = {}) => {
  const { stepDown, barrierLevel, strikeLevel, barrierType } = formValues || {};
  const barrierLevelFloat = parseFloat(barrierLevel);
  let minAutocallTriggerLevel = barrierLevelFloat;
  if (!barrierLevelFloat && barrierType === 'None') {
    const strikeLevelFloat = parseFloat(strikeLevel);
    minAutocallTriggerLevel = strikeLevelFloat;
  }
  if (stepDown && Array.isArray(schedule) && minAutocallTriggerLevel) {
    return schedule.map(({ autocallTriggerLevel, ...rest }) => {
      const autocallTriggerLevelFloat = parseFloat(autocallTriggerLevel);
      const autocallTriggerLevelValue = autocallTriggerLevelFloat < minAutocallTriggerLevel ? minAutocallTriggerLevel : autocallTriggerLevelFloat;
      return {
        ...rest,
        autocallTriggerLevel: !isNaN(autocallTriggerLevelValue) ? autocallTriggerLevelValue : null,
      }
    })
  }
  return schedule;
};

export default excludeScheduleChanges;
