import { timer } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { constants } from 'react-redux-alerts';
import { API_FAIL, FE_CACHED, USER_ONLINE_STATUS } from '../errors/actions';
import { filter, mergeMap, mapTo } from 'rxjs/operators';
import { INFO_MESSAGES_USER_PAGE } from 'redux/epics/info-messages';

const ALERT_TIMEOUT = 5000;
const ALERTS_NO_TIMEOUT = [
  API_FAIL,
  FE_CACHED,
  USER_ONLINE_STATUS,
  INFO_MESSAGES_USER_PAGE
];

const closeAlertEpics = action$ => action$.pipe(
  ofType(constants.CREATE_ALERT),
  filter(({ alertName }) => !ALERTS_NO_TIMEOUT.includes(alertName)),
  mergeMap(({ alertName }) =>
    timer(ALERT_TIMEOUT)
      .pipe(
        mapTo({
          type: constants.DISMISS_ALERT,
          alertName
        }))
  )
);

export default combineEpics(
  closeAlertEpics,
);
