import Loader from 'components/common/loader/Loader';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { DEBOUNCE_TIME } from 'constants.js';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';

const AutoComplete = props => {
  const {
    selectedSingleOption,
    selectedActiveSingleOption,
    client,
    query,
    input,
    queryVariables,
    onInputChangeValue,
    placeHolder,
    label,
    mapResponse,
    field,
    formValues,
    disabled,
    fetchPolicy,
    onAutocompleteLoaded,
    queryFilter,
    bgLoaderOverlay,
    ...rest
  } = props;

  const [formValuesState, setFormValues] = useState(null);
  const [firstRequest, setFirstRequest] = useState(true);
  const [options, setOptions] = useState(null);
  const [isLoading, setLoader] = useState(false);

  const getOptionsQuery = async (formValues = {}, value) => {
    let variables = typeof queryVariables === 'function' ? queryVariables(formValues, value) : {};
    if (isObject(variables) && queryFilter) {
      variables['filter'] = queryFilter;
    }

    const response = await client.query({
      query,
      variables,
      fetchPolicy,
    }).catch(e => {
      console.log(e);
    });
    return response;
  }

  const _getData = async ({ formValues, value } = {}, firstRequest) => {
    setLoader(true);
    try {
      const { data } = await getOptionsQuery(formValues, value) || {};
      setResponse(data, firstRequest);
    } catch (e) {
      console.error(e.message);
    }
    setLoader(false);
  }

  const getData = debounce(_getData, DEBOUNCE_TIME);

  const setResponse = (data = {}, firstRequestParam = firstRequest) => {
    const options = typeof mapResponse === 'function' ? mapResponse(data) : [];
    setOptions(options);

    if (firstRequestParam) {
      setFirstRequest(!firstRequestParam);
      if (typeof onAutocompleteLoaded === 'function') {
        onAutocompleteLoaded(options, input);
      }
    }
  }

  const onInputChange = field => {
    const { value } = field;
    if(value) getData({ formValues, value }, false);
  }

  const onChangeFilter = field => {
    const { name, e: value } = field;
    if (typeof onInputChangeValue === 'function') onInputChangeValue({ name, value, options });
    const values = { ...formValuesState, [name]: value };
    setFormValues(values);
  }

  useEffect(() => {
    if (!isEqual(formValues, formValuesState)) {
      setFormValues(formValues);
      getData(formValues, firstRequest);
    }
  }, [formValues, formValuesState, firstRequest]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="loader-wrapper">
      <InlineSelectInput
        {...rest}
        {...field}
        disabled={disabled}
        input={input}
        label={label}
        placeHolder={placeHolder}
        options={options}
        justValue={true}
        className="select-border"
        selectedSingleOption={selectedSingleOption && firstRequest && options?.length === 1}
        selectedActiveSingleOption={selectedActiveSingleOption}
        onChangeFilter={onChangeFilter}
        onInputChange={onInputChange}
      />
      {isLoading ?
        <Fragment>
          <Loader className="loader-bottom" />
          {bgLoaderOverlay && <div className="loader-overlay overlay-card-bg"></div>}
        </Fragment>
        : null}
    </div>
  )
};

AutoComplete.defaultProps = {
  placeHolder: 'Please start typing text',
  formValues: {},
  disabled: false,
  selectedSingleOption: false,
  fetchPolicy: GRAPHQL_NETWORK_ONLY,
};

AutoComplete.propTypes = {
  placeHolder: PropTypes.string,
  query: PropTypes.object.isRequired,
  onInputChangeValue: PropTypes.func,
  onAutocompleteLoaded: PropTypes.func,
  queryVariables: PropTypes.func,
  input: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  mapResponse: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selectedSingleOption: PropTypes.bool,
  fetchPolicy: PropTypes.string,
};

export default compose(
  memo,
  withApollo,
)(AutoComplete);
