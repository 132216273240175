export const PRICING_FX = '@PRICING/FX';
export const PRICING_FX_FORM_LOAD = `${PRICING_FX}/FORM_LOAD`;
export const PRICING_FX_FORM_OUTPUT = `${PRICING_FX}/FORM_OUTPUT`;
export const PRICING_FX_DATA_LOADED = `${PRICING_FX}/FORM_DATA_LOADED`;
export const PRICING_FX_CARD_CREATE = `${PRICING_FX}/CARD/CREATE`;
export const PRICING_FX_CARD_DELETE = `${PRICING_FX}/CARD/DELETE`;
export const PRICING_FX_CARD_START_DELETE = `${PRICING_FX}/CARD/START_DELETE`;
export const PRICING_FX_CARD_UPDATE = `${PRICING_FX}/CARD/UPDATE`;
export const PRICING_FX_CARDS_UPDATE = `${PRICING_FX}/CARDS/UPDATE`;
export const PRICING_FX_CARDS_FETCH_MORE = `${PRICING_FX}/CARDS/FETCH_MORE`;
export const PRICING_FX_COMMODITY_UPDATE = `${PRICING_FX}/UPDATE/COMMODITY`;
export const PRICING_FX_REQUEST_PRICE = `${PRICING_FX}/CARD/REQUEST/PRICE`;
export const PRICING_FX_CONFIRMATION_MODAL_CANCEL = `${PRICING_FX}/REQUEST/CONFIRMATION_MODAL_CANCEL`;
export const PRICING_FX_CONFIRMATION_MODAL_APPROVE = `${PRICING_FX}/REQUEST/CONFIRMATION_MODAL_APPROVE`;

export const loadFxForm = () => ({ type: PRICING_FX_FORM_LOAD });

export const loadedFxData = payload => ({
  type: PRICING_FX_DATA_LOADED,
  payload,
});

export const fxCardCreate = payload => ({
  type: PRICING_FX_CARD_CREATE,
  payload,
});

export const fxCardDelete = payload => ({
  type: PRICING_FX_CARD_DELETE,
  payload,
});

export const fxCardStartDelete = payload => ({
  type: PRICING_FX_CARD_START_DELETE,
  payload,
});

export const fxCardUpdate = payload => ({
  type: PRICING_FX_CARD_UPDATE,
  payload,
});

export const fxCardsUpdate = payload => ({
  type: PRICING_FX_CARDS_UPDATE,
  payload,
});

export const fxCardsFetchMore = payload => ({
  type: PRICING_FX_CARDS_FETCH_MORE,
  payload
});


export const fxCommodityUpdateAction = payload => ({
  type: PRICING_FX_COMMODITY_UPDATE,
  payload
});

export const fxRequestPriceAction = payload => ({
  type: PRICING_FX_REQUEST_PRICE,
  payload
});

export const fxApproveModalAction = payload => ({
  type: PRICING_FX_CONFIRMATION_MODAL_APPROVE,
  payload
});

export const fxCancelModalAction = payload => ({
  type: PRICING_FX_CONFIRMATION_MODAL_CANCEL,
  payload
});

