import PropTypes from 'prop-types';
import React from 'react';

function ButtonRemove(props) {
  const { result, onRemove, className, children } = props;

  function remove() {
    onRemove(result);
  }

  return (
    <div className={className} onClick={remove}>
      {children}
    </div>
  )
}

ButtonRemove.propTypes = {
  onRemove: PropTypes.func.isRequired,
  result: PropTypes.any.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ButtonRemove;
