import PropTypes from 'prop-types';
import React from 'react';

function ChangeOrderBlock(props) {
  const { value, label, onChange, orderBy, disabled } = props;

  const changeOrder = () => {
    if (typeof onChange === 'function') onChange(value);
  }

  return (
    <div
      className={'app-table-col col-order size-table-' + value + (value === orderBy ? ' active' : '')}
      onClick={!disabled ? changeOrder : null}>
      {label}
    </div>
  )
}

ChangeOrderBlock.defaultProps = {
  orderBy: 'createdAt',
  disabled: false,
};

ChangeOrderBlock.propTypes = {
  onChange: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default ChangeOrderBlock;
