import { DEFAULT_APP_TITLE } from 'constants.js';

export const DEFAULT_THEME = 'agile';

const THEME_AGILE = {
  texts: {
    copyright: 'Marex Solutions Agile',
  },
  images: {
    logo: require('assets/images/logo@3x.png').default,
    loginLogo: require('assets/images/logo@3x.png').default,
  },
  styles: null,
  name: DEFAULT_THEME,
  meta: {
    defaultTitle: DEFAULT_APP_TITLE,
    loginTitle: 'Log In',
    loginDescription: 'Log In',
    description: 'AGILE is our Commodity Hedging Platform, freely available to all clients.',
    favicon: '/marex-icon.png',
  }
};

export default THEME_AGILE;
