import validateJs from 'utils/validate.js';
import { PRICE_NUMERICALITY } from 'components/trade/swap/swapTradeFormValidate';

export const DEFAULT_ORDER_VALIDATIONS = (data, props) => {
  return ({
    price: {
      presence: true,
      numericality: PRICE_NUMERICALITY(props),
    },
    quantity: {
      presence: true,
      numericality: {
        greaterThan: 0,
      },
    },
    timeInForce: {
      presence: true,
    },

  });
};

export const validate = (data, props) => {
  if(data instanceof Array) return undefined;
  const SCHEMA = {
    ...DEFAULT_ORDER_VALIDATIONS(data, props),
  };

  return validateJs(data, SCHEMA) || {}
}
