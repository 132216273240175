import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { Row, Col, Button, Form } from 'reactstrap';
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InlineInput } from 'components/form/InlineInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { FORM_SUBMIT_SUCCESS_TIMEOUT } from 'constants.js';
import validate from './DashboardRequestFormValidate';
import PageMeta from 'components/common/PageMeta';
import { connect } from 'react-redux';
import { setDashboardRequestAction } from 'redux/actions/auth';
import { bindActionCreators, compose } from 'redux';
import { apolloErrors } from 'apollo.js';
import { CREATE_DASHBOARD_REQUEST_FORM } from './query';

export const DASHBOARD_REQUEST_FORM = 'DashboardRequestForm';

class DashboardRequestForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  submit = async data => {
    const { mutate } = this.props;
    try {
      let response = await mutate({
        variables: {
          requestDetails: data.details,
        }
      }).then((resp) => {
        this.setState({ submitted: resp.data.createDashboardRequest });
      });

      this.runResetFormTimeout();

      return response.data;
    }
    catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  };

  runResetFormTimeout = () => {
    const { submitted } = this.state;
    const { setDashboardRequestAction } = this.props;
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
      if (submitted) {
        setDashboardRequestAction(true);
      }
    }, FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(DASHBOARD_REQUEST_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  closeModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  render() {
    const { pristine, handleSubmit, invalid, submitting, error, intl: { formatMessage } } = this.props;
    const { submitted } = this.state;
    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage('Request successfully sent!', 'Marex Solutions will be in touch with you soon to setup your custom dashboard.')} />
        :
        <div className="dashboard-request-form">
          <PageMeta title="Request Dashboard" />
          <div className="modal-header">
            <div className="modal-title">
              <FormattedMessage id="custom-request.title"
                defaultMessage="Request Dashboard"
              />
            </div>
          </div>
          <MainErrorMessage error={error} />
          <Form onSubmit={handleSubmit(this.submit)} noValidate>
            <Field
              name="details"
              type="textarea"
              label={
                formatMessage({
                  id: 'form.provide-details',
                  defaultMessage: 'Please, provide details:'
                })
              }
              placeHolder={formatMessage({
                id: 'form.provide-details-fulls',
                defaultMessage: 'Please, provide us details regarding the dashboard you would like to have access to.'
              })}
              minLength={2}
              rows="4"
              maxLength={255}
              component={InlineInput} />

            <Row className="text-right row-size-sm">
              <Col xs={6} md={{ size: 3, offset: 6 }}>
                <Button color="default" type="button" className="w-100" disabled={submitting} onClick={this.closeModal}>
                  <FormattedMessage id="common/cancel"
                    defaultMessage="Cancel" />
                </Button>
              </Col>
              <Col xs={6} md={3}>
                <Button type="submit" color="primary" disabled={pristine || invalid || submitting} className="w-100">
                  <FormattedMessage id="common/submit"
                    defaultMessage="Submit" />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
    )
  }
}

const reduxFormOptions = {
  form: DASHBOARD_REQUEST_FORM,
  validate
};

const mapDispatchToProps = dispatch => bindActionCreators({ setDashboardRequestAction }, dispatch);

export default compose(
  connect(null, mapDispatchToProps),
  graphql(CREATE_DASHBOARD_REQUEST_FORM),
  reduxForm(reduxFormOptions),
  injectIntl,
)(DashboardRequestForm)
