export const PRICING_MINI_ACCUMULATOR = '@PRICING/MINI_ACCUMULATOR';
export const PRICING_MINI_ACCUMULATOR_CARD_CREATE = `${PRICING_MINI_ACCUMULATOR}/CARD/CREATE`;
export const PRICING_MINI_ACCUMULATOR_CARD_COPY = `${PRICING_MINI_ACCUMULATOR}/CARD/COPY`;
export const PRICING_MINI_ACCUMULATOR_CARD_DELETE = `${PRICING_MINI_ACCUMULATOR}/CARD/DELETE`;
export const PRICING_MINI_ACCUMULATOR_CARD_START_DELETE = `${PRICING_MINI_ACCUMULATOR}/CARD/START_DELETE`;
export const PRICING_MINI_ACCUMULATOR_CARD_UPDATE = `${PRICING_MINI_ACCUMULATOR}/CARD/UPDATE`;
export const PRICING_MINI_ACCUMULATOR_CARDS_UPDATE = `${PRICING_MINI_ACCUMULATOR}/CARDS/UPDATE`;
export const PRICING_MINI_ACCUMULATOR_CARD_DISABLE = `${PRICING_MINI_ACCUMULATOR}/CARDS/DISABLE`;
export const PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE = `${PRICING_MINI_ACCUMULATOR}/CARDS/GET_REFERENCE_PRICE`;
export const PRICING_MINI_ACCUMULATOR_CARD_REFERENCE_PRICE_STOP = `${PRICING_MINI_ACCUMULATOR}/CARDS/GET_REFERENCE_PRICE_STOP`;
export const PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE_FINISH = `${PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE}_FINISH`;
export const PRICING_MINI_ACCUMULATOR_CARD_FIELD_VALUES = `${PRICING_MINI_ACCUMULATOR}/CARDS/FIELDS/VALUES`;
export const PRICING_MINI_ACCUMULATOR_CARDS_FETCH_MORE = `${PRICING_MINI_ACCUMULATOR}/CARDS/FETCH_MORE`;
export const PRICING_MINI_ACCUMULATOR_CARDS_LOAD = `${PRICING_MINI_ACCUMULATOR}/CARDS_LOAD`;
export const PRICING_MINI_ACCUMULATOR_CARDS_LOADED = `${PRICING_MINI_ACCUMULATOR}/CARDS_LOADED`;
export const PRICING_MINI_ACCUMULATOR_CARDS_LOADING = `${PRICING_MINI_ACCUMULATOR}/LOADING`;
export const PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY = `${PRICING_MINI_ACCUMULATOR}/PAGE/DESTIROY`;
export const PRICING_MINI_ACCUMULATOR_BANK_HOLIDAYS_SET = `${PRICING_MINI_ACCUMULATOR}/BANK-HOLIDAYS`;

export const loadMiniAccumulatorCards = payload => ({ type: PRICING_MINI_ACCUMULATOR_CARDS_LOAD, payload });
export const loadedMiniAccumulatorCardsData = payload => ({ type: PRICING_MINI_ACCUMULATOR_CARDS_LOADED, payload });

export const miniAccumulatorCardCreate = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_CREATE,
  payload,
});

export const miniAccumulatorCardCopy = (from, to) => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_COPY,
  payload: {
    from, to
  },
});

export const miniAccumulatorCardDelete = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_DELETE,
  payload,
});

export const miniAccumulatorCardStartDelete = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_START_DELETE,
  payload,
});

export const miniAccumulatorCardUpdate = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_UPDATE,
  payload,
});

export const miniAccumulatorCardDisableField = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_DISABLE,
  payload,
});

export const miniAccumulatorCardGetReferencePrice = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE,
  payload,
});

export const stopCalculatingReferencePrice = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_REFERENCE_PRICE_STOP,
  payload,
});

export const miniAccumulatorCardGetReferencePriceFinish = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE_FINISH,
  payload,
});

export const miniAccumulatorCardsUpdate = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARDS_UPDATE,
  payload,
});

export const miniAccumulatorCardsFetchMore = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARDS_FETCH_MORE,
  payload
});

export const setMiniAccumulatorCardsLoader = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARDS_LOADING,
  payload
});

export const setMiniAccumulatorFieldValues = payload => ({
  type: PRICING_MINI_ACCUMULATOR_CARD_FIELD_VALUES,
  payload
});

export const destroyMiniAccumulatorPage = () => ({ type: PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY });

export const bankHolidaysSet = payload => ({
  type: PRICING_MINI_ACCUMULATOR_BANK_HOLIDAYS_SET,
  payload
});
