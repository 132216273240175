import moment from 'moment';
import { setToken } from 'redux/actions/auth';
import store from 'store.js';
import logger, { LOGIN_TIME, USER_ID } from 'utils/Logger';

export const storage = localStorage;
export const STORAGE_NAME = 'AUTH_TOKEN';

export const getAuthToken = (reduxStore = store) => {
  let storeToken;
  if (reduxStore && reduxStore.getState) {
    const state = reduxStore.getState();
    storeToken = state?.auth?.token;
  }

  const storageToken = storage.getItem(STORAGE_NAME);
  if(reduxStore && storeToken !== storageToken) {
    reduxStore.dispatch(setToken(storageToken));
    return storageToken;
  }
  return storeToken || storageToken;
};

export const setAuthToken = (token, saveToStore = false, reduxStore = store) => {
  storage.setItem(STORAGE_NAME, token);
  if (saveToStore) {
    reduxStore.dispatch(setToken(token));
  }
}

export const removeAuthToken = (manually = false) => {
  storage.removeItem(USER_ID);
  storage.removeItem(STORAGE_NAME);
  logger.setIdentity();
  const start = parseInt(localStorage.getItem(LOGIN_TIME) || 0);
  const end = Date.now();
  logger.info('The user logged Out.', {
    manually,
    start,
    end,
    duration: end - start,
    formated: moment.duration(end - start).humanize()
  });
}

export const setAccount = (_id) => {
  storage.setItem(USER_ID, _id);
  logger.setIdentity();
  logger.startApp();
}
