import { PRICINIG_MINI_FORM } from 'pages/price/mini/components/pricinigMiniForm/PricinigMiniForm';
import { miniAccumulatorCardUpdate } from 'redux/actions/accumulator-mini';
import { REDUX_FORM_FIELD_CHANGE } from 'redux/epics/constants';
import { distinctObjectChanges } from 'redux/epics/utils';
import { from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { getCardId } from '../structures/swap/utils';
import { findPricinigMiniCard } from './limitations';
import { pricinigMiniFormFilter } from './utils';

export const resetResultFieldPriceEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => {
      return (
        action.type === REDUX_FORM_FIELD_CHANGE
        && action.meta.form && action.meta.form.indexOf(`${PRICINIG_MINI_FORM}_`) >= 0
      );
    }),
    filter(pricinigMiniFormFilter),
    filter(({ payload }) => !!payload && state$.value.accumulatorMini?.cards?.length),
    debounceTime(100),
    map(({ meta, payload }) => ({ form: meta.form, field: meta.field, payload })),
    distinctUntilChanged(distinctObjectChanges),
    filter(({ form }) => {
      const cardId = getCardId({ form });
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      return !card?.isLoading || card?.trailId;
    }),
    switchMap(({ form, field }) => {
      const cardId = getCardId({ form });
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      const fields = Array.isArray(card?.fields) ? card.fields : [];
      let solvedFields = [];
      let isCardResult = false;
      let newCard = {
        ...card,
        fields: fields.map((cardField) => {
          const isResult = cardField.isResult && cardField.name === field;
          if (cardField.isLock && cardField.disabled) {
            solvedFields.push(cardField.name)
          };
          if (!isCardResult && cardField.isResult) isCardResult = true;
          return { ...cardField, isResult };
        }),
        isCardResult,
      };
      if (!solvedFields.includes(field)) {
        newCard = {
          ...newCard,
          trailId: null,
          tradeId: null,
        };
      }
      return from([
        miniAccumulatorCardUpdate(newCard),
      ])
    }),
  );

export default resetResultFieldPriceEpic;
