import validateJs from 'utils/validate.js';
import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';

export default function validate(data) {
  return validateJs(data, {
    payoutCurrency: {
      presence: true,
    },
    contractExpiry: {
      presence: true,
    },
    quantity: {
      presence: true,
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
      },
    },
    strikeLevel: {
      presence: true,
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
      },
    },

  }) || {};
}
