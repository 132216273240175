import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ROUTE_NOT_FOUND } from 'pages/constants';
import Modal from 'components/modal/Modal';
import StaticPage from '../staticPage/StaticPage';

class StaticPageModalRoute extends Component {

  onCloseModal = () => {
    const { history } = this.props;
    history.goBack()
  }

  render() {
    const { match: { params: { slug } } } = this.props;
    return (
      slug ?
        <Modal
          isOpen={true}
          component={<StaticPage seo={slug} />}
          onToggle={this.onCloseModal}
          showBtnClose={false}
        />
        :
        <Redirect to={ROUTE_NOT_FOUND} />
    )
  }
}

export default StaticPageModalRoute;
