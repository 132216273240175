import React, { PureComponent } from 'react';
import TourTooltip from './TourTooltip';
import PropTypes from 'prop-types';
import { TOOLTIP_TIMEOUT } from 'constants.js';

class TourToolipDelay extends PureComponent {

  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    const { delay } = this.props;
    this.timer = setTimeout(() => {
      this.moveNext();
    }, delay);
  }

  moveNext = () => {
    const { next } = this.props;
    if (typeof next === 'function') {
      next();
    }
  }

  handleNextClick = e => {
    clearTimeout(this.timer);
    this.moveNext();
  }

  render() {
    const { next, ...rest } = this.props;
    return <TourTooltip next={this.handleNextClick} {...rest} />
  }
}

TourToolipDelay.defaultProps = {
  delay: TOOLTIP_TIMEOUT,
}

TourToolipDelay.propTypes = {
  required: PropTypes.array,
  delay: PropTypes.number.isRequired,
  next: PropTypes.func
};

export default TourToolipDelay;
