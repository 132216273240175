import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { loadAccumulatorForm, submitForm } from 'redux/actions/price';
import { TOUR_STEPS_PRICING_FORM } from '../../../tour/TourPricingForm';
import AccumulatorFormFields, { accumulatorFormFieldsProps } from './AccumulatorFormFields';
import { priceFormPropTypes } from '../priceFormPropTypes';

function AccumulatorForm(props) {
  const {
    loadForm,
    addSteps,
    submitting,
    invalid,
    pristine,
    handleSubmit,
    reset,
    ...rest
  } = props;
  useEffect(() => {
    if (typeof addSteps === 'function') {
      addSteps(TOUR_STEPS_PRICING_FORM);
    }
    loadForm();
  }, [loadForm, addSteps])

  return (
    <Form onSubmit={handleSubmit} onReset={reset} noValidate className="price-form price-form-container price-form-accumulator">
      <div className="price-form-fields">
        <AccumulatorFormFields {...rest} />
      </div>
      <Row>
        <Col xs={6} sm={4} xl={{ size: 3, offset: 6 }}>
          <Button color="default" type="reset" className="w-100" disabled={pristine || submitting}>
            <FormattedMessage id="form.price.reset"
              defaultMessage="Reset" />
          </Button>
        </Col>
        <Col xs={6} sm={{ size: 4, offset: 4 }} xl={{ size: 3, offset: 0 }}>
          <Button className="tour-price-btn w-100" color="primary" type="submit" disabled={invalid || submitting}>
            <FormattedMessage id="form.price.submit"
              defaultMessage="Price" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

AccumulatorForm.propTypes = {
  ...priceFormPropTypes,
  ...accumulatorFormFieldsProps,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  loadForm: loadAccumulatorForm,
  onSubmit: submitForm,
}, dispatch);

export { AccumulatorForm as Component };
export default connect(null, mapDispatchToProps)(AccumulatorForm);
