export const NOTIFICATIONS = '@NOTIFICATIONS';
export const NOTIFICATION_NEW = `${NOTIFICATIONS}/NEW`;
export const NOTIFICATION_READ = `${NOTIFICATIONS}/READ`;
export const NOTIFICATION_UPDATE = `${NOTIFICATIONS}/SINGLE/UPDATE`;
export const NOTIFICATION_REPRIСING = `${NOTIFICATIONS}/REPRICE`;
export const NOTIFICATION_REPRICING_RESET = `${NOTIFICATIONS}/REPRICING_RESET`;
export const NOTIFICATION_EDIT = `${NOTIFICATIONS}/EDIT`;
export const NOTIFICATIONS_UPDATE = `${NOTIFICATIONS}/UPDATE`;
export const NOTIFICATIONS_GET = `${NOTIFICATIONS}/GET`;
export const NOTIFICATIONS_COUNTER = `${NOTIFICATIONS}/COUNTER`;
export const NOTIFICATION_TOGGLE = `${NOTIFICATIONS}/TOGGLE`;
export const SHOW_ONLY_READ = 'SHOW_ONLY_READ';
export const NOTIFICATION_SHOW_ONLY_READ_TOGGLE = `${NOTIFICATIONS}/SHOW_ONLY_READ_TOGGLE`;
export const NOTIFICATION_READ_ALL = `${NOTIFICATIONS}/READ_ALL`;

export const notificationAdd = payload => ({
  type: NOTIFICATION_NEW,
  payload
});

export const notificationUpdate = payload => ({
  type: NOTIFICATION_UPDATE,
  payload
});

export const notificationUpdateCounter = payload => ({
  type: NOTIFICATIONS_COUNTER,
  payload
});

export const notificationRead = payload => ({
  type: NOTIFICATION_READ,
  payload
});

export const notificationRePriceAction = payload => ({
  type: NOTIFICATION_REPRIСING,
  payload
});

export const notificationRePriceReset = payload => ({
  type: NOTIFICATION_REPRICING_RESET,
  payload
});

export const notificationEditAction = payload => ({
  type: NOTIFICATION_EDIT,
  payload
});

export const notificationsToggleAction = payload => ({
  type: NOTIFICATION_TOGGLE,
  payload
});

export const notificationsGetAction = () => ({
  type: NOTIFICATIONS_GET
})

export const notificationsUpdateAction = payload => ({
  type: NOTIFICATIONS_UPDATE,
  payload
});

export const notificationsOnlyReadToggleAction = (status = false) => ({
  type: NOTIFICATION_SHOW_ONLY_READ_TOGGLE,
  payload: status
});
export const notificationsReadAllAction = () => ({
  type: NOTIFICATION_READ_ALL,
});

