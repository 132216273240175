import React, { Fragment } from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import PageMeta from 'components/common/PageMeta';

export const PricePage = props => (
  <Fragment>
    <PageMeta title="Pricing Mini" />
    <ErrorBoundary>
      <Bundle title="Pricing Mini" load={() => import('./PricinigMini')}>
        {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
      </Bundle>
    </ErrorBoundary>
  </Fragment>
);

export default PricePage;
