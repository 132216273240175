
export const getCardById = (cards = [], cardId) => {
  const findIndex = cards.findIndex(({ id }) => id === cardId);
  return findIndex >= 0 ? cards[findIndex] : null
}

export const getCardId = (meta) => {
  const formName = meta.form.split('_');
  return formName[formName.length - 1];
}

export const getCardByFormName = (state, meta, stateName = 'price') => {
  const cards = state.value[stateName].trailPrice.cards || [];
  const cardId = getCardId(meta);
  return getCardById(cards, cardId);
};

export const getErrorsCardByFormName = (state, meta, stateName = 'form') => {
  const { form: formName } = meta;
  const cardForm = state.value[stateName][formName] || { syncErrors: {} }
  return cardForm.syncErrors || {};
};


