import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectTrailAction, badSelectTrailAction } from 'redux/prices/history/pricingHistoryActions';
import PricingHistoryItem from './PricingHistoryItem';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

class PricingHistoryList extends Component {
  shouldComponentUpdate(nextProps) {
    return (nextProps.items && !isEqual(nextProps.items, this.props.items)) || this.props.screenSize !== nextProps.screenSize ||
      !isEqual(this.props.displayColumns, nextProps.displayColumns);
  }

  render() {
    const { items: filterItems = [], currentTrail, onClose, selectTrail, badSelectTrail, screenSize, displayColumns } = this.props;
    const items = filterItems.filter(({ structure }) => !!structure);
    return (
      items?.length ?
        <Fragment>
          {items.map((node, index) => {
            const id = `${node.structure ? node.structure.toLowerCase() : ''}-${node.id}-${index}`;
            return (
              <PricingHistoryItem
                key={id}
                item={node}
                index={index}
                screenSize={screenSize}
                onClose={onClose}
                isSelected={!!currentTrail && currentTrail.id === node.id}
                selectTrail={selectTrail}
                badSelectTrail={badSelectTrail}
                displayColumns={displayColumns}
              />
            )
          })}
        </Fragment>
        : null)
  }
}

const mapStateToProps = createSelector([
  state => state.price.trailPrice
], (currentTrail) => ({ currentTrail })
);

const mapDispatchToProps = dispatch => bindActionCreators({
  badSelectTrail: badSelectTrailAction,
  selectTrail: selectTrailAction
}, dispatch);

PricingHistoryList.propTypes = {
  items: PropTypes.array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingHistoryList);
