import React from 'react';
import Modal from 'components/modal/Modal';
import StaticPage from '../staticPage/StaticPage';
import PropTypes from 'prop-types';

const StaticPageModal = props => {
  const { slug, title } = props;
  return (
    slug ?
      <Modal
        titleBtn={title}
        component={<StaticPage seo={slug} />
        } />
      :
      null
  )
}

StaticPageModal.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default StaticPageModal;
