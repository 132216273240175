import InlineInput from 'components/form/InlineInput';
import { DATE_FORMAT_PRICING_ACCUMULATOR_MINI } from 'constants.js';
import { isWeekday, tommorowDay } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

const PricinigMiniFormExpiry = props => {
  const {
    isLoading = false,
    structureExpiredAtMax,
    filterDate,
    scheduleDates,
    bankHolidays,
    greeksDisplay
  } = props;
  const className = ['d-flex', 'mt-2', greeksDisplay ? 'mb-1' : 'mb-3'].join(' ');
  return (
    <div className={className}>
      <div className="pre-label expiry-label">
        <FormattedMessage id="form.price.fields.expiration.label"
          defaultMessage="Expiry" /> <span className="pl-2 font-weight-bold">
          <FormattedMessage id="scheduleDates"
            defaultMessage={scheduleDates} /></span>
      </div>
      <div className="ml-auto form-control-mini form-control-mini-expiry text-right">
        <Field
          name="structureExpiryDate"
          component={InlineInput}
          type="date"
          filterDate={filterDate}
          excludeDates={bankHolidays}
          minDate={tommorowDay()}
          maxDate={structureExpiredAtMax}
          readOnlyInput={true}
          placeHolder={'Select'}
          disabled={isLoading}
          className="text-right"
          dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
        />
      </div>
    </div>
  );
};

PricinigMiniFormExpiry.defaultProps = {
  structureExpiredAtMax: PropTypes.string.isRequired,
  filterDate: isWeekday,
};

PricinigMiniFormExpiry.propTypes = {
  structureExpiredAtMax: PropTypes.string.isRequired,
  filterDate: PropTypes.func,
};

export default PricinigMiniFormExpiry
