import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { constants } from 'react-redux-alerts';
import { bindActionCreators } from 'redux';
import { notificationUserOnlineStatus, USER_ONLINE_STATUS } from 'redux/errors/actions';
import { setUserOnlineStatusAction } from 'redux/actions/auth';

const USER_ONLINE_REQUEST_TIMEOUT = 5000;

const detectNavigatorOnlineStatus = () => {
  const { onLine: online } = navigator;
  return online
};

export const UserOnlineStatusContext = createContext(detectNavigatorOnlineStatus);

export function withUserOnlineStatusContext(WrappedComponent) {
  return function WrapperComponent(props) {
    return (
      <UserOnlineStatusContext.Consumer>
        {(onlineStatus) => {
          return <WrappedComponent {...props} onlineStatus={onlineStatus} />
        }}
      </UserOnlineStatusContext.Consumer>
    )
  }
}

class UserOnlineStatusProvider extends Component {

  constructor(props) {
    super(props);
    this.detectOnlineStatus = debounce(this._detectOnlineStatus.bind(this), 350);
    this.fecthPixel = debounce(this._fecthPixel.bind(this), 350); // Use debounce to make request with small delay after connection appear. Example LAN offline device connect via WIFI
    this.state = { online: detectNavigatorOnlineStatus() };
    this.interval = null;
    this.init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.online !== nextState.online;
  }

  init() {
    this.detectOnlineStatus();
    window.addEventListener('offline', this.detectOnlineStatus); // Events for navigator.onLine not detect LAN offline
    window.addEventListener('online', this.detectOnlineStatus);
    this.initFecth();
  }

  initFecth() {
    this.interval = setInterval(() => {
      this.fecthPixel();
    }, USER_ONLINE_REQUEST_TIMEOUT);
  }

  _fecthPixel = async () => {
    let online = true;
    try {
      const hash = (Math.random() + Date.now()).toString(36);
      await fetch(`/images/pixel.gif?query=${hash}`);
    } catch (e) {
      online = false;
    }

    this.setState({ online });
  }

  _detectOnlineStatus() {
    const online = detectNavigatorOnlineStatus();
    if(online) {
      return this.fecthPixel();
    }
    this.setState({ online });
  }

  componentDidUpdate(prevProps, prevState) {
    const { online: prevStateOnline } = prevState;
    const { online } = this.state;
    const { notificationUserOnlineStatus, dismisAlert, setUserOnlineStatusAction } = this.props;
    if (prevStateOnline === true && online === false) {
      notificationUserOnlineStatus(online);
    } else {
      dismisAlert();
    }
    setUserOnlineStatusAction(online);
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.detectOnlineStatus);
    window.removeEventListener('online', this.detectOnlineStatus);
    clearInterval(this.interval);
  }

  render() {
    const { children } = this.props;
    return (
      <UserOnlineStatusContext.Provider value={this.state}>
        {children}
      </UserOnlineStatusContext.Provider>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  notificationUserOnlineStatus,
  dismisAlert: () => ({
    type: constants.DISMISS_ALERT,
    alertName: USER_ONLINE_STATUS
  }),
  setUserOnlineStatusAction,
}, dispatch);

UserOnlineStatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
  notificationUserOnlineStatus: PropTypes.func.isRequired,
  dismisAlert: PropTypes.func.isRequired,
  setUserOnlineStatusAction: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(UserOnlineStatusProvider);
