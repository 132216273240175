import { REDUX_FORM_FIELD_CHANGE, REDUX_FORM_FIELD_UNREGISTER, REDUX_FORM_RESET, REDUX_FORM_FIELD_REGISTER, REDUX_FORM_UPDATE_SYNC_ERRORS } from 'redux/epics/constants';

export const changeFormActionFilter = (formName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName;

export const changeActionFilter = (formName, fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName
    && action.meta.field === fieldName;

export const changeFormErrors = (formName) =>
  action => action.type === REDUX_FORM_UPDATE_SYNC_ERRORS
    && action.meta.form === formName;

export const changeActionFilterRegExp = (formName, fieldName) => {
  const reg = new RegExp(fieldName)
  return action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName
    && reg.test(action.meta.field);
}

export const registerFieldActionDynamicNameFilter = (formName, fieldName) =>
  action => action.type === REDUX_FORM_FIELD_REGISTER
    && action.meta.form === formName
    && action.payload.name && action.payload.name.indexOf(fieldName) >= 0;

export const registerFieldsActionFilter = (formName, fieldNames = []) =>
  action => action.type === REDUX_FORM_FIELD_REGISTER
    && action.meta.form === formName
    && fieldNames.includes(action.payload?.name);

export const changeActionFilterArray = (formName, fieldNames = []) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName
    && fieldNames.includes(action.meta.field);

export const focusActionFilter = (formName, fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName
    && action.meta.field === fieldName;

export const blurActionFilter = (formName, fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form === formName
    && action.meta.field === fieldName;

export const changeAnyFormFieldActionFilter = (fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.field === fieldName;

export const changeFieldFilterByPattern = (fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && new RegExp(fieldName).test(action.meta.field);

export const changeDynamicFormActionFilterArray = (formName, fieldNames = []) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form && action.meta.form.indexOf(formName) >= 0
    && fieldNames.includes(action.meta.field);

export const changeDynamicFormActionFilterArrayExclude = (formName, fieldNamesExclude = []) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form && action.meta.form.indexOf(formName) >= 0
    && !fieldNamesExclude.includes(action.meta.field);

export const changeFormRadioFieldActionFilter = (fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.field && action.meta.field.indexOf(fieldName) >= 0;

export const changeDynamicFormNameActionFilter = (formName, fieldName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form && action.meta.form.indexOf(formName) >= 0
    && action.meta.field === fieldName;

export const changeFormActionDynamicFormFilter = (formName) =>
  action => action.type === REDUX_FORM_FIELD_CHANGE
    && action.meta.form && action.meta.form.indexOf(formName) >= 0

// Dynamic Fields

export const destroyReduxFieldFilter = (formName) =>
  action => {
    return action.type === REDUX_FORM_FIELD_UNREGISTER
      && action.meta.form && action.meta.form.indexOf(formName) >= 0
  };

export const destroyDynamicFormActionFilterArray = (formName, fieldNames) =>
  action => {
    return action.type === REDUX_FORM_FIELD_UNREGISTER
      && action.meta.form && action.meta.form.indexOf(formName) >= 0
      && fieldNames.includes(action.payload.name);
  };

export const destroyDynamicFormActionFilterArrayExclude = (formName, fieldNamesExclude) =>
  action => {
    return action.type === REDUX_FORM_FIELD_UNREGISTER
      && action.meta.form && action.meta.form.indexOf(formName) >= 0
      && !fieldNamesExclude.includes(action.payload.name);
  };


// Reset
export const formResetFilter = formName => action => action.type === REDUX_FORM_RESET && action.meta.form === formName;
