import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import Bundle from "../Bundle";
import LoadingFullPage from "../../components/loading/LoadingFullPage";
import React from "react";
import PageMeta from "../../components/common/PageMeta";

const ExperimentalConfigurableSp = (props) => {
  return (
    <>
      {/* @ts-ignore */}
      <ErrorBoundary>
        <PageMeta title="Experimental Configurable SP" />
        <Bundle
          title="Experimental Configurable SP"
          load={() => import("layouts/PreviewSpLayout")}
        >
          {(Component) =>
            Component ? <Component {...props} /> : <LoadingFullPage />
          }
        </Bundle>
      </ErrorBoundary>
    </>
  );
};

export default ExperimentalConfigurableSp;
