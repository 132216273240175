import Loader from 'components/common/loader/Loader';
import TableFilterDropdownList from 'components/tables/TableHeaderFilter/TableFilterDropdownList';
import TableFilterForm from 'components/tables/TableHeaderFilter/TableFilterForm';
import { getFiltersVisiblity } from 'components/tables/utils';
import debounce from 'lodash/debounce';
import InputFormComponentFactory from 'pages/price/InputFormComponentFactory';
import TableColumnsTogglerWrapper from 'pages/price/PricingHistory/TableColumnsTogglerWrapper';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { DASHBOARD_TRADES_FORM_COLUMNS, DASHBOARD_TRADES_FORM_FILTERS } from './constants';
import { FILTERS_LIST_TRADES, NAV_FILTERS_LIST_TRADES } from './filter/filter';
import { extractFilterVariables } from './filter/filterBuilder';
import OpenTradeDownloadBtn from './OpenTradeDownloadBtn';
import OpenTradesUnwindSection from './OpenTradesUnwindSection'
import { DISPLAY_COLUMNS_TRADES_SORTING } from './sorter';
import { DASHBOARD_REPORT_TRADE_QUERY } from './query';
import validate from './filter/validate';

class TradesTableFilter extends PureComponent {
  constructor(props) {
    const isMyDashboard = Boolean(props.isMyDashboard);
    super(props);
    this.state = {
      filters: extractFilterVariables(FILTERS_LIST_TRADES
        .filter(({ name }) => !isMyDashboard || name !== 'trader'), { dashboardId: props?.dashboardId, isMyDashboard }),
    };
    this.changeFilter = debounce(this._changeFilter, 500);
  }

  _changeFilter = field => {
    const { onChangeFilter } = this.props;
    let sendData = field?.name ? { ...field, key: field.name, value: field.value } : null;
    if (!field.name && field?.target?.name) {
      sendData = {
        ...field,
        key: field.target.name,
        value: field.target.value ? field.target.value : null
      }
    };
    onChangeFilter(sendData);
  }

  clearAllFilters = () => {
    const { onChangeFilter } = this.props;
    onChangeFilter({ key: 'ALL', value: null });
  }

  toggleFilter = (name) => {
    const { filters } = this.state;
    const index = filters.findIndex(item => item.name === name);
    filters[index].isVisible = !filters[index].isVisible;
    this.setState({
      filters: [...filters]
    });
  }

  renderNavFilter = (filter, changeFilter) => (
    <InputFormComponentFactory
      key={`input-${filter.name}`}
      field={filter}
      bsSize={filter.size}
      options={filter.options}
      onChangeFilter={changeFilter}
      placeHolder={filter.placeHolder}
    />
  );

  render() {
    const { displayColumns, isLoading, isActiveAllColumns, downloadVariables, onChangeFilter, conditionsOfChoiceTrades, selectedIds, selectAllChecked, onUnwind, onSelectedAll, isSlackId } = this.props;
    const { filters = [] } = this.state;
    const { visibleFilters, inVisibleFilters } = getFiltersVisiblity(filters);
    return (
      <Fragment>
        <div className="loader-wrapper mb-3">
          <TableFilterForm
            formName={DASHBOARD_TRADES_FORM_FILTERS}
            initialValues={DASHBOARD_TRADES_FORM_FILTERS_INITAL_VALUES}
            filters={visibleFilters}
            validate={validate}
            onChangeFilter={onChangeFilter}
            toggleFilter={this.toggleFilter}
            {...this.props}>
            {({ changeFilter }) => (
              <div className="d-flex">
                {isSlackId &&
                  (<OpenTradesUnwindSection
                    conditionsOfChoiceTrades={conditionsOfChoiceTrades}
                    selectedIds={selectedIds}
                    selectAllChecked={selectAllChecked}
                    onUnwind={onUnwind}
                    onSelectedAll={onSelectedAll}
                  />)}
                <div className="ml-auto d-flex align-items-center justify-content-end">
                  {NAV_FILTERS_LIST_TRADES.map((filter) => {
                    const { name } = filter;
                    return name === "expiryDays" ? (
                      <div key={name} class="ml-4 mr-2 d-flex align-items-center">
                        <div className="mr-2 mb-0 form-control-label">Days Until Expiry</div>
                        {this.renderNavFilter(filter, changeFilter)}
                      </div>
                    ) : this.renderNavFilter(filter, changeFilter);
                  })}
                  <div className="ml-5">
                    <OpenTradeDownloadBtn
                      variables={downloadVariables}
                      query={DASHBOARD_REPORT_TRADE_QUERY}
                    />
                    <TableColumnsTogglerWrapper
                      columns={DISPLAY_COLUMNS_TRADES_SORTING}
                      formName={DASHBOARD_TRADES_FORM_COLUMNS}
                      storageName={'openTrades'}
                      displayColumns={displayColumns}
                      isActiveAllColumns={isActiveAllColumns} />
                  </div>
                  <div className="mr-1 mr-sm-2 mt-1">
                    <TableFilterDropdownList list={inVisibleFilters} onSelect={this.toggleFilter} />
                  </div>
                </div>
              </div>
            )}
          </TableFilterForm>
          {isLoading && visibleFilters?.length ?
            <Fragment>
              <div className="loader-overlay"></div>
              <Loader />
            </Fragment> : null}
        </div>
      </Fragment>
    )
  }
};

TradesTableFilter.defaultProps = {
  isMyDashboard: false,
};

TradesTableFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  dashboardId: PropTypes.number.isRequired,
};

const DASHBOARD_TRADES_FORM_FILTERS_INITAL_VALUES = {
  filterUnderlying: null,
  filterUpdatedAt: null,
  filterType: null,
  filterStatus: null,
  barrierKnocked: false,
};

export default TradesTableFilter;
