import { STRUCTURE_ACCUMULATOR, INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import isNaN from 'lodash/isNaN';
import { change, untouch, isSubmitting } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { PRICING_FORM, setCahrtData, updateFieldParams } from 'redux/actions/price';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, throttleTime } from 'rxjs/operators';
import { changeActionFilter, changeActionFilterArray } from 'utils/reduxFormSelector';

const changeStructureTypeEpic = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(PRICING_FORM, 'structureType')),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    map(({ payload }) => payload),
    distinctUntilChanged(),
    switchMap((type) => {
      const validationProp = type.toLowerCase() === 'consumer' ? 'min' : 'max';
      return action$.pipe(
        filter(changeActionFilter(PRICING_FORM, 'accumulationLevel')),
        map(({ payload }) => {
          const value = parseFloat(payload);
          return !isNaN(value) ? value : null;
        }),
        distinctUntilChanged(),
        map(val => updateFieldParams('barrierLevel', { [validationProp]: val })),
        throttleTime(50)
      )
    })
  );

const setChartDataEpic = (action$, state$) =>
  action$
    .pipe(
      filter(changeActionFilterArray(PRICING_FORM, ['accumulationLevel', 'barrierLevel', 'remainderLevel'])),
      filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR && state$.value.price.underlying),
      debounceTime(150),
      filter(() => !isSubmitting(PRICING_FORM)(state$.value)),
      map(({ payload, meta }) => {
        const value = parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE ? parseFloat(payload) : null;
        const data = {
          ...state$.value.price.chart,
          [meta.field]: value
        }
        return setCahrtData(data);
      })
    );

const remainderFeatureEpic = (action$, state$) =>
  action$
    .pipe(
      filter(changeActionFilter(PRICING_FORM, 'remainderFeature')),
      filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR && state$.value.price.underlying),
      filter(({ payload }) => payload === 'none'),
      filter(() => !isSubmitting(PRICING_FORM)(state$.value)),
      switchMap(() => {
        const data = {
          ...state$.value.price.chart,
          remainderLevel: null
        }
        const actions = [
          setCahrtData(data),
          change(PRICING_FORM, 'remainderLevel', null),
          untouch(PRICING_FORM, 'remainderLevel')
        ];
        return from(actions);
      })
    );

export default combineEpics(
  changeStructureTypeEpic,
  setChartDataEpic,
  remainderFeatureEpic
);
