import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { reset } from 'redux-form';
import { ofType } from 'redux-observable';
import { setStructuredProductsLoader, structuredProductsFormOptionsDefaultSet, structuredProductsFormOptionsSet, STRUCTURED_PRODUCTS_FORM_DATA_LOADED } from "redux/actions/structured-products";
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export const initOptionsAutocallable = action$ =>
  action$
    .pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_DATA_LOADED),
      filter(({ payload }) => payload && payload.data),
      map(({ payload }) => payload),
      switchMap(({data: options, isFirstRequest}) => {
        const actions = [
          structuredProductsFormOptionsSet(options),
          structuredProductsFormOptionsDefaultSet(options),
          setStructuredProductsLoader(false)
        ]
        if(isFirstRequest) actions.push(reset(PRICING_STRUCTURED_FORM))
        return from(actions);
      }),
    );
export default initOptionsAutocallable;
