import { ofType, combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { SWAP_CARDS_REQUEST_PRICE, swapCardUpdate } from 'redux/actions/swap';
import { priceFormFailed } from 'redux/actions/price';
import { requestPriceMutation } from 'redux/queries/swap'
import { getCardById } from 'redux/epics/price/structures/swap/utils';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { STRUCTURE_SWAP } from 'constants.js';

const sendReuest = switchMap((card) => {
  const { id } = card;
  return from(requestPriceMutation({ id }))
    .pipe(
      filter(payload => !!payload),
      map(({ status }) => swapCardUpdate({
        ...card,
        status,
        loading: false,
        isLoading: false,
        isRequested: true,
      })),
      catchError((error) => {
        const actions = [
          priceFormFailed(),
          swapCardUpdate({
            ...card,
            loading: false,
            isLoading: false,
          })
        ];
        const err = getSingleGraphQlError(error);
        if (err?.message) {
          return from([
            ...actions,
            notificationErrorSimple(err.message),
          ])
        }
      })
    )
});

const getActions = (state$) => [
  ofType(SWAP_CARDS_REQUEST_PRICE),
  filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_SWAP),
  filter(({ payload }) => payload),
  map(({ payload }) => payload),
  map(({ id }) => getCardById(state$.value.price.trailPrice.cards, id)),
]

const startPriceRequest = (action$, state$) =>
  action$.pipe(
    ...getActions(state$),
    switchMap((card) => from([
      swapCardUpdate({
        ...card,
        price: null,
        loading: true,
        isLoading: true,
        isRequested: false,
      })
    ]))
  );

const submitCardForRequestEpic = (action$, state$) =>
  action$.pipe(
    ...getActions(state$),
    debounceTime(200),
    sendReuest,
  );

export default combineEpics(
  startPriceRequest,
  submitCardForRequestEpic,
);
