import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { IDLE_VIDEO_TIME } from 'constants.js';

class ModalMessage extends PureComponent {
  static defaultProps = {
    timer: IDLE_VIDEO_TIME,
  };

  constructor(props) {
    super(props);
    this.state = { show: true };
    this.startModalTimeout();
  }

  startModalTimeout = () => {
    const { timer } = this.props;
    this.timeout = setTimeout(() => {
      this.setState({ show: false });
    }, timer);
  }

  clearModalTimeout = () => {
    clearTimeout(this.timeout);
  }

  componentWillUnmount() {
    const { onClose } = this.props;
    this.clearModalTimeout();
    if (typeof onClose === 'function') onClose();
  }

  render() {
    const { show } = this.state;
    const { children, onClose } = this.props;
    const onToggle = onClose || (() => this.setState({ show: false }));

    return (
      children ?
        <Modal
          isOpen={show}
          component={children}
          onToggle={onToggle}
          onClose={onClose}
          showCloseBtn={false} />
        :
        null
    )
  }
}

ModalMessage.propTypes = {
  timer: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func
}

export default ModalMessage;
