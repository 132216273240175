import React, { Fragment } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const DetailsSwitcher = props => {
  const { isOpen, onToggle } = props;
  return (
    <Button color="link" type="button" className="btn-details" onClick={onToggle}>
      {!isOpen ?
        <Fragment>
          <KeyboardArrowDownIcon />
          <FormattedMessage id="common/details"
            defaultMessage="Details" />
        </Fragment>
        :
        <Fragment>
          <KeyboardArrowUpIcon />
          <FormattedMessage id="common/close"
            defaultMessage="Close" />
        </Fragment>}
    </Button>
  );
};

export default DetailsSwitcher;
