import {
  STRUCTURE_SWAP,
  ERROR_FETCH_CONTRACT_EXPIRY,
} from "constants.js";
import {
  GQ_DIRECTION_FIRST,
  GQ_VIEW,
  STRUCTURE,
} from "pages/minifuture/constants";
import { ofType } from "redux-observable";
import {
  minifutureCardCreate,
  MINIFUTURE_SUBMIT_FORM,
} from "redux/actions/minifuture";
import {
  priceFormFailed,
} from "redux/actions/price";
import { notificationErrorSimple } from "redux/alerts/actions";
import {
  extractContractData,
} from "redux/epics/price/structures/swap/";
import {
  createCardMutation,
} from "redux/queries/minifuture";
import { from } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { catchCardCreation } from "redux/epics/price/structures/swap/cards/create";
import { fetchContractExpiryList } from "pages/price/output/query";

const saveCard = (structure, commodityContract, cardData) => {
  return from(createCardMutation(cardData)).pipe(
    filter((payload) => !!payload),
    map(
      ({
        price,
        priceForCalcSpread,
        id,
        direction,
        strike,
        issueSize,
        totalSold,
        marketingName,
        externalId,
        contractExpiry,
        contractCode,
        account,
      }) => {
        return minifutureCardCreate({
          ...cardData,
          id,
          price,
          priceForCalcSpread,
          direction,
          strike,
          issueSize,
          totalSold,
          marketingName,
          externalId,
          contractExpiry,
          contractCode,
          account,
        });
      }
    ),
    catchCardCreation(commodityContract)
  );
};

export const applyMfContract = (
  structure = STRUCTURE_SWAP.toLowerCase(),
  cardData,
  save
) => {
  const { commodityCode, commodityContract, baseCurrency, termCurrency } =
    cardData;

  // MF-FX strategy
  if (baseCurrency && termCurrency) {
    const cardPayload = {
      ...cardData,
      quotedCurrency: baseCurrency,
    };
    return save(structure, commodityContract, cardPayload);
  }

  return from(
    fetchContractExpiryList({
      type: STRUCTURE_SWAP.toLowerCase(),
      commodityCode: commodityCode,
    })
  ).pipe(
    filter((payload) => !!payload),
    switchMap((expiryData) => {
      if (expiryData.length) {
        const {
          bloombergTicker,
          contract: contractCode,
          expiry: contractExpiry,
          strike: strikeLevel,
        } = expiryData[0];
        const cardPayload = {
          ...cardData,
          bloombergTicker,
          contractCode,
          contractExpiry,
          strikeLevel,
        };

        return save(structure, commodityContract, cardPayload);
      }
      return from([
        priceFormFailed(),
        notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY),
      ]);
    }),
    catchError(() =>
      from([
        priceFormFailed(),
        notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY),
      ])
    )
  );
};

export const createMinifutureCard = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_SUBMIT_FORM),
    filter(
      ({ payload }) =>
        !!payload && Array.isArray(state$.value.minifuture.pricings)
    ),
    map(({ payload }) =>
      extractContractData(state$.value.minifuture.pricings, payload)
    ),
    filter((contract) => contract),
    debounceTime(200),
    switchMap((payload) => {
      const {
        commodity: commodityContract,
        commodityCode,
        quotedCurrency,
        product: externalId,
        type,
        baseCurrency,
        termCurrency,
      } = payload;
      const cardData = {
        structure: STRUCTURE,
        view: GQ_VIEW.lv_rv,
        clientDirectionFirst: GQ_DIRECTION_FIRST.buy,
        clientDirectionSecond: type,
        externalId,
        commodityContract,
        commodityCode,
        quantity: 1,
        quotedCurrency,
        baseCurrency,
        termCurrency,
      };

      return applyMfContract(cardData.structure, cardData, saveCard);
    })
  );

export default createMinifutureCard;
