// import Portal from 'pages/portal/single/';
import { ROUTE_PORTAL, ROUTE_PORTAL_CATEGORY, ROUTE_PORTAL_SINGLE_POST } from 'pages/constants';
import PortalCategory from 'pages/portal/category/';
import Portal from 'pages/portal/Portal';
import PortalSingle from 'pages/portal/single/';
import PrivateRoute from 'pages/routes/PrivateRouter';
import React from 'react';
import { Switch } from 'react-router-dom';

const PortalLayout = () => {
  return (
    <Switch>
      <PrivateRoute path={ROUTE_PORTAL} exact component={Portal} />
      <PrivateRoute path={ROUTE_PORTAL_CATEGORY} exact component={PortalCategory} />
      <PrivateRoute path={ROUTE_PORTAL_SINGLE_POST} exact component={PortalSingle} />,
    </Switch>
  )
};

export default PortalLayout;
