import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Input } from 'reactstrap';

const isSupportedType = (type) => {
  return type !== 'checkbox' && type !== 'file' && type !== 'select-multiple';
};

class MaskedInput extends PureComponent {

  static defaultProps = {
    label: false,
  };

  handleKeyPress = event => {
    const { onBlur, maskNormalizer } = this.props;
    const key = event.which;
    if (key === 13) {
      onBlur(maskNormalizer(event.target.value));
    }
  }

  maskNormalizer(value, mask, normalizeChange, originalBlur, originalChange) {
    const { intl, intlNumberOptions } = this.props;
    return {
      value: mask(value, intl, intlNumberOptions),
      onBlur: event => {
        if (isSupportedType(event.target.type)) {
          originalBlur(normalizeChange(event.target.value, 'onBlur'));
        } else {
          originalBlur(event);
        }
      },
      onChange: event => {
        if (isSupportedType(event.target.type)) {
          originalChange(normalizeChange(event.target.value, 'onChange'));
        } else {
          originalChange(event);
        }
      }
    }
  }

  render() {
    const {
      type,
      mask,
      maskNormalizer,
      value,
      onBlur,
      onChange,
      label,
      isOutput,
      dispatch,
      meta,
      intlNumberOptions,
      ...rest
    } = this.props;
    const elLabel = label ? label : '';
    return <Input type={type} label={elLabel} {...rest} invalid={meta.touched && !meta.active && meta.invalid} {...this.maskNormalizer(value, mask, maskNormalizer, onBlur, onChange)} onKeyPress={this.handleKeyPress} />
  }

}

MaskedInput.defaultProps = {
  intlNumberOptions: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
};

MaskedInput.propTypes = {
  mask: PropTypes.func,
  maskNormalizer: PropTypes.func
};

export default injectIntl(MaskedInput);
