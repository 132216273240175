import { PRICING_SET_FORM_STRUCTURES, PRICING_INIT, PRICING_SET_IS_ASIAN_SWAP_STRUCTURES } from "../actions/price";

const FORM_DATA_INIT_STATE = {
  formData: {
    structures: [],
    loading: null,
    isAsianSwap: false
  }
}

export default function formDataReducer(state = FORM_DATA_INIT_STATE, action) {
  try {
    switch (action.type) {
      case PRICING_SET_FORM_STRUCTURES:
        return { ...state, structures: action.payload, loading: false }

      case PRICING_SET_IS_ASIAN_SWAP_STRUCTURES:
        return { ...state, isAsianSwap: action.payload }
      case PRICING_INIT:
        return { loading: false } //Set to true for valiataion available structures request
      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
