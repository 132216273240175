import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const ChangePassword = props => (
  <ErrorBoundary>
    <PageMeta title="Change your password?" />
    <Bundle title="Change your password Page" load={() => import('./ChangePassword')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default ChangePassword;
