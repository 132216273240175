import { change } from 'redux-form';

export const PRICING = '@PRICING';
export const PRICING_INIT = `${PRICING}/INIT`;
export const PRICING_LOADER = `${PRICING}/LOADER`;
export const PRICING_RESULTS_LOAD = `${PRICING}/RESULTS/LOADER`;

export const PRICING_FORM = `${PRICING}/FORM`;
export const PRICING_FORM_LOAD = `${PRICING}/FORM_LOAD`;
export const PRICING_FORM_LIMITATION = `${PRICING}/FORM_LIMITATION`;
export const PRICING_SET_FORM_STRUCTURES = `${PRICING}/SET_STRUCTURES`;
export const PRICING_SET_IS_ASIAN_SWAP_STRUCTURES = `${PRICING}/SET_IS_ASIAN_SWAP`;

export const PRICING_UPDATE_STRUCTURE = `${PRICING}/UPDATE_STRUCTURE`;
export const PRICING_UPDATE_UNDERLINE = `${PRICING}/UPDATE_UNDERLINE`;
export const PRICING_RESET_UNDERLINE = `${PRICING}/RESET_UNDERLINE`;
export const PRICING_SELECT_SHEET = `${PRICING}/SELECT_SHEET`;
export const PRICING_UPDATE_SHEET = `${PRICING}/UPDATE_SHEET`;
export const PRICING_UNSELECT_SHEET = `${PRICING}/UNSELECT_SHEET`;
export const PRICING_STRUCTURE_UPDATE_DETAILS = `${PRICING}/STRUCTURE/UPDATE_DETAILS`;
export const PRICING_SET_CHART_DATA = `${PRICING}/PRICING/SET_CHART_DATA`;
export const PRICING_SET_STATIC_CURSOR_DATA = `${PRICING}/PRICING/SET_STATIC_CURSOR_DATA`;

export const PRICING_SET_FIELDS_PARAMS = `${PRICING}/SET_FIELDS_PARAMS`;
export const PRICING_CREATE_FIELDS_PARAMS = `${PRICING}/CREATE_FIELDS_PARAMS`;
export const PRICING_UPDATE_FIELDS_PARAMS = `${PRICING}/UPDATE_FIELDS_PARAMS`;
export const PRICING_DELETE_FIELDS_PARAMS = `${PRICING}/DELETE_FIELDS_PARAMS`;
export const PRICING_SELECT_SUB_FORM = `${PRICING}/SELECT_SUB_FORM`;
export const PRICING_SUBMIT_FORM = `${PRICING}/SUBMIT_FORM`;
export const PRICING_SUBMIT_FORM_REQUEST = `${PRICING}/SUBMIT_FORM_REQUEST`;
export const PRICING_SUBMIT_FORM_CANCEL = `${PRICING}/SUBMIT_FORM_CANCEL`;
export const PRICING_FORM_SUBMITTED = `${PRICING}/FORM_SUBMITTED`;
export const PRICING_UPDATE_TRAIL_RPICE = `${PRICING}/UPDATE_TRAIL_RPICE`;
export const PRICING_FORM_FAILED = `${PRICING}/FORM_FAILED`;
export const PRICING_LOAD_FORM_VALUE = `${PRICING}/LOAD_FORM_VALUE`;
export const PRICING_RESET = `${PRICING}/RESET`;
export const PRICING_SET_INITIAL_STATE = `${PRICING}/PRICING_SET_INITIAL_STAT`;

export const PRICING_ACCUMULATOR_FORM_LOAD = `${PRICING}/ACCUMULATOR/FORM_LOAD`;
export const PRICING_ACCUMULATOR_DATA_LOADED = `${PRICING}/ACCUMULATOR/FORM_DATA_LOADED`;
export const PRICING_VANILLA_FORM_LOAD = `${PRICING}/VANILLA/FORM_LOAD`;
export const PRICING_VANILLA_FORM_LOADED = `${PRICING}/VANILLA/FORM_DATA_LOADE`;

type StructureType = {
  id: number | null,
}

type UnderlyingType = {
  id: number | null,
}

export function setInitialStatePricinig() {
  return {
    type: PRICING_SET_INITIAL_STATE
  }
}

export function setFieldParams(structure: StructureType) {
  return {
    type: PRICING_SET_FIELDS_PARAMS,
    structure
  }
}

export function addFieldParams(payload) {
  return {
    type: PRICING_CREATE_FIELDS_PARAMS,
    payload
  }
};

export function updateFieldParams(name: string, payload: object) {
  return {
    type: PRICING_UPDATE_FIELDS_PARAMS,
    name,
    payload
  }
};

export function deleteFieldParams(payload: string) {
  return {
    type: PRICING_DELETE_FIELDS_PARAMS,
    payload
  }
};

export function selectSubForm(payload: string) {
  return {
    type: PRICING_SELECT_SUB_FORM,
    payload
  };
};

export function submitForm(payload) {
  return {
    type: PRICING_SUBMIT_FORM,
    payload,
  };
};

export function submitFormRequest(payload) {
  return {
    type: PRICING_SUBMIT_FORM_REQUEST,
    payload,
  };
};

export function updateTrailPrice(payload) {
  return {
    type: PRICING_UPDATE_TRAIL_RPICE,
    payload,
  };
};

export type PriceAction = {
  type: string,
  structureId: number | null,
  structure? : StructureType
} | {
  type: string,
  id: number | null,
  underlying?: UnderlyingType
};

export type IStructureDetailsType = {
  businessDays: number,
  dailyQuantity: number,
  startDate: string
}

export type ISproductAnalysisType = {
  barrierLevel: number,
  accumulationLevel: number,
  spotNotional: number,
  notionalPhysical: number,
  futureTrandingUnits: string,
  currency: string
}

export const updateStructure = (structure: StructureType): PriceAction => ({
  type: PRICING_UPDATE_STRUCTURE,
  structure,
  structureId: structure ? structure.id : null,
});

export const updateUnderline = (underlying: UnderlyingType): PriceAction => ({
  type: PRICING_UPDATE_UNDERLINE,
  underlying,
  id: underlying ? underlying.id : null,
});

export const resetUnderline = () => ({
  type: PRICING_RESET_UNDERLINE,
  underlying: null
});

export const initPrice = () => ({
  type: PRICING_INIT
});

export const changePayoutCurrency =
  (value) => change(PRICING_FORM, 'payoutCurrency', value);

export const changePayoutNotional =
  (value) => change(PRICING_FORM, 'notional', value);

export const updateStructureDetails = (payload: IStructureDetailsType) => ({
  type: PRICING_STRUCTURE_UPDATE_DETAILS,
  payload,
});

export type PayloadLoadedData = {
  structures: Object[],
  underlyings: Object[],
  products: Object[],
  pricings: Object[],
};

export const pricingDataLoader = (payload = false) => ({
  type: PRICING_LOADER,
  payload
});

export const pricingResultsLoader = (payload = false) => ({
  type: PRICING_RESULTS_LOAD,
  payload
});

export const loadedAccumulatorData = payload => ({
  type: PRICING_ACCUMULATOR_DATA_LOADED,
  payload,
});

export const loadedVanillaData = payload => ({
  type: PRICING_VANILLA_FORM_LOADED,
  payload,
});

export const loadFormValue = (formData) => ({
  type: PRICING_LOAD_FORM_VALUE,
  payload: formData,
});

export const setCahrtData = payload => ({
  type: PRICING_SET_CHART_DATA,
  payload,
});

export const setStaticCursorData = payload => ({
  type: PRICING_SET_STATIC_CURSOR_DATA,
  payload,
});

export const setPricingFormStructures = payload => ({
  type: PRICING_SET_FORM_STRUCTURES,
  payload
});

export const setIsAsianSwapStructures = payload => ({
  type: PRICING_SET_IS_ASIAN_SWAP_STRUCTURES,
  payload
});

export const cancelCalculation = () => ({
  type: PRICING_SUBMIT_FORM_CANCEL
});

export const loadForm = () => ({ type: PRICING_FORM_LOAD });
export const loadVanillaForm = () => ({ type: PRICING_VANILLA_FORM_LOAD });
export const loadAccumulatorForm = () => ({ type: PRICING_ACCUMULATOR_FORM_LOAD });
export const setLimitationForm = payload => ({ type: PRICING_FORM_LIMITATION, payload });
export const priceFormSubmitted = payload => ({ type: PRICING_FORM_SUBMITTED, payload });
export const priceFormFailed = payload => ({ type: PRICING_FORM_FAILED, payload });
export const priceReset = payload => ({ type: PRICING_RESET, payload });

