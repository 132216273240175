import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_NOT_FOUND, ROUTE_HOME } from '../constants';
import intersection from 'lodash/intersection';
import { ROLE_DASHBOARD } from 'pages/roles';
import { createSelector } from 'reselect';

export const allowedStates = roles => roles ? roles.map(item => item.name) : null;

const PrivateRoute = props => {
  const { component: Component, isAuthorized, roles, authorized, ...rest } = props;
  const allowed = allowedStates(roles);
  // Check user role
  let isAllowed = true;
  let redirectTo = ROUTE_NOT_FOUND;
  if (allowed?.length && authorized?.length) {
    isAllowed = intersection(allowed, authorized).length !== 0;
    redirectTo = allowed.includes(ROLE_DASHBOARD) ? ROUTE_HOME : ROUTE_NOT_FOUND;
  } else if (authorized && roles) {
    isAllowed = false;
  }

  if (isAuthorized && !isAllowed) {
    return (
      <Redirect to={{
        pathname: redirectTo,
        state: { from: props.location }
      }} />
    )
  }

  return (
    <Route {...rest} render={(renderProps) => (
      isAuthorized ? (
        <Component {...renderProps} {...props} roles={allowed} />
      ) : (
          <Redirect to={{
            pathname: ROUTE_LOGIN,
            state: { from: props.location }
          }} />
        )
    )} />
  )
};

PrivateRoute.propTypes = {
  authorized: PropTypes.array,
  path: PropTypes.string,
  isAuthorized: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  component: PropTypes.any,
  exact: PropTypes.bool
};

const mapStateToProps = createSelector(
  state => state.auth,
  ({ isAuthorized, account }) => ({
    isAuthorized,
    userId: account && account.id,
    roles: account ? account.roles : null,
    dashboardRequestStatus: account ? account.dashboardRequestStatus : null
  })
);

export default connect(mapStateToProps)(PrivateRoute);
