export const OPEN_TRADES_FORM_EDIT = '@OPEN_TRADES/FORM/EDIT';
const DASHBOARD_TRADES_FORM = '@DASHBOARD_TRADES/FORM';
export const DASHBOARD_TRADES_FORM_FILTERS = `${DASHBOARD_TRADES_FORM}_FILTERS`;
export const DASHBOARD_TRADES_FORM_COLUMNS = `${DASHBOARD_TRADES_FORM}_COLUMNS`;
export const DASHBOARD_TRADES_FORM_ITEM = `${DASHBOARD_TRADES_FORM}_ITEM`;

const DASHBOARD_OTC_ORDERS_FORM = '@DASHBOARD_OTC_ORDERS/FORM';
export const DASHBOARD_OTC_ORDERS_FORM_FILTERS = `${DASHBOARD_OTC_ORDERS_FORM}_FILTERS`;
export const DASHBOARD_OTC_ORDERS_FORM_COLUMNS = `${DASHBOARD_OTC_ORDERS_FORM}_COLUMNS`;

