import { getAuthToken, removeAuthToken, setAuthToken, setAccount } from 'auth/';
import { ACCOUNT_DASHBOARD_REQUEST_STATUS, AUTH_LOGOUT, AUTH_SET_ACCOUNT, AUTH_SET_TOKEN, USER_ONLINE_STATUS, UNWIND_STATUS } from 'redux/actions/auth';

const AUTH_INIT_STATE_EMPTY = {
  isAuthorized: false,
  account: null,
  online: true,
  token: null,
  unwindHistoryId: null,
};

const tokenFromStorage = getAuthToken();

const AUTH_INIT_STATE = tokenFromStorage ?
  mapStateByToken(AUTH_INIT_STATE_EMPTY, tokenFromStorage)
  : AUTH_INIT_STATE_EMPTY;

export default function authReducer(state = AUTH_INIT_STATE, action = {}) {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      setAuthToken(action.token)
      return mapStateByToken(state, action.token);

    case AUTH_SET_ACCOUNT:
      setAccount(action.payload._id);
      return { ...state, account: action.payload };

    case ACCOUNT_DASHBOARD_REQUEST_STATUS:
      return {
        ...state,
        account: {
          ...state.account,
          dashboardRequestStatus: action.payload
        }
      }

    case USER_ONLINE_STATUS:
      return { ...state, online: action.payload }

    case AUTH_LOGOUT:
      removeAuthToken(action.payload.manually);
      return { ...AUTH_INIT_STATE_EMPTY };

    case UNWIND_STATUS:
      return { ...state, unwindHistoryId: action.payload }

    default:
      return state;
  }
}

function mapStateByToken(state = AUTH_INIT_STATE_EMPTY, token) {
  return { ...state, isAuthorized: Boolean(token), token };
}
