import React from 'react';
import InputAnimation from '../InputAnimation';

const renderChildren = (value) => (<div className="form-control readonly">{!!value ? value : '-'}</div>);

const InlineInputBlock = props => {
  let { value, label } = props;
  value = !!value ? value : false;
  return (<div className="form-control-wrapper">
    {label ? <label>{label}</label> : null}
    {renderChildren(value)}
    <InputAnimation value={value} active={true} />
  </div>)
}

export default InlineInputBlock;
