import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

function LazyBlock(props) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        {props.children}
      </Suspense>
    </ErrorBoundary>
  );
}

LazyBlock.propTypes = {
  children: PropTypes.node.isRequired
}

export default LazyBlock;
