import validateJs from 'utils/validate.js';
import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { TYPE_ICEBERG, TYPE_STOP, TIME_IN_FORCE_GTD, MIN_PRICE_VALIDATION, TYPE_STOP_LIMIT, DIRECTION, TYPE_OCO } from 'components/trade/constants.js';
import validateMessages from 'utils/validate.messages';

export const STOP_PRICE_NUMERICALITY = (data, props) => {
  const greaterThanOrEqualToDefaultCondition = props?.contractExpirySecond ? undefined : MIN_PRICE_VALIDATION;
  const orderType = data?.orderType || props?.orderType
  const numericality = {
    onlyInteger: false,
    greaterThanOrEqualTo: greaterThanOrEqualToDefaultCondition,
    lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
  };
  if(orderType === TYPE_STOP_LIMIT && props?.direction === DIRECTION.BUYER && props.price) {
    numericality.greaterThan = parseFloat(props.price);
    numericality.greaterThanOrEqualTo = undefined;
  }
  if(orderType === TYPE_STOP_LIMIT && props?.direction === DIRECTION.SELLER && props.price) {
    numericality.lessThan = parseFloat(props.price);
    numericality.greaterThanOrEqualTo = greaterThanOrEqualToDefaultCondition;
  }

  return numericality;
}

export const PRICE_NUMERICALITY = (props) => ({
  onlyInteger: false,
  greaterThanOrEqualTo: props?.contractExpirySecond ? undefined : MIN_PRICE_VALIDATION,
  lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
})

export const DEFAULT_ORDER_VALIDATIONS = (data, props) => {
  return ({
    price: {
      presence: true,
      numericality: PRICE_NUMERICALITY(props),
    },
    stopPrice: {
      presence: data?.orderType === TYPE_STOP || data?.orderType === TYPE_STOP_LIMIT,
      numericality: STOP_PRICE_NUMERICALITY(data, props),
    },
    quantity: {
      presence: true,
      numericality: {
        greaterThan: 0,
      },
    },
    timeInForce: {
      presence: true,
    },
    expiryDate: {
      presence: data?.timeInForce === TIME_IN_FORCE_GTD,
      expiryGoodTillDate: true
    },
    maxShow: (data?.orderType === TYPE_ICEBERG) ? {
      presence: true,
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
      },
    } : undefined
  });
};

export const validateMF = (data) => (validateJs(data, {
  ...DEFAULT_ORDER_VALIDATIONS(data),
  clientId: {
    presence: true,
  },
}) || {});

export const validateSwap = (data, props) => {
  if(data instanceof Array) return undefined;
  const SCHEMA = {
    ...DEFAULT_ORDER_VALIDATIONS(data, props),
    price: {
      presence: { allowEmpty: false },
    },
    legs: data?.orderType === TYPE_OCO ? {
      presence: {
        message: `^Legs ${validateMessages.blank}`
      },
      arrayFixed: {
        orderType: {
          presence: true,
        },
        price: {
          presence: true,
          numericality: PRICE_NUMERICALITY(props),
        },
        stopPrice: {
          itemComparator: {
            comparator: ({ orderType }) => ({
              presence: orderType === TYPE_STOP || orderType === TYPE_STOP_LIMIT,
              numericality: STOP_PRICE_NUMERICALITY({ orderType }, props),
            })
          },
        },
      },
    } : undefined,
  };

  if(data?.orderType === TYPE_OCO) SCHEMA.price = undefined;

  return validateJs(data, SCHEMA) || {}
}
