import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { combineEpics, ofType } from 'redux-observable';
import { pricingResultsLoader, setStaticCursorData } from 'redux/actions/price';
import { PRICING_SWAP_CARDS_FETCH_MORE, PRICING_SWAP_DATA_LOADED, swapCardsUpdate } from 'redux/actions/swap';
import { loadSwapCardsData } from 'redux/queries/swap';
import { from } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { swapTrailPricesFilter } from '..';
import { SWAP_BULLET } from 'pages/price/output/asianSwap/constants';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { STRUCTURE_SWAP } from 'constants.js';

export const loadSwapCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_DATA_LOADED),
      filter(({ payload }) => payload && !isEmpty(payload.pricings) && !isEmpty(payload.swapType) && payload.swapType === SWAP_BULLET),
      switchMap(() => {
        const limit = state$.value.auth.account.cardsLimit
        return from(loadSwapCardsData(null , limit)).pipe(
          switchMap(data => {
            const { structure, swapType } = priceFormValueSelector(state$.value) || {};
            if(structure === STRUCTURE_SWAP && swapType === SWAP_BULLET) {
              const actions = [
                swapCardsUpdate(data),
                setStaticCursorData(data),
                pricingResultsLoader(false),
              ];
              return from(actions);
            }
            return from([]);
          })
        )
      })
    );

export const fetchMoreSwapCardsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_CARDS_FETCH_MORE),
      filter(({ payload }) => !!payload && !!state$.value.price && !!state$.value.price.trailPrice && !!state$.value.price.trailPrice.pageInfo),
      filter(() => swapTrailPricesFilter(state$)),
      map(({ payload }) => payload),
      switchMap(({cursor, limit}) => {
        return from(loadSwapCardsData(cursor, limit)).pipe(
          filter(data => data && Array.isArray(data.cards)),
          switchMap(data => {
            const oldCards = state$.value.price.trailPrice && state$.value.price.trailPrice.cards ? state$.value.price.trailPrice.cards : [];
            const actions = [
              swapCardsUpdate({
                ...data,
                cards: uniqBy([
                  ...oldCards,
                  ...data.cards,
                ], 'id')
              }),
              pricingResultsLoader(false),
            ];
            return from(actions).pipe(
              delay(180) //Animation time
            );
          })
        )
      })
    );

export default combineEpics(
  loadSwapCardsEpic,
  fetchMoreSwapCardsEpic,
);
