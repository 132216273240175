import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';

export const UserNameComponent = props => {
  const { firstName, lastName, className } = props;
  return (firstName ?
    <div className={className}>
      <div className="d-none d-lg-inline-block">
        <FormattedMessage id="logged.header.welcome"
          defaultMessage="Welcome," />&nbsp;
      </div>
      <span className="text-bolder">{firstName}{lastName ? ` ${lastName}` : null}</span>
    </div>
    : null)
}

const mapStateToProps = createSelector(
  ({ auth }) => auth,
  (auth) => ({
    firstName: auth && auth.account && auth.account.firstName ? auth.account.firstName : null,
    lastName: auth && auth.account && auth.account.lastName ? auth.account.lastName : null
  })
);

UserNameComponent.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  className: PropTypes.string,
}

export default connect(mapStateToProps)(UserNameComponent);
