import { Field } from "redux-form";
import { InlineSelectInput } from "@agile/react-components";
import { useSelector } from "react-redux";
import { spFormValueSelector } from "../../../../../components/configurableSp/selectors/selectors";
import { availableOptionsFrequency } from "../../../../../redux/epics/structured-products/form/options/handleOptions";
import {useEffect, useMemo} from 'react';

const Frequency = ({ formName, changeField, optionsMap, change, ...rest }) => {
  const formValues: any = useSelector((state) => {
    return spFormValueSelector(formName)(state);
  });

  const options = useMemo(
    () => availableOptionsFrequency(formValues),
    [formValues?.maturity, formValues?.autocall],
  );

  useEffect(() => {
    changeField(formName, 'frequency', options[0])
  }, [formValues?.maturity, options]);

  return (
    <Field
      name="frequency"
      component={InlineSelectInput}
      change={changeField}
      options={options}
      clearable={false}
      {...rest}
    />
  );
};

export default Frequency;
