import gql from 'graphql-tag';

export const PRINT_PRICINIG_MINI_FACT_SHEET_QUERY = gql`query factSheetPricingMini(
  $id: ID!
  $notional: Float!
) {
  factSheetPricingMini (
    id: $id
    notional: $notional
  ) {
    referencePrice
    reference
    currency
    underlyingReference
    tradeDate
    expiryDate
    settlementDate
    barrierShift
    value
    impliedVolatilityShift
    primaryFixingValue
    delta
    deltaPercentage
    vega
    view {
      description
    }
    userInputs {
      label
      value
      field
    }
    solvedValues {
      value
      field
    }
    structureCode
    lotSize
    businessDays
    futTradingUnits
    futValPt
    tradedCurrency
    roundingRule
    accumulatorUnderlyings {
      id
      logo
      currency
      commodityContract
      quoteUnits
    }
    description {
      id
      type
      leverage
      leverageStyle
      productDescription
      product
      remainderFeature
      barrierType
    }
    card {
      title
    }
    accumulatorUnderlyingsData {
      contractCode
    }
    factSheetData
  }
}`;
