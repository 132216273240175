import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { loadFxForm } from 'redux/actions/fx';
import FxFormFields from './FxFormFields';
import { priceFormPropTypes } from '../priceFormPropTypes';

function FxForm(props) {
  const {
    loadForm,
    submitting,
    invalid,
    pristine,
    handleSubmit,
    reset,
    ...rest
  } = props;

  const loadFormCallback = useCallback(loadForm);

  useEffect(() => {
    loadFormCallback();
  }, [loadFormCallback]);

  return (
    <Form onSubmit={handleSubmit} onReset={reset} noValidate className="price-form price-form-container price-form-accumulator">
      <div className="price-form-fields">
        <FxFormFields {...rest} />
      </div>
      <Row>
        <Col xs={12} sm={{ size: 5, offset: 7 }} xl={{ size: 4, offset: 8 }}>
          <Button className="tour-price-btn w-100" color="primary" type="submit" disabled={pristine || invalid || submitting}>
            <FormattedMessage id="form.price.submit"
              defaultMessage="Create Card" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
  loadForm: loadFxForm,
}, dispatch);

FxForm.propTypes = {
  ...priceFormPropTypes
};

export default connect(null, mapDispatchToProps)(FxForm);
