import React from 'react';
import { Transition } from 'react-transition-group';

export const renderSuccessMessage = (text, subtext) => {
  return (
    <div className="form-message text-center">
      {text ? text : null}
      {subtext ? <div className="form-sub-message">{subtext}</div> : null}
    </div>
  )
};

const SuccessMessage = ({ children }) => {
  return (
    <Transition timeout={400} in appear={true}>
      {status => (
        <div className={`transition-fade-jump transition-fade-jump-${status}`} >
          {children}
        </div>
      )}
    </Transition>
  )
}

export default SuccessMessage;
