import { combineEpics } from 'redux-observable';
import { REDUX_FORM_FIELD_FOCUS, REDUX_FORM_FIELD_CHANGE } from '../constants';
import { PRICING_FORM } from 'redux/actions/price';
import { PRICING_TAG_FORM } from 'pages/price/PricingTag/PricingTagForm';
import { reset, isInvalid, getFormValues } from 'redux-form';
import { filter, mapTo } from 'rxjs/operators';

export const priceTagFormValueSelector = getFormValues(PRICING_TAG_FORM);

// Reset Price Tag form if focus or change Price Form
const resetPriceTagFormEpic = (action$, state$) =>
  action$
    .pipe(
      filter(action => (action.type === REDUX_FORM_FIELD_FOCUS || action.type === REDUX_FORM_FIELD_CHANGE) && action.meta.form === PRICING_FORM),
      filter(() => {
        const priceTagFormValues = priceTagFormValueSelector(state$.value);
        return isInvalid(PRICING_TAG_FORM)(state$.value) && (!priceTagFormValues || (priceTagFormValues && !priceTagFormValues.pricingTag));
      }),
      mapTo(reset(PRICING_TAG_FORM))
    )

export default combineEpics(
  resetPriceTagFormEpic,
)
