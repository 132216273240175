export const MINIFUTURE = '@MINIFUTURE';
export const MINIFUTURE_SUBMIT_FORM = `${MINIFUTURE}/SUBMIT_FORM`;
export const MINIFUTURE_FORM_LOAD = `${MINIFUTURE}/FORM_LOAD`;
export const MINIFUTURE_DATA_LOADED = `${MINIFUTURE}/FORM_DATA_LOADED`;
export const MINIFUTURE_CARD_CREATE = `${MINIFUTURE}/CARD/CREATE`;
export const MINIFUTURE_CARD_DELETE = `${MINIFUTURE}/CARD/DELETE`;
export const MINIFUTURE_CARD_START_DELETE = `${MINIFUTURE}/CARD/START_DELETE`;
export const MINIFUTURE_CARD_UPDATE = `${MINIFUTURE}/CARD/UPDATE`;
export const MINIFUTURE_CARDS_UPDATE = `${MINIFUTURE}/CARDS/UPDATE`;
export const MINIFUTURE_CARDS_FETCH_MORE = `${MINIFUTURE}/CARDS/FETCH_MORE`;
export const MINIFUTURE_CARD_FORM_OUTPUT = `${MINIFUTURE}/FORM_OUTPUT`;
export const MINIFUTURE_CARD_REFETCH = `${MINIFUTURE}/REFETCH`;
export const MINIFUTURE_REQUEST_PRICE = `${MINIFUTURE}/CARD/REQUEST/PRICE`;
export const MINIFUTURE_CONFIRMATION_MODAL_CANCEL = `${MINIFUTURE}/REQUEST/CONFIRMATION_MODAL_CANCEL`;
export const MINIFUTURE_CONFIRMATION_MODAL_APPROVE = `${MINIFUTURE}/REQUEST/CONFIRMATION_MODAL_APPROVE`;

export const MINIFUTURE_PRODUCT_CREATION_HOLIDAYS_LOADED = `${MINIFUTURE}/REQUEST/PRODUCT_CREATION/HOLIDAYS_LOADED`;

export const minifutureFormCardName = id => `${MINIFUTURE_CARD_FORM_OUTPUT}_${id}`;

export const loadForm = () => ({ type: MINIFUTURE_FORM_LOAD });

export const createCard = payload => ({
  type: MINIFUTURE_SUBMIT_FORM,
  payload,
});

export const loadedMinifutureData = payload => ({
  type: MINIFUTURE_DATA_LOADED,
  payload,
});

export const minifutureCardCreate = payload => ({
  type: MINIFUTURE_CARD_CREATE,
  payload,
});

export const minifutureCardDelete = payload => ({
  type: MINIFUTURE_CARD_DELETE,
  payload,
});

export const minifutureCardStartDelete = payload => ({
  type: MINIFUTURE_CARD_START_DELETE,
  payload,
});

export const minifutureCardUpdate = payload => ({
  type: MINIFUTURE_CARD_UPDATE,
  payload,
});

export const minifutureCardsUpdate = payload => ({
  type: MINIFUTURE_CARDS_UPDATE,
  payload,
});

export const minifutureCardsFetchMore = payload => ({
  type: MINIFUTURE_CARDS_FETCH_MORE,
  payload
});

export const starRefetchinMfCards = () => ({
  type: MINIFUTURE_CARD_REFETCH,
});

export const minifutureRequestPriceAction = payload => ({
  type: MINIFUTURE_REQUEST_PRICE,
  payload
});

export const minifutureApproveModalAction = payload => ({
  type: MINIFUTURE_CONFIRMATION_MODAL_APPROVE,
  payload
});

export const minifutureCancelModalAction = payload => ({
  type: MINIFUTURE_CONFIRMATION_MODAL_CANCEL,
  payload
});

export const loadedBankHolidaysAction = () => ({
  type: MINIFUTURE_PRODUCT_CREATION_HOLIDAYS_LOADED,
});
