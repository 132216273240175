import validateJs from 'utils/validate.js';
import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';

const SCHEMA = {
  payoutCurrency: {
    presence: true,
  },
  contractExpiry: {
    presence: true,
  },
  quantity: {
    presence: true,
  },
}

export default function validate(data, props = {}) {
  const { baseCurrency, termCurrency } = props
  if (baseCurrency && termCurrency) {
    SCHEMA.quantity.numericality = {
      greaterThan: 0,
      onlyInteger: true,
    }
  } else {
    SCHEMA.quantity.numericality = {
      greaterThan: 0,
      lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE,
      onlyInteger: true,
    }
  }

  return validateJs(data, SCHEMA) || {};
}
