import React, { PureComponent } from 'react';
import Modal from 'components/modal/Modal';
import DashboardRequestForm from './DashboardRequestForm';
import { MODAL_ANIMATION_TIME } from 'constants.js';
import PropTypes from 'prop-types';

class CustomRequestsModal extends PureComponent {
  state = {
    isOpen: true
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false
    })
    this.timeout = setTimeout(() => {
      const { history, location: { pathname } } = this.props;
      const route = pathname.replace('/request', '');
      history.push(route);
    }, MODAL_ANIMATION_TIME);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { isOpen } = this.state;
    return (
      <Modal
        btnType="primary"
        isOpen={isOpen}
        onToggle={this.onCloseModal}
        onClose={this.onCloseModal}
        component={<DashboardRequestForm />} />
    );
  }
}

CustomRequestsModal.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default CustomRequestsModal;
