import moment from "moment";
import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { change, getFormValues } from 'redux-form';

import { REQUEST_PRODUCT_CREATION } from 'pages/minifuture/form/RequestProductCreation/constants';
import { MINIFUTURE_PRODUCT_CREATION_HOLIDAYS_LOADED } from 'redux/actions/minifuture';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';
import { DATE_FORMAT_UTC } from 'constants.js';

const formValueSelector = getFormValues(REQUEST_PRODUCT_CREATION);

const formFilter = (action) =>
  action &&
  action.meta &&
  !action.meta.form.indexOf(REQUEST_PRODUCT_CREATION);

const setDefaultFinalFixingDateEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_PRODUCT_CREATION_HOLIDAYS_LOADED),
    map(() => formValueSelector(state$.value)),
    filter(({ fixingDate }) => !fixingDate),
    switchMap(() => {
      const date = moment().hour(0).minute(0).second(0).format(`${DATE_FORMAT_UTC}[Z]`);
      if(!moment(date).isBusinessDay()) {
        return from([change(REQUEST_PRODUCT_CREATION, 'fixingDate', moment.utc(date).businessAdd(1).format(`${DATE_FORMAT_UTC}[Z]`))])
      }
      return from([change(REQUEST_PRODUCT_CREATION, 'fixingDate', date)])
    })
  );

const changeRedemptionDateEpic = (action$) =>
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('fixingDate')),
    filter(formFilter),
    switchMap(({ payload }) => {
      return from([change(REQUEST_PRODUCT_CREATION, 'redemptionDate', moment.utc(payload).businessAdd(5).format(`${DATE_FORMAT_UTC}[Z]`))])
    })
  );

export default combineEpics(
  setDefaultFinalFixingDateEpic,
  changeRedemptionDateEpic
);
