import { InlineSelectInput } from "@agile/react-components";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { change, Field, formValueSelector, unregisterField } from "redux-form";
import {
  restoreStructuredFormUnderlyingsAddEmptyAction,
  restoreStructuredFormUnderlyingsRemoveAction,
  restoreStructuredFormUnderlyingsResetAction,
  restoreStructuredFormUnderlyingsUpdateAction,
} from "redux/actions/structured-products";
import { createSelector } from "reselect";
import { PRICING_STRUCTURED_FORM } from "../constants";
import DynamicFieldRemove from "../dynamicFields/DynamicFieldRemove";
import {
  GET_STRUCTURED_UNDERLYING,
  GET_UNDERLYINGS_FOR_CONFIGURABLE_SP,
} from "./query";
import { PRICING_FORM } from "redux/actions/price";
import { isDefined } from "validate.js";
import isEqual from "lodash/isEqual";
import "./underlying.scss";
import { UnderlyingField } from "./utils";
import { WrappedFieldInputProps } from "redux-form/lib/Field";

const selectorFormValues = (formName) => formValueSelector(formName);
const formNamePropSelector = (_, ownProps) =>
  ownProps?.formName || PRICING_STRUCTURED_FORM;

interface UnderlyingsOwnProps {
  max?: number;
  formName: string;
  onUnderlyingChanged?: (_, values: string[]) => void;
  client: any;
  showBottomPadding?: boolean;
  addUnderlyingImmediately?: boolean;
  isForConfigurableSP?: boolean;
  validation: string;
  label?: string;
  name?: string;
  input?: WrappedFieldInputProps;
}

interface UnderlyingsStateProps {
  fields: UnderlyingField[];
  issuer: string;
  isRestoringFromHistory: boolean;
}

interface UnderlyingsDispatchProps {
  unregisterField: typeof unregisterField;
  change: typeof change;
  removalAllUnderlyings: typeof restoreStructuredFormUnderlyingsResetAction;
  removeUnderlying: typeof restoreStructuredFormUnderlyingsRemoveAction;
  addEmptyUnderlying: typeof restoreStructuredFormUnderlyingsAddEmptyAction;
  updateUnderlying: typeof restoreStructuredFormUnderlyingsUpdateAction;
}

type UnderlyingsProps = UnderlyingsOwnProps &
  UnderlyingsStateProps &
  UnderlyingsDispatchProps;

const Underlyings: React.FC<UnderlyingsProps> = (props) => {
  const {
    client,
    unregisterField,
    change,
    max = 6,
    fields,
    removeUnderlying,
    removalAllUnderlyings,
    addEmptyUnderlying,
    updateUnderlying,
    issuer,
    showBottomPadding = true,
    addUnderlyingImmediately = false,
    formName = PRICING_STRUCTURED_FORM,
    onUnderlyingChanged,
    isForConfigurableSP = false,
    validation,
    label = "Underlying",
    isRestoringFromHistory = false,
  } = props;

  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<{ [name: string]: boolean }>({});

  useEffect(() => {
    const selectedFromProps = fields
      .map(({ value }) => value)
      .filter(Boolean) as string[];
    if (!isEqual(selectedFromProps, selected)) {
      setSelected(selectedFromProps);
    }
  }, [fields]);

  useEffect(() => {
    if (onUnderlyingChanged) {
      onUnderlyingChanged({}, selected);
    }
  }, [selected]);

  useEffect(() => {
    if(isRestoringFromHistory) {
      return () => {
        removalAllUnderlyings();
      };
    }
  }, []);

  const _onInputChange = async (field: UnderlyingField) => {
    const { value, name } = field;
    let underLyingOptions: UnderlyingField["options"] = [];
    if (value) {
      if (isForConfigurableSP) {
        const {
          data: {
            getUnderlyingsForConfigurableSP: { underlyings },
          },
        } = await getUnderlyingsForConfigurableSP({
          searchTerm: value,
          dynamicFilters: {
            validation,
          },
        });
        underLyingOptions =
          underlyings && underlyings.length
            ? underlyings
                .filter(({ uri }) => !selected.includes(uri))
                .map((underlying) => {
                  const { msId, referenceData, codes } = underlying || {};
                  const name = referenceData?.name?.[0];
                  return {
                    label: name,
                    value: `entity/${msId}`,
                    iceTicker: codes.iceTicker
                  };
                })
            : [];
        updateUnderlying({
          name,
          options: underLyingOptions,
          id: props.input?.name,
        });
      } else {
        const { data } = await getUnderlyings({ value, issuer });
        const underlyings = data?.getUnderlying;
        underLyingOptions = underlyings?.length
          ? underlyings
              .filter(({ id }) => !selected.includes(id))
              .map(({ title: label, id: value }) => ({
                label,
                value,
              }))
          : [];
      }
      updateUnderlying({ name, options: underLyingOptions });
    }
    setLoading({ ...loading, [name]: false });
  };

  const onDebounceInputChange = debounce(_onInputChange, 350, {
    leading: true,
    trailing: false,
  });

  const onInputChange = (field: UnderlyingField) => {
    if (!!field.value) {
      setLoading({ ...loading, [field.name]: true });
    }
    onDebounceInputChange(field);
  };

  const onRemove = (index: number) => {
    if (Array.isArray(fields) && fields.length && fields[index]) {
      const name = fields[index].name;
      change(formName, name, null);
      unregisterField(formName, name);
      removeUnderlying(index);
    }
  };

  const onChangeFilter = (field: UnderlyingField & { e: string | any }) => {
    const { name, e } = field;
    const value = isForConfigurableSP ? e?.value : e;

    if (isForConfigurableSP) {
      if (e) updateUnderlying({ name, value, iceTicker: e.iceTicker?.[0], label: e.label });
    } else {
      updateUnderlying({ name, value });
    }

    const isPushedNewItem =
      value &&
      fields.length < max &&
      fields.length - selected.length === 1;

    if (
      (value && fields.length === 1) ||
      isPushedNewItem ||
      (value && fields.length - selected.length === 0) ||
      (addUnderlyingImmediately && !isForConfigurableSP)
    ) {
      addEmptyUnderlying();
    }
  };

  const getUnderlyingsForConfigurableSP = async (values) => {
    return await client
      .query({
        query: GET_UNDERLYINGS_FOR_CONFIGURABLE_SP,
        variables: values,
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUnderlyings = async (values?: {
    value: string | undefined;
    issuer: string;
  }) => {
    return await client
      .query({
        query: GET_STRUCTURED_UNDERLYING,
        variables: {
          bloombergTicker: values?.value,
          issuer: values?.issuer,
        },
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const mb4 = showBottomPadding ? "mb-4" : "";

  return (
    <div className={mb4}>
      <Row>
        {fields.map((field, index) => {
          const options = field.options ? field.options : [];
          const { name } = field;
          return (
            <Col xs={12} key={name}>
              <div
                className={classnames(
                  `d-flex align-items-center ${mb4} app-form-row`,
                  { loading: loading[name] },
                )}
              >
                <div className="pre-label app-label">
                  <FormattedMessage
                    id="common/underlying"
                    defaultMessage={label}
                  />
                </div>
                <div id={`form-group-${name}`} className="form-group-row">
                  <DynamicFieldRemove
                    name={"Underlying"}
                    index={index}
                    onRemove={onRemove}
                    show={fields.length !== 1}
                  >
                    <Field
                      name={name}
                      type="text"
                      placeHolder="Please start typing the underlying name or ticker"
                      action={PRICING_FORM}
                      component={InlineSelectInput}
                      className="w-100"
                      options={options}
                      justValue={!isForConfigurableSP}
                      selectedSingleOption={false}
                      onChangeFilter={onChangeFilter}
                      onInputChange={onInputChange}
                      autoFocus={index > 0}
                      showError={fields.length === 1}
                      change={change}
                      useVirtualizedList={true}
                    />
                  </DynamicFieldRemove>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch): UnderlyingsDispatchProps =>
  bindActionCreators(
    {
      unregisterField,
      change,
      removalAllUnderlyings: restoreStructuredFormUnderlyingsResetAction,
      removeUnderlying: restoreStructuredFormUnderlyingsRemoveAction,
      addEmptyUnderlying: restoreStructuredFormUnderlyingsAddEmptyAction,
      updateUnderlying: restoreStructuredFormUnderlyingsUpdateAction,
    },
    dispatch,
  );

export const mapStateToPropsUnderlyings = createSelector(
  [(state) => state, formNamePropSelector],
  (state, formName): UnderlyingsStateProps => {
    const issuerField = selectorFormValues(formName)(state, "issuer");
    // configurable sp uses format { label: string, value: string }
    // value in static data is an id, legacy underlying service wants the label issuer value
    const issuer = isDefined(issuerField?.label)
      ? issuerField.label
      : issuerField;
    return { 
      fields: state.structuredProducts?.underlyings, 
      issuer, 
      isRestoringFromHistory: state.structuredProducts?.isRestoringFromHistory 
    };
  },
);

export default compose(
  connect(mapStateToPropsUnderlyings, mapDispatchToProps),
  withApollo,
)(Underlyings);
