import { THEME_INIT_STATE } from './initial';

const TITLES_KEYS = ['defaultTitle', 'loginTitle', 'loginDescription'];
const IMAGES_KEYS = ['favicon'];

const prepareMetaImages = (images, imagesKeys = IMAGES_KEYS) => {
  if (!images) return;
  let meta = {};
  imagesKeys.forEach(key => {
    if (images[key]) meta[key] = images[key];
  });
  return meta;
}

const prepareMetaTexts = (texts, titlesKeys = TITLES_KEYS) => {
  if (!texts) return;
  let meta = {};
  titlesKeys.forEach(key => {
    if (texts[key]) meta[key] = texts[key];
  });
  return meta;
}

export const prepareMeta = (payload, defaultMeta = THEME_INIT_STATE.meta) => {
  if (!payload) return defaultMeta;
  const { texts, images } = payload;
  let meta = {
    ...defaultMeta,
    ...prepareMetaTexts(texts),
    ...prepareMetaImages(images),
  };

  return meta;
};
