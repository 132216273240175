import { createSelector } from 'reselect';
import { optionsProvider } from '../../utils';

export const fxLimitationSelector = createSelector(
  [
    state => state.price.limitation
  ],
  (limitation) => {
    return limitation && limitation.commodityContracts ? {
      commodityContracts: optionsProvider(limitation.commodityContracts || []),
    } : {}
  }
);
