import { STRUCTURE_AUTOCALLABLE, STRUCTURE_CAPITAL_PROTECTION, STRUCTURE_TWIN_WIN, STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import { PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/autocallable/constats';
import { AUTOCALLABLE_FORM_FIELDS_KEYS_ARRAY } from 'pages/structured-products/components/forms/types/autocallable/fields';
import { PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/capitalProtection/constats';
import { CAPITAL_PROTECTION_FORM_FIELDS_KEYS_ARRAY } from 'pages/structured-products/components/forms/types/capitalProtection/fields';
import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { submitStructuredProductsAutocollableFormMutation, submitStructuredProductsCapitalProtectionFormMutation, submitStructuredProductsTwinWinFormMutation } from 'redux/queries/structured-products/';
import { formResetFilter } from 'utils/reduxFormSelector';
import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import lowerCase from 'lodash/lowerCase';
import { TWIN_WIN_FORM_FIELDS_KEYS_ARRAY } from 'pages/structured-products/components/forms/types/twinWin/fields';
import { PRICING_FORM_TWIN_WIN_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/twinWin/constants';
import { PRICING_FORM_FIXED_COUPON_NOTE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/fixedCouponNote/constants';

export const structuredProductsFormResetFilter = formResetFilter(PRICING_STRUCTURED_FORM);

export const structureRestoringFromHistoryFilter = state => !state?.value?.structuredProducts?.isRestoringFromHistory;

export const structuredProductsFormStrucutreFilter = (state$, structure) => formValuesSelectorStructuredForm(state$.value)?.structure === structure;

export const formRadioValue = val => lowerCase(val) === 'yes';

// @TODO: pass form options and maybe form keys
export const caseStrucutredProductsFormConfig = structure => {
  switch (structure) {
    case STRUCTURE_AUTOCALLABLE:
      return {
        initialValues: PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES,
        submit: submitStructuredProductsAutocollableFormMutation,
        fieldKeysArray: AUTOCALLABLE_FORM_FIELDS_KEYS_ARRAY
      };
    case STRUCTURE_TWIN_WIN:
      return {
        initialValues: PRICING_FORM_TWIN_WIN_INITIAL_VALUES,
        submit: submitStructuredProductsTwinWinFormMutation,
        fieldKeysArray: TWIN_WIN_FORM_FIELDS_KEYS_ARRAY
      };
    case STRUCTURE_CAPITAL_PROTECTION:
      return {
        initialValues: PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES,
        submit: submitStructuredProductsCapitalProtectionFormMutation,
        fieldKeysArray: CAPITAL_PROTECTION_FORM_FIELDS_KEYS_ARRAY
      };
    case STRUCTURE_FIXED_COUPON_NOTE:
      return {
        initialValues: PRICING_FORM_FIXED_COUPON_NOTE_INITIAL_VALUES,
        submit: submitStructuredProductsAutocollableFormMutation,
        fieldKeysArray: AUTOCALLABLE_FORM_FIELDS_KEYS_ARRAY
      };
    default:
      return {
        initialValues: {}
      };
  }
};

export const isStructureContaintSchedule = (structure = '') => [STRUCTURE_AUTOCALLABLE, STRUCTURE_TWIN_WIN, STRUCTURE_FIXED_COUPON_NOTE].includes(structure);
