import LazyBlock from 'components/common/lazy/LazyBlock';
import Loader from 'components/common/loader/Loader';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import ProductChartBlockQuery from 'pages/price/ProductChart/ProductChartBlock/ProductChartBlockQuery';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import 'pages/price/ProductChart/ProductChart.scss';

const Chart = lazy(() => import('./PricingMiniDynamicChart'));

const chartLines = (fields, props) => {
  const lines = [
    {
      label: 'Ref',
      name: 'reference',
      value: parseFloat(props['reference']),
    }
  ];
  fields.forEach(field => {
    if (field.label && field.name && field.name !== "premiumAmount" && props[field.name]) {
      lines.push({
        label: field.label,
        name: field.name,
        value: parseFloat(props[field.name]),
      });
    }
  })
  return lines;
}

function PriciniMiniChart(props) {
  const {
    marketData,
    trail,
    fields,
  } = props;

  const marketDataId = marketData && marketData.id ? marketData.id : null;
  const lines = chartLines(fields, props);
  return (
    <ErrorBoundary>
      {marketDataId ? (
        <LazyBlock>
          <ProductChartBlockQuery marketDataId={marketDataId} trail={trail}>
            {({ marketData, loading }) => {
              if (loading) return <Loader />;
              return (
                <Chart
                  trail={trail}
                  marketData={marketData}
                  height={560}
                  xGap={50}
                  lines={lines}
                />
              )
            }}
          </ProductChartBlockQuery>
        </LazyBlock>
      ) : null
      }
    </ErrorBoundary>
  )
}

PriciniMiniChart.propTypes = {
  bloombergTicker: PropTypes.string.isRequired,
};

export default PriciniMiniChart;
