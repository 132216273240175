import InlineInput from 'components/form/InlineInput';
import CardRadioBtn from 'components/form/inputs/cardRadioBtn/CardRadioBtn';
import InlineInputBlock from 'components/form/inputs/readOnly/InlineInputBlock.jsx';
import { CARD_STATUSES, DATE_FORMAT_LABEL_DAY_MONTH_SHORT } from 'constants.js';
import { ROUTE_MINIFUTURE_TRADE } from 'pages/constants';
import {
  DIRECTION_FIRST,
  GQ_DIRECTION_SECOND,
  STATE_NAME,
} from 'pages/minifuture/constants';
import { formatDate2UI } from 'pages/price/PriceForm/utils.js';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { minifutureRequestPriceAction, minifutureFormCardName } from 'redux/actions/minifuture';
import { orderInit } from 'redux/actions/orders';
import validate from './validate.js';
import InlineInputWithMask from '../../../../components/form/inputs/inlineInput/InlineInputWithMask';
const { REQUESTED, APPROVED, DEACTIVATED } = CARD_STATUSES;

const getButtonLabel = (status) => {
  switch (status) {
    case REQUESTED: return 'Requested';
    case APPROVED: return 'Trade';
    default: return 'Request Price';
  }
}

const getButton = (
  {
    tradeCm,
    tradeFx,
    requestPrice
  },
  {
    isLoading,
    isDisabled,
    invalid,
    isApprovedStatus,
    isRequestedStatus,
    isNotExistPriceForTrade,
    isMinifutureFx,
    isError,
  },
  direction,
  label,
) => {
  if(isMinifutureFx) {
    return (
      <Button type="button"
        className={
          "w-100 px-1 btn-input-h text-uppercase" +
          (direction === "buyer" ? " btn-buy" : " btn-sell")
        }
        onClick={isApprovedStatus ? tradeFx : requestPrice}
        disabled={isLoading || isDisabled || invalid || isRequestedStatus || isNotExistPriceForTrade || isError}>
        {
          !invalid && !isDisabled && isLoading ?
            'Loading...' :
            label
        }
      </Button>
    )
  }

  return (
    <Button
      type="button"
      className={
        "w-100 px-1 btn-input-h text-uppercase" +
        (direction === "buyer" ? " btn-buy" : " btn-sell")
      }
      onClick={tradeCm}
      disabled={isLoading || isDisabled || invalid || isError}
    >
      {!invalid && !isDisabled && isLoading ? "Loading..." : "Send Order"}
    </Button>
  )
}

const calcFairValue = (isMinifutureFx, { ratio, strikeLevel, clientDirectionSecond, price, denomCcy, termCurrency, baseCurrency, quanto }) => {
  if (isMinifutureFx) {
    // rule #1
    if (denomCcy === termCurrency) {
      return clientDirectionSecond === GQ_DIRECTION_SECOND.rv ? (ratio * (strikeLevel - price)) : (ratio * (price - strikeLevel));
    } // rule #2
    else if (denomCcy === baseCurrency) {
      return clientDirectionSecond === GQ_DIRECTION_SECOND.rv ? (ratio * (strikeLevel - price) / price) : (ratio * (price - strikeLevel) / price);
    }
    else {
      return null;
    }
  } else {
    return clientDirectionSecond === GQ_DIRECTION_SECOND.rv
      ? strikeLevel - price + quanto
      : price - strikeLevel + quanto;
  }
};

export const minifutureFormValuesSelector = (id, state) =>
  getFormValues(minifutureFormCardName(id))(state);
function PricingOutputCardForm(props) {
  const {
    direction,
    id,
    handleSubmit,
    invalid,
    isLoading,
    history,
    orderInit,
    clientDirectionSecond,
    price,
    strikeLevel,
    contractExpiry,
    productInfo,
    status,
    isMinifutureFx,
    cardRequestPrice,
    intl: { formatNumber },
    isError,
    ratio,
  } = props;

  const isDeactivatedStatus = status === DEACTIVATED;
  const formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }

  const quanto = productInfo?.quantoValue || 0;
  const ccy = productInfo?.ccy || null;

  const calcRemaining = productInfo?.issueSize - productInfo?.totalSold
  const fairValue = calcFairValue(isMinifutureFx, {...props, quanto, denomCcy: ccy });

  const formattedTotalSold = productInfo?.totalSold ? formatNumber(productInfo?.totalSold, formatOptions) : null;
  const formattedIssueSize = productInfo?.issueSize ? formatNumber(productInfo?.issueSize, formatOptions) : null;
  const formattedRemaining = calcRemaining ? formatNumber(calcRemaining, formatOptions) : null;
  const formattedPrice = price ? formatNumber(price, formatOptions) : null;
  const formattedFairValue = price ? formatNumber(fairValue, formatOptions) : null;

  const formattedStrikeLevel = strikeLevel ? formatNumber(strikeLevel, formatOptions) : null;
  const tradeCm = () => {
    orderInit({
      id,
      stateName: STATE_NAME,
      remaining: calcRemaining,
      totalSold: productInfo?.totalSold,
      issueSize: productInfo?.issueSize,
      fairValue,
      quantoValue: quanto,
      ccy,
      ratio
    });
    history.push(ROUTE_MINIFUTURE_TRADE);
  };

  const requestPrice = () => {
    cardRequestPrice({ id })
  }

  const tradeFx = () => {
    orderInit({
      id,
      stateName: STATE_NAME,
      remaining: calcRemaining,
      totalSold: productInfo?.totalSold,
      issueSize: productInfo?.issueSize,
      fairValue,
      quantoValue: quanto,
      ccy,
      ratio
    });
    history.push(ROUTE_MINIFUTURE_TRADE);
  }

  const viewName = `view-${!clientDirectionSecond ? "buysell" : "lvrv"}`;
  const isDisabled = !contractExpiry || !strikeLevel || isDeactivatedStatus;

  const isRequestedStatus = status === REQUESTED;
  const isApprovedStatus = status === APPROVED;
  const isNotExistPriceForTrade = (isApprovedStatus && !price) ||  isDeactivatedStatus
  const buttonActions = {
    tradeCm,
    requestPrice,
    tradeFx
  }

  const buttonStates = {
    isLoading,
    isDisabled,
    invalid,
    isApprovedStatus,
    isRequestedStatus,
    isNotExistPriceForTrade,
    isMinifutureFx,
    isError
  }

  return (
    <Form
      id={`form-card-minifuture-${id}`}
      className={"minifuture-form"}
      onSubmit={handleSubmit}
      noValidate
    >
      <Row className="row-size pb-3">
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.payout-currency.label"
                defaultMessage="Currency"
              />
            }
            value={ccy}
          />
        </Col>
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.issueSize.label"
                defaultMessage="Issue Size"
              />
            }
            value={formattedIssueSize}
          />
        </Col>
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.totalSold.label"
                defaultMessage="Total Sold"
              />
            }
            value={formattedTotalSold}
          />
        </Col>
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.remaining.label"
                defaultMessage="Remaining"
              />
            }
            value={formattedRemaining}
          />
        </Col>
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.strikeLevel.label"
                defaultMessage="Strike Level"
              />
            }
            value={formattedStrikeLevel}
          />
        </Col>
        <Col xs={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.fairValue.label"
                defaultMessage="Fair Value"
              />
            }
            value={formattedFairValue}
          />
        </Col>
        <Col xs={6}>
          <Field
            type="text"
            name="underlying"
            label={"Underlying"}
            placeHolder="Underlying"
            readOnly
            component={InlineInput}
          />
        </Col>
        <Col xs={6} className="mf-price">
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.price.label"
                defaultMessage="Live"
              />
            }
            value={formattedPrice}
          />
        </Col>
        <Col xs={6} sm={6}>
          <InlineInputBlock
            label={
              <FormattedMessage
                id="form.price.fields.contractExpiry.label"
                defaultMessage="Contract Expiry"
              />
            }
            value={contractExpiry}
          />
          <Field
            name="clientDirectionFirst"
            justValue={true}
            component={CardRadioBtn}
            options={DIRECTION_FIRST.map(i => ({...i, disabled: isDisabled}))}
            groupType="sm"
            cardId={id}
            className={`${viewName}-bottom mb-0`}
          />
        </Col>
        <Col xs={6}>
          <Field
            name="quantity"
            type="formatted-number"
            label={"Quantity"}
            placeHolder="Total Size"
            disabled={isDisabled}
            component={InlineInputWithMask}
          />
          {getButton(
            buttonActions,
            buttonStates,
            direction,
            getButtonLabel(status),
          )}
        </Col>
      </Row>
    </Form>
  );
}

PricingOutputCardForm.defaultProps = {
  isLoading: false,
};

PricingOutputCardForm.propTypes = {
  direction: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.any,
  productInfo: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const {
    contractCode,
    quotedCurrency,
    clientDirectionFirst,
    clientDirectionSecond,
    price,
    commodityContract,
    strike,
    contractExpiry,
    ratio,
    baseCurrency,
    termCurrency
  } = ownProps;

  const isMinifutureFx = !!(baseCurrency && termCurrency);
  const quantity = !isNaN(parseFloat(ownProps.quantity))
    ? ownProps.quantity
    : 1;

  const formattedContractExpiry = isMinifutureFx ? formatDate2UI(contractExpiry, DATE_FORMAT_LABEL_DAY_MONTH_SHORT) : formatDate2UI(contractExpiry, 'MMM-YY')

  return {
    form: minifutureFormCardName(ownProps.id),
    strikeLevel: strike,
    contractExpiry: contractExpiry ? formattedContractExpiry : null,
    isMinifutureFx,
    initialValues: {
      currency: quotedCurrency,
      quantity,
      clientDirectionFirst,
      clientDirectionSecond,
      underlying: commodityContract,
      contractCode,
      price,
      ratio,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    cardRequestPrice: minifutureRequestPriceAction,
    orderInit
  }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ enableReinitialize: false, validate }),
  withRouter,
  memo,
  injectIntl,
)(PricingOutputCardForm);
