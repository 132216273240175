export const TIME_IN_FORCE_GTD = 'gtd';
export const TIME_IN_FORCE_DAY = 'day';
export const TIME_IN_FORCE_GTC = 'gtc';
export const FX_TIME_IN_FORCE_OPTIONS = [
  {
    value: TIME_IN_FORCE_DAY,
    label: 'Day',
  },
  {
    value: TIME_IN_FORCE_GTD,
    label: 'Good Till Date',
  },
];
export const TIME_IN_FORCE_OPTIONS = [
  {
    value: TIME_IN_FORCE_DAY,
    label: 'Day',
  },
  {
    value: TIME_IN_FORCE_GTC,
    label: 'GTC',
  },
  {
    value: TIME_IN_FORCE_GTD,
    label: 'Good Till Date',
  },
];

export const FX_TIME_IN_FORCE_DEFAULT = FX_TIME_IN_FORCE_OPTIONS[0];
export const TIME_IN_FORCE_DEFAULT = TIME_IN_FORCE_OPTIONS[0];

export const TYPE_LIMIT = 'limit';
export const TYPE_MARKET = 'market';
export const TYPE_STOP = 'stop';
export const TYPE_ICEBERG = 'iceberg';
export const TYPE_STOP_LIMIT = 'stop-limit';
export const TYPE_OCO = 'oco';

export const MKT = 'MKT';

export const PRICINIG_ORDER_TYPES = [
  { value: TYPE_LIMIT, label: 'Limit', disabled: false },
  { value: TYPE_MARKET, label: 'Market', disabled: false },
  { value: TYPE_STOP, label: 'Stop', disabled: false },
  { value: TYPE_ICEBERG, label: 'Iceberg', disabled: false },
  { value: TYPE_STOP_LIMIT, label: 'Stop Limit', disabled: false },
  { value: TYPE_OCO, label: 'OCO', disabled: false },
];

export const OCO_ORDER_TYPES = [
  { value: TYPE_STOP, label: 'Stop', disabled: false },
  { value: TYPE_LIMIT, label: 'Limit', disabled: false },
  { value: TYPE_STOP_LIMIT, label: 'Stop Limit', disabled: false },
];

export const FX_ORDER_TYPES = [
  { value: TYPE_LIMIT, label: 'Limit', disabled: false },
  { value: TYPE_MARKET, label: 'Market', disabled: false },
];

export const MF_FX_ORDER_TYPES = [
  { value: TYPE_MARKET, label: 'Market', disabled: false },
];

export const ORDER_TYPES_DEFAULT = PRICINIG_ORDER_TYPES[0];
export const ORDER_STRUCTURE_MINIFUTURE = 'minifuture';

export const MIN_PRICE_VALIDATION = 0.000001;

export const LIMIT_PRICE_LABEL = "Limit Price"
export const PRICE_LABEL = "Price"
export const DIRECTION = {
  BUYER: "buyer",
  SELLER: "seller"
}
