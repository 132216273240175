import { requiredField } from "./FormItem";

const accumulationLevelFieldDescription = {
  label: 'Strike Level',
  placeHolder: 'Enter Strike Level',
  name: 'strikeLevel',
  type: 'number',
  isOutput: true
};

const strikeLevelForms = {
  forms: [{
    name: 'strikeLevel',
    label: 'Strike Level',
    input: [
      {
        ...accumulationLevelFieldDescription,
        validate: requiredField,
      }
    ],
    output: {
      ...accumulationLevelFieldDescription,
      validate: requiredField,
    }
  }]
}

export const vanillaStructureConfig = {
  strikeLevel: strikeLevelForms
}
