import moment from 'moment';
import validateJs from 'utils/validate.js';


const { datetime } = validateJs.validators;
validateJs.validators.settlementDate = datetime.bind({
  parse: (value) => +moment.utc(value),
  format: (value) => moment.utc(value).toISOString()
});

type SchemaType = {
  unit: any,
  firstMonth: any,
  lastMonth?: any,
  notional?: any,
  legs?: any
}

const TOTAL_SIZE_FIXING_DATES_SCHEMA = {
  notional: {
    presence: true,
    numericality: {
      greaterThan: 0,
    },
  },
  legs: {
    presence: true,
    array: {
      notional: {
        presence: true,
        numericality: {
          greaterThanOrEqualTo: 0,
          message: (value, attribute, { greaterThanOrEqualTo }, { contractExpiry }) => `: ${moment(contractExpiry).format('MMM-YY')} must be greater than or equal to ${greaterThanOrEqualTo}`
        },
      },
      contractExpiry: {
        presence: true,
      },
      expiryDate: {
        presence: true,
      }
    }
  }
}

const SCHEMA: SchemaType = {
  unit: {
    presence: true,
  },
  firstMonth: {
    presence: true,
  },
  ...TOTAL_SIZE_FIXING_DATES_SCHEMA
};

export const validateLegs = (data) => {
  return validateJs(data, TOTAL_SIZE_FIXING_DATES_SCHEMA) || {};
}

export default function validate(data) {
  const { firstMonth } = data;
  SCHEMA.lastMonth = {
    presence: true,
    settlementDate: {
      earliest: +moment.utc(firstMonth),
      message: `cannot be before First Month`
    }
  };

  return validateJs(data, SCHEMA) || {};
}
