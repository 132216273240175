import validateMessages from 'utils/validate.messages';

export const notValid = '^Is not a number';
export const notGreaterThanOrEqualTo = '^must be greater than or equal to %{count}';
export const notLessThanOrEqualTo = '^must be less than or equal to %{count}';
export const notGreaterThan = '^must be greater than %{count}';
export const notLessThan = '^must be less than %{count}';
export const presence = {
  message: `^${validateMessages.blank}`
};
