import { TIME_IN_FORCE_DEFAULT, ORDER_TYPES_DEFAULT, MKT } from '../constants.js';

export const VANILLA_ORDERS_FORM_INITIAL_VALUES = {
  timeInForce: TIME_IN_FORCE_DEFAULT.value,
  orderType: ORDER_TYPES_DEFAULT.value,
  priceMkt: MKT,
  stopPrice: null,
  maxShow: null,
  expiryDate: null,
  maxExpiryDate: null,
};
