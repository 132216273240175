import { STRUCTURE_CAPITAL_PROTECTION } from 'constants.js';
import { ISSUER_DEFAULT } from '../../constants';

export const CAPITAL_PROTECTION_LEVEL_DEFAULT =100;
export const CAPITAL_PROTECTION_LEVEL_MIN = 0;

export const PARTICIPATION_LEVEL_DEFAULT =100;
export const PARTICIPATION_LEVEL_MIN = 0;
export const PARTICIPATION_LEVEL_MAX = 500;

export const PARTICIPATION_LEVEL_DEFAULT_2 =100;
export const PARTICIPATION_LEVEL_MIN_2 = 0;
export const PARTICIPATION_LEVEL_MAX_2 = 500;

export const REBATE_LEVEL_DEFAULT =0;
export const REBATE_LEVEL_MIN = 0;
export const REBATE_LEVEL_MAX = 50;

export const PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES_ADDITIONAL = {
  optionType2: 'Call',
  strikeLevel2: 100,
  participationLevel2: PARTICIPATION_LEVEL_DEFAULT_2,
  capType2: 'Uncapped',
  barrierType2: 'European Knock Out',
};

export const PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES = {
  structure: STRUCTURE_CAPITAL_PROTECTION,
  issuer: ISSUER_DEFAULT,
  currency: 'USD',
  maturity: 12,
  basketType: 'Worst Of',
  capitalProtectionLevel: CAPITAL_PROTECTION_LEVEL_DEFAULT,
  optionType: 'Call',
  strikeLevel: 100,
  participationLevel: PARTICIPATION_LEVEL_DEFAULT,
  capType: 'Uncapped',
  barrierType: 'European Knock Out',
  rebateLevel: REBATE_LEVEL_DEFAULT,
  legalShape: 'Note',
  spread: 0,
  ...PRICING_FORM_CAPITAL_PROTECTION_INITIAL_VALUES_ADDITIONAL,
};
