import React, { PureComponent } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { onChangeField } from 'components/form/utils/';

class InlineRadioGroup extends PureComponent {
  render() {
    const {
      options = [],
      onChangeFilter,
      label, input: { name, onChange, value },
      ...rest
    } = this.props;
    return (
      <FormGroup className="form-group-checkbox-dots text-nowrap">
        {label ? <Label for={name}>{label}</Label> : null}
        <div className="d-flex">
          {options.map((radio, index) => {
            const inputId = name + '-' + index;
            return (
              <FormGroup check inline key={inputId} className="flex-fill">
                <Label for={inputId} className="form-check-label">
                  <Input
                    name={name}
                    id={inputId}
                    type="radio"
                    value={radio.value}
                    checked={radio.value === value}
                    onChange={e => onChangeField(e, onChange, { name, ...rest, onChangeFilter })}
                  />
                  <span className="form-check-dot"></span>
                  {radio.label}
                </Label>
              </FormGroup>
            )
          })}
        </div>
      </FormGroup>
    )
  }
}

InlineRadioGroup.propTypes = {
  options: PropTypes.array
};

export default InlineRadioGroup;
