import gql from 'graphql-tag';

export const FETCH_STATIC_PAGE = gql`query staticPageQuery (
  $seo: String!
  ) {
    staticPage (
      seo: $seo
    ) {
      title
      content
    }
}`;
