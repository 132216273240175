export const BREAKPOINT_XS = 'xs';
export const BREAKPOINT_SM = 'sm';
export const BREAKPOINT_MD = 'md';
export const BREAKPOINT_LG = 'lg';
export const BREAKPOINT_XL = 'xl';
export const BREAKPOINT_XXL = 'xxl';

export const BREAKPOINTS = {
  [BREAKPOINT_XS]: 0,
  [BREAKPOINT_SM]: 576,
  [BREAKPOINT_MD]: 768,
  [BREAKPOINT_LG]: 992,
  [BREAKPOINT_XL]: 1200,
  [BREAKPOINT_XXL]: 1400,
};

export const isMobileScreen = screenSize => ![BREAKPOINT_LG, BREAKPOINT_XL, BREAKPOINT_XXL].includes(screenSize);
export const isDesktopScreen = screenSize => [BREAKPOINT_XL, BREAKPOINT_XXL].includes(screenSize);

export const BREAKPOINTS_OBJECT = Object.keys(BREAKPOINTS).map(key => ({
  key,
  value: BREAKPOINTS[key]
}));

