import validateJs from 'utils/validate.js';
import { BUFFER_TIMEOUT } from '../../../../../constants';

const SCHEMA = {
  contractExpiry: {
    presence: true,
  },
  quantity: {
    presence: true,
    numericality: {
      greaterThan: 0,
    },
  },

};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function validate(data, state) {
  return sleep(BUFFER_TIMEOUT).then(()=> {
    const { termCurrency } = state;
    const { currency,  payoutCurrency } = data;

    if( currency !== payoutCurrency ) {
      SCHEMA.tradeDate = {
        presence: true,
      };
    }

    SCHEMA.payoutCurrency = {
      presence: !termCurrency ? true : {
        message: `^Cannot proceed with ${termCurrency}.`
      },
    }

    return validateJs(data, SCHEMA) || {};
  })
}
