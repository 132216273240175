import React, { Component, createContext } from 'react';
import debounce from 'lodash/debounce';
import { BREAKPOINTS_OBJECT } from './constants';
import PropTypes from 'prop-types';

const detectSize = () => {
  const winW = window.innerWidth;
  const screen = BREAKPOINTS_OBJECT.slice().reverse().find((({ value }) => value <= winW));
  return {
    screenSize: screen.key,
    window: {
      width: winW
    }
  }
};

const defaultSizeValues = detectSize();

export const BreakPointsContext = createContext(defaultSizeValues);

export function withBreakPointsContext(WrappedComponent) {
  return function WrapperComponent(props) {
    return (
      <BreakPointsContext.Consumer>
        {(responsive) => {
          return <WrappedComponent {...props} responsive={responsive} />
        }}
      </BreakPointsContext.Consumer>
    )
  }
}

const isChangedScreenSize = (screenSizeCurrent, screenSizeNext) => screenSizeCurrent && screenSizeCurrent !== screenSizeNext;

class BreakPointsProvider extends Component {

  constructor(props) {
    super(props);
    this.state = defaultSizeValues;
    this.detectBreakpoint = debounce(this._detectBreakpoint.bind(this), 150);
    this.init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return isChangedScreenSize(this.state.screenSize, nextState.screenSize);
  }

  init() {
    this.detectBreakpoint();
    window.addEventListener('resize', this.detectBreakpoint);
  }

  _detectBreakpoint = () => {
    this.setState({
      ...detectSize()
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.detectBreakpoint);
  }

  render() {
    const { children } = this.props;
    return (
      <BreakPointsContext.Provider value={this.state}>
        {children}
      </BreakPointsContext.Provider>
    )
  }
}

BreakPointsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default BreakPointsProvider;
