import React from "react";
import { Col, Row, Container } from "reactstrap";
import { FormattedNumber } from 'react-intl';

const OpenTradesDataView = (props) => {
  const { items } = props
  return (
    items && items.map((i => (
      <Container className="container-result">
         <Row>
          <Col xs={12} sm={12} className="text-left" >
            <b>External ID: {i.externalId}</b>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} className="text-left" >
            Client {i.direction}
          </Col>
          <Col xs={12} sm={4} className="text-left">
            <FormattedNumber value={i.tradingPrice} maximumFractionDigits={2} /> {i.currency}
          </Col>
          <Col xs={12} sm={4} className="text-left">
            Contract: {i.contractTicker}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} className="text-left" >
            Ref Price: <FormattedNumber value={i.referencePrice} maximumFractionDigits={2} />
          </Col>
          <Col xs={12} sm={4} className="text-left">
            Remaining Fixings: <FormattedNumber value={i.remainingFixings} maximumFractionDigits={2} />
          </Col>
          <Col xs={12} sm={4} className="text-left">
            Barrier Level: <FormattedNumber value={i.clientBarrierLevel} maximumFractionDigits={2} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} className="text-left" >
            Delta (lot): <FormattedNumber value={parseInt(i.deltaLot) || 0} maximumFractionDigits={2} />
          </Col>
          <Col xs={12} sm={4} className="text-left">
            Gamma ({i.gammaCurrency}): <FormattedNumber value={i.gamma} maximumFractionDigits={2} />
          </Col>
          <Col xs={12} sm={4} className="text-left">
            Vega ({i.vegaCurrency}): <FormattedNumber value={i.vega} maximumFractionDigits={2} />
          </Col>
        </Row>
      </Container>
    )))
  )
}

export default OpenTradesDataView;
