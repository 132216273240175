
import React, { Component, Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'reactstrap';
import { DATE_FORMAT_INPUT, DATE_FORMAT_INPUT_DATEPICKER, DATE_FORMAT_UTC } from 'constants.js';
import { normalizeDate } from 'utils/formNormalizers';
import FieldErrorMessage from '../FieldErrorMessage';
import { converDateToDB } from './InlineDateRangePicker/InlineDateRangePicker';
import Portal from 'reactstrap/lib/Portal';
import enUS from 'date-fns/locale/en-US';
registerLocale('en-US', enUS)

class UnEditableDataInput extends Component {
  render() {
    const { errorOnIsTouched, meta, placeHolder, readOnlyInput, showMonthYearPicker, ...rest } = this.props;
    return (
      <Fragment>
        <Input
          {...rest}
          invalid={meta.active && meta.invalid}
          placeholder={placeHolder}
          airia-label={placeHolder}
          readOnly={readOnlyInput}
          className={readOnlyInput ? ' form-control-active' : ''} />
        {meta.error ? <FieldErrorMessage {...meta} errorOnIsTouched={errorOnIsTouched} className={rest.name}/> : null}
      </Fragment>
    )
  }
}

export const defaultDateFormater = (value, format = DATE_FORMAT_INPUT) => {
  const d = (value && moment(value, format, true).isValid()) ? value : new Date(value);
  const date = moment(d, format, true).hour(0).minute(0).second(0).millisecond(0).utc(0).toDate();
  return date;
};

export const parseUTC = date => new Date(moment.utc(new Date(date)).format(DATE_FORMAT_UTC))

const InlineDatePicker = props => {
  const {
    placeHolder,
    readOnlyInput,
    readOnly,
    maxDate,
    onChange,
    onBlur,
    minDate,
    filterDate,
    dateFormater,
    value,
    meta,
    dateFormat = DATE_FORMAT_INPUT,
    includeIntervalsFromDefault,
    includeIntervalsFromDate,
    errorOnIsTouched = true,
    ...rest
  } = props;

  const handleChange = value => {
    const d = converDateToDB(normalizeDate(value));
    onChange(d);
  }

  const checkFormat = format => {
    return format === DATE_FORMAT_INPUT ? DATE_FORMAT_INPUT_DATEPICKER : format
  }
  const date = value ? dateFormater(parseUTC(value), dateFormat) : null;

  const includeDateIntervals = []
  if(includeIntervalsFromDefault) {
    const from = includeIntervalsFromDate ? includeIntervalsFromDate : value;
    includeDateIntervals.push({
      start: moment.utc(from).businessSubtract(includeIntervalsFromDefault, 'days').toDate(),
      end: moment.utc(from).businessAdd(includeIntervalsFromDefault, 'days').toDate()
    });
  }

  return <DatePicker
    locale="en-US"
    {...rest}
    onChange={handleChange}
    selected={date}
    dateFormat={checkFormat(dateFormat)}
    filterDate={filterDate}
    minDate={includeDateIntervals.length ? includeDateIntervals[0].start : minDate ? parseUTC(minDate) : null}
    maxDate={includeDateIntervals.length ? includeDateIntervals[0].end : maxDate ? parseUTC(maxDate) : null}
    // includeDateIntervals={ includeDateIntervals.length ? includeDateIntervals : null } // Available in newer version of react-datepicker
    customInput={<UnEditableDataInput errorOnIsTouched={errorOnIsTouched} placeHolder={placeHolder} meta={meta} {...rest} readOnlyInput={readOnlyInput} />}
  />;
}

InlineDatePicker.defaultProps = {
  dateFormater: defaultDateFormater,
  popperContainer: Portal,
  includeIntervalsFromDefault: 0,
  includeIntervalsFromDate: null,
  errorOnIsTouched: true,
};

InlineDatePicker.propTypes = {
  dateFormater: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  popperContainer: PropTypes.func,
  includeIntervalsFromDefault: PropTypes.number,
  includeIntervalsFromDate: PropTypes.string,
  errorOnIsTouched: PropTypes.bool
};

export default memo(InlineDatePicker);
