import React from 'react';
import InlineInput from 'components/form/InlineInput';
import { DATE_FORMAT_PRICING_ACCUMULATOR_MINI } from 'constants.js';
import { isWeekday, tommorowDay } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import { FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';

const ExpiryDateField = ({ formatMessage, maxExpiryDate, filterDate: customFilterDate }) => (
  <Col xs={12} sm={4}>
    <Field
      name="expiryDate"
      type="date"
      placeHolder={formatMessage({
        id: "trade/expiry_date.label",
        defaultMessage: "Expiry Date",
      })}
      label={<FormattedMessage id="trade/expiry_date.label"
        defaultMessage="Expiry Date" />}
      filterDate={customFilterDate || isWeekday}
      minDate={tommorowDay()}
      maxDate={maxExpiryDate}
      dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
      component={InlineInput}
    />
  </Col>
);

export default ExpiryDateField;
