export const PRICING_ASIAN_SWAP = '@PRICING/ASIAN_SWAP';
export const PRICING_ASIAN_SWAP_CARDS_FETCH_MORE = `${PRICING_ASIAN_SWAP}/CARDS/FETCH_MORE`;
export const PRICING_ASIAN_SWAP_CARD_START_DELETE = `${PRICING_ASIAN_SWAP}/CARD/START_DELETE`;

export const PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_APPROVE = `${PRICING_ASIAN_SWAP}/REQUEST/CONFIRMATION_MODAL_APPROVE`;
export const PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_CANCEL = `${PRICING_ASIAN_SWAP}/REQUEST/CONFIRMATION_MODAL_CANCEL`;
export const ASIAN_SWAP_CARDS_REQUEST_PRICE = `${PRICING_ASIAN_SWAP}/CARD/REQUEST/PRICE`;

export const asianSwapCardsFetchMore = payload => ({
  type: PRICING_ASIAN_SWAP_CARDS_FETCH_MORE,
  payload
});

export const asianSwapCardStartDelete = payload => ({
  type: PRICING_ASIAN_SWAP_CARD_START_DELETE,
  payload,
});

export const asianSwapApproveModalAction = payload => ({
  type: PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_APPROVE,
  payload
});

export const asianSwapCancelModalAction = payload => ({
  type: PRICING_ASIAN_SWAP_CONFIRMATION_MODAL_CANCEL,
  payload
});

export const asianSwapCardsRequestPrice = payload => ({
  type: ASIAN_SWAP_CARDS_REQUEST_PRICE,
  payload
});
