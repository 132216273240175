import { onChangeField } from 'components/form/utils/';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import { classNames } from 'pages/price/PriceForm/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { FormFeedback } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { change, touch } from 'redux-form';
import { PRICING_FORM } from 'redux/actions/price';
import InlineBox from './InlineBox';
import InputAnimation from './InputAnimation';
import Loader from 'components/common/loader/Loader';

const findValue = (val, options = []) => {
  return options.find(({ value }) => val === value);
}

const preselectValueIfOneOption = ({ options, touched }) => {
  if (options?.length === 2 && !touched) {
      const result = options.find(({label}) => label !== 'N/A');
      return result ? { value: result.value, options, touched: true } : null;
  }
  return null;
}

class InlineSelectInput extends Component {
  constructor(args) {
    super(args);
    this.state = {
      disabled: false,
      value: null,
      options: args.options || [],
      touched: false,
      preselectedDefaultFirstOptionOnLoad: false,
    }
    this.setSingleValue();
  }

  static getDerivedStateFromProps(props, state) {
    const { options = [], input, disabled = false, selectedSingleOption, selectedActiveSingleOption, preselectIfOneOption } = props;
    const { options: optionsState, touched } = state;
    const selectValue = input.value;
    const isDisabled = selectedSingleOption !== false && !selectValue && options.length === 1 ? true : disabled;
    if (!isEqual(options, optionsState) || input.value !== state.value) {
      const preselectedValue = preselectValueIfOneOption({ options, touched });
      return preselectedValue && !preselectIfOneOption ? preselectedValue : {
        value: input.value,
        options,
        disabled: (!selectedActiveSingleOption && selectedSingleOption !== false && Array.isArray(options) && options.length === 1 && options[0].value === input.value) || isDisabled,
      }
    }

    return null;
  }

  // shouldComponentUpdate(nextProps) {
  //   return !isEqual(this.props, nextProps);
  // }

  componentDidUpdate() {
    this.setSingleValue();
  }

  setSingleValue() {
    const { options = [], input = {}, selectedSingleOption, change, meta = {}, resetEmptyValue = true, setDefaultFirstOption = false } = this.props;
    const { preselectedDefaultFirstOptionOnLoad } = this.state;
    const { form: formName } = meta;
    const { value: selectValue, name: inputName } = input;

    if (resetEmptyValue && !options?.length && !isNull(selectValue)) {
      change(formName, inputName, null);
    }

    if (!preselectedDefaultFirstOptionOnLoad && setDefaultFirstOption && options?.length) {
      change(formName, inputName, options[0].value);
      this.setState({preselectedDefaultFirstOptionOnLoad: true});
    }
    else if (selectedSingleOption !== false && !selectValue && options && options.length === 1) {
      const selectValue = options[0].value;
      // Forse set single value for select
      change(formName, inputName, selectValue);
    } else if (selectedSingleOption !== false) {
      // @TODO: check if we can remove selectValue.value
      const checkValue = selectValue?.value ? selectValue.value : selectValue;
      const includeValue = findValue(checkValue, options);
      // Set null value if it isn't in options

      const newValue = selectValue && includeValue ? selectValue : null;
      if (selectValue && !newValue) {
        change(formName, inputName, newValue);
      }
    }
  }

  onChange = value => {
    const { justValue, input: { name, onChange }, onChangeFilter } = this.props;
    let result;
    if (justValue && !!value) {
      result = value.value;
    } else if (isNull(value)) {
      result = null;
    } else {
      result = {
        ...value,
        name: name
      };
    }

    onChangeField(result, onChange, { name, onChangeFilter });
  }

  onInputChange = value => {
    const { input: { name }, onInputChange } = this.props;
    if (typeof onInputChange === 'function') {
      onInputChange({ name, value })
    }
  }

  onBlur = () => {
    const { touched } = this.state;
    const { meta, input } = this.props;
    if (!touched) {
      touch(meta.form, input.name);
      input.onBlur();
    }
  }

  render() {
    const { fieldId, label, input, meta, className, placeHolder, showError, autoFocus, forceDisabled, clearable, loading = {} } = this.props;
    const animation = !meta.form || meta.form === PRICING_FORM;
    const { disabled, value, options } = this.state;
    const { touched, invalid, submitting, error } = meta;
    const formControlClassName = classNames({
      'inline-select': true,
      'form-control-force-disabled': forceDisabled,
      'single-value': options?.length <= 1,
      'select-clean': !clearable,
      ...(typeof className === 'string' || className instanceof String) ? { [className]: true } : className
    });

    return (
      <InlineBox label={label} meta={meta} name={input.name} className={formControlClassName || null}>
        <div className="form-control-wrapper" id={fieldId}>
        {loading[input.name] ? <Loader /> :
          <>
            <ReactSelect
              options={options}
              {...input}
              autoFocus={autoFocus}
              value={value}
              onBlur={this.onBlur}
              onChange={this.onChange}
              placeholder={placeHolder}
              onInputChange={this.onInputChange}
              disabled={forceDisabled || disabled} />
            {animation ? <InputAnimation value={value} active={!meta.active} name={input.name} /> : null}
            {showError && touched && invalid && !submitting && error && error.length ?
              <FormFeedback className="d-block">
                {error[error.length - 1]}
              </FormFeedback>
              : null}
          </>}
        </div>
      </InlineBox>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

InlineSelectInput.defaultProps = {
  justValue: false,
  options: [],
  autoFocus: false,
  clearable: true,
  forceDisabled: false,
  resetEmptyValue: true,
  preselectIfOneOption: false,
  className: 'inline-select',
  fieldId: '',
};

InlineSelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  resetEmptyValue: PropTypes.bool.isRequired,
  forceDisabled: PropTypes.bool,
  clearable: PropTypes.bool,
  preselectIfOneOption: PropTypes.bool,
  fieldId: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.bool)
  ])
};

export default connect(null, mapDispatchToProps)(InlineSelectInput);

