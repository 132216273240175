import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import { change, startSubmit, stopSubmit } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { scrollToStartAction } from 'redux/actions/common';
import { priceFormFailed, priceFormSubmitted, PRICING_FORM, PRICING_FORM_FAILED, PRICING_FORM_SUBMITTED, PRICING_SUBMIT_FORM, PRICING_SUBMIT_FORM_CANCEL, PRICING_UPDATE_TRAIL_RPICE } from 'redux/actions/price';
import { PRICING_SWAP_CARD_CREATE } from 'redux/actions/swap';
import { PRICING_FX_CARD_CREATE } from 'redux/actions/fx';
import { cantCalculatePriceFieldResponse, notificationErrorSimple } from 'redux/alerts/actions';
import { fetchAccumulationLevel, fetchBarrierLevel, fetchPrice } from 'redux/queries/price';
import { from, merge, Observable, of, zip } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { priceFormValueSelector } from '../../price-form';

const submitFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    map(({ payload }) => payload),
    debounceTime(300),
    switchMap(payload => {
      const {
        currentFormConfig: formConfig,
        structure,
        underlying
      } = state$.value.price;
      const formType = priceFormValueSelector(state$.value).structure;

      // added errors to notify user
      if (isNull(structure) || isEmpty(structure)) {
        return from([
          stopSubmit(PRICING_FORM),
          notificationErrorSimple('Please select structure details.')
        ]);
      }

      if (isNull(underlying) || isEmpty(underlying)) {
        return from([
          stopSubmit(PRICING_FORM),
          notificationErrorSimple('Please select underlying.')
        ]);
      }

      return from(loadAuditTrail(formType, formConfig, payload, state$))
        .pipe(
          map((auditTrail) => priceFormSubmitted({
            response: auditTrail,
            currentFormConfig: formConfig,
            formData: payload
          })),
          catchError(() => {
            return of(priceFormFailed());
          }),
          // takeUntil(action$.pipe(ofType(PRICING_SUBMIT_FORM_CANCEL))),
        )
    }),
  );

const priceFormSubmittingTrackingEpic = action$ =>
  merge(
    action$.pipe(
      ofType(PRICING_SUBMIT_FORM),
      mapTo(startSubmit(PRICING_FORM))
    ),
    merge(
      action$.pipe(ofType(PRICING_FORM_SUBMITTED)),
      action$.pipe(ofType(PRICING_FORM_FAILED)),
      action$.pipe(ofType(PRICING_SWAP_CARD_CREATE)),
      action$.pipe(ofType(PRICING_FX_CARD_CREATE)),
      action$.pipe(ofType(PRICING_SUBMIT_FORM_CANCEL))
    ).pipe(
      mapTo(stopSubmit(PRICING_FORM))
    )
  );

// @TODO: avoid pure JS selector use ref
function getPositionBlock(el) {
  var x = 0;
  var y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    x += el.offsetLeft - el.scrollLeft + el.clientLeft;
    y += el.offsetTop - el.scrollTop + el.clientTop;
    el = el.offsetParent;
  }
  return { x, y: y > 90 ? y : 0 };
}

const scrollToFormResponseEpic = action$ =>
  zip(
    action$.pipe(ofType(PRICING_FORM_SUBMITTED)),
    action$.pipe(
      ofType(PRICING_UPDATE_TRAIL_RPICE),
      filter(({ payload }) => payload)
    ),
  ).pipe(
    delay(250), //Wait until Pricing Tag render in mobile screen
    map(() => {
      const el = document.getElementById('resp-block');
      const respPos = getPositionBlock(el);
      return scrollToStartAction(respPos);
    })
  );

const submitFormFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FORM_FAILED),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    mergeMap(() => {
      const { currentFormConfig: formConfig } = state$.value.price;
      return from([
        cantCalculatePriceFieldResponse(formConfig.output.label),
        change(PRICING_FORM, formConfig.output.name, null)
      ]);
    })
  );


const loadAuditTrail = (formType, formConfig, formData, state$) => {
  const state = state$.value;
  const descriptionId = state.price.structure.id;
  const underlyingId = state.price.underlying.id;
  switch (formType) {
    case STRUCTURE_ACCUMULATOR:
      return loadAuditTrailAccumulator(formConfig.output, formData, descriptionId.toString(), underlyingId.toString());
    default:
      return Observable.error('undefined form structure');
  }
}

const loadAuditTrailAccumulator = (output, formData, descriptionId, underlyingId) => {
  switch (output.name) {
    case 'barrierLevel':
      return from(fetchBarrierLevel(
        underlyingId,
        parseFloat(formData.accumulationLevel),
        parseFloat(formData.notional, 10),
        parseFloat(formData.pricePerLot),
        descriptionId,
      ));
    case 'accumulationLevel':
      return from(fetchAccumulationLevel(
        underlyingId,
        parseFloat(formData.barrierLevel),
        parseFloat(formData.notional, 10),
        parseFloat(formData.pricePerLot),
        descriptionId,
      ));
    case 'price':
      const { remainderLevel, expiration: structureExpiryDate } = formData;
      return from(fetchPrice(
        underlyingId,
        parseFloat(formData.notional, 10),
        parseFloat(formData.accumulationLevel),
        parseFloat(formData.barrierLevel),
        descriptionId,
        structureExpiryDate,
        parseFloat(remainderLevel, 10),
      ));
    default:
      return Observable.error('undefined calculation method');
  }
}

export default combineEpics(
  submitFormEpic,
  priceFormSubmittingTrackingEpic,
  submitFormFailedEpic,
  scrollToFormResponseEpic
);
