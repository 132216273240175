import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FormattedNumber } from 'react-intl';

const UnwindSummaryView = ({
  cashSettlement,
  referencePrice,
  delta,
  gamma,
  vega,
  vegaCurrency,
  gammaCurrency,
  currency,
}) => {
  const deltaValue = delta ? Math.round(Number(delta)) : 0;
  return (
    <Container className="container-result container-divider">
      <Row>
        <Col xs={12} sm={12} className="text-left" >
          <b>Summary:</b>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4} className="text-left" >
          Client {cashSettlement > 0 ? 'Pays' : 'Receives'}
        </Col>
        <Col xs={12} sm={4} className="text-left">
          <FormattedNumber value={ cashSettlement ? Math.abs(cashSettlement) : 0 } maximumFractionDigits={2} /> {currency}
        </Col>
        <Col xs={12} sm={4} className="text-left" >
          Ref Price: <FormattedNumber value={referencePrice} maximumFractionDigits={2} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4} className="text-left" >
          Delta (lot): <FormattedNumber value={deltaValue || 0} maximumFractionDigits={2} />
        </Col>
        <Col xs={12} sm={4} className="text-left">
          Gamma ({gammaCurrency}): <FormattedNumber value={gamma} maximumFractionDigits={2} />
        </Col>
        <Col xs={12} sm={4} className="text-left">
          Vega ({vegaCurrency}): <FormattedNumber value={vega} maximumFractionDigits={2} />
        </Col>
      </Row>
    </Container>
  );
}

export default UnwindSummaryView;
