import React, { Component, Fragment } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FormattedDate } from 'react-intl';
import { onChangeField } from 'components/form/utils/';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import './InlineDateRangePicker.scss';

export const converDateToDB = (d) => d ? d.toISOString() : null;

class InlineDateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null
    }
  }

  static getDerivedStateFromProps(props) {
    const { input } = props;
    const { value } = input;
    if (input && isObject(value) && value.min && value.max) {
      return { startDate: value.min, endDate: value.max }
    } else if (input && (value === '' || isNull(value))) {
      return { startDate: null, endDate: null }
    }
    return null;
  }

  handleChange = ({ startDate, endDate }) => {
    const { input: { name, onChange }, onChangeFilter } = this.props;

    const value = !isNull(startDate) && !isNull(endDate) ?
      {
        min: converDateToDB(startDate),
        max: converDateToDB(endDate),
      } : null;

    onChangeField(value, onChange, { name, onChangeFilter });
  }

  handleChangeStart = (startDate) => this.handleChange({ startDate })

  handleChangeEnd = (endDate) => this.handleChange({ endDate })

  clearDates = () => {
    this.handleChange({ startDate: null, endDate: null });
  }

  onApply = (e, picker) => {
    this.handleChange({
      startDate: picker.startDate,
      endDate: picker.endDate
    });
  }

  render() {
    const { placeHolder, label, input } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <Fragment>
        {label ? <Label for={input.name}>{label}</Label> : null}
        <div className="daterangepicker-wrapper">
          <DateRangePicker onApply={this.onApply}>
            <div className="form-control">
              {startDate && endDate ?
                <Fragment>
                  <FormattedDate value={startDate} /> - <FormattedDate value={endDate} />
                </Fragment>
                :
                <span className="form-control-placeholder">{placeHolder}</span>
              }
            </div>
          </DateRangePicker>
          {startDate && endDate ? <span className="btn-clear" onClick={this.clearDates}>×</span> : null}
        </div>
      </Fragment>
    );
  }
}

InlineDateRangePicker.propTypes = {
  onChange: PropTypes.func,
};

export default InlineDateRangePicker;
