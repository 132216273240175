import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import MaxShowField from './components/MaxShowField';
import StopPriceField from './components/StopPriceField';
import TotalSizeField from './components/TotalSizeField';
import PriceField from './components/PriceField';
import { TYPE_STOP, TYPE_ICEBERG, TYPE_STOP_LIMIT, TYPE_OCO } from 'components/trade/constants.js';


const OrderTypesRow = props => {
  const { formData, intl: { formatMessage }, isCombinedCard, compoPrice, structure, isMinifutureFx } = props;
  const { orderType } = formData;

  const inputs = [
    TotalSizeField({ formatMessage, isCombinedCard, structure, isMinifutureFx }),
    ...(orderType === TYPE_ICEBERG) ? [MaxShowField({ formatMessage })] : [],
    ...(orderType === TYPE_STOP || orderType === TYPE_STOP_LIMIT) ? [StopPriceField({ formatMessage })] : [],
    ...(orderType !== TYPE_OCO) ? [PriceField({ orderType, isCombinedCard, compoPrice, formatMessage })] : [],
  ];

  const fields = [
    ...inputs,
  ];

  return (
    <Row className="row-size-xs">
      {fields.map((component, index) => (
        <Fragment key={index}>
          {component}
        </Fragment>
      ))}
    </Row>
  );
}

OrderTypesRow.propTypes = {
  formData: PropTypes.object.isRequired,
};

export default OrderTypesRow;
