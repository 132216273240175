import React from 'react';
import Loader from 'components/common/loader/Loader';

const LoadingFullPage = _ => (
  <div className="full-page">
    <Loader />
  </div>
);

export default LoadingFullPage;
