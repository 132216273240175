import { STRUCTURE_FX, CARD_STATUSES } from 'constants.js';
import { combineEpics, ofType, } from 'redux-observable';
import { fxCardUpdate, PRICING_FX_CONFIRMATION_MODAL_CANCEL, PRICING_FX_FORM_OUTPUT, PRICING_FX_CONFIRMATION_MODAL_APPROVE } from 'redux/actions/fx';
import { priceFormFailed } from 'redux/actions/price';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { cardFxError } from 'redux/epics/price/structures/fx/';
import { getCardByFormName, getCardById, getErrorsCardByFormName } from 'redux/epics/price/structures/swap/utils';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { updateFxCardMutation } from 'redux/queries/fx';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { reset, touch, untouch, change } from 'redux-form';

const { REQUESTED } = CARD_STATUSES;

const formFilter = action => action && action.meta && !action.meta.form.indexOf(PRICING_FX_FORM_OUTPUT)
const trailPricesFilter = state$ => priceFormValueSelector(state$.value) && state$.value.fx.trailPrice && Array.isArray(state$.value.fx.trailPrice.cards);

const eventCardUpdate = (action$, state$, debounce = 50) => merge(
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('tradeDate')),
    filter(formFilter),
    filter(() => trailPricesFilter(state$)),
    debounceTime(debounce),
    filter(({ payload }) => !!payload),
  ),
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('direction')),
    filter(formFilter),
    filter(() => trailPricesFilter(state$)),
    debounceTime(debounce),
    filter(({ payload }) => !!payload),
  ),
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('notionalCurrency')),
    filter(formFilter),
    filter(() => trailPricesFilter(state$)),
    debounceTime(debounce),
    filter(({ payload }) => !!payload),
  ),
  action$.pipe(
    filter(changeAnyFormFieldActionFilter('notional')),
    filter(formFilter),
    filter(() => trailPricesFilter(state$)),
    debounceTime(debounce),
    filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0),
  ),
);

const startCardUpdateEpic = (action$, state$) =>
  eventCardUpdate(action$, state$, 300).pipe(
    switchMap(({ meta }) => {
      const card = getCardByFormName(state$, meta, 'fx');
      const isRequested = card.status === REQUESTED;
      return from([
        fxCardUpdate({
          ...card,
          isLoading: true, //Use to disable submit button
          confirmationPopup: isRequested
        })
      ]);
    })
  );

const sendUpdateRequest = (card, actionsToTriggerValidate = []) => from(updateFxCardMutation(STRUCTURE_FX.toLowerCase(), card))
  .pipe(
    switchMap(({ id, price, status, limit }) => {
      const cardNew = {
        ...card,
        id,
        status,
        price,
        limit,
      };

      return from([
        fxCardUpdate(cardNew),
        ...actionsToTriggerValidate,
      ])
    }),
    catchError((error) => {
      const actions = [
        priceFormFailed(),
        fxCardUpdate(card)
      ];
      const err = getSingleGraphQlError(error);
      if (err?.message && err.code === 403) {
        return from([
          ...actions,
          notificationErrorSimple(err.message),
        ])
      }
      return from([
        ...actions,
        cardFxError(card, 'update'),
      ]);
    })
  );

const isConfirmationPopup = (state$, meta) => {
  const card = getCardByFormName(state$, meta, 'fx');
  return card?.confirmationPopup;
};

const cardUpdateEpic = (action$, state$) =>
  eventCardUpdate(action$, state$, 300).pipe(
    filter(({ payload, meta }) => !!payload),
    map(({ meta, payload }) => {
      const { field, form: formName } = meta;
      const card = getCardByFormName(state$, meta, 'fx');
      const formErrors = getErrorsCardByFormName(state$, meta);
      return { field, formName, payload, card, formErrors, meta }
    }),
    filter(({ meta }) => !isConfirmationPopup(state$, meta)),
    switchMap(({ meta, payload, formName }) => {
      const card = getCardByFormName(state$, meta, 'fx');
      const cardUpdated = {
        [meta.field]: payload
      };

      const actionsToTriggerValidate = meta.field === "notionalCurrency" ? [
        change(formName, 'notional', null),
        change(formName, 'notional', card.notional),
        untouch(formName, 'notional'),
        touch(formName, 'notional'),
      ] : [];

      return sendUpdateRequest({
        ...card,
        ...cardUpdated,
        loading: false,
        isLoading: false
      }, actionsToTriggerValidate)
    })
  );

const closeModalEpic = (action$, state$) =>  action$.pipe(
  ofType(PRICING_FX_CONFIRMATION_MODAL_CANCEL),
  switchMap(({ payload }) => {
    const card = getCardById(state$.value.fx.trailPrice.cards, payload);
    const actions = [
      reset(`${PRICING_FX_FORM_OUTPUT}_${payload}`),
      fxCardUpdate({
        ...card,
        loading: false,
        isLoading: false,
        confirmationPopup: false,
      }),
    ];
    return from(actions);
  })
)

const approveModalEpic = (action$, state$) => action$.pipe(
  ofType(PRICING_FX_CONFIRMATION_MODAL_APPROVE),
  switchMap(({ payload }) => {
    const card = getCardById(state$.value.fx.trailPrice.cards, payload);
    const formName = `${PRICING_FX_FORM_OUTPUT}_${payload}`;
    const { values } = state$.value.form[formName] || {}

    return sendUpdateRequest({
      ...card,
      ...values,
      loading: false,
      isLoading: false,
      confirmationPopup: false,
    }, [])
  }),
)

export default combineEpics(
  startCardUpdateEpic,
  cardUpdateEpic,
  approveModalEpic,
  closeModalEpic,
);
