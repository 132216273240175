import { actions, constants } from 'react-redux-alerts';

export const INFO_MESSAGES_USER_PAGE = 'INFO-MESSAGES/USER/PAGE';

export function notificationUserPageContent(payload) {
  const { seo, onClose, onAccept } = payload;
  return actions.createAlert(INFO_MESSAGES_USER_PAGE, {
    onClose,
    seo,
    onAccept,
  });
}

export const dismissNotificationUserPageContent = () => ({
  type: constants.DISMISS_ALERT,
  alertName: INFO_MESSAGES_USER_PAGE
});
