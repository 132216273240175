import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getSortLabelOptions } from 'components/tables/utils';

export const SORT_FIELDS = [
  {
    key: 'updatedAt',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/last-changes" defaultMessage="Last Changes" /></div>,
    displayAllways: true
  },
  {
    key: 'time',
    className: '',
    isDisabled: true,
    label: <div className="text"><FormattedMessage id="pricing-history/time" defaultMessage="Time" /></div>,
  },
  {
    key: 'structure',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/structure" defaultMessage="Structure" /></div>,
    displayAllways: true
  },
  {
    key: 'type',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/consumer-proudecer" defaultMessage="Type" /></div>,
    displayAllways: true
  },
  {
    key: 'commodityContract',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/commodity-contract" defaultMessage="Commodity Contract" /></div>,
    displayAllways: true
  },
  {
    key: 'contractExpiry',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/contract-expiry" defaultMessage="Contract Expiry" /></div>
  },
  {
    key: 'quotedCurrency',
    className: '',
    label: <div className="text"><FormattedMessage id="pricing-history/currency" defaultMessage="Currency" /></div>
  },
  {
    key: 'optionExpiryDate',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/structure-expiry" defaultMessage="Structure Expiry Date" /></div>
  },
  {
    key: 'referencePrice',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/reference-price" defaultMessage="Reference Price" /></div>
  },
  {
    key: 'remainderFeature',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="form.price.fields.remainder-quantity.label" defaultMessage="Remainder Feature" /></div>
  },
  {
    key: 'remainderLevel',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="form.price.fields.remainder-level.label" defaultMessage="Remainder Level" /></div>
  },
  {
    key: 'strikeLevel',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/accumulation-level" defaultMessage="Accumulation Level" /></div>
  },
  {
    key: 'barrierType',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/barrier-type" defaultMessage="Barrier Type" /></div>
  },
  {
    key: 'barrierLevel',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/barrier-level" defaultMessage="Barrier Level" /></div>
  },
  {
    key: 'leverage',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/leverage" defaultMessage="Leverage" /></div>,
  },
  {
    key: 'leverageStyle',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/leverageStyle" defaultMessage="Leverage Style" /></div>,
  },
  {
    key: 'accumulationStyle',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/accumulationStyle" defaultMessage="Accumulation Style" /></div>,
  },
  {
    key: 'price',
    className: 'text-right',
    label: <div className="text"><FormattedMessage id="pricing-history/price-pe-lot" defaultMessage="Price" /></div>,
    displayAllways: true
  },
];

export const getSortLabel = getSortLabelOptions(SORT_FIELDS);
