import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { pricingMiniFormValuesSelector } from '../pricinigMiniForm/utils';
import PriciniMiniChart from './PriciniMiniChart';

function PriciniMiniChartContainer(props) {
  const { bloombergTicker } = props;
  return (
    bloombergTicker ?
      <PriciniMiniChart {...props} />
      : null
  )
};

PriciniMiniChartContainer.defaultProps = {
  reference: null,
  barrLevel: null,
  accLevel: null,
  remainderLevel: null,
  bloombergTicker: null,
  fields: [],
};

PriciniMiniChartContainer.propTypes = {
  reference: PropTypes.number,
  barrLevel: PropTypes.number,
  accLevel: PropTypes.number,
  remainderLevel: PropTypes.number,
  form: PropTypes.string.isRequired,
  bloombergTicker: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  // Use for chart in feature will add it to card as chart property
  const formValues = ownProps.form ? pricingMiniFormValuesSelector(ownProps.form)(state) : null;
  return { ...formValues };
}

export default connect(mapStateToProps)(PriciniMiniChartContainer);
