import { zip } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { PRICING_FORM_SUBMITTED, PRICING_FORM_FAILED, PRICING_UPDATE_TRAIL_RPICE } from 'redux/actions/price';
import { goToStep } from 'redux/actions/tours';
import { filter, mapTo, map, switchMap, take } from 'rxjs/operators';
import { COMMON_SCROLL_FINISH } from 'redux/actions/common';

const successPriceFormSubmitEpic = (action$, state$) =>
  zip(
    action$.pipe(
      ofType(PRICING_FORM_SUBMITTED),
      filter(() => state$.value.tours && state$.value.tours.show === true)
    ),
    action$.pipe(ofType(PRICING_FORM_SUBMITTED)),
  ).pipe(
    switchMap(() => {
      return zip(
        action$.pipe(ofType(PRICING_UPDATE_TRAIL_RPICE)),
        action$.pipe(ofType(COMMON_SCROLL_FINISH))
      ).pipe(
        map(() => {
          return goToStep(12)
        }), // Tooltip '.tour-price-btn',
        take(1)
      )
    })
  );

const errorPriceFormSubmitEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FORM_FAILED),
      filter(() => state$.value.tours && state$.value.tours.show === true),
      mapTo(goToStep(10)),
    )

export default combineEpics(
  successPriceFormSubmitEpic,
  errorPriceFormSubmitEpic,
)
