import React from 'react';
import { Col } from 'reactstrap';
import PriceFieldComponent from './../swap/PriceField';

const PriceField = ({
  orderType,
  isCombinedCard,
  compoPrice,
  formatMessage,
}) => (
  <Col xs={12} sm={4}>
    <PriceFieldComponent orderType={orderType} isCombinedCard={isCombinedCard} compoPrice={compoPrice} formatMessage={formatMessage} />
  </Col>
);

export default PriceField;
