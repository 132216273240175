import {
  PRICING_MINI_ACCUMULATOR_BANK_HOLIDAYS_SET,
  PRICING_MINI_ACCUMULATOR_CARDS_LOADING,
  PRICING_MINI_ACCUMULATOR_CARDS_UPDATE,
  PRICING_MINI_ACCUMULATOR_CARD_DISABLE,
  PRICING_MINI_ACCUMULATOR_CARD_UPDATE,
  PRICING_MINI_ACCUMULATOR_CARD_COPY,
  PRICING_MINI_ACCUMULATOR_CARD_DELETE,
  PRICING_MINI_ACCUMULATOR_CARD_REFERENCE_PRICE_STOP,
} from 'redux/actions/accumulator-mini';

type CardType = {
  id: string | null
}

export type AccumulatorMiniReducerState = {
  cards: Array<any> | [],
  isLoading: Boolean | false,
  pageInfo: Object | {},
}

const PRICING_MIN_INIT_STATE: AccumulatorMiniReducerState = {
  cards: [],
  isLoading: false,
  pageInfo: {},
};

const miniAccumulatorUpdateCard = (cards: Array<any> = [], card) => {
  const findIndex = cards.findIndex(({ id }) => id === card.id);
  if (findIndex >= 0) {
    cards[findIndex] = {
      ...cards[findIndex],
      ...card,
    };
  }
  return cards;
}

const miniAccumulatorStopCalculatingReferencePrice = (cards: Array<any> = [], cardId) => {
  const findIndex = cards.findIndex(({ id }) => id === cardId);
  if (findIndex >= 0) {
    cards[findIndex] = {
      ...cards[findIndex],
      isCalculating: false,
      isReferencePriceForSolve: false,
      isCardResult: false,
      isLoading: false,
    };
  }
  return cards;
}

export const miniAccumulatorMegeCards = (cardsNext = [], cardsPrev: Array<any> = []) => {
  if (!cardsPrev?.length) return cardsNext;

  const cardsPrevMap = new Map();
  cardsPrev.forEach((card) => {
    const { id } = card;
    if (id) cardsPrevMap.set(id, card);
  })

  return Array.isArray(cardsNext) ? cardsNext.map((card: CardType) => {
    const { id } = card;
    if (cardsPrevMap.has(id)) {
      const cardPrev = cardsPrevMap.get(id);
      return {
        ...card,
        ...cardPrev,
      }
    }
    return card;
  }) : [];
}

export default function priceReducer(state = PRICING_MIN_INIT_STATE, action) {
  try {
    switch (action.type) {
      // Single
      case PRICING_MINI_ACCUMULATOR_CARD_UPDATE:
        return {
          ...state,
          cards: miniAccumulatorUpdateCard(state.cards, action.payload)
        }
      case PRICING_MINI_ACCUMULATOR_CARD_REFERENCE_PRICE_STOP:
        return {
          ...state,
          cards: miniAccumulatorStopCalculatingReferencePrice(state.cards, action.payload)
        }

      // Multiple
      case PRICING_MINI_ACCUMULATOR_CARDS_UPDATE:
        {
          const { cards: cardsNext, pageInfo } = action.payload;
          const cards = miniAccumulatorMegeCards(cardsNext, state?.cards || []);
          return { ...state, cards, pageInfo }
        }

      case PRICING_MINI_ACCUMULATOR_CARD_DISABLE:
        return { ...state, chart: action.payload }

      case PRICING_MINI_ACCUMULATOR_CARDS_LOADING:
        return { ...state, isLoading: action.payload }

      case PRICING_MINI_ACCUMULATOR_BANK_HOLIDAYS_SET:
        return { ...state, bankHolidays: action.payload };

      case PRICING_MINI_ACCUMULATOR_CARD_COPY:
        {
          const { from, to } = action.payload;
          const cards = [...state.cards];
          const index = cards.findIndex(({ id }) => id === from);
          cards.splice(index + 1, 0, { ...cards[index], id: to });
          return { ...state, cards };
        }
      case PRICING_MINI_ACCUMULATOR_CARD_DELETE:
        {
          const cards = [...state.cards];
          const index = cards.findIndex(({ id }) => id === action.payload);
          if (index > -1) cards.splice(index, 1);
          return { ...state, cards };

        }

      default:
        return state;
    }
  }
  catch (error) {
    console.error(error);
    return state;
  }
}
