import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

export const filterOrderStatutes = (list, statuses = []) => {
  if (statuses && !statuses.length) return list;
  return list && uniqBy(list, 'id').filter(item => {
    statuses = statuses || [];
    return item.status && statuses.includes(item.status);
  })
};

export const mergeOrders = (updateItems, propItems) => {
  let propItemsNew = [...propItems];
  const updateItemsTop = [];
  if (updateItems && !updateItems.length) return propItems;
  updateItems.forEach(item => {
    const notificationItem = {
      ...item,
      updatedAt: item.updatedAt || item.createdAt
    };
    const findElIndex = propItemsNew.findIndex(({ id }) => id === notificationItem.id);
    if (findElIndex >= 0) {
      const findItem = propItemsNew[findElIndex];
      if (!isEqual(findItem, notificationItem)) {
        propItemsNew[findElIndex] = moment(findItem.updatedAt).isAfter(notificationItem.updatedAt) ?
          { ...notificationItem, ...findItem } :
          { ...findItem, ...notificationItem };
      }
    } else {
      updateItemsTop.push(notificationItem);
    }
  });
  return uniqBy([
    ...updateItemsTop,
    ...propItemsNew
  ], 'id');
}

export const normalizeId = id => id ? id.replace(/=/g, '-') : null;
