import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { change, Field } from 'redux-form';
import { onChangeField } from 'components/form/utils/';

export const markerLeftPosition = (options = [], active) => {
  const findIndex = options.findIndex((option) => option.value === active);
  const leftPostion = 100 / options.length * findIndex;
  return leftPostion > 0 ? leftPostion.toFixed(4).toString() : '0'
}

function RadioGroup(props) {

  const { groupType, options : optionProps = [], label, input, change, meta = {}, onChange, onChangeFilter, disabled, groupStyle } = props;
  const options = optionProps || [];
  const name = input && input.name ? input.name : null;
  const markerStyle = (input && input.value) ?
    {
      left: `${markerLeftPosition(options, input.value)}%`
    }
    : null;

  // @TODO: check it better move login to RXJS
  if (options && options.length === 1) {
    const selectValue = options[0].value;
    // Forse set single value for radiogroup
    change(meta.form, name, selectValue);
  }

  const onChangeFunc = () => {
    onChangeField(input.value, onChange || input.onChange, { name: input.name, onChangeFilter });
  }

  return (
    <FormGroup style={groupStyle} className={`form-group-radiogroup form-group-size-${groupType} form-group-${name.toLowerCase()}`}>
      {label ? <Label for={name}>{label}</Label> : null}
      <div className={`pills-rounded nav clearfix nav-pills nav-pills-col-${options.length}`}>
        {options.map((radio, index) => {
          const inputId = name + meta.form + '-' + index;
          return (
            <div className="nav-item" key={radio.value}>
              <Field
                name={name}
                id={inputId}
                component="input"
                type="radio"
                value={radio.value}
                onChange={onChangeFunc}
                disabled={disabled || radio.disabled}
              />
              <Label className="nav-link" for={inputId}>
                <span>
                  {radio.label}
                </span>
              </Label>
            </div>)
        })}
        {input.value ? <div className="nav-pills-marker" key="pills-marker" style={markerStyle}></div> : null}
      </div>
    </FormGroup>
  )
};

RadioGroup.defaultProps = {
  options: [],
  groupType: 'md',
  meta: {},
  disabled: false,
  groupStyle: {}
};

RadioGroup.propTypes = {
  disabled: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  groupType: PropTypes.oneOf(['xs', 'sm', 'lg', 'md']),
  groupStyle: PropTypes.object
};

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export { RadioGroup as Component };
export default compose(
  memo,
  connect(null, mapDispatchToProps),
)(RadioGroup);
