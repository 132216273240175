import { from, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { STRUCTURE_SWAP, INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import { extractSwapContract } from 'pages/price/output/selectors';
import { PRICINIG_SWAP_FORM_OUTPUT } from 'pages/price/output/swap/swapPricingOutputCardForm/SwapPricingOutputCardForm';
import { formatDate2UI } from 'pages/price/PriceForm/utils';
import { combineEpics, ofType } from 'redux-observable';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { pricingDataLoader, setLimitationForm, updateTrailPrice } from 'redux/actions/price';
import { loadedSwapData, PRICING_SWAP_CARD_START_DELETE, PRICING_SWAP_DATA_LOADED, PRICING_SWAP_FORM_LOAD, swapCardUpdate } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { LIMITATION_RELATION_SWAP } from 'redux/prices/config-swap';
import { loadAllVariable } from 'redux/queries/price';
import { loadSwapData } from 'redux/queries/swap';
import { changeAnyFormFieldActionFilter } from 'utils/reduxFormSelector';
import { SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';
import { priceFormDestroyFilter } from '../../filters';
import cardsEpics from './cards/';
import { getCardByFormName, getCardById } from './utils';

export const swapFormFilter = action => action && action.meta && !action.meta.form.indexOf(PRICINIG_SWAP_FORM_OUTPUT)

export const swapTrailPricesFilter = state$ => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_SWAP && state$.value.price.trailPrice && Array.isArray(state$.value.price.trailPrice.cards);

export const trailPricesFilter = state$ => {
  return state$.value && state$.value.price && state$.value.price.trailPrice && Array.isArray(state$.value.price.trailPrice.cards);
};

export const loadSwapForm = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_FORM_LOAD),
      filter(({ payload }) => {
        if(payload === SWAP_ASIAN) {
          return state$.value.formData?.isAsianSwap;
        }
        return !!payload
      }),
      switchMap(({ payload: swapType }) => {
        return from(loadSwapData(swapType))
          .pipe(
            takeUntil(action$.pipe(filter(
              action => action.type === AUTH_LOGOUT ||
                priceFormDestroyFilter(action)
            )))
          );
      }),
      switchMap(({ swapType, pricings, currencyOptions = {}, enableCalendarSpread, asianUnderlyingContracts, asianSettlementOptions }) => {
        const actions = [
          loadedSwapData({ swapType, pricings, currencyOptions, enableCalendarSpread, asianUnderlyingContracts, asianSettlementOptions }),
        ]
        return from(actions);
      })
    );

export const swapLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_SWAP_DATA_LOADED),
      map(({ payload }) => payload && payload.pricings),
      filter(data => !!data),
      map(data => {
        const formData = priceFormValueSelector(state$.value);
        return loadAllVariable(formData, data, LIMITATION_RELATION_SWAP);
      }),
      map((limitation) => {
        return setLimitationForm({
          limitation,
          limitationRelation: LIMITATION_RELATION_SWAP
        })
      }),
    );

export const loadSwapFinish = action$ =>
  action$.pipe(
    ofType(PRICING_SWAP_DATA_LOADED),
    switchMap(() => from([
      pricingDataLoader(false),
      updateTrailPrice(null)
    ]))
  );

export const extractContractData = (pricings, cardData) => {
  const find = extractSwapContract({
    pricings: pricings,
    commodity: cardData.commodity
  });
  return find ? {
    ...cardData,
    commodityCode: find.commodityCode,
    quotedCurrency: find.quotedCurrency,
    baseCurrency: find.baseCurrency,
    termCurrency: find.termCurrency,
  } : null;
}

export const cardChangeFieldsLoaderEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('contractExpiry')),
      filter(swapFormFilter),
      filter(() => swapTrailPricesFilter(state$)),
      debounceTime(50),
      filter(({ payload }) => !!payload),
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter('quantity')),
      filter(swapFormFilter),
      filter(() => swapTrailPricesFilter(state$)),
      debounceTime(50),
      filter(({ payload, meta }) => !!payload && meta && parseFloat(payload) > 0 && parseFloat(payload) <= INPUT_NUMBER_MAX_VALUE),
    ),
  ).pipe(
    map(({ meta }) => {
      const card = getCardByFormName(state$, meta);
      return swapCardUpdate({
        ...card,
        isLoading: true //Use to disable submit button
      });
    })
  );
// End Update card actions

export const cardLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SWAP_CARD_START_DELETE),
    filter(() => swapTrailPricesFilter(state$)),
    map(({ payload }) => payload),
    switchMap(id => {
      const card = getCardById(state$.value.price.trailPrice.cards, id)
      const actions = [
        swapCardUpdate({
          ...card,
          loading: true
        })];
      return from(actions);
    })
  );


export const cardError = (card, action = 'delete') => {
  if (!card) {
    return notificationErrorSimple(`Can't delete card`);
  }
  const label = card.commodityContract ? ` ${card.commodityContract}` : '';
  const date = formatDate2UI(card.expiry);
  const text = `Can't ${action} price for${label} - ${date}`;
  return notificationErrorSimple(text);
}

export default combineEpics(
  loadSwapForm,
  loadSwapFinish,
  swapLifeEpic,
  cardLoaderEpic,
  cardChangeFieldsLoaderEpic,
  cardsEpics,
);
