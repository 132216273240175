import { DATE_FORMAT_INPUT, RECORD_EMPTY } from 'constants.js';
import isNumber from 'lodash/isNumber';
import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';
import { formatDate2UI } from '../../../PriceForm/utils';
import { getSortLabel } from '../../sorter';
import capitalize from 'lodash/capitalize';
import { pickBarrierTypeLabel } from 'pages/price/FormItem';

const AccumulatorPricingHistoryRowMore = props => {
  const {
    contractExpiry,
    quotedCurrency,
    optionExpiryDate,
    referencePrice,
    strikeLevel,
    barrierType,
    barrierLevel,
    remainderFeature,
    remainderLevel,
    isScreenXs,
    displayColumns,
    leverage,
    leverageStyle,
    accumulationStyle,
    accumulatorUnderlyings,
  } = props;
  const barrierTypeLabel = pickBarrierTypeLabel(barrierType);
  const currency = accumulatorUnderlyings && accumulatorUnderlyings.currency ? accumulatorUnderlyings.currency : quotedCurrency;
  return (
    <Fragment>
      {displayColumns && displayColumns['contractExpiry'] ?
        <div className="app-table-col size-contractExpiry">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('contractExpiry')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {formatDate2UI(contractExpiry)}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['quotedCurrency'] ?
        <div className="app-table-col size-quotedCurrency">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('quotedCurrency')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <div className="text-uppercase">{currency}</div>
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['optionExpiryDate'] ?
        <div className="app-table-col size-optionExpiryDate text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('optionExpiryDate')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            {formatDate2UI(optionExpiryDate, DATE_FORMAT_INPUT)}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['referencePrice'] ?
        <div className="app-table-col size-referencePrice text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('referencePrice')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <div><FormattedNumber value={referencePrice} minimumFractionDigits={2} maximumFractionDigits={2} /></div>
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['remainderFeature'] ?
        <div className="app-table-col size-remainderFeature text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('remainderFeature')}
            </div> : null}
          <div className="float-right float-sm-none block-right text-capitalize">
            {remainderFeature ? capitalize(remainderFeature) : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['remainderLevel'] ?
        <div className="app-table-col size-remainderLevel text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('remainderLevel')}
            </div> : null}
          <div className="float-right float-sm-none block-right text-capitalize">
            {remainderLevel ? <FormattedNumber value={remainderLevel} minimumFractionDigits={0} maximumFractionDigits={2} /> : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['strikeLevel'] ?
        <div className="app-table-col size-strikeLevel text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('strikeLevel')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <FormattedNumber value={strikeLevel} minimumFractionDigits={2} maximumFractionDigits={2} />
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['barrierType'] ?
        <div className="app-table-col size-barrierType text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('barrierType')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {barrierType ? barrierTypeLabel : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['barrierLevel'] ?
        <div className="app-table-col size-barrierLevel text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('barrierLevel')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {isNumber(barrierLevel) ? <div><FormattedNumber value={barrierLevel} /></div> : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['leverage'] ?
        <div className="app-table-col size-leverage text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('leverage')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {leverage ? leverage : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['leverageStyle'] ?
        <div className="app-table-col size-leverageStyle text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('leverageStyle')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {leverageStyle ? leverageStyle : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['accumulationStyle'] ?
        <div className="app-table-col size-accumulationStyle text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('accumulationStyle')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {accumulationStyle ? accumulationStyle : RECORD_EMPTY}
          </div>
        </div>
        : null}
    </Fragment>
  );
}

export default AccumulatorPricingHistoryRowMore;
