import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Card, CardTitle } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import { PRICING_MINI_CARD_TYPES, PRICING_MINI_CARD_TYPE_FLEX } from '../pricinigMiniForm/constants';
import PricinigMiniForm from '../pricinigMiniForm/PricinigMiniForm';
import { miniAccumulatorCardDelete } from 'redux/actions/accumulator-mini';

function PricingMiniFormsCard(props) {
  const { index, title, img, id, miniAccumulatorCardDelete } = props;
  const style = {
    backgroundImage: `url('${img}')`,
  };
  const deleteCardHandler = () => miniAccumulatorCardDelete(id);
  return (
    <Card className={`h-100 card-modern card-pricinig-mini loader-wrapper card-pricinig-mini-${id}`}>
      <div className="card-pricinig-mini-bg" style={style}>
      </div>
      <div className="card-controls text-right">
        <div onClick={deleteCardHandler} className="control p-2">
          <CloseIcon />
        </div>
      </div>
      <CardTitle>
        {title}
      </CardTitle>
      <PricinigMiniForm index={index} {...props} type={PRICING_MINI_CARD_TYPE_FLEX} />
    </Card>
  )
};

PricingMiniFormsCard.defaultProps = {
  isLoading: false,
  type: PRICING_MINI_CARD_TYPE_FLEX,
};

PricingMiniFormsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  img: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(PRICING_MINI_CARD_TYPES).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  miniAccumulatorCardDelete,
}, dispatch);

export default compose(
  connect(null, mapDispatchToProps),
)(PricingMiniFormsCard);
