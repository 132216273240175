import { STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import { BARRIER_TYPE_AMERICAN } from 'pages/price/FormItem';
import { PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/autocallable/constats';

export const PRICING_FORM_FIXED_COUPON_NOTE_INITIAL_VALUES = {
  ...PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES,
  frequency: 1,
  maturity: 6,
  barrierType: BARRIER_TYPE_AMERICAN,
  structure: STRUCTURE_FIXED_COUPON_NOTE,
};
