import 'core-js';
import 'cross-fetch/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import App from './App';
import { unregister } from './registerServiceWorker';
import startCheckingAppVersion from './appRefreshService';
import store from 'store.js';
import apolloClient from './apollo';
import './utils/validate';
import { rxjsDevTools } from './utils/debug-stream/dev-tools';

// if (process.env.NODE_ENV !== 'production') {
//   var axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
  // rxjsDevTools();
// }

if (!global.Intl) {
  global.Intl = require('intl');
}

if (window.self === window.top) {
  ReactDOM.render((
    <IntlProvider locale="en-gb" defaultLocale="en-gb">
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </Provider>
    </IntlProvider>
  ), document.getElementById('root'));

  // we have previously registered service workers - so unregister here to avoid further caching
  unregister();
  startCheckingAppVersion();
}
