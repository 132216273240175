import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import ReferenceFiguresContent from './ReferenceFiguresContent';
import PropTypes from 'prop-types';

const PrintProductChartHeader = props => {
  const {
    productImg,
    commodityContract,
    type,
  } = props;
  return (
    <Row>
      <Col className="d-flex flex-column justify-content-center section-image">
        {productImg ? <img className="underlying-img" src={productImg} alt={commodityContract} /> : null}
      </Col>
      <Col>
        <h2 className="text-uppercase">
          <FormattedMessage id="print-pdf/reference-figure"
            defaultMessage="Reference Figure" />
        </h2>
        <ReferenceFiguresContent type={type} className="section-refernce-figure" data={props} />
      </Col>
    </Row>
  );
};

PrintProductChartHeader.propTypes = {
  productImg: PropTypes.string.isRequired,
  commodityContract: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default PrintProductChartHeader;
