import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import Loader from 'components/common/loader/Loader';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { ROUTE_NOT_FOUND } from 'pages/constants';
import PageMeta from 'components/common/PageMeta';
import { FETCH_STATIC_PAGE } from './query';

const StaticPage = ({ seo }) => {
  return (
    seo ?
      <Query query={FETCH_STATIC_PAGE} variables={{ seo }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <Redirect to={ROUTE_NOT_FOUND} />
          if (data && data.staticPage && data.staticPage.content) {
            const html = { __html: data.staticPage.content };
            return (
              <Fragment>
                <PageMeta title={data.staticPage.title} />
                {data.staticPage.title ? <h1>{data.staticPage.title}</h1> : null}
                <div dangerouslySetInnerHTML={html}></div>
              </Fragment>
            );
          } else {
            return null;
          }
        }}
      </Query>
      : <Redirect to={ROUTE_NOT_FOUND} />
  )
};

StaticPage.propTypes = {
  seo: PropTypes.string.isRequired
}

export default StaticPage;
