import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import { normalizeId } from 'pages/orders/utils';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import DescriptionIcon from '@material-ui/icons/Description';
import DescriptionFormatted from 'pages/price/output/accumulator/structureDescription/DescriptionFormatted';


const ProductDescriptionModal = ({ title, productDescription = '' }) => (
  <div className="pricing-mini-trade-form">
    <div className="modal-header">
      <div className="modal-title">
        {title}
      </div>
    </div>
    <div className="h5 sub-title">
      <FormattedMessage
        id="pricing-mini-trade.title"
        defaultMessage="Product Description"
      />
    </div>
    <DescriptionFormatted description={productDescription} />
  </div>
);

export const ProductDescriptionBtn = ({ cardId, title, productDescription }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);
  const onCloseModal = () => setOpen(false);

  if(!productDescription) return null;
  return (
    <div className="factsheet-block">
      <Modal
        btnType="primary"
        btnClass="btn-icon"
        isOpen={isOpen}
        onToggle={onCloseModal}
        onClose={onCloseModal}
        component={<ProductDescriptionModal title={title} productDescription={productDescription} />} />
      <Button id={`pricing-mini-product-description-${normalizeId(cardId)}`} color="link" size="sm" className="btn-icon" onClick={toggle}>
        <DescriptionIcon />
      </Button>
      <UncontrolledTooltip placement="top" target={`pricing-mini-product-description-${normalizeId(cardId)}`}>
        <FormattedMessage id="price.product-description"
          defaultMessage="Product Description" />
      </UncontrolledTooltip>
    </div>
  )
}
