import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { apolloErrors } from 'apollo.js';
import { compose, bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm, reset, SubmissionError, change } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import round from 'lodash/round';
import InlineInput from 'components/form/InlineInput';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT, RECORD_EMPTY } from 'constants.js';
import { filterTimeInForceOptions } from 'pages/price/PriceForm/utils';
import {
  TIME_IN_FORCE_OPTIONS, ORDER_TYPES_DEFAULT, TYPE_MARKET,
  PRICINIG_ORDER_TYPES, MIN_PRICE_VALIDATION, TYPE_LIMIT, TIME_IN_FORCE_GTC
} from '../constants.js';
import TradeLabel from '../TradeLabel';
import { ASIAN_SWAP_ORDERS_FORM_INITIAL_VALUES } from './constants';
import { CREATE_ASIAN_SWAP_ORDER_MUTATION } from './query';
import { validate } from './validate.js';
import OrderAccount from 'components/orders/accounts/AccountsTrade';
import { injectIntl } from 'react-intl';
import OrderTypeField from 'components/trade/components/OrderTypeField';
import PriceField from 'components/trade/fx/PriceField';

const placeOrderArguments = (data, props) => {
  const {
    id: cardId,
    structure,
    commodityCode,
    actualPrice,
    avgPrice,
    legsPrices
  } = props;
  const {
    timeInForce = TIME_IN_FORCE_GTC,
    orderType = ORDER_TYPES_DEFAULT.value,
    account,
    price
  } = data;

  return {
    mutation: CREATE_ASIAN_SWAP_ORDER_MUTATION,
    variables: {
      cardId,
      structure,
      commodityCode,
      timeInForce,
      orderType,
      price: orderType === TYPE_LIMIT ? price : actualPrice,
      slackIceClientsId: account,
      avgPrice,
      legsPrices: legsPrices.map(({ month, cmPrice, notional, quoteId, fxRate, fxSpreadRate }) => ({ month, cmPrice, notional, quoteId, fxRate, fxSpreadRate })),
    },
  };
}


class Trade extends PureComponent {

  constructor(props) {
    super(props);
    const { price, form } = props;
    this.state = {
      submitted: false,
      price,
      formName: form,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { price } = props;
    const { price: statePrice } = state;
    return price !== statePrice ? { price } : null;
  }

  submit = async data => {
    const {
      client,
    } = this.props;
    try {
      const { variables, mutation } = placeOrderArguments(data, this.props)

      let response = await client.mutate({
        mutation,
        variables
      }).then((resp) => {
        if (resp.data.createAsianSwapOrder && resp.data.createAsianSwapOrder.id) {
          this.runResetFormTimeout();
          this.setState({
            submitted: true
          });
        }
        return resp.data;
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  }

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(ASIAN_SWAP_ORDERS_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      handleSubmit,
      submitting,
      direction,
      commodityContract,
      error,
      firstName,
      lastName,
      formData,
      isCombinedCard,
      structure,
      intl: { formatMessage },
      actualPrice,
      priceDigitsConvention
    } = this.props;
    let { invalid } = this.props;
    const { submitted, formName } = this.state;
    const { quantity, price, orderType } = formData;

    const showFormLabel = quantity && ((price && parseFloat(price) > 0) || orderType === TYPE_MARKET) && (parseFloat(quantity) > 0);
    const { label: orderTypeLable } = PRICINIG_ORDER_TYPES.find(item => item.value === orderType) || ORDER_TYPES_DEFAULT;

    if (isCombinedCard && (!actualPrice || actualPrice < MIN_PRICE_VALIDATION)) {
      invalid = true;
    }
    const overriddenOrderTypes = PRICINIG_ORDER_TYPES.map(item => ({ ...item, disabled: item.value === TYPE_MARKET || item.value === TYPE_LIMIT ? false : true }))

    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage('Order has been created successfully.')} />
        :
        <Fragment>
          <h1>
            {direction === 'buyer' ? 'Buy' : 'Sell'}&nbsp;-&nbsp;{commodityContract}
          </h1>
          <MainErrorMessage error={error} />
          <Form id={`form-card-swap`} onSubmit={handleSubmit(this.submit)} noValidate>
            <Row className="row-size-xs">
              <OrderTypeField structure={structure} isCombinedCard={isCombinedCard} overriddenOrderTypes={overriddenOrderTypes} sm={4} />
              <Col xs={12} sm={4}>
                <Field
                  name="timeInForce"
                  className="timeInForce"
                  label={<FormattedMessage id="trade/timeInForce.label"
                    defaultMessage="Time In Force" />}
                  justValue={true}
                  component={InlineSelectInput}
                  options={filterTimeInForceOptions(TIME_IN_FORCE_OPTIONS, orderType, true)}
                  clearable={false}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                <Field
                  name="quantity"
                  type="number"
                  placeHolder={formatMessage({
                    id: "trade/total-size.label",
                    defaultMessage: "Total Size",
                  })}
                  label={<FormattedMessage id="trade/total-size.label"
                    defaultMessage="Total Size" />}
                  step="1"
                  disabled={true}
                  component={InlineInput}
                />
              </Col>
              <Col xs={12} sm={4}>
                <PriceField orderType={orderType} actualPrice={actualPrice} formatMessage={formatMessage} maximumFractionDigits={priceDigitsConvention} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <OrderAccount
                  direction={direction}
                  formName={formName}
                />
              </Col>
            </Row>

            <div className="mb-3">
              {showFormLabel ?
                <TradeLabel  {...this.props} price={price} orderType={orderType} quantity={quantity} />
                : RECORD_EMPTY}
            </div>

            <div className="mb-3">
              <FormattedMessage id="common/account"
                defaultMessage="User" />: {firstName} {lastName}
            </div>

            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.onCloseModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="submit"
                className={"text-uppercase" + (direction === 'buyer' ? ' btn-buy' : ' btn-sell')}
                onClick={this.trade}
                disabled={invalid || submitting}>
                <FormattedMessage id="orders.send-limit"
                  defaultMessage={`Send ${orderTypeLable} order`} />
              </Button>
            </div>
          </Form>
        </Fragment>
    );
  }
}

Trade.defaultProps = {
  quantity: 1,
  formData: {}
};

Trade.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.number
};

export const ASIAN_SWAP_ORDERS_FORM = '@PRICINIG/ASIAN_SWAP/FORM_TRADE';

export const selectorFormValues = getFormValues(ASIAN_SWAP_ORDERS_FORM);

const getPrice = (id, trailPrice = [], priceDigitsConvention) => {
  if (!id) return { price: null };
  const { price = null, avgPrice = null, legsPrices = [] } = trailPrice.find(({ id: cardId }) => cardId === id)

  return {
    actualPrice: round(price, priceDigitsConvention) || null,
    avgPrice: round(avgPrice, priceDigitsConvention) || null,
    legsPrices
  }
}

const mapStateToProps = (state, ownProps) => {

  const { id, price: selectedPrice, notional, termCurrency, baseCurrency } = ownProps;
  const isCombinedCard = termCurrency !== baseCurrency;
  const formData = selectorFormValues(state);
  const price = round(selectedPrice, 4) || null;


  const trailPrice = state?.price?.trailPrice?.cards || [];
  const { actualPrice, avgPrice, legsPrices } = getPrice(id, trailPrice, 4)
  return {
    formData,
    isCombinedCard,
    actualPrice,
    avgPrice,
    legsPrices,
    priceDigitsConvention: 4,
    initialValues: {
      quantity: notional,
      price,
      ...ASIAN_SWAP_ORDERS_FORM_INITIAL_VALUES,
    }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  change
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: ASIAN_SWAP_ORDERS_FORM,
    enableReinitialize: true,
    validate,
  }),
  withApollo,
  injectIntl,
)(Trade);
