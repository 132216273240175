import isNaN from 'lodash/isNaN';
import React, { memo, useState } from 'react';
import StyledSlider from './StyledSlider';

const Slider = props => {
  const {
    onChange,
    name,
    value,
    disabled,
    min,
    max,
  } = props;

  const parseValue = (min = null) => !isNaN(parseFloat(value)) ? parseFloat(value) : min;
  const valueNumber = parseValue();
  const [sliderValue] = useState(valueNumber);

  return (
    <StyledSlider
      aria-label="Range slider"
      name={name}
      defaultValue={sliderValue}
      value={parseValue(0)}
      valueLabelDisplay={disabled ? 'off' : 'on'}
      onChange={onChange}
      disabled={disabled}
      max={max}
      min={min}
    />
  );
}

export default memo(Slider);
