import {
  NOTIFICATION_NEW,
  NOTIFICATION_READ,
  NOTIFICATION_REPRIСING,
  NOTIFICATION_EDIT,
  NOTIFICATION_REPRICING_RESET,
  NOTIFICATIONS_UPDATE,
  NOTIFICATIONS_GET,
  NOTIFICATIONS_COUNTER,
  NOTIFICATION_UPDATE,
  NOTIFICATION_TOGGLE,
  SHOW_ONLY_READ,
  NOTIFICATION_SHOW_ONLY_READ_TOGGLE,
  NOTIFICATION_READ_ALL,
} from 'redux/actions/notifications';

export type ICustomRequestsReducerState = {
  newItem: Object | null,
  lastRead: Object | null,
  rePrice: Object | null,
  editable: Object | null,
  list: Array<any> | null,
  loading: Boolean | false,
  counter: Number;
  isOpen: Boolean | false,
  showOnlyRead: Boolean | false,
}

const NOTIFICATIONS_INIT_STATE: ICustomRequestsReducerState = {
  newItem: null,
  lastRead: null,
  rePrice: null,
  editable: null,
  list: [],
  loading: true,
  counter: 0,
  isOpen: false,
  showOnlyRead: localStorage.getItem(SHOW_ONLY_READ) ? localStorage.getItem(SHOW_ONLY_READ) === 'true' : true,
};

const updateList = (list, newItem) => {
  const findIndex = newItem && newItem.id ? list.findIndex(({ id }) => id === newItem.id) : null;
  if (findIndex >= 0) {
    const newList = [...list];
    newList[findIndex] = {
      ...newList[findIndex],
      ...newItem
    };
    return newList;
  }
  return [
    newItem,
    ...list
  ];
};

export default function customRequestsReducer(state: ICustomRequestsReducerState = NOTIFICATIONS_INIT_STATE, action: Action): ICustomRequestsReducerState {
  switch (action.type) {
    case NOTIFICATION_NEW:
      const list = updateList(state.list, action.payload);
      return { ...state, list };

    case NOTIFICATION_READ:
      const lastRead = {
        id: action.payload.id,
        isRead: true
      };
      return {
        ...state,
        list: updateList(state.list, lastRead)
      };

    case NOTIFICATION_TOGGLE:
      return { ...state, isOpen: action.payload }
    case NOTIFICATION_UPDATE:
      return { ...state, list: updateList(state.list, action.payload) }
    case NOTIFICATION_REPRIСING:
      return { ...state, rePrice: action.payload };

    case NOTIFICATION_REPRICING_RESET:
      return { ...state, rePrice: null };

    case NOTIFICATIONS_GET:
      return { ...state, loading: true }

    case NOTIFICATION_EDIT:
      return { ...state, editable: action.payload };

    case NOTIFICATIONS_UPDATE:
      return { ...state, list: action.payload, loading: false }

    case NOTIFICATIONS_COUNTER:
      return { ...state, counter: parseInt(action.payload, 10) };

    case NOTIFICATION_SHOW_ONLY_READ_TOGGLE:
      localStorage.setItem(SHOW_ONLY_READ, action.payload);
      return { ...state, showOnlyRead: action.payload, loading: true }

    case NOTIFICATION_READ_ALL:
      return { ...state, loading: true }

    default:
      return state;
  }
}
