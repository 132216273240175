import apolloClient from 'apollo.js';
import gql from 'graphql-tag';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { mapNodes } from 'utils/';
import store from 'store.js';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import { STRUCTURE_SWAP, DEFAULT_LIMIT_FOR_LOAD_CARDS } from 'constants.js';

const ASIAN_SWAP_CARDS_LIST_QUERY = gql`query asianSwapListQuery (
  $cursor: String
  $limit: Int
) {
AsianSwapConnection (
  after: $cursor
  first: $limit
) {
  edges {
    node{
      id
      structure
      direction
      commodityContract
      commodityCode
      underlyingContracts
      firstMonth
      lastMonth
      termCurrency
      baseCurrency
      status
      fxCode
      unit
      unitType
      disableCompo
      fixing
      settlement
      maxMonthes
      notional
      legs {
        notional
        contractExpiry
      }
      bloombergTicker
      balmo
    }
    cursor
  }
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
  }
}
}`;

export const loadAsianSwapCards = async (cursor = null, limit) => {
  try {
    let resp = await apolloClient.query({
      query: ASIAN_SWAP_CARDS_LIST_QUERY,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        cursor,
        limit: limit || DEFAULT_LIMIT_FOR_LOAD_CARDS
      }
    })
      .then((res) => {
        const cards = mapNodes(res.data.AsianSwapConnection);
        const pageInfo = res.data.AsianSwapConnection.pageInfo;
        return { cards, pageInfo };
      });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    console.log(e);
  }
}

const ASIAN_SWAP_DELETE_CARD_MUTATION = gql`mutation deleteAsianSwapCardMutation(
  $id: String!
) {
  deleteAsianSwapCard (
    id: $id
  )
}`;

export const deleteAsianSwapCardMutation = id => {
  return apolloClient.mutate({
    mutation: ASIAN_SWAP_DELETE_CARD_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

const ASIAN_SWAP_CREATE_CARD_MUTATION = gql`mutation createAsianSwapCardMutation(
  $direction: String!
  $commodityCode: String!
  $commodityContract: String!
  $underlyingContracts: String!
  $settlement: String!
  $firstMonth: DateString!
  $lastMonth: DateString!
  $baseCurrency: String!
  $fxCode: String
  $legs: [AsianSwapCardLegArgs]
  $notional: Float!
  $balmo: Boolean
) {
  createAsianSwapCard (
    direction: $direction
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    underlyingContracts: $underlyingContracts
    firstMonth: $firstMonth
    lastMonth: $lastMonth
    baseCurrency: $baseCurrency
    fxCode: $fxCode
    legs: $legs
    notional: $notional
    settlement: $settlement
    balmo: $balmo
  ) {
    id
    structure
    termCurrency
    baseCurrency
    status
    disableCompo
    unit
    unitType
    fixing
    settlement
    maxMonthes
    bloombergTicker
    balmo
  }
}`;

export const createAsianSwapCardMutation = ({
    direction,
    commodityCode,
    commodityContract,
    underlyingContracts,
    firstMonth,
    lastMonth,
    baseCurrency,
    fxCode,
    legs,
    notional,
    settlement,
    balmo = true
  }) => {
  return apolloClient.mutate({
    mutation: ASIAN_SWAP_CREATE_CARD_MUTATION,
    variables: {
      direction,
      commodityCode,
      commodityContract,
      underlyingContracts,
      firstMonth,
      lastMonth,
      baseCurrency,
      fxCode,
      legs,
      notional,
      settlement,
      balmo
    },
  })
    .then(response => response.data.createAsianSwapCard);
}

const ASIAN_SWAP_UPDATE_CARD_MUTATION = gql`mutation updateAsianSwapCardMutation(
  $id: String!
  $commodityCode: String!
  $baseCurrency: String!
  $termCurrency: String
  $fxCode: String
  $unit: String!
  $unitType: String!
  $fixing: String!
  $settlement: String!
  $underlyingContracts: String!
  $firstMonth: DateString!
  $lastMonth: DateString!
  $notional: Float!
  $legs: [AsianSwapCardLegArgs]
  $balmo: Boolean
) {
  updateAsianSwapCard (
    id: $id
    commodityCode: $commodityCode
    baseCurrency: $baseCurrency
    termCurrency: $termCurrency
    fxCode: $fxCode
    unit: $unit
    unitType: $unitType
    fixing: $fixing
    settlement: $settlement
    underlyingContracts: $underlyingContracts
    firstMonth: $firstMonth
    lastMonth: $lastMonth
    notional: $notional
    legs: $legs
    balmo: $balmo
  ) {
    id
    status
    disableCompo
    bloombergTicker
  }
}`;

export const updateAsianSwapCardMutation = ({
    id,
    commodityCode,
    baseCurrency,
    termCurrency,
    fxCode,
    unit,
    unitType,
    fixing,
    settlement,
    underlyingContracts,
    month,
    lastMonth,
    notional,
    legs,
    balmo = true
  }) => {
  const variables = {
    id,
    commodityCode,
    baseCurrency,
    termCurrency,
    fxCode,
    unit,
    unitType,
    fixing,
    settlement,
    underlyingContracts,
    firstMonth: month,
    lastMonth,
    notional,
    legs,
    balmo
  };
  return apolloClient.mutate({
    mutation: ASIAN_SWAP_UPDATE_CARD_MUTATION,
    variables,
  })
    .then(response => response.data.updateAsianSwapCard);
}


const ASIAN_SWAP_REQUEST_PRICE_MUTATION = gql`mutation asianSwapPriceRequestMutation(
  $id: String!
) {
  asianSwapPriceRequest (
    id: $id
  ) {
    id
    status,
  }
}`;

export const requestPriceMutation = ({
  id,
}) => {
  return apolloClient.mutate({
    mutation: ASIAN_SWAP_REQUEST_PRICE_MUTATION,
    variables: {
      id,
    },
  }).then(response => response.data.asianSwapPriceRequest);
}
