import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import { combineEpics, ofType } from 'redux-observable';
import {
  structuredProductsFormOptionsSet,
  STRUCTURED_PRODUCTS_FORM_DATA_LOADED,
  STRUCTURED_PRODUCTS_FORM_RESTORE_CURRENCY_LIST
} from 'redux/actions/structured-products';
import { merge, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { changeActionFilter } from 'utils/reduxFormSelector';
import { pricingStructuredFormValuesFilter } from '../changes';
import { availableFirstObservationIn } from './firstObservationIn';
import { createFloatingCurveOptions } from './floatingCurveOptions';
import { availableOptionsFrequency, OPTION_INFINE_VALUE } from './handleOptions';
import { change, untouch } from 'redux-form';
import { AUTOCALL_VALUE } from 'constants.js';

export const changeMaturityEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'maturity')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocall')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_DATA_LOADED)
    ),
  )
    .pipe(
      debounceTime(200),
      filter(() => pricingStructuredFormValuesFilter(state$.value)),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      switchMap((formData) => {
        if (formData.frequency === null || formData.frequency === undefined) {
          formData.frequency = state$.value.structuredProducts.formOptions?.frequencyValue
        }
        const options = availableOptionsFrequency(formData, state$.value.structuredProducts.formOptionsDefault.frequency);
        return from([
          ...((formData.autocall === AUTOCALL_VALUE.YES || formData.autocall === AUTOCALL_VALUE.ISSUER_CALLABLE) && formData.frequency === OPTION_INFINE_VALUE ?
            [
              change(PRICING_STRUCTURED_FORM, 'frequency', null),
              untouch(PRICING_STRUCTURED_FORM, 'frequency'),
            ] : []),
          structuredProductsFormOptionsSet({ frequency: options })
        ])
      }),
    );

export const changeAvailableFirstObservationIn = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'maturity')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'frequency')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'structure')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
  )
    .pipe(
      debounceTime(200),
      filter(() => pricingStructuredFormValuesFilter(state$.value)),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      map((formData) => {
        formData.firstObservationIn = formData.firstObservationIn || state$.value.structuredProducts.formOptions?.firstObservationInValue;
        if (formData.frequency === null || formData.frequency === undefined) {
          formData.frequency = state$.value.structuredProducts.formOptions?.frequencyValue
        }
        return availableFirstObservationIn(formData)
      }),
      map(options => structuredProductsFormOptionsSet({
        firstObservationIn: options
      })),
    );

export const changeCurrencyEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'currency')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'legalShape')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
  )
    .pipe(
      filter(() => pricingStructuredFormValuesFilter(state$.value)),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      switchMap((formData) => {
        const currentCurrency = formData?.currency;
        const currency = state$?.value?.structuredProducts?.formOptions?.currency?.find(i => i.value === currentCurrency);
        const options = createFloatingCurveOptions(currency);
        return from([
          structuredProductsFormOptionsSet({ floatingCurve: options }),
          ...(options.length > 1 ? [
            change(PRICING_STRUCTURED_FORM, 'floatingCurve', options[0].value),
            untouch(PRICING_STRUCTURED_FORM, 'floatingCurve')
          ] : [])
        ])
      }),
    );

const MAREX_GROUP_CURRENCY_LIST = [
  'AUD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HUF',
  'ILS',
  'JPY',
  'PLN',
  'USD',
  'ZAR'
]

export const changeCurrencyListEpic = (action$, state$) =>
  merge(
    action$.pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_RESTORE_CURRENCY_LIST),
      filter(({ payload }) => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'issuer')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'structure')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    )
  )
    .pipe(
      debounceTime(1100), // debounce has to be greater than restore
      filter(() => pricingStructuredFormValuesFilter(state$.value)),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      switchMap((formData) => {
        const issuer = formData?.issuer;
        const isMarexGroup = issuer === 'Marex Group PLC';
        const options =  state$?.value?.structuredProducts?.formOptionsDefault?.currency || []
        const currencyOptions = isMarexGroup ? options?.filter((c) => MAREX_GROUP_CURRENCY_LIST.includes(c.value)) : options;

        const selectedCurrency = formData?.currency;

        return from([
          structuredProductsFormOptionsSet({ currency: currencyOptions }),
          ...(currencyOptions.length > 1 ? [
            change(PRICING_STRUCTURED_FORM, 'currency', currencyOptions.find(ccy => ccy.value === selectedCurrency) ? selectedCurrency : null),
            untouch(PRICING_STRUCTURED_FORM, 'currency')
          ] : [])
        ])
      }),
    );

export default combineEpics(
  changeMaturityEpic,
  changeAvailableFirstObservationIn,
  changeCurrencyEpic,
  changeCurrencyListEpic
);
