import { classNames } from 'pages/price/PriceForm/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, Label } from 'reactstrap';

const InlineCheckbox = props => {
  const { name, labelCheckbox, meta, placeHolder, onChangeFilter, skip, showIcon, ...rest } = props;
  const className = classNames({
    'hide-icon': !showIcon,
    'custom-control custom-checkbox': true,
  });
  return (
    <div className={className}>
      <Input id={name} className="custom-control-input" name={name} {...rest} aria-label={placeHolder} />
      <Label htmlFor={name} className="custom-control-label" >
        {labelCheckbox}
      </Label>
    </div>
  );
};

InlineCheckbox.defaultProps = {
  showIcon: true,
};

InlineCheckbox.propTypes = {
  showIcon: PropTypes.bool.isRequired,
};

export default InlineCheckbox;
