import React, { Component, Fragment } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CardBody } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { Form, reduxForm, getFormValues } from 'redux-form';
import MainErrorMessage from 'components/form/MainErrorMessage';
import { isThemeSelector } from 'components/theme/utils';
import { TRADE_BUTTON_TIMEOUT_SECONDS } from 'constants.js';
import { miniAccumulatorCardGetReferencePrice, stopCalculatingReferencePrice } from 'redux/actions/accumulator-mini';
import { submitForm } from 'redux/actions/price';
import { accumulatorMiniCardsSelector } from '../pricingMiniFormsList/PricingMiniFormsListWrapper';
import { accumulatorMiniLimitationSelector, isGreeksDisplaySelector } from './accumulatorMiniLimitationSelector.js';
import { initialValues, REFERENCE_TYPES_OPTIONS, REFERENCE_TYPE_LIVE, REFERENCE_TYPE_LIMIT } from './constants';
import PricinigMiniFormChooser from './types/PricinigMiniFormChooser';
import { pricingMiniFormValueSelector } from './utils';
import validate from './validate.js';

class PricinigMiniForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: props.fields,
      isEnabledTrade: true,
      trailId: null,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.fields, state.fields)) return {
      fields: props.fields,
      trailId: props.trailId,
      isEnabledTrade: props.isTrade
    };

    if (props.trailId !== state.trailId) return {
      trailId: props.trailId,
      isEnabledTrade: props.isTrade
    };

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps.limitation, this.props.limitation) || !isEqual(nextProps.fields, this.props.fields) || !isEqual(this.props, nextProps)
      || this.state.isEnabledTrade !== nextState.isEnabledTrade;
  }

  submit = data => {
    const { id, onSubmit } = this.props;
    onSubmit({ ...data, id });
  }

  getReferencePrice = () => {
    const { id, miniAccumulatorCardGetReferencePrice } = this.props;
    miniAccumulatorCardGetReferencePrice({ id });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isTrade && prevState.trailId !== this.state.trailId) {
      if (this.timeout) this.clearModalTimeout();
      this.startTimeout();
    }
  }

  startTimeout = () => {
    const { countDown = TRADE_BUTTON_TIMEOUT_SECONDS } = this.props;
    const countDownMS = countDown * 1000;
    this.timeout = setTimeout(() => {
      this.setState({ isEnabledTrade: false });
    }, countDownMS);
  }

  clearModalTimeout = () => {
    clearTimeout(this.timeout);
  }

  componentWillUnmount() {
    this.clearModalTimeout();
  }

  onChangeReferenceType = (referenceType) => {
    if (referenceType === REFERENCE_TYPE_LIMIT) this.getReferencePrice();
  }

  render() {

    const {
      id,
      handleSubmit,
      invalid,
      isLoading = false,
      contractExpiries = [],
      structureExpiredAt,
      selectedUnderlying,
      referenceTypes = [],
      form,
      isTrade,
      errorText,
      isCardResult,
      referenceType,
      title,
      countDown,
      type,
      isFactSheet,
      isCalculating,
      stopCalculatingReferencePrice,
    } = this.props;
    const { fields = [], isEnabledTrade } = this.state;
    const isDisabled = false; //!expiryOptions || !expiryOptions.length || !(isNumber(price));
    const structureExpiredAtMax = structureExpiredAt?.[0] ? structureExpiredAt[0].value : null;
    const bloombergTicker = selectedUnderlying?.bloombergTicker ? selectedUnderlying.bloombergTicker : null;
    const isDisabledTradeBtn = !isCardResult || !isTrade || isLoading || isDisabled || invalid || !isEnabledTrade;
    const labelBtnPrice = 'Solve';
    const labelBtnLoading = `Solving...`;
    const isDisabledReference = referenceType === REFERENCE_TYPE_LIVE;

    const onStopReferencePrice = (cardId) => stopCalculatingReferencePrice(cardId);

    return (
      <Fragment>
        <CardBody>
          {errorText ? <MainErrorMessage error={[{ message: errorText }]} /> : null}
          <Form id={`form-card-mini-${id}`}
            onSubmit={handleSubmit(this.submit)}
            className="form-card-mini d-flex align-items-start flex-column h-100"
            noValidate>

            <PricinigMiniFormChooser
              {...this.props}
              contractExpiries={contractExpiries}
              referenceTypes={referenceTypes}
              title={title}
              fields={fields}
              bloombergTicker={bloombergTicker}
              structureExpiredAtMax={structureExpiredAtMax}
              isDisabledTradeBtn={isDisabledTradeBtn}
              labelBtnPrice={labelBtnPrice}
              labelBtnLoading={labelBtnLoading}
              isDisabledReference={isDisabledReference}
              isFactSheet={isFactSheet}
              form={form}
              countDown={countDown}
              onChangeReferenceType={this.onChangeReferenceType}
              getReferencePrice={this.getReferencePrice}
              type={type}
              isCalculating={isCalculating}
              onStopReferencePrice={onStopReferencePrice}
            />
          </Form>
        </CardBody>
        {
          isLoading
            ? <div className="loader-overlay overlay-0"></div>
            : null
        }
      </Fragment >
    );
  }
};

PricinigMiniForm.defaultProps = {
  contractExpiries: [],
  referenceTypes: REFERENCE_TYPES_OPTIONS,
  isLoading: false,
  selectedUnderlying: null,
  fields: [],
  isTrade: false,
  isFactSheet: true,
};

PricinigMiniForm.propTypes = {
  contractExpiries: PropTypes.array.isRequired,
  referenceTypes: PropTypes.array.isRequired,
  structureExpiredAt: PropTypes.array.isRequired,
  miniAccumulatorCardGetReferencePrice: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  selectedUnderlying: PropTypes.object,
  trailId: PropTypes.string,
  tradeId: PropTypes.string,
  isTrade: PropTypes.bool.isRequired,
  isFactSheet: PropTypes.bool.isRequired,
};

export const miniFormCardName = id => `${PRICINIG_MINI_FORM}_${id}`;

export const PRICINIG_MINI_FORM = '@PRICINIG/FORM_MINI';


const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const form = miniFormCardName(id);

  const selectorFormValues = getFormValues(form);
  const formData = selectorFormValues(state);

  const greeksDisplay = isGreeksDisplaySelector(state);

  // @TODO: Simplify selectors
  const cards = accumulatorMiniCardsSelector(state);
  const props = cards?.items ? {
    ...cards.items.find(({ id: cardId }) => cardId === id),
    bankHolidays: cards.bankHolidays,
  } : null;

  const limitations = accumulatorMiniLimitationSelector(props);
  const referenceType = pricingMiniFormValueSelector(form)(state, 'referenceType');
  const { isTheme = false } = isThemeSelector(state);

  return {
    form,
    referenceType,
    ...limitations,
    ...props,
    isFactSheet: !isTheme,
    formData,
    greeksDisplay
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onSubmit: submitForm,
  miniAccumulatorCardGetReferencePrice,
  stopCalculatingReferencePrice,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ enableReinitialize: true, validate, initialValues }),
)(PricinigMiniForm);
