import React from 'react';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import TourTooltip from './TourTooltip';
import PropTypes from 'prop-types';

const TourTooltipFormFields = props => {
  const { isValid, ...rest } = props;
  return <TourTooltip disabledNext={!isValid} {...rest} />
}

TourTooltipFormFields.propTypes = {
  formName: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { formName } = props;
  return {
    isValid: formName ? isValid(formName)(state) : false
  }
}

export default connect(
  mapStateToProps
)(TourTooltipFormFields);
