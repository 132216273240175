import React, { memo } from 'react';
import { DropdownItem } from 'reactstrap';

function TableFilterDropdownListItem(props) {
  const { name, onSelect, isVisible, placeHolder } = props;

  const onChange = () => {
    onSelect(name)
  }

  return (
    <DropdownItem
      key={name}
      onClick={onChange}
      className={isVisible ? 'active' : ''}>
      {placeHolder}
    </DropdownItem>
  )
}

export default memo(TableFilterDropdownListItem);
