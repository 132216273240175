import { actions } from 'react-redux-alerts';

export const PRICING_FAIL_LOAD_PRODUCTS = 'PRICING_FAIL_LOAD_PRODUCTS';
export const PRICING_FAIL_CALCULATE_FIELD = 'PRICING_FAIL_CALCULATE_FIELD';
export const TRAIL_SELECT_FAIL = 'TRAIL_SELECT_FAIL';
export const NOTIFICATION_IS_EXPIRED = 'NOTIFICATION_IS_EXPIRED';
export const PRICING_FAIL_FORM_LOAD_ALL_DATA = 'PRICING_FAIL_FORM_LOAD_ALL_DATA';
export const SIMPLE_ERROR = '@ERROR/SIMPLE';
export const EXPIRATION_PASSWORD = 'EXPIRATION_PASSWORD';

export const productDoesNotExist = (structure, underline) =>
  actions.createAlert(PRICING_FAIL_LOAD_PRODUCTS, { structure, underline });

export const cantCalculatePriceFieldResponse = (fieldName) => actions.createAlert(PRICING_FAIL_CALCULATE_FIELD, { fieldName });

export const trailSelectFail = (trail) => actions.createAlert(TRAIL_SELECT_FAIL, { trail });

export const notificationExpired = () => actions.createAlert(NOTIFICATION_IS_EXPIRED);

export const notificationPriceFailLoadAllData = structure => actions.createAlert(PRICING_FAIL_FORM_LOAD_ALL_DATA, { structure });

export const expirationPasswordWarning = (text) => actions.createAlert(EXPIRATION_PASSWORD, text);

export const notificationErrorSimple = (text) => actions.createAlert(SIMPLE_ERROR, text);
