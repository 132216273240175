import { combineEpics, ofType } from 'redux-observable';
import { structuredProductsReset, STRUCTURED_PRODUCTS_FORM_DESTROY } from "redux/actions/structured-products";
import { mapTo } from 'rxjs/operators';

export const destroyAutocallableForm = action$ =>
  action$
    .pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_DESTROY),
      mapTo(structuredProductsReset())
    );

export default combineEpics(
  destroyAutocallableForm,
);
