import { combineEpics, ofType } from 'redux-observable';
import { PRICING_MINI_ACCUMULATOR_CARDS_LOAD, PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY } from 'redux/actions/accumulator-mini';
import { switchMap, takeUntil } from 'rxjs/operators';
import changes from './changes';
import limitations from './limitations';
import lock from './lock';
import pricingsMiniForms from './pricings-mini-forms';
import getReferencePriceEpic from './reference-price';
import submit from './submit';

export const pricingMiniPageLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_MINI_ACCUMULATOR_CARDS_LOAD),
      switchMap(() => {
        return combineEpics(
          pricingsMiniForms,
          lock,
          limitations,
          getReferencePriceEpic,
          submit,
          changes,
        )(action$, state$).pipe(
          takeUntil(action$.pipe(ofType(PRICING_MINI_ACCUMULATOR_MINI_PAGE_DESTROY)))
        )
      })
    );


export default pricingMiniPageLifeEpic;
