import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = props => {
  const {
    children,
    className,
    element,
  } = props;

  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  const portalContainer = () => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    }
  };

  useEffect(portalContainer, []);

  return createPortal(children, container);
}

Portal.defaultProps = {
  className: 'ms-portal',
  element: 'div',
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  element: PropTypes.oneOf(['div', 'span', 'p']).isRequired,
};

export default Portal;
