import { JL } from 'jsnlog';
import { v4 as uuid } from 'uuid';
import Bowser from "bowser";
import fetch from 'node-fetch';
import { USER_ID, URL_HEARTBEAT, URL_LOGS } from './constants';
import { posibleAddapters, getTimeStamp, _getLevel, _getLevelTitle } from './helpers';
import { STORAGE_NAME } from 'auth/index';

import { Subject, from } from 'rxjs';
import { filter, take, bufferTime, switchMap } from 'rxjs/operators';

const observable = new Subject()
  .pipe(
    bufferTime(1500),
    filter((data) => !!data.length),
    switchMap((data) => from(data).pipe(
      take(2),
    ))
  )

observable.subscribe(({ level, msg, data, metadata, e }) => {
  JL().log(level, {
    level: _getLevelTitle(level),
    time: getTimeStamp(),
    msg,
    data,
    metadata
  }, e);
});

export default class Logger {
  constructor() {
    this.sessionId = window.sessionStorage.getItem('sessionid') || uuid();
    this._heartbeat = false;
    this.setIdentity();
    this._setAppenders();
  }

  setIdentity() {
    this.identity = localStorage.getItem(USER_ID) ? `AUTH|${localStorage.getItem(USER_ID)}` : null;
    // if(!this._heartbeat && this.identity) {
    //   this._heartbeat = true;
    //   this.heartbeat();
    // } else if(!this.identity){
    //   this._heartbeat = false;
    // }
  }

  heartbeat() {
    fetch(`${URL_HEARTBEAT}?s=${this.sessionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem(STORAGE_NAME)}`
      },
    });

    if (this._heartbeat) {
      // setTimeout(() => this.heartbeat(), 5 * 60 * 1000);
      setTimeout(() => this.heartbeat(), 5000);
    }
  }

  _getOptions(adapter) {
    let options = {
      level: _getLevel()
    };

    if (adapter === 'splunk') {
      options = {
        ...options,
        url: URL_LOGS,
        batchSize: 1,
        maxBatchSize: 5,
        batchTimeout: 1000
      }
    }
    return options;
  }

  _setAppenders() {
    const LOGGERS = window.LOGGERS || '';
    const appenders = LOGGERS.split(',')
      .filter(adapter => posibleAddapters[adapter])
      .map(adapter => posibleAddapters[adapter].setOptions(this._getOptions(adapter)));
    JL().setOptions({ appenders });
  }

  trace(msg, data) {
    this.formatLogObject(msg, data, _getLevel('trace'));
  }

  debug(msg, data) {
    this.formatLogObject(msg, data, _getLevel('debug'));
  }

  info(msg, data) {
    this.formatLogObject(msg, data, _getLevel('info'));
  }

  warn(msg, data) {
    this.formatLogObject(msg, data, _getLevel('warn'));
  }

  error(msg, data) {
    const { browser, os } = this.browserInfo();
    this.formatLogObject(msg, {
      ...data,
      browser: browser.name,
      browserVersion: browser.version,
      os: os.name,
    }, _getLevel('error'));
  }

  browserInfo() {
    const { cookieEnabled, userAgent } = window.navigator;
    const _browser = Bowser.getParser(userAgent);
    const { parsedResult: { browser, os, platform } } = _browser.parse();

    return {
      cookieEnabled,
      browser,
      os,
      platform,
      path: document?.location?.href
    }
  }

  startApp() {
    const { cookieEnabled, browser, os, platform } = this.browserInfo();
    this.info('Application Started', {
      cookies: cookieEnabled,
      appVersion: `${window.VERSION}`,
      browser: browser.name,
      browserVersion: browser.version,
      os: os.name,
      osVersion: os.version,
      osName: os.versionName,
      platform: platform.type,
    });
  }

  formatLogObject(msg, data, level, e) {
    const { path } = this.browserInfo();

    observable.next({
      level,
      msg,
      data,
      e,
      metadata: {
        sessionId: this.sessionId,
        identity: this.identity,
        path,
        appVersion: `${window.VERSION}`,
      },
    });
  }

};
