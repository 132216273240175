import React, { Fragment } from 'react';
import { FormattedNumber, FormattedDate } from 'react-intl';
import isNumber from 'lodash/isNumber';
import { getSortLabel } from '../sorter';
import { RECORD_EMPTY } from 'constants.js';
import { formatDate2UI } from '../../PriceForm/utils';
import {pickBarrierTypeLabel } from 'pages/price/FormItem';

const PricingHistoryRowMore = props => {
  const {
    contractExpiry,
    quotedCurrency,
    optionExpiryDate,
    referencePrice,
    strikeLevel,
    barrierType,
    barrierLevel,
    isScreenXs,
    displayColumns
  } = props;
  const barrierTypeLabel = pickBarrierTypeLabel(barrierType);
  return (
    <Fragment>
      {displayColumns && displayColumns['contractExpiry'] ?
        <div className="app-table-col size-contractExpiry">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('contractExpiry')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
          {formatDate2UI(contractExpiry)}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['quotedCurrency'] ?
        <div className="app-table-col size-quotedCurrency">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('quotedCurrency')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <div className="text-uppercase">{quotedCurrency}</div>
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['optionExpiryDate'] ?
        <div className="app-table-col size-optionExpiryDate text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('optionExpiryDate')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            <FormattedDate value={optionExpiryDate} />
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['referencePrice'] ?
        <div className="app-table-col size-referencePrice text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('referencePrice')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <div><FormattedNumber value={referencePrice} minimumFractionDigits={2} maximumFractionDigits={2} /></div>
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['strikeLevel'] ?
        <div className="app-table-col size-strikeLevel text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('strikeLevel')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            <FormattedNumber value={strikeLevel} minimumFractionDigits={2} maximumFractionDigits={2} />
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['barrierType'] ?
        <div className="app-table-col size-barrierType text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('barrierType')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {barrierType ? barrierTypeLabel : RECORD_EMPTY}
          </div>
        </div>
        : null}
      {displayColumns && displayColumns['barrierLevel'] ?
        <div className="app-table-col size-barrierLevel text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('barrierLevel')}
            </div> : null}
          <div className="float-right float-sm-none block-right">
            {isNumber(barrierLevel) ? <div><FormattedNumber value={barrierLevel} /></div> : RECORD_EMPTY}
          </div>
        </div>
        : null}
    </Fragment>
  );
}

export default PricingHistoryRowMore;
