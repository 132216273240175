import React, { PureComponent } from 'react';
import TableColumnsToggler from './TableColumnsToggler';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import serialize from 'serialize-javascript';

export const totalColumns = (displayColumns = [], fields) => {
  const total = Object.values(displayColumns).filter((visible) => visible).length + fields.filter(item => item.displayAllways).length;
  return isObject(displayColumns) ? total : ''
};

class TableColumnsTogglerWrapper extends PureComponent {

  constructor(args) {
    super(args);
    this.state = {
      initialValues: setInitialValues(args.storageName, args.columns)
    }
    this.setInitalValuesCheckAll = setInitalValuesCheckAll.bind(this, args.columns)
  }

  static getDerivedStateFromProps(props, state) {
    const initialValues = setInitialValues(props.storageName, props.columns);
    const result = !isEqual(state.initialValues, initialValues) ? { initialValues } : null;
    return result;
  }

  onCheckAll = () => {
    this.setState({
      initialValues: this.setInitalValuesCheckAll()
    })
  }

  render() {
    const { initialValues } = this.state;
    const { formName } = this.props;
    return <TableColumnsToggler formName={formName} onCheckAll={this.onCheckAll} {...this.props} initialValues={initialValues} />
  }
}

TableColumnsTogglerWrapper.defaultProps = {
  isActiveAllColumns: true
}

TableColumnsTogglerWrapper.propTypes = {
  storageName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  displayColumns: PropTypes.object.isRequired,
  isActiveAllColumns: PropTypes.bool.isRequired,
}

// Storage
export const STORAGE_APP_OPTIONS = 'AGILE/OPTIONS';

const getAppOptions = () => {
  const options = localStorage.getItem(STORAGE_APP_OPTIONS);
  let data;
  try {
    data = JSON.parse(options);
  } catch (e) {
    data = null;
  }
  return data || {};
};

function setInitialValues(name, columns) {
  const data = getAppOptions();
  const key = name || 'pricingHistory';
  const initialValuesStorage = data[key] ? data[key] : null;
  return initialValuesStorage ? initialValuesStorage : setInitalValuesCheckAll(columns);
}

function setInitalValuesCheckAll(sorter = []) {
  let initialValues = {};
  if (Array.isArray(sorter)) {
    sorter.forEach(({ key, fieldName }) => {
      const fieldNameKey = fieldName ? fieldName : key;
      initialValues[fieldNameKey] = true;
    });
  }
  return initialValues;
}

export const setAppOptions = (key, options) => {
  const data = getAppOptions();
  data[key] = options;
  localStorage.setItem(STORAGE_APP_OPTIONS, serialize(data));
}
// Stortage
export default TableColumnsTogglerWrapper;
