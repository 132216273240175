import gql from 'graphql-tag';
import { PRODUCT_ANALYSIS_FRAGMENT } from './productAnalysis/ProductAnalysisFragment';

export const PRICING_FORM_RESPONSE_FRAGMENT = gql`fragment PriceFormResponseFragment on PricingAccumulatorHistoryType {
  ...ProductAnalysisFragment
  tag
  accumulationLevel: strikeLevel
  strikeLevel
  barrierLevel
  price
  barrierType
  referencePrice
  marketDataId
  businessDays
  dailyQuantity
  startDate
  quoteUnits
  deltaLot
  remainderLevel
  isTrade
}
${PRODUCT_ANALYSIS_FRAGMENT}
`;
