import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import InlineInput from 'components/form/InlineInput';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import PriceField from './PriceField';

import { TYPE_LIMIT, OCO_ORDER_TYPES, TYPE_STOP_LIMIT, TYPE_STOP } from '../constants.js';


const OCOLeg = (props) => {
  const {
    intl: { formatMessage },
    name,
    index,
    isCombinedCard,
    fields,
    compoPrice,
    change: changeAction,
    editMode = false
  } = props;

  const legData = fields.get(index) ? fields.get(index) : {};
  const {
    orderType = TYPE_LIMIT,
    price,
  } = legData;
  const legTypeOptions = orderType !== TYPE_LIMIT ? OCO_ORDER_TYPES.filter(({ value }) => value !== TYPE_LIMIT) : OCO_ORDER_TYPES;
  const typeDisable = orderType === TYPE_LIMIT ? true : (editMode || false);
  const orderTypeClassName = typeDisable ? 'orderType single-value' : 'orderType'

  const orderTypeHandler = (type) => changeAction(`legs[${index}].stopPrice`, type === TYPE_STOP ? price : null);

  return (
    <Fragment>
      <Row className="row-size-xs">
        <Col xs={12} sm={4}>
          <FormattedMessage id="trade/leg.label"
            defaultMessage="Leg {id}" values={{ id: index + 1 }} />
        </Col>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={4}></Col>
      </Row>
      <Row className="row-size-xs">
        <Col xs={12} sm={4}>
          <Field
            name={`${name}.orderType`}
            className={orderTypeClassName}
            label={<FormattedMessage id="trade/orderType.label" defaultMessage="Order Type" />}
            justValue={true}
            component={InlineSelectInput}
            options={legTypeOptions}
            clearable={false}
            forceDisabled={typeDisable}
            onChange={orderTypeHandler}
          />
        </Col>
        <Col xs={12} sm={4}>
          {orderType === TYPE_LIMIT ?
            <PriceField
              orderType={orderType}
              name={`${name}.price`}
              isCombinedCard={isCombinedCard}
              compoPrice={compoPrice}
              formatMessage={formatMessage}
            />
            : <Field
              name={`${name}.stopPrice`}
              type="number"
              placeHolder={formatMessage({
                id: "trade/stop_price.label",
                defaultMessage: "Stop Price",
              })}
              label={<FormattedMessage id="trade/stop_price.label"
                defaultMessage="Stop Price" />}
              step="1"
              component={InlineInput}
            />
          }
        </Col>
        <Col xs={12} sm={4}>
          {[TYPE_STOP, TYPE_STOP_LIMIT].includes(orderType) ?
            <PriceField
              orderType={orderType}
              name={`${name}.price`}
              isCombinedCard={isCombinedCard}
              compoPrice={compoPrice}
              formatMessage={formatMessage}
            />
            : null}
        </Col>
      </Row>
    </Fragment>
  )
}

const RenderLegs = ({ fields, ...props }) => (
  <Fragment>
    {fields.map((name, index, fields) => <OCOLeg key={index} index={index} name={name} fields={fields} {...props} />)}
  </Fragment>
);

export default RenderLegs;
