import isEmpty from "lodash/isEmpty";

export const FREQUENCY_OPTIONS = [
  { label: "Monthly", value: 1 },
  { label: "Bi-Monthly", value: 2 },
  { label: "Quarterly", value: 3 },
  { label: "Semi Annual", value: 6 },
  { label: "Annual", value: 12 },
];

export const OPTION_INFINE = "In Fine";
export const OPTION_INFINE_VALUE = 0;

const OPTION_INFINE_OBJ = {
  label: OPTION_INFINE,
  value: OPTION_INFINE_VALUE,
};

const compareMaturityFrequency = (maturity, frequency, strict = false) => {
  const maturityInt = parseInt(maturity, 10);
  const frequencyInt = parseInt(frequency, 10);
  if (strict)
    return maturityInt % frequencyInt === 0 && maturityInt / frequencyInt > 1;
  return maturityInt % frequencyInt === 0 && maturityInt / frequencyInt >= 1;
};

export const availableOptionsFrequency = (
  formValues = {},
  frequencyOptionsDefault = FREQUENCY_OPTIONS,
) => {
  if (
    !formValues ||
    isEmpty(formValues) ||
    !formValues["autocall"] ||
    !formValues["maturity"]
  )
    return frequencyOptionsDefault;
  let frequencyOptions = [];
  const autocall =
    formValues["autocall"]?.value?.toLowerCase() ||
    formValues["autocall"].toLowerCase();
  const maturity = formValues["maturity"]?.value || formValues["maturity"];

  frequencyOptions = frequencyOptionsDefault.filter(
    ({ value: frequencyValue }) =>
      compareMaturityFrequency(maturity, frequencyValue, true),
  );

  if (autocall === "no") {
    frequencyOptions.push(OPTION_INFINE_OBJ);
  }

  return frequencyOptions;
};

export const addDisabledOptions = (options = [], disableValue = "") => {
  return options.map((item) => ({
    ...item,
    disabled: item.value.toLowerCase() === disableValue.toLowerCase(),
  }));
};

export const removeDisabledOptions = (options) =>
  options.map((item) => {
    const { disabled, ...rest } = item;
    return {
      ...rest,
    };
  });
