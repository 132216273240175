import { pickBarrierTypeLabel } from 'pages/price/FormItem';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { createSelector } from 'reselect';
import { filterOptions, optionsProvider, optionsProviderEnhaceLabel } from 'pages/price/PriceForm/utils';
import AccumulatorForm from './AccumulatorForm';
import AccumulatorFormValidate from './AccumulatorFormValidate';
import { accumulatorLimitationSelector } from './accumulatorLimitationSelector';
import { PRICING_FORM_INITIAL_VALUES } from './constats';
import { getUniqValuesByProp } from './utils';

export const structuresSelector = createSelector(
  [state => state.price.structures],
  structures => structures
);

const accumulatorFormProps = state => {
  const props = accumulatorLimitationSelector(state);
  const structures = structuresSelector(state);
  const formValues = priceFormValueSelector(state);

  // Limitation by structure field with dependency
  const remainderFeatures = formValues ?
    optionsProvider(filterOptions(structures, { field: 'barrierType', value: formValues.barrierType }, 'remainderFeature'))
    : [];

  // Simple list without limitation
  const structureType = optionsProvider(getUniqValuesByProp(structures, 'type'));
  const barrierType = optionsProviderEnhaceLabel(getUniqValuesByProp(structures, 'barrierType'), pickBarrierTypeLabel);
  const leverageValue = optionsProvider(getUniqValuesByProp(structures, 'leverage'));
  const leverageStyle = optionsProvider(getUniqValuesByProp(structures, 'leverageStyle'));
  const accumulationStyles = optionsProvider(getUniqValuesByProp(structures, 'accumulationStyle'));
  return {
    ...props,
    component: AccumulatorForm,
    initialValues: PRICING_FORM_INITIAL_VALUES,
    validate: AccumulatorFormValidate,
    structureType,
    barrierType,
    leverageValue,
    leverageStyle,
    remainderFeatures,
    formValues,
    accumulationStyles,
  }
}

export default accumulatorFormProps;
