
export const OPEN_TRADES = '@OPEN_TRADES';
export const OPEN_TRADES_SET_INITIAL_STATE = `${OPEN_TRADES}/INIT`;
export const OPEN_TRADES_SET_CURRENT = `${OPEN_TRADES}/CURRENT/SET`;
export const OPEN_TRADES_RESET_CURRENT = `${OPEN_TRADES}/CURRENT/RESET`;
export const OPEN_TRADES_INIT_CURRENT = `${OPEN_TRADES}/INIT`;
export const OPEN_TRADES_EDIT = `${OPEN_TRADES}/EDIT`;
export const OPEN_TRADES_REFETCH = `${OPEN_TRADES}/REFETCH`;
export const OPEN_TRADES_DELETE = `${OPEN_TRADES}/DELETE`;
export const OPEN_TRADES_RESET = `${OPEN_TRADES}/RESET`;
export const OPEN_TRADES_NEW = `${OPEN_TRADES}/NEW`;


export const openTradeSet = payload => ({ type: OPEN_TRADES_SET_CURRENT, payload });
export const openTradeReset = payload => ({ type: OPEN_TRADES_RESET_CURRENT, payload });
export const openTradeInit = payload => ({ type: OPEN_TRADES_INIT_CURRENT, payload });
export const openTradeEdit = payload => ({ type: OPEN_TRADES_EDIT, payload });
export const openTradeRefetch = payload => ({ type: OPEN_TRADES_REFETCH, payload });
export const openTradeDelete = payload => ({ type: OPEN_TRADES_DELETE, payload });
export const openTradesSetInital = () => ({ type: OPEN_TRADES_SET_INITIAL_STATE });
export const openTradesNew = payload => ({ type: OPEN_TRADES_NEW, payload });
