import { DATE_FORMAT_LABEL, DATE_FORMAT_LABEL_SHORT } from 'constants.js';
import { prepareDates } from 'pages/price/PriceForm/utils';
import { createSelector } from 'reselect';

export const accumulatorMiniLimitation = (limitation) => {
  return limitation ? {
    contractExpiries: limitation.contractExpiries ? prepareDates({ dates: limitation.contractExpiries, format: DATE_FORMAT_LABEL_SHORT }) : [],
    structureExpiredAt: limitation.structureExpiredAt ? prepareDates({ dates: limitation.structureExpiredAt, format: DATE_FORMAT_LABEL }) : [],
  } : null
};

export const accumulatorMiniLimitationSelector = createSelector(
  [state => state.limitation],
  accumulatorMiniLimitation
);

export const isGreeksDisplaySelector = createSelector(
  [state => state.auth],
  ({ account = {} }) => (!!account?.greeksDisplay)
);
