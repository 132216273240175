import isEmpty from "lodash/isEmpty";

export const OPTION_NO_DELAY = "No Delay";
const noDelay = {
  value: OPTION_NO_DELAY,
  label: OPTION_NO_DELAY,
};

export const createMonthLabel = (index) =>
  `${index} month${index === 1 ? "" : "s"}`;

export const createFirstObservationIn = (total = 24) => {
  const list = [];
  for (let index = 0; index < total; index++) {
    const label = createMonthLabel(index);
    list.push({
      value: index,
      label,
    });
  }
  return list;
};

export const availableFirstObservationIn = (formValues = {}) => {
  if (!formValues && isEmpty(formValues)) return [noDelay];

  const frequencyValue =
    formValues["frequency"]?.value || formValues["frequency"];
  const maturityValue = formValues["maturity"]?.value || formValues["maturity"];

  const frequency = parseInt(frequencyValue, 10);
  const maturity = parseInt(maturityValue, 10);
  const firstObservationInOptions = createFirstObservationIn(maturity);

  const options = firstObservationInOptions.filter(({ value }) => {
    const valueInt = parseInt(value, 10);
    return (
      valueInt % frequency === 0 && valueInt < maturity && valueInt > frequency
    );
  });

  return [noDelay, ...options];
};
