import { ofType } from 'redux-observable';
import { miniAccumulatorCardUpdate } from 'redux/actions/accumulator-mini';
import { PRICING_SUBMIT_FORM } from 'redux/actions/price';
import { from } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { emptyPricingFormFilter } from './utils';

export const cardLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => emptyPricingFormFilter(state$)),
    filter(() => state$.value.accumulatorMini.cards && state$.value.accumulatorMini.cards.length),
    debounceTime(100),
    map(({ payload }) => payload),
    switchMap(({ id: cardId }) => {
      const cards = state$.value.accumulatorMini.cards;
      const findIndex = cards.findIndex(({ id }) => id === cardId);
      const card = cards[findIndex];
      const actions = [
        miniAccumulatorCardUpdate({
          ...card,
          isCardResult: false,
          fields: card.fields.map((field) => ({ ...field, isResult: false })),
          errorText: null,
          isLoading: true
        }),
      ];
      return from(actions);
    })
  );

export default cardLoaderEpic;
