import {
  PRICING_FORM,
  PRICING_UPDATE_STRUCTURE,
} from 'redux/actions/price';
import { REDUX_FORM_RESET, REDUX_FORM_DESTROY, REDUX_FORM_INITIALIZE } from '../constants';

export const selectStructureFilter = action => action.type === PRICING_UPDATE_STRUCTURE && !!action.structureId;
export const unselectStructureFilter = action => action.type === PRICING_UPDATE_STRUCTURE && !action.structureId;
export const priceFormResetFilter = action => action.type === REDUX_FORM_RESET && action.meta.form === PRICING_FORM;
export const priceFormDestroyFilter = action => action.type === REDUX_FORM_DESTROY && action.meta.form.includes(PRICING_FORM);
export const priceFormInitFilter = action => action.type === REDUX_FORM_INITIALIZE && action.meta.form === PRICING_FORM;
export const priceFormInitializeFilter = form => action => action.type === REDUX_FORM_INITIALIZE && action.meta.form === form;
