import { from } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { STRUCTURE_VANILLA, STRUCTURE_SWAP } from 'constants.js';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { structuresConfig } from 'pages/price/FormItem';
import { vanillaStructureConfig } from 'pages/price/VanillaFormItem';
import { PRICING_FORM, PRICING_INIT, setFieldParams, setPricingFormStructures, updateTrailPrice, setIsAsianSwapStructures } from 'redux/actions/price';
import { changeActionFilter } from 'utils/reduxFormSelector';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { checkAvailableStructure } from 'redux/queries/structures';
import { ROUTE_PRICING } from 'pages/constants';

export const setDynamicFromValueEpic = action$ =>
  action$
    .pipe(
      filter(changeActionFilter(PRICING_FORM, 'structure')),
      filter(({ payload }) => !!payload),
      map(({ payload }) => payload.toLowerCase()),
      distinctUntilChanged(),
      switchMap(structure => {
        switch (structure) {
          case STRUCTURE_SWAP.toLowerCase():
            return from([
              updateTrailPrice([]),
            ]);
          case STRUCTURE_VANILLA.toLowerCase():
            return from([
              updateTrailPrice([]),
              setFieldParams(vanillaStructureConfig['strikeLevel']),
            ]);
          default: // Accumulator Default
            return from([
              setFieldParams(structuresConfig['European']),
            ]);
        }
      })
    );


export const intiStructuresEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_INIT),
    switchMap(() => {
      return from(checkAvailableStructure())
        .pipe(
          filter(({ pricingStructure }) => isArray(pricingStructure)),
          switchMap(({ pricingStructure, isAsianSwap }) => {
            if (isEmpty(pricingStructure) || !pricingStructure) {
              return from([notificationErrorSimple('You have no structures available. Please contact your sales representative.')])
            }

            const actions = [
              setIsAsianSwapStructures(isAsianSwap),
              setPricingFormStructures(sortBy(pricingStructure)),
            ];

            const { location } = state$.value.router || {}
            if(!isAsianSwap && location?.pathname === '/pricing/swap/asian') {
              actions.push(push(`${ROUTE_PRICING}/swap`))
            }

            return from(actions);
          }),
          catchError(() => from([notificationErrorSimple('You have no structures available. Please contact your sales representative.')]))
        )
    })
  )

export default combineEpics(
  setDynamicFromValueEpic,
  intiStructuresEpic,
);
