import { formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import { createSelector } from 'reselect';
import { mapStateToPropsUnderlyings } from '../../underlyings/Underlyings';
import AutocallableFormFields from './AutocallableFormFields';
import AutocallableFormValidate from './AutocallableFormValidate';
import { messageBarrierLevel1, PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from './constats.js';

export const structuredProductsFormFieldsSelector = createSelector(
  [state => state.structuredProducts.formFields],
  (formFields) => formFields
);

export const structuresSelector = createSelector(
  [state => state.structuredProducts.structures],
  structures => structures
);

export const formMessages = (formData = {}) => {
  let messages = {};
  const barrierLevel = parseFloat(formData.barrierLevel);
  if (barrierLevel === 1) {
    messages['barrierLevel'] = messageBarrierLevel1;
  }
  return messages;
}

const autocallableFormProps = (state, formOptions) => {
  const structures = structuresSelector(state);
  const formFields = structuredProductsFormFieldsSelector(state);
  const underlyingsFields = mapStateToPropsUnderlyings(state)?.fields || [];
  const formData = formValuesSelectorStructuredForm(state);

  const firstObservationInOptions = formOptions && formOptions['firstObservationIn'] ? formOptions['firstObservationIn'] : [];
  const solveForOptions = formOptions && formOptions['solveFor'] ? formOptions['solveFor'] : [];
  const couponDirectionOptions = formOptions && formOptions['couponDirection'] ? formOptions['couponDirection'] : [];
  const issuerOptions = formOptions && formOptions['issuer'] ? formOptions['issuer'] : [];
  const legalShapeOptions = formOptions && formOptions['legalShape'] ? formOptions['legalShape'] : [];
  const floatingCurveOptions = formOptions && formOptions['floatingCurve'] ? formOptions['floatingCurve'] : [];
  const currencyOptions = formOptions && formOptions['currency'] ? formOptions['currency'] : [];
  const couponTypeOptions = formOptions && formOptions['couponType'] ? formOptions['couponType'] : [];
  const memoryCouponOptions = formOptions && formOptions['memoryCoupon'] ? formOptions['memoryCoupon'] : [];
  const autocallOptions = formOptions && formOptions['autocall'] ? formOptions['autocall'] : [];
  const maturityOptions = formOptions && formOptions['maturity'] ? formOptions['maturity'] : [];
  const frequencyOptions = formOptions && formOptions['frequency'] ? formOptions['frequency'] : [];
  const barrierTypeOptions = formOptions && formOptions['barrierType'] ? formOptions['barrierType'] : [];


  const messages = formMessages(formData);
  return {
    structures,
    issuerOptions,
    legalShapeOptions,
    floatingCurveOptions,
    currencyOptions,
    maturityOptions,
    frequencyOptions,
    component: AutocallableFormFields,
    initialValues: PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES,
    barrierTypeOptions,
    autocallOptions,
    firstObservationInOptions,
    couponTypeOptions,
    memoryCouponOptions,
    solveForOptions,
    couponDirectionOptions,
    validate: AutocallableFormValidate,
    formFields,
    underlyingsFields,
    messages,
  }
};

export default autocallableFormProps;
