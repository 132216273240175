import React  from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';

export const PricePage = props => (
  <ErrorBoundary>
    <Bundle title="Pricing Page" load={() => import('layouts/PriceLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default PricePage;
