// @TODO: this component dublicate RadioGroup dublicate radio button
// has only rounding styles other
// need to check if it is possible to reuse RadioGroup
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { change, Field } from 'redux-form';

export const markerLeftPosition = (options, active) => {
  const findIndex = options.findIndex((option) => option.value === active);
  const leftPostion = 100 / options.length * findIndex;
  return leftPostion > 0 ? leftPostion.toFixed(4).toString() : '0'
}

function CardRadioBtn(props) {

  const { groupType, options = [], label, input, change, meta, cardId, className } = props;
  const name = input && input.name ? input.name : null;
  const markerStyle = (input && input.value) ?
    {
      left: `${markerLeftPosition(options, input.value)}%`
    }
    : null;

  // @TODO: check it better move login to RXJS
  if (options && options.length === 1) {
    const selectValue = options[0].value;
    // Forse set single value for radiogroup
    change(meta.form, name, selectValue);
  }

  return (
    <FormGroup className={`${className} form-group-radiogroup form-group-size-${groupType} form-group-${name.toLowerCase()}`}>
      {label ? <Label for={name}>{label}</Label> : null}
      <div className={`pills-rounded pills-card nav clearfix nav-pills nav-pills-col-${options.length}`}>
        {options.map((radio, index) => {
          const inputId = name + '-' + index + '-' + cardId;
          return (
            <div className="nav-item" key={radio.value}>
              <Field
                name={name}
                id={inputId}
                component="input"
                type="radio"
                value={radio.value}
                disabled={radio.disabled}
              />
              <Label className="nav-link" for={inputId}>
                <span>
                  {radio.label.toUpperCase()}
                </span>
              </Label>
            </div>)
        })}
        {input.value ? <div className="nav-pills-marker" key="pills-marker" style={markerStyle}></div> : null}
      </div>
    </FormGroup>
  )
}

CardRadioBtn.defaultProps = {
  options: [],
  groupType: 'md'
}

CardRadioBtn.propTypes = {
  options: PropTypes.array.isRequired,
  cardId: PropTypes.string.isRequired,
  className: PropTypes.string,
  groupType: PropTypes.oneOf(['xs', 'sm', 'lg', 'md'])
};

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export { CardRadioBtn as Component };
export default compose(
  memo,
  connect(null, mapDispatchToProps),
)(CardRadioBtn);
