import { apolloErrors } from 'apollo.js';
import { InlineInput } from 'components/form/InlineInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { FORM_SUBMIT_SUCCESS_TIMEOUT } from 'constants.js';
import ClientFields from 'pages/price/mini/components/trade/ClientFields';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form } from 'reactstrap';
import { compose } from 'redux';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';
import { OPEN_TRADES_FORM_EDIT } from '../constants';
import { DASHBOARD_TRADE_UPDATE_MUTATION } from '../query';
import validate from './validateOpenTradesTableItemEdit';

class OpenTradesTableItemEditForm extends PureComponent {
  constructor(args) {
    super(args);
    this.state = {
      submitted: false,
      successMsg: 'Trade has been updated.',
    };
  }

  submit = async values => {
    const { mutate, trailId: [accumulatorMiniHistoryId, otcStatementId, commonId], dashboardId, onSuccessUpdate, isMyDashboard } = this.props;
    const { locationId, customerId, physicalId, traderId } = values;
    const { successMsg } = this.state;
    try {
      const response = await mutate({
        variables: {
          accumulatorMiniHistoryId,
          otcStatementId,
          dashboardId,
          isMyDashboard,
          locationId,
          customerId,
          physicalId,
          traderId,
          commonId,
        }
      });
      const { data } = response;
      if (typeof onSuccessUpdate === 'function') onSuccessUpdate(data?.updateOTCStatementMutation);

      this.setState({ submitted: true, successMsg });

      this.runResetFormTimeout();

      return response;
    }
    catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  };

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(OPEN_TRADES_FORM_EDIT);
    if (typeof onClose === 'function') onClose();
  }

  closeModal = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') onClose();
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  render() {
    const {
      pristine,
      handleSubmit,
      invalid,
      submitting,
      error,
      intl: { formatMessage },
      isClient,
      clientId,
      initialValues,
      isMyDashboard,
    } = this.props;
    const { submitted, successMsg } = this.state;

    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage(successMsg)} />
        :
        <div className="pricing-mini-trade-form">
          <div className="modal-header">
            <div className="modal-title">
              <FormattedMessage id="open-dashboard/trade"
                defaultMessage="Trade details" />
            </div>
          </div>
          <MainErrorMessage error={error} />
          <Form onSubmit={handleSubmit(this.submit)} noValidate>
            <ClientFields
              isUserTradersField={!isMyDashboard}
              isClient={isClient}
              isClientIdField={false}
              clientId={clientId}
              initialValues={initialValues} />
            <Field
              name="physicalId"
              type="text"
              label="Physical ID"
              placeHolder={formatMessage({
                id: 'open-trades/enter-physical-id',
                defaultMessage: 'Enter Physical ID'
              })}
              component={InlineInput} />
            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.closeModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="button" onClick={handleSubmit(this.submit)} color="primary" disabled={pristine || invalid || submitting}>
                <FormattedMessage id="common/save"
                  defaultMessage="Save" />
              </Button>
            </div>
          </Form>
        </div>
    )
  }
};

const reduxFormOptions = {
  form: OPEN_TRADES_FORM_EDIT,
  validate,
};

OpenTradesTableItemEditForm.propTypes = {
  trailId: PropTypes.string.isRequired,
  dashboardId: PropTypes.number.isRequired,
  onSuccessUpdate: PropTypes.func,
  onClose: PropTypes.func,
  isClient: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

export default compose(
  graphql(DASHBOARD_TRADE_UPDATE_MUTATION),
  reduxForm(reduxFormOptions),
  injectIntl,
)(OpenTradesTableItemEditForm);
