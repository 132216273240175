import React from 'react';
import TourTooltipFormFields from 'components/tour/TourTooltipFormFields';
import TourTooltip from 'components/tour/TourTooltip';
import TourToolipDelay from 'components/tour/TourToolipDelay';
import TourTooltipValidForm from 'components/tour/TourTooltipValidForm';
import { FormattedMessage } from 'react-intl';
import { PRICING_FORM } from 'redux/actions/price';
import { tooltipStyles } from './contants';
import { IDLE_VIDEO_TIME } from 'constants.js';

export const TOUR_STEPS_PRICING_FORM = [
  {
    animate: false,
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.structure"
        defaultMessage="Select the structure you want to price. We are continually working on adding new structures. More coming soon." />}
    />,
    selector: '.tour-structure',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.type"
        defaultMessage="Looking for a Consumer hedge? Producer hedge?" />
    }
    />,
    selector: '.tour-type',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltipFormFields required={['commodity', 'contractExpiry']} text={
      <FormattedMessage id="tour/price.comodity-contract-and-expir"
        defaultMessage="Which market & contract are you looking for?" />
    } formName={PRICING_FORM} />,
    selector: '.tour-comodity-contract-and-expiry',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },

  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.bloomberticker"
        defaultMessage="Here is the corresponding Bloomberg ticker for the commodity you selected." />
    }
    />,
    selector: '.tour-bloomberticker',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.ref-price-group"
        defaultMessage="Here, you will be able to select another currency, expiry or amend the reference price of the contract." />
    }
    />,
    selector: '.tour-ref-price-group',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.notional"
        defaultMessage="By default, the Notional is 1 lot a day. Feel free to change it according to your needs." />
    }
    />,
    selector: '.tour-notional',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.barrier-type"
        defaultMessage="Select the Barrier Type of the Accumulator. On the right side, you will see the product description for more details on the payoff." />
    }
    />,
    selector: '.tour-barrier-type',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourToolipDelay text={
      <FormattedMessage id="tour/price.product-description"
        defaultMessage="Here is the product description." />
    }
      delay={IDLE_VIDEO_TIME}
    />,
    selector: '.tour-product-description',
    position: 'left',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.leverage"
        defaultMessage="Here, you will be able to select different kind of leverage." />
    }
    />,
    selector: '.tour-leverage',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },
  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.solve-for"
        defaultMessage="Select the parameter you want to solve for and fill the others." />
    }
    />,
    selector: '.tour-solve-for',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },

  {
    text: <TourTooltipValidForm text={
      <FormattedMessage id="tour/price.dynamic-form"
        defaultMessage="Please, fill in the fields. You can use arrow up and down to set the minimum value regarding to selected pricing." />
    }
      formName={PRICING_FORM}
    />,
    selector: '.tour-dynamic-form',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },

  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.price-btn"
        defaultMessage="Click here to price the product." />
    }
    />,
    selector: '.tour-price-btn',
    position: 'right',
    allowClicksThruHole: true,
    ...tooltipStyles,
  },

  {
    text: <TourTooltip text={
      <FormattedMessage id="tour/price.price-result"
        defaultMessage="The pricing result is displayed here." />
    }
    />,
    selector: '.tour-price-result',
    position: 'left',
    isFixed: false,
    allowClicksThruHole: true,
    ...tooltipStyles,
  },

];
