import apolloClient from 'apollo.js';
import gql from 'graphql-tag';

const CAPITAL_PROTECTION_CALCULATE_PRICE_MUTATION = gql`mutation priceCapitalProtectedNote (
  $issuer: String
  $currency: String!
  $underlyings: [String]!
  $basketType: String!
  $maturity: Int!
  $capitalProtectionLevel: Float!
  $options: [CapitalOptionsType]
  $notional: Float!
  $legalShape: String
  $floatingCurve: String
  $spread: Float
  $structure: String
) {
  priceCapitalProtectedNote (
    issuer: $issuer
    currency: $currency
    underlyings: $underlyings
    basketType: $basketType
    maturity: $maturity
    capitalProtectionLevel: $capitalProtectionLevel
    options: $options
    notional: $notional
    legalShape: $legalShape
    floatingCurve: $floatingCurve
    spread: $spread
    structure: $structure
  ) {
    userId
    valuationDate
    reportDate
    trackId
    optionId
    marketData
    resultCurrency
    marketValue
    theta
    rho
    corrDelta
    assets {
      sdTicker
      currency
      deltaSpot
      divRisk
      fxCorrDelta
      vega
      gamma
    }
    reoffer
    tag
    legalShape
    productAnalysis {
      title: bloombergTicker
      delta
      vega
    }
    productAnalysisAdditional {
      title
      value
    }
    isTrade
    hasSlackId
    notional
    isInternal
  }
}`;

export const submitStructuredProductsCapitalProtectionFormMutation = data => {
  const {
    structure,
    issuer,
    currency,
    underlyings,
    basketType,
    maturity,
    capitalProtectionLevel,
    options,
    notional,
    legalShape,
    floatingCurve,
    spread,
  } = data;

  return apolloClient.mutate({
    mutation: CAPITAL_PROTECTION_CALCULATE_PRICE_MUTATION,
    variables: {
      structure,
      issuer,
      currency,
      underlyings,
      basketType,
      maturity,
      capitalProtectionLevel,
      options,
      notional,
      legalShape,
      floatingCurve,
      spread,
    }
  }).then(({ data = {} }) => {
    const { priceCapitalProtectedNote } = data;
    return priceCapitalProtectedNote;
  });
};
