import React, { PureComponent, Fragment } from 'react';
import { FormattedNumber, FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import AccumulatorPricingHistoryRowMore from './AccumulatorPricingHistoryRowMore';
import AnimateHeight from 'components/animations/AnimateHeight';
import DetailsSwitcher from 'components/controls/DetailsSwitcher';
import { BREAKPOINT_XS } from 'components/theme/breakPoints/constants';
import { getSortLabel } from '../../sorter';
import { toggleOpen } from 'components/layout/header/ProfileDropdown';

class AccumulatorPricingHistoryRow extends PureComponent {

  state = {
    isOpen: false
  }

  toggleOpen = toggleOpen.bind(this);

  toggle = e => {
    e.stopPropagation();
    e.preventDefault();
    this.toggleOpen();
  }

  render() {
    const {
      updatedAt,
      createdAt,
      structure,
      type,
      commodityContract,
      price,
      screenSize,
      displayColumns
    } = this.props;
    const { isOpen } = this.state;
    const isScreenXs = screenSize === BREAKPOINT_XS;
    return (
      <Fragment>
        <div className="app-table-col size-updatedAt">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('updatedAt')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            <FormattedDate value={updatedAt || createdAt} />
          </div>
        </div>
        {displayColumns && displayColumns['time'] ?
          <div className="app-table-col size-time">
            {isScreenXs ?
              <div className="app-table-xs-title d-inline-block d-sm-none">
                {getSortLabel('time')}
              </div>
              : null}
            <div className="float-right float-sm-none block-right">
              <FormattedTime value={updatedAt || createdAt} />
            </div>
          </div> : null}
        <div className="app-table-col size-structure">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('structure')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            <div>{structure}</div>
          </div>
        </div>
          <div className="app-table-col size-type">
            {isScreenXs ?
              <div className="app-table-xs-title d-inline-block d-sm-none">
                {getSortLabel('type')}
              </div>
              : null}
            <div className="float-right float-sm-none block-right">
              {type}
            </div>
          </div>
        <div className="app-table-col size-commodityContract">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('commodityContract')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            {commodityContract}
          </div>
        </div>

        {!isScreenXs ?
          <AccumulatorPricingHistoryRowMore isScreenXs={isScreenXs} {...this.props} />
          :
          <AnimateHeight in={!!isOpen} height={200} duration={200}>
            <AccumulatorPricingHistoryRowMore isScreenXs={isScreenXs} {...this.props} />
          </AnimateHeight>
        }

        <div className="app-table-col size-price text-sm-right">
          {isScreenXs ?
            <div className="app-table-xs-title d-inline-block d-sm-none">
              {getSortLabel('price')}
            </div>
            : null}
          <div className="float-right float-sm-none block-right">
            <FormattedNumber value={price} minimumFractionDigits={2} maximumFractionDigits={2} />
          </div>
        </div>

        <div className="card-pricing-history-action">
          <Button size="sm">
            <FormattedMessage id="pricing-history/recalculate"
              defaultMessage="Price" />
          </Button>
        </div>

        {isScreenXs ? <DetailsSwitcher onToggle={this.toggle} isOpen={isOpen} /> : null}

      </Fragment>
    );
  }
}

export default AccumulatorPricingHistoryRow;
