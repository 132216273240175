import NonLoggedLayout from 'components/layout/nonLogged/NonLoggedLayout';
import StaticPageModalRoute from 'components/staticPage/';
import ConfirmPage from 'pages/confirm/';
import {
  ROUTE_EMAIL_CONFIRM,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGN_UP,
  ROUTE_STATIC_PAGE,
  ROUTE_CHANGE_PASSWORD,
} from 'pages/constants';
import ForgotPasswordPage from 'pages/forgot-password/';
import LoginPage from 'pages/login';
import ResetPasswordPage from 'pages/reset-password/';
import SignUpPage from 'pages/sign-up';
import ChangePassword from 'pages/change-password';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const PUBLIC_ROUTES_NO_THEME = [
  <Route key={ROUTE_EMAIL_CONFIRM} path={ROUTE_EMAIL_CONFIRM} exact component={ConfirmPage} />,
  <Route key={ROUTE_SIGN_UP} path={ROUTE_SIGN_UP} exact component={SignUpPage} />,
  <Route key={ROUTE_STATIC_PAGE} path={ROUTE_STATIC_PAGE} exact component={StaticPageModalRoute} />,
];

const PublicRoutes = props => {
  const { isTheme } = props;
  return (
    <NonLoggedLayout isTheme={isTheme}>
      <Switch>
        <Route path={ROUTE_LOGIN} exact component={LoginPage} />
        <Route key={ROUTE_CHANGE_PASSWORD} path={ROUTE_CHANGE_PASSWORD} exact component={ChangePassword} />,
        <Route key={ROUTE_FORGOT_PASSWORD} path={ROUTE_FORGOT_PASSWORD} exact component={ForgotPasswordPage} />,
        <Route key={ROUTE_RESET_PASSWORD} path={ROUTE_RESET_PASSWORD} exact component={ResetPasswordPage} />,
        {!isTheme ?
          PUBLIC_ROUTES_NO_THEME
          : null}
        <Redirect from="/" to={ROUTE_LOGIN} />
      </Switch>
    </NonLoggedLayout>
  );
};

PublicRoutes.defaultProps = {
  isTheme: false,
};

PublicRoutes.propTypes = {
  isTheme: PropTypes.bool.isRequired,
};

export default PublicRoutes;
