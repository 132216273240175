import React, { Fragment, useEffect, useState, useCallback } from "react";
import moment from "moment";
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import InlineBox from 'components/form/inputs/InlineBox';
import InlineInputWithMask from "components/form/inputs/inlineInput/InlineInputWithMask";
import FieldErrorMessage from 'components/form/FieldErrorMessage';
import { validateLegs } from './validate';
import './SizePerSettlementInput.scss';
import { normalizeZeroToNull } from "utils/formNormalizers";
import { isNil } from "lodash";

const RenderDate = ({ input: { value } }) => (<span>{moment(value).format('MMM-YY')}</span>)

const RenderDropdownItem = ({ fields }) => (
  <Fragment>
    {
      fields.map((leg, index) => (
        <DropdownItem key={index} text>
          <Row>
            <Col>
              <Field
                name={`${leg}.contractExpiry`}
                component={RenderDate}
              />
            </Col>
            <Col>
              <Field
                name={`${leg}.notional`}
                placeholder="Notional"
                step="1"
                component={InlineInputWithMask}
              />
            </Col>
          </Row>
        </DropdownItem>
      ))
    }
  </Fragment>
);

export const SizePerSettlementInput = (props) => {
  const { label, isFillAll, formData } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const toggle = () => setIsOpen(!isOpen);

  const onClose = useCallback(() => {
    const formErrors = validateLegs(formData);
    if (isNil(formErrors.notional)) {
      const { notional } = Object.assign(...formErrors.legs || [{}]);
      setErrors(notional);
    } else {
      setErrors(null);
    }
  }, [formData]);

  useEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  return (
    <InlineBox className="SizePerSettlementInput" label={label} meta={{}}>
      <Dropdown isOpen={isOpen} toggle={toggle} direction={'down'}>
        <div className="lable">
          <Field
            name="notional"
            disabled={true}
            component={InlineInputWithMask}
          />
        </div>
        <FieldErrorMessage errorOnIsTouched={false} invalid={true} submitting={false} error={errors} />
        <DropdownToggle caret>
          <span className="Select-arrow-zone">
            <span className="Select-arrow"></span>
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {isFillAll ?
            <Fragment>
              <DropdownItem header>
                <Row>
                  <Col>All</Col>
                  <Col>
                    <Field
                      name="fillAllLegs"
                      step="1"
                      component={InlineInputWithMask}
                      normalize={normalizeZeroToNull}
                    />
                  </Col>
                </Row>
              </DropdownItem>
              <DropdownItem divider />
            </Fragment> : null
          }
          <FieldArray name="legs" component={RenderDropdownItem} />
        </DropdownMenu>
      </Dropdown>
    </InlineBox>
  );
};

SizePerSettlementInput.defaultProps = {
  label: 'Size per Settlement',
  isFillAll: true,
}

SizePerSettlementInput.propTypes = {
  label: PropTypes.string.isRequired,
  isFillAll: PropTypes.bool.isRequired,
};

export default SizePerSettlementInput;
