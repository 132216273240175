import { onChangeField } from 'components/form/utils/';
import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';
import { PRICING_FORM } from 'redux/actions/price';
import InlineCheckbox from './inputs/checkbox/InlineCheckbox';
import InlineDatePicker from './inputs/InlineDatePicker';
import InlineRadio from './inputs/radio/InlineRadio';
import InlineInputBlock from './inputs/readOnly/InlineInputBlock';
import Slider from './inputs/slider/Slider';

const renderInput = props => {
  const { type, step, meta, onChange, isOutput, labelCheckbox, filterType, queryField, placeHolder, onChangeFilter, name, isVisible, selectedSingleOption, ...rest } = props;
  const { value } = rest.input || {};
  if (!name) {
    return null;
  }

  const restFull = { name, type, meta, onChangeFilter, ...rest };

  // @TODO: simplify logic
  // maybe use autofil or custom property to set empty
  // Display empty value '-' in div element insteat input with value
  if (
    ((type !== 'number' && rest.value === '') || (type === 'number' && rest.value === '-')) &&
    !meta.active && !isOutput && meta.form === PRICING_FORM) {
    return <InlineInputBlock value={rest.value} name={rest.name} />
  }
  switch (type) {
    case 'date':
      return (<InlineDatePicker placeHolder={placeHolder} onChange={e => onChangeField(e, onChange, { ...restFull, meta })} selected={value} meta={meta} name={name} {...rest} />);
    case 'number':
      return (<Input onChange={e => onChangeField(e, onChange, { ...restFull, meta })} invalid={meta.touched && meta.invalid && !props.disabled} type={type} step={step} name={name} {...rest} placeholder={placeHolder} aria-label={placeHolder} />);
    case 'checkbox':
      return <InlineCheckbox placeHolder={placeHolder} labelCheckbox={labelCheckbox} onChange={e => onChangeField(e, onChange, restFull)} meta={meta} type={type} onChangeFilter={onChangeFilter} name={name} {...rest} />
    case 'radio':
      return <InlineRadio onChange={e => onChangeField(e, onChange, restFull)} meta={meta} type={type} onChangeFilter={onChangeFilter} name={name} {...rest} />
    case 'slider':
      return <Slider meta={meta} invalid={meta.touched && meta.invalid} onChange={(e, val) => onChangeField(val, onChange, restFull)} name={name} {...rest} />
    default:
      return (<Input type={type} name={name} {...rest} placeholder={placeHolder} invalid={meta.touched && meta.invalid} onChange={e => onChangeField(e, onChange, restFull)} aria-label={placeHolder} />);
  }
}

renderInput.defaultProps = {
  isOutput: false,
  step: '0.01',
}

renderInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isOutput: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default renderInput;
