import { JL } from 'jsnlog';

export const getTimeStamp = () => new Date().toISOString();

const consoleAppender = () => {
  const cls = JL.createConsoleAppender('consoleAppender');
  cls.sendLogItems = function (logItems, successCallback) {
    try {
      if (!JL._console) {
        return;
      }
      for (let i = 0; i < logItems.length; ++i) {
        const l = logItems[i].l;
        const { msg, data } = JSON.parse(logItems[i].m);
        if (l <= JL.getDebugLevel()) {
          console.debug(msg, data);
        }
        else if (l <= JL.getInfoLevel()) {
          console.info(msg, data);
        }
        else if (l <= JL.getWarnLevel()) {
          console.warn(msg, data);
        }
        else {
          console.error(msg, data);
        }
      }
    } catch (e) {
    }
    successCallback();
  }
  return cls;
}

export const posibleAddapters = {
  console: consoleAppender(),
  splunk: JL.createAjaxAppender('ajaxAppender')
};

export const _getLevel = (type) => {
  const caseType = type || window.LEVEL || 'trace';
  switch (caseType) {
    case 'info':
      return JL.getInfoLevel();
    case 'trace':
      return JL.getTraceLevel();
    case 'debug':
      return JL.getDebugLevel();
    case 'warn':
      return JL.getWarnLevel();
    case 'error':
      return JL.getErrorLevel();
    default:
      return JL.getDebugLevel();
  }
}

export const _getLevelTitle = (level) => {
  switch (level) {
    case JL.getInfoLevel():
      return 'INFO';
    case JL.getTraceLevel():
      return 'TRACE';
    case JL.getDebugLevel():
      return 'DEBUG';
    case JL.getWarnLevel():
      return 'WARN';
    case JL.getErrorLevel():
      return 'ERROR';
    default:
      return '';
  }
}
