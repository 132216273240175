import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';

export const mapNodes = createSelector(
  nodeList => nodeList ? nodeList.edges : null,
  edges => edges ? edges.map(({ node: { __typename, ...rest }, cursor = null}) => ({...rest, cursor})) : []
);

export const mapNodesWithLegs = createSelector(
  nodeList => nodeList ? nodeList.edges : null,
  edges => edges ? edges.reduce((acc, { node: { __typename, legs, ...rest }, cursor = null}) => {
    if(legs && legs.length && rest.structure !== SWAP_ASIAN) {
      for (const [index, leg] of legs.entries()) {
        acc.push({ ...rest, ...leg, id: `${rest.id}-${index}`, cursor});
      }
    } else {
      acc.push({...rest, cursor});
    }

    return [...acc];
  }, []) : []
);

export const orderByReactIntlNode = list => orderBy(list, 'label.props.children.props.defaultMessage')

export const pathToClass = (pathname = '') => {
  const path = pathname.replace(/\//g, '-').slice(1);
  return `page-${path === '' ? 'main' : path}`;
};
