import { STRUCTURE_ACCUMULATOR, STRUCTURE_SWAP, STRUCTURE_VANILLA, STRUCTURE_FX } from '../../../constants';
import accumulatorFormProps from './forms/accumulator/accumulatorFormProps';
import swapFormProps from './forms/swap/swapFormProps';
import vanillaFormProps from './forms/vanilla/vanillaFormProps';
import fxFormProps from './forms/fx/fxFormProps';
import UnknownStructure from './UnknownStructure';
import { slugify } from './utils';

export default function (structure, state, ownProps) {
  let props = {};
  const structureName = structure ? slugify(structure) : 'Unknown';

  switch (structureName) {
    case (slugify(STRUCTURE_VANILLA)):
      props = vanillaFormProps(state);
      break;
    case (slugify(STRUCTURE_ACCUMULATOR)):
      props = accumulatorFormProps(state);
      break;
    case (slugify(STRUCTURE_SWAP)):
      props = swapFormProps(state, ownProps);
      break;
    case (slugify(STRUCTURE_FX)):
      props = fxFormProps(state);
      break;
    default:
      return {
        structure: structureName,
        component: UnknownStructure
      }
  }
  return props;
}
