export const ORDERS = '@ORDERS';
export const ORDERS_SET_INITIAL_STATE = `${ORDERS}/INIT`;
export const ORDERS_SET_CURRENT = `${ORDERS}/CURRENT/SET`;
export const ORDERS_RESET_CURRENT = `${ORDERS}/CURRENT/RESET`;
export const ORDERS_INIT_CURRENT = `${ORDERS}/INIT`;
export const ORDERS_EDIT = `${ORDERS}/EDIT`;
export const ORDERS_REFETCH = `${ORDERS}/REFETCH`;
export const ORDERS_DELETE = `${ORDERS}/DELETE`;
export const ORDERS_RESET = `${ORDERS}/RESET`;
export const ORDERS_NEW = `${ORDERS}/NEW`;
export const ORDERS_UPDATE_OPTIONS = `${ORDERS}/CURRENT/OPTIONS`;
export const ORDERS_LOADING_OPTIONS = `${ORDERS}/CURRENT/OPTIONS/LOADING`;

export const orderSetOptions = (field, options) => ({
  type: ORDERS_UPDATE_OPTIONS,
  payload: {
    field,
    options
  }
});

export const orderGetOptions = (field) => ({
  type: ORDERS_LOADING_OPTIONS,
  payload: {
    field,
  }
});

export const orderSet = payload => ({
  type: ORDERS_SET_CURRENT,
  payload
});

export const orderReset = payload => ({
  type: ORDERS_RESET_CURRENT,
  payload
});

export const orderInit = payload => ({
  type: ORDERS_INIT_CURRENT,
  payload
});

export const orderEdit = payload => ({
  type: ORDERS_EDIT,
  payload
});

export const orderRefetch = payload => ({
  type: ORDERS_REFETCH,
  payload
});

export const orderDelete = payload => ({
  type: ORDERS_DELETE,
  payload
});

export const ordersSetInital = () => ({
  type: ORDERS_SET_INITIAL_STATE
});

export const ordersNew = payload => ({
  type: ORDERS_NEW,
  payload
});
