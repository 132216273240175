import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import capitalize from 'lodash/capitalize';
import { ROUTE_PORTAL } from 'pages/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink as Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { CATEGORIES } from './category/data';
import lowerCase from 'lodash/lowerCase';
import PropTypes from 'prop-types';

const Portal = props => {
  const { categories } = props;
  return (
    <Container className="mr-container-md cards-list">
      <h1 className="h3 mb-3">
        <FormattedMessage id="common/portal"
          defaultMessage="Portal" />
      </h1>
      <Row className="row-size-sm">
        {categories
          .map(category =>
            <Col tag={Link} key={lowerCase(category)} className="mb-2" xs={12} sm={6} to={`${ROUTE_PORTAL}/category/${category}`}>
              <Card className={`card-modern h-100 card-link`}>
                <div className="d-flex flex-row align-items-center">
                  <div className="block-circle card-link-icon s-40">
                    <LibraryBooksIcon className="icon-24" />
                  </div>
                  <div>
                    <h3 className="card-title-sm mb-0">
                      {capitalize(category)}
                    </h3>
                  </div>
                </div>
              </Card>
            </Col>
          )}
      </Row>
    </Container>
  );
};

Portal.defaultProps = {
  categories: CATEGORIES,
};

Portal.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default Portal;
