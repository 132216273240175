import React, { PureComponent, Fragment } from 'react';
import gql from 'graphql-tag';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import NotifyIcon from './NotifyIcon';

export const NOTIFICATION_FRAGMENT = gql`fragment notificationDropdownConnectionFragment on NotifyMeType {
  id
  operator
  emailStatus
  status
  createdAt
  updatedAt
  structure
  value
}
`;

class NotifyMeDropdownListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { readStatus: props.isRead, isVisible: true };
    this.changeStatus = this._changeStatus.bind(this);
    this.onDelete = this._onDelete.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isRead !== prevState.isRead) {
      const { showOnlyRead } = nextProps;
      return {
        readStatus: nextProps.isRead,
        isVisible: showOnlyRead ? !nextProps.isRead : true
      }
    }
    return null;
  }

  _onDelete = () => {
    const { onDelete } = this.props;
    onDelete(this.props);
    this.setState({
      isVisible: false
    })
  }

  _changeStatus = () => {
    const { readStatus } = this.state;
    const { onChageStatus, showOnlyRead } = this.props;
    if (readStatus === false) {
      this.setState({
        readStatus: true,
        isVisible: showOnlyRead ? false : true
      });
    }
    onChageStatus(this.props);
  }

  render() {
    const { body, updatedAt, payload, createdAt, index } = this.props;
    const { readStatus, isVisible } = this.state;
    const statusClassName = !readStatus ? ' unread' : '';
    const disabledClassName = payload && payload.disabled ? ' disabled-cursor' : '';
    const html = body ? { __html: body } : null;
    const disabled = payload && payload.disabled;
    const type = payload && payload.type;
    const typeClass = type ? ' ' + type.toLowerCase() : '';
    return (
      payload && isVisible ?
        <Fragment>
          <Row id={`item-notify-me-${index}`} className={`notification-dropdown-row${statusClassName}${disabledClassName}${typeClass}`} onClick={!disabled ? this.changeStatus : null}>
            <Col xs={12} sm={8} className="pr-0" >
              <div className="notification-icon">
                <NotifyIcon type={type} />
              </div>
              {html ? <div className="notification-content">
                <div dangerouslySetInnerHTML={html} className="text"></div>
              </div> : null}
            </Col>
            <Col xs={12} sm={4} className="text-right">
              <div className="notification-content text-lighten notification-date">
                <FormattedDate value={updatedAt || createdAt} />&nbsp;<FormattedTime value={updatedAt || createdAt} />
              </div>
            </Col>
          </Row>
          {disabled ? <UncontrolledTooltip placement="top" target={`item-notify-me-${index}`}>
            <FormattedMessage id="common/no-re-pricing"
              defaultMessage="Re-pricing is not available" />
          </UncontrolledTooltip>
            : null}
        </Fragment>
        :
        null
    )
  }
}

NotifyMeDropdownListItem.propTypes = {
  onChageStatus: PropTypes.func.isRequired,
};

NotifyMeDropdownListItem.fragments = {
  item: NOTIFICATION_FRAGMENT
}

export default NotifyMeDropdownListItem;
