export const NEON_COMMODITY_UPDATE = '@NEON/UPDATE/COMMODITY';

export const NEON_CARD_LAYOUT_UPDATE = '@NEON/UPDATE/CARD_LAYOUT';

export const neonCommodityUpdateAction = payload => ({
  type: NEON_COMMODITY_UPDATE,
  payload
});

export const neonCommodityLayoutUpdate = payload => ({
  type: NEON_CARD_LAYOUT_UPDATE,
  payload
});
