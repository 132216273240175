import apolloClient from 'apollo.js';
import gql from 'graphql-tag';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { STRUCTURED_PRODUCTS_HISTORY_QUERY_ITEM_FRAGMENT } from 'pages/structured-products/history/components/query';
import { TAG_ACTION_HISTORY } from 'redux/actions/structured-products.js'

export const GET_STRUCTURED_PRODUCT_BY_TAG = gql`query structuredProductsPricingHistoryShareQuery(
  $tag: String!
) {
  structuredProductsPricingTagShare (
    tag: $tag
  ) {
    ...structuredProductsHistoryConnectionQueryFragment
    schedule {
      couponLevel
      autocallTriggerLevel
      couponTriggerLevel
      observationDate
      redemptionDate
    }
  }
}
${STRUCTURED_PRODUCTS_HISTORY_QUERY_ITEM_FRAGMENT}`;

export const GET_STRUCTURED_PRODUCT_HISTORY_BY_TAG = gql`query structuredProductsPricingHistoryShareQuery(
  $tag: String!
) {
  structuredProductsPricingHistoryShare (
    tag: $tag
  ) {
    ...structuredProductsHistoryConnectionQueryFragment
    schedule {
      couponLevel
      autocallTriggerLevel
      couponTriggerLevel
      observationDate
      redemptionDate
    }
  }
}
${STRUCTURED_PRODUCTS_HISTORY_QUERY_ITEM_FRAGMENT}`;

export const getStructuredProductByTag = (tag, action) =>
  apolloClient.query({
    query: action === TAG_ACTION_HISTORY ? GET_STRUCTURED_PRODUCT_HISTORY_BY_TAG : GET_STRUCTURED_PRODUCT_BY_TAG,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: { tag }
  })
  .then(({ data }) => data.structuredProductsPricingHistoryShare || data.structuredProductsPricingTagShare)

