import { useSelector } from "react-redux";
import { spFormValueSelector } from "../../../../../components/configurableSp/selectors/selectors";
import React, { useEffect, useMemo } from "react";
import { Field } from "redux-form";
import { InlineSelectInput } from "@agile/react-components";

const FloatingCurve = ({
  formName,
  changeField,
  optionsMap,
  change,
  ...rest
}) => {
  const formValues: any = useSelector((state) => {
    return spFormValueSelector(formName)(state);
  });

  const selectedCurrency = formValues?.currency?.value;

  const options = useMemo(() => {
    if (!optionsMap[selectedCurrency]) {
      return [];
    }
    return optionsMap[selectedCurrency].reduce((result, { ui }) => {
      result.push({ label: ui, value: ui });
      return result;
    }, []);
  }, [selectedCurrency, optionsMap]);

  useEffect(() => {
    if (options.length) {
      changeField(formName, "floatingCurve", options[0]);
    }
  }, [options]);

  return (
    <Field
      name="floatingCurve"
      component={InlineSelectInput}
      change={changeField}
      options={options}
      clearable={false}
      {...rest}
    />
  );
};

export default FloatingCurve;
