import InsertChartIcon from '@material-ui/icons/InsertChart';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { GRAPHQL_CACHE_FIRST } from 'components/graphql/constants';
import { GET_MARKET_DATA_BY_BLOOMBERG } from 'pages/price/ProductChart/accumulator/query';
import 'pages/price/ProductChart/ProductChart.scss';
import ProductChartModal from 'pages/price/ProductChart/ProductChartModal';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import PriciniMiniChartContainer from './PriciniMiniChartContainer';

const renderChart = props => {
  const { bloombergTicker } = props;
  return (
    bloombergTicker ? <PriciniMiniChartContainer {...props} bloombergTicker={bloombergTicker} /> : null
  )
}

function PriciniMiniChartBlock(props) {
  const {
    title,
    form,
    bloombergTicker,
    cardId,
  } = props;

  return (
    <ErrorBoundary>
      {bloombergTicker ?
        <Query query={GET_MARKET_DATA_BY_BLOOMBERG} variables={{ bloombergTicker }} fetchPolicy={GRAPHQL_CACHE_FIRST}>
          {({ error, data: { getMarketDataByBloombergTicker: marketData } = {} }) => {
            if (error) throw new Error(error);
            const marketDataId = marketData && marketData.id ? marketData.id : null;
            return (
              marketDataId ? (
                <div className="chart-block">
                  <ProductChartModal
                    buttonLabel="Expand"
                    className="modal-chart"
                    icon={<InsertChartIcon />}
                    iconTitle={'Chart'}
                    isIconTooltip={true}
                    cardId={cardId}
                    title={title}
                    component={renderChart({ bloombergTicker, ...props, marketData, form })} />
                </div>
              ) : null
            )
          }}
        </Query>
        : null
      }
    </ErrorBoundary>
  )
};

PriciniMiniChartBlock.defaultProps = {
  title: 'Historical Price Of The Reference Contract',
  bloombergTicker: null,
};

PriciniMiniChartBlock.propTypes = {
  bloombergTicker: PropTypes.string,
  cardId: PropTypes.string.isRequired,
};

export default PriciniMiniChartBlock;
