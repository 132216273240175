import { optionsProvider } from 'pages/price/PriceForm/utils';

export const REQUEST_PRODUCT_CREATION = '@REQUEST/PRODUCT/CREATION';

export const OPTIONS = {
  structure: optionsProvider(['Minifuture']),
  YESNO: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],
  type: optionsProvider(['Leverage', 'Reverse']),
  issuer: optionsProvider(['Marex Financial']),
  format: optionsProvider(['Certificate']),
};

export const initialValues = {
  structure: OPTIONS.structure[0].value,
  issuer: OPTIONS.issuer[0].value,
  currency: null,
  format: OPTIONS.format[0].value,
  type: OPTIONS.type[0].value,
  commodityCode: null,
  fixingDate: null,
  redemptionDate: null,
  strikeLevel: null,
  knockOutLevel: null,
  rolling: OPTIONS.YESNO[0].value,
  ratio: 1,
  issueSize: null,
  issuePrice: null,
};
