import CachedIcon from '@material-ui/icons/Cached';
import InlineInput from 'components/form/InlineInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import CountDown from 'components/common/countdown/CountDown';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import { Field } from 'redux-form';
import { REFERENCE_TYPES_OPTIONS } from '../constants';

const TOTAL_TIME_TO_CALCULATE = 10; // in seconds

const PricinigMiniFormReferencePrice = props => {
  const {
    id,
    isLoading = false,
    referenceTypes,
    form,
    getReferencePrice,
    onChangeReferenceType,
    isDisabledReference,
    isCalculating,
    onStopReferencePrice,
  } = props;

  useEffect(() => {
    getReferencePrice();
  }, []);

  return (
    <div className="control-wrapper slider-wrapper ml-auto mb-2">
      <div className="reference-type-wrapper">
        <Field
          name={'referenceType'}
          component={RadioGroup}
          justValue={true}
          className={'select-disabled'}
          forceDisabled={isLoading}
          options={referenceTypes}
          groupType={'xs'}
          onChangeFilter={({e: referenceType}) => onChangeReferenceType(referenceType)}
        />
      </div>
      <Field
        name="reference"
        component={InlineInput}
        placeHolder={'Ref'}
        form={form}
        showAnimation={true}
        disabled={isLoading || isDisabledReference}
        label={'Reference Price'}
        type="number" />
      <button id={`reference-price-${id}-btn`} type="button" className="btn" onClick={getReferencePrice} disabled={isLoading}>
        <CachedIcon />
      </button>
      <UncontrolledTooltip placement="top" target={`reference-price-${id}-btn`}>
        <FormattedMessage id="form.price.fields.reference-price"
          defaultMessage="Reference Price" />
      </UncontrolledTooltip>
      <div>
        { isCalculating ? <CountDown showCounter={false} count={TOTAL_TIME_TO_CALCULATE} showProgress={true} id={id} onStop={onStopReferencePrice} className='countdown-reference'/> : null }
      </div>
    </div>
  );
};

PricinigMiniFormReferencePrice.defaultProps = {
  referenceTypes: REFERENCE_TYPES_OPTIONS,
};

PricinigMiniFormReferencePrice.propTypes = {
  referenceTypes: PropTypes.array.isRequired,
  getReferencePrice: PropTypes.func.isRequired,
  onChangeReferenceType: PropTypes.func.isRequired,
};

export default PricinigMiniFormReferencePrice
