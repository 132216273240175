import Loader from 'components/common/loader/Loader';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PricingMiniFormsList from './PricingMiniFormsList';

class PricingMiniFormsListWrapper extends Component {
  state = {
    page: 1
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.items, nextProps.items)
      || this.props.isLoading !== nextProps.isLoading
      || !isEqual(this.props.pageInfo, nextProps.pageInfo);
  }

  loadMore = () => {
    const {
      pageInfo,
      isLoading,
      items,
      underlyingId,
      onLoadMore,
    } = this.props;
    const { page } = this.state;

    const hasMore = !isLoading && pageInfo && pageInfo.hasNextPage;
    if (hasMore && items?.length && underlyingId) {
      const nextPage = page + 1;
      if (isFunction(onLoadMore)) onLoadMore({ nextPage })
    }
  }

  render() {
    const {
      pageInfo,
      isLoading,
      items,
    } = this.props;

    const hasMore = !isLoading && pageInfo && pageInfo.hasNextPage;

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        threshold={250}
        useWindow={true}
        hasMore={hasMore}
        loader={<Loader key={'PricingMiniFormsListWrapperLoader'} />}>
        <PricingMiniFormsList items={items} />
      </InfiniteScroll>

    );
  }
};

PricingMiniFormsListWrapper.defaultProps = {
  isLoading: false,
  pageInfo: {},
};

PricingMiniFormsListWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pageInfo: PropTypes.object.isRequired,
  swapCardsFetchMore: PropTypes.func,
  underlyingId: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export const accumulatorMiniCardsSelector = createSelector(
  [
    state => state.accumulatorMini.cards,
    state => state.accumulatorMini.pageInfo,
    state => state.accumulatorMini.bankHolidays,
  ],
  (items, pageInfo, bankHolidays) => ({ items, pageInfo, bankHolidays })
);

export default connect(accumulatorMiniCardsSelector)(PricingMiniFormsListWrapper);
