import { combineEpics, ofType } from 'redux-observable';
import { STRUCTURED_PRODUCTS_FORM_DESTROY, STRUCTURED_PRODUCTS_FORM_LOAD } from 'redux/actions/structured-products';
import { switchMap, takeUntil } from 'rxjs/operators';
import form from './form/';
import history from './history/';

export const structuredProductsLifeEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(STRUCTURED_PRODUCTS_FORM_LOAD),
      switchMap(() => {
        return combineEpics(
          form,
          history,
        )(action$, state$)
          .pipe(
            takeUntil(action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_DESTROY)))
          )
      })
    );

export default combineEpics(
  structuredProductsLifeEpic,
);


