import validateMessages from 'utils/validate.messages';
import validateJs from 'utils/validate.js';
import {
  CAPITAL_PROTECTION_LEVEL_MIN,
  PARTICIPATION_LEVEL_MIN,
  PARTICIPATION_LEVEL_MAX,
  PARTICIPATION_LEVEL_MIN_2,
  PARTICIPATION_LEVEL_MAX_2,
  REBATE_LEVEL_MIN,
  REBATE_LEVEL_MAX,
} from './constats';
import { INPUT_NUMBER_MAX_VALUE_LARGE } from 'constants.js';

// @TODO: check all logic and update validations
const dynamicValidationsCreate = (formData, props = {}) => {
  let valdiations = {};
  const { underlyingsFields = [] } = props;
  const {
    optionType,
    optionType2,
    strikeLevel,
    strikeLevel2,
  } = formData;

  const strikeLevelValue = parseFloat(strikeLevel);
  const strikeLevelValue2 = parseFloat(strikeLevel2);
  const optionTypeCondition = optionType?.toLowerCase() === 'call';
  const optionTypeCondition2 = optionType2?.toLowerCase() === 'call';

  valdiations['strikeLevel'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition ? 85 : 50,
      lessThanOrEqualTo: optionTypeCondition ? 150 : 115,
    },
    maxDecimalDigits: true,
  };

  valdiations['strikeLevel2'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition2 ? 85 : 50,
      lessThanOrEqualTo: optionTypeCondition2 ? 150 : 115,
    },
    maxDecimalDigits: true,
  };

  valdiations['capLevel'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition ? strikeLevelValue : 0,
      lessThanOrEqualTo: optionTypeCondition ? 200 : strikeLevelValue,
    },
    maxDecimalDigits: true,
  };

  valdiations['capLevel2'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition2 ? strikeLevelValue2 : 0,
      lessThanOrEqualTo: optionTypeCondition2 ? 200 : strikeLevelValue2,
    },
    maxDecimalDigits: true,
  };

  valdiations['barrierLevel'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition ? strikeLevelValue : 0,
      lessThanOrEqualTo: optionTypeCondition ? 200 : strikeLevelValue,
    },
    maxDecimalDigits: true,
  };

  valdiations['barrierLevel2'] = {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: optionTypeCondition2 ? strikeLevelValue2 : 0,
      lessThanOrEqualTo: optionTypeCondition2 ? 200 : strikeLevelValue2,
    },
    maxDecimalDigits: true,
  };

  if (underlyingsFields && underlyingsFields.length) {
    valdiations[underlyingsFields[0].name] = {
      presence: {
        message: `^Underlying ${validateMessages.blank}`,
      }
    }
  }

  return valdiations;
}

export default function validate(formData, props = {}) {
  const dynamicValidations = dynamicValidationsCreate(formData, props);
  return validateJs(formData, {
    ...dynamicValidations,
    structure: {
      presence: true,
    },
    issuer: {
      presence: true,
    },
    legalShape: {
      presence: true,
    },
    spread: {
      presence: {
        message: `^Spread ${validateMessages.blank}`
      },
      numericality: {
        onlyInteger: true,
      }
    },
    floatingCurve: {
      presence: true,
    },
    currency: {
      presence: true,
    },
    maturity: {
      presence: true,
    },
    capitalProtectionLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: CAPITAL_PROTECTION_LEVEL_MIN,
      },
      maxDecimalDigits: true,
    },
    participationLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: PARTICIPATION_LEVEL_MIN,
        lessThanOrEqualTo: PARTICIPATION_LEVEL_MAX,
      },
      maxDecimalDigits: true,
    },
    participationLevel2: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: PARTICIPATION_LEVEL_MIN_2,
        lessThanOrEqualTo: PARTICIPATION_LEVEL_MAX_2,
      },
      maxDecimalDigits: true,
    },
    rebateLevel: {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: REBATE_LEVEL_MIN,
        lessThanOrEqualTo: REBATE_LEVEL_MAX,
      },
      maxDecimalDigits: true,
    },
    notional: {
      presence: {
        message: `^Notional ${validateMessages.blank}`
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE_LARGE,
      }
    },
    ...dynamicValidations,
  }) || {};
}
