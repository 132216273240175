import { combineEpics } from 'redux-observable';
import price from './price/';
import customRequests from './custom-requests/';
import notifications from './notifications/';
import tours from './tours/';
import alerts from '../alerts/epics';
import common from './common';
import orders from './orders/';
import neon from './neon/';
import structuredProducts from './structured-products/';
import minifuture from './minifuture';
import booking from './booking';

export const rootEpic = combineEpics(
  alerts,
  price,
  customRequests,
  notifications,
  tours,
  common,
  orders,
  neon,
  structuredProducts,
  minifuture,
  booking,
);
