import React from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const InlineRadio = props => {
  const { name, label, meta, placeHolder, groupName, ...rest } = props;
  return (
    <div className="custom-control custom-radio">
      <Input id={name} className="custom-control-input" {...rest} aria-label={placeHolder || label} name={groupName} />
      <Label htmlFor={name} className="custom-control-label" >
        {label}
      </Label>
    </div>
  );
};

InlineRadio.defaultProps = {
  groupName: 'radio-group',
};

InlineRadio.propTypes = {
  groupName: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default InlineRadio;
