import Modal from 'components/modal/Modal';
import StaticPage from 'components/staticPage/StaticPage';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createAlert } from 'react-redux-alerts';
import { Button } from 'reactstrap';
import { INFO_MESSAGES_USER_PAGE } from 'redux/epics/info-messages';

const PagePopup = ({ message }) => {
  const { seo, onClose, onAccept } = message;
  const [isOpen, toggleModal] = useState(true);

  const onCloseHandler = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    toggleModal(false);
  }

  const onAcceptHandler = async () => {
    try {
      let response = await onAccept()
        .then((resp) => {
          toggleModal(false);
          return resp;
        });
      return response;
    } catch (e) {
      console.log(e.graphQLErrors);
    }
  }

  return (
    seo && isOpen ?
      <Modal
        titleBtn="Custom Pricing Request"
        btnClass="tour-btn-custom-requests"
        isOpen={isOpen}
        showBtnClose={false}
        component={<StaticPage seo={seo} />}
        footer={<Fragment>
          <Button color="default" type="button" className="mb-2 mb-sm-0 ml-3" onClick={onCloseHandler}>
            <FormattedMessage id="modal.close"
              defaultMessage="Close" />
          </Button>
          <Button color="primary" type="button" className="mb-2 mb-sm-0 ml-3" onClick={onAcceptHandler}>
            <FormattedMessage id="modal.ok"
              defaultMessage="Accept" />
          </Button>
        </Fragment>}
      />
      : null
  )
};

export default createAlert({
  alertName: INFO_MESSAGES_USER_PAGE,
})(PagePopup);
