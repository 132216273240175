export const getSortLabelOptions = sorterOptions => (key) => {
  const sorterMap = new Map();
  sorterOptions.forEach((sorter) => {
    sorterMap.set(sorter.key, sorter);
  });

  const sorter = sorterMap.get(key);
  return sorter && sorter.label;
}

export const hasScrollBar = (current) => current && current.scrollHeight > current.clientHeight;

export const getFiltersVisiblity = (filters = []) => {
  const visibleFilters = [];
  const inVisibleFilters = [];
  filters.forEach((filter) => {
    const { isVisible } = filter;
    if (isVisible) {
      visibleFilters.push(filter);
    } else {
      inVisibleFilters.push(filter);
    }
  });

  return { visibleFilters, inVisibleFilters };
};
