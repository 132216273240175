import React, { useState } from 'react';
import FactSheetIcon from '@material-ui/icons/ListAlt';
import Modal from 'components/modal/Modal';
import { normalizeId } from 'pages/orders/utils';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, UncontrolledTooltip } from 'reactstrap';
import FactSheetModalForm from './FactSheetModalForm';
import FactSheetPrint from './FactSheetPrint';
import { miniFormCardName } from '../pricinigMiniForm/PricinigMiniForm';
import { pricingMiniFormValueSelector } from '../pricinigMiniForm/utils';
import { connect } from 'react-redux';

const FactSheet = props => {
  const { 
    trailId,
    bloombergTicker,
    cardId,
    commodityContract,
    premiumAmount: premiumAmountUserInput,
    premiumField,
  } = props;

  const [isFactSheetOpen, setFactSheetOpen] = useState(false);
  const [isOpenModal, toggleModal] = useState(false);
  const [notional, setNotional] = useState(null);
  const [premiumAmount, setPremiumAmount] = useState(premiumAmountUserInput);

  const initialValues = {
    notional,
    premiumAmount: premiumAmountUserInput
  }

  const toggleFactSheet = () => setFactSheetOpen(!isFactSheetOpen);
  const setNotionalAction = (value) => setNotional(value);
  const setPremiumAction = (value) => setPremiumAmount(value);

  const togglePopup = () => toggleModal(!isOpenModal);
  const onCloseModal = () => toggleModal(false);

  return (
    isFactSheetOpen ?
      (trailId && <FactSheetPrint
        trailId={trailId}
        bloombergTicker={bloombergTicker}
        commodityContract={commodityContract}
        notional={notional}
        premium={premiumAmount}
        onClose={toggleFactSheet}
        premiumField={premiumField}
        setPremiumAction={setPremiumAction}
        setNotionalAction={setNotionalAction}
      />)
      :
      <div className="factsheet-block">
        <Button id={`pricing-mini-factsheet-${normalizeId(cardId)}`} color="link" size="sm" className="btn-icon" onClick={togglePopup}>
          <FactSheetIcon />
        </Button>
        <UncontrolledTooltip placement="top" target={`pricing-mini-factsheet-${normalizeId(cardId)}`}>
          <FormattedMessage id="price.factsheet"
            defaultMessage="Factsheet" />
        </UncontrolledTooltip>
        <Modal
          btnType="primary"
          btnClass="btn-icon"
          isOpen={isOpenModal}
          onToggle={onCloseModal}
          onClose={onCloseModal}
          component={<FactSheetModalForm {...props} toggleFactSheet={toggleFactSheet} setNotionalAction={setNotionalAction} setPremiumAction={setPremiumAction} initialValues={initialValues} />}
        />
      </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { cardId } = ownProps;
  const form = miniFormCardName(cardId);

  const premiumAmount = pricingMiniFormValueSelector(form)(state, 'premiumAmount');

  return {
    premiumAmount,
  }
}

FactSheet.propTypes = {
  trailId: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  commodityContract: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(FactSheet);
