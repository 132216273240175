export const PRICING_BULLET_STRIP = '@PRICING/BULLET_STARIP';
export const PRICING_BULLET_STRIP_CARD_START_DELETE = `${PRICING_BULLET_STRIP}/CARD/START_DELETE`;

export const PRICING_BULLET_STRIP_CONFIRMATION_MODAL_APPROVE = `${PRICING_BULLET_STRIP}/REQUEST/CONFIRMATION_MODAL_APPROVE`;
export const PRICING_BULLET_STRIP_CONFIRMATION_MODAL_CANCEL = `${PRICING_BULLET_STRIP}/REQUEST/CONFIRMATION_MODAL_CANCEL`;
export const BULLET_STRIP_CARDS_REQUEST_PRICE = `${PRICING_BULLET_STRIP}/CARD/REQUEST/PRICE`;

export const bulletStripCardStartDelete = payload => ({
  type: PRICING_BULLET_STRIP_CARD_START_DELETE,
  payload,
});

export const bulletStripApproveModalAction = payload => ({
  type: PRICING_BULLET_STRIP_CONFIRMATION_MODAL_APPROVE,
  payload
});

export const bulletStripCancelModalAction = payload => ({
  type: PRICING_BULLET_STRIP_CONFIRMATION_MODAL_CANCEL,
  payload
});

export const bulletStripCardsRequestPrice = payload => ({
  type: BULLET_STRIP_CARDS_REQUEST_PRICE,
  payload
});
