import { STRUCTURE_ACCUMULATOR } from 'constants.js';
import debug from 'debug';
import sortBy from 'lodash/sortBy';
import { structuresConfig } from 'pages/price/FormItem';
import { change, getFormInitialValues, getFormValues } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { changePayoutNotional, pricingDataLoader, PRICING_ACCUMULATOR_DATA_LOADED, PRICING_ACCUMULATOR_FORM_LOAD, PRICING_FORM, PRICING_FORM_LIMITATION, PRICING_FORM_SUBMITTED, PRICING_SELECT_SHEET, PRICING_UPDATE_UNDERLINE, PRICING_VANILLA_FORM_LOAD, setCahrtData, setFieldParams, setLimitationForm, updateStructureDetails, updateTrailPrice, updateUnderline } from 'redux/actions/price';
import { PRICING_SWAP_FORM_LOAD } from 'redux/actions/swap';
import { PRICING_FX_FORM_LOAD } from 'redux/actions/fx';
import priceHistoryLoadedEpic from 'redux/prices/history/pricingHistoryEpicLoaded';
import { combineLatest, from, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { changeActionFilter, changeFormActionFilter } from 'utils/reduxFormSelector';
import { LIMITATION_RELATION } from '../../prices/configs';
import selectStructureEpic from '../../prices/epics/selectStructureEpic';
import { loadAllVariable } from '../../queries/price';
import { distinctObjectChanges } from 'redux/epics/utils';
import { priceFormResetFilter, selectStructureFilter, unselectStructureFilter } from './filters';
import priceFormSubmitEpic from './structures/accumulator/calculations';
import dynamicFormEpic from './structures/accumulator/dynamic-form';

export const setInitialValuesPriceForm = (state$) => {
  const state = state$.value;
  const initialValues = getFormInitialValues(PRICING_FORM)(state);
  const actions = [
    ...Object.keys(initialValues).map(key =>
      change(PRICING_FORM, key, initialValues[key]))
  ];
  return from(actions)
}

export const LOGGER_PREFIX = 'ma:price:epic';
export const logger = debug(LOGGER_PREFIX);

export const priceFormValueSelector = getFormValues(PRICING_FORM);

export const preloadStructureLimitationEpicLogger = debug(LOGGER_PREFIX + ':preloadStructureLimitationEpic')
export const preloadStructureLimitationEpic = (action$, state$) =>
  action$
    .pipe(
      filter(changeFormActionFilter(PRICING_FORM)),
      filter(({ meta: { field } }) => priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR &&
        LIMITATION_RELATION
          .filter(({ onlyFill }) => !onlyFill)
          .map(({ key }) => key).includes(field)),
      // debounceTime(10),
      map(() => priceFormValueSelector(state$.value)),
      tap(preloadStructureLimitationEpicLogger.bind(preloadStructureLimitationEpicLogger, 'after debounce')),
      // tap(filterData => {
      //   console.log(filterData);
      // }),
      map((filterData) => loadAllVariable(filterData, state$.value.price.pricings)),
      distinctUntilChanged(distinctObjectChanges),
      map((limitation) => {
        return setLimitationForm({
          limitation,
          limitationRelation: LIMITATION_RELATION
        })
      }),
      tap(preloadStructureLimitationEpicLogger.bind(preloadStructureLimitationEpicLogger, 'preloadStructureLimitationEpic2'))
    );

// @TOOD: remove as useless part
const initUnderlineEpic = () => of(updateUnderline(null));

const selectUnderlyingEpic = (action$, state$) =>
  combineLatest(
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'commodity'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'contractExpiry'))),
    action$.pipe(filter(changeActionFilter(PRICING_FORM, 'payoutCurrency'))),
  ).pipe(
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    filter((changes) => !changes.some(({ payload }) => !payload)),
    switchMap(([{ payload: selectedCommodity }, { payload: selectedContractExpiry }, { payload: selectedCurrency }]) => {
      const pricings = state$.value.price.pricings;
      const underlying = pricings
        .find(
          ({ commodity, contractExpiry, accumulatorUnderlyings: { currency } }) =>
            commodity === selectedCommodity
            && contractExpiry === selectedContractExpiry
            && currency === selectedCurrency
        );

      const actions = [
        updateUnderline(underlying)
      ];
      const limitation = state$.value.price.limitation;
      if (limitation && limitation['structureExpiredAt'] && limitation['structureExpiredAt'].length) {
        actions.push(
          change(PRICING_FORM, 'expiration', limitation['structureExpiredAt'][0])
        );
      }

      return from(actions);
    }),
    tap(logger)
  )

const unSelectUnderlyingEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_UPDATE_UNDERLINE),
      filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
      filter(({ id }) => !!id),
      switchMapTo(
        merge(
          action$.pipe(filter(changeActionFilter(PRICING_FORM, 'commodity'))),
          action$.pipe(filter(changeActionFilter(PRICING_FORM, 'contractExpiry')))
        ).pipe(
          filter(({ payload }) => !payload),
          switchMap(() => {
            let actions = [
              setCahrtData(null),
              updateUnderline(null),
              change(PRICING_FORM, 'expiration', null)
            ];
            return from(actions);
          })
        )
      )
    );

const underlyingEpic = combineEpics(initUnderlineEpic, selectUnderlyingEpic, unSelectUnderlyingEpic);

const fillNotionalFromSheetEpicLogger = debug(LOGGER_PREFIX + ':fillNotionalFromSheetEpic')
export const fillNotionalFromSheetEpic = (action$, state$) =>
  action$
    .pipe(
      filter(action => action.type === PRICING_SELECT_SHEET && !priceFormValueSelector(state$.value).notional),
      map(({ sheet }) => changePayoutNotional(sheet.businessDays)),
      tap(fillNotionalFromSheetEpicLogger)
    );

export const unFillNotionalFromSheetEpic = (action$, state$) =>
  action$
    .pipe(
      filter(priceFormResetFilter),
      filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
      map(_ => changePayoutNotional(null)),
      tap(fillNotionalFromSheetEpicLogger)
    );

export const setFormFieldsEpics = (action$, state$) =>
  action$
    .pipe(
      filter(selectStructureFilter),
      filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
      tap(logger.bind(logger, 'setFormFieldsEpics')),
      map(({ structure }) => structure.barrierType),
      tap(logger.bind(logger, 'setFormFieldsEpics')),
      map((barrierType) => setFieldParams(structuresConfig[barrierType]))
    );

export const unSetFormFieldsEpics = action$ =>
  action$.pipe(
    filter(unselectStructureFilter),
    map(_ => setFieldParams(null))
  );

const fillSingleValueEpicsLogger = debug(LOGGER_PREFIX + ':fillSingleValueEpics');

const resetLimitedValueEpics = (action$, state$) =>
  action$
    .pipe(
      ofType(PRICING_FORM_LIMITATION),
      // filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
      tap(fillSingleValueEpicsLogger),
      switchMap(({ payload: { limitation: limitationObject, limitationRelation = LIMITATION_RELATION } }) => {
        const formValues = priceFormValueSelector(state$.value);
        const state = state$.value;
        const initialValues = getFormInitialValues(PRICING_FORM)(state);

        return from(
          limitationRelation
            .filter(
              ({ key, limitationKey, compare, firstFill }) =>
                formValues &&
                !!formValues[key] &&
                !firstFill &&
                Array.isArray(limitationObject[limitationKey]) &&
                !limitationObject[limitationKey]
                  .some(data => compare(formValues[key], data))
            )
            .filter(({ key }) => !Object.keys(initialValues).includes(key))
            .map(({ key }) => change(PRICING_FORM, key, null))
        );
      }),
      tap(fillSingleValueEpicsLogger)
    );

// @TODO: check and optimize performance
const fillSingleValueEpics = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_FORM_LIMITATION),
    filter(() => priceFormValueSelector(state$.value) && priceFormValueSelector(state$.value).structure === STRUCTURE_ACCUMULATOR),
    tap(fillSingleValueEpicsLogger),
    switchMap(({ payload: { limitation: limitationObject, limitationRelation = LIMITATION_RELATION } }) => {
      const formValues = priceFormValueSelector(state$.value);

      let actions = limitationRelation
        .filter(({ onlyFill }) => !onlyFill)
        .filter(({ limitationKey }) => limitationObject[limitationKey] && limitationObject[limitationKey].length === 1)
        .filter(({ key, limitationKey }) => formValues && (!formValues[key] || formValues[key] !== limitationObject[limitationKey][0]))
        .filter(({ key }) => key !== 'expiration')
        .map(({ key, limitationKey }) => change(PRICING_FORM, key, limitationObject[limitationKey][0]));

      if (limitationObject && formValues && limitationObject['barrierType'] && !limitationObject['barrierType'].includes(formValues['barrierType'])) {
        const barrierType = sortBy(limitationObject['barrierType'])[0];
        actions = [
          ...actions,
          change(PRICING_FORM, 'barrierType', barrierType)
        ]
      }

      return from(actions);
    }),
    tap(fillSingleValueEpicsLogger),
  )

const addOrderTrailPriceEpic = action$ =>
  action$
    .pipe(
      ofType(PRICING_FORM_SUBMITTED),
      filter(({ payload }) => !!payload),
      switchMap(({ payload: { response } }) => {
        const actions = [
          updateTrailPrice(response),
          updateStructureDetails(response)
        ];
        return from(actions);
      })
    );

export const priceFormLifeEpics = (action$, state$) =>
  merge(
    action$.pipe(ofType(PRICING_ACCUMULATOR_DATA_LOADED)),
    action$.pipe(filter(priceFormResetFilter))
  ).pipe(
    switchMap(() => {
      return combineEpics(
        preloadStructureLimitationEpic,
        underlyingEpic,
        selectStructureEpic,
      )(action$, state$).pipe(
        takeUntil(action$.pipe(filter(priceFormResetFilter)))
      )
    })
  );

export const loaderPricingFormStart = action$ =>
  merge(
    action$.pipe(ofType(PRICING_ACCUMULATOR_FORM_LOAD)),
    action$.pipe(ofType(PRICING_VANILLA_FORM_LOAD)),
    action$.pipe(ofType(PRICING_SWAP_FORM_LOAD)),
    action$.pipe(ofType(PRICING_FX_FORM_LOAD)),
  ).pipe(
    mapTo(pricingDataLoader(true))
  );

export default combineEpics(
  priceFormLifeEpics,
  fillNotionalFromSheetEpic,
  unFillNotionalFromSheetEpic,
  setFormFieldsEpics,
  unSetFormFieldsEpics,
  resetLimitedValueEpics,
  fillSingleValueEpics,
  addOrderTrailPriceEpic,
  selectStructureEpic,
  priceFormSubmitEpic,
  loaderPricingFormStart,
  priceHistoryLoadedEpic,
  dynamicFormEpic
);
