import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { cloneElement, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal as ReactstrapModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const BTN_TYPES = ['link', 'primary', 'default'];
const BTN_SIZES = ['md', 'xs', 'sm', 'lg'];

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen || false
    };

    this.toggle = this._toggle.bind(this);
    this.close = this._close.bind(this);
  }

  static defaultProps = {
    showCloseBtn: false,
    btnType: BTN_TYPES[0],
    btnSize: BTN_SIZES[0],
    btnClass: '',
    showBtnClose: true,
    disableOnToggle: false
  };

  static getDerivedStateFromProps(props) {
    return isBoolean(props.isOpen) ? { isOpen: props.isOpen } : null;
  }

  _toggle() {
    const { isOpen } = this.state;
    const { onToggle, disableOnToggle } = this.props;
    if(disableOnToggle) return

    this.setState({
      isOpen: !isOpen
    });

    if (typeof onToggle === 'function') {
      onToggle(!isOpen);
    }
  }

  _close() {
    this.setState({
      isOpen: false
    });
    const { onClose } = this.props;
    const { isOpen } = this.state;
    if (typeof onClose === 'function') {
      onClose(isOpen);
    }
  }

  render() {
    const {
      component,
      title,
      titleBtn,
      text,
      className,
      showCloseBtn,
      btnType,
      btnSize,
      btnClass,
      onClose,
      showBtnClose,
      footer,
      ...rest } = this.props;
    const { isOpen } = this.state;
    const modalClassName = className ? className : '';
    return (
      component ?
        <div className="modal-switcher">
          {titleBtn ? <Button size={btnSize} color={btnType} onClick={this.toggle} className={btnClass}>
            {titleBtn}
          </Button>
            :
            null}
          <ReactstrapModal isOpen={isOpen} onClosed={onClose} toggle={this.toggle} className={'modal-dark ' + modalClassName} {...rest} >
            {showBtnClose ? <div className="modal-close" onClick={this.toggle}></div> : null}
            {
              (title || text) ?
                <ModalHeader>
                  {title}
                  {text ? <div className="sub-header">{text}</div> : null}
                </ModalHeader>
                : null
            }
            <ModalBody>
              {component ? cloneElement(component, { onClose: this.close }) : null}
            </ModalBody>

            {showCloseBtn ?
              <ModalFooter>
                <Button color="default" type="button" className="mb-2 mb-sm-0 ml-3" onClick={this.toggle}>
                  <FormattedMessage id="modal.close"
                    defaultMessage="Close" />
                </Button>
              </ModalFooter>
              : null
            }

            {footer ?
              <ModalFooter>
                {footer}
              </ModalFooter>
              : null}

          </ReactstrapModal>
        </div>
        : null
    );
  }
};

Modal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  titleBtn: PropTypes.node,
  btnType: PropTypes.oneOf(BTN_TYPES),
  btnSize: PropTypes.oneOf(BTN_SIZES),
  btnClass: PropTypes.string,
  showBtnClose: PropTypes.bool,
  disableOnToggle: PropTypes.bool,
  isOpen: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
  component: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default Modal;
