import { forkJoin } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { PRICING_ACCUMULATOR_DATA_LOADED, pricingDataLoader, PRICING_ACCUMULATOR_FORM_LOAD, loadedAccumulatorData } from 'redux/actions/price';
import { filter, switchMap, map, mapTo, takeUntil } from 'rxjs/operators';
import { priceFormResetFilter, priceFormDestroyFilter } from '../../filters';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { setInitialValuesPriceForm } from 'redux/epics/price/price-form';
import { loadAccumulatorDescriptionsData, loadAccumulatorUnderlyingsData } from 'redux/queries/accumulator';

const combineUnderlyingsDescriptions = ([pricings, structures]) => {
  const records = {
    pricings,
    structures,
  };
  return records;
}

export const loadAccumulatorForm = action$ =>
  action$
    .pipe(
      ofType(PRICING_ACCUMULATOR_FORM_LOAD),
      switchMap(() => {
        return forkJoin([
          loadAccumulatorUnderlyingsData(),
          loadAccumulatorDescriptionsData(),
        ]).pipe(
          takeUntil(
            action$.pipe(
              filter(action => action.type === AUTH_LOGOUT || priceFormDestroyFilter(action))
            ))
        );
      }),
      map(data => {
        //@TODO: add error handlings
        const records = combineUnderlyingsDescriptions(data);
        return loadedAccumulatorData(records);
      })
    );

export const setInitialValuesPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_ACCUMULATOR_DATA_LOADED),
    switchMap(() => setInitialValuesPriceForm(state$))
  );

// @TODO: use to set strucutre object in ReduxFrom via initialValues
export const resetInitialValuesPriceFormEpic = (action$, state$) =>
  action$.pipe(
    filter(priceFormResetFilter),
    switchMap(() => setInitialValuesPriceForm(state$))
  )

export const loaderPricingFormFinish = action$ =>
  action$.pipe(
    ofType(PRICING_ACCUMULATOR_DATA_LOADED),
    mapTo(pricingDataLoader(false))
  );

export default combineEpics(
  loadAccumulatorForm,
  setInitialValuesPriceFormEpic,
  resetInitialValuesPriceFormEpic,
  loaderPricingFormFinish,
);
