import gql from 'graphql-tag';

export const USER_TRADERS_OPTIONS_QUERY_FRAGMENT = gql`fragment userTraderFragment on UserAccountType {
  firstName
  lastName
}`;

export const USER_TRADERS_OPTIONS_QUERY = gql`query getUserTradersQuery (
  $search: String
  $cursor: String
  $filter: [TradersUserAccountFilterType]
  $first: Int
) {
  userTraders (
    search: $search
    after: $cursor
    first: $first
    filter: $filter
  ) {
    edges {
      node {
        id
        ...userTraderFragment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}
${USER_TRADERS_OPTIONS_QUERY_FRAGMENT}`;
