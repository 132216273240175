import autocallableFormProps from '../autocallable/autocallableFormProps';
import { PRICING_FORM_TWIN_WIN_INITIAL_VALUES } from './constants';

const twinWinFormProps = (state, formOptions) => {
  const props = autocallableFormProps(state, formOptions);
  const { barrierTypeOptions = [], initialValues } = props;
  return {
    ...props,
    barrierTypeOptions: barrierTypeOptions.filter(({ value }) => !['None', 'Vanilla'].includes(value)),
    initialValues: {
      ...initialValues,
      ...PRICING_FORM_TWIN_WIN_INITIAL_VALUES,
    },
  }
}

export default twinWinFormProps;
