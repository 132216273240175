import { PureComponent } from 'react'
import PropTypes from 'prop-types';

const initalState = {
  mod: null, // short for "module" but that's a keyword in js, so "mod"
  error: null
}

class Bundle extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      ...initalState
    };
    this.load(this.props);
  }

  componentDidUpdate(prevProps) {
    const { load } = this.props;
    const { load: prevLoad } = prevProps;
    if (load !== prevLoad) {
      this.load();
    }
  }

  load = async () => {
    const { load, title } = this.props;
    try {
      const response = await load().then((mod) => {
        this.setState({
          // handle both es imports and cjs
          mod: mod.default ? mod.default : mod,
        })
      });
      return response;
    } catch (e) {
      if (window.API_HOST.indexOf('dev') >= 0) {
        console.log(e);
      }
      const error = `Failed to load${title ? ' ' + title : ''}.`;
      this.setState({ error })
    }
  }

  componentWillUnmount() {
    this.setState({ ...initalState })
  }

  render() {
    const { children } = this.props;
    const { mod, error } = this.state;
    if (error) throw new Error(error);
    return mod ?
      children(mod)
      :
      null
  }
}

Bundle.propTypes = {
  load: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default Bundle;
