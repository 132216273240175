import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators, compose } from 'redux'
import { Field, change } from 'redux-form';
import { GET_AVAILABLE_ACCOUNTS } from './query';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { connect } from 'react-redux';
import { NOT_APPLICABLE } from './constants';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

const FIELD_NAME = 'account';

const OrderAccount = (props) =>  {
  const [accounts, setAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [formName] = useState(props.formName)

  useEffect(()=> {
    const init = async() => {
      const accounts = [{ id: 0, client: NOT_APPLICABLE, isDefault: false }];
      const { onTurnOnUpdateBtn, selectedAccount, change } = props;

      try {
        const responseAccounts = await getAvailableAccounts();
        if (responseAccounts?.length) {
          accounts.push(...responseAccounts);
          if (onTurnOnUpdateBtn) {
            onTurnOnUpdateBtn();
          }
        }
        if (responseAccounts?.length === 1) {
          change(formName, FIELD_NAME, responseAccounts[0].id);
        }
      } catch (e) {
        notificationErrorSimple(`Can't get max Good Till Date`);
      }

      const defaultAccount = accounts.find(({ isDefault }) => isDefault);
      if (defaultAccount?.id) {
        accounts.shift();
        change(formName, FIELD_NAME, defaultAccount.id);
      }
      selectedAccount && onTurnOnUpdateBtn();

      setAccounts(accounts);
      setIsLoading(false);
    }
    init()
  }, [])

  const getAvailableAccounts = async () => {
    const { client, notificationError } = props;

    try {
      const response = await client.query({
        query: GET_AVAILABLE_ACCOUNTS,
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
      }).then((resp) => resp.data && resp.data.userCounterparties);
      return response;
    } catch (error) {
      console.error(error);
      const err = getSingleGraphQlError(error);
      notificationError(err.message);
    }
  }

  return (
    isLoading
      ? null
      : <Field
          name={FIELD_NAME}
          label={<FormattedMessage id="account.label" defaultMessage="Account" />}
          justValue={true}
          component={InlineSelectInput}
          options={accounts.map(({id, client}) => ({ value: id, label: client }))}
        />
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
  notificationError: notificationErrorSimple,
  change,
}, dispatch);

export default compose(
  withApollo,
  connect(null, mapDispatchToProps)
)(OrderAccount);
