export const BOOKING_FORM_OPTION = '@BOOKING/FORM_OPTION';
export const CUSTOM_STRIPE_TYPE = 'Custom Strip';
export const DEFAULT_INSTRUMENT = CUSTOM_STRIPE_TYPE;
export const INSTRUMENT_FORM_TYPE = 'InstrumentForm';

export const INSTRUMENT_OPTIONS = [
  { label: CUSTOM_STRIPE_TYPE, value: CUSTOM_STRIPE_TYPE },
];

export const INSTRUMENT_TO_LEG_TITLE = new Map([
  [CUSTOM_STRIPE_TYPE, 'Strip']
]);

export const CUSTOM_STRIPE_FORMS = {
  STRUCTURE: 'CustomStripStructureForm',
  DETAILS: 'CustomStripDetailsForm',
  CALENDAR: 'CustomStripCalendarForm',
};

export const BOOKING_FORM_CALENDAR = `${BOOKING_FORM_OPTION}/${CUSTOM_STRIPE_FORMS.CALENDAR}`;
export const FORM_TYPES = [INSTRUMENT_FORM_TYPE, ...Object.values(CUSTOM_STRIPE_FORMS)];

export const CUSTOM_STRIPE_STEPS = [
  { label: 'Instrument', __type: INSTRUMENT_FORM_TYPE },
  { label: 'Structure', __type: CUSTOM_STRIPE_FORMS.STRUCTURE },
  { label: 'Booking Details', __type: CUSTOM_STRIPE_FORMS.DETAILS },
  { label: 'Calendar', __type: CUSTOM_STRIPE_FORMS.CALENDAR },
];

export const STEPS = new Map([
  [CUSTOM_STRIPE_TYPE, CUSTOM_STRIPE_STEPS],
]);

export const OPTION_STATUS_PENDING = 'pending';
export const OPTION_STATUS_FINISH = 'done';


export const EMPTY_TITLE = '-';
export const NEW_OPTION_STATE = {
  data: {
    [INSTRUMENT_FORM_TYPE]: {
      instrument: DEFAULT_INSTRUMENT,
    },
  },
  status: OPTION_STATUS_PENDING,
  title: EMPTY_TITLE,
};

export const FIRST_LAST_MONTH_FORMAT = 'MMM yy';
export const FIXING_DATE_FORMAT = 'dd MMM yyyy';

export const FIXING_DATE_TYPE = {
  FND: 'fnd',
  LISTED_OPTION_EXPIRY: 'listedOptionExpiry',
  CUSTOM: 'custom',
}

export const PACKAGE_TYPE = {
  SWAP: 'swap',
  OPTION: 'option',
}

export const DEFAULT_PACKAGE_TYPES = {
  [PACKAGE_TYPE.SWAP]: 0,
  [PACKAGE_TYPE.OPTION]: 0,
}

export const VALID_PACKAGE_TYPES = {
  [PACKAGE_TYPE.SWAP]: 1,
  [PACKAGE_TYPE.OPTION]: 999999,
}

export const EMPTY_SLACK_TEAM_ERROR = 'You have no slack team assigned, please contact your representative for more details.';

